export default {
  name: 'data',
  namespaced: true,
  state: {
    countries: false,
    currencies: false,
    currenciesOptions: [],
    currenciesExponents: false,
    schemas: {},
    flat_schemas: {},
    types: [],
    flatTypes: [],
    exportFormats: false,
  },
  mutations: {
    setCountries: function (state, config) {
      state.countries = config;
    },
    setCurrencies: function (state, config) {
      state.currencies = config.map((c) => c.code);
      state.currenciesOptions = config.map((c) => ({key: c.code, label: c.code + ' (' + c.name + ')'}));
    },
    setCurrenciesExponents: function (state, config) {
      state.currenciesExponents = config.reduce(
        (acc, c) => ({...acc, [c.code]: {exponent: c.exponent, iso: c.iso_exponent}}),
        {}
      );
    },
    setSchemas: (state, payload) => {
      payload.forEach((schema) => {
        state.schemas[schema.id] = schema;
      });
    },
    setFlatSchemas: (state, payload) => {
      payload.forEach((schema) => {
        state.flat_schemas[schema.id] = {...schema, attributes: schema.attributes.properties};
      });
    },
    setTypes: (state, payload) => {
      state.types = payload.map((s) => ({id: s.id, is_product: s.is_product}));
    },
    setFlatTypes: (state, payload) => {
      state.flatTypes = payload.map((s) => ({id: s.id, is_product: s.is_product}));
    },
    setExportFormats: (state, formats) => {
      state.exportFormats = formats;
    },
  },
  actions: {
    setCurrencies: function (context, config) {
      if (config) {
        context.commit('setCurrencies', config);
      }
    },
    setCurrenciesExponents(context, config) {
      if (config) {
        context.commit('setCurrenciesExponents', config);
      }
    },
    setCountries: function (context, config) {
      if (config) {
        context.commit('setCountries', config);
      }
    },
    setSchemas: (context, schemas) => {
      context.commit('setSchemas', schemas);
      context.commit('setTypes', schemas);
    },
    setFlatSchemas: (context, schemas) => {
      context.commit('setFlatSchemas', schemas);
      context.commit('setFlatTypes', schemas);
    },
    setExportFormats: (context, formats) => {
      context.commit('setExportFormats', formats);
    },
  },
};
