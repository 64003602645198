<template>
  <teleport :to="getTeleportLocation()">
    <div
      class="side-panel"
      :class="{open: modelValue, fixed: isFixedPanel, collapsed: isCollapsed, 'hover-expanded': isHoveredExpanded}"
      @mouseenter="shouldHoveredExpanded = true"
      @mouseleave="shouldHoveredExpanded = false"
    >
      <div class="side-panel-content">
        <s-icon type="cross" color="theme-secondary" :on-click="closePanel" class="close-button" />
        <slot />
      </div>
      <div
        v-if="collapsable && isFixedPanel"
        class="side-panel-collapse"
        :class="{collapsed: shouldBeCollapsed}"
        @click="collaspse"
        @mouseenter="shouldHoveredExpanded = true"
      >
        <s-icon type="arrow-drop-down"></s-icon>
      </div>
    </div>
  </teleport>
</template>

<script>
  import {isEmpty} from '@veasel/core/tools';
  import icon from '@veasel/base/icon';

  export default {
    name: 's-side-panel',
    components: {
      's-icon': icon,
    },
    emits: ['update:modelValue'],
    props: {
      modelValue: {
        type: Boolean,
        default: false,
      },
      navbarContainerName: {
        type: String,
        description:
          'ID/Class to find the navbar, to see if there is enough room to show the side panel. Include # or ., like you would use in querySelector()',
        default: '#navbar-container',
      },
      contentContainerName: {
        type: String,
        description:
          'ID/Class to find the content container/page, to see if there is enough room to show the side panel. Include # or ., like you would use in querySelector()',
        default: '#page',
      },
      forcedFixedPanel: {
        type: Boolean,
        default: false,
        description: 'Forces the opening of the panel is be fixed/over the content, instead of pushing the content',
      },
      collapsable: {
        type: Boolean,
        default: false,
        description: 'Allows the side panel to be hidden, but not removed',
      },
    },
    beforeCreate() {
      // Insert div to teleport stuff to the bottom of the root element
      const rootEl = this.$root.$el;
      // Made the root be flex
      rootEl.classList.add('flex');
      if (rootEl.querySelectorAll('#side-panel-wrapper').length === 0) {
        rootEl.insertAdjacentHTML('beforeend', '<div id="side-panel-wrapper"></div>');
      }
    },
    data() {
      return {
        isFixedPanel: false,
        shouldBeCollapsed: true,
        shouldHoveredExpanded: true,
      };
    },
    computed: {
      isHoveredExpanded() {
        return this.collapsable && this.modelValue ? this.shouldHoveredExpanded : false;
      },
      isCollapsed() {
        return this.collapsable && this.modelValue ? this.shouldBeCollapsed : false;
      },
    },
    methods: {
      closePanel() {
        this.$emit('update:modelValue', false);
      },
      getTeleportLocation() {
        return document.querySelectorAll('#side-panel-wrapper').length > 0
          ? '#side-panel-wrapper'
          : '#side-panel-wrapper-top';
      },
      collaspse() {
        this.shouldBeCollapsed = !this.shouldBeCollapsed;
        if (this.shouldBeCollapsed) {
          this.shouldHoveredExpanded = false;
        }
      },
      forceCollapse() {
        this.shouldBeCollapsed = true;
        this.shouldHoveredExpanded = false;
      },
      forceHoverOpen() {
        this.shouldHoveredExpanded = true;
        this.shouldBeCollapsed = true;
      },
    },
    watch: {
      modelValue(newValue) {
        if (newValue) {
          const page = document.querySelectorAll(this.contentContainerName);
          const navbar = document.querySelectorAll(this.navbarContainerName);
          if (isEmpty(page) || isEmpty(navbar) || this.forcedFixedPanel) {
            this.isFixedPanel = true;
          } else {
            this.isFixedPanel = page[0].offsetWidth + navbar[0].offsetWidth - 336 < 1280;
          }
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@veasel/core';

  .side-panel {
    border: 1px solid var(--secondary-light);
    margin-top: 57px;
    position: relative;
    min-height: calc(100vh - 90px);
    height: 100%;
    width: 0;
    transition: ease all 0.5s;
    background: var(--background-main);
    box-sizing: border-box;
    box-shadow: 0 3px 6px #00000029;
    z-index: 42;

    &.fixed {
      position: fixed;
      right: rem(-340px);
      height: calc(100% - 45px);
    }

    &.open {
      right: 0;
      width: rem(336px);
    }

    &.collapsed {
      right: rem(-340px);
    }

    &.hover-expanded {
      right: 0;
      width: rem(336px);
    }

    .side-panel-content {
      position: fixed;
      width: 336px;
      overflow: auto;
      height: calc(100% - 45px);
    }

    .close-button {
      position: absolute;
      right: 12px;
      margin-top: 12px;
      font-size: rem(16px);
      transition: all ease $transition-time;

      &:hover {
        margin-top: 10px;
        color: var(--active);
      }
    }
  }

  .side-panel-collapse {
    font-size: 16px;
    border-radius: 8px 0 0 8px;
    cursor: pointer;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    // This position is absolute to the WINDOW, not the side panel
    display: flex;
    //z-index: 100;
    align-items: center;
    justify-content: center;
    transition: ease all $transition-time;
    top: 0;
    width: 34px;
    height: 68px;
    position: absolute;
    left: -35px;
    background: var(--background-main);

    ::v-deep(.s-icon .icon) {
      transition: ease all $transition-time;
    }

    ::v-deep(.s-icon) {
      position: relative;
      transform: rotate(270deg);
      transition: ease all $transition-time;
      left: 2px;
    }

    // Covers the shadow of the elements
    &::after {
      content: '';
      width: 6px;
      height: 68px;
      background: var(--background-main);
      position: absolute;
      right: -4px;
    }

    &.collapsed {
      right: 0;

      ::v-deep(.s-icon) {
        transform: rotate(90deg);
        left: -2px;
      }
    }

    &:hover ::v-deep(.s-icon .icon) {
      color: var(--active);
    }
  }
</style>
