<template>
  <s-input-wrapper v-bind="$props">
    <currency-input-wrapper
      :id="id"
      ref="inputRef"
      v-model="$_rawValue"
      :placeholder="_placeholder"
      :options="options"
      class="s-input-group-field"
      :autocomplete="autocomplete"
      :disabled="disabled"
      @click="$_onClick"
      @focus="$_onFocus"
      @blur="$_onBlur"
    />
  </s-input-wrapper>
</template>

<script>
  import SInputWrapper from '@veasel/inputs/input-wrapper';
  import {getDecimalMultiplierForCurrency} from '@veasel/core/tools';
  import {
    syncValue,
    onChange,
    onClick,
    onBlur,
    onFocus,
    onKeyPressed,
    onPaste,
    id,
    name,
    placeholder,
    isDisabled,
    isRequired,
    pattern,
    maxLength,
    helperMessages,
  } from '@veasel/core/mixins';
  import CurrencyInputWrapper from './currencyInputWrapper.vue';

  export default {
    name: 's-input-monetary',
    components: {
      SInputWrapper,
      CurrencyInputWrapper,
    },
    description: 'A user input for selecting monetary value.',
    mixins: [
      syncValue(Number),
      onChange(),
      onClick(),
      onBlur(),
      onFocus(),
      onKeyPressed(),
      onPaste(),
      id,
      name,
      placeholder,
      isDisabled,
      isRequired,
      pattern,
      maxLength,
      helperMessages,
    ],
    props: {
      autocomplete: {
        type: String,
        default: 'off',
      },
      valueAsInteger: {
        description: 'A flag to return a value using lower currency (currency cents) or higher currency (currency).',
        type: Boolean,
        default: false,
      },
      currencyExponent: {
        description: 'A number to override.',
        type: Number,
        default: -1,
      },
      currency: {
        description: 'The currency used for the monetary value.',
        type: String,
        default: null, // Indicates the CurrencyInputWrapper that currency code must be hidden
      },
      locale: {
        description: 'The geographic locale used to format the number.',
        type: String,
        default: 'en-UK',
      },
    },
    computed: {
      $_rawValue: {
        get() {
          return this.$_value ? this.unparseExponent(this.$_value) : this.$_value;
        },
        set(val) {
          this.$_value = val ? this.parseExponent(val) : val;
        },
      },
      type: function () {
        return this.password ? 'password' : 'text';
      },
      options: function () {
        return {
          currency: this.currency,
          locale: this.locale,
          exportValueAsInteger: this.valueAsInteger,
        };
      },
      // this is to handle undefined exponents (see https://en.wikipedia.org/wiki/ISO_4217)
      // will check inconsistency between the result we want (ex: XAU should have 0 decimal) and the way Intl is formatting (ex: XAU is formatted with 2 decimals)
      overrideExponent() {
        if (this.valueAsInteger && this.currencyExponent !== -1) {
          if (getDecimalMultiplierForCurrency(this.currency) !== Math.pow(10, this.currencyExponent)) {
            return getDecimalMultiplierForCurrency(this.currency) / Math.pow(10, this.currencyExponent);
          }
        }
        return 1;
      },
    },
    methods: {
      unparseExponent(val) {
        return val * this.overrideExponent;
      },
      parseExponent(val) {
        return val / this.overrideExponent;
      },
    },
    data() {
      return {
        inputType: 'monetary',
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import '@veasel/core/sass/conf';

  .s-input-group-field {
    font-family: var(--monotype-caption-font-family) !important;
  }

  .sl-input.small {
    min-height: $row-small-height - 7px;

    ::v-deep(input) {
      min-height: $row-small-height - 9px;
      font-size: 14px;
      display: block;
      margin-top: 0 !important;
    }
  }
</style>
