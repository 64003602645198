import {clone} from '@veasel/core/tools';

export default {
  computed: {
    aggregatedResult: {
      get: function () {
        return this.extendAggregations(this.updatedData);
      },
      set: function () {},
    },
  },

  methods: {
    // extend nested data object with intermediate value
    extendAggregations: function (data) {
      for (const i in data) {
        if (data[i].groups) {
          this.extendAggregations(data[i].groups);
          const aggregation = this.extractAndCalculateAggregations(data[i].groups);
          data[i].value =
            data[i].value === null
              ? this.opts.nullPlaceholder
              : data[i].value === undefined
              ? this.opts.undefinedPlaceholder
              : data[i].value;
          data[i].aggregations = aggregation.aggregations;
          data[i].count = aggregation.count;
        } else {
          data[i].aggregations = this.extendAggregationsWithCustomColumns(clone(data[i].aggregations));
        }
      }
      return data;
    },

    // recursively extract aggregations results from each children
    extractAndCalculateAggregations: function (data) {
      const results = [];
      const _this = this;
      extractResults(data);

      // eslint-disable-next-line require-jsdoc
      function extractResults(data) {
        for (const i in data) {
          if (data[i].groups) {
            extractResults(data[i].groups);
          } else {
            results.push({
              aggregations: _this.extendAggregationsWithCustomColumns(clone(data[i].aggregations)),
              count: data[i].aggregations[0].value,
            });
          }
        }
      }

      const aggResult = results.reduce(this.aggregationLogic);
      return aggResult;
    },

    // calculation logic to aggregate intermediate results
    aggregationLogic: function (acc, row, index) {
      for (const i in this.aggregateHeaders) {
        // If a value is NaN, this means that the value does not exist, but for calculations, we should make 'none' be 0
        if (isNaN(row.aggregations[i].value)) {
          row.aggregations[i].value = 0;
        }
        if (isNaN(acc.aggregations[i].value)) {
          acc.aggregations[i].value = 0;
        }

        switch (acc.aggregations[i].type) {
          case 'count':
          case 'sum':
            acc.aggregations[i].value += row.aggregations[i].value;
            break;
          case 'avg':
            // Iterative mean calculation
            acc.aggregations[i].value += (1 / (index + 1)) * (row.aggregations[i].value - acc.aggregations[i].value);
            break;
          case 'min':
            acc.aggregations[i].value = Math.min(acc.aggregations[i].value, row.aggregations[i].value);
            break;
          case 'max':
            acc.aggregations[i].value = Math.max(acc.aggregations[i].value, row.aggregations[i].value);
            break;
          case 'custom':
            if (!acc.aggregations[i]) {
              acc.aggregations[i] = {};
            }
            acc.aggregations[i].value =
              typeof this.aggregateHeaders[i].composedOf !== 'undefined'
                ? this.calculateCustomValue(acc.aggregations, this.aggregateHeaders[i].composedOf)
                : 0;
            break;
        }
        if (acc.aggregations[i].type === 'count') {
          acc.aggregations[i].value = parseInt(acc.aggregations[i].value);
        } else {
          acc.aggregations[i].value = parseFloat(acc.aggregations[i].value.toFixed(2));
        }
      }
      return acc;
    },

    // add custom columns result to aggregation objects
    extendAggregationsWithCustomColumns: function (aggregations) {
      for (const i in this.aggregateHeaders) {
        if (!aggregations[i]) {
          aggregations[i] = {
            property: this.aggregateHeaders[i].key,
            value:
              typeof this.aggregateHeaders[i].composedOf !== 'undefined'
                ? parseFloat(this.calculateCustomValue(aggregations, this.aggregateHeaders[i].composedOf).toFixed(2))
                : this.options.undefinedPlaceholder,
            type: 'custom',
          };
        }
      }
      return aggregations;
    },

    // calculation logic for custom columns
    calculateCustomValue: function (aggregations, composition) {
      switch (composition.op) {
        case '+':
          return aggregations[composition.index1].value + aggregations[composition.index2].value;
        case '-':
          return aggregations[composition.index1].value - aggregations[composition.index2].value;
        case 'x':
          return aggregations[composition.index1].value * aggregations[composition.index2].value;
        case '/':
          return aggregations[composition.index1].value / aggregations[composition.index2].value;
      }
    },
  },
};
