<template>
  <section ref="years-picker" class="years-picker">
    <div class="years-picker-control">
      <s-icon type="arrow-drop-up" size="14" @click="prevYear" />
    </div>
    <div ref="yearsList" class="years-picker-list">
      <div
        v-for="year in years"
        :key="year"
        class="year"
        :class="[
          {active: parseInt(year) === parseInt(yearNow)},
          {inactive: parseInt(year) !== parseInt(yearNow) && (limitTo(year) || limitFrom(year))},
        ]"
        @click="selectYear(year, false)"
      >
        {{ year }}
      </div>
    </div>
    <div class="years-picker-control">
      <s-icon type="arrow-drop-down" size="14" @click="nextYear" />
    </div>
  </section>
</template>

<script>
  import functions from '../utils/functions';
  import mixins from '../utils/mixins';

  const MAX_NR_YEARS = 7;

  export default {
    name: 'calendarYears',
    emits: ['changeYear'],
    mixins: [mixins],
    data() {
      return {
        years: [],
      };
    },
    computed: {
      yearNow() {
        return this.$props.date.format({year: 'numeric'});
      },
      yearsListMidPoint() {
        return Math.floor(MAX_NR_YEARS / 2);
      },
    },
    methods: {
      selectYear(year, update = false) {
        this.$emit('changeYear', parseInt(year), update);
      },
      prevYear() {
        this.years.pop();
        const year = parseInt(this.years[0]) - 1;
        this.years.unshift(`${year}`);
        this.selectYear(this.years[this.yearsListMidPoint]);
      },
      nextYear() {
        this.years.shift();
        const year = parseInt(this.years[this.years.length - 1]) + 1;
        this.years.push(`${year}`);
        this.selectYear(this.years[this.yearsListMidPoint]);
      },
      addListScrollEventListener() {
        const yearsListRef = this.$refs.yearsList;
        const opts = {
          downfn: this.prevYear,
          upfn: this.nextYear,
        };
        yearsListRef.addEventListener('wheel', (e) => functions.scrollWheelEventListener(e, opts));
      },
      removeListScrollEventListener() {
        const yearsListRef = this.$refs.yearsList;
        const opts = {
          downfn: this.prevYear,
          upfn: this.nextYear,
        };
        yearsListRef.removeEventListener('wheel', (e) => functions.scrollWheelEventListener(e, opts));
      },
      createYearsList(date) {
        this.years = [];

        // Centre active year
        for (let i = 0; i < MAX_NR_YEARS; i++) {
          if (i === 0) {
            date.subtract(this.yearsListMidPoint, 'year');
          } else {
            date.add(1, 'year');
          }

          this.years.push(date.format({year: 'numeric'}));
        }
      },
    },
    watch: {
      date(newVal) {
        const date = newVal.clone();

        this.createYearsList(date);
      },
    },
    mounted() {
      const date = this.$props.date.clone();

      this.createYearsList(date);

      this.$nextTick(() => {
        this.addListScrollEventListener();
      });
    },
    beforeUnmount() {
      this.removeListScrollEventListener();
    },
  };
</script>

<style lang="scss" scoped>
  @import '@veasel/core/sass/export';
  $height: 32px;
  $width: 32px;

  .inactive {
    color: var(--secondary-50) !important;
    pointer-events: none;
  }

  .s-icon {
    @include title;

    &:hover {
      background: var(--secondary-light);
    }
  }

  .years-picker {
    display: grid;
    grid-area: years;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 1fr;
    text-align: center;
    font-weight: bold;
    gap: 5px;
    place-items: center;
    width: 100%;
    user-select: none;

    & .active {
      background: var(--active) !important;
      color: white;
    }

    &-control {
      @include title;

      display: grid;
      align-items: center;
      cursor: pointer;
      background: var(--background-secondary);
      width: 100%;

      .s-icon {
        height: 20px;
        display: grid;
        place-content: center;
      }

      &:hover {
        background: var(--secondary-light);
      }
    }

    &-list {
      font-weight: bold;
      overflow: auto;
      display: grid;
      height: 100%;
      width: 100%;
      grid-template-rows: minmax($height, auto);
      grid-template-columns: minmax($width, auto);
      background: var(--background-secondary);

      & .year {
        cursor: pointer;
        display: grid;
        margin: 0;
        width: 100%;
        grid-template-rows: minmax($height, 1fr);
        place-items: center;
        align-items: center;

        &:hover {
          background: var(--secondary-light);
        }
      }
    }
  }
</style>
