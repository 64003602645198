<template>
  <button
    :id="id"
    :name="name"
    class="s-button"
    :class="[
      color,
      size,
      'icon-position-' + iconPosition,
      'styling-' + styling,
      {
        'has-no-text': !text,
        'has-sticker': sticker,
        'has-icon': loading ? 'loader' : icon,
        loading: loading,
      },
    ]"
    :disabled="isDisable || loading"
    @click="$_onClick"
  >
    <s-icon
      v-if="icon"
      class="button-icon"
      :class="{[iconPosition]: true, hide: loading}"
      :type="icon"
      :spinner="iconSpinner"
      :size="iconSize"
      :color="isDisable ? 'theme-background-main' : iconColor"
    ></s-icon>
    <s-icon
      v-if="loading"
      class="button-icon loading-icon"
      type="loader"
      spinner
      :size="iconSize"
      color="white"
    ></s-icon>
    <span v-if="sticker && styling === 'block'" class="button-sticker" :class="{hide: loading}">{{ sticker }}</span>
    <slot></slot>
    <span v-if="text" class="button-text" :class="{hide: loading}">{{ text }}</span>
    <span v-if="sticker && styling !== 'block'" class="button-sticker" :class="{hide: loading}">{{ sticker }}</span>
  </button>
</template>

<script>
  import {id, name, onClick, size} from '@veasel/core/mixins';
  import icon from '@veasel/base/icon';
  import {themeColorsList} from '@veasel/core/themes';

  // TODO: develop disable feature according to form validation
  export default {
    name: 's-button',
    description: 'A basic button component.',
    components: {
      's-icon': icon,
    },
    mixins: [id, name, onClick(true), size(['small', 'medium', 'large'], 'medium')], // onClick is mandatory
    slots: {
      default: 'Custom design can be used for creating a different style of button, for example an animation',
    },
    props: {
      text: {
        description: 'The text to describe button action.',
        type: String,
      },
      icon: {
        description: 'An icon to display.',
        type: String,
      },
      iconPosition: {
        description: 'The icon position.',
        type: String,
        default: 'left',
      },
      iconSpinner: {
        description: 'Is icon spinnning',
        type: Boolean,
        default: false,
      },
      sticker: {
        description: 'A sticker to display.',
        type: [String, Number, Boolean],
      },
      color: {
        description: 'The button color.',
        type: String,
        values: [
          'blue',
          'lightblue',
          'red',
          'green',
          'orange',
          'gray',
          'lightgray',
          'white',
          'focus-blue',
          ...themeColorsList,
        ],
        validator: (val) =>
          [
            'blue',
            'lightblue',
            'red',
            'green',
            'orange',
            'gray',
            'lightgray',
            'white',
            'focus-blue',
            ...themeColorsList,
          ].includes(val),
        default: 'theme-active',
      },
      iconColor: {
        description: 'The icon color to display in the button',
        type: String,
        values: [
          'blue',
          'lightblue',
          'red',
          'green',
          'orange',
          'gray',
          'lightgray',
          'white',
          'focus-blue',
          ...themeColorsList,
        ],
        validator: (val) =>
          [
            'blue',
            'lightblue',
            'red',
            'green',
            'orange',
            'gray',
            'lightgray',
            'white',
            'focus-blue',
            ...themeColorsList,
          ].includes(val),
        default: 'theme-background-main',
      },
      styling: {
        description: 'Style of the button',
        type: String,
        values: ['default', 'block'],
        validator: (val) => ['default', 'block'].includes(val),
        default: 'default',
      },
      disable: {
        description: 'A flag to allow user to click on the button or not.',
        type: Boolean,
        default: false,
      },
      submit: {
        description:
          'If wrapped in a s-form component, will handle the logic of all inputs with required/error statuses in the given s-form.',
        type: String,
      },
      loading: {
        description: 'True if the button text should be replaced by a loading spinner',
        type: Boolean,
        default: false,
      },
    },
    data: function () {
      return {
        isValid: true,
      };
    },
    computed: {
      isDisable: function () {
        return this.disable || !this.isValid;
      },
      formValidStatus: function () {
        if (this.submit) {
          return this.$veasel.formValidStatus[this.submit];
        } else {
          return true;
        }
      },
      iconSize: function () {
        if (this.size === 'large') {
          return 38;
        }
        return 12;
      },
    },
    watch: {
      submit: {
        immediate: true,
        handler: function (submit) {
          this.listenForFormChanges(submit);
          if (submit) {
            if (typeof this.$veasel.formValidUpdate[submit] === 'function') {
              this.$veasel.formValidUpdate[submit]();
            }
          } else {
            this.isValid = true;
          }
        },
      },
      formValidStatus: {
        immediate: true,
        handler: function (newValue) {
          this.isValid = newValue;
        },
      },
    },
    created: function () {
      this.listenForFormChanges(this.submit);
    },
    methods: {
      listenForFormChanges: function (submit) {
        if (!submit) {
          this.isValid = true;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@veasel/core';

  button.s-button {
    cursor: pointer;
    user-select: none;
    position: relative;
    padding: 0 1rem;
    border-radius: $button-radius;

    .button-icon {
      position: absolute;
      pointer-events: none;
      top: 50%;
      transform: translateY(-50%);
    }

    .button-sticker {
      position: relative;
      pointer-events: none;
      background-color: $white;
      padding: 0 0.25rem;
      margin-left: 0.5rem;
      border-radius: 0.5rem;
      @include base-small-bold;
    }

    .button-text {
      display: inline-block;
      pointer-events: none;
      @include base-alt-font;
    }

    .hide {
      visibility: hidden;
    }

    .button-loader {
      &::before {
        content: '';
        position: absolute;
        margin-left: -11px;
        margin-top: -11px;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        border: 4px solid $gray;
        border-top-color: $white;
        -webkit-animation: anim-loading 1s linear infinite;
        animation: anim-loading 1s linear infinite;
      }
    }

    &[disabled] {
      //pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5;
    }

    .button-icon.loading-icon {
      top: calc(50% + rem(1px));
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.medium {
      height: 2rem;
      font-size: 0.75rem;

      &.has-icon {
        &.icon-position-left .button-text {
          padding: 0 0 0 rem(12px);
        }

        &.icon-position-right .button-text {
          padding: 0 rem(12px) 0 0;
        }
      }

      &.has-no-text.has-icon {
        padding: 0 1rem;

        .button-text {
          padding: 0;
        }
      }

      .button-icon {
        &.left {
          left: rem(8px);
        }

        &.right {
          right: rem(8px);
        }
      }
    }

    &.small {
      height: 1.5rem;
      font-size: 0.75rem;
      padding: 0 (get-spacing('s') + get-spacing('xs'));

      .button-text {
        line-height: unset;
      }

      &.has-icon {
        &.icon-position-left .button-text {
          padding: 0 0 0 20px;
        }

        &.icon-position-right .button-text {
          padding: 0 20px 0 0;
        }
      }

      &.has-no-text.has-icon {
        .button-text {
          padding: 0;
        }
      }

      .button-icon {
        font-size: 0.75rem;

        &.left {
          left: 10px;
        }

        &.right {
          right: 10px;
        }
      }

      .button-sticker {
        position: relative;
      }

      .button-loader {
        &::before {
          margin-left: -8px;
          margin-top: -8px;
          height: 7px;
          width: 7px;
        }
      }
    }

    &.large {
      min-width: 60px;
      height: $row-large-height - 2px;
      font-size: 18px;

      &.has-icon {
        &.icon-position-left .button-text {
          padding: rem(0 14px 0 65px);
        }

        &.icon-position-right .button-text {
          padding: rem(0 65px 0 14px);
        }
      }

      &.has-no-text.has-icon .button-text {
        padding: 1px;
      }

      .button-icon {
        &.left {
          left: rem(22px);
        }

        &.right {
          right: rem(22px);
        }
      }
    }

    &.has-no-text .button-icon {
      &.left,
      &.right {
        left: 0;
        right: 0;
      }
    }

    &.loading {
      cursor: default;
      pointer-events: none;
    }

    &.styling-block {
      height: 100%;
      border-radius: 0;
      padding-left: get-spacing('s');
    }
  }
</style>
