<template>
  <div :class="{'input-bool': isBoolean, 'input-align-right': alignRight}" class="input-wrapper">
    <div
      class="s-input s-input-group row-fluid preload relative select-container"
      :class="[
        size,
        $_helperTooltipSelector,
        {['input-label-' + labelMod]: !!label},
        {'input-label-visible': isLabelVisible},
        {'input-disabled': disabled, 'input-error': $_hasErrors, 'input-required': $_required},
        classes,
      ]"
      :title="tooltip || label"
    >
      <label
        v-if="label && labelMod !== 'none' && (!isBoolean || labelMod !== 'inside')"
        ref="inputLabel"
        class="label"
        :class="{visible: isLabelVisible}"
        :style="[labelMod === 'left' || labelMod === 'right' ? {width: labelWidth} : {}]"
        :for="id"
      >
        {{ label }}
      </label>

      <slot ref="slot"></slot>
    </div>
    <s-helper-tooltip :options="$_helperTooltipOptions" :position="helperTooltipPosition" />
  </div>
</template>

<script>
  import sHelperTooltip from '@veasel/inputs/helper-tooltip';
  import {
    id,
    name,
    label,
    size,
    pattern,
    syncValue,
    isDisabled,
    hasErrors,
    isRequired,
    helperMessages,
  } from '@veasel/core/mixins';

  export default {
    name: 's-input-wrapper',
    description: 'An input wrapper component to handle generic customer styling.',

    mixins: [id, name, label, size(), pattern, syncValue, isDisabled, hasErrors, isRequired, helperMessages],

    components: {
      sHelperTooltip,
    },
    emits: ['label-on-top'],
    props: {
      modelValue: {
        default: null,
      },
      class: {
        default: '',
      },
      isBoolean: {
        type: Boolean,
        default: false,
      },
      alignRight: {
        type: Boolean,
        default: false,
      },
      tooltip: {
        type: String,
      },
    },

    computed: {
      classes() {
        return this.class.split(' ');
      },
    },
    data: function () {
      return {
        helperTooltipPosition: 'top',
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import '@veasel/core';

  $input-box-shadow-focus: 0 0 6px #00000029;
  $input-box-shadow-size: 0 0 3px 1px;
  $input-background-color: var(--secondary-light);

  .s-input {
    outline-width: 1px;
    display: flex;
    border-radius: $button-radius;

    ::v-deep(.input-container) {
      display: flex;
      flex-grow: 1;
    }

    // LABEL

    .label {
      @include base-alt;
      z-index: 1;
      text-transform: capitalize;
    }

    &.input-label-none {
      .label {
        display: none;
      }
    }

    &.input-label-left {
      align-items: center;

      .label {
        display: inline-block;
        text-align: left;
        padding-right: 0.5rem;
      }
    }

    &.input-label-right {
      align-items: center;

      .label {
        display: inline-block;
        text-align: right;
        padding-right: 0.5rem;
      }
    }

    &.input-label-top {
      flex-wrap: wrap;

      .label {
        flex: 0 0 100%; /* flex-grow, flex-shrink, flex-basis */
        display: block;
        height: 1.5rem;
      }
    }

    &.input-label-inside {
      margin-top: 0;

      .label {
        pointer-events: none;
        opacity: 0;
        position: absolute;
        margin-left: rem(4px);
        padding-left: rem(4px);
        padding-right: rem(4px);
        margin-top: rem(8px);
        transition: all 0.1s ease-in;
        @include base-small-bold-secondary;

        &.visible {
          margin-top: -0.125rem;
          opacity: 1;
        }
      }

      &.input-label-visible {
        ::v-deep(input),
        ::v-deep(textarea) {
          padding-top: 10px;
        }
      }
    }

    ::v-deep(input),
    ::v-deep(textarea) {
      &::placeholder {
        @include caption-secondary;
      }
    }

    ::v-deep(.s-input-group-field),
    ::v-deep(.fake-input) {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      flex-grow: 1;

      &.select {
        flex-grow: 1;
      }
    }

    // STATE

    &.input-success ::v-deep(.s-input-group-field) {
      box-shadow: $input-box-shadow-size var(--approved);
    }

    &.input-required:not(.input-disabled) ::v-deep(.s-input-group-field) {
      box-shadow: $input-box-shadow-size var(--warning);
    }

    &.input-error ::v-deep(.s-input-group-field) {
      box-shadow: $input-box-shadow-size var(--error);
    }

    &.input-disabled {
      cursor: not-allowed;

      ::v-deep(.s-input-group-field) {
        pointer-events: none;
        background-color: $input-disabled-color;
      }
    }

    ::v-deep(.s-input-group-field) {
      box-sizing: border-box;
      min-height: 2rem;
      text-align: left;
      transition: ease background $transition-time, ease box-shadow $transition-time;
      border: none;
      border-radius: $button-radius;
      box-shadow: 0 0 0 #00000029;
      background-color: $input-background-color;
      @include base;

      &:hover {
        background: var(--background-main);
        -webkit-box-shadow: $input-box-shadow-focus;
        box-shadow: $input-box-shadow-focus;
      }

      &:focus {
        outline: 1px solid transparent;
        background: var(--background-main);
        -webkit-box-shadow: $input-box-shadow-focus;
        box-shadow: $input-box-shadow-focus;
      }
    }

    &.small {
      ::v-deep(.s-input-group-field),
      ::v-deep(.fake-input) {
        min-height: 1.5rem;
        font-size: 14px;
        display: block;
        margin-top: 0 !important;
        @include caption;
      }

      .label {
        @include caption-alt;

        line-height: 0.75rem;
      }

      &.input-label-top .label {
        height: 1rem;
      }

      &.input-label-inside .label {
        display: none;
      }

      &.input-label-visible {
        ::v-deep(input),
        ::v-deep(textarea) {
          padding-top: 0;

          &::placeholder {
            @include caption-secondary;
          }
        }
      }
    }

    :-webkit-autofill {
      /* Expose a hook for JavaScript when auto fill is shown. JavaScript can capture 'animationstart' events */
      animation-name: onAutoFillStart;
    }

    :not(:-webkit-autofill) {
      /* Expose a hook for JS onAutoFillCancel. JavaScript can capture 'animationstart' events */
      animation-name: onAutoFillCancel;
    }
  }

  .input-wrapper.input-bool {
    .input-label-left .label {
      margin-top: 0;
    }

    .input-label-right .label {
      margin-top: 0;
    }

    .s-input {
      align-items: center;
    }
  }

  .input-wrapper.input-bool.input-align-right .s-input {
    flex-direction: row-reverse;

    &:not(.input-label-top) .label {
      order: 9;
    }

    .switch-wrapper {
      order: 0;
    }
  }

  .input-wrapper::v-deep(.input-textarea) {
    min-height: rem(52px);

    &.s-input.input-label-inside {
      overflow: visible;

      .label {
        background: var(--secondary-light);
      }

      &.input-textarea-focused label {
        background: var(--background-main);
      }

      &.input-disabled label {
        background: $input-disabled-color;
      }
    }
  }
</style>
