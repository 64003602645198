export default {
  methods: {
    handlePrecedence() {
      // split operation in groups
      let groups = [...this.elements];
      // nest by groups
      groups = this.replaceParenthesisByNestedArrays(groups);
      // resolve precedence by adding additional groups
      groups = this.resolvePrecedenceForAGroup(groups); // .reduce((acc, g)=>[...acc, ...this.resolvePrecedenceForAGroup(g)], []);
      // flatten groups
      groups = this.replaceNestedArraysByParenthesis(groups);
      this.elements = groups;
    },

    resolvePrecedenceForAGroup(group) {
      // if groups have more than one operator
      const groupOperators = group.filter((e) => e.type === 'operators');
      const operatorsToResolve = [...groupOperators].sortBy('precedence', true);

      for (let i = 0; i < operatorsToResolve.length - 1; i++) {
        const operatorToGroup = operatorsToResolve[i];

        const numberOfOperator = [...new Set(groupOperators.map((o) => o.key))].length;
        if (numberOfOperator > 1) {
          const startsGroupWithParenthesis = groupOperators[0].key === operatorToGroup.key;
          const endsGroupWithParenthesis = groupOperators[groupOperators.length - 1].key === operatorToGroup.key;
          const newGroup = [];
          let inGroup = false;
          // wrap groups in parenthesis
          group.forEach((element, index, array) => {
            if (startsGroupWithParenthesis && index === 0) {
              newGroup.push(this.groupStartObject);
              inGroup = true;
            }
            if (inGroup && element.type === 'operators' && element.key !== operatorToGroup.key) {
              newGroup.push(this.groupEndObject);
              inGroup = false;
            } else if (!inGroup && element.type === 'operators' && element.key === operatorToGroup.key) {
              newGroup.splice(newGroup.length - 1, 0, this.groupStartObject);
              inGroup = true;
            }
            newGroup.push(element);
            if (endsGroupWithParenthesis && index === array.length - 1) {
              newGroup.push(this.groupEndObject);
              inGroup = false;
            }
          });
          if (numberOfOperator >= 2) {
            return this.resolvePrecedenceForAGroup(this.replaceParenthesisByNestedArrays(newGroup));
          } else {
            return newGroup;
          }
        } else {
          return group.map((e) => (e instanceof Array ? this.resolvePrecedenceForAGroup(e) : e));
        }
      }
      return group.map((e) => (e instanceof Array ? this.resolvePrecedenceForAGroup(e) : e));
    },
  },
};
