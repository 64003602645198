<template>
  <transition name="slideY" @enter="enter" @after-enter="afterEnter" @leave="leave">
    <slot />
  </transition>
</template>

<script>
  export default {
    name: 's-transition-slide-y',
    methods: {
      enter(element) {
        element.style.width = getComputedStyle(element).width;
        element.style.position = 'absolute';
        element.style.visibility = 'hidden';
        element.style.height = 'auto';

        const height = getComputedStyle(element).height;

        element.style.width = null;
        element.style.position = null;
        element.style.visibility = null;
        element.style.height = 0;

        // Force repaint to make sure the
        // animation is triggered correctly.
        getComputedStyle(element).height;

        // Trigger the animation.
        // We use `requestAnimationFrame` because we need
        // to make sure the browser has finished
        // painting after setting the `height`
        // to `0` in the line above.
        requestAnimationFrame(() => {
          element.style.height = height;
        });
      },
      afterEnter(element) {
        element.style.height = 'auto';
      },
      leave(element) {
        element.style.height = getComputedStyle(element).height;

        // Force repaint to make sure the
        // animation is triggered correctly.
        getComputedStyle(element).height;

        requestAnimationFrame(() => {
          element.style.height = 0;
        });
      },
    },
  };
</script>

<style lang="scss">
  @import '@veasel/core';

  .slideY-enter-active,
  .slideY-leave-active {
    transition: height $transition-time ease-in-out;
    overflow: hidden;
  }

  .slideY-enter,
  .slideY-leave-to {
    height: 0;
  }
</style>
