export default {
  data: function () {
    return {
      searchText: '',
      throttledSearch: false,
    };
  },

  computed: {
    // rows after search
    searchedRows: function () {
      if (this.opts.frontend && this.searchText.length > 0) {
        const searchedRows = this.rows.searchText(this.searchText);
        this.opts.pagination.num_entries = searchedRows.length;
        return searchedRows;
      } else {
        return this.rows;
      }
    },
  },

  watch: {
    // watch for changes in the searchbar
    searchText: function (value) {
      this.throttledSearch(value);
    },
  },

  methods: {
    // emit a search event
    searchInRows: function (value) {
      this.opts.pagination.offset = 0;
      this.updateQuery();
      this.$emit(this.opts.search.event, value);
    },
  },
};
