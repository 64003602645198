<template>
  <div
    class="s-popup-overlay"
    :class="{show: isOpen}"
    @click.self="options.closeOnClickOutside ? close(true, true) : close(false, true)"
  >
    <div v-if="isOpen" class="s-popup" :class="{show: isOpen}">
      <div v-if="options.icon" class="s-popup-icon">
        <s-animation
          :key="options.icon"
          v-model="animations[options.icon].modelValue"
          :animationData="animations[options.icon].animationData"
          width="50%"
          height="50%"
          :options="animations[options.icon].options"
        />
      </div>
      <header v-if="options.title" class="s-popup-title">
        {{ options.title }}
      </header>
      <section v-if="options.text" class="s-popup-text p-x-l" v-html="options.text"></section>
      <section v-if="options.content" class="s-popup-content p-x-l" v-html="options.content.outerHTML"></section>
      <footer class="s-popup-footer m-x-l m-y-l align-right">
        <s-button
          v-for="(v, k) in options.buttons"
          :key="k"
          class="s-popup-button"
          :text="v.text"
          :on-click="() => close(v.close, v.value)"
          :color="v.className"
        />
      </footer>
    </div>
  </div>
</template>

<script>
  import errorAnimation from '../utils/lottie_error.json';
  import warningAnimation from '../utils/lottie_warning.json';
  import infoAnimation from '../utils/lottie_info.json';
  import successAnimation from '../utils/lottie_success.json';

  import {defineComponent} from 'vue';

  export default defineComponent({
    name: 's-popup',
    data() {
      return {
        animations: {
          error: {
            modelValue: {},
            options: {
              loop: false,
              autoPlay: true,
            },
            animationData: errorAnimation,
          },
          warning: {
            modelValue: {},
            options: {
              loop: false,
              autoPlay: true,
            },
            animationData: warningAnimation,
          },
          info: {
            modelValue: {},
            options: {
              loop: false,
              autoPlay: true,
            },
            animationData: infoAnimation,
          },
          success: {
            modelValue: {},
            options: {
              loop: false,
              autoPlay: true,
            },
            animationData: successAnimation,
          },
        },
        options: {},
        isOpen: false,
        state: {
          promise: {},
        },
        defaultOptions: {
          title: '',
          text: '',
          content: '',
          icon: '',
          buttons: {},
          closeOnClickOutside: true,
          dangerMode: false,
          timer: 0,
        },
        defaultButtonData: {
          text: '',
          value: true,
          visible: true,
          className: 'theme-secondary',
          close: true,
        },
      };
    },
    computed: {
      getDefaultState() {
        return this.defaultOptions;
      },
    },
    methods: {
      getState() {
        return {isOpen: this.isOpen};
      },
      defaultButtonOk() {
        return Object.assign({}, this.defaultButtonData, {
          text: 'Ok',
          value: true,
          visible: true,
          className: 'theme-active s-popup-button--confirm',
          close: true,
        });
      },
      defaultButtonCancel() {
        return Object.assign({}, this.defaultButtonData, {
          text: 'Cancel',
          value: false,
          visible: true,
          className: 'theme-background-secondary s-popup-button--cancel',
          close: true,
        });
      },
      optionsFromStringArgs(args) {
        this.options = Object.assign({}, this.getDefaultState, {text: args[0], title: args[1], icon: args[2]});
        const {buttons} = args[3] ? args[3] : this.options.buttons;
        this.generateButtons(buttons);
      },
      optionsFromObjectArgs(args) {
        const {buttons, text, title, icon, dangerMode, timer, content} = args[0];
        this.options = Object.assign({}, {...this.getDefaultState, content, text, title, icon, dangerMode, timer});
        this.generateButtons(buttons);
      },
      generateButtons(buttons) {
        this.options.buttons = {};
        const okButton = this.defaultButtonOk();
        const cancelButton = this.defaultButtonCancel();
        if (typeof buttons == 'boolean') {
          if (buttons) {
            this.options.buttons[cancelButton.text] = Object.assign({}, cancelButton);
            this.options.buttons[okButton.text] = Object.assign({}, okButton);
          }
        } else if (Array.isArray(buttons)) {
          buttons.forEach((btn, i) => {
            if (i == 0) {
              this.options.buttons[btn] = Object.assign({}, {...this.defaultButtonData, ...cancelButton}, {text: btn});
            }
            if (i == 1) {
              this.options.buttons[btn] = Object.assign({}, {...this.defaultButtonData, ...okButton}, {text: btn});
            }
          });
        } else if (typeof buttons == 'object') {
          Object.entries(buttons).forEach(([key, value], i) => {
            if (i > 2) {
              return;
            }
            if (typeof value == 'boolean' && value) {
              this.options.buttons[okButton.text] = Object.assign({}, okButton);
            } else if (this.options.dangerMode && i == 2) {
              this.options.buttons[key] = Object.assign({}, this.defaultButton, {
                ...value,
                className: `${okButton.className.replace('theme-active', 'theme-error')}`,
              });
            } else {
              this.options.buttons[key] = Object.assign({}, this.defaultButton, value);
            }
          });
        } else {
          this.options.buttons[okButton.text] = Object.assign({}, okButton);
        }
      },
      generateOptions(args) {
        const argsAreString = args.find((e, i) => {
          if (typeof e == 'string' && i < 3) {
            return e;
          }
        });

        if (args[0] && argsAreString) {
          this.optionsFromStringArgs(args);
        } else if (typeof args[0] == 'object') {
          this.optionsFromObjectArgs(args);
        } else {
          const errorMsg = 'Invalid Format of Popup';
          console.log(errorMsg);
          return;
        }
        if (this.options.timer > 0) {
          const timeoutPopup = setTimeout(() => {
            this.close(true, null);
            clearTimeout(timeoutPopup);
          }, this.options.timer);
        }
      },
      show(...args) {
        this.generateOptions(args);
        this.isOpen = true;
        return new Promise((resolve, reject) => {
          this.state.promise = {resolve, reject};
        });
      },
      close(close = true, value = true) {
        if (close) {
          this.isOpen = false;
        }
        this.state.promise.resolve(value);
      },
    },
  });
</script>

<style lang="scss" scoped>
  @import '@veasel/core';

  @keyframes scaleing {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    1% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }

    45% {
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
    }

    80% {
      -webkit-transform: scale(0.95);
      transform: scale(0.95);
    }

    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  .s-popup {
    -webkit-animation: scaleing 0.3s;
    animation: scaleing 0.3s;
    width: 478px;
    opacity: 0;
    background-color: $white;
    text-align: center;
    border-radius: 0;
    position: static;
    margin: 20px auto;
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    z-index: 94;
    transition: opacity 0.2s, -webkit-transform 0.3s;
    transition: transform 0.3s, opacity 0.2s, -webkit-transform 0.3s;
    font-size: initial;

    &-icon {
      padding: 0;
      position: relative;
      box-sizing: content-box;
      margin: 20px auto;
      margin-top: 20px;
    }

    &-title {
      color: rgba($black, 0.65);
      font-weight: 600;
      text-transform: none;
      position: relative;
      display: block;
      margin-top: 25px;
      padding: 0 16px;
      font-size: 27px;
      line-height: normal;
      text-align: center;
    }

    &-content,
    &-text {
      font-size: 16px;
      position: relative;
      line-height: normal;
      vertical-align: top;
      text-align: justify;
      margin-top: 25px;
      display: inline-block;
      font-weight: 400;
      color: rgba($black, 0.64);
      overflow-wrap: break-word;
      box-sizing: border-box;
    }

    &-button {
      margin-left: 1em;
    }

    &-overlay {
      opacity: 0;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
      font-size: 0;
      overflow-y: auto;
      background-color: rgba($black, 0.4);
      z-index: 10000;
      pointer-events: none;
      transition: opacity 0.3s;

      &::before {
        content: ' ';
        display: inline-block;
        vertical-align: middle;
        height: 100%;
      }
    }
  }

  .show {
    opacity: 1;
    pointer-events: auto;
  }
</style>
