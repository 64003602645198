/**
 * Allow a component to call a specific function or emit an event when value change
 * @param {boolean} required
 * @return {object}
 */
const onChange = function (required = false) {
  return {
    props: {
      onChange: {
        description: 'Function called or event emitted on value change',
        type: [Function, String],
        required: required,
      },
    },
    methods: {
      $_onChange: function (event) {
        if (typeof this.onChange == 'function') {
          this.onChange(event);
        } else if (typeof this.onChange == 'string') {
          this.$el.dispatchEvent(new Event(this.onChange));
        } else if (required) {
          console.error(this.$options.name + ' requires onChange prop');
        }
      },
    },
  };
};

export default onChange;
