import {STime} from '@veasel/core/time';

export default {
  computed: {
    day: function () {
      this.emitDayRangeEvent();
      return {
        date: this.internalDatetimeFocused.clone(),
        isInCurrentMonth: this.internalDatetimeFocused.month === this.currentMonth,
        isInWeekend: [5, 6].includes(this.internalDatetimeFocused.getISODayOfWeek()),
        isToday: this.internalDatetimeFocused.format('dateNumeric') === new STime().format('dateNumeric'),
      };
    },
  },
};
