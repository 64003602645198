// TODO: const are useful?
// roles of the entered value into the query
export const Roles = {
  GROUPED_COLUMN: 'grouped_column', // none key prop in query string used to group columns for aggregation
  FIELD: 'field',
  OPERATOR: 'operator',
  AGGREGATION_FUN: 'aggregation_fun',
  VALUE: 'value',
};

// field types used by the user to enter the query
export const Fields = {
  SELECT: 'select',
  TEXT: 'text',
  NUMBER: 'number',
  DATE: 'date',
};

// INPUT PLACEHOLDERS FOR EACH TYPE
export const Placeholders = {
  COLUMN: 'Column',
  FILTER: 'Filter',
  NUMBER: '123',
  DATE: 'DD-MM-YYYY',
  MULTIPLE_STRING: 'value1, value2',
  ARRAY: 'value1, value2',
  MULTIPLE_NUMBER: '123, 456',
  MULTIPLE_DATE: 'DD-MM-YYYY, DD-MM-YYYY',
  NONE: '',
};

// HINT MESSAGES FOR EACH SITUATION
export const HintMessages = {
  FILTER_COLUMNS: 'Select a column to filter on',
  FILTER_FILTER: 'Select a filter type',
  FILTER_VALUE: {
    MANY_STRING: 'Enter one or more strings (comma separated value, enter to validate)',
    MANY_INTEGER: 'Enter one or more integers (comma separated, enter to validate)',
    ONE_INTEGER: 'Enter one integer',
    TWO_INTEGER: 'Enter two integers (comma separated, enter to validate)',
    MANY_FLOAT: 'Enter one or more floats (comma separated, enter to validate)',
    ONE_FLOAT: 'Enter one float',
    TWO_FLOAT: 'Enter two floats (comma separated, enter to validate)',
    ONE_DATE: 'Enter one date',
    TWO_DATE: 'Enter two dates (comma separated, enter to validate)',
  },
  GROUP_COLUMN: 'Select a columns to group by',
  AGG_COLUMN: 'Select a column to aggregate on',
  AGG_TYPE: 'Select an aggregation type',
};
