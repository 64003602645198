<template>
  <div :id="id" :name="name" class="s-box flex align-items-center" :class="size" :style="{backgroundColor: boxColor}">
    <s-icon :size="determineSize(size)" :type="boxIcon" color="theme-background-main" class="box-icon" />
    <div class="box-content"><slot /></div>
  </div>
</template>

<script>
  import {id, name, size} from '@veasel/core/mixins';
  import icon from '@veasel/base/icon';

  export default {
    name: 's-box',
    description: 'A basic box wrapper for user messages.',
    components: {
      's-icon': icon,
    },
    mixins: [id, name, size(['small', 'medium', 'large'])],
    props: {
      type: {
        description: 'The message type.',
        type: String,
        values: ['info', 'question', 'warning', 'error', 'success'],
        validator: (val) => ['info', 'question', 'warning', 'error', 'success'].includes(val),
        default: 'info',
      },
    },
    slots: {
      default: 'Content of the box',
    },
    computed: {
      boxIcon: function () {
        switch (this.type) {
          case 'info-circle':
            return 'info-circle';
          case 'question':
            return 'help';
          case 'warning':
            return 'warning1';
          case 'error':
            return 'cross-circle';
          case 'success':
            return 'tick';
          default:
            return 'info-circle';
        }
      },
      boxColor: function () {
        let cssVar = '';
        switch (this.type) {
          case 'question':
            cssVar = '--secondary-50';
            break;
          case 'info':
            cssVar = '--label-1';
            break;
          case 'warning':
            cssVar = '--warning';
            break;
          case 'error':
            cssVar = '--error';
            break;
          case 'success':
            cssVar = '--approved';
            break;
          default:
            cssVar = '--label-1';
            break;
        }
        return 'rgba(var(' + cssVar + '-rgb),1)';
      },
    },
    methods: {
      determineSize(value) {
        switch (value) {
          case 'large':
            return 30;
          case 'medium':
            return 20;
          case 'small':
            return 15;
          default:
            return 20;
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@veasel/core';
  .s-box {
    position: relative;
    padding: get-spacing('xs') get-spacing('xs');
    border-radius: $border-radius;
    @include base;

    color: var(--background-main);

    ::v-deep(a) {
      color: var(--background-main);
      text-decoration: underline;
      cursor: pointer;
    }

    ::v-deep(a:hover) {
      text-decoration: none;
    }

    .box-icon {
      line-height: 0.8rem;
      margin: get-spacing('s');

      ::v-deep(.s-icon) {
        pointer-events: none;
      }
    }
  }

  .large.s-box {
    padding: get-spacing('s');

    .box-icon {
      margin-right: get-spacing('m');
      margin-left: get-spacing('s');
    }
  }

  .small.s-box {
    padding: 0.125rem;

    .box-icon {
      margin-left: get-spacing('s');
      margin-right: get-spacing('s');
    }
  }
</style>
