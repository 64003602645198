import {allMatchingParents} from '@veasel/core/tools';

export default {
  props: {
    required: {
      description: 'A flag to make the input required',
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      $_isMissing: false,
      formIds: [],
    };
  },
  mounted: function () {
    this.formIds = allMatchingParents(this.$el, '.s-form').map((node) => node.id);
  },
  computed: {
    _isEmpty() {
      const value = this.modelValue;
      const hasValue =
        value !== undefined &&
        value !== '' &&
        value !== null &&
        (typeof value === 'string' ||
          typeof value === 'number' ||
          value.isUtc ||
          (value instanceof Array && value.length && value.length > 0) ||
          (value instanceof Object && !Array.isArray(value)));
      return !hasValue;
    },
    $_required() {
      if (!this.required) {
        return false;
      }
      return this._isEmpty;
    },
  },
  methods: {
    $_requireValidate: function () {
      if (this.formIds.length) {
        for (const i in this.formIds) {
          this.$veasel.formValidUpdate[this.formIds[i]]();
        }
      }
    },
  },
};
