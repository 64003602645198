import {createRouter, createWebHistory} from 'vue-router';
import routes from './config/routes';

// In order to check permissions in the navigation guards, need access to the user permissions and features (store)
import store from './store';
// and the permissions checking function ()
import {CheckPermissionKey} from '@/services/permissions';
import API from '@/services/api';

// Modules routes

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes,
});

router.beforeEach(async (to, _from, next) => {
  /**
   * We need feature flags to know what we can and can't show/load on the system.
   * By waiting for the request (if it hasn't already been done) it ensures we have all
   * the required info before we take any action around this
   *
   * We wrap this in a try/catch so then if we aren't logined in, or there is an issue, this isn't a show stopper and previously written
   * code for handling login and forbidden issues are handled
   **/
  try {
    await API.getFeatureFlags();
  } catch (e) {
    console.warn(e);
  }
  const path = to.matched.length ? to.matched[to.matched.length - 1].path : '';

  if (
    to.path !== '/login' &&
    (!store.state.user.isAuth || !store.state.user.pass_changed || store.getters['user/needsMFASetup'])
  ) {
    // If the user is not logged in or has not changed their password, redirect to the login page
    next({path: '/login', query: {last_route: to.path, ...to.query}});
  } else if (
    to.path === '/login' &&
    store.state.user.isAuth &&
    store.state.user.pass_changed &&
    !store.getters['user/needsMFASetup']
  ) {
    // If the user is already logged in and has changed their password, redirect away from the login page
    next('/home');
  } else if (to.path === '/login') {
    next();
  } else {
    let perm = true;

    // If the route requires a specific service
    if (to.meta.service && store.state.user.features) {
      perm = CheckPermissionKey(
        store.state.user.features.concat(store.state.system.feature_flags),
        'by_service',
        to.meta.service,
        true
      );
    }

    // Always check page permissions
    if (path && store.state.user.permissions) {
      perm =
        perm &&
        (CheckPermissionKey(store.state.user.permissions, 'by_page', path, false) ||
          CheckPermissionKey(
            store.state.user.features.concat(store.state.system.feature_flags),
            'by_page',
            path,
            false
          ));
    }

    if (!perm) {
      next(to.meta.redirect || '/home');
    } else {
      next();
    }
  }
});

export default router;
