<template>
  <div class="s-xhr-loader" :style="'width: calc( 100% - ' + widthReduction + 'px )'">
    <div ref="bar" class="loader-bar" :style="{width: progress + '%'}"></div>
  </div>
</template>

<script>
  const PROGRESS_TABLE = [100, 67, 45, 30, 20, 13, 9, 6, 4];

  export default {
    name: 's-xhr-loader',
    description:
      'An automatic and autonomous loading bar on the top of the screen to notify the user from the ongoing network traffic.',
    data: function () {
      return {
        progress: 0,
      };
    },
    props: {
      widthReduction: {
        type: Number,
        default: 0,
      },
    },
    created: function () {
      // Expose the start & end request functions to window so the Tools.js functions has access to it
      window.$slXHRLoader = {
        startRequest: this.startRequest,
        endRequest: this.endRequest,
      };
    },
    methods: {
      startRequest: function (nb) {
        this.$refs.bar.style.transition = '0.8s';
        this.progress = (PROGRESS_TABLE[nb] || 2) / 2;
      },
      endRequest: function (nb) {
        this.progress = PROGRESS_TABLE[nb] || 3;
        if (nb === 0) {
          this.progress = 100;
          setTimeout(() => {
            this.$refs.bar.style.transition = '0s';
            this.progress = 0;
          }, 800);
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@veasel/core/sass/conf';

  .s-xhr-loader {
    display: block;
    width: 100%;
    height: 10px;
    position: fixed;
    pointer-events: none;
    z-index: 49;
    opacity: 0.8;

    .loader-bar {
      position: absolute;
      display: block;
      height: 10px;
      transition: 0.8s;
      transition-timing-function: ease-out;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeHolderShimmer;
      animation-timing-function: linear;
      background: linear-gradient(to right, $gray 10%, $black 40%, $gray 60%);
      background-size: 1000px 10px;
    }

    @keyframes placeHolderShimmer {
      0% {
        background-position: -468px 0;
      }

      100% {
        background-position: 468px 0;
      }
    }
  }
</style>
