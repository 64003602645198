import {getValueAtPath, setValueAtPath} from '@veasel/core/tools';

export default {
  data: function () {
    return {
      cellToEdit: {},
      isEditing: false,
    };
  },
  emits: ['update:rows'],

  methods: {
    // edit a table cell
    editCell: function (row, col, rowIndex) {
      this.releaseSelection();
      let input;
      if (typeof col.editable === 'object' && col.editable.type) {
        input = {...col.editable};
      } else if (typeof col.editable === 'function') {
        input = col.editable(row);
      } else {
        input = {type: 'text', options: {}};
      }
      if (input.schema) {
        if (input.schema instanceof Array) {
          input.schema = input.schema.map((nestedInput) =>
            typeof nestedInput === 'function' ? nestedInput(row) : nestedInput
          );
        }
      }
      this.cellToEdit = {
        value: getValueAtPath(row, col.data),
        input: input || 'text',
        col: col,
        row: row,
        uniqId: this.opts.uniqId || 'id',
        rowIndex: rowIndex,
      };
      this.isEditing = true;
    },
    saveCell: function (value) {
      this.isEditing = false;
      const rows = this.rows.slice();
      setValueAtPath(rows[this.cellToEdit.rowIndex], this.cellToEdit.col?.data, value);
      this.$emit('update:rows', rows);
      this.cellToEdit = {};
    },
  },
};
