// Logic for converting a set of 'rules', as created by a query builder or search input, into an SQL query.

const SQL = {
  renderSQL: function (rules) {
    let sql = '';

    if (rules.length > 0) {
      rules.forEach((rule) => {
        sql += SQL.renderSQLRule(rule);
      });
    }

    return sql;
  },

  renderSQLRule: function (rule) {
    let sql = '';

    if (rule.rules) {
      // This rule contains nested rules, concatenate these into an SQL string recursively
      const joined =
        rule.rules
          .map(SQL.renderSQLRule)
          .filter((s) => s != '')
          .join(' ' + rule.condition + ' ') || '';

      if (joined === '') {
        return sql;
      }

      // Wrap in parenthesis
      sql = '( ' + joined + ' )';

      // Negate if necessary
      if (rule.not === true) {
        sql = '(NOT ' + sql + ')';
      }
    } else {
      // This rule is a single term (field, operator, value)
      sql = SQL.renderSQLTerm(rule);
    }

    return sql;
  },

  // Each term has field and condition, and optionally a value or array of values
  renderSQLTerm: function (term) {
    switch (term.operator) {
      case 'in':
        if (term.value.length === 1) {
          return term.field + ' = ' + SQL.parseSQLType(term.value[0]);
        } else if (term.value.length > 0) {
          return term.field + ' IN(' + term.value.map(SQL.parseSQLType).join(', ') + ')';
        }
        break;

      case 'not_in':
        if (term.value.length === 1) {
          return term.field + ' != ' + SQL.parseSQLType(term.value[0]);
        } else if (term.value.length > 0) {
          return term.field + ' NOT IN(' + term.value.map(SQL.parseSQLType).join(', ') + ')';
        }
        break;

      case 'equal':
        return term.field + ' = ' + SQL.parseSQLType(term.value);

      case 'not_equal':
        return term.field + ' = ' + SQL.parseSQLType(term.value);

      case 'greater':
        return term.field + ' > ' + SQL.parseSQLType(term.value);

      case 'greater_or_equal':
        return term.field + ' >= ' + SQL.parseSQLType(term.value);

      case 'less':
        return term.field + ' < ' + SQL.parseSQLType(term.value);

      case 'less_or_equal':
        return term.field + ' <= ' + SQL.parseSQLType(term.value);

      case 'is_null':
        return term.field + ' IS NULL';

      case 'is_not_null':
        return term.field + ' IS NOT NULL';

      case 'is_false':
        return term.field + ' IS FALSE';

      case 'is_true':
        return term.field + ' IS TRUE';

      case 'between':
        return term.field + ' BETWEEN ' + SQL.parseSQLType(term.value[0]) + ' AND ' + SQL.parseSQLType(term.value[1]);
    }

    console.warn('COULD NOT PARSE SQL', term.field, term.operator, term.value);

    return '';
  },

  // Strings in SQL queries need to be wrapped in single quotes
  parseSQLType: function (val) {
    if (typeof val === 'string') {
      return "'" + val + "'";
    } else {
      return val;
    }
  },
};

export default SQL.renderSQL;
