export default {
  name: 'dataCollection',
  namespaced: true,

  state: {
    currentCollectionId: undefined,
    exchangeRateViewId: undefined,
    collectionViews: [],
    exchangeCurrencies: [],
    dq: {},
  },

  getters: {
    currentCollectionId: (state) => {
      return state.currentCollectionId;
    },

    exchangeRateViewId: (state) => {
      return state.exchangeRateViewId;
    },

    collectionViews: (state) => {
      return state.collectionViews;
    },

    getViewById: (state) => (viewId) => {
      return state.collectionViews.find((view) => view.id === viewId);
    },

    exchangeCurrencies: (state) => {
      return state.exchangeCurrencies;
    },
  },

  mutations: {
    setCurrentCollectionId: function (state, id) {
      state.currentCollectionId = id;
    },

    setExchangeRateViewId: function (state, id) {
      state.exchangeRateViewId = id;
    },

    setCollectionViews: function (state, views) {
      state.collectionViews = views;
    },

    setExchangeCurrencies: function (state, currencies) {
      state.exchangeCurrencies = currencies;
    },

    setCollectionDQ(state, data) {
      state.dq[data.collection_id] = data;
    },

    resetCollectionDQ(state, data) {
      delete state.dq[data.collection_id];
    },

    resetCollection: function (state) {
      state.currentCollectionId = undefined;
      state.exchangeRateViewId = undefined;
      state.exchangeCurrencies = [];
      state.collectionViews = [];
    },
  },

  actions: {
    setCurrentCollectionId: function (context, id) {
      context.commit('setCurrentCollectionId', id);
    },

    setExchangeRateViewId: function (context, id) {
      context.commit('setExchangeRateViewId', id);
    },

    setCollectionViews: function (context, views) {
      context.commit('setCollectionViews', views);
    },

    setExchangeCurrencies: function (context, currencies) {
      if (currencies) {
        context.commit('setExchangeCurrencies', currencies);
      }
    },

    setCollectionDQ(context, data) {
      context.commit('setCollectionDQ', data);
    },

    resetCollectionDQ(context, data) {
      context.commit('resetCollectionDQ', data);
    },

    resetCollection: function (context) {
      context.commit('resetCollection');
    },
  },
};
