import {STime} from '@veasel/core/time';

export default {
  computed: {
    weekDays: function () {
      const weekDays = [];

      const day = this.firstWeekDay.clone();

      while (day.isBefore(this.lastWeekDay)) {
        weekDays.push({
          date: day.clone(),
          isInCurrentMonth: day.month === this.currentMonth,
          isInWeekend: [5, 6].includes(day.getISODayOfWeek()),
          isToday: day.format('dateNumeric') === new STime().format('dateNumeric'),
        });
        day.add(1, 'day');
      }

      this.emitDayRangeEvent();
      return weekDays;
    },

    weekMonth: function () {
      return this.weekDays[3].date.format('month-short');
    },

    weekYear: function () {
      return this.weekDays[3].date.year;
    },

    weekNumber: function () {
      return this.weekDays[3].date.getISOWeekOfYear();
    },

    // first day of the week
    firstWeekDay: function () {
      // isoWeek = Sunday is the last day of week
      return this.internalDatetimeFocused.startOf('week');
    },

    // last day of the week
    lastWeekDay: function () {
      // isoWeek = Sunday is the last day of week
      return this.internalDatetimeFocused.endOf('week');
    },
  },

  methods: {
    switchToDailyView: function (date) {
      this.internalDatetimeFocused = date;
      this.internalView = 'daily';
    },
  },
};
