<template>
  <span :class="['pipe-spacer-wrapper', 'm-x-s', size]">
    <span class="pipe-spacer-inner"></span>
  </span>
</template>

<script>
  import {size} from '@veasel/core/mixins'; // used by input-file with input-text mode

  export default {
    name: 's-pipe-spacer',
    description:
      'A spacer with margin included. Great for separating text properties. With the medium size, it sets the height of the pipe from the font size of the parent element, so in that situation, it should set the height automatically.',
    mixins: [size(['small', 'medium', 'large'])],
  };
</script>

<style lang="scss" scoped>
  @import '@veasel/core';
  .pipe-spacer-wrapper {
    $width: get-spacing('s') + get-spacing('s') + 1px;

    width: rem($width);
    position: relative;

    &.small {
      @include base-small-font;
    }

    &.large {
      font-size: 18px;
    }
  }

  .pipe-spacer-inner {
    background: var(--secondary-50);
    width: rem(1px);
    height: calc(1em - 2px); // Height of these are 2px less than the font size of the parent
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
</style>
