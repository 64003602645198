<template>
  <div :id="id" :name="name" class="s-tabs relative" :class="'style-' + tabStyle">
    <div ref="scrollContainer" class="scroll-container" v-on:scroll="showArrowsIfRequired()">
      <div ref="leftScrollArrow" class="tab-scroll-arrow" @click="leftArrowScroll()">
        <s-icon :type="'arrow-drop-left'" :color="'gray'" />
      </div>
      <div
        v-for="opt in options"
        :key="opt.key"
        class="s-tabs-tab"
        :class="{active: opt.key === $_value, disabled: opt.disabled}"
      >
        <a
          :class="['tab-option', 'tab-option-' + opt.key, {active: opt.key === $_value, disabled: opt.disabled}]"
          @click="changeTab(opt.key)"
        >
          {{ opt.label }}
        </a>
      </div>
      <div ref="rightScrollArrow" class="tab-scroll-arrow" @click="rightArrowScroll()">
        <s-icon :type="'arrow-drop-right'" :color="'gray'" />
      </div>
    </div>
  </div>
</template>

<script>
  import {id, name, smoothScroll, syncValue} from '@veasel/core/mixins';
  import icon from '@veasel/base/icon';

  export default {
    name: 's-tabs',

    components: {
      's-icon': icon,
    },

    description: 'A simple tabs component.',

    mixins: [id, name, smoothScroll, syncValue(String)],

    emits: ['tab-changed'],

    props: {
      options: {
        description: 'The list of available tabs, value, labels, and states.',
        type: Array,
      },
      onTabChange: {
        description: 'The function triggered when the current tab is updated',
        type: Function,
      },
      tabStyle: {
        description: 'The tabs visual style.',
        type: String,
        default: 'secondary',
        values: ['primary', 'secondary', 'centered'],
        validator: (s) => ['primary', 'secondary', 'centered'].includes(s),
      },
    },

    methods: {
      changeTab(tabId) {
        this.$_value = tabId;
        this.$emit('tab-changed', tabId);

        if (this.onTabChange && typeof this.onTabChange === 'function') {
          this.onTabChange(tabId);
        }

        // If the new tab is not in view, it needs to be put in view
        const scrollContainer = this.$refs.scrollContainer;
        const newTab = scrollContainer.querySelector('.tab-option-' + tabId);
        const isLeftArrowVisible = window.getComputedStyle(this.$refs.leftScrollArrow).getPropertyValue('opacity') > 0;
        const isRightArrowVisible =
          window.getComputedStyle(this.$refs.rightScrollArrow).getPropertyValue('opacity') > 0;
        const leftArrowWidth = isLeftArrowVisible ? this.$refs.leftScrollArrow.clientWidth : 0;
        const rightArrowWidth = isRightArrowVisible ? this.$refs.rightScrollArrow.clientWidth : 0;
        const tabPadding = this.tabStyle === 'flat' ? 10 : 0; // Flat tab design has extra padding

        // Scroll to the right
        if (
          newTab.offsetLeft + newTab.clientWidth + newTab.clientLeft + rightArrowWidth >
          scrollContainer.scrollLeft + scrollContainer.offsetWidth
        ) {
          const newScrollLeft =
            newTab.offsetLeft + newTab.clientWidth - scrollContainer.offsetWidth + rightArrowWidth + tabPadding + 2;
          this.smoothScroll(scrollContainer, 'scrollLeft', newScrollLeft, 300);
        } else if (newTab.offsetLeft < scrollContainer.scrollLeft + leftArrowWidth) {
          // Scroll to the left
          const newValue = newTab.offsetLeft - leftArrowWidth - tabPadding;
          this.smoothScroll(scrollContainer, 'scrollLeft', newValue, 300);
        }
      },
      getTabOptionClass: (option) => 'tab-option-' + option,
      // Check and see if we need to display arrows on the tabs
      showArrowsIfRequired: function () {
        const scrollContainer = this.$refs.scrollContainer;
        if (scrollContainer.scrollLeft > 0) {
          this.$refs.leftScrollArrow.classList.add('tab-scroll-arrow__active');
        } else {
          this.$refs.leftScrollArrow.classList.remove('tab-scroll-arrow__active');
        }
        if (scrollContainer.scrollLeft < scrollContainer.scrollWidth - scrollContainer.offsetWidth) {
          this.$refs.rightScrollArrow.classList.add('tab-scroll-arrow__active');
        } else {
          this.$refs.rightScrollArrow.classList.remove('tab-scroll-arrow__active');
        }
      },
      leftArrowScroll: function () {
        const scrollContainer = this.$refs.scrollContainer;
        const arrowWidth = this.$refs.leftScrollArrow.clientWidth;
        const tabToScrollTo = [].slice.call(scrollContainer.querySelectorAll('.s-tabs-tab')).filter(function (tab) {
          // Is the end of the element located on the right side of the left arrow
          if (tab.offsetLeft + tab.clientWidth >= scrollContainer.scrollLeft + arrowWidth - 5) {
            return true;
          }
        });
        this.smoothScroll(scrollContainer, 'scrollLeft', tabToScrollTo[0].offsetLeft - arrowWidth, 300);
      },
      rightArrowScroll: function () {
        const scrollContainer = this.$refs.scrollContainer;
        const arrowWidth = this.$refs.rightScrollArrow.clientWidth;
        const scrollContainerRightPoint = scrollContainer.scrollLeft + scrollContainer.offsetWidth;
        const tabToScrollTo = [].slice.call(scrollContainer.querySelectorAll('.s-tabs-tab')).filter(function (tab) {
          // Is the end of the element located on the right side of the right arrow
          if (tab.offsetLeft + tab.clientWidth >= scrollContainerRightPoint - arrowWidth + 5) {
            return true;
          }
        });
        const newValue =
          tabToScrollTo[0].offsetLeft + tabToScrollTo[0].clientWidth - scrollContainer.offsetWidth + arrowWidth;
        this.smoothScroll(scrollContainer, 'scrollLeft', newValue, 300);
      },
    },

    mounted() {
      const scrollContainer = this.$refs.scrollContainer;
      // Add a scroll listener to convert vertical scroll to horizontal scroll
      scrollContainer.addEventListener(
        'wheel',
        (e) => {
          if (e.deltaY > 0) {
            scrollContainer.scrollLeft += 100;
          } else {
            scrollContainer.scrollLeft -= 100;
          }
          e.preventDefault(); // Prevents the page scrolling as well
        },
        {passive: false}
      );

      // Check if the amount of tabs require that arrows be shown
      this.showArrowsIfRequired();
    },
  };
</script>

<style lang="scss">
  @import '@veasel/core';

  .s-tabs {
    scrollbar-color: $gray $lightgray;
    scrollbar-width: thin;

    ::-webkit-scrollbar {
      height: 4px;
      background-color: $lightgray;
    }

    ::-webkit-scrollbar-thumb {
      background-color: $gray;
    }

    * {
      -webkit-box-sizing: initial;
      box-sizing: initial;
    }

    .scroll-container {
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;

      .tab-scroll-arrow,
      .s-tabs-tab {
        @include base();

        height: 40px;
        margin-right: 2px;
        padding: 0;
        display: inline-block;

        a {
          cursor: pointer;
          color: $tabs-inactive-text-color;
          border: 1px solid $tabs-border-color;
          border-bottom-color: $tabs-border-color;
          background-color: $tabs-inactive-background-color;
          padding: 9px 16px;
          display: block;
          bottom: 0;
          height: 21px;

          &:hover {
            background-color: $gray-darker;
            color: $tabs-active-background-color;
          }

          &.active {
            background-color: $tabs-active-background-color;
            border: 1px solid $tabs-border-color;
            border-bottom: 0 solid transparent;
            color: $tabs-active-text-color;
            height: 22px;
            pointer-events: none;
          }

          &.disabled:not(.active) {
            //background-color: $tabs-disabled-background-color;
            //border: 1px solid $tabs-border-color;
            border-bottom: 0 solid transparent;
            color: $tabs-disabled-text-color;
            pointer-events: none;
          }
        }
      }

      .tab-scroll-arrow {
        position: absolute;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 2;
        width: 20px;
        font-size: 20px;
        opacity: 1;
        background: #d6d6d6;
        transition: ease opacity 0.2s;

        &:not(.tab-scroll-arrow__active) {
          opacity: 0;
          pointer-events: none;
        }

        &:not(:first-child) {
          // Right Arrow
          right: -2px;
        }

        &:hover {
          background-color: #545454;

          .svg-icon svg {
            fill: #f9f9f9;
          }
        }
      }
    }
  }

  .s-tabs.style-primary {
    .scroll-container {
      .s-tabs-tab {
        height: 56px;
        margin: 0;

        .tab-option {
          @include caption-alt;

          display: flex;
          align-items: center;
          background-color: inherit;
          box-sizing: border-box;
          border-bottom: 1px solid var(--background-secondary);
          height: 100%;
          padding: 0 rem(32px);
          transition: background-color $transition-time ease, color $transition-time ease;

          &:hover:not(.disabled):not(.active) {
            background-color: var(--light-hover);
            border-color: var(--light-hover);
            border-bottom-color: var(--hover);
          }

          &.active {
            @include caption-alt-background-main;

            background-color: var(--active);
            border-color: var(--active);
            border-bottom-color: var(--hover);
          }

          &.disabled:not(.active) {
            @include caption-secondary-50;
          }
        }
      }
    }
  }

  .s-tabs.style-secondary,
  .s-tabs.style-centered {
    padding-left: rem(10px);

    &::before {
      content: '';
      width: 100%;
      height: 1px;
      display: block;
      background: var(--secondary-50);
      bottom: 0;
      z-index: 1;
      left: 0;
      position: absolute;
    }

    box-sizing: border-box;

    .scroll-container {
      padding-top: 1px;

      .s-tabs-tab {
        // Reset some styles from the default view
        height: auto;
        margin: 0;
        position: relative;
        z-index: 1;

        &::after {
          content: '';
          width: 100%;
          height: 1px;
          display: block;
          background: transparent;
          bottom: 0;
          z-index: 500000;
          left: 0;
          position: absolute;
        }

        &.active::after {
          background: var(--active);
        }

        a {
          @include base-alt-font;

          font-size: rem(12px);
          color: var(--active);
          background: transparent;
          border: 0;
          position: relative;
          padding: rem(14px) rem(27px);

          &:hover {
            color: var(--hover);
          }
        }

        .tab-option {
          &.active {
            @include base-alt-font;

            color: var(--main-dark);
            background: transparent;
          }

          &.disabled {
            color: var(--secondary);
          }
        }
      }

      .tab-scroll-arrow {
        border: 1px solid #000;
        box-sizing: border-box;
        width: 22px;
        top: 1px;
        height: 41px;
      }
    }
  }

  .s-tabs.style-centered {
    padding-left: 0;

    &::before {
      display: none;
    }

    .scroll-container {
      text-align: center;
    }
  }
</style>
