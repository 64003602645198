<template>
  <div v-if="display && options.inputSelector" class="sl-helper-tooltip">
    <s-tooltip
      ref="tooltip"
      :force-visible="options.forceVisibilty"
      :target="options.inputSelector"
      color="white"
      :position="options.position"
    >
      <div v-for="validationRule in validationRules" :key="validationRule.message">
        <s-icon v-bind="getIconConfig(validationRule)" />
        <span v-html="' ' + validationRule.message"></span>
      </div>
    </s-tooltip>
  </div>
</template>

<script>
  // rule default messages overidable in 'helper-messages' input prop. eg: { [rule]: 'my override message' }
  const defaultMessages = {
    pattern: {
      email: 'must be an email',
      url: 'must be a URL',
      ip: 'must be an IP address',
      html: 'must be HTML',
      number: 'must be a number',
      float: 'must be a float',
      float2d: 'must be a decimal number with a maximum of 2 decimals',
      integer: 'must be an integer',
      string: 'must be a string',
      noSpecials: 'must not contain special characters',
      json: 'must be JSON',
      hex: 'must be hexadecimal color format',
      rgba: 'must be RGBA color format',
    },
    min: (min) => 'select at least ' + min + ' options',
    max: (max) => 'select no more than ' + max + ' options',
    acceptedFiles: (acceptedFiles) => 'must be a ' + acceptedFiles + ' file',
    maxFileSize: (maxFileSize) => 'file size must be less than ' + maxFileSize + ' MB',
  };

  import tooltip from '@veasel/base/tooltip';

  export default {
    name: 's-helper-tooltip',

    description: 'A Tooltip to describe an input format and requirements.',

    components: {
      's-tooltip': tooltip,
    },

    props: {
      options: {
        description: 'A option object to defined rules for the tooltip.',
        type: Object,
      },
    },

    computed: {
      validationRules: function () {
        const validationRules = [];

        for (const rule in this.options.rules) {
          let message;
          // override message in 'helper-messages' input prop. ex { [rule]: 'my override message' }
          if (this.options.rules[rule].message) {
            message = this.options.rules[rule].message;
          } else if (defaultMessages[rule]) {
            message =
              typeof defaultMessages[rule] === 'function'
                ? defaultMessages[rule](this.options.rules[rule].value)
                : defaultMessages[rule][this.options.rules[rule].value];
          }

          if (message) {
            validationRules.push({
              message: message,
              info: this.options.rules[rule].info, // display blue info icon
              error: this.options.rules[rule].error, // display green checkmark or red cross icon
            });
          }
        }

        return validationRules;
      },
      display: function () {
        return (
          this.validationRules.length &&
          (this.options.isFocused ||
            this.options.hasErrors ||
            ['s-input-file', 's-input-bool'].includes(this.options.inputName)) &&
          !this.options.disabled
        );
      },
    },

    methods: {
      getIconConfig: function (validationRule) {
        if (validationRule.info) {
          return {type: 'info', color: 'lightblue'};
        } else if (this.$parent.$_hasNoValue) {
          return {type: 'caret-right', color: 'gray'};
        } else {
          return {
            type: validationRule.error ? 'cross' : 'checkmark',
            color: validationRule.error ? 'red' : 'green',
          };
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sl-helper-tooltip ::v-deep(.s-tooltip) {
    width: 100%;

    .tooltip-container {
      left: 0 !important;
    }
  }

  .show {
    visibility: visible;
  }

  .hide {
    visibility: hidden;
  }
</style>
