<template>
  <div v-if="step !== 0" :style="getSliderPointerImage" :class="$_helperTooltipSelector">
    <div
      class="s-input-slider row-fluid preload relative"
      :class="[size, {'margin-top': !!label}, {numeric: isNumberMode}]"
    >
      <div :class="{'align-label-left': isLabelPositionLeft}">
        <label v-if="label" class="label-over-input" :class="{outlined: isLabelOutlined}" :for="getID">
          {{ label }}
        </label>
        <div class="sl-input-group">
          <div
            v-if="size !== 'small'"
            ref="sliderLabelParent"
            class="slider-labels"
            :class="{'hide-current-value': !showCurrentValue}"
          >
            <span
              v-for="(label, key) in getLabels()"
              :key="key"
              :class="{selected: parseFloat(label) === parseFloat(computedValue)}"
              class="slider-label active"
              v-bind:style="{left: ((stepValue * key) / (maxValue - minValue)) * 100 + '%'}"
              :title="label"
              @click="setValueFromLabel(label)"
            >
              <span class="slider-label-text" v-text="label"></span>
            </span>
          </div>
          <div class="numeric-slider-label-wrapper">
            <span
              v-if="showNumericSliderLabel"
              class="numeric-slider-label"
              :style="{left: selectedSliderLeft + '%'}"
              v-text="$_value"
            ></span>
          </div>
          <input
            :id="getID"
            ref="sliderInputGroup"
            v-model="computedValue"
            :name="name"
            type="range"
            :min="minValue"
            :max="maxValue"
            :step="stepValue"
            :disabled="disabled"
            class="sl-input-group-field"
            :class="{disabled: disabled, 'is-error': $_hasErrors, 'is-required': $data.$_isMissing}"
            @change="$_onChange"
            @click="$_onClick"
            @focus="$_onFocus"
            @blur="$_onBlur"
            @animationstart="animationStart"
          />
          <div v-if="showCurrentValue && !isNumberMode" class="slider-selected" v-text="$_value"></div>
        </div>
      </div>
    </div>
    <sl-helper-tooltip ref="helper-tooltip" :options="$_helperTooltipOptions" />
  </div>
</template>

<script>
  import helperTooltip from '@veasel/inputs/helper-tooltip';
  import sliderPointer from '../utils/sliderPointer.svg';

  import {
    id,
    name,
    label,
    size,
    onClick,
    syncValue,
    onChange,
    hasErrors,
    isDisabled,
    onBlur,
    onFocus,
    helperMessages,
  } from '@veasel/core/mixins';

  export default {
    name: 's-input-slider',
    description: 'A user input selecting a number between a range, or an option.',
    components: {'sl-helper-tooltip': helperTooltip},
    mixins: [
      id,
      name,
      label,
      size(),
      syncValue(String),
      onChange(),
      hasErrors,
      isDisabled,
      onClick(),
      onBlur(),
      onFocus(),
      helperMessages,
    ],
    data: function () {
      return {
        showEvery: 0, // Used to work out how many dashes we should show,
        helperTooltipPosition: 'bottom',
        inputType: 'slider',
      };
    },
    props: {
      min: {
        description: 'Smallest number that can be selected. This is ignored when options are provided.',
        type: Number,
        default: 0,
      },
      max: {
        description: 'Smallest number that can be selected. This is ignored when options are provided.',
        type: Number,
        default: 100,
      },
      step: {
        description:
          'When dragging the slider, this sets how many steps is taken per movement. This is ignored when options are provided.',
        type: Number,
        default: 1,
      },
      labelPosition: {
        description: 'Position of the label. Can either be top or left',
        type: String,
        default: 'top',
      },
      showCurrentValue: {
        description: 'Display the currently selected value',
        type: Boolean,
        default: true,
      },
      options: {
        description:
          'When using the slider to select labels, this is to provide the options that can be picked. Min, max & step props are ignored when this is used',
        type: Array,
        default: () => {
          return [];
        },
      },
    },
    computed: {
      isNumberMode: function () {
        return this.options.length === 0;
      },
      minValue: function () {
        return this.isNumberMode ? this.min : 0;
      },
      maxValue: function () {
        return this.isNumberMode ? this.max : 100;
      },
      stepValue: function () {
        return this.isNumberMode ? this.step : 100 / (this.options.length - 1);
      },
      getID: function () {
        return typeof this.id !== 'undefined' ? this.id : 'u' + this.$.uid;
      },
      isLabelPositionLeft: function () {
        return this.labelPosition.toLowerCase() === 'left';
      },
      selectedSliderLeft: function () {
        if (this.isNumberMode) {
          return (this.$_value / (this.max - this.min)) * 100;
        }
        return 0;
      },
      showNumericSliderLabel: function () {
        if (!this.showCurrentValue || !this.isNumberMode || this.size === 'small') {
          return false;
        }
        return !(+this.minValue === +this.computedValue || +this.maxValue === +this.computedValue);
      },
      computedValue: {
        get: function () {
          if (this.isNumberMode) {
            if (this.$_value > this.max) {
              // Provided value is not valid, we will make it the max amount
              return this.max;
            }
            if (this.$_value < this.min) {
              // Provided value is not valid, we will make it the min amount
              return this.min;
            }
            return this.$_value;
          } else {
            // Find index in array
            if (typeof this.$_value === 'undefined') {
              return 0;
            }
            const index = this.options.findIndex((value) => value.toLowerCase() === this.$_value.toLowerCase());
            if (index < 0) {
              // Could not find index, so we are going to set it as the first value
              return 0;
            }
            return index * this.stepValue;
          }
        },
        set: function (newValue) {
          if (this.isNumberMode) {
            this.$_value = newValue;
          } else {
            this.$_value = this.options[Math.round(newValue / this.stepValue)];
          }
        },
      },
      getSliderPointerImage: () => {
        return {
          '--slider-pointer': 'url(' + sliderPointer + ')',
        };
      },
    },
    methods: {
      getLabels: function () {
        if (this.isNumberMode) {
          // Number mode
          return [this.min, this.max];
        } else {
          // Option Mode
          return this.options;
        }
      },
      setValueFromLabel: function (value) {
        this.$_value = value.toString();
      },
    },
    mounted() {
      if (this.step === 0) {
        console.error("[Suade Lib]: Don't provide the prop 'step' as 0, you can't change a value with 0 points");
      }
    },
  };
</script>

<style lang="scss" scoped>
  @import '@veasel/core/sass/conf';

  .sl-input-group {
    min-height: 26px;
  }

  .s-input-slider {
    // Firstly, reset all the different browser designs and tweaks
    input[type='range'] {
      -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
      width: 100%; /* Specific width is required for Firefox. */
      background: transparent; /* Otherwise white in Chrome */
      margin: 0;
      position: relative;
      z-index: 1;
      outline: none;
      height: 16px;
    }

    input[type='range']:focus {
      outline: none;
      border: 0;
    }

    input[type='range']::-moz-focus-outer {
      border: 0;
    }

    //Style the thumb
    input[type='range']::-webkit-slider-thumb {
      border: none;
      height: 15px;
      width: 10px;
      background-image: var(--slider-pointer);
      cursor: pointer;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      -webkit-appearance: none;
      margin-top: -6px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    }

    input[type='range'].disabled::-webkit-slider-thumb {
      opacity: 0.4;
      cursor: not-allowed;
    }

    /* All the same stuff for Firefox */
    input[type='range']::-moz-range-thumb {
      border: none;
      height: 15px;
      width: 10px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      border-radius: 0;
      background-image: var(--slider-pointer);
      cursor: pointer;
    }

    input[type='range'].disabled::-moz-range-thumb {
      opacity: 0.4;
      cursor: not-allowed;
    }

    input[type='range']::-webkit-slider-runnable-track {
      width: 100%;
      outline: none;
      height: 2px;
      cursor: pointer;
      background: rgba(0, 0, 0, 0.5);
    }

    input[type='range']::-moz-range-track {
      width: 100%;
      outline: none;
      height: 2px;
      cursor: pointer;
      background: rgba(0, 0, 0, 0.5);
    }

    &.numeric {
      .slider-labels {
        &:not(.hide-current-value) .slider-label {
          top: 0;
          transition: ease all 0.2s;

          &:not(.selected) {
            display: none;
            opacity: 0.5;

            > span {
              visibility: hidden;
            }
          }

          &.selected {
            font-size: 12px;
            top: 18px;

            &:last-of-type,
            &:first-of-type {
              font-size: 16px;
              top: 10px;
            }
          }

          &::after {
            display: none;
          }
        }

        &.hide-current-value .slider-label {
          visibility: hidden;

          &:last-of-type,
          &:first-of-type {
            visibility: visible;
          }
        }
      }
    }
  }

  .slider-labels {
    display: block;
    justify-content: space-between;
    width: calc(100% - 10px);
    height: 30px;
    position: relative;
    text-align: center;
    margin: 10px auto 5px;

    .slider-label {
      display: inline-block;
      position: absolute;
      transform: translate(-50%);
      text-align: center;
      top: 10px;
      padding-bottom: 4px;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 34px;

      &:first-of-type {
        text-align: left;
        display: block !important;
        transform: none;
        margin-left: -5px;

        > .slider-label-text {
          visibility: visible !important;
        }

        &::after {
          left: 0;
          transform: none;
        }
      }

      &:last-of-type {
        left: initial !important;
        right: 0;
        text-align: right;
        display: block !important; // always show the last one
        transform: none;
        margin-right: -5px;

        > .slider-label-text {
          visibility: visible !important;
        }

        &::after {
          right: 0;
          left: initial;
          transform: none;
        }
      }

      &::after {
        background: rgba(0, 0, 0, 0.5);
        width: 1px;
        height: 4px;
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        top: 28px;
        content: '';
      }
    }
  }

  .slider-label-text {
    cursor: pointer;
  }

  .slider-selected {
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }

  .align-label-left {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .sl-input-group {
      flex-grow: 1;
    }

    label {
      max-width: 15%;
      padding-right: 10px;
    }
  }

  .numeric-slider-label-wrapper {
    width: calc(100% - 10px);
    position: relative;
    margin-left: 5px;

    .numeric-slider-label {
      position: absolute;
      transform: translate(-50%, 0);
      display: inline-block;
      margin-top: -16px;
      font-size: 12px;
    }
  }
</style>
