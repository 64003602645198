<template>
  <div
    v-if="$_value"
    :id="id"
    :name="name"
    class="s-modal columns"
    @mousedown.self="mousedown('outer')"
    @mouseup.self="mouseup('outer')"
  >
    <div
      class="modal-container"
      :class="[size, shouldBeSticky ? 'is-sticky' : '']"
      @mousedown="mousedown('container')"
      @mouseup="mouseup('container')"
    >
      <div class="modal-header" :class="shouldBeSticky ? 'sticky-header' : ''">
        <a class="close-btn" @click="closeModal">
          <s-icon type="cross" color="theme-main-dark" size="20" />
        </a>
        <h3 v-if="title" class="modal-title">{{ title }}</h3>
      </div>
      <div class="modal-content">
        <slot></slot>
        <div v-if="loading" class="loading-placeholder"></div>
        <s-loading-panel
          :state="loading ? 'loading' : 'completed'"
          :width-adjust="0"
          :height-adjust="0"
          :show-text="false"
          zero-left
          striped
        />
      </div>
    </div>
  </div>
</template>

<script>
  import icon from '@veasel/base/icon';
  import loadingPanel from '@veasel/base/loading-panel';
  import {id, name, size, syncValue} from '@veasel/core/mixins';

  export default {
    name: 's-modal',

    description: 'A view to display in front of the rest of the screen containing additional content.',

    components: {
      's-icon': icon,
      's-loading-panel': loadingPanel,
    },

    mixins: [id, name, size(['small', 'medium', 'large'], 'medium'), syncValue(Boolean)],

    props: {
      title: {
        description: 'The optional title for the modal view',
        type: String,
      },
      required: {
        description: 'A flag to avoid closing the modal view by clicking outside.',
        type: Boolean,
        default: false,
      },
      loading: {
        description: 'A flag to handle asynchronous loading of the modal view content.',
        type: Boolean,
        default: false,
      },
      onCancel: {
        description: 'A function to run when the modal is closed via the close or by clicking outside the box',
        type: Function,
        default: () => null,
      },
    },

    data: function () {
      return {
        mouseDownLocation: null,
      };
    },

    computed: {
      shouldBeSticky() {
        return this.title;
      },
    },

    methods: {
      // Close the modal by emittin a change to reflect in the synced value
      closeModal() {
        this.$_value = false;
        this.onCancel();
      },

      // Track the location of the mouse down press
      mousedown(location) {
        this.mouseDownLocation = location;
      },

      // Track the location of the mouse release
      mouseup(location) {
        // Only close the modal if both the press and release were in the 'outer' gray area, but only if !required
        if (this.mouseDownLocation === 'outer' && location === 'outer' && !this.required) {
          this.closeModal();
        }

        // Reset the down location
        this.mouseDownLocation = null;
      },

      // Set the modal height to display inputs select and datetime if the new height is greater than the previous one
      setHeight: function (height) {
        const modalContainer = this.$el.querySelector('.modal-container');
        if (modalContainer && modalContainer.clientHeight < height) {
          modalContainer.style.height = height + 'px';
          modalContainer.style.maxHeight = '';
        }
      },
      // Resset the modal height
      resetHeight: function () {
        const modalContainer = this.$el.querySelector('.modal-container');
        if (modalContainer) {
          modalContainer.style.height = '';
          modalContainer.style.maxHeight = '70vh';
        }
      },

      onEscapePressed(e) {
        if ((e.key === 'Escape' || e.keyCode === 27) && !this.required) {
          // prevents closing the modal if the user is in an input or textarea
          if (this.isNotIn(e.srcElement.tagName, ['INPUT', 'TEXTAREA'])) {
            this.closeModal();
          }
        }
      },

      isNotIn(str, arr) {
        return !arr.includes(str);
      },
    },

    mounted() {
      document.addEventListener('keydown', this.onEscapePressed);
    },
    beforeUnmount() {
      document.removeEventListener('keydown', this.onEscapePressed);
    },
  };
</script>

<style scoped lang="scss">
  @import '@veasel/core';

  .s-modal {
    z-index: 50;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: $opacity;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0);

    .modal-container {
      z-index: 51;
      position: relative;
      background-color: $white;
      min-height: 200px;
      border-radius: $border-radius;
      overflow-y: auto;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      padding: get-spacing('m');

      h3.modal-title {
        margin: 0;
        padding-bottom: get-spacing('s');
        @include subtitle;
      }

      .modal-header {
        z-index: 52; // Above the content
        background-color: $white;
        padding: 0;

        &.sticky-header {
          padding: 0;
          position: sticky;
          top: 0;
          width: 100%;
          padding-top: get-spacing('m');
        }
      }

      &.is-sticky {
        padding-bottom: get-spacing('m');
        padding-right: get-spacing('m');
        padding-left: get-spacing('m');
        padding-top: 0;
      }

      .close-btn {
        position: absolute;
        right: 15px;
        top: 15px;
        border-radius: 100%;
        height: 20px;
        cursor: pointer;
        z-index: 52;

        &:hover .s-icon {
          fill: $black;
        }
      }

      &.large {
        margin-top: 5vh;
        margin-bottom: 5vh;
        max-height: 90vh;
        margin-left: 5vw;
        margin-right: 5vw;
        width: calc(90vw - 50px);
      }

      &.medium {
        margin-top: 10vh;
        margin-bottom: 10vh;
        max-height: 80vh;
        margin-left: 15vw;
        margin-right: 15vw;
        width: calc(70vw - 50px);
      }

      &.small {
        margin-top: 15vh;
        margin-bottom: 15vh;
        max-height: 70vh;
        margin-left: 30vw;
        margin-right: 30vw;
        width: calc(40vw - 50px);
      }
    }

    .loading-placeholder {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
</style>
