export const SECONDS_A_MINUTE = 60;
export const SECONDS_A_HOUR = SECONDS_A_MINUTE * 60;
export const SECONDS_A_DAY = SECONDS_A_HOUR * 24;
export const SECONDS_A_WEEK = SECONDS_A_DAY * 7;
export const SECONDS_A_YEAR = SECONDS_A_DAY * 365;
export const SECONDS_A_MONTH = SECONDS_A_YEAR / 12;

export const MILLISECONDS_A_SECOND = 1e3;
export const MILLISECONDS_A_MINUTE = SECONDS_A_MINUTE * MILLISECONDS_A_SECOND;
export const MILLISECONDS_A_HOUR = SECONDS_A_HOUR * MILLISECONDS_A_SECOND;
export const MILLISECONDS_A_DAY = SECONDS_A_DAY * MILLISECONDS_A_SECOND;
export const MILLISECONDS_A_WEEK = SECONDS_A_WEEK * MILLISECONDS_A_SECOND;
export const MILLISECONDS_A_MONTH = SECONDS_A_MONTH * MILLISECONDS_A_SECOND;
export const MILLISECONDS_A_YEAR = SECONDS_A_YEAR * MILLISECONDS_A_SECOND;

export const MS = 'millisecond';
export const S = 'second';
export const MIN = 'minute';
export const H = 'hour';
export const D = 'day';
export const W = 'week';
export const M = 'month';
export const Q = 'quarter';
export const Y = 'year';
export const DATE = 'date';

export const FALLBACK_TIMEZONE = 'UTC';
export const LOCALE = 'locale';
export const NAIVE = 'naive';
export const DEFAULT_FORMAT = 'iso';
export const ISO_REGEX =
  /^(\d{4})[-/](\d{2})[-/](\d{2})(?:T(\d{2}):(\d{2}):(\d{2})(?:\.(\d{3}))?(?:[+-]\d{2}:\d{2}|Z)?)?$/;
export const MAX_DATE_ARRAY_LENGTH = 7; // YYYY/MM/DD HH:mm:ss:ms

export const DATE_NUMERIC_OPTIONS = {year: 'numeric', month: '2-digit', day: '2-digit'};
export const TIME_NUMERIC_OPTIONS = {hour: '2-digit', minute: '2-digit', second: '2-digit'};
export const DATETIME_NUMERIC_OPTIONS = {...DATE_NUMERIC_OPTIONS, ...TIME_NUMERIC_OPTIONS};
