import {clone, humanizeString} from '@veasel/core/tools';

export default {
  data: function () {
    return {
      expandColumnStatus: [],
      customCol: {},
    };
  },

  computed: {
    visibleAggregates: function () {
      return this.aggregateHeaders.filter(function (c) {
        return c.visible;
      });
    },
    hasAllColumnsVisible: function () {
      return this.aggregates.length > 0 && this.aggregates.length == this.visibleAggregates.length;
    },
    hasAllColumnsHidden: function () {
      return this.aggregates.length > 0 && this.visibleAggregates.length == 0;
    },
  },

  methods: {
    // expand/collapse all row until a given column
    expandAll: function (index) {
      if (this.expandColumnStatus[index]) {
        // collapse
        if (Array.isArray(this.aggGroupRefs)) {
          this.aggGroupRefs.forEach((aggGroup) => {
            if (aggGroup !== null) {
              aggGroup.collapseAll(index, this.opts.uuid);
            }
          });
        } else if (typeof this.aggGroupRefs !== 'undefined') {
          this.aggGroupRefs.collapseAll(index, this.opts.uuid);
        }
        for (let i = parseInt(index); i < this.groupHeaders.length; i++) {
          this.expandColumnStatus[i] = false;
        }
      } else {
        // build the list of expand column function
        const promises = [];
        for (let i = 0; i < parseInt(index) + 1; i++) {
          promises.push(() =>
            this.$nextTick(function () {
              this.expandColumnStatus[i] = true;
              if (Array.isArray(this.aggGroupRefs)) {
                this.aggGroupRefs.forEach((aggGroup) => {
                  if (aggGroup !== null) {
                    aggGroup.expandAll(index, this.opts.uuid);
                  }
                });
              } else if (typeof this.aggGroupRefs !== 'undefined') {
                this.aggGroupRefs.expandAll(index, this.opts.uuid);
              }
            })
          );
        }
        // running serial promises to expand each column
        return promises.reduce((promiseChain, currentTask) => {
          return promiseChain.then(currentTask);
        }, Promise.resolve());
      }
    },

    // handler for expandAll status
    collapsed: function (x, flag) {
      if (flag) {
        for (let i = 0; i <= parseInt(x); i++) {
          if (
            this.$el.querySelectorAll(
              '[agg-index^="' + i + '_"].agg-group > .group-cell > .expand-icon:first-child:not(.is-extended)'
            ).length +
              document.querySelectorAll('[empty-col-index="' + i + '"]').length <=
            1
          ) {
            this.expandColumnStatus[i] = true;
          }
        }
      } else {
        for (let i = parseInt(x); i < this.groupHeaders.length; i++) {
          this.expandColumnStatus[i] = false;
        }
      }
    },

    // a column has been shown/hidden
    updateColumnVisibility: function () {
      const aggregateHeaders = clone(this.aggregateHeaders);
      this.aggregateHeaders = [];
      this.aggregateHeaders = aggregateHeaders;
    },

    // display all columns
    displayAllColumns: function () {
      this.aggregateHeaders = this.aggregateHeaders.map(function (c) {
        c.visible = true;
        return c;
      });
    },

    // hide all columns
    hideAllColumns: function () {
      this.aggregateHeaders = this.aggregateHeaders.map(function (c) {
        c.visible = false;
        return c;
      });
    },

    // add a new custom column
    addCustomColumn: function (col1, op, col2) {
      const customCol = {
        title:
          humanizeString(this.aggregateHeaders[col1].title) +
          ' ' +
          op +
          ' ' +
          humanizeString(this.aggregateHeaders[col2].title),
        key: 'custom_' + this.aggregateHeaders.length,
        type: 'custom',
        composedOf: {index1: col1, op: op, index2: col2},
        visible: true,
        index: this.aggregateHeaders.length,
        monetary:
          op === '/'
            ? !(this.aggregateHeaders[col1].monetary === this.aggregateHeaders[col2].monetary)
            : this.aggregateHeaders[col1].monetary || this.aggregateHeaders[col2].monetary,
      };
      this.aggregateHeaders.push(customCol);
      this.aggregatedResult = this.extendAggregations(this.updatedData);
      this.customCol = {};
    },
  },
};
