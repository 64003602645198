const MIN_COL_WIDTH = 100;
const CHECKBOX_COL_WIDTH = 29;

export default {
  data: function () {
    return {
      containerWidth: 0,
      width: {},
    };
  },

  computed: {
    // column width if no width assigned for column
    basicColWidth: function () {
      const totalSetColumns = this.visibleCols.filter((c) => c.width).reduce((acc, c) => acc + c.width, 0);
      const numberOfUnsetColumns = this.visibleCols.filter((c) => !c.width).length;
      const colWidthForUnsetColumns =
        ((this.containerWidth - (this.checkboxColumnWidth + 3) || 0) - totalSetColumns) / numberOfUnsetColumns;
      return Math.max(MIN_COL_WIDTH, colWidthForUnsetColumns.toFixed(2));
    },
    // total table width
    innerTableWidth: function () {
      return (
        this.visibleCols.reduce((acc, c) => acc + (c.width ? c.width : this.basicColWidth), 0) +
        this.checkboxColumnWidth +
        1
      );
    },
    // cropped table width
    outerTableWidth: function () {
      return this.containerWidth - 2 || 0;
    },
    // checkbox column width
    checkboxColumnWidth: function () {
      return this.opts.checkboxes.display ? CHECKBOX_COL_WIDTH : 0;
    },
    // specified row height
    basicRowHeight: function () {
      return this.opts.fixedRowHeight;
    },
  },

  methods: {
    // resize the table when the container is resized
    resize: function () {
      this.containerWidth = this.$el.offsetWidth;
      if (this.opts.stickyScroller) {
        this.$nextTick(() => {
          this.hideScroller();
        });
      }
    },
    // column width during manual resize
    getResizedColumnWidth: function (event, colSize) {
      return Math.max(MIN_COL_WIDTH - 3, colSize + (event.clientX - this.resizeClientX)) + 1;
    },
    // total table width during manual resize
    getTotalWidthBeforeResize: function (originalWidth) {
      return originalWidth + this.checkboxColumnWidth;
    },
  },
};
