export default {
  name: 'report',

  namespaced: true,

  state: {
    reportTypes: false,
    metaReportTypes: false,
    reportsConfig: false,
    reportConfs: false, // schedulers
    customValidationReportTypes: false,
    reportTemplates: {},
    cellMetadata: {},
    subreports: false,
    reportTypeVersions: {},
    workflows: false,
    dq: {},
  },

  getters: {
    calculators(state) {
      if (state.reportTypes && state.reportTypes.length > 0) {
        return [...new Set(state.reportTypes.map((t) => t.service))];
      } else {
        return [];
      }
    },
  },

  mutations: {
    setReportTypes(state, config) {
      state.reportTypes = config;
    },

    setMetaReportTypes(state, config) {
      state.metaReportTypes = config;
    },

    resetMetaReportTypes(state) {
      state.metaReportTypes = false;
    },

    setReportTypeVersions(state, config) {
      state.reportTypeVersions[config.additionalInfo] = config;
    },

    setReportConfig(state, data) {
      const reportsConfig = data.reduce(
        (totalConfig, {name, config}) => ({
          ...totalConfig,
          ...Object.keys(config).reduce((e, c) => ({...e, [c]: {...config[c], calculator: name}}), {}),
        }),
        {}
      );
      state.reportsConfig = reportsConfig;
    },

    setReportConfs(state, config) {
      state.reportConfs = config;
    },

    resetReportConfs(state) {
      state.reportConfs = false;
    },

    setReportTemplate(state, data) {
      state.reportTemplates[data.key] = data.template;
    },

    setCellMetadata(state, data) {
      state.cellMetadata[data.locator] = data.metadata;
    },

    clearCellMetadata(state) {
      state.cellMetadata = {};
    },

    setSubreports(state, data) {
      state.subreports = data;
    },

    setReportWorkflows(state, data) {
      state.workflows = data.data;
    },

    resetWorkflows(state) {
      state.workflows = false;
    },

    setReportDQ(state, data) {
      state.dq[data.report_id] = data.rules;
    },

    resetReportDQ(state, data) {
      delete state.dq[data.report_id];
    },

    setCustomValidationReportTypes(state, data) {
      state.customValidationReportTypes = data;
    },
  },

  actions: {
    setReportTypes(context, config) {
      if (config) {
        context.commit('setReportTypes', config);
      }
    },

    resetReportTypes(context) {
      context.commit('setReportTypes', false);
    },

    setReportTypeVersion(context, config) {
      if (config) {
        context.commit('setReportTypeVersions', config);
      }
    },

    setMetaReportTypes(context, config) {
      if (config) {
        context.commit('setMetaReportTypes', config);
      }
    },

    resetMetaReportTypes(context) {
      context.commit('resetMetaReportTypes');
    },

    setReportConfig(context, config) {
      if (config) {
        context.commit('setReportConfig', config);
      }
    },

    setReportConfs(context, config) {
      if (config) {
        context.commit('setReportConfs', config);
      }
    },

    resetReportConfs(context) {
      context.commit('resetReportConfs');
    },

    setReportTemplate(context, data) {
      if (data.key !== '') {
        context.commit('setReportTemplate', data);
      }
    },

    setCellMetadata(context, data) {
      context.commit('setCellMetadata', data);
    },

    clearCellMetadata(context) {
      context.commit('clearCellMetadata');
    },

    setSubreports(context, data) {
      if (data) {
        context.commit('setSubreports', data);
      }
    },

    setReportWorkflows(context, data) {
      context.commit('setReportWorkflows', data);
    },

    resetWorkflows(context, data) {
      context.commit('resetWorkflows', data);
    },

    setReportDQ(context, data) {
      context.commit('setReportDQ', data);
    },

    resetReportDQ(context, data) {
      context.commit('resetReportDQ', data);
    },

    setCustomValidationReportTypes(context, config) {
      if (config && config.valid_report_types) {
        context.commit('setCustomValidationReportTypes', config.valid_report_types);
      }
    },
  },
};
