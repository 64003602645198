import {isEmpty} from '@veasel/core/tools';

export default {
  props: {
    infoMessage: {
      description: 'Info message to display in the helper tooltip',
      type: String,
    },
    disableTooltip: {type: Boolean, default: false},
    accept: {type: String, default: ''},
    maxSize: {type: Number, default: undefined},
    fakeInputHelperMessage: {
      description:
        'Object representing tooltip helper message. If there is custom implementation of s-input-wrapper over a non-native input element',
      type: [Object, Boolean],
      default: false,
    },
  },

  data() {
    return {
      helperTooltipPosition: 'top',
    };
  },

  computed: {
    $_helperTooltipSelector: function () {
      let helperTooltipSelector;
      if (!isEmpty(this.$_helperTooltipRules)) {
        if (this.id) {
          helperTooltipSelector = this.id + '-helper-tooltip-selector';
        }
      }
      return helperTooltipSelector;
    },
    $_helperTooltipOptions: function () {
      if (this.fakeInputHelperMessage) {
        return this.fakeInputHelperMessage;
      }
      let options = {
        inputName: this.$options.name,
        rules: this.$_helperTooltipRules,
        isFocused: this.$data.$_isFocused,
        forceVisibilty: this.id && this.$data.$_isFocused,
        hasErrors: this.$_hasErrors,
        position: this.helperTooltipPosition,
        inputSelector: this.$_helperTooltipSelector ? '.' + this.$_helperTooltipSelector : undefined,
        disabled: this.disableTooltip,
      };
      if (this.$options.name === 's-operations') {
        const sOperationOptions = this.$data.warnings;
        options = {
          ...options,
          ...sOperationOptions,
        };
      }
      return options;
    },

    $_helperTooltipRules: function () {
      const helperTooltipRules = {};

      let minMessage = '';
      let maxMessage = '';
      if (this.$options.name === 's-input-datetime') {
        if (this.min && this.max) {
          minMessage = ' between ' + this.displayedMin + ' and ' + this.displayedMax;
        } else if (this.min) {
          minMessage = this.$attrs['min-message'] || ' after ' + this.displayedMin;
        } else if (this.max) {
          maxMessage = this.$attrs['max-message'] || ' before ' + this.displayedMax;
        }
      } else if (this.$options.name === 's-input-number') {
        if (this.min !== undefined) {
          minMessage = this.$attrs['min-message'] || ' ≥ ' + this.min;
        }

        if (this.max !== undefined) {
          maxMessage = (this.min !== undefined ? ' and' : '') + (this.$attrs['max-message'] || ' ≤ ' + this.max);
        }
      }

      if (minMessage + maxMessage) {
        helperTooltipRules.infoMessage = {message: 'must be ' + minMessage + maxMessage, info: true};
      }

      if (this.infoMessage) {
        helperTooltipRules.infoMessage = {message: this.infoMessage, info: true};
      }

      if (this.$props.pattern) {
        helperTooltipRules.pattern = {
          message: this.$attrs['pattern-message'],
          value: this.pattern,
          error: this.$data.$_hasPatternError,
        };
      }

      if (this.accept) {
        helperTooltipRules.acceptedFiles = {message: this.$attrs['accept-message'], value: this.accept, info: true};
      }

      // files max size
      if (this.maxSize) {
        helperTooltipRules.maxFileSize = {
          message: this.$attrs['max-size-message'],
          value: this.maxSize,
          error: this.$data.$_hasMaxSizeError,
        };
      }

      // min max input select values number
      if (this.$options.name === 's-input-select' && this.multiple) {
        if (this.min) {
          helperTooltipRules.min = {
            message: this.$attrs['min-message'],
            value: this.min,
            error: this.$data.$_hasMinError,
          };
        }
        if (this.max) {
          helperTooltipRules.max = {
            message: this.$attrs['max-message'],
            value: this.max,
            error: this.$data.$_hasMaxError,
          };
        }
      }
      if (this.$options.name === 's-operations') {
        return {...helperTooltipRules, ...this.$data.warnings.rules};
      }
      return helperTooltipRules;
    },
  },
};
