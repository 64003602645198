<template>
  <s-label title="Environment" :type="color">{{ environment }}</s-label>
</template>

<script>
  export default {
    name: 'EnvironmentLabel',
    props: ['env'],
    computed: {
      environment() {
        return this.env.toUpperCase();
      },
      color() {
        return {
          '-': 'secondary',
          DEV: 'secondary',
          DR: 'warning',
          POC: 'active',
          UAT: 'error',
          PROD: 'approved',
        }[this.environment];
      },
    },
  };
</script>
