// globals
import {reactive} from 'vue';

// CORE

// styles
import '@veasel/core/sass/_base.scss';

// services
import * as Tools from '@veasel/core/tools';
import '@veasel/core/native-tools';
import '@veasel/core/tutorial';
import {resize} from '@veasel/core/mixins';
import popups from '@veasel/core/popups';
import notifications from '@veasel/core/notifications';

// Directives
import {selectOverflow, colorOverflow, dateOverflow, clickOutside, dragAndDrop} from '@veasel/core/directives';

window.Tools = Tools;

// mixins
window.Mixins = {
  resize: resize,
};

// directives
window.Directives = {
  dragAndDrop: dragAndDrop,
};

// components
import animation from '@veasel/base/animation';
import box from '@veasel/base/box';
import button from '@veasel/base/button';
import code from '@veasel/base/code';
import {dropdown, dropdownItem, dropdownTitle} from '@veasel/base/dropdown';
import expandable from '@veasel/base/expandable';
import icon from '@veasel/base/icon';
import image from '@veasel/base/image';
import label from '@veasel/base/label';
import loader from '@veasel/base/loader';
import loadingPanel from '@veasel/base/loading-panel';
import markdown from '@veasel/base/markdown';
import modal from '@veasel/base/modal';
import pipeSpacer from '@veasel/base/pipe-spacer';
import placeholder from '@veasel/base/placeholder';
import progress from '@veasel/base/progress';
import stepTimeline from '@veasel/base/step-timeline';
import tabs from '@veasel/base/tabs';
import tooltip from '@veasel/base/tooltip';
import {transitionSlideY} from '@veasel/base/transitions';

import navbar from '@veasel/layout/navbar';
import pageHeader from '@veasel/layout/page-header';
import sidePanel from '@veasel/layout/side-panel';
import topbar from '@veasel/layout/topbar';
import xhrLoader from '@veasel/layout/xhr-loader';

import form from '@veasel/inputs/form';
import input from '@veasel/inputs/input';
import inputBool from '@veasel/inputs/input-bool';
import inputCheckbox from '@veasel/inputs/input-checkbox';
import inputColor from '@veasel/inputs/input-color';
import inputDatetime from '@veasel/inputs/input-datetime';
import inputFile from '@veasel/inputs/input-file';
import inputMonetary from '@veasel/inputs/input-monetary';
import inputNumber from '@veasel/inputs/input-number';
import inputQuery from '@veasel/inputs/input-query';
import inputRadio from '@veasel/inputs/input-radio';
import inputRadioGroup from '@veasel/inputs/input-radio-group';
import inputSelect from '@veasel/inputs/input-select';
import inputSlider from '@veasel/inputs/input-slider';
import inputText from '@veasel/inputs/input-text';
import inputTextarea from '@veasel/inputs/input-textarea';

import operations from '@veasel/advanced-inputs/operations';
import queryBuilder from '@veasel/advanced-inputs/query-builder';
import questionnaire from '@veasel/advanced-inputs/questionnaire';
import treeSelector from '@veasel/advanced-inputs/tree-selector';

import aggTable from '@veasel/data/agg-table';
import calendar from '@veasel/data/calendar';
import dataList from '@veasel/data/data-list';
import dataTable from '@veasel/data/data-table';
import filteredSearch from '@veasel/data/filtered-search';
import groupList from '@veasel/data/group-list';
import pagination from '@veasel/data/pagination';

import axisChart from '@veasel/charts/axis-chart';
import {crossFilter, crossFilterItem} from '@veasel/charts/cross-filter';
import gauge from '@veasel/charts/gauge';
import logicCircuit from '@veasel/charts/logic-circuit';
import monitorSparkline from '@veasel/charts/monitor-sparkline';
import organizationChart from '@veasel/charts/organization-chart';
import parCoords from '@veasel/charts/par-coords';
import sankey from '@veasel/charts/sankey';
import pieChart from '@veasel/charts/pie-chart';
import sparkline from '@veasel/charts/sparkline';
import treeChart from '@veasel/charts/tree-chart';

const COMPONENT_PREFIX = 's';

export default {
  install: function (app, options) {
    app.component(COMPONENT_PREFIX + '-icon', icon);

    app.component(COMPONENT_PREFIX + '-animation', animation);
    app.component(COMPONENT_PREFIX + '-box', box);
    app.component(COMPONENT_PREFIX + '-button', button);
    app.component(COMPONENT_PREFIX + '-code', code);
    app.component(COMPONENT_PREFIX + '-dropdown', dropdown);
    app.component(COMPONENT_PREFIX + '-dropdown-item', dropdownItem);
    app.component(COMPONENT_PREFIX + '-dropdown-title', dropdownTitle);
    app.component(COMPONENT_PREFIX + '-expandable', expandable);
    app.component(COMPONENT_PREFIX + '-image', image);
    app.component(COMPONENT_PREFIX + '-label', label);
    app.component(COMPONENT_PREFIX + '-loader', loader);
    app.component(COMPONENT_PREFIX + '-loading-panel', loadingPanel);
    app.component(COMPONENT_PREFIX + '-markdown', markdown);
    app.component(COMPONENT_PREFIX + '-modal', modal);
    app.component(COMPONENT_PREFIX + '-pipe-spacer', pipeSpacer);
    app.component(COMPONENT_PREFIX + '-placeholder', placeholder);
    app.component(COMPONENT_PREFIX + '-progress', progress);
    app.component(COMPONENT_PREFIX + '-step-timeline', stepTimeline);
    app.component(COMPONENT_PREFIX + '-tabs', tabs);
    app.component(COMPONENT_PREFIX + '-tooltip', tooltip);
    app.component(COMPONENT_PREFIX + '-transition-slide-y', transitionSlideY);

    app.component(COMPONENT_PREFIX + '-navbar', navbar);
    app.component(COMPONENT_PREFIX + '-page-header', pageHeader);
    app.component(COMPONENT_PREFIX + '-side-panel', sidePanel);
    app.component(COMPONENT_PREFIX + '-topbar', topbar);
    app.component(COMPONENT_PREFIX + '-xhr-loader', xhrLoader);

    app.component(COMPONENT_PREFIX + '-form', form);
    app.component(COMPONENT_PREFIX + '-input', input);
    app.component(COMPONENT_PREFIX + '-input-bool', inputBool);
    app.component(COMPONENT_PREFIX + '-input-checkbox', inputCheckbox);
    app.component(COMPONENT_PREFIX + '-input-color', inputColor);
    app.component(COMPONENT_PREFIX + '-input-datetime', inputDatetime);
    app.component(COMPONENT_PREFIX + '-input-file', inputFile);
    app.component(COMPONENT_PREFIX + '-input-monetary', inputMonetary);
    app.component(COMPONENT_PREFIX + '-input-number', inputNumber);
    app.component(COMPONENT_PREFIX + '-input-query', inputQuery);
    app.component(COMPONENT_PREFIX + '-input-radio', inputRadio);
    app.component(COMPONENT_PREFIX + '-input-radio-group', inputRadioGroup);
    app.component(COMPONENT_PREFIX + '-input-select', inputSelect);
    app.component(COMPONENT_PREFIX + '-input-slider', inputSlider);
    app.component(COMPONENT_PREFIX + '-input-text', inputText);
    app.component(COMPONENT_PREFIX + '-input-textarea', inputTextarea);

    app.component(COMPONENT_PREFIX + '-operations', operations);
    app.component(COMPONENT_PREFIX + '-query-builder', queryBuilder);
    app.component(COMPONENT_PREFIX + '-questionnaire', questionnaire);
    app.component(COMPONENT_PREFIX + '-tree-selector', treeSelector);

    app.component(COMPONENT_PREFIX + '-agg-table', aggTable);
    app.component(COMPONENT_PREFIX + '-calendar', calendar);
    app.component(COMPONENT_PREFIX + '-data-list', dataList);
    app.component(COMPONENT_PREFIX + '-data-table', dataTable);
    app.component(COMPONENT_PREFIX + '-filtered-search', filteredSearch);
    app.component(COMPONENT_PREFIX + '-group-list', groupList);
    app.component(COMPONENT_PREFIX + '-pagination', pagination);

    app.component(COMPONENT_PREFIX + '-axis-chart', axisChart);
    app.component(COMPONENT_PREFIX + '-cross-filter', crossFilter);
    app.component(COMPONENT_PREFIX + '-cross-filter-item', crossFilterItem);
    app.component(COMPONENT_PREFIX + '-gauge', gauge);
    app.component(COMPONENT_PREFIX + '-logic-circuit', logicCircuit);
    app.component(COMPONENT_PREFIX + '-monitor-sparkline', monitorSparkline);
    app.component(COMPONENT_PREFIX + '-organization-chart', organizationChart);
    app.component(COMPONENT_PREFIX + '-par-coords', parCoords);
    app.component(COMPONENT_PREFIX + '-sankey', sankey);
    app.component(COMPONENT_PREFIX + '-pie-chart', pieChart);
    app.component(COMPONENT_PREFIX + '-sparkline', sparkline);
    app.component(COMPONENT_PREFIX + '-tree-chart', treeChart);

    // Provide locale to inject in s-input-datetime as default application value
    app.provide('DATETIME_LOCALE', options && options.DATETIME_LOCALE ? options.DATETIME_LOCALE : undefined);

    // Adding global variables
    app.config.globalProperties.$veasel = {
      formValidUpdate: {}, // For s-form to store functions for children to fire
    };

    // For s-form to store the valid status for a submit button, but we need to set it up like this so it is Vue reactive
    const formValidStatus = reactive({formValidStatus: {}});

    Object.defineProperty(app.config.globalProperties.$veasel, 'formValidStatus', {
      get() {
        return formValidStatus.formValidStatus;
      },
      set(value) {
        formValidStatus.formValidStatus = value;
      },
    });

    // Expose css var values to the system globally via s_colors
    const themeStyles = getComputedStyle(document.body);
    app.mixin({
      data: () => ({
        s_colors: (value) => themeStyles.getPropertyValue(value),
      }),
    });

    selectOverflow.install(app);
    dateOverflow.install(app);
    colorOverflow.install(app);
    clickOutside.install(app);
    dragAndDrop.install(app);

    const sidePanelWrapper = document.createElement('div');
    sidePanelWrapper.setAttribute('id', 'side-panel-wrapper-top');
    document.body.appendChild(sidePanelWrapper);

    // Append div to body of DOM, which will hold all tooltip components
    const tooltipWrapper = document.createElement('div');
    tooltipWrapper.setAttribute('id', 'tooltip-wrapper');
    tooltipWrapper.style.position = 'fixed';
    tooltipWrapper.style.top = 0;
    tooltipWrapper.style.left = 0;
    tooltipWrapper.style.zIndex = 80;

    document.body.appendChild(tooltipWrapper);

    app.use(
      notifications,
      // optional settings
      {
        defaultPosition: 'bottom-right',
        defaultTimeout: 2500,
        disableTimeout: false,
        allowHtml: false,
      }
    );
    app.use(popups);

    return app;
  },
};
