import {safeGet, extend, isEmpty, getStorage, isDeepEqual, catchify} from '@veasel/core/tools';

const DEFAULT_OPTIONS = {
  uniqId: 'id', // String (uniq data key used to store selection, and display while editing)
  checkboxes: {
    // CHECKBOXES:
    display: false, // Boolean (display the checkbox column, will be true if actions are passed)
    actions: [], // Array[{name:String, callback:Function, visible:Function, disable: Function, disabledMsg:Function}] (no action button displayed if false, if an array is specified, display an actions dropdown a and floating checkbox column)
    selection: [], // Array (list of selected rows objects using uniqId to identify)
    event: 'checkboxes-updated', // String (name of the event to propagate)
    selectAll: true, // Boolean (display the selectAll checkbox on top of the other checkboxes)
  },
  additionalFilter: {
    // FILTERS: additonal filter (like year, or data type)
    display: false, // Boolean (not displayed if false, display a select input if true)
    filters: [], // Array[{ label: String (optionnal label displayed before component), key: String (send to backend), field: 'input component suffix (select, bool...)' , default: String | Boolean (default value), fieldOptions: Object (input component props options) }]
    parsing: {}, // String for existing parsingMethods ("flat") or Object containing 2 functions parseQuery and serializeQuery
    selection: {}, // Preset the filters
    event: 'filter-updated', // String (name of the event to propagate)
  },
  search: {
    // SEARCH:
    display: false, // Boolean (no search bar if false, display search bar if true)
    columns: [], // Array[{name:String, key:String}] (search by columns) --TODO use columns when search bar ready
    event: 'search-updated', // String (name of the event to propagate)
    timeLimit: 400, // Number (time in milliseconds to wait before actually searching)
  },
  settings: {
    // SETTINGS:
    display: true, // Boolean (not displaying the settings dropdown if false, display it if object filled)
    columnOrder: true, // Boolean (allow to reorder columns)
    columnVisibility: true, // Boolean (allow to show/hide columns)
    pageSize: [10, 25, 50, 100], // false | Array[Number] (allow to update page size)
    storeSettings: 'default', // false | String (not storing anything if false, storing settings in local storage with the key specified as a string)
    resize: true, // Boolean (allow column resizing)
    displayCurrency: true, // Boolean (display or not the currency code in front of all monetary values)
  },
  sorting: {
    // SORTING:
    display: true, // Boolean (allow column sorting)
    multiple: false, // Boolean (allow to select multiple columns to sort by using shift/ctrl keys)
    event: 'sort-by', // String (name of the event to propagate)
    displayArrow: false, // String (name of the event to propagate)
  },
  resizing: {
    // COLUMN RESIZING:
    display: true, // Boolean (allow column resizing)
    save: true, // Boolean (if true, save the column width in localstorage, doesn't save anything if false)
    event: 'column-resized', // String (name of the event to propagate)
  },
  pagination: {
    // PAGINATION:
    display: true, // Boolean (disable pagination if false)
    event: 'page-updated', // String (event to emit for backend pagination)
    offset: 0, // Number (the number of rows to hide before the set of rows to display)
    limit: 0, // Number (the page size used if none defined in local storage)
    num_entries: 0, // Number (the total of rows on all pages used to build the pagination)
    align: 'left', // The alignment of the pagination (left, center, right)
  },
  selection: {
    // CELL SELECTION:
    display: true, // Boolean (display excel like cell selection layer)
    arrowKeys: true, // Boolean (allow keyboard navigation)
    multiple: true, // Boolean (allow multiple cells selection)
    copyToClipboard: true, // Boolean (allow ctrl+c shortcut to copy selection to clipboard)
    displayTotal: true, // Boolean (if true, display the total of the selection if selected cells contains numbers)
    event: 'cell-selected', // Boolean (if true, display the total of the selection if selected cells contains numbers)
  },
  exportView: {
    // PAGE EXPORT:
    display: false,
    event: 'export-view', // String (name of he event to propagate)
    listing: false, // Boolean | Array[{title:String, value:Function, style:Function, width:Number}] (if array specified, will export the listing with the given of columns headers, values and style renderer)
    types: [
      {
        // Array[{name:String, value:String, logic:function}] (using the array to build export buttons)
        name: 'Export to Excel', // String (name is what is displayed in the button)
        logic: 'xlsx', // String | function (String can be specified to use an built-in logic, or function can be passed to implement custom logic)
      },
    ],
  },
  editable: false, // Boolean (allow cell edition and synchronize the `rows` prop)
  frontend: true, // Boolean (all rows interaction like paginating, searching, and sorting, are handled by frontend if true, or sent to parent if false)
  queryMap: {
    // Object (mapping object for each query parameter)
    limit: 'limit',
    offset: 'offset',
    numEntries: 'num_entries',
    search: 'search_query',
    filters: 'search_query',
    orderBy: 'order_by',
    sort: 'sort',
  },
  actionButton: {
    disable: false,
    tooltipText: undefined,
  },
  backendEvent: 'queryUpdated', // String (event to emit for any query update that should trigger backend interaction)
  rowName: (isMultiple) => (isMultiple ? 'items' : 'item'), // Function, arg is Bool if there are multiples rows in view
  stickyHeader: true, // Boolean | Number (basic table header if false, floating top if true, floating top with margin if number specified)
  stickyScroller: true, // Boolean | Number (basic table scroller if false, floating bottom if true, floating bottom with margin if number specified)
  fixedRowHeight: 24, // Number (if number specified, force table rows to have a fixed height) --TODO: handle checkboxes with updated row height
  rowRenderer: function () {}, // Function (logic used to render the style on rows)
  highlight: false, // String (no highlighting if false, highlight a row when needed, like when the side panel is open)
  loading: {state: false}, // Boolean (display a loading panel if true, hide it if false)
};

const DEFAULT_ACTIONS_OPTIONS = {
  name: undefined, // String (name of the action to display in the dropdown)
  disabledMsg: 'Action disabled', // String (explanation to display if the action is disabled)
  visible: true, // Boolean | Function (display action if true, hide action if false)
  disable: false, // Boolean | Function (disable action if true, allow action if false)
  callback: function () {
    // Function (function to call when an action is clicked)
    console.warn('No callback specified for action ' + this.name);
  },
};

export default {
  data: function () {
    return {
      opts: {},
      safeGet: safeGet,
    };
  },

  emits: [
    DEFAULT_OPTIONS.checkboxes.event,
    DEFAULT_OPTIONS.additionalFilter.event,
    DEFAULT_OPTIONS.search.event,
    DEFAULT_OPTIONS.sorting.event,
    DEFAULT_OPTIONS.resizing.event,
    DEFAULT_OPTIONS.pagination.event,
    DEFAULT_OPTIONS.selection.event,
    DEFAULT_OPTIONS.exportView.event,
    DEFAULT_OPTIONS.backendEvent,
  ],

  watch: {
    // watch pagination modification
    'options.pagination': {
      handler: function (pagination) {
        const newPagination = extend(DEFAULT_OPTIONS.pagination, this.opts.pagination, pagination);

        // We always want to override the rows per page/limit with what is in local storage, as this is what the user specifically wants
        if (!isEmpty(this.opts.settings) && this.opts.settings.storeSettings) {
          const paginationLimit = getStorage('datatable-pagination-limit-' + this.opts.settings.storeSettings);
          if (paginationLimit !== false) {
            newPagination.limit = paginationLimit;
          }
        }

        this.opts.pagination = newPagination;
      },
      deep: true,
    },
    // Highlight flag
    'options.highlight': {
      handler: function (newVar) {
        this.opts.highlight = newVar;
      },
      deep: true,
    },
    // watch loading flag
    'options.loading': {
      handler: function (loading) {
        this.opts.loading = loading;
      },
    },
    'options.actionButton.disable': {
      handler: function (disable) {
        this.opts.actionButton.disable = disable;
      },
    },
    'options.actionButton.tooltipText': {
      handler: function (tooltipText) {
        this.opts.actionButton.tooltipText = tooltipText;
      },
    },
    // watch sticky top
    'options.stickyHeader': {
      immediate: true,
      handler: function (stickyTop) {
        this.$nextTick(() => {
          if (this.$el && this.$el.querySelector('.sticky-header')) {
            if (typeof stickyTop === 'number') {
              this.$el.querySelector('.sticky-header').style.top = stickyTop + 'px';
            } else {
              this.$el.querySelector('.sticky-header').style.top = 0;
            }
          }
        });
      },
    },
    'options.additionalFilter.filters': {
      deep: true,
      handler: function (filters) {
        this.opts.additionalFilter = extend(DEFAULT_OPTIONS.additionalFilter, this.opts.additionalFilter, {
          filters: filters,
        });
      },
    },
    'options.additionalFilter.selection': {
      deep: true,
      handler(selection, oldValue) {
        // If there is no change, then we don't need to run updates
        if (!isDeepEqual(selection, oldValue)) {
          this.opts.additionalFilter.selection = selection;
          this.setFilters();
        }
      },
    },
    'options.rowName': {
      handler: function (newFunction) {
        this.opts.rowName = newFunction;
      },
    },
    'options.checkboxes.selection': {
      deep: true,
      handler: function (selection) {
        this.checkboxSelectionMap = {};
        for (const i in selection) {
          this.checkboxSelectionMap[safeGet(selection[i], this.opts.uniqId)] = true;
        }
      },
    },
  },

  methods: {
    // initialize options using provided and default options
    initOptions: function (overrides = {}) {
      const options = extend({}, DEFAULT_OPTIONS, overrides, this.options);
      if (options.pagination) {
        options.pagination.num_entries =
          options.pagination && !options.frontend ? options.pagination.num_entries : this.rows.length;
        if (options.settings.storeSettings) {
          const paginationLimit = getStorage('datatable-pagination-limit-' + options.settings.storeSettings);
          if (paginationLimit !== false) {
            options.pagination.limit = paginationLimit;
          }
        }
      }
      if (options.checkboxes.display || options.checkboxes.actions.length) {
        options.checkboxes.display = true;

        if (options.checkboxes.actions.length) {
          for (const i in options.checkboxes.actions) {
            const action = extend({}, DEFAULT_ACTIONS_OPTIONS, options.checkboxes.actions[i]);
            action.safeDisable = catchify(
              (s) => (s.length && typeof action.disable === 'function' ? action.disable(s) : !!action.disable),
              'S-DATATABLE: disabling logic failed for action "' + action.name + '"'
            );
            action.safeVisible = catchify(
              action.visible,
              'S-DATATABLE: visibility logic failed for action "' + action.name + '"'
            );
            options.checkboxes.actions[i] = action;
          }
        }
        // init given checkbox selection
        for (const i in options.checkboxes.selection) {
          this.checkboxSelectionMap[safeGet(options.checkboxes.selection[i], options.uniqId)] = true;
        }
      }
      this.opts = options;
    },
  },
};
