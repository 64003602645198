import {STime} from '@veasel/core/time';
export default {
  props: {
    date: {
      type: Object,
      description: 'STime object passed from parent component representing current date',
    },
    to: {
      type: [Object, Boolean],
      description:
        'String or STime object representing the starting date. It is used to limit the start of date/datetime range',
    },
    from: {
      type: [Object, Boolean],
      description: 'String or STime object representing a end date. It is used to limit the end of date/datetime range',
    },
    isOpen: {
      type: Boolean,
      description: 'Boolean representing the state of the picker, open or close',
    },
    selectedDate: {
      type: [Object],
    },
  },
  methods: {
    limitFrom(year, month = 1, day = 1, hour = 23, minute = 59, second = 59) {
      if (year) {
        const fromLimit = this.$props.from
          ? this.$props.from.isAfter(new STime([year, month, day, hour, minute, second]))
          : false;
        return fromLimit;
      } else {
        return false;
      }
    },
    limitTo(year, month = 1, day = 1, hour = 0, minute = 0, second = 0) {
      if (year) {
        const toLimit = this.$props.to
          ? this.$props.to.isBefore(new STime([year, month, day, hour, minute, second]))
          : false;
        return toLimit;
      } else {
        return false;
      }
    },
  },
};
