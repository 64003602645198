<template>
  <div
    :class="[
      'questionnaire-container',
      isSingularQuestionFocused ? 'single-question-view' : '',
      readOnly ? 'read-only' : '',
    ]"
  >
    <div ref="questionWrapper" class="question-wrapper align-left">
      <div
        v-for="(question, index) in questionsToShow"
        ref="question"
        :key="index"
        class="question"
        :data-node-id="question.nodeID"
      >
        <div class="col-6">
          <div
            class="question-text"
            :class="isSingularQuestionFocused ? 'theme-style-header' : 'theme-style-base'"
            v-text="question.text"
          />
        </div>
        <div class="col-6">
          <s-box v-if="!isEmpty(question.guidance)" type="info" size="large" class="m-y-m">
            <div v-html="question.guidance" />
          </s-box>
        </div>
        <div v-if="!readOnly" class="col-6">
          <s-input-radio-group
            v-model="answers[question.nodeID]"
            :options="answersSelectionForRadioBoxes(question.answers)"
            :config="radioInputConfig"
            class="question-options"
            :class="{'m-t-l': isSingularQuestionFocused}"
          />
        </div>
        <div
          v-else-if="answers[question.nodeID]"
          class="read-only-answer"
          v-text="answers[question.nodeID].label"
        ></div>
        <div v-if="devMode" class="dev-troubleshooting">
          <div>
            Question ID:
            <b v-text="question.id"></b>
          </div>
          <div>
            Node ID:
            <b v-text="question.nodeID"></b>
          </div>
          <div>
            Display Order:
            <b v-text="question.displayTime"></b>
          </div>
        </div>
      </div>

      <div v-if="isFinished && isSingularQuestionFocused" class="question question-finished">
        <div class="question-text theme-style-header">Questionnaire Complete</div>
        <div v-if="currentActions.length > 0" class="theme-style-subtitle m-t-m m-b-s">Results are:</div>
        <div class="flex tag-text">
          <div v-for="currentAction in currentActions" :key="currentAction.property_name" class="action-tag">
            <s-label :title="replaceSpecialCharBySpaces(currentAction.property_name)" type="secondary">
              {{ replaceSpecialCharBySpaces(currentAction.property_value) }}
            </s-label>
          </div>
        </div>
        <s-button
          class="continue-button m-t-xxxl"
          text="Continue"
          :on-click="continueQuestionnaire"
          color="theme-active"
        />
        <div v-if="devMode" class="dev-troubleshooting">
          <div><b>Answers:</b></div>
          <div v-text="answers"></div>
        </div>
      </div>
    </div>

    <div v-if="isSingularQuestionFocused || readOnly" class="status-bar">
      <div class="question-navigator flex">
        <transition name="fade">
          <div v-if="canScrollToPrevQuestion" class="flex full-width justify-content-flex-start">
            <div class="navigator-link flex align-items-center" v-on:click="scrollToPreviousQuestion">
              <s-icon type="arrow-drop-left" color="theme-active" />
              <span class="link-title">Previous Question</span>
            </div>
          </div>
        </transition>
        <transition name="fade">
          <div v-if="canScrollToNextQuestion" class="flex full-width justify-content-flex-end">
            <div class="navigator-link flex align-items-center" v-on:click="scrollToNextQuestion">
              <span class="link-title">Next Question</span>
              <s-icon type="arrow-drop-right" color="theme-active" />
            </div>
          </div>
        </transition>
      </div>

      <div class="status-divider"></div>
      <div class="bottom-bar flex flex-wrap justify-content-space-between align-items-center">
        <div class="tag-text">
          <div v-if="currentActions.length" class="theme-style-base-small-bold m-b-xs">
            Attributes
            <span v-if="!readOnly">to</span>
            set:
          </div>
          <div class="flex flex-wrap">
            <div v-for="currentAction in currentActions" :key="currentAction.property_name" class="action-tag">
              <s-label :title="replaceSpecialCharBySpaces(currentAction.property_name)" type="secondary">
                {{ replaceSpecialCharBySpaces(currentAction.property_value) }}
              </s-label>
            </div>
          </div>
        </div>

        <div v-if="!readOnly" class="progress-bar" style="min-width: 100px">
          <s-progress :value="progressPercentage" size="small" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {isEmpty, getValueAtPath} from '@veasel/core/tools';
  import {id, smoothScroll, syncValue} from '@veasel/core/mixins';
  import box from '@veasel/base/box';
  import button from '@veasel/base/button';
  import icon from '@veasel/base/icon';
  import label from '@veasel/base/label';
  import progress from '@veasel/base/progress';
  import inputRadioGroup from '@veasel/inputs/input-radio-group';

  export default {
    name: 's-questionnaire',

    components: {
      's-box': box,
      's-button': button,
      's-icon': icon,
      's-label': label,
      's-progress': progress,
      's-input-radio-group': inputRadioGroup,
    },

    mixins: [id, smoothScroll, syncValue(Array)],
    description:
      'A questionnaire that allows specific questions to be asked based on the previous answer, as well as executing different actions based on answers.',
    props: {
      questions: {
        description: 'The questions to be asked to the user',
        type: Array,
        required: true,
      },
      logic: {
        description: 'The logic to apply from the answers to each question',
        type: Object,
        required: true,
      },
      entrypoint: {
        description: 'The ID of the logic on where to start asking questions',
        type: String,
        required: true,
      },
      logicVariables: {
        description: 'An object that can be used by the logic to figure out which question to go to next',
        type: Object,
      },
      isSingularQuestionFocused: {
        description: 'Should questions show one at a time',
        type: Boolean,
        default: false,
      },
      readOnly: {
        description: 'Should questions not be answerable, and can see the provided answers',
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        test_results: [], // Array to save the data we send to the parent locally
        questionDisplay: {}, // Object to enable which questions are currently showing, and which ones are not
        answers: {}, // Answers from the radio buttons are stored here before we transform them to something usable in the results prop
        radioInputConfig: {required: true, styling: 'button'},
        currentActions: [], // The actions that will currently be run
        canScrollToNextQuestion: false,
        canScrollToPrevQuestion: false,
        progressPercentage: 0,
        isFinished: false,
        isInitialising: true,
        devMode: false, // For troubleshooting questionnaire, not just from development of the component but for the questions and logic
      };
    },
    methods: {
      calculateNextNodeDestination: function (nextNode) {
        if (Array.isArray(nextNode)) {
          const gotoArray = nextNode;
          let gotoResult = null;
          gotoArray.forEach((goToLogic) => {
            if (gotoResult === null) {
              // If we have our results, we don't need to proceed to the next one
              let gotoLogicPasses = false;

              // If we hit a string, we are going to set that
              if (typeof goToLogic === 'string') {
                gotoResult = goToLogic;
                return true;
              }

              // Test each rule and get the results from that
              const ruleResults = [];
              goToLogic.rules.forEach((rule) => {
                const fieldUnderTest = getValueAtPath(this.logicVariables, rule.field);

                // Operator values are eq, neq, in, not_in
                switch (rule.operator.toLowerCase()) {
                  case 'eq':
                    ruleResults.push(fieldUnderTest === rule.value);
                    break;
                  case 'neq':
                    ruleResults.push(fieldUnderTest !== rule.value);
                    break;
                  case 'in':
                    ruleResults.push(rule.value.includes(fieldUnderTest));
                    break;
                  case 'not_in':
                    ruleResults.push(!rule.value.includes(fieldUnderTest));
                    break;
                  default:
                    console.error('SUADE LIBS: Rule operator is not valid in questionnaire component:' + rule);
                    break;
                }
              });

              // Now that we have tested each rule, lets see the condition and if this passes
              switch (goToLogic.condition?.toLowerCase()) {
                case 'or':
                  gotoLogicPasses = ruleResults.filter((result) => result === true).length > 0;
                  break;
                case 'and':
                  gotoLogicPasses = ruleResults.filter((result) => result === true).length === ruleResults.length;
                  break;
                default:
                  if (ruleResults.length === 1 && ruleResults[0] === true) {
                    gotoLogicPasses = true;
                  }
                  break;
              }

              if (gotoLogicPasses) {
                gotoResult = goToLogic.destination;
              }
            }
          });
          if (gotoResult !== null) {
            return gotoResult;
          }
          return null; // No further options, so we just stop the questionnaire
        }
        return nextNode;
      },

      isEmpty(string) {
        return isEmpty(string);
      },
      progressPercentageCalculation() {
        if (this.isFinished) {
          this.progressPercentage = 100;
        } else {
          const amountOfQuestionsLeft = this.nodeLengthCalculation(this.latestQuestion.nodeID, 0);
          this.progressPercentage = Math.round(
            (Object.keys(this.answers).length / (amountOfQuestionsLeft + Object.keys(this.answers).length + 1)) * 100
          );
        }
      },
      // Work out the current maximum length of questions left
      nodeLengthCalculation(currentNode, count) {
        if (typeof currentNode === 'object') {
          currentNode = this.calculateNextNodeDestination(currentNode);
        }
        const answers = Object.keys(this.logic[currentNode].answers);
        const nodeLengths = [];
        answers.forEach((answerKey) => {
          const answer = this.logic[currentNode].answers[answerKey];
          if (answer.goto === null) {
            nodeLengths.push(count);
          } else {
            nodeLengths.push(this.nodeLengthCalculation(answer.goto, count + 1));
          }
        });
        return Math.max(...nodeLengths);
      },
      // Transform the possible answers to be used for the radio box component
      answersSelectionForRadioBoxes(answers) {
        const answersForRadioBoxes = [];
        Object.keys(answers).forEach(function (key) {
          answersForRadioBoxes.push({
            key: key,
            label: answers[key].replace(/[^a-zA-Z0-9]/g, ' '),
          });
        });
        return answersForRadioBoxes;
      },
      completeResults() {
        // Go though the tree and pull out actions and completed answers
        let nextNode = this.entrypoint;
        const completedNodes = [];
        const actions = [];
        do {
          // Get answer for this question
          nextNode = this.calculateNextNodeDestination(nextNode);

          const answer = this.answers[nextNode].key;
          const answerActions = this.logic[nextNode].answers[answer];
          completedNodes.push(nextNode);
          nextNode = answerActions.goto;

          // Add the action to the array of actions
          if (answerActions.actions.length > 0) {
            actions.push(...answerActions.actions);
          }
        } while (nextNode !== null); // Keep looping through the answered questions to make sure we have all the actions

        // Flush out and remove answers to questions that are no longer required
        Object.keys(this.answers).forEach((key) => {
          if (!completedNodes.includes(key)) {
            delete this.answers[key];
          }
        });

        this.$emit('complete', actions);
      },
      scrollToPreviousQuestion() {
        const amountToScroll = this.$refs.questionWrapper.offsetWidth;
        this.smoothScroll(
          this.$refs.questionWrapper,
          'scrollLeft',
          this.$refs.questionWrapper.scrollLeft - amountToScroll,
          500
        ).then(() => {
          // Make sure that this now is divisible by the amount to scroll - we don't want to be left with half widths, otherwise this stuffs up later
          this.$nextTick(() => {
            const amountToMultiply = Math.round(
              this.$refs.questionWrapper.scrollLeft / this.$refs.questionWrapper.offsetWidth
            );
            this.$refs.questionWrapper.scrollLeft = this.$refs.questionWrapper.offsetWidth * amountToMultiply;
            this.checkIfQuestionsViewCanBeScrolled();
          });
        });
      },
      scrollToNextQuestion() {
        const amountToScroll = this.$refs.questionWrapper.offsetWidth;
        this.smoothScroll(
          this.$refs.questionWrapper,
          'scrollLeft',
          this.$refs.questionWrapper.scrollLeft + amountToScroll,
          500
        ).then(() => {
          // Make sure that this now is divisible by the amount to scroll - we don't want to be left with half widths, otherwise this stuffs up later
          this.$nextTick(() => {
            const amountToMultiply = Math.round(
              this.$refs.questionWrapper.scrollLeft / this.$refs.questionWrapper.offsetWidth
            );
            this.$refs.questionWrapper.scrollLeft = this.$refs.questionWrapper.offsetWidth * amountToMultiply;
            this.checkIfQuestionsViewCanBeScrolled();
          });
        });
      },
      replaceSpecialCharBySpaces(string) {
        string = string.toString();
        return string.replace(/[^a-zA-Z0-9]/g, ' ');
      },
      checkIfQuestionsViewCanBeScrolled() {
        this.canScrollToPrevQuestion = this.$refs.questionWrapper.scrollLeft !== 0;
        this.canScrollToNextQuestion =
          this.$refs.questionWrapper.clientWidth + this.$refs.questionWrapper.scrollLeft <
          this.$refs.questionWrapper.scrollWidth - 10;
      },
      continueQuestionnaire() {
        this.$emit('finish');
      },
    },
    computed: {
      // Work out what questions we should show
      questionsToShow() {
        const questionsToShow = [];
        Object.keys(this.logic).forEach((key) => {
          const node = this.logic[key];
          if (this.questionDisplay[key] !== false && Object.keys(node.answers).length > 1) {
            // Find Question Data
            const index = this.questions.findIndex((question) => question.id === node.question_id);
            if (index >= 0) {
              const questionData = this.questions[index];
              questionData.nodeID = key;
              questionData.displayTime = this.questionDisplay[key];
              questionsToShow.push(questionData);
            } else {
              console.error('SUADE LIBS: Cannot find question for question ID: ' + node.question_id);
            }
          }
        });
        questionsToShow.sort((a, b) => (a.displayTime > b.displayTime ? 1 : -1));
        return questionsToShow;
      },
      latestQuestion() {
        return this.questionsToShow[this.questionsToShow.length - 1];
      },
    },
    mounted() {
      // Build the question display array so we know which questions we can show, and which we can't
      Object.keys(this.logic).forEach((key) => {
        this.questionDisplay[key] = false;
      });
      this.questionDisplay[this.entrypoint] = 1;

      // We need to populate the local answers object so if this loads with some answers already answered, we should start somewhere
      if (!isEmpty(this.$_value)) {
        this.$_value.forEach((result) => {
          const questionID = this.logic[result.question].question_id;
          const questionIndex = this.questions.findIndex((question) => {
            return question.id === questionID;
          });

          if (questionIndex >= 0) {
            this.answers[result.question] = {
              label: this.questions[questionIndex].answers[result.answer],
              key: result.answer,
            };
          } else {
            console.warn('SUADE LIBS: Bad answer data for ' + result.question);
          }
        });
      } else {
        this.isInitialising = false;
      }
    },
    emits: ['change', 'complete', 'finish'],
    watch: {
      // Every time a question is answered, we want to show the next question or action something
      answers: {
        handler() {
          // Every question we are going to reset to hide, then show each question based on the answer.
          // This is just in case the user goes back and changes the answer to a question
          Object.keys(this.questionDisplay).forEach((key) => {
            this.questionDisplay[key] = false;
          });

          // Always show the entrypoint question
          this.questionDisplay[this.entrypoint] = 1;

          this.isFinished = false;
          const results = [];
          const actions = [];

          // We need to check if this answer is to a question that is actually being asked - for example, if an answer to a previous question has been asked,
          // and we are no longer asking this question, then we need to delete this answer

          let currentGoTo = this.entrypoint;
          const allowedAnswers = [];
          const toDeleteAnswers = [];

          while (currentGoTo !== null) {
            // Add this to the list of answers we are allowed
            allowedAnswers.push(currentGoTo);

            // Get this next goto based on the current answer
            if (typeof this.answers[currentGoTo] === 'undefined') {
              currentGoTo = null; // We are done
            } else if (Array.isArray(this.logic[currentGoTo].answers[this.answers[currentGoTo].key].goto)) {
              currentGoTo = this.calculateNextNodeDestination(
                this.logic[currentGoTo].answers[this.answers[currentGoTo].key].goto
              );
            } else {
              currentGoTo = this.logic[currentGoTo].answers[this.answers[currentGoTo].key].goto;
            }
          }

          // Now we are going to loop through the answers, and set the display for the next question to true
          Object.keys(this.answers).forEach((key, index) => {
            if (!allowedAnswers.includes(key)) {
              // We don't want this anymore
              toDeleteAnswers.push(key);
              return false;
            }
            const answer = this.answers[key].key;
            const goto = this.calculateNextNodeDestination(this.logic[key].answers[answer].goto);

            // If we are not at the end of the questions
            if (goto !== null) {
              // We set an index to show, rather than simple boolean input. This is so we can create a display order of the questions,
              // rather than relying on the order of questions provided, as what can (and does happen) if that we are directed
              // to a question that is earlier in the list of questions rather than later
              this.questionDisplay[goto] = index + 10;

              // If the next question only has one answer, we want to answer that now, but add the data after a slight delay so this watcher still fires and load the next question
              if (Object.keys(this.logic[goto].answers).length === 1 && typeof this.answers[goto] === 'undefined') {
                this.$nextTick().then(() => {
                  const answerKey = Object.keys(this.logic[goto].answers)[0];
                  const questionIndex = this.questions.findIndex((question) => {
                    return question.id === this.logic[goto].question_id;
                  });
                  this.answers[goto] = {
                    key: answerKey,
                    label: this.questions[questionIndex].answers[answerKey],
                  };
                });
              }
            } else {
              // If the questionnaire is finished
              this.isFinished = true;
            }

            // Add this answer's actions to the array of actions to complete
            if (
              this.logic[key].answers[answer].actions !== undefined &&
              this.logic[key].answers[answer].actions.length > 0
            ) {
              actions.push(...this.logic[key].answers[answer].actions);
            }

            // Update the results to send back
            results.push({
              question: key,
              answer: this.answers[key].key,
            });
          });

          // Delete answers that we no longer need
          toDeleteAnswers.forEach((key) => {
            delete this.answers[key];
          });

          // Send results to components parent (if we aren't first loading the questionnaire)
          if (!this.isInitialising) {
            this.$_value = results;
            this.test_results = results;
          }

          // Save the actions
          this.currentActions = actions;

          // Update the progress percentage
          this.progressPercentageCalculation();

          // If we aren't getting ready to display, and are actioning from a change in answer, then we want to see if we are finished, or fire an update event
          if (!this.isInitialising) {
            if (this.isFinished) {
              this.completeResults();
            } else {
              this.$emit('change');
            }
          }

          // If in scroll-mode, we will scroll across
          if (this.isSingularQuestionFocused && !this.isInitialising) {
            this.$nextTick().then(() => {
              const amountToScroll = this.$refs.questionWrapper.offsetWidth;
              this.smoothScroll(
                this.$refs.questionWrapper,
                'scrollLeft',
                this.$refs.questionWrapper.scrollLeft + amountToScroll,
                500
              ).then(() => {
                this.$nextTick(() => {
                  this.checkIfQuestionsViewCanBeScrolled();
                });
              });
            });
          } else {
            this.$nextTick().then(this.checkIfQuestionsViewCanBeScrolled);
          }
          this.isInitialising = false;
        },
        deep: true,
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@veasel/core';
  .question {
    margin-bottom: 40px;
  }

  .question-text {
    margin-bottom: 8px;
    white-space: pre-wrap;
  }

  .single-question-view .question-wrapper {
    will-change: scroll-position;
    overflow: hidden;
    width: 100%;
    padding: 50px 0;
    box-sizing: border-box;
    display: flex;

    .question {
      min-width: 100%;
      font-size: 40px;

      > .col-5 {
        margin-left: 0;
      }

      &.question-finished {
        .action-tags {
          margin-bottom: 32px;
        }
      }

      .back-arrow {
        font-size: 40px;
      }
    }
  }

  .status-divider {
    width: 100%;
    height: 1px;
    background: $blue;
    opacity: 0.3;
    margin: 12px 0;
  }

  .tag-text {
    text-transform: capitalize;
  }

  .navigator-link {
    @include base-small-bold-active;

    cursor: pointer;
    user-select: none;

    &:hover .link-title {
      text-decoration: underline;
    }
  }

  .read-only {
    .question-text {
      @include base-alt;
    }

    .read-only-answer {
      @include base;

      margin-left: 0.5%;
    }
  }

  .continue-button {
    float: right;
    width: 100px;
  }

  .dev-troubleshooting {
    margin-top: 20px;
    font-size: 14px;
  }
</style>
