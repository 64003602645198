export default {
  props: {
    label: {
      description: 'The descriptive label for the input.',
      type: String,
    },
    labelMod: {
      description: 'A flag to force the label to be outlined.',
      type: String,
      values: ['none', 'left', 'right', 'top', 'inside'],
      default: 'inside',
    },
    labelWidth: {
      description: 'A flag to force the label to be outlined.',
      type: String,
      default: '80px',
    },
  },
  data: function () {
    return {
      inputInternalValue: undefined,
      autofill: false,
      isValueEmpty: false,
    };
  },
  computed: {
    isLabelVisible: function () {
      if (this.labelMod !== 'inside') {
        return true;
      }
      const value = this.modelValue;
      const hasValue =
        value !== undefined &&
        value !== '' &&
        value !== null &&
        (typeof value === 'number' ||
          value.isUtc ||
          (value.length && value.length > 0) ||
          (value instanceof Object && !Array.isArray(value)));
      const isTypingValue =
        this.inputInternalValue !== '' &&
        this.inputInternalValue !== null &&
        this.inputInternalValue !== undefined &&
        this.inputInternalValue.length;
      return hasValue || isTypingValue || this.autofill;
    },
  },
  methods: {
    // This hack detects chrome auto fill using the :-webkit-autofill css pseudoclass and
    animationStart(e) {
      if (e.animationName.startsWith('onAutoFillStart')) {
        this.autofill = true;
      } else if (e.animationName.startsWith('onAutoFillCancel')) {
        this.autofill = false;
      }
    },
  },
  mounted: function () {
    if (this.$el.querySelector('input')) {
      this.$el.querySelector('input').addEventListener('input', (event) => {
        this.inputInternalValue = event.srcElement.value;
      });
    }
  },
};
