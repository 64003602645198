import {isEmpty} from '@veasel/core/tools';

/**
 * Allow a component to call a specific function or emit an event when the input is focus
 * @param {boolean} required
 * @return {object}
 */
const onFocus = function (required = false) {
  return {
    props: {
      onFocus: {
        description: 'Function called or event emitted on input focus',
        type: [Function, String],
        required: required,
      },
    },
    data: function () {
      return {$_isFocused: false};
    },
    methods: {
      $_onFocus: function () {
        this.$data.$_isFocused = true;

        if (
          !isEmpty(this.$_helperTooltipRules) &&
          this.id &&
          this.$refs['helper-tooltip'] &&
          this.$refs['helper-tooltip'].$refs['tooltip']
        ) {
          // on input focus the onMouseEnter handler is not call
          this.$nextTick(() => {
            this.$refs['helper-tooltip'].$refs['tooltip'].onMouseEnter();
          });
        }

        if (typeof this.onFocus == 'function') {
          this.onFocus();
        } else if (typeof this.onFocus == 'string') {
          this.$el.dispatchEvent(new Event(this.onFocus));
        } else if (required) {
          console.error(this.$options.name + ' requires onFocus prop');
        }
      },
    },
  };
};

export default onFocus;
