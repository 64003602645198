import notifications from '../components/notifications.vue';
import {createApp} from 'vue';
import icon from '@veasel/base/icon';
import tooltip from '@veasel/base/tooltip';
import * as Tools from '@veasel/core/tools';

export default {
  install(app, options) {
    let defaultOptions = {
      defaultPosition: 'bottom-right',
      defaultTimeout: 2500,
      disableTimeout: false,
      allowHtml: false,
    };
    if (options) {
      defaultOptions = {...options};
    }
    const notificationsPluginApp = createApp(notifications);
    const mountPoint = document.createElement('div');
    mountPoint.id = 'notifications';
    notificationsPluginApp.provide('defaultOptions', defaultOptions);
    notificationsPluginApp.component('s-icon', icon);
    notificationsPluginApp.component('s-tooltip', tooltip);
    const component = notificationsPluginApp.mount(mountPoint);
    if (document.body) {
      document.body.appendChild(mountPoint);
    }
    app.config.globalProperties.$notify = component;
    app.config.globalProperties.Notify = component;
    notificationsPluginApp.config.globalProperties.$veasel = {
      tools: Tools,
    };
    window.Notify = component;
  },
};
