import {scaleLinear, select, selectAll} from 'd3';

export default {
  name: 'Sparkline',

  data() {
    return {
      showTooltip: false,
      svgGraph: undefined,
      viewBoxOffset: 1,
      graphOptions: {},
      tooltipData: {},
      suadeColors: {
        blue: '--active',
        darkBlue: '--main-dark',
        gray: '--secondary',
        green: '--approved',
        lightBlue: '--light-active',
        red: '--error',
      },
      xLinear: () => {},
      yLinear: () => {},
    };
  },

  methods: {
    getGraphOptions: function (defaultOptions) {
      return {
        ...defaultOptions,
        ...this.options,
      };
    },
    removeGraph: function () {
      const wrapper = selectAll(`[data-sparkline-id="${this.trackId}"] svg`);

      wrapper.remove();
    },
    setupGraph: function () {
      this.removeGraph();

      const wrapper = select(`[data-sparkline-id="${this.trackId}"]`);

      this.svgGraph = wrapper
        .select('[data-chart]')
        .append('svg')
        .attr('width', this.graphOptions.width)
        .attr('height', this.graphOptions.height)
        .attr(
          'viewBox',
          `0 0 ${this.graphOptions.width + this.viewBoxOffset} ${this.graphOptions.height + this.viewBoxOffset}`
        );

      this.svgGraph.on('mouseenter', () => {
        this.showTooltip = true;
      });
    },
    getLinearScaleX: function (xMin, xMax) {
      return scaleLinear().domain([xMin, xMax]).range([0, this.graphOptions.width]);
    },
    getLinearScaleY: function (yMin, yMax) {
      return scaleLinear().domain([yMin, yMax]).range([this.graphOptions.height, this.graphOptions.marginTop]);
    },
  },
};
