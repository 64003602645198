<template>
  <s-input-wrapper v-bind="$props">
    <!-- hidden file input used through 'browse' function to choose files in the user file system -->
    <input
      :id="id"
      ref="input"
      type="file"
      :multiple="multiple"
      :accept="accept"
      @change="
        handleFilesChange($event.target.files);
        $_onChange();
      "
    />

    <!-- Image upload file input -->
    <div
      v-if="inputStyle === 'image'"
      class="image-block flex justify-content-center align-items-center"
      :style="imageProps"
      @drop.prevent="
        handleFilesChange($event.dataTransfer.files);
        $_onChange();
      "
      @dragover.prevent
      @dragenter.prevent
    >
      <span v-if="!$_value[0]">
        Drop your image here, or
        <a @click="browse">browse</a>
      </span>
      <div v-else class="relative" :style="imageProps">
        <img :src="$_value[0].image" @click.self="browse" />
        <s-icon type="cross" @click="() => reset(true)" />
      </div>
    </div>

    <!-- Button or text input file input -->
    <template v-else>
      <s-button
        v-if="inputStyle === 'button'"
        :id="id"
        :name="name"
        :on-click="browse"
        :text="text"
        :icon="icon"
        :disabled="disabled"
        :class="[{disabled: disabled}, colClass]"
        :size="size"
      />
      <input
        v-else
        ref="text-input"
        v-model="displayedInputValue"
        type="text"
        :name="name"
        :placeholder="_placeholder"
        :maxlength="maxlength"
        :disabled="disabled"
        readonly
        class="s-input-group-field"
        @drop.prevent="
          handleFilesChange($event.dataTransfer.files);
          $_onChange();
        "
        @dragover.prevent
        @dragenter.prevent
        @click="browse"
      />

      <s-icon type="cross" class="clear-button" :on-click="() => reset(true)" />
    </template>
  </s-input-wrapper>
</template>

<script>
  import SInputWrapper from '@veasel/inputs/input-wrapper';
  import {
    syncValue,
    onChange,
    onClick,
    onBlur,
    onFocus,
    onKeyPressed,
    onPaste,
    id,
    name,
    readonly,
    placeholder,
    isDisabled,
    isRequired,
    pattern,
    maxLength,
    helperMessages,
  } from '@veasel/core/mixins';

  // inpired by https://scotch.io/tutorials/how-to-handle-file-uploads-in-vue-2
  export default {
    name: 's-input-file',
    description: 'A user input for selecting files.',
    components: {
      SInputWrapper,
    },
    mixins: [
      syncValue([String, Object, Array]),
      onChange(),
      onClick(),
      onBlur(),
      onFocus(),
      onKeyPressed(),
      onPaste(),
      id,
      name,
      readonly,
      placeholder,
      isDisabled,
      isRequired,
      pattern,
      maxLength,
      helperMessages,
    ],
    emits: ['files-read'],
    props: {
      inputStyle: {
        description: 'The style of file input to display',
        type: String,
        values: ['input', 'button', 'image'],
        validator: (val) => ['input', 'button', 'image'].includes(val),
        default: 'input',
      },
      text: {
        description: 'The text to display in the button',
        type: String,
      },
      icon: {
        description: 'The icon to display in the button',
        type: String,
      },
      accept: {
        description:
          'The <a href="https://www.w3schools.com/tags/att_input_accept.asp">file extension, category or media type</a>  accepted (values must be separated by commas)',
        type: String,
      },
      multiple: {
        description: 'A flag to allow multiple file selection',
        type: Boolean,
      },
      type: {
        description:
          'If <em>reader</em>, files content is read (with <em>read-as</em> reader)<br/>If <em>upload</em>, only files meta data is load',
        type: String,
        values: ['upload', 'reader'],
        validator: (val) => ['upload', 'reader'].includes(val),
        default: 'reader',
      },
      readAs: {
        description: 'A reader used to read the file content',
        type: [String, Object],
        values: ['buffer', 'binary', 'data-url', 'text', {}],
        validator: (val) => ['buffer', 'binary', 'data-url', 'text'].includes(val) || typeof val === 'object',
        default: 'text',
      },
      maxSize: {
        description: 'The maximum file size allowed in MB',
        type: Number,
      },
      detail: {
        description: 'A flag to displays the selected files detail',
        type: Boolean,
      },
      height: {
        description: 'Height of image upload area and image',
        type: String,
        default: '300px',
      },
      width: {
        description: 'Width of image upload area and image',
        type: String,
        default: '500px',
      },
    },
    data() {
      return {
        uploadError: undefined,
        uploadedFilesNumber: 0,
        readUploadedFilesNumber: 0,
        $_hasMaxSizeError: false,
        colClass: undefined,
        inputType: 'file',
        imageHover: false,
      };
    },
    computed: {
      displayedInputValue: function () {
        if (!this.$_value) {
          return undefined;
        }
        return this.detail
          ? this.$_value.map((file) => file.name).join('; ')
          : this.$_value.length > 0
          ? this.multiple
            ? this.$_value.length + ' selected file(s)'
            : this.$_value[0].name || '1 selected file'
          : undefined;
      },
      imageProps() {
        return {
          height: this.height,
          width: this.width,
        };
      },
    },
    methods: {
      reset: function (hard) {
        this.$_value = [];
        this.inputInternalValue = undefined;
        this.uploadError = undefined;
        if (hard) {
          this.$refs.input.value = '';
        }
      },
      browse: function () {
        this.reset(true);
        this.$refs.input.click();
      },
      handleFilesChange(files) {
        this.uploadedFilesNumber = files.length;
        this.readUploadedFilesNumber = 0;

        if (!this.multiple) {
          this.reset();
        }
        if (!files.length) {
          this.$_value = [];
          return;
        }
        // $_value could be init in reset() and is used in this code portion
        // so the 'value' prop (use in get computed '$_value') need to be update
        this.$nextTick(() => {
          const sum = (acc, curr) => acc + curr.size;
          const filesList = this.multiple ? Array.from(files) : Array.from(files).slice(-1);
          this.uploadedFilesNumber = filesList.length;
          const totalSize = (filesList.reduce(sum, 0) + this.$_value.reduce(sum, 0)) / 1048576;

          if (this.maxSize == undefined || totalSize <= this.maxSize) {
            for (let i = 0; i < files.length; i++) {
              const f = files[i];
              if (this.type === 'reader' && this.inputStyle !== 'image') {
                this.readFileContent(f);
              } else {
                this.$_value = [
                  ...this.$_value,
                  {
                    name: f.name,
                    type: f.type,
                    size: f.size,
                    lastModified: f.lastModified,
                    image: URL.createObjectURL(f),
                  },
                ];
              }
            }
            this.$data.$_hasMaxSizeError = false;
          } else {
            this.$data.$_hasMaxSizeError = true;
          }
        });
      },
      readFileContent: function (file) {
        const reader = new FileReader();
        const self = this;

        reader.onload = function (event) {
          // Add new file object with its content
          self.$_value.push({
            name: file.name,
            type: file.type,
            size: file.size,
            lastModified: file.lastModified,
            content: event.target.result,
          });
          self.readUploadedFilesNumber++;

          if (self.uploadedFilesNumber === self.readUploadedFilesNumber) {
            self.$emit('files-read', self.$_value);
          }
        };

        let readAs;
        if (typeof this.readAs === 'string') {
          readAs = this.readAs;
        } else {
          const extension = file.name.split('.').reverse()[0];
          const index = Object.values(this.readAs).findIndex((extensions) => extensions.includes(extension));
          readAs = Object.keys(this.readAs)[index];
        }
        switch (readAs) {
          case 'buffer':
            reader.readAsArrayBuffer(file);
            break;
          case 'binary':
            reader.readAsBinaryString(file);
            break;
          case 'data-url':
            reader.readAsDataURL(file);
            break;
          case 'text':
          default:
            reader.readAsText(file);
            break;
        }
      },
    },
    mounted() {
      // get the 'col-' class and apply it to the sl-input-text or button
      // and remove it from the current s-input-file element
      // this.colClass = [...this.$el.classList].filter((c) => c.startsWith('col-'))[0];
      // if (this.colClass) {
      //   this.$el.classList.remove(this.colClass);
      // }
    },
  };
</script>

<style lang="scss" scoped>
  @import '@veasel/core/sass/conf';

  .dropbox {
    outline: 2px dashed grey; /* the dash box */
    outline-offset: -10px;
    background: lightcyan;
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
  }

  .dropbox:hover {
    background: lightblue; /* when mouse over to the drop zone, change color */
  }

  .dropbox ::v-deep(p) {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
  }

  input[type='file'] {
    display: none;
  }

  // FILE INPUT (UPLOAD)
  .sl-input {
    ::v-deep(input),
    ::v-deep(button) {
      cursor: pointer;
    }

    .sl-input-group.file.is-button {
      width: 100%;

      ::v-deep(button.s-button) {
        width: 100%;
        display: block;
        color: $white;
      }
    }
  }

  // FILE BUTTON (READER)
  .s-button.file-reader {
    padding: 0;
    height: 36px;

    input {
      display: none;
    }
  }

  .is-error,
  .is-required {
    ::v-deep(input) {
      border: 1px solid white !important;
    }
  }

  .clear-button {
    display: none;
    position: absolute;
    cursor: pointer;
    right: 6px;
    top: calc(50% - 8px);
  }

  .sl-input:hover {
    .clear-button {
      display: block;
    }
  }

  a {
    color: $link-color;
    cursor: pointer;
    font-weight: $font-weight-heavy;
  }

  .image-block {
    border: dashed 1px gray;
    padding: 10px;

    img {
      object-fit: cover;
      height: inherit;
      width: inherit;
    }

    .s-icon-cross {
      position: absolute;
      top: 5px;
      right: 5px;
      visibility: hidden;
      opacity: 0.2;
      -webkit-transition: opacity 200ms, visibility 200ms;
      transition: opacity 200ms, visibility 200ms;
    }
  }

  .image-block:hover {
    .s-icon-cross {
      visibility: visible;
      opacity: 1;
    }
  }
</style>
