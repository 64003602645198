import hash from 'object-hash';
import {deepExtend} from '@veasel/core/tools';

import OPTIONS_HELPER from './optionsHelper';

const optionsCache = {};

const optionsFactory = function (optionsProp) {
  const getObjectOptions = function (options) {
    const objectOptions = deepExtend({}, options);

    if (options['NEXT_STEP']) {
      objectOptions['NEXT_STEP'] = getNextStepOptionFrom(options);

      if (objectOptions['NEXT_STEP'].options) {
        const nestedOptions = deepExtend([], objectOptions['NEXT_STEP'].options);

        for (let i = 0; i < nestedOptions.length; i++) {
          const nestedOption = nestedOptions[i];

          objectOptions['NEXT_STEP'].options[i] = getObjectOptions(nestedOption);
        }
      }
    }

    return objectOptions;
  };

  const getNextStepOptionFrom = function (source) {
    let stepOption = undefined;
    const nextStep = source['NEXT_STEP'];

    if (typeof nextStep === 'string') {
      // merge options object from 'options' prop and generic options defined in 'OPTIONS_HELPER'
      stepOption = deepExtend(
        {},
        getStepOptionByKey(OPTIONS_HELPER, nextStep),
        getStepOptionByKey(optionsProp, nextStep)
      );
    } else {
      // merge additional options in 'nextStep' and those from 'options' prop and those from 'OPTIONS_HELPER'
      stepOption = deepExtend(
        {},
        getStepOptionByKey(OPTIONS_HELPER, nextStep.INHERITED_STEP, nextStep),
        getStepOptionByKey(optionsProp, nextStep.INHERITED_STEP, nextStep),
        nextStep
      );
    }

    // delete unused props in search components
    delete stepOption.INHERITED_STEP;
    delete stepOption.INHERITED_STEP_FUNCTION_ARGS;

    return stepOption;
  };

  const getStepOptionByKey = function (source, key, stepObject) {
    let stepOption = undefined;
    if (source && source[key]) {
      let nextStep = source[key];

      // if the step is a function
      if (stepObject && stepObject.INHERITED_STEP_FUNCTION_ARGS) {
        nextStep = source[key].apply(null, stepObject.INHERITED_STEP_FUNCTION_ARGS);
      }

      // merge additional options in 'nextStep' and those from 'options' prop and those from 'OPTIONS_HELPER'
      stepOption = deepExtend(
        {},
        getStepOptionByKey(OPTIONS_HELPER, nextStep.INHERITED_STEP),
        getStepOptionByKey(optionsProp, nextStep.INHERITED_STEP),
        nextStep
      );
    }

    return stepOption;
  };

  // Hash the incoming options property
  const optionsHash = hash(JSON.stringify(optionsProp));

  // If the cache does not contain an entry for this hash, generate the options object and cache it
  if (!optionsCache[optionsHash]) {
    optionsCache[optionsHash] = getObjectOptions(optionsProp);
  }

  // Return the options object from the cache
  return optionsCache[optionsHash];
};

export default optionsFactory;
