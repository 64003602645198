import {uniqId} from '@veasel/core/tools';

export default {
  props: {
    id: {
      description: 'The html identifier for the input.',
      type: String,
      default: () => uniqId('id_'),
    },
  },
};
