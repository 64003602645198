export default {
  data() {
    return {
      lastWidth: null,
      lastHeight: null,
    };
  },
  methods: {
    checkForSizeChange() {
      this.$nextTick(() => {
        const clientWidth = Math.max(this.$el.clientWidth, 0);
        const clientHeight = Math.max(this.$el.clientHeight, 0);

        if (clientWidth !== this.lastWidth || clientHeight !== this.lastHeight) {
          this.lastWidth = clientWidth;
          this.lastHeight = clientHeight;
          this.redrawDueToResize();
        }
      });
    },

    redrawDueToResize() {
      /* Stub, implement in chart component if needed */
    },
  },

  mounted() {
    window.addEventListener('resize', this.checkForSizeChange);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.checkForSizeChange);
  },
};
