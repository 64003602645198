<template>
  <div class="dropdown-item-wrapper">
    <div
      :id="uID"
      :class="['flex', 'align-items-center', 'dropdown-item', disable ? 'disabled' : '', indentItem ? 'indent' : '']"
      @click="(event) => (disable ? event.preventDefault() : $_onClick(event))"
    >
      <s-icon
        v-if="!isEmpty(buttonIcon)"
        :type="buttonIcon"
        class="icon m-r-s"
        :color="disable ? 'theme-secondary-50' : 'theme-secondary'"
      />
      <div :class="buildClassList">
        {{ buttonText }}
        <slot></slot>
      </div>
    </div>
    <s-tooltip v-if="tooltipText" :position="tooltipPosition" color="theme-background-main" :target="'#' + uID">
      {{ tooltipText }}
    </s-tooltip>
  </div>
</template>

<script>
  import {isEmpty, uniqId} from '@veasel/core/tools';
  import {onClick} from '@veasel/core/mixins';
  import icon from '@veasel/base/icon';
  import tooltip from '@veasel/base/tooltip';

  export default {
    name: 's-dropdown-item',
    components: {
      's-icon': icon,
      's-tooltip': tooltip,
    },
    mixins: [onClick(false)],
    description: 'A component to display a item, built for use within the dropdown component',
    props: {
      buttonText: {
        description: 'The text to describe item action.',
        type: String,
        default: ' ',
      },
      buttonIcon: {
        description: 'The icon to have next to the text',
        type: String,
        default: '',
      },
      disable: {
        description: 'A flag to allow user to click on the item or not.',
        type: Boolean,
        default: false,
      },
      tooltipText: {
        description: 'Text to appear in a tooltip when hovered over',
        type: String,
        default: '',
      },
      tooltipPosition: {
        description: 'Position of tooltip when hovered over',
        type: String,
        default: 'right',
      },
      indentItem: {
        description: 'Indents the item so then it appears to be a subitem',
        type: Boolean,
        default: false,
      },
      isAnchor: {
        description: 'A flag to say that the slot is occupied by an HTML anchor',
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        uID: '',
      };
    },
    computed: {
      buildClassList() {
        const styleClasses = [this.disable ? 'theme-style-caption-secondary-50' : 'theme-style-caption', 'item-text'];

        if (this.isAnchor) {
          styleClasses.push('dropdown-item-anchor');
        }
        return styleClasses;
      },
    },
    methods: {
      isEmpty,
    },
    created() {
      this.uID = uniqId('uid-');
    },
  };
</script>

<style lang="scss" scoped>
  @import '@veasel/core';

  .icon {
    font-size: 14px;
  }

  .icon-spacer {
    width: 12px;
  }

  .dropdown-item-wrapper {
    position: relative;
  }

  .dropdown-item {
    padding: rem(9px) rem(11px);

    &:not(.disabled) {
      transition: ease background $transition-time;
      cursor: pointer;

      &:hover {
        background-color: var(--secondary-light);

        ::v-deep(i) {
          color: var(--main-dark);
        }
      }
    }

    ::v-deep(.icon) {
      display: flex;
      align-items: center;
    }

    &.indent {
      padding-left: get-spacing('l');

      &::before {
        content: '';
        width: 4px;
        height: 1px;
        background: var(--secondary);
        display: block;
        position: absolute;
        top: 50%;
        left: 12px;
      }
    }

    &-anchor {
      display: inline-block;
      width: 100%;
      height: 100%;
      text-align: left;

      & ::v-deep(a) {
        display: inherit;
        width: inherit;
        height: inherit;
        color: inherit;
      }
    }
  }
</style>
