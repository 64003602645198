/**
 * Defines the component size ('small', 'medium', 'large')
 * @param {string[]} availableSizes
 * @param {string} defaultValue
 * @return {Object}
 */
const size = function (availableSizes = ['small', 'medium'], defaultValue = 'medium') {
  return {
    props: {
      size: {
        description: 'The input visual size.',
        type: String,
        values: availableSizes,
        validator: (val) => availableSizes.includes(val),
        default: defaultValue,
      },
    },
  };
};

export default size;
