<!-- eslint-disable max-len -->
<!-- Ideally, the SVGs should be served with the icon system, but we want to change it to SVG - so in order to get the new logo and colours released, I did it like this -->
<!-- When we change icons and fonts, then we should remove this disabler as it shouldn't be required anymore -->
<template>
  <div id="app" class="app-parent">
    <header v-if="isAuth && pass_changed && !needsMFASetup && !bad_gateway" class="relative">
      <!-- TOPBAR -->
      <s-topbar
        id="topbar-container"
        :height="topbarHeight"
        :navbarExpandedWidth="0"
        :isNavbarCollapsed="false"
        :usePrimaryBackground="setTopbarBackgroundAsPrimary"
        data-cy="topbar"
      >
        <template v-slot:header>
          <div class="x-grid full-width full-height">
            <div class="row x-row align-items-center full-height justify-content-space-between">
              <div class="col-2 line-height-0">
                <div class="logo" :class="[navbarCollapsed ? 'm-l-s' : 'm-l-l']">
                  <s-icon type="suade-logo-full" size="95" />
                </div>
              </div>
              <div class="col-10 justify-content-flex-end align-items-center flex full-height">
                <div class="m-t-s m-r-l">
                  <VersionWarning />
                </div>
                <div class="m-t-s m-r-l" data-cy-blackout>
                  <EnvironmentLabel :env="environment" />
                </div>
                <div v-if="devModeIsOn || demoModeIsOn" class="devmode" data-cy="dev-mode-indicator">
                  <div id="additional-mode" class="theme-style-caption-secondary">*</div>
                  <s-tooltip target="#additional-mode" position="bottom">
                    <div v-html="additionalModeInfo"></div>
                  </s-tooltip>
                </div>

                <s-button
                  data-cy="running-jobs"
                  :on-click="() => $router.push({path: '/running-jobs'})"
                  styling="block"
                  color="theme-background-secondary"
                >
                  <s-icon class="topbar-icon" type="guage" color="theme-secondary" size="20" />
                  <span
                    v-if="runningJobsCount > 0"
                    class="theme-style-base-small-bold-secondary animation-sticker-hover"
                  >
                    <span class="red-icon"></span>
                  </span>
                  <span class="theme-style-caption-secondary p-l-s button-text vertical-align-middle">Jobs</span>
                </s-button>

                <s-button
                  styling="block"
                  color="theme-background-secondary"
                  class="button-border todo"
                  :on-click="() => $router.push({path: '/home', query: {tab: 'todo'}})"
                  data-cy="todo"
                >
                  <span class="vertical-align-middle">
                    <s-icon class="topbar-icon" type="todo-clipboard" color="theme-secondary" size="20" />
                    <span v-if="todo.count > 0" class="theme-style-base-small-bold-secondary animation-sticker-hover">
                      <span class="red-icon todo"></span>
                    </span>
                  </span>

                  <span class="theme-style-caption-secondary p-l-s button-text vertical-align-middle">To-Do</span>
                </s-button>

                <s-dropdown
                  class="button-border"
                  text="Help"
                  button-styling="block"
                  button-color="theme-background-secondary"
                  align="right"
                  data-cy="dropdown-help"
                >
                  <template #button>
                    <s-icon class="topbar-icon" type="help" color="theme-secondary" size="20" />
                    <span class="p-l-s theme-style-caption-secondary button-text help-text">Help</span>
                  </template>

                  <s-dropdown-item button-text="Help" :on-click="openHelpDesk" />
                  <s-dropdown-item button-text="Releases" :on-click="() => $router.push({path: '/system/releases'})" />
                  <s-dropdown-item
                    :isAnchor="true"
                    tooltipText="Regulatory Understanding & User Forum"
                    tooltipPosition="left"
                  >
                    <a target="_blank" href="https://ruuf.suade.org/">RUUF</a>
                  </s-dropdown-item>
                </s-dropdown>

                <s-dropdown
                  button-styling="block"
                  button-color="theme-background-secondary"
                  class="button-border"
                  align="right"
                  data-cy="dropdown-user"
                >
                  <template #button>
                    <UserThumbnail
                      :url="'/api' + current.photo_url"
                      class="inline-block thumbnail topbar-thumb"
                      :profile-id="current.id"
                      :username="current.username"
                    />
                  </template>

                  <s-dropdown-title>
                    {{ current.username }}
                  </s-dropdown-title>

                  <s-dropdown-item button-text="Settings" :on-click="() => $router.push({path: '/user/settings'})" />
                  <s-dropdown-item button-text="Logout" :on-click="() => $router.push({path: '/logout'})" />
                </s-dropdown>
              </div>
            </div>
          </div>
        </template>
      </s-topbar>
    </header>
    <s-xhr-loader
      class="xhr-loader"
      :class="[isAuth && pass_changed && !needsMFASetup && !bad_gateway ? 'm-t-xxxxl' : 'bad-gateway']"
    ></s-xhr-loader>
    <!-- NAVBAR -->
    <s-navbar
      v-if="isAuth && pass_changed && !needsMFASetup && !bad_gateway"
      id="navbar-container"
      data-cy="navbar"
      aria-label="Navigation"
      :menu="navbar"
      :width="navbarWidth"
      :margin-top="topbarHeight"
      :permission="navbarPermissionCheck"
      :link-permission="navbarPermissionLinkCheck"
      :collapsed="navbarCollapsed"
      :usePrimaryBackground="setNavbarBackgroundAsPrimary"
      :logoURL="imageLogoURL"
      :logoSquareURL="imageLogoSquareURL"
      @update-collapse="updateNavbarCollapseState"
      @logout="logOut()"
    />

    <!-- PAGE CONTENT -->
    <main v-if="isFullscreenPage" class="relative" data-cy="page-container">
      <router-view id="page" ref="routerView" />
    </main>

    <main
      v-else-if="!bad_gateway"
      class="content relative"
      :class="{'navbar-open': !navbarCollapsed}"
      data-cy="page-container"
    >
      <router-view id="page" ref="routerView" />
    </main>

    <!-- SYSTEM UNAVAILABLE MESSAGE -->
    <main v-else class="system-unavailable align-center relative">
      <div class="theme-style-base-alt">
        Suade system is temporarily unavailable. Please try again shortly. If this issue persists, contact your system
        administrator.
      </div>
      <br />
      <s-button text="Retry" icon="refresh" color="theme-active" :onClick="retryClicked" />
    </main>

    <aside id="side-panel-wrapper" aria-label="Side panel"></aside>
  </div>
</template>

<script>
  import {mapState, mapGetters} from 'vuex';

  import Navbar from '@/config/navbar.js';
  import Routes from '@/config/routes.js';

  import AppFunctionsMixin from '@/modules/_utils/appFunctionsMixin.js';
  import UserThumbnail from '@/modules/_components/userThumbnail.vue';
  import JobsAnimation from '@/assets/animations/jobs.json';
  import TodoAnimation from '@/assets/animations/todo.json';
  import HelpAnimationData from '@/assets/animations/help.json';

  import API from '@/services/api';
  import {isEmpty} from '@veasel/core/tools';
  import EnvironmentLabel from '@/modules/_components/environmentLabel.vue';
  import VersionWarning from '@/modules/_components/versionWarning.vue';

  export default {
    components: {
      EnvironmentLabel,
      UserThumbnail,
      VersionWarning,
    },

    mixins: [AppFunctionsMixin],

    data: function () {
      return {
        navbar: Navbar,

        // Sizes
        navbarWidth: 240,
        topbarHeight: 57,
        setTopbarBackgroundAsPrimary: true,
        setNavbarBackgroundAsPrimary: true,
        jobsAnimation: {},
        jobsAnimationData: JobsAnimation,
        todoAnimation: {},
        todoAnimationData: TodoAnimation,
        helpAnimation: {},
        helpAnimationData: HelpAnimationData,
        animationOptions: {
          autoPlay: false,
          loop: true,
        },
        longPollInterval: '',
        jobList: [],
        fullscreenPages: ['login'],
      };
    },

    computed: {
      ...mapState('user', ['current', 'isAuth', 'pass_changed', 'features', 'todo', 'navbarCollapsed']),

      ...mapState('system', [
        'color_primary',
        'image_logo',
        'image_logo_square',
        'bad_gateway',
        'environment',
        'feature_flags',
      ]),

      ...mapGetters('user', ['needsMFASetup']),

      isFullscreenPage() {
        return this.$route.name ? this.fullscreenPages.includes(this.$route.name.toLowerCase()) : false;
      },

      runningJobsCount() {
        return this.jobList.filter((item) => ['started', 'running', 'queued'].includes(item.status)).length;
      },

      imageLogoURL: function () {
        if (!isEmpty(this.image_logo)) {
          return '/api/file/logo/' + this.image_logo;
        }
        return '';
      },

      imageLogoSquareURL: function () {
        if (!isEmpty(this.image_logo_square)) {
          return '/api/file/logo/' + this.image_logo_square;
        }
        return '';
      },

      devModeIsOn: function () {
        return this.features.includes('dev_mode');
      },

      demoModeIsOn: function () {
        return this.features.includes('demo_mode');
      },

      devmodeSourceMessage: function () {
        return this.current.dev_mode_source === 'local' ? '(local)' : '';
      },

      demomodeSourceMessage: function () {
        return this.current.demo_mode_source === 'local' ? '(local)' : '';
      },

      additionalModeInfo() {
        return (
          (this.devModeIsOn ? 'Development mode: active ' + this.devmodeSourceMessage : '') +
          (this.devModeIsOn && this.demoModeIsOn ? '<br/>' : '') +
          (this.demoModeIsOn ? 'Demo mode: active ' + this.demomodeSourceMessage : '')
        );
      },

      routerView() {
        return this.$refs.routerView;
      },

      xhrWidth() {
        const width = this.navbarCollapsed ? 66 : this.navbarWidth;
        return {
          width: 'calc(100% - ' + width + 'px)',
        };
      },
    },

    // Disconnect from the websocket service when leaving the page / destroying the modal
    beforeUnmount() {
      this.$root.webSocketsUnsubscribe('Topbar');
      clearInterval(this.longPollInterval);
    },

    mounted() {
      // This check allows for a refresh scenario where we will need to re-subscribe
      // If we are already subscribed this will be ignored
      if (this.isAuth) {
        this.$root.webSocketsSubscribe('AllJobs', false);
        this.$root.webSocketsSubscribeEvent('AllJobs', 'jobs', this.processWSmessage);
      }
      this.requestJobs();
      this.updateTopbarColor();
    },

    methods: {
      playAnimation(jobsAnimation) {
        jobsAnimation.play();
      },

      stopAnimation(jobsAnimation) {
        jobsAnimation.pause();
      },

      processWSmessage(job) {
        if (job) {
          const index = this.jobList.map((j) => j.id).indexOf(job.id);

          if (index > -1) {
            // Job already in list, update with new information
            this.jobList.splice(index, 1, job);
          } else {
            // Job not in list, needs to be inserted at the top
            this.jobList.unshift(job);
          }
        }
      },

      requestJobs() {
        API.getJobs({
          limit: 100,
          offset: 0,
        })
          .then((results) => {
            this.jobList = results.data;
          })
          .catch((err) => {
            console.error(err);
          });
        return true;
      },

      // searchKeyPressed(event) {
      //   // When the user presses enter emit the search event and string
      //   if (event.key === 'Enter') {
      //     this.search(this.searchStringInternal);
      //   }
      // },
      //
      // search(string) {
      //   // TODO: Implement search when the endpoint is available
      //   Popup.show({title: 'Search', text: 'Search functionality is not currently implemented', icon: 'info'});
      // },

      // This function is passed to the navbar to be used to check if the user has permission to see each item
      navbarPermissionCheck(item) {
        if (isEmpty(item.service)) {
          item._service = ((Routes.find((r) => r.path == item?.to.path) || {}).meta || {}).service;
        } else {
          item._service = item.service;
        }
        item._calculators = ((Routes.find((r) => r.path == item?.to.path) || {}).meta || {}).calculators;
        let shouldShow =
          (!item._service || (item._service && this.sl_feat(item._service, 'by_service'))) &&
          (!item._calculators || (item._calculators && this.sl_feat(item._calculators, 'by_calculators'))) &&
          (!item.to || (item?.to && this.sl_perm(item?.to.path || item?.to, 'by_page')));

        // If the user has a submenu permission, then we want to show that menu item
        if (typeof item.submenu === 'object') {
          item.submenu.forEach((submenu) => {
            if (this.navbarPermissionCheck(submenu)) {
              shouldShow = true;
            }
          });
        }
        return shouldShow;
      },
      updateNavbarCollapseState(newValue) {
        this.$store.dispatch('user/setNavbarCollapsed', newValue);
      },
      // Get the best page to go to based on permissions of a link
      navbarPermissionLinkCheck(item) {
        if (
          (!item.service || (item.service && this.sl_feat(item.service, 'by_service'))) &&
          (!item.to || (item.to && this.sl_perm(item.to.path || item.to, 'by_page')))
        ) {
          return item;
        }

        // If the user has a submenu permission, then we want to show that menu item
        if (typeof item.submenu === 'object') {
          const validPages = item.submenu.filter((submenu) => this.navbarPermissionCheck(submenu));
          if (validPages.length > 0) {
            return validPages[0];
          }
        }
        return item;
      },

      retryClicked() {
        window.location.reload();
      },
      updateTopbarColor() {
        if (!isEmpty(this.color_primary)) {
          document.documentElement.style.setProperty('--topbar', this.color_primary);
        } else {
          document.documentElement.style.removeProperty('--topbar');
        }
      },
      openHelpDesk() {
        window.open('https://suade.zendesk.com/hc/en-us/categories/8829542009757-User-Guide', '_blank');
      },
    },

    watch: {
      isAuth() {
        if (this.isAuth === false) {
          this.$router.push({
            path: '/login',
            query: {
              ...this.$route.query,
              last_route: this.$route.path,
            },
          });

          this.$root.webSocketsUnsubscribe('AllJobs');
        } else {
          this.$root.webSocketsSubscribe('AllJobs', false);
          this.$root.webSocketsSubscribeEvent('AllJobs', 'jobs', this.processWSmessage);
        }
      },
      $route(to) {
        if (typeof to.name !== 'undefined') {
          document.title = to.name + ' - Suade: Bridging the Regulatory Gap through Modern Technology';
        } else {
          document.title = 'Suade: Bridging the Regulatory Gap through Modern Technology';
        }
      },

      needsMFASetup() {
        if (this.needsMFASetup === true && this.$route.path !== '/login') {
          this.$router.push({
            path: '/login',
            query: {
              ...this.$route.query,
              last_route: this.$route.path,
            },
          });
        }
      },
      color_primary: function () {
        this.updateTopbarColor();
      },
    },
  };
</script>

<!-- eslint-disable-next-line -->
<style lang="scss">
  @import '@veasel/core';

  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }

  .content {
    margin: 62px 0 10px 67px;
    width: calc(100% - 50px);

    &.navbar-open {
      margin-left: 241px;
      width: calc(100% - 241px);
    }
  }

  // Adjust the sweet alert modal to fit better with the app style (no rounded corners, correct green)
  .swal-modal {
    border-radius: 0;
  }

  .swal-button {
    border-radius: 0;
  }

  .swal-button--logout.session-timeout {
    background-color: $gray;
  }

  .swal-button--extend.session-timeout {
    background-color: $green;
  }

  .swal-button.disable-mfa {
    color: #555;
    background-color: #efefef;

    &:not([disabled]):hover {
      background-color: #e8e8e8;
    }
  }

  body {
    background: var(--background-secondary);
  }

  .page {
    box-sizing: border-box;
    min-height: calc(100vh - 90px);

    .s-page-header {
      top: 40px;
      padding-bottom: 12px; // (*1) To visualize top shadow: compensates .body margin (4px less)
    }

    .body {
      padding: get-spacing('s') get-spacing('m') get-spacing('max') get-spacing('m');
      background: var(--background-main);
      box-shadow: 0 0 6px #8a98b929;
      border-radius: $border-radius;
      margin-top: get-spacing('xs'); // (*1) To visualize top shadow: 4px;
      flex-grow: 1; // Only grows if .container applied
    }
  }

  // Vue Transitions

  // Simple fade transition
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.25s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  // Misc
  .clickable-box {
    border-radius: $border-radius;
    cursor: pointer;
    transition: opacity calc($transition-time / 2) ease-in-out;

    &:hover {
      background: var(--light-hover);
    }

    .s-icon {
      display: flex;
      align-items: center;
    }
  }
</style>

<style lang="scss" scoped>
  @import '@/sass/conf';
  .system-unavailable {
    margin-top: 20px;

    ::v-deep(.s-button .svg-icon) {
      color: var(--secondary);
    }
  }

  #app {
    display: flex;
  }

  .logo {
    display: inline-block;

    ::v-deep(svg path:nth-child(1)),
    ::v-deep(svg path:nth-child(2)),
    ::v-deep(svg path:nth-child(3)) {
      fill: var(--active);
    }
  }

  .button-animation {
    display: inline-block;
    margin-top: 2px !important;
    vertical-align: middle;
    margin-right: 8px;
  }

  .animation-sticker-hover {
    position: absolute;
    margin-top: 10px;
    margin-left: -8px;
  }

  ::v-deep(.s-dropdown) > .s-button::after {
    color: var(--secondary);
  }

  .topbar-thumb ::v-deep(.s-icon-default-userpic)::before {
    fill: var(--secondary);
  }

  .topbar-icon {
    display: inline-block;
    vertical-align: middle;
    line-height: 10px;
  }

  .vertical-align-middle {
    vertical-align: middle;
  }

  #topbar-container {
    border-bottom: 1px solid #d9d9d9;
  }

  .red-icon {
    width: 10px;
    height: 10px;
    display: block;
    background: var(--error);
    border-radius: 50px;
    margin-top: 3px;
    margin-left: 0;
    box-sizing: border-box;
    border: 2px solid var(--background-secondary);
  }

  .xhr-loader {
    &.bad-gateway {
      margin-top: 0;
    }
  }

  .help-text {
    vertical-align: middle;
  }
</style>
