<template>
  <div class="list-main-container">
    <!-- This component has been copied from the datalist component and modified -->
    <!-- Anything commented with (different from datalist) has been added otherwise it has come from datalist. Some parts have been deleted -->

    <!-- List Features Row, above list content -->
    <div v-if="showDatalistHeader" class="datalist-header relative flex align-items-center">
      <!-- Select / Deselect all checkboxes, leftmost -->
      <div v-if="opts.checkboxes.display && opts.checkboxes.selectAll" class="checkbox-all-container">
        <s-input-checkbox
          v-model="checkboxSelectAll"
          class="checkbox-all"
          :on-change="updateCheckboxSelectAll"
          :title="checkboxSelectAll ? 'Unselect all' : 'Select all'"
          size="small"
          :label="selectAllLabel"
          data-cy="checkbox-all"
        />
      </div>

      <!--Actions, left -->
      <div
        v-if="
          opts.exportView.listing ||
          (opts.checkboxes.display &&
            opts.checkboxes.actions.length > 0 &&
            opts.checkboxes.actions.some((a) => a.visible))
        "
        class="list-actions"
      >
        <!-- Lonely Action Button (if there is only one enabled action) -->
        <s-button
          v-if="hasOnlyOneEnabledAction"
          class="lonely-action-button"
          :text="actions[0].name"
          :sticker="checkboxSelection.length ? '' + checkboxSelection.length : false"
          :on-click="callTheActionCallback"
          :disable="!opts.exportView.listing && checkboxSelection.length === 0"
        />
        <!-- Actions Dropdown -->
        <s-dropdown
          v-else
          ref="actionDropdown"
          align="left"
          button-text="Actions"
          :button-sticker="checkboxSelection.length ? '' + checkboxSelection.length : false"
          :disable="!opts.exportView.listing && checkboxSelection.length === 0"
        >
          <ul class="options">
            <li
              v-for="(action, index) in actions"
              v-show="action.safeVisible(checkboxSelection)"
              :key="index"
              :class="{disable: action.safeDisable(checkboxSelection)}"
            >
              <a :data-action="action.name" @click="action.callback(checkboxSelectionIds, checkboxSelection)">
                {{ action.name }}
              </a>
              <div class="disable-tooltip right">
                <s-icon type="help"></s-icon>
                {{ action.disabledMsg }}
              </div>
            </li>
          </ul>
        </s-dropdown>
      </div>

      <!-- Additional filters, centre left  -->
      <div v-if="opts.additionalFilter.display && opts.additionalFilter.filters.length" class="list-additional-filter">
        <s-dropdown
          ref="additionalFilters"
          button-text="Filters"
          :button-sticker="currentFilterCount"
          class="filters-dropdown"
          data-cy="datalist-dropdown-filter"
        >
          <div class="additional-filters-content">
            <div
              v-for="(filter, index) in opts.additionalFilter.filters"
              :key="filter.key"
              class="row x-row"
              :class="{'margin-top': index !== 0, ['filter-' + filter.key]: true}"
            >
              <component
                :is="'sl-input-' + filter.field"
                :id="filter.key"
                v-model="filter.default"
                :class="{'content-right': ['bool'].includes(filter.field), 'col-12': !['bool'].includes(filter.field)}"
                v-bind="filter.fieldOptions"
                :on-change="filterChange"
              />
            </div>
          </div>
        </s-dropdown>
      </div>

      <!-- Group by input select (different to datalist) -->
      <div class="col-2">
        <s-input-select
          v-model="currentGroup"
          :options="groupBy.options"
          label="Group By"
          v-bind="groupBy.props"
          class="input-select-group"
        />
      </div>

      <!-- Search bar, centre & expand right -->
      <div class="list-search relative">
        <div v-if="opts.search.display">
          <s-input-text v-model="searchText" placeholder="Search" data-cy="datalist-search-input" />
          <!-- Not in use, disabling in case for future use -->
          <!--          <span>{{ opts.pagination.num_entries + ' results' }}</span>-->
          <!--          <div v-if="opts.search.display" class="list-search-result float left"></div>-->
        </div>
      </div>

      <div class="additional-buttons float right relative">
        <slot name="buttons"></slot>
      </div>
    </div>

    <!-- Group Rows (different to datalist) -->
    <div
      v-for="(group, index) in groupedRows"
      :key="'group-row-' + index"
      class="group-row"
      :class="{'no-group': !currentGroup}"
    >
      <label v-if="currentGroup">{{ group[0][currentGroup.toLowerCase()] }}</label>

      <div class="list-rows-container">
        <template v-for="(row, index) in filteredGroup(group)" :key="'list-row-' + index + '-' + queryIndex">
          <div
            :id="'list-row-' + index"
            class="list-row"
            :class="{'no-padding': options.noPadding, disabled: row.disabled}"
          >
            <div
              v-if="opts.checkboxes.display"
              class="checkbox-inline-container"
              :title="row.actionAvailable ? undefined : 'No available action for this row'"
            >
              <s-input-checkbox
                v-if="row.actionAvailable"
                :id="'checkbox-' + safeGet(row, opts.uniqId)"
                v-model="checkboxSelectionMap[safeGet(row, opts.uniqId)]"
                :disabled="row.disabled"
                size="small"
              />
              <!-- checkbox is not displayed if none action is available for the row -->
              <div v-else :style="{width: '18px'}"></div>
            </div>
            <div class="slot-inline-container" :class="opts.checkboxes.display ? 'fit-checkbox' : ''">
              <slot v-bind:row="row" v-bind:index="index"></slot>
            </div>
          </div>
        </template>
      </div>

      <!-- Show more button (different to datalist) -->
      <div class="align-center m-t-m m-b-m">
        <s-button
          v-if="currentGroup && group.length > groupCounts[group[0][currentGroup.toLowerCase()]]"
          text="More"
          size="small"
          color="theme-secondary"
          @click="groupCounts[group[0][currentGroup.toLowerCase()]] += offset"
        />
        <s-button
          v-else-if="!currentGroup && group.length > allCount"
          text="More"
          size="small"
          color="theme-secondary"
          @click="allCount += offset"
        />
      </div>
    </div>

    <!-- Loading panel covers rows -->
    <s-loading-panel :state="opts.loading ? 'loading' : 'completed'" />
  </div>
</template>

<script>
  import {debounce, safeGet} from '@veasel/core/tools';
  // mixins
  import {
    optionsLogic,
    columnsLogic,
    checkboxLogic,
    exportLogic,
    sortingLogic,
    searchLogic,
    paginationLogic,
    queryLogic,
    additionalFilterLogic,
    groupLogic,
  } from '@veasel/core/mixins';

  import button from '@veasel/base/button';
  import {dropdown} from '@veasel/base/dropdown';
  import icon from '@veasel/base/icon';
  import inputCheckbox from '@veasel/inputs/input-checkbox';
  import inputText from '@veasel/inputs/input-text';

  const DEFAULT_OPTION_OVERRIDES = {
    frontend: false, // Boolean (force pagination and search etc to only be handled back end)
    stickyHeader: false, // Boolean | Number (headers not present in datalist)
    stickyScroller: false, // Boolean | Number (scroller not present in datalist)
    settings: {
      pageSize: false,
    },
  };

  export default {
    name: 's-group-list',

    components: {
      's-button': button,
      's-dropdown': dropdown,
      's-icon': icon,
      's-input-checkbox': inputCheckbox,
      's-input-text': inputText,
    },

    mixins: [
      optionsLogic,
      checkboxLogic,
      exportLogic,
      paginationLogic,
      columnsLogic,
      queryLogic,
      searchLogic,
      sortingLogic,
      additionalFilterLogic,
      groupLogic,
    ],

    props: {
      rows: {type: Array, default: () => []},
      options: {type: Object, default: () => ({})},
      groupBy: {
        type: Object,
        default: () => ({
          options: [], // List of groups for the group by select input
          props: {}, // Other props to bind to the select input (e.g. custom-label)
        }),
      },
    },

    emits: ['checkboxes-updated', 'export-view', 'filter-updated', 'query-updated', 'search-updated'],

    computed: {
      alteredRows: function () {
        return this.rows.map((row) => ({...row, actionAvailable: this.hasOneActionAvailable(row)}));
      },
      showDatalistHeader: function () {
        return (
          (this.opts.checkboxes.display && this.opts.checkboxes.selectAll) ||
          (this.opts.checkboxes.display &&
            this.opts.checkboxes.actions.length > 0 &&
            this.opts.checkboxes.actions.some((a) => a.visible)) ||
          (this.opts.additionalFilter.display && this.opts.additionalFilter.filters.length) ||
          this.opts.search.display ||
          this.opts.exportView.listing ||
          this.$slots.buttons
        );
      },
    },

    created: function () {
      this.initOptions(DEFAULT_OPTION_OVERRIDES);
    },

    mounted: function () {
      this.throttledSearch = debounce(this.searchInRows, this.opts.search.timeLimit);
    },

    data: function () {
      return {
        safeGet: safeGet,
        queryIndex: 0, // Appended to the :key property of each row to ensure the row and all sub-components are re-rendered correctly
      };
    },

    watch: {
      rows: function () {
        this.queryIndex += 1; // Increment the query index to ensure the :key property of each row is new
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@veasel/core';

  .list-row {
    padding: 5px 0 5px 0;
    border-top: 1px solid $lightgray;
    display: flex;
    flex-direction: row;

    &:hover {
      background: $lightgray;
    }

    &.no-padding {
      padding: 0;
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .list-row:last-of-type {
    border-bottom: 1px solid $lightgray;
  }

  .checkbox-inline-container {
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-left: 10px;
  }

  .slot-inline-container {
    flex: 1 0 auto;
    max-width: 100%;

    &.fit-checkbox {
      max-width: calc(100% - 26px);
      margin-left: 6px;
    }
  }

  .pagination-container {
    padding-top: 15px;
  }

  .datalist-header {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;

    .checkbox-all-container {
      flex: 0 1 auto;
      padding-left: 10px;
      padding-right: 10px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    .list-actions {
      flex: 0 1 auto;
      margin-right: 10px;

      :not(:has(.lonely-action-button)) {
        width: 130px;
      }

      .lonely-action-button {
        width: 100%;
      }
    }

    .list-additional-filter {
      flex: 0 1 auto;
      margin-right: 8px;
    }

    .list-search {
      flex: 1 0 auto;
      margin-left: 8px;

      span {
        position: absolute;
        top: 25px;
        right: 3px;
        font-size: 10px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .list-export button {
      margin-left: 10px;
    }

    .list-settings {
      width: 130px;
      min-height: 38px;
    }

    ::v-deep(.s-dropdown) {
      user-select: none;
      width: 100%;

      .additional-filters-content {
        text-align: left;
      }

      .settings-content {
        width: 300px;
        text-align: left;

        .table-size {
          select {
            width: 60px;
          }
        }

        .table-ordering {
          .container {
            overflow-y: auto;
            max-height: 220px;
          }

          li {
            position: relative;
            height: 18px;
            padding: 4px 6px;

            label {
              padding-left: 24px;
              cursor: pointer;
            }

            input {
              width: 18px;
              height: 18px;
              left: 0;
              margin-top: -1px;
              position: absolute;
            }
          }
        }
      }
    }

    .additional-buttons ::v-deep(.s-button) {
      margin-left: 10px;
    }
  }

  .input-select-group {
    margin-top: -10px;
    min-width: 300px;
  }

  .group-row {
    :not(&.no-group) {
      .list-row:first-of-type {
        border-top: none !important;
      }

      .list-row:last-of-type {
        border-bottom: none !important;
      }
    }

    label {
      @include subtitle;
      margin-bottom: get-spacing('s');
      display: block;
    }
  }
</style>
