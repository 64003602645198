<template>
  <div v-if="showTooltip" class="graph-tooltip" :style="`top: ${details.posY}px; left: ${details.posX}px;`">
    <span v-if="showDot" class="graph-tooltip-dot" :style="`color: ${details.dotColor ?? '#000'};`">&middot;</span>
    <span class="graph-tooltip-text">{{ details.text }}</span>
  </div>
</template>

<script>
  export default {
    name: 's-graph-tooltip',
    props: {
      showDot: {
        type: Boolean,
        description: 'A flag to enable or disable the colour dot on the tooltip.',
        default: true,
      },
      showTooltip: {
        type: Boolean,
        description: 'A flag to enable or disable the tooltip.',
        default: false,
      },
      details: {
        type: Object,
        description: 'Data object containing coords and text for the tooltip',
      },
    },
  };
</script>

<style lang="scss" scoped>
  .graph-tooltip {
    padding: 0 5px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    line-height: 1.2rem;
    font-size: 0.8rem;
    border: 1px solid #fff;
    white-space: pre-line;
    min-width: max-content;

    &-text,
    &-dot {
      vertical-align: middle;
      display: inline-block;
    }

    &-dot {
      font-size: 2.4rem;
      height: 22px;
      line-height: 1.1rem;
    }
  }
</style>
