import popup from '../components/popup.vue';
import button from '@veasel/base/button';
import animation from '@veasel/base/animation';

import {createApp} from 'vue';

export default {
  install() {
    const popupApp = createApp(popup);
    const mountPoint = document.createElement('div');
    mountPoint.id = 'popup';
    popupApp.component('s-animation', animation);
    popupApp.component('s-button', button);
    const popupElement = popupApp.mount(mountPoint);
    if (document.body) {
      document.body.appendChild(mountPoint);
    }
    window.Popup = Object.assign(
      {},
      {
        show: popupElement.show,
        getState: popupElement.getState,
        close: popupElement.close,
      }
    );
  },
};
