<template>
  <component :is="'s-input-' + props.type" v-bind="props" v-model="$_value" @enter="$emit('enter')" />
</template>

<script>
  import {syncValue, id, name, size} from '@veasel/core/mixins';

  import inputCheckbox from '@veasel/inputs/input-checkbox';
  import inputColor from '@veasel/inputs/input-color';
  import inputDatetime from '@veasel/inputs/input-datetime';
  import inputFile from '@veasel/inputs/input-file';
  import inputMonetary from '@veasel/inputs/input-monetary';
  import inputNumber from '@veasel/inputs/input-number';
  import inputQuery from '@veasel/inputs/input-query';
  import inputRadio from '@veasel/inputs/input-radio';
  import inputRadioGroup from '@veasel/inputs/input-radio-group';
  import inputSelect from '@veasel/inputs/input-select';
  import inputSlider from '@veasel/inputs/input-slider';
  import inputText from '@veasel/inputs/input-text';
  import inputTextarea from '@veasel/inputs/input-textarea';

  export default {
    name: 's-input',
    description: 'A user input as a switch button for boolean value.',
    components: {
      's-input-checkbox': inputCheckbox,
      's-input-color': inputColor,
      's-input-datetime': inputDatetime,
      's-input-file': inputFile,
      's-input-monetary': inputMonetary,
      's-input-number': inputNumber,
      's-input-query': inputQuery,
      's-input-radio': inputRadio,
      's-input-radioGroup': inputRadioGroup,
      's-input-select': inputSelect,
      's-input-slider': inputSlider,
      's-input-text': inputText,
      's-input-textarea': inputTextarea,
    },
    mixins: [syncValue([String, Object, Array, Number]), id, name, size(['small', 'medium'], 'small')],

    props: {
      props: {
        description: 'The object containing all the props.',
        type: Object,
      },
    },
    emits: ['enter'],
  };
</script>
