<template>
  <s-input-wrapper v-bind="$props">
    <multiselect
      :id="id"
      ref="multiselect"
      v-model="internalValue"
      v-select-overflow="overflow"
      :name="name"
      :options="displayedOptions"
      :show-no-options="optionsSearch == undefined"
      :show-no-results="optionsSearch == undefined"
      :multiple="multiple"
      :taggable="tagging"
      :max="max"
      :placeholder="_placeholder"
      :disabled="disabled"
      :searchable="searchable || hasHiddenOptions || tagging"
      :internal-search="!optionsSearch"
      :close-on-select="!multiple && !keepOpenOnSelect"
      :clear-on-select="!multiple"
      :options-limit="maxDisplayedOptions"
      :limit="maxDisplayedValues"
      :track-by="$data.$_trackBy"
      :label="$data.$_label"
      :custom-label="customLabel"
      :allow-empty="allowEmpty"
      :block-keys="noEnter ? ['Enter'] : []"
      :selectLabel="noEnter ? '' : ''"
      tag-placeholder="Press enter to create a new tag"
      :group-label="hasGroups ? 'group' : undefined"
      :group-values="hasGroups ? 'options' : undefined"
      :hide-selected="hideSelected"
      selectedLabel=""
      :max-height="maxHeight ? maxHeight : 300"
      class="s-input-group-field fake-input"
      @tag="addTag"
      @update:modelValue="inputUpdated"
      @search-change="onQueryChange"
      @open="focusAndScrollTop"
      @close="blurAndResetInput"
    >
      <!-- override 'vue-multiselect' displayed messages -->
      <template v-slot:afterList>
        <span v-if="hasHiddenOptions" class="multiselect__option after-list theme-style-caption">
          ... ({{ $data.$_options.length }} total options)
        </span>
        <li
          v-if="additionalButton"
          class="multiselect__element additional_button"
          @click="$emit('additional-button-clicked')"
        >
          <span class="multiselect__option">{{ additionalButton }}</span>
        </li>
      </template>

      <template v-slot:noResult>
        <span class="multiselect__option no-result theme-style-caption">No options found</span>
      </template>

      <template v-slot:maxElements>
        <span class="multiselect__option max-elements theme-style-caption">Maximum of {{ max }} options selected</span>
      </template>

      <template v-slot:noOptions>
        <span class="multiselect__option no-options theme-style-caption">List is empty</span>
      </template>

      <template v-slot:caret>
        <s-icon class="multiselect__custom-arrow" type="arrow-drop-down" color="theme-secondary" :size="10" />
      </template>

      <template v-slot:tag="{option, search, remove}">
        <slot name="tag" :option="option" :search="search" :remove="remove"></slot>
      </template>
    </multiselect>
  </s-input-wrapper>
</template>

<script>
  import SInputWrapper from '@veasel/inputs/input-wrapper';
  import {clone} from '@veasel/core/tools';
  import {
    syncValue,
    onChange,
    onClick,
    onBlur,
    onFocus,
    onKeyPressed,
    onPaste,
    id,
    name,
    placeholder,
    isDisabled,
    isRequired,
    pattern,
    maxLength,
    helperMessages,
    size,
  } from '@veasel/core/mixins';
  import multiselect from '@suadelabs/vue3-multiselect';

  export default {
    name: 's-input-select',
    description: 'A user input to select one or many values from a list of options (based on vue-multiselect).',
    emits: ['label-on-top', 'update:searchString', 'keydown', 'additional-button-clicked'],
    components: {
      SInputWrapper,
      multiselect,
    },
    mixins: [
      syncValue([String, Array, Object, Number]),
      onChange(),
      onClick(),
      onBlur(),
      onFocus(),
      onKeyPressed(),
      onPaste(),
      id,
      name,
      placeholder,
      isDisabled,
      isRequired,
      pattern,
      maxLength,
      helperMessages,
      size(['small', 'medium'], 'medium'),
    ],
    props: {
      options: {
        description: 'The list of options to choose from.',
        type: Array,
        default: () => [],
      },
      sort: {
        description: 'A flag to sort the options alphabetically.',
        type: Boolean,
        default: true,
      },
      multiple: {
        description: 'A flag to allow multiple selection.',
        type: Boolean,
      },
      tagging: {
        description: 'A flag to allow selecting values not listed in the options list.',
        type: Boolean,
      },
      min: {
        description: 'The minimum number of item to select.',
        type: Number,
      },
      max: {
        description: 'The maximum number of item to select.',
        type: Number,
      },
      searchable: {
        description: 'A flag to allow searching through options.',
        type: Boolean,
        default: true,
      },
      maxDisplayedOptions: {
        description: 'The maximum number of options to display at the same time in the dropdown list.',
        type: Number,
        default: 5000,
      },
      maxDisplayedValues: {
        description: 'The maximum number of selected values to display in the input.',
        type: Number,
      },
      trackBy: {
        description: 'The uniq key used to identify the options.',
        type: String,
      },
      labelKey: {
        description: 'The text value used to display the options to users.',
        type: String,
      },
      customLabel: {
        description: 'A function to construct the option display value using other options fields.',
        type: Function, // ex: ({ id, name, info }) => name + ' - ' + info;
      },
      optionsSearch: {
        description: 'A function used to asynchronously update the option list.',
        type: Function, // ex: used for AJAX options update
      },
      selectLabel: {
        description: 'A string to say that an option is selected.',
        type: String,
      },
      notFoundRenderer: {
        description: 'A function to render an item when a given value is not found in options.',
        type: Function,
      },
      noEnter: {
        description: 'A flag to allow the use of Enter key to select values.',
        type: Boolean,
      },
      searchString: {
        description: 'The search string value.',
        type: String,
      }, // The partial string entered by the user to search for available options
      hideSelected: {
        description: 'A flag to remove the selected options from the option list.',
        type: Boolean,
        default: false,
      },
      maxHeight: {
        description: 'The maximum height the input can grow to.',
        type: Number,
      },
      overflow: {
        description: 'Indicates whether the input dropdown should escape from scrollable overflow containers',
        type: String,
        values: ['auto', 'enabled', 'disabled'],
        validator: (v) => ['auto', 'enabled', 'disabled'].includes(v),
        default: 'auto',
      },
      allowEmpty: {
        description: 'A flag to temporary allow empty value even if field is required.',
        type: Boolean,
        default: true,
      },
      keepOpenOnSelect: {
        description: 'A flag to keep the select opened after selection.',
        type: Boolean,
        default: false,
      },
      additionalButton: {
        description: 'An additional button to the option list.',
        type: String,
        default: '',
      },
    },
    data: function () {
      return {
        inputType: 'select',
        $_options: [],
        $_trackBy: undefined,
        $_label: undefined,
        internalValue: undefined,
        hideDropdown: false,
        presetValue: undefined,
        helperTooltipPosition: 'top',
        open: false,
        above: false,
        observer: null,
      };
    },
    computed: {
      displayedOptions: function () {
        return this.sortOptions(clone(this.$data.$_options));
      },
      hasHiddenOptions: function () {
        return this.$data.$_options.length >= this.maxDisplayedOptions;
      },
      hasGroups: function () {
        return (
          this.optionsAreObject() && this.$data.$_options[0].group && Array.isArray(this.$data.$_options[0].options)
        );
      },
    },
    watch: {
      options: {
        handler: function (newValue) {
          this.$data.$_options = [...newValue];
          this.objectOptionsSettings();
          this.internalValue =
            this.$_value === undefined ? this.formatToOption(this.presetValue) : this.formatToOption(this.$_value);
          this.presetValue = undefined;
        },
        deep: true,
      },
      trackBy: function (newValue) {
        this.$data.$_trackBy = newValue;
      },
      labelKey: function (newValue) {
        this.$data.$_label = newValue;
      },
      internalValue: {
        immediate: true,
        deep: true,
        handler: function (newInternalValue, oldInternalValue) {
          if (JSON.stringify(newInternalValue) !== JSON.stringify(oldInternalValue)) {
            this.$_value = this.formatToKey(newInternalValue);
          }
        },
      },
      $_value: {
        immediate: true,
        deep: true,
        handler: function (newValue) {
          // if there is isRequired mixin
          if (this.required !== undefined) {
            this.$_requireValidate(newValue);
          }
          this.internalValue = this.formatToOption(newValue);
          this.objectOptionsSettings();
          this.$data.$_options = this.sortOptions(clone(this.$data.$_options));
        },
      },
    },
    methods: {
      addTag: function (newTag) {
        const valueToAdd = this.optionsAreObject()
          ? {
              [this.$data.$_trackBy]: newTag,
              [this.$data.$_label]: newTag,
            }
          : newTag;

        this.$data.$_options.push(valueToAdd);

        if (this.multiple) {
          if (typeof this.$_value === 'undefined' || typeof this.$_value === 'string') {
            this.$_value = [valueToAdd];
          } else {
            this.$_value.push(valueToAdd);
          }
        } else {
          this.$_value = valueToAdd;
        }
        this.$_onChange();
      },
      optionsAreObject: function () {
        return this.$data.$_options.length && typeof this.$data.$_options[0] === 'object';
      },
      objectOptionsSettings: function () {
        if (this.optionsAreObject()) {
          this.$data.$_trackBy = this.$data.$_trackBy == undefined ? 'key' : this.$data.$_trackBy;
          this.$data.$_label = this.$data.$_label == undefined ? 'label' : this.$data.$_label;
        }
      },

      sortOptions: function (options) {
        if (this.sort) {
          const isSelectedValue = (v) => {
            if (this.trackBy) {
              return (
                v[this.trackBy] === this.$_value ||
                (Array.isArray(this.$_value) && this.$_value.includes(v[this.trackBy]))
              );
            } else {
              return v === this.$_value || (Array.isArray(this.$_value) && this.$_value.includes(v));
            }
          };

          const comparatorFactory = (secondComparator) => {
            return (a, b) => {
              if (isSelectedValue(a) && !isSelectedValue(b)) {
                // display the selected values before
                return -1;
              }

              if (!isSelectedValue(a) && isSelectedValue(b)) {
                // display the selected values before
                return 1;
              }

              // sort with the comparator
              return secondComparator(a, b);
            };
          };

          if (this.optionsAreObject()) {
            const labelComparator = this.customLabel
              ? (a, b) => (this.customLabel(a) || '').localeCompare(this.customLabel(b) || '')
              : (a, b) => (a[this.$data.$_label] || '').localeCompare(b[this.$data.$_label] || '');

            const comparator = comparatorFactory(labelComparator);

            if (this.hasGroups) {
              for (const groupOptions of options) {
                groupOptions.options.sort(comparator);
              }
            } else {
              options.sort(comparator);
            }
          } else {
            options.sort(comparatorFactory((a, b) => (a + '').localeCompare(b + '')));
          }
        }
        return options;
      },
      onQueryChange: function (query) {
        // Update the synced search string value to inform the parent what has been typed into the multiselect
        this.$emit('update:searchString', query);

        if (this.optionsSearch && typeof this.optionsSearch == 'function') {
          const result = this.optionsSearch(query);
          if (result instanceof Promise) {
            Promise.resolve(result).then((options) => {
              this.$data.$_options = options;
            });
          } else {
            this.$data.$_options = result;
          }
        }
      },
      inputUpdated: function (value) {
        this.$_value = this.formatToKey(value);
        this.$_onChange();
      },
      formatToKey: function (val) {
        let formatedValue = val;
        if (val !== null && val != undefined && val != '' && this.trackBy) {
          if (this.multiple) {
            formatedValue = val.map((v) => v[this.trackBy]);
          } else {
            formatedValue = val[this.trackBy];
          }
        }
        return formatedValue;
      },
      formatToOption: function (val) {
        let unformatedValue = val;
        if (val !== null && val != undefined && val != '' && this.trackBy) {
          if (this.multiple) {
            unformatedValue = val.map(this.findOptionForKey).filter((v) => v !== null);
          } else {
            unformatedValue = this.findOptionForKey(val);
          }
        }
        if (val !== undefined && !this.options.length) {
          this.presetValue = val;
          if (!this.tagging) {
            return undefined;
          }
        }
        return unformatedValue;
      },
      findOptionForKey: function (key) {
        if (this.options[0] && typeof this.options[0] !== 'string') {
          let reconciliatedOption;
          if (this.hasGroups) {
            reconciliatedOption = this.options.flatMap((g) => g.options).find((o) => o[this.trackBy] === key);
          } else {
            reconciliatedOption = this.options.find((o) => o[this.trackBy] === key);
          }
          if (!reconciliatedOption && this.options.length > 0) {
            reconciliatedOption = this.notFoundRenderer
              ? this.notFoundRenderer(key, this.trackBy)
              : {
                  [this.trackBy]: key,
                  label: key + '*',
                };
          }
          return reconciliatedOption;
        } else if (this.tagging) {
          return {[this.trackBy]: key, label: [this.trackBy]};
        } else {
          return key;
        }
      },
      blurAndResetInput: function (e) {
        if (this.label) {
          this.inputInternalValue = undefined;
        }
        this.$_onBlur(e);
        this.helperTooltipPosition = 'top';

        this.open = false;
        this.$emit('label-on-top', false);
      },
      focusAndScrollTop: function (e) {
        this.$_onFocus(e);
        this.$nextTick(() => {
          this.$el.querySelector('.multiselect__content-wrapper').scrollTop = 0;
          this.helperTooltipPosition = this.$el.querySelectorAll('.multiselect--above').length ? 'bottom' : 'top';
        });

        this.open = true;
      },

      scrollEvent(e) {
        // Only close the select when a scroll occurs if the overflow hack is active
        if (this.open && this.$el.classList.contains('select-overflow-active') && !this.$el.contains(e.target)) {
          this.$el.querySelector('input').blur();
        }
      },
    },
    created() {
      // hide the dropdown when the input allow only tagging
      this.hideDropdown = this.options == undefined && this.tagging;
      this.$data.$_options = Array.isArray(this.options) ? this.options : [];
      this.$data.$_trackBy = this.trackBy;
      this.$data.$_label = this.labelKey;
      this.objectOptionsSettings();
    },

    mounted() {
      if (this.searchable) {
        this.$refs.multiselect.$refs.search.addEventListener('paste', this.$_onPaste);
      }

      // Watch for class changes on the multiselect
      if (typeof MutationObserver === 'function') {
        this.observer = new MutationObserver((mutations) => {
          for (const m of mutations) {
            const classStr = m.target.getAttribute(m.attributeName);

            // Set or clear the above flag, to indicate if the list of options is displayed upwards
            this.above = classStr.includes('multiselect--above');
          }
        });

        this.observer.observe(this.$refs.multiselect.$el, {
          attributes: true,
          attributeOldValue: true,
          attributeFilter: ['class'],
        });
      }

      window.addEventListener('scroll', this.scrollEvent, true);
      if (this.$el.querySelectorAll('input[name="select"]').length) {
        this.$el.querySelector('input[name="select"]').addEventListener('keydown', (event) => {
          this.$emit('keydown', event);
        });
      }
    },

    beforeUnmount() {
      if (this.observer) {
        this.observer.disconnect();
      }

      window.removeEventListener('scroll', this.scrollEvent, true);
    },
  };
</script>

<style lang="scss" scoped>
  @import '@veasel/core';

  $marginOffset: 0.5rem;
  $paddingOffset: 0.125rem;

  ::v-deep(.input-label-inside.select-container) {
    .multiselect,
    .multiselect--disabled {
      .multiselect__single {
        margin-top: $marginOffset;
      }

      .multiselect__tag {
        margin-top: $marginOffset;
        margin-bottom: -$marginOffset;
        padding-top: 0;
        padding-bottom: 0;
      }

      .multiselect__tag-icon {
        top: -0.25rem;
      }
    }
  }

  ::v-deep(.select-container.small) {
    .multiselect,
    .multiselect--disabled {
      .multiselect__tag {
        margin-top: 1px;
        padding: 0 0.75rem;
        color: $black;
      }

      .multiselect__placeholder {
        color: $black;
      }

      .multiselect__tag-icon {
        display: none;
      }
    }
  }

  ::v-deep(.input-label-inside.select-container.small) {
    .multiselect,
    .multiselect--disabled {
      .multiselect__single {
        margin-top: 0;
      }

      .multiselect__tag {
        margin-top: 1px;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
      }

      .multiselect__tag-icon {
        top: -1px;
      }
    }
  }

  ::v-deep(.select-container) {
    &.disabled {
      cursor: not-allowed;
    }

    &.hidden-dropdown {
      .multiselect__content-wrapper,
      .multiselect__select {
        display: none;
      }
    }

    .multiselect {
      position: relative;
      width: 100%;

      &.multiselect--active:not(.is-error):not(.is-required) {
        border: none;
      }

      &:hover,
      &.multiselect--active {
        background: var(--background-main);
        box-shadow: 0 0 6px #00000029;
      }

      .multiselect__custom-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: $marginOffset;
        transition: ease all $transition-time;
        width: 10px;
      }

      &.multiselect--active:not(.multiselect--above) .multiselect__custom-arrow {
        transform: translateY(-50%) rotate(180deg);
      }
    }

    &.small {
      .multiselect,
      .multiselect__tags {
        min-height: 1.5rem;
        padding-top: 0;
      }

      .multiselect__input {
        left: 2px;
        top: 2px;
      }

      .multiselect__input,
      .multiselect__single {
        margin-top: 0;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 0;
      }

      .multiselect__placeholder {
        padding-top: 0.25rem;
      }

      .multiselect__content-wrapper {
        top: calc(100% + 7px);
        bottom: auto;
        left: 0;
        width: 100%;
      }

      .multiselect--above .multiselect__content-wrapper {
        bottom: calc(100% + 5px);
        top: auto;
      }

      .multiselect__tag {
        top: 2px;
        padding: 1px 32px 1px 12px;
        margin-right: $marginOffset;
      }

      .multiselect__tag-icon {
        line-height: 20px;
      }
    }

    fieldset[disabled] .multiselect {
      pointer-events: none;
    }

    .multiselect * {
      box-sizing: border-box;
    }

    .multiselect--disabled {
      background-color: $input-disabled-color;
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.6;
      border-color: $black;

      .multiselect__tag {
        margin-right: $marginOffset;
        padding: 4px 12px 4px 12px;
        color: $black;
      }

      .multiselect__placeholder {
        color: $black;
      }

      .multiselect__tag-icon {
        display: none;
      }
    }

    .multiselect--active:not(.multiselect--above) .multiselect__current,
    .multiselect--active:not(.multiselect--above) .multiselect__input,
    .multiselect--active:not(.multiselect--above) .multiselect__tags {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .multiselect--above.multiselect--active .multiselect__current,
    .multiselect--above.multiselect--active .multiselect__input,
    .multiselect--above.multiselect--active .multiselect__tags {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .multiselect--active .multiselect__select {
      transform: rotateZ(180deg);
    }

    .multiselect__input {
      margin-top: 4px;
    }

    .multiselect__single {
      display: flex;
      margin-top: 4px;
      margin-bottom: 0;
    }

    .multiselect__input,
    .multiselect__single {
      position: relative;
      min-height: 20px;
      border: none;
      transition: border 0.1s ease;
      box-sizing: border-box;
      vertical-align: top;
      background: transparent;

      &:focus {
        width: auto !important;
        border: 0;
        outline: none;
      }

      &::placeholder {
        opacity: 1;

        @include caption-secondary;
      }
    }

    .multiselect__tag ~ .multiselect__input,
    .multiselect__tag ~ .multiselect__single {
      width: auto;
    }

    .multiselect__input:hover,
    .multiselect__single:hover {
      border-color: #cfcfcf;
    }

    .multiselect__tags-wrap {
      display: inline;
    }

    .multiselect__tags {
      min-height: 2rem;
      display: block;
      padding: $paddingOffset 2.5rem 0 0;
      border: 0;
      background: transparent;
      border-bottom-color: transparent;
    }

    .multiselect__tag {
      position: relative;
      display: inline-block;
      padding: 4px 33px 4px 12px;
      margin-right: $marginOffset;
      border: 1px solid var(--secondary-light);
      box-sizing: border-box;
      line-height: 1;
      background: var(--background-main);
      margin-bottom: 0;
      white-space: nowrap;
      overflow: hidden;
      max-width: 100%;
      text-overflow: ellipsis;
      top: 1px;
      border-radius: $button-radius;
      @include base-small;
    }

    .multiselect__tag:hover {
      background: $lightgray-darker;
    }

    .multiselect__tag-icon {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      font-weight: 700;
      font-style: initial;
      width: 32px;
      text-align: center;
      transition: all 0.2s ease;
    }

    .multiselect__tag-icon::after {
      content: '×';
      color: var(--secondary);
      font-size: 0.8rem;
      font-weight: bold;
      line-height: 1.5rem;
    }

    .multiselect__current {
      line-height: 16px;
      min-height: 32px;
      box-sizing: border-box;
      display: block;
      overflow: hidden;
      padding: 8px 12px 0;
      padding-right: 30px;
      white-space: nowrap;
      margin: 0;
      text-decoration: none;
      border: none;
      cursor: pointer;
    }

    .multiselect__select {
      line-height: 32px;
      display: block;
      position: absolute;
      box-sizing: border-box;
      width: 40px;
      height: 97%;
      right: 1px;
      top: 1px;
      padding: 4px 8px;
      margin: 0;
      text-decoration: none;
      text-align: center;
      cursor: pointer;
    }

    .multiselect__select::before {
      position: relative;
      right: 0;
      top: calc(50% - 7px);
      color: $black;
      margin-top: 4px;
      border: none;
      content: '';
    }

    .multiselect__placeholder {
      @include caption-secondary;

      padding-top: 6px;
      padding-left: 2px;
      display: inline-block;
      margin-bottom: 0;
    }

    .multiselect--active .multiselect__placeholder {
      display: none;
    }

    .multiselect__content-wrapper {
      background-color: $white;
      position: absolute;
      display: block;
      width: 100%;
      left: 0;
      top: calc(100% + 7px);
      overflow: auto;
      border: none;
      border-top: none;
      z-index: 91;
      -webkit-overflow-scrolling: touch;
      box-shadow: 0 -6px 0 #fff, 0 0 6px #00000029; // Add white shadow to give the appearance of a single element with the dropdown and the orginal bar
      border-radius: 0 0 4px 4px;
    }

    .multiselect__content {
      list-style: none;
      display: inline-block;
      padding: 0 0 8px 0;
      margin: 0;
      min-width: 100%;
      vertical-align: top;
      max-width: 100%;
    }

    .multiselect--above .multiselect__content-wrapper {
      left: 0;
      top: auto;
      max-width: 100%;
      bottom: calc(100% + 5px);
      box-shadow: 0 4px 0 #fff, 0 0 6px #00000029; // Add white shadow to give the appearance of a single element with the dropdown and the orginal bar
      border-radius: 4px 4px 0 0;
      border-bottom: none;
      border-top: none;
    }

    .multiselect__content::-webkit-scrollbar {
      display: none;
    }

    .multiselect__element {
      display: block;
      font-size: rem(12px);

      @include caption-font;
    }

    .multiselect__option {
      display: block;
      padding: 8px 12px;
      min-height: 24px;
      line-height: 16px;
      text-decoration: none;
      text-transform: none;
      position: relative;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;

      &.after-list,
      .no-result,
      .max-elements,
      .no-options {
        cursor: default;
      }

      &[data-select='Press enter to create a new tag'] {
        color: $black;

        &::after {
          content: attr(data-select);
          color: $black;
          background: white;
        }
      }
    }

    .multiselect__option--highlight {
      background: var(--secondary-light);
      outline: none;
    }

    .multiselect__element.additional_button {
      font-weight: bold;
      text-align: center;

      .multiselect__option {
        padding: 8px 12px;

        &:hover {
          background: $green;
          outline: none;
          color: white;
        }
      }
    }

    .multiselect__option::after {
      top: 0;
      right: 0;
      position: absolute;
      min-height: 20px;
      line-height: 16px;
      padding-top: 4px;
      padding-bottom: 0;
      padding-right: 12px;
      padding-left: 20px;
    }

    .multiselect__option--selected {
      background: var(--active);
      color: var(--background-main);
      font-weight: 700;
    }

    .multiselect--disabled .multiselect__current,
    .multiselect--disabled .multiselect__select {
      background: #ededed;
      color: #837676;
    }

    .multiselect__option--disabled {
      background: #ededed !important;
      color: #a6a6a6 !important;
      cursor: text;
      pointer-events: none;
    }

    .multiselect__option--group {
      background: #f2f6fa !important;
      color: #323232 !important;
      font-weight: bold;
    }

    .multiselect__option--group.multiselect__option--highlight {
      background: #35495e;
      color: #fff;
    }

    .multiselect__option--group.multiselect__option--highlight::after {
      background: #35495e;
    }

    .multiselect__option--disabled.multiselect__option--highlight {
      background: #dedede;
    }

    .multiselect__option--group-selected.multiselect__option--highlight {
      background: $lightgray-darker;
      color: #fff;
    }

    .multiselect__option--group-selected.multiselect__option--highlight::after {
      background: $lightgray-darker;
      content: attr(data-deselect);
      color: #fff;
    }

    .multiselect-enter-active,
    .multiselect-leave-active {
      transition: all 0.15s ease;
    }

    .multiselect-enter,
    .multiselect-leave-active {
      opacity: 0;
    }

    .multiselect__strong {
      margin-bottom: 8px;
      line-height: 25px;
      display: inline-block;
      vertical-align: top;
    }

    *[dir='rtl'] .multiselect {
      text-align: right;
    }

    *[dir='rtl'] .multiselect__select {
      right: auto;
      left: 1px;
    }

    *[dir='rtl'] .multiselect__tags {
      padding: 8px 8px 0 40px;
    }

    *[dir='rtl'] .multiselect__content {
      text-align: right;
    }

    *[dir='rtl'] .multiselect__option::after {
      right: auto;
      left: 0;
    }

    *[dir='rtl'] .multiselect__clear {
      right: auto;
      left: 12px;
    }

    *[dir='rtl'] .multiselect__spinner {
      right: auto;
      left: 1px;
    }
  }
</style>
