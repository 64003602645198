export default {
  data: function () {
    return {
      currentColumnResized: false,
      resizeClientX: 0,
      resizedColumnWidth: 0,
      totalWidthBeforeResize: 0,
      resizeMoveTriggered: false,
    };
  },

  methods: {
    // start resizing a column
    resizeStart: function (event, col) {
      this.currentColumnResized = col;
      this.resizeClientX = event.clientX;

      this.totalWidthBeforeResize = this.getTotalWidthBeforeResize(event.target.parentElement.clientWidth);

      document.addEventListener('mousemove', this.resizeMove);
      document.addEventListener('mouseup', this.resizeEnd);
    },
    // resize the column while dragging it
    resizeMove: function (event) {
      this.resizeMoveTriggered = true;
      const colSize = this.currentColumnResized.width || this.basicColWidth - 3;
      this.resizedColumnWidth = this.getResizedColumnWidth(event, colSize);
      const totalInnerWidth = this.totalWidthBeforeResize + this.resizedColumnWidth - colSize;
      const currentColumn = event.target.parentElement;
      if (currentColumn) {
        currentColumn.style.width = this.resizedColumnWidth;
      }
      const innerScroller = document.querySelector('.scroller-inner');
      if (innerScroller) {
        innerScroller.style.width = totalInnerWidth;
      }
    },
    // end resizing a column
    resizeEnd: function () {
      this.canReset = true;
      document.removeEventListener('mousemove', this.resizeMove);
      document.removeEventListener('mouseup', this.resizeEnd);

      if (this.resizeMoveTriggered) {
        const colIndex = this.cols.indexOfObject('data', this.currentColumnResized.data);
        this.currentColumnResized.width = this.resizedColumnWidth + 3;
        if (this.opts.resizing.save) {
          this.cols[colIndex] = this.currentColumnResized;
        }
      }
      this.$emit(this.opts.resizing.event, this.currentColumnResized);
      this.resetResizingAction();
    },
    // reset the resizing variable to be ready for the next resize
    resetResizingAction: function () {
      this.resizeMoveTriggered = false;
      this.currentColumnResized = false;
      this.resizeClientX = 0;
      this.resizedColumnWidth = 0;
      this.totalWidthBeforeResize = 0;
    },
  },
};
