import {getValueAtPath, isEmpty, escapeHtml} from '@veasel/core/tools';
import {currency, currencyFromInteger} from '@veasel/core/formatters';
import {STime} from '@veasel/core/time';
import {getIconByType} from '@veasel/base/icon';

const BLANK_VALUE = ' ';

export default {
  data: function () {
    return {
      renderers: {
        none: {
          value: (value) => {
            return value;
          },
          style: (cell) => {
            const cellToSet = Array.isArray(cell) ? cell[0] : cell;
            if (cellToSet?.style) cellToSet.style.textAlign = 'left';
          },
        },
        humanize: {
          value: (value) => {
            return value.replace(/_/g, ' ').replace(/(\b[a-z](?!\s))/g, (x) => x.toUpperCase());
          },
          style: (cell) => {
            const cellToSet = Array.isArray(cell) ? cell[0] : cell;
            if (cellToSet?.style) cellToSet.style.textAlign = 'left';
          },
        },
        uppercase: {
          value: (value) => {
            return value.toUpperCase();
          },
          style: (cell) => {
            const cellToSet = Array.isArray(cell) ? cell[0] : cell;
            if (cellToSet?.style) cellToSet.style.textAlign = 'left';
          },
        },
        lowercase: {
          value: (value) => {
            return value.toLowerCase();
          },
          style: (cell) => {
            const cellToSet = Array.isArray(cell) ? cell[0] : cell;
            if (cellToSet?.style) cellToSet.style.textAlign = 'left';
          },
        },
        text: {
          value: (value) => {
            if (typeof value === 'object') {
              value = JSON.stringify(value);
            }
            return value;
          },
          style: (cell) => {
            const cellToSet = Array.isArray(cell) ? cell[0] : cell;
            if (cellToSet?.style) cellToSet.style.textAlign = 'left';
          },
        },
        number: {
          value: (value) => {
            let exp = ('' + value).split('e-');
            return '' + (exp.length > 1 ? value.toFixed(exp[1]) : value);
          },
          style: (cell) => {
            const cellToSet = Array.isArray(cell) ? cell[0] : cell;
            if (cellToSet?.style) cellToSet.style.textAlign = 'right';
          },
        },
        monetary: {
          value: (value, obj, col) => {
            let currencyToUse =
              typeof col.editable === 'function'
                ? col.editable(obj).options.currency
                : col.currency || obj.__currency_used || obj.currency_code || obj.base_currency_code || 'GBP';

            // This is a dirty temp fix to help Regtech with https://gitlab.suade.io/suade/Services/-/issues/16960
            // They will be deprecating this soon in favour of `minimum_balance` Also see `monetaryFromInteger` below
            if (Object.hasOwn(col, 'data') && col.data === 'minimum_balance_eur') {
              currencyToUse = 'EUR';
            }

            return currency(value, currencyToUse, undefined, {
              hideCurrencyCode: !this.opts.settings.displayCurrency,
            });
          },
          style: (cell) => {
            const cellToSet = Array.isArray(cell) ? cell[0] : cell;
            if (cellToSet && cellToSet.style) {
              cellToSet.style.textAlign = 'right';
              cellToSet.setAttribute('class', 'monetary-number');
            }
          },
        },

        monetaryFromInteger: {
          value: (value, obj, col) => {
            // This is a dirty temp fix to help Regtech with https://gitlab.suade.io/suade/Services/-/issues/16960
            // They will be deprecating this soon in favour of `minimum_balance` Also see `monetary` above
            let currencyToUse =
              Object.hasOwn(col, 'data') && col.data === 'minimum_balance_eur'
                ? 'EUR'
                : obj.__currency_used || obj.currency_code || obj.base_currency_code;

            return currencyFromInteger(
              value,
              currencyToUse,
              undefined,
              {hideCurrencyCode: !this.opts.settings.displayCurrency},
              this.currencyExponentDefinition
            );
          },
          style: (cell) => {
            const cellToSet = Array.isArray(cell) ? cell[0] : cell;
            if (cellToSet && cellToSet.style) {
              cellToSet.style.textAlign = 'right';
              cellToSet.setAttribute('class', 'monetary-number');
            }
          },
        },
        boolean: {
          value: (value) => {
            return value === null ? '' : value ? 'Yes' : 'No';
          },
          style: (cell) => {
            const cellToSet = Array.isArray(cell) ? cell[0] : cell;
            if (cellToSet?.style) cellToSet.style.textAlign = 'center';
          },
        },
        nested: {
          value: (value) => {
            return value && value.length != 0 ? value.length : null;
          },
          style: (cell, value) => {
            if (cell) {
              const elementToSet = Array.isArray(cell) ? cell[0] : cell;
              elementToSet.setAttribute('title', JSON.stringify(value, 2, 2));
            }
          },
        },
        date: {
          value: (value) => {
            if (typeof value !== 'string' && isNaN(value) && isNaN(value._i) && !value._isAMomentObject) {
              value = BLANK_VALUE;
            } else {
              if (value !== '') {
                value = new STime(value, 'naive').format('dateNumeric', 'UTC');
              } else {
                value = BLANK_VALUE;
              }
            }
            return value;
          },
          style: (cell) => {
            const cellToSet = Array.isArray(cell) ? cell[0] : cell;
            if (cellToSet?.style) cellToSet.style.textAlign = 'left';
          },
        },
        dateTime: {
          value: (value) => {
            if (typeof value !== 'string' && isNaN(value) && isNaN(value._i) && !value._isAMomentObject) {
              value = BLANK_VALUE;
            } else {
              if (value !== '') {
                value = new STime(value, 'UTC').format('dateTimeNumeric', 'locale', 'UTC');
              } else {
                value = BLANK_VALUE;
              }
            }
            return value;
          },
          style: (cell) => {
            const cellToSet = Array.isArray(cell) ? cell[0] : cell;
            if (cellToSet?.style) cellToSet.style.textAlign = 'left';
          },
        },
        array: {
          value: (value) => {
            if (value && typeof value === 'object' && value.length) {
              if (typeof value[0] === 'object') {
                value = value.map((e) => JSON.stringify(e)).join(', ');
              } else {
                const dateArray = value.map((item) => {
                  return new STime(item, 'UTC').format('dateTime', 'locale', 'UTC');
                });
                const isDateArray = dateArray.find((element) => element !== undefined);
                if (isDateArray !== undefined && dateArray[0] !== undefined) {
                  value = dateArray.join(', ');
                } else {
                  value = value.join(', ');
                }
              }
            } else {
              value = BLANK_VALUE;
            }
            return value;
          },
          style: (cell) => {
            const cellToSet = Array.isArray(cell) ? cell[0] : cell;
            if (cellToSet?.style) cellToSet.style.textAlign = 'left';
          },
        },
        length: {
          value: (value) => {
            if (value && typeof value === 'object' && value.length) {
              value = value.length;
            } else {
              value = '0';
            }
            return value;
          },
          style: (cell) => {
            const cellToSet = Array.isArray(cell) ? cell[0] : cell;
            if (cellToSet?.style) cellToSet.style.textAlign = 'left';
          },
        },
      },
    };
  },

  computed: {
    // pre process all rows value in a object to avoid useless re rendering
    renderedCells: function () {
      const data = {};
      for (const y in this.visibleRows) {
        for (const x in this.visibleCols) {
          data[y + '_' + x] = this.renderCell(this.visibleRows[y], this.visibleCols[x], x, y) || BLANK_VALUE;
          if (this.opts.editable && this.visibleCols[x].editable) {
            data[y + '_' + x] += '<div class="edit-cell"><i class="icon-edit"/> ' + getIconByType('edit') + '</div>';
          }
        }
        this.renderRow(this.visibleRows[y], y);
      }
      return data;
    },
  },

  methods: {
    // use a built-in renderer or a custom one to display a table cell
    renderCell: function (row, col, x, y) {
      const colReference = col.data;
      let value = getValueAtPath(row, colReference, null);
      // If the value is empty, lets try and see if the value exists at a flat reference
      if (value === null && !isEmpty(row[colReference])) {
        value = row[colReference];
      }

      // If value is still null, then lets make it an empty string
      if (value === null) {
        return '';
      }

      if (col.rendering && typeof col.rendering === 'function') {
        this.$nextTick(() => {
          const elem = this.$refs[y + '_' + x];
          col.rendering(elem, value, row, col, x, y);
        });
      }

      if (value === undefined) {
        return value;
      }

      if (typeof value === 'string') {
        return this.parseRouterLinks(col.formating(escapeHtml(value), row, col, x, y));
      } else {
        return col.formating(value, row, col, x, y);
      }
    },

    // use a custom renderer to display a table row
    renderRow: function (row, y) {
      if (this.opts.rowRenderer) {
        this.$nextTick(() => {
          const elem = this.$refs[y];
          if (elem) {
            elem.style.textAlign = 'none';
            this.opts.rowRenderer(elem, row, y);
          }
        });
      }
    },
    // Check for router links in the HTML that need to be converted to hacky hrefs
    parseRouterLinks: function (formattedHtml) {
      let parsed = formattedHtml;

      if (typeof formattedHtml === 'string') {
        // Regex to find and replace router-links
        parsed = parsed.replace(/<router-link.+?(\/>|\/router-link>)/g, (routerLink) => {
          // Regexes to parse just the link and text portions
          const link = routerLink
            .match(/to=".*"/g)[0]
            .replace('to="', '')
            .replace('"', '');
          const text = routerLink.match(/>.*</g)[0].replace(/(<|>)/g, '');

          // Replace with a normal link
          return `<a href='javascript:;' target='_self' onclick='event.preventDefault(); vue.$router.push({ path: "${link}" });'>${text}</a>`;
        });
      }

      return parsed;
    },
  },
};
