<template>
  <div class="agg-result" :style="{width: outerWidth + 'px'}">
    <div class="agg-container" :style="{width: innerWidth + 'px'}">
      <div
        v-for="(agg, index) in visibleAggregates"
        :key="agg.index"
        class="agg-cell monetary-number"
        :style="{
          width: options.aggColWidth + 'px',
          'background-color': agg.type === 'count' ? countColor : agg.type === 'custom' ? customColor : color,
        }"
        :agg-index="x + index + '_' + y"
        :col-index="index"
        :value="result.aggregations[agg.index].value"
        :title="valueToDisplay(agg)"
        @mousedown="aggSelected"
        @dblclick="drillDown"
      >
        {{ valueToDisplay(agg) }}
      </div>
    </div>
  </div>
</template>

<script>
  import {closestOrSelf} from '@veasel/core/tools';
  import {currency} from '@veasel/core/formatters';

  export default {
    name: 's-agg-result',

    emits: ['agg-selected', 'drill-down'],

    props: {
      result: {type: Object, default: () => ({})},
      currencyCode: {type: String, default: 'GBP'},
      query: {type: Object, default: () => ({})},
      options: {type: Object, default: () => ({})},
      outerWidth: {type: Number, default: 100},
      innerWidth: {type: Number, default: 100},
      color: {type: String, default: '#fff'},
      countColor: {type: String, default: '#fff'},
      customColor: {type: String, default: '#fff'},
      x: {type: Number, default: 0},
      y: {type: String, default: '0'},
      currentAggScroll: {type: Number, default: 0},
      visibleAggregates: {type: Array, default: () => []},
      parentGroupCurrency: {type: String, default: ''},
    },
    mounted() {
      // When a new AggResult is created, if we are in the middle of scrolling, we need to setup the correct scroll position
      this.$el.scrollLeft = this.currentAggScroll;
    },

    methods: {
      valueToDisplay(agg) {
        let _currency = false;
        if (agg.monetary) {
          if (this.options.isConvertedToCurrency) {
            _currency = this.options.currencyToConvertTo;
          } else if (this.parentGroupCurrency) {
            _currency = this.parentGroupCurrency;
          }
        }
        if (
          !this.result.aggregations[agg.index] ||
          this.result.aggregations[agg.index].value === undefined ||
          this.result.aggregations[agg.index].value === null
        ) {
          return this.options.undefinedPlaceholder;
        }

        if (_currency && _currency !== 'not found') {
          return currency(this.result.aggregations[agg.index].value, _currency);
        } else {
          return this.result.aggregations[agg.index].value;
        }
      },
      // propagate a aggregation selection
      aggSelected: function (event) {
        event.stopPropagation();
        const index = closestOrSelf(event.target, '[agg-index]').getAttribute('agg-index');
        this.$emit('agg-selected', index);
      },

      // propagate a drill down action
      drillDown: function (event) {
        event.stopPropagation();
        const index = closestOrSelf(event.target, '[agg-index]').getAttribute('agg-index');
        this.$emit('drill-down', index);
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@veasel/core/sass/conf';

  .agg-result {
    position: absolute;
    right: 0;
    z-index: 26;

    /* max-width:50%; */
    overflow: hidden;

    .agg-container {
      position: relative;
      height: 20px;
      table-layout: fixed;
      display: table;
    }

    .agg-cell {
      text-align: right;
      display: table-cell;
      padding: 0 8px;
      height: 20px;
      box-sizing: border-box;
      border-left: 1px solid $black;
      border-bottom: 1px solid $black;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &:last-child {
        border-right: 1px solid $black;
      }
    }
  }
</style>
