import {defineComponent, createApp} from 'vue';
import tutorialStep from '../components/tutorialStep.vue';

const Tutorial = {
  containerId: 's-tutorial-container',
  app: false,
  index: 0,
  removeExistingStep(closeCallback = () => {}) {
    if (this.app) {
      this.app.unmount();
      this.app = false;
    }
    if (document.getElementById(this.containerId)) {
      document.getElementById(this.containerId).remove();
    }
    closeCallback();
  },
  open(section, index = 0, closeCallback = () => {}, nextCallback = () => {}) {
    this.removeExistingStep();
    let container;
    if (document.getElementById(this.containerId)) {
      container = document.getElementById(this.containerId);
    } else {
      container = document.createElement('div');
      container.id = this.containerId;
      document.body.appendChild(container);
    }
    this.closeCallback = closeCallback;
    this.nextCallback = nextCallback;
    const tutorialStepView = defineComponent({
      extends: tutorialStep,
      data() {
        return {
          index: index,
          section: section,
        };
      },
      methods: {
        nextCallback: nextCallback,
        closeCallback: (done) => {
          this.destroy();
          this.removeExistingStep();
          closeCallback(done);
        },
      },
    });
    this.app = createApp(tutorialStepView);
    this.mountedComponent = this.app.mount(container);
  },
  update(section) {
    if (this.mountedComponent) {
      this.open(section, this.mountedComponent.index, this.closeCallback, this.nextCallback);
    }
  },
  destroy() {
    if (this.app) {
      this.app.unmount();
      this.mountedComponent = false;
      this.closeCallback = undefined;
      this.nextCallback = undefined;
    }
  },
};

if (typeof window !== 'undefined') {
  window.Tutorial = Tutorial;
}

export default Tutorial;
