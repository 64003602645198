import {valueMatchPattern} from '@veasel/core/tools';

// needs isError mixins
export default {
  props: {
    pattern: {
      description: 'Pattern key (available in <em>Tool.js</em>), <em>RegExp</em> or <em>function: val => bool</em>',
      type: [String, RegExp, Function],
      validator: (val) =>
        [
          'email',
          'url',
          'ip',
          'html',
          'number',
          'float',
          'float2d',
          'integer',
          'string',
          'noSpecials',
          'json',
          'hex',
          'rgba',
        ].includes(val) ||
        val instanceof RegExp ||
        typeof val === 'function',
    },
  },

  data: () => ({$_hasPatternError: false}),

  computed: {
    _validPattern() {
      return !!valueMatchPattern(this.modelValue, this.pattern);
    },
  },

  methods: {
    $_patternValidate: function (val) {
      const matchPattern = valueMatchPattern(val, this.pattern);
      if (matchPattern instanceof Promise) {
        // this.pattern is async function
        matchPattern.then((res) => {
          this.$data.$_hasPatternError = !res;
          if (typeof this.$veasel.formValidUpdate[this.formId] === 'function') {
            this.$veasel.formValidUpdate[this.formId]();
          }
        });
      } else {
        this.$data.$_hasPatternError = !matchPattern;
        if (typeof this.$veasel.formValidUpdate[this.formId] === 'function') {
          this.$veasel.formValidUpdate[this.formId]();
        }
      }
    },
  },
};
