<template>
  <div class="sparkline-container" :data-sparkline-id="setUniqueId">
    <graph-bar v-if="type === 'bar'" :values="values" :options="options" :track-id="setUniqueId" />
    <graph-bar v-if="type === 'discrete'" :values="values" :options="options" :type="type" :track-id="setUniqueId" />
    <graph-bar v-if="type === 'tristate'" :values="values" :options="options" :type="type" :track-id="setUniqueId" />
    <graph-box v-if="type === 'box'" :values="values" :options="options" :track-id="setUniqueId" />
    <graph-bullet v-if="type === 'bullet'" :values="values" :options="options" :track-id="setUniqueId" />
    <graph-line v-if="type === 'line'" :values="values" :options="options" :track-id="setUniqueId" />
    <graph-pie v-if="type === 'pie'" :values="values" :options="options" :track-id="setUniqueId" />
  </div>
</template>

<script>
  import graphBar from './graphBar.vue';
  import graphBox from './graphBox.vue';
  import graphBullet from './graphBullet.vue';
  import graphLine from './graphLine.vue';
  import graphPie from './graphPie.vue';
  import {uniqId} from '@veasel/core/tools';

  export default {
    name: 'Sparkline',

    components: {
      graphBar,
      graphBox,
      graphBullet,
      graphPie,
      graphLine,
    },

    props: {
      values: {
        description: 'The number values to be used when constructing the graph.',
        type: Array,
        required: true,
      },
      type: {
        description: 'The type of chart.',
        values: ['line', 'bar', 'tristate', 'discrete', 'bullet', 'pie', 'box'],
        type: String,
        validator: (val) => ['line', 'bar', 'tristate', 'discrete', 'bullet', 'pie', 'box'].includes(val),
        default: 'line',
      },
      options: {
        description: 'The options object, to specify style.',
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      setUniqueId() {
        return uniqId('sparkline-');
      },
    },
  };
</script>

<style scoped lang="scss">
  .sparkline-container {
    display: flex;

    ::v-deep(svg) {
      display: block;
    }

    ::v-deep(.graph-wrap) {
      position: relative;
      margin: auto;
    }
  }
</style>
