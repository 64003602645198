<template>
  <div class="s-monitor-sparkline" :style="{height: `${chartOptions.height}px`}">
    <div class="chart-label">
      <span class="block">{{ legend.title }}</span>
      <span class="block m-t-nxs">{{ legend.value || 'Loading' }}</span>
    </div>
    <div :id="uniqueID" class="chart-container"></div>
  </div>
</template>

<script>
  import * as d3 from 'd3';
  import {clone, uniqId} from '@veasel/core/tools';
  import {id, chartResize} from '@veasel/core/mixins';

  const DEFAULT_OPTIONS = {
    height: 120,
    background: 'var(--approved)',
    color: 'white',
    interval: 1000,
    retention: 30,
  };

  export default {
    name: 's-monitor-sparkline',
    description: 'Sparkline without axis',
    mixins: [chartResize, id],
    props: {
      legend: {
        description: 'Custom legend title and value',
        type: Object,
        default: () => ({}),
      },
      lastValue: {
        description: 'Last live value for the chart',
        type: Object,
        default: () => ({}),
      },
      options: {
        description: 'The options object, to specify some features and chart style.',
        type: Object,
        default: () => ({}),
      },
    },

    data() {
      return {
        uniqueID: '',
        values: [],
      };
    },
    computed: {
      chartOptions() {
        return {...DEFAULT_OPTIONS, ...this.options};
      },
    },
    methods: {
      buildChart(selection) {
        const _this = this;
        selection.each(function (data) {
          data = data.map((d) => ({time: +d.x, value: +d.y}));

          const margin = {top: 0, right: 0, bottom: 8, left: 0};
          const chartBox = document.querySelector(`#${_this.uniqueID}`);
          const chartWidth = chartBox.offsetWidth;
          const chartHeight = chartBox.offsetHeight;
          const x = d3.scaleTime().rangeRound([0, chartWidth - margin.left - margin.right]);
          const y = d3.scaleLinear().rangeRound([chartHeight - margin.top - margin.bottom, 0]);
          const xMin = new Date(Date.now() - (_this.chartOptions.retention - 1) * 1000);
          const xMax = new Date(Date.now() - _this.chartOptions.interval * 2);

          let yMin;
          let yMax;
          const yDomain = _this.chartOptions.yDomain;
          if (yDomain && yDomain.yMin !== undefined && yDomain.yMax !== undefined) {
            yMin = _this.chartOptions.yDomain.yMin;
            yMax = _this.chartOptions.yDomain.yMax;
          } else {
            yMin = d3.min(data, (d) => d.value);
            yMax = d3.max(data, (d) => d.value);
          }

          x.domain([xMin, xMax]);
          y.domain([yMin, yMax]);

          const line = d3
            .line()
            .x((d) => x(d.time))
            .y((d) => y(d.value));

          let svg = d3.select(this).selectAll('svg').data([data]);

          const gEnter = svg.enter().append('svg').append('g');
          gEnter.append('g').attr('class', 'lines').append('path').attr('class', 'data');

          svg = selection.select('svg');
          svg.attr('width', chartWidth).attr('height', chartHeight);

          const g = svg.select('g').attr('transform', `translate(${margin.left},${margin.top})`);

          g.selectAll('g path.data')
            .data(data)
            .style('stroke', _this.chartOptions.color)
            .style('stroke-width', 1)
            .style('fill', 'none')
            .transition()
            .duration(_this.chartOptions.interval)
            .ease(d3.easeLinear)
            .on('start', tick);

          function tick() {
            d3.select(this)
              .attr('d', () => {
                return line(data);
              })
              .attr('transform', null);

            const xMinLess = new Date(new Date(xMin).getTime() - _this.chartOptions.interval);
            d3.active(this)
              .attr('transform', `translate(${x(xMinLess)},0)`)
              .transition()
              .on('start', tick);
          }
        });
      },
      updateChart() {
        d3.select(`#${this.uniqueID}`).datum(this.values).call(this.buildChart);
      },
      redrawDueToResize() {
        this.updateChart();
      },
    },
    created() {
      this.uniqueID = this.id || uniqId('chart_').replace('.', '');

      setInterval(() => {
        this.updateChart();
      }, this.chartOptions.interval);
    },
    watch: {
      lastValue: {
        handler(newValue) {
          this.values.push(clone(newValue));
          if (this.values.length > this.chartOptions.retention) {
            this.values.shift();
          }
        },
        deep: true, // Necessary for example demo page
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@veasel/core';

  .s-monitor-sparkline {
    width: 100%;
    padding: 24px 16px 16px 16px;
    background: v-bind('chartOptions.background');
    position: relative;
    box-sizing: border-box;
    transition: background-color $transition-time ease-in-out;

    .chart-label {
      height: calc(100% - 8px);
      padding-right: 24px;
      position: absolute;
      top: 8px;
      left: 8px;
      z-index: 10; // 10-19 element with layers

      &::after {
        // Doesn't seem to be possible to use 'rgba' with 'v-bind'
        content: '';
        width: 100%;
        height: calc(100% - 8px);
        background: v-bind('chartOptions.background');
        opacity: 0.5;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1; // < 0 background element
        transition: background-color $transition-time ease-in-out;
      }

      span {
        transition: all $transition-time ease-in-out;
      }

      span:nth-child(1) {
        @include base-small-bold-background-main;

        color: v-bind('chartOptions.color'); // Overrides mixin color
      }

      span:nth-child(2) {
        @include header;

        color: v-bind('chartOptions.color'); // Overrides mixin color
      }
    }

    .chart-container {
      width: 100%;
      height: 100%;
      position: relative;
    }

    &::after {
      content: '';
      width: calc(100% - 32px);
      opacity: 0.5;
      border-bottom: 1px solid v-bind('chartOptions.color');
      position: absolute;
      left: 16px;
      bottom: 16px;
      z-index: 11;
    }
  }
</style>
