<template>
  <div :id="id" class="s-form">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 's-form',
    description:
      'A wrapper form input and buttons to link the inputs required/invalid flag with an enabled submit button.',
    slots: {
      default: 'Form content (list of inputs and submit button)',
    },
    props: {
      id: {type: String, required: true},
    },
    data: function () {
      return {
        hasRequired: 0,
        hasErrors: 0,
      };
    },
    mounted: function () {
      this.listenForChangesInForm(this.id);
      this.updateFormValidation();
      this.$veasel.formValidStatus[this.id] = this.isValid;
    },
    beforeUnmount() {
      delete this.$veasel.formValidStatus[this.id];
    },
    updated: function () {
      this.updateFormValidation();
    },
    computed: {
      isValid: function () {
        return this.hasRequired === 0 && this.hasErrors === 0;
      },
    },
    watch: {
      id: {
        immediate: true,
        handler: function (id) {
          this.listenForChangesInForm(id);
        },
      },
    },
    methods: {
      listenForChangesInForm: function (id) {
        this.$veasel.formValidUpdate[id] = this.updateFormValidation;
      },
      updateFormValidation: function () {
        this.$nextTick(() => {
          // count the number of required sl-input fields, excluding the ones in s-search components
          this.hasRequired = [
            ...this.$el.querySelectorAll(
              '.s-input.input-required:not(.input-disabled), .sl-input .is-required, .s-input .is-required'
            ),
          ].filter((e) => !e.closest('.inside-search')).length;

          this.hasErrors = this.$el.querySelectorAll('.s-input.input-error').length;
          this.$veasel.formValidStatus[this.id] = this.isValid;

          // Check if form is nested inside another form and if so call updateFormValidation class (hacky)
          if (this.$el.parentNode && this.$el.parentNode.classList.contains('s-form')) {
            this.$parent.updateFormValidation();
          }
        });
      },
    },
  };
</script>
