<template>
  <div class="graph-wrap graph-bullet" data-chart>
    <graph-tooltip :showTooltip="showTooltip" :showDot="false" :details="tooltipData" />
  </div>
</template>

<script>
  import {pointer} from 'd3';
  import SVGGraph from '../utils/svgGraphMixin.js';
  import {colorsMixin} from '@veasel/core/colors';
  import graphTooltip from './graphTooltip.vue';

  export default {
    name: 's-graph-bullet',
    components: {
      graphTooltip,
    },
    mixins: [colorsMixin, SVGGraph],
    props: {
      values: {
        description: 'The number values to be used when constructing the graph.',
        type: Array,
        required: true,
      },
      options: {
        description: 'The options object, to specify style.',
        type: Object,
        default: () => ({}),
      },
      trackId: {
        description: 'The unique identifier for the wrapper that the svg must be applied to',
        type: String,
        required: true,
      },
    },
    data() {
      return {
        // These are what the numbers in the re-ordered data represent.
        identifiers: ['range-1', 'range-2', 'range-3', 'performance', 'target'],
        relativeWidths: [],
      };
    },
    methods: {
      drawBulletGraph: function () {
        this.setupBulletGraphOptions();
        this.setupGraph();

        const data = [...this.values];
        // The data is reversed so that the layering will be correct when drawn.
        const reorderedData = data.reverse();
        this.xLinear = this.getLinearScaleX(0, Math.max(...this.values));
        this.showDot = false;

        this.drawRectangles(reorderedData);

        this.updateTargetRect();
        this.updatePerformanceRect();
        this.updateRange3Rect();
        this.updateRange2Rect();
        this.updateRange1Rect();

        this.handleBulletMouseEvents();
      },
      setupBulletGraphOptions: function () {
        const defaults = {
          width: 60,
          height: 25,
          targetWidth: 3,
          targetColor: this.s_colors(this.suadeColors.red),
          performanceColor: this.s_colors(this.suadeColors.blue),
          // TODO: DEAN Update these colours with this task - https://gitlab.suade.io/suade/Services/-/issues/10572
          range1Color: 'dimgray',
          range2Color: 'gray',
          range3Color: 'lightgray',
        };
        this.graphOptions = this.getGraphOptions(defaults);
      },
      drawRectangles: function (reorderedData) {
        this.svgGraph
          .selectAll('rect')
          .data(reorderedData)
          .enter()
          .append('rect')
          .attr('class', (_data, index) => this.identifiers[index])
          .attr('x', 0)
          .attr('y', 0)
          .attr('width', (data) => {
            this.relativeWidths.push(this.xLinear(data));
            return this.xLinear(data);
          })
          .attr('height', this.graphOptions.height)
          .on('mousemove', (e, data) => {
            const [posX, posY] = pointer(e);

            this.tooltipData.text = this.updateTooltipText(e.target, data);

            this.tooltipData.posX = posX + 15;
            this.tooltipData.posY = posY - 20;
          });
      },
      updateTargetRect: function () {
        this.svgGraph
          .select(`.${this.identifiers[4]}`)
          .attr('x', (data) => this.xLinear(data))
          .attr('y', 3)
          .attr('height', this.graphOptions.height - this.graphOptions.height / 4)
          .attr('width', 3)
          .attr('opacity', 1)
          .attr('fill', this.graphOptions.targetColor);
      },
      updatePerformanceRect: function () {
        const performanceHeight = this.graphOptions.height / 3;
        this.svgGraph
          .select(`.${this.identifiers[3]}`)
          .attr('y', performanceHeight)
          .attr('height', performanceHeight)
          .attr('opacity', 1)
          .attr('fill', this.graphOptions.performanceColor);
      },
      updateRange3Rect: function () {
        this.svgGraph
          .select(`.${this.identifiers[2]}`)
          .attr('x', (_data, index) => this.relativeWidths[index + 1])
          .attr('width', this.relativeWidths[2] - this.relativeWidths[1])
          .attr('fill', this.graphOptions.range3Color);
      },
      updateRange2Rect: function () {
        this.svgGraph
          .select(`.${this.identifiers[1]}`)
          .attr('x', (_data, index) => this.relativeWidths[index])
          .attr('width', this.relativeWidths[1] - this.relativeWidths[0])
          .attr('fill', this.graphOptions.range2Color);
      },
      updateRange1Rect: function () {
        this.svgGraph.select(`.${this.identifiers[0]}`).attr('fill', this.graphOptions.range1Color);
      },
      updateTooltipText: function (target, data) {
        let text = 'Performance';
        if (target.classList.value.indexOf('range') !== -1) {
          text = 'Range';
        }
        if (target.classList.value === 'target') {
          text = 'Target';
        }
        return `${text} - ${data}`;
      },
      handleBulletMouseEvents: function () {
        this.svgGraph.on('mouseleave', () => {
          this.showTooltip = false;
        });
      },
    },

    mounted() {
      this.drawBulletGraph();
    },
  };
</script>
