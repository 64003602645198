export default {
  data() {
    return {
      isError: false,
    };
  },

  methods: {
    checkValidity(closeTooltipIfValid) {
      // should check order match following rules
      const orderIsValid = this.elements.every((element, index, array) => {
        let expectedType;
        if (index === 0) {
          expectedType = 'start';
        } else {
          expectedType = array[index - 1].type;
        }
        return this.elementTypes[expectedType].following.includes(element.type);
      });
      // should starts by a correct item
      const startsCorrectly = this.elements.length && !!this.elementTypes[this.elements[0].type]?.canBeFirst;
      // should finishes by a correct item
      const finishesCorrectly =
        this.elements.length && !!this.elementTypes[this.elements[this.elements.length - 1].type]?.canBeLast;
      // register groups
      const groupStarted = this.elements.map((e, i) => ({...e, index: i})).filter((e) => e.type === 'groupStart');
      const groupEnded = this.elements.map((e, i) => ({...e, index: i})).filter((e) => e.type === 'groupEnd');
      // should have all groups properly closed
      const groupsNumberMatch = groupStarted.length === groupEnded.length;
      // should ensure that all started groups are ended
      let groupsAreInValidOrder = !!groupsNumberMatch;
      if (groupsNumberMatch) {
        groupsAreInValidOrder = groupStarted.every((e, index) => {
          return e.index < groupEnded[index].index;
        });
      }
      // should check that there is no missing required attributes
      const requiredAttributes =
        this.elements.filter(
          (element) => element.attrs && element.attrs.some((a) => a.required && element.attributes[a.key] === undefined)
        ).length === 0;
      const allValuesAreValid = this.elements.filter((element) => element.type === 'invalid').length === 0;

      this.warnings = {
        inputSelector: '#' + (this.id || this.defaultID),
        forceVisibilty: true,
        position: this.tooltipPosition,
        isFocused: true,
        rules: {
          allValuesAreValid: {error: !allValuesAreValid, message: 'All values are valid selections'},
          orderIsValid: {error: !orderIsValid, message: 'The operands and operators must be in a valid order'},
          startsCorrectly: {error: !startsCorrectly, message: 'The operation starts with a good operand'},
          finishesCorrectly: {error: !finishesCorrectly, message: 'The operation ends with a good operand'},
          groupsNumberMatch: {error: !groupsNumberMatch, message: 'All parenthesis opened should be closed properly'},
          groupsAreInValidOrder: {
            error: !groupsAreInValidOrder,
            message: "A parenthesis can't be closed before being opened",
          },
          requiredAttributes: {error: !requiredAttributes, message: 'Some operands are missing required attributes'},
        },
      };
      this.isError = true;
      this.warnings.hasErrors = this.isError;
      if (
        this.elements.length === 0 ||
        (orderIsValid &&
          startsCorrectly &&
          finishesCorrectly &&
          groupsNumberMatch &&
          groupsAreInValidOrder &&
          requiredAttributes)
      ) {
        this.isError = false;
        if (this.elements.length === 0) {
          this.$data.$_isMissing = true;
        }
        if (closeTooltipIfValid) {
          this.warnings = {};
        }
        return true;
      } else {
        return false;
      }
    },
  },
};
