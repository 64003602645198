// Global mixin to provide permissions and features checking functions for conditional rendering

import {mapState} from 'vuex';

import {clone} from '@veasel/core/tools';

// Import permissions service
import {CheckPermissionKey} from '@/services/permissions.js';

export default {
  computed: {
    ...mapState('user', ['permissions', 'features', 'current']),

    ...mapState('system', ['feature_flags', 'running_calculators', 'running_containers', 'use_teams_for_visibility']),

    ...mapState('report', ['reportTypes', 'reportsConfig']),

    teamsPermissionsDict() {
      let teamsPermissionDict = {};
      (this.current.teams || [])
        .map((team) => ({
          team_ids: [team.id, ...(team.teams_sharing_with_us?.map((sharedTeam) => sharedTeam.id) || [])],
          actions:
            team.actions.length === 0
              ? true
              : team.actions.reduce(
                  (dict, action) => ({
                    ...dict,
                    [action.action_type]: [...(dict[action.action_type] || []), action.report_type],
                  }),
                  {}
                ),
        }))
        .forEach((team) => {
          team.team_ids.forEach((id) => {
            if (!teamsPermissionDict[id]) {
              teamsPermissionDict[id] = team.actions;
            } else if (teamsPermissionDict[id] !== true) {
              teamsPermissionDict[id] = mergeActionObject(teamsPermissionDict[id], team.actions);
            }
          });
        });
      return teamsPermissionDict;
    },
  },

  methods: {
    // Return true if the current user has the specified permission
    sl_perm(key, type = 'single') {
      return CheckPermissionKey(this.permissions, type, key, false);
    },

    // Return true if the system running has the specified feature
    sl_feat(key, type = 'single') {
      if (type === 'by_calculators') {
        return CheckPermissionKey(
          this.running_calculators.map((c) => c.name),
          type,
          key,
          true
        );
      } else if (type === 'by_calculator') {
        return this.running_calculators && this.reportsConfig[key]
          ? this.running_calculators.map((c) => c.name).includes(this.reportsConfig[key].calculator)
          : false;
      } else if (type === 'by_report') {
        return this.reportTypes ? this.reportTypes.map((t) => t.id).includes(key) : false;
      } else if (type === 'by_config') {
        // handles global boolean config flags, must be added in The oject below
        return (
          {
            use_teams_for_visibility: this.use_teams_for_visibility,
          }[key] || false
        );
      } else {
        return CheckPermissionKey(this.features.concat(this.feature_flags), type, key, true);
      }
    },

    // Return true if the current user has the specified permission
    sl_container(key) {
      return this.running_containers.find((c) => c.name === key);
    },
    // Apply permissions to received breadcrumbs or use current route ones and return them
    sl_get_permitted_breadcrumbs(breadcrumbs) {
      let permittedBreadcrumbs = clone(breadcrumbs);

      if (!permittedBreadcrumbs) {
        permittedBreadcrumbs = clone(this.$route.meta.breadcrumb);
      }

      permittedBreadcrumbs.forEach((b) => {
        if (b.to) {
          // If there's a path then check permissions
          const path = b.to.path || b.to;
          const isPermitted = this.sl_perm(path, 'by_page');
          if (!isPermitted) {
            typeof b.to === 'object' ? delete b['to.path'] : delete b['to'];
          }
        }
      });

      return permittedBreadcrumbs;
    },

    teamIsAllowedToDoActionOnReportType(team, action, reportType) {
      return (
        !this.use_teams_for_visibility ||
        this.teamsPermissionsDict?.[team] === true ||
        !!this.teamsPermissionsDict?.[team]?.[action]?.includes(reportType)
      );
    },
  },
};

function mergeActionObject(a, b) {
  const keys = ['view', 'create', 'edit', 'export'];
  return keys
    .map((k) => ({key: k, value: [...new Set([...(a[k] || []), ...(b[k] || [])])]}))
    .reduce((dict, entry) => ({...dict, [entry.key]: entry.value}), {});
}
