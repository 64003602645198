export default {
  flat: {
    parseQuery(query) {
      const parsedQuery = {};
      for (const key in query) {
        if (query[key] instanceof Object && !(query[key] instanceof Array)) {
          switch (query[key].filter) {
            case 'is':
              parsedQuery[key + '_date_is'] = query[key].date;
              break;
            case 'is_between':
              parsedQuery[key + '_date_is_after'] = query[key].date_from;
              parsedQuery[key + '_date_is_before'] = query[key].date_to;
              break;
            case 'is_before':
              parsedQuery[key + '_date_is_before_ex'] = query[key].date;
              break;
            case 'is_after':
              parsedQuery[key + '_date_is_after_ex'] = query[key].date;
              break;
          }
        } else {
          parsedQuery[key] = query[key];
        }
      }
      return parsedQuery;
    },
    serializeQuery(query) {
      const serializedQuery = {};
      for (const key in query) {
        if (key.includes('_date_is')) {
          const keys = key.split('_date_is');
          switch (keys[1]) {
            case '':
              serializedQuery[keys[0]] = {filter: 'is', date: query[key]};
              break;
            case '_before_ex':
              serializedQuery[keys[0]] = {filter: 'is_before', date: query[key]};
              break;
            case '_after_ex':
              serializedQuery[keys[0]] = {filter: 'is_after', date: query[key]};
              break;
            default:
              serializedQuery[keys[0]] = {filter: 'is_between'};
              serializedQuery[keys[0]].date_to = query[keys[0] + '_date_is_before'];
              serializedQuery[keys[0]].date_from = query[keys[0] + '_date_is_after'];
              break;
          }
        } else {
          serializedQuery[key] = query[key];
        }
      }
      return serializedQuery;
    },
  },
};
