import * as C from './consts';

export const prettyUnit = (u) => {
  const special = {
    M: C.M,
    y: C.Y,
    w: C.W,
    d: C.D,
    D: C.DATE,
    h: C.H,
    m: C.MIN,
    s: C.S,
    ms: C.MS,
    Q: C.Q,
  };
  return (
    special[u] ||
    String(u || '')
      .toLowerCase()
      .replace(/s$/, '')
  );
};

export const pad = (num) => {
  const norm = Math.floor(Math.abs(num));
  return (norm < 10 ? '0' : '') + norm;
};
