<template>
  <div v-if="type === 'default'" class="suade-loader">
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      :fill="color ? color : defaultColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.5988 24.9796L21.9748 36.8523C21.668 38.2407 22.5172 39.6212 23.8716 39.9357C25.226 40.2503 26.5727 39.3798 26.8795 37.9914L28.3924 31.1466C29.0061 28.3698 27.3077 25.6087 24.5988 24.9796Z"
      />
      <path
        d="M19.9611 26.9284L9.21638 32.3577C7.95989 32.9926 7.43895 34.5314 8.05284 35.7946C8.66672 37.0578 10.183 37.5671 11.4395 36.9322L17.634 33.8021C20.147 32.5322 21.1888 29.4548 19.9611 26.9284Z"
      />
      <path
        d="M15.3245 24.6587L4.50435 19.3854C3.23904 18.7688 1.72005 19.2951 1.11161 20.561C0.503168 21.8269 1.03567 23.3531 2.30098 23.9697L8.53903 27.0098C11.0697 28.2431 14.1076 27.1905 15.3245 24.6587Z"
      />
      <path
        d="M14.2272 19.6411L11.5924 7.77088C11.2843 6.38277 9.93436 5.50245 8.57721 5.80463C7.22007 6.10681 6.36966 7.47705 6.67777 8.86516L8.19678 15.7086C8.81301 18.4848 11.5129 20.2455 14.2272 19.6411Z"
      />
      <path
        d="M17.6925 15.2265L25.2321 5.75779C26.1138 4.65052 25.9555 3.03169 24.8784 2.14203C23.8014 1.25237 22.2135 1.42877 21.3318 2.53604L16.9851 7.99492C15.2217 10.2095 15.5384 13.4471 17.6925 15.2265Z"
      />
      <path
        d="M22.6145 15.5125L34.6209 15.4878C36.025 15.4849 37.1665 14.3411 37.1705 12.933C37.1746 11.5249 36.0397 10.3858 34.6357 10.3887L27.7137 10.4029C24.9057 10.4087 22.6227 12.6964 22.6145 15.5125Z"
      />
      <path
        d="M26.0211 19.9L33.4651 29.4461C34.3356 30.5624 35.9285 30.7641 37.023 29.8966C38.1175 29.0291 38.2991 27.4209 37.4286 26.3045L33.137 20.801C31.396 18.5684 28.2101 18.1649 26.0211 19.9Z"
      />
    </svg>
  </div>

  <s-icon v-else class="suade-loader-icon" :color="color ? color : 'theme-active'" :type="type" spinner />
</template>
<script>
  import icon from '@veasel/base/icon';
  import {colorsMixin} from '@veasel/core/colors';

  export default {
    name: 's-loader',
    description: 'A simple loader component to indicate a loading process.',
    components: {
      's-icon': icon,
    },
    mixins: [colorsMixin],
    props: {
      type: {
        description: 'The type of icon that should be used for the loader.',
        type: String,
        default: 'default',
      },
      color: {
        description: 'The colour of icon that should be used for the loader.',
        type: String,
      },
    },
    computed: {
      defaultColor() {
        return this.s_colors('--active');
      },
    },
  };
</script>

<style scoped lang="scss">
  .suade-loader {
    width: 40px;
    height: 40px;
    margin: auto;
    animation: anim-fade-in 0.5s ease-in, anim-loading 3s linear infinite;

    svg {
      height: 100%;
      width: 100%;
      animation: anim-rotate 2s infinite linear;
    }

    &-icon {
      animation: anim-fade-in 0.5s ease-in;
    }
  }

  @keyframes anim-rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes anim-fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
</style>
