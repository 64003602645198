<template>
  <div ref="datalist" class="table-main-container">
    <!--TABLE FEATURES ROW-->
    <div class="datatable-header relative flex justify-content-space-between table-settings">
      <div class="left flex flex-grow">
        <template
          v-if="opts.checkboxes.display && opts.checkboxes.actions && opts.checkboxes.actions.some((a) => a.visible)"
        >
          <!-- Lonely Action Button (if there is only one enabled action) -->
          <s-button
            v-if="hasOnlyOneEnabledAction"
            class="lonely-action-button m-r-s"
            :text="opts.checkboxes.actions[0].name"
            :sticker="checkboxSelection.length ? '' + checkboxSelection.length : false"
            :on-click="callTheActionCallback"
            :disable="checkboxSelection.length === 0"
          />
          <!--ACTIONS DROPDOWN-->
          <s-dropdown
            v-else
            ref="tableActions"
            button-text="Actions"
            class="m-r-s table-actions"
            :button-sticker="checkboxSelection.length ? '' + checkboxSelection.length : false"
            :disable="checkboxSelection.length === 0"
            align="left"
          >
            <s-dropdown-item
              v-for="(action, index) in opts.checkboxes.actions"
              v-show="action.safeVisible(checkboxSelection)"
              :key="index"
              :disable="action.safeDisable(checkboxSelection)"
              :on-click="() => action.callback(checkboxSelectionIds, checkboxSelection)"
              :button-text="action.name"
              :data-action="action.name"
              :tooltip-text="action.safeDisable(checkboxSelection) ? action.disabledMsg : null"
            />
          </s-dropdown>
        </template>
        <!--ADDITIONAL FILTERS-->
        <div
          v-if="opts.additionalFilter.display && opts.additionalFilter.filters.length"
          class="table-additional-filter float left"
        >
          <s-dropdown ref="additionalFilters" button-text="Filters">
            <div class="additional-filters-content">
              <div v-for="filter in opts.additionalFilter.filters" :key="filter.key" class="row">
                <div v-if="filter.label">
                  <label class="col-4" :for="filter.key">{{ filter.label }}</label>
                  <component
                    :is="'s-input-' + filter.field"
                    :id="filter.key"
                    v-model="filter.default"
                    class="col-8"
                    :class="{'content-right': ['bool'].includes(filter.field)}"
                    v-bind="filter.fieldOptions"
                    :on-change="filterChange"
                  />
                </div>
                <component
                  :is="'s-input-' + filter.field"
                  v-else
                  v-model="filter.default"
                  class="col-12"
                  v-bind="filter.fieldOptions"
                  :on-change="filterChange"
                />
              </div>
            </div>
          </s-dropdown>
        </div>
        <!--SEARCH-->
        <div
          v-if="opts.search.display"
          class="table-search relative"
          :class="{stretched: !opts.additionalFilter.display}"
        >
          <s-input-text v-model="searchText" placeholder="Search" design-style="search"></s-input-text>
          <span class="table-search-results">{{ opts.pagination.num_entries + ' results' }}</span>
        </div>
      </div>
      <!--SETTINGS-->
      <div v-if="opts.settings.display" class="right">
        <s-dropdown ref="tableSettings" button-text="Settings" align="right">
          <div class="settings-content p-s theme-style-base align-left">
            <!-- EXPORT PAGE -->
            <div v-if="opts.exportView.display" class="table-export">
              <h5>Export</h5>
              <div>
                <s-button
                  v-for="exportViewType in opts.exportView.types"
                  :key="exportViewType.logic"
                  size="small"
                  :on-click="() => exportView(exportViewType)"
                  :text="exportViewType.name"
                  :disable="!visibleCols.length"
                ></s-button>
              </div>
              <hr />
            </div>

            <!-- TABLE SIZE -->
            <div v-if="opts.pagination" class="table-size">
              <div>
                <span class="p-r-s">Show</span>
                <span>
                  <select v-model="opts.pagination.limit" @change="() => changePage(1)">
                    <option v-for="(opt, index) in opts.settings.pageSize" :key="index" :value="opt">{{ opt }}</option>
                  </select>
                </span>
                <span class="p-l-s">of {{ opts.pagination.num_entries }} entries</span>
              </div>
              <hr />
            </div>

            <!-- CURRENCY TOGGLE -->
            <div class="display-currency">
              <div>
                <s-input-bool v-model="opts.settings.displayCurrency" label="Display Currency" left size="small" />
              </div>
              <hr />
            </div>

            <!-- TABLE COLUMNS -->
            <div v-if="opts.settings.columnVisibility || opts.settings.columnOrder" class="table-ordering">
              <div class="m-b-xs">Column ordering and visibility</div>
              <div>
                <ul>
                  <li
                    v-for="(col, index) in orderedCols"
                    :id="index"
                    :key="index"
                    v-drag-and-drop
                    class="m-y-xs"
                    v-on:drop="dropColumns"
                    v-on:drag="dragColumns"
                  >
                    <s-input-checkbox
                      v-model="col.visible"
                      :label="col.title"
                      size="small"
                      :on-change="() => updateColumnVisibility(col)"
                    />
                  </li>
                </ul>
              </div>
              <hr />
            </div>
            <div class="table-settings-buttons">
              <s-button
                size="small"
                :on-click="displayAllColumns"
                text="Display all"
                class="m-r-s"
                :disable="hasAllColumnsVisible"
              ></s-button>
              <s-button
                size="small"
                :on-click="hideAllColumns"
                text="Hide all"
                class="m-r-s"
                :disable="hasAllColumnsHidden"
              ></s-button>
              <s-button
                size="small"
                :on-click="resetColumns"
                text="Reset Columns"
                :disable="!canReset"
                color="red"
                class="float right"
              ></s-button>
            </div>
          </div>
        </s-dropdown>
      </div>
    </div>
    <!--TABLE HEADER-->
    <div class="table-container v-record-mask" :style="{width: outerTableWidth + 'px'}">
      <div v-if="hasColumns" class="sticky-header" :class="{'is-sticky': opts.stickyHeader}">
        <div
          v-if="opts.checkboxes.display"
          class="sticky-checkbox-header"
          :class="{'is-sticky': opts.stickyHeader}"
          :style="{width: checkboxColumnWidth + 'px'}"
        >
          <div class="checkbox-header">
            <table>
              <thead>
                <tr :style="{height: basicRowHeight / 16 + 'em'}">
                  <th
                    col-name="checkboxes"
                    :width="checkboxColumnWidth + 'px'"
                    :title="checkboxSelectAll ? 'Uncheck all rows' : 'Check all rows'"
                  >
                    <s-input-checkbox
                      v-model="checkboxSelectAll"
                      :on-change="updateCheckboxSelectAll"
                      :centerInput="true"
                      size="small"
                      class="checkbox-all justify-content-center"
                    />
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
        <div ref="headerScroller" class="scroller-outer" :style="{width: outerTableWidth + 'px'}">
          <div class="scroller-inner" :style="{width: innerTableWidth + 'px'}">
            <table>
              <thead>
                <tr :style="{height: basicRowHeight / 16 + 'em'}">
                  <th v-if="opts.checkboxes.display" col-name="checkboxes" :width="checkboxColumnWidth + 'px'"></th>
                  <th
                    v-for="(col, index) in visibleCols"
                    :key="index"
                    class="column-header"
                    :class="{sortable: col.sortable}"
                    :col-name="col.data"
                    :width="(col.width || basicColWidth) - 3 + 'px'"
                    :style="{width: col.width}"
                  >
                    <span @click="sortBy($event, col)">
                      <span class="column-title" :title="col.title">{{ col.title }}</span>
                      <span
                        v-if="opts.sorting.display && col.sortable"
                        class="sorting-arrows"
                        :title="'Sort by ' + col.title"
                        :class="sortingMap[col.data]"
                      >
                        <template v-if="opts.sorting.displayArrow">
                          <s-icon
                            type="caret-up"
                            size="14"
                            :class="{up: true, active: sortingMap[col.data] && sortingMap[col.data].order === 'asc'}"
                          ></s-icon>
                          <s-icon
                            type="caret-down"
                            size="14"
                            :class="{down: true, active: sortingMap[col.data] && sortingMap[col.data].order === 'desc'}"
                          ></s-icon>
                        </template>
                      </span>
                    </span>
                    <span
                      v-if="opts.resizing.display && col.resizable"
                      class="resizing-bar"
                      :title="'Resize ' + col.title"
                      @mousedown="resizeStart($event, col)"
                    ></span>
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <!--TABLE CHECKBOXES-->
      <div v-if="opts.checkboxes.display && hasColumns" class="sticky-checkbox">
        <div class="checkbox-table">
          <table>
            <thead>
              <tr :style="{height: basicRowHeight / 16 + 'em'}">
                <th :width="checkboxColumnWidth + 'px'">-</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(row, index) in visibleRows"
                :key="index"
                :width="checkboxColumnWidth + 'px'"
                :style="{height: basicRowHeight / 16 + 'em'}"
                :const="(actionAvailable = hasOneActionAvailable(row))"
              >
                <th :title="actionAvailable ? undefined : 'No available action for this row'">
                  <s-input-checkbox
                    v-if="actionAvailable"
                    v-model="checkboxSelectionMap[safeGet(row, opts.uniqId)]"
                    class="justify-content-center"
                    size="small"
                    :centerInput="true"
                  ></s-input-checkbox>
                  <!-- checkbox is not displayed if none action is available for the row -->
                  <div v-else :style="{width: '18px'}"></div>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--TABLE CONTENT-->
      <div v-if="!hasColumns && opts.settings.display">
        <s-box type="warning">All columns are hidden, please check columns visibility in table settings.</s-box>
      </div>

      <div class="table-content">
        <div
          ref="tableScroller"
          class="scroller-outer"
          :style="{width: outerTableWidth + 'px'}"
          @scroll="(e) => scrollSync(e, 'table')"
        >
          <div class="scroller-inner" :style="{width: innerTableWidth + 'px'}">
            <table>
              <thead>
                <tr :style="{height: basicRowHeight / 16 + 'em'}">
                  <th
                    v-if="opts.checkboxes.display && hasColumns"
                    col-name="checkboxes"
                    :width="checkboxColumnWidth + 'px'"
                  ></th>
                  <th
                    v-for="(col, index) in visibleCols"
                    :key="index"
                    class="column-header"
                    :col-name="col.data"
                    :width="(col.width || basicColWidth) - 3 + 'px'"
                    :style="{width: col.width}"
                  >
                    {{ col.title }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, y) in visibleRows"
                  :key="y"
                  :ref="(b) => setRowRef(y, b)"
                  :row-index="y"
                  :style="{height: basicRowHeight / 16 + 'em'}"
                >
                  <td
                    v-if="opts.checkboxes.display && hasColumns"
                    col-name="checkboxes"
                    :width="checkboxColumnWidth + 'px'"
                  ></td>
                  <td
                    v-for="(col, x) in visibleCols"
                    :key="x"
                    :ref="y + '_' + x"
                    :cell-index="y + '_' + x"
                    :col-name="col.data"
                    @mousedown="selectionMouseStart($event)"
                    v-html="renderedCells[y + '_' + x]"
                  ></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div ref="selectorScroller" class="selector-container scroller-outer" :style="{width: outerTableWidth + 'px'}">
          <div class="selector-inner-container scroller-inner" :style="{width: innerTableWidth + 'px'}">
            <div v-show="cellSelection" class="selector"></div>
            <div v-show="selectionTotal" class="selector-total">
              <label>Total:</label>
              <span class="ellipsis">{{ selectionTotal }}</span>
            </div>
          </div>
        </div>
      </div>
      <!--TABLE SCROLLER-->
      <div class="sticky-scroller" :class="{'is-sticky': opts.stickyScroller}">
        <div
          ref="scrollScroller"
          class="scroller-outer"
          :style="{width: outerTableWidth + 'px'}"
          @scroll="(e) => scrollSync(e, 'scroller')"
        >
          <div class="scroller-inner" :style="{width: innerTableWidth + 'px'}"></div>
        </div>
      </div>
    </div>

    <s-loading-panel :state="opts.loading ? 'loading' : 'completed'" />
    <!--PAGINATION-->
    <div v-if="hasColumns" ref="pagination" class="pagination-container" :style="{width: outerTableWidth + 'px'}">
      <s-pagination
        v-if="opts.pagination && hasRows"
        :offset="opts.pagination.offset"
        :limit="opts.pagination.limit"
        :num-entries="opts.pagination.num_entries"
        @change-page="changePage"
      />
    </div>
    <!--CELL EDIT-->
    <cell-edit-modal
      v-if="opts.editable"
      v-model:visible="isEditing"
      :data="cellToEdit"
      :currencyExponentDefinition="currencyExponentDefinition"
      @saveCellEdit="saveCell"
    />
  </div>
</template>

<script>
  import {debounce, throttle, getAllScrollParents} from '@veasel/core/tools';
  // mixins
  import {
    resize,
    optionsLogic,
    columnsLogic,
    checkboxLogic,
    exportLogic,
    editLogic,
    sortingLogic,
    resizingLogic,
    searchLogic,
    selectionLogic,
    paginationLogic,
    queryLogic,
    stylingLogic,
    scrollingLogic,
    renderingLogic,
    additionalFilterLogic,
  } from '@veasel/core/mixins';
  // components
  import {box, button, dropdown, dropdownItem, icon, loadingPanel} from '@veasel/base';
  import {pagination} from '@veasel/data';
  import {
    inputCheckbox,
    inputColor,
    inputDatetime,
    inputFile,
    inputMonetary,
    inputNumber,
    inputQuery,
    inputRadio,
    inputRadioGroup,
    inputSelect,
    inputSlider,
    inputText,
    inputTextarea,
  } from '@veasel/inputs';
  import cellEditModal from './cellEdit.modal.vue';

  export default {
    name: 's-data-table',

    description: 'A Table to display data with many features.',

    components: {
      's-box': box,
      's-button': button,
      's-dropdown': dropdown,
      's-dropdown-item': dropdownItem,
      's-icon': icon,
      's-loading-panel': loadingPanel,
      's-pagination': pagination,
      's-input-checkbox': inputCheckbox,
      's-input-color': inputColor,
      's-input-datetime': inputDatetime,
      's-input-file': inputFile,
      's-input-monetary': inputMonetary,
      's-input-number': inputNumber,
      's-input-query': inputQuery,
      's-input-radio': inputRadio,
      's-input-radioGroup': inputRadioGroup,
      's-input-select': inputSelect,
      's-input-slider': inputSlider,
      's-input-text': inputText,
      's-input-textarea': inputTextarea,
      cellEditModal,
    },

    mixins: [
      optionsLogic,
      columnsLogic,
      checkboxLogic,
      exportLogic,
      editLogic,
      sortingLogic,
      resizingLogic,
      searchLogic,
      selectionLogic,
      paginationLogic,
      queryLogic,
      stylingLogic,
      scrollingLogic,
      renderingLogic,
      resize,
      additionalFilterLogic,
    ],

    props: {
      rows: {
        description: 'The list of data rows to display',
        // array of objects [{colA:'1A', colB:'1B'}, {colA:'2A', colB:'2B'}]
        type: Array,
        default: () => [],
      },
      columns: {
        description: `
        The list of columns to display
        <ul style="margin-left: 20px;">
          <li><b>data</b> string : the column key to retrieve the value</li>
          <li><b>title</b> string : the column title to display in header</li>
          <li><b>width</b> string/number : the column minimum width (in px or %)</li>
          <li><b>noSorting</b> bool : a flag to disable the column sorting</li>
          <li><b>renderer</b> function : the rendering function using the following format:
            <blockquote>
              function(cell, value, x, y, row, col, scopeToCompile) {<br/>

                &nbsp;&nbsp; // cell           -> HTML element of the inner cell <br/>

              &nbsp;&nbsp; // value          -> the cell value<br/>

              &nbsp;&nbsp; // x              -> row index<br/>

              &nbsp;&nbsp; // y              -> column index<br/>

              &nbsp;&nbsp; // row            -> the row object<br/>

              &nbsp;&nbsp; // col            -> the column object<br/>

              &nbsp;&nbsp; // scopeToCompile -> an extendable object that will be use during compiling<br/><br/>

              &nbsp;&nbsp; cell.innerHTML = value;<br/>

              &nbsp;&nbsp; cell.style.backgroundColor = y%2===0 ? 'red' : 'blue';<br/>

              &nbsp;&nbsp; return cell;<br/>

              },
            </blockquote>
          </li>
        </ul>`,
        // array of columns [{data:'colA', title:'colB'}]
        type: Array,
        default: () => [],
      },
      options: {
        description: 'The options object, to specify advanced features and table style.',
        type: Object, // see optionLogic mixin
        default: () => ({}),
      },
      currencyExponentDefinition: {
        description: 'The dictionary to define currency exponents.',
        type: Object, // see optionLogic mixin
        default: () => ({}),
      },
    },

    created: function () {
      this.initOptions();
      this.initColumns();
    },
    methods: {
      setRowRef(rowID, el) {
        this.rowRefs[rowID] = el;
      },
    },
    data() {
      return {
        rowRefs: {},
        filteringQuery: {filters: {}, search: ''},
      };
    },
    computed: {
      isUpgradedComponent() {
        return true;
      },
      alteredRows() {
        return this.visibleRows;
      },
    },

    mounted: function () {
      this.resize();
      this.throttledSearch = debounce(this.searchInRows, this.opts.search.timeLimit);
      this.throttledCellUpdate = debounce(this.cellsUpdated, 200);
      this.throttleMmousemoveOutHandler = throttle(this.mousemoveOutHandler, 100);
      this.scrollers = getAllScrollParents(this.$el);

      // If the datatable is rendered, but is hidden, some positioning values like width are set to 0.
      // By setting up a resizeObserver, when the datatable changes it size because it is now visible, we run the resize function
      if ('ResizeObserver' in window) {
        const resizeObserver = new window.ResizeObserver(() => {
          this.resize();
        });
        resizeObserver.observe(this.$el);
      }
    },
  };
</script>

<style scoped lang="scss">
  @import '@veasel/core';

  .table-main-container {
    user-select: none;

    .s-loading-panel ::v-deep(.loading-overlay) {
      z-index: 28;
    }

    .datatable-header {
      margin-bottom: 10px;

      .table-additional-filter {
        min-height: 38px;
        margin-right: 10px;
      }

      .table-search {
        flex-grow: 1;
        min-height: 38px;
        margin-right: 10px;

        span {
          position: absolute;
          top: 8px;
          right: 12px;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          @include base-small;
        }
      }

      .table-settings {
        min-height: 38px;

        ::v-deep(.dropdown-content) {
          padding-right: 0 !important;
        }
      }

      .s-dropdown {
        hr {
          border-style: solid;
          border-bottom: 0;
          margin: get-spacing('m') 0;
        }

        .settings-content {
          padding-top: 10px;
          width: 300px;
          max-height: 300px;
          overflow-y: auto;
          text-align: left;
        }
      }
    }

    table thead tr th.column-header {
      overflow: visible;
      background: var(--background-secondary);

      &.sortable {
        cursor: pointer;
      }

      &:not(.sortable) {
        pointer-events: none;
      }

      .column-title {
        padding: 0 12px;
        width: calc(100% - 24px);
        left: 0;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include base-alt;
      }

      .sorting-arrows {
        top: 0;
        right: 4px;
        width: 10px;
        height: 20px;
        display: block;
        position: absolute;
        cursor: pointer;

        i {
          pointer-events: none;
          font-size: 12px;
          position: absolute;
          color: var(--secondary);
          left: 0;

          &.up {
            margin-top: 0;
          }

          &.down {
            margin-top: 7px;
          }

          &.active {
            color: black;
          }
        }
      }

      .resizing-bar {
        pointer-events: all;
        position: absolute;
        cursor: col-resize;
        z-index: 25;
        right: -4px;
        top: 0;
        height: 100%;
        width: 6px;
      }
    }

    th {
      position: relative;

      input[type='checkbox'] {
        position: absolute;
        top: 1px;
        left: 6px;
        width: 18px;
        height: 18px;
      }
    }

    .table-container {
      position: relative;

      .sticky-header {
        &:not(.is-sticky) {
          position: absolute;
          top: auto;
        }

        &.is-sticky {
          position: sticky;
          top: 0;
        }

        display: block;
        z-index: 22;

        .scroller-outer {
          top: 0;
          position: absolute;
          overflow: hidden;
        }
      }

      .sticky-checkbox-header {
        &:not(.is-sticky) {
          position: absolute;
          top: auto;
        }

        &.is-sticky {
          position: sticky;
          left: 0;
        }

        z-index: 24;

        .checkbox-header {
          position: absolute;

          th {
            border-bottom: 0;
            padding-top: 0.1875rem;
          }
        }
      }

      .sticky-checkbox {
        position: absolute;
        top: 0;

        .checkbox-table {
          position: sticky;
          display: block;
          left: 0;
          z-index: 21;
          width: 29px;
        }
      }

      .table-content {
        position: relative;

        .scroller-outer {
          overflow: auto;
        }

        td {
          position: relative;

          ::v-deep(.edit-cell) {
            display: none;
            position: absolute;
            right: 0;
            top: 0;
            background-color: $white;
            padding: 3px 3px 0 6px;
            border-left: 1px solid $lightgray-darker;
            cursor: pointer;

            svg {
              width: 14px;
              height: 14px;
            }
          }

          &:hover ::v-deep(.edit-cell) {
            display: block;
          }
        }

        .selector-container {
          pointer-events: none;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          overflow: hidden;

          .selector-inner-container {
            position: relative;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 100%;

            .selector {
              // Selector
              background-color: var(--light-hover);
              mix-blend-mode: multiply;
            }

            .selector-total {
              position: fixed;
              padding: get-spacing('xs');
              z-index: 26;
              opacity: 1;
              background-color: var(--hover);
              @include base-font;

              color: var(--background-main);

              label {
                text-align: left;
                font-weight: bold;
              }

              span {
                float: right;
                text-align: right;
              }
            }
          }
        }
      }

      .sticky-scroller {
        &:not(.is-sticky) {
          position: absolute;
          bottom: 24px;
        }

        &.is-sticky {
          position: sticky;
          bottom: 0;
        }

        display: block;
        z-index: 23;

        .scroller-outer {
          overflow-y: hidden;
          overflow-x: auto;

          .scroller-inner {
            height: 1px;
          }
        }
      }

      table {
        background-color: $white;
        table-layout: fixed;
        word-break: break-all;
        border-collapse: collapse;
        width: 100%;

        tr th {
          // Checkbox background color
          background-color: var(--background-secondary);
        }

        tr td,
        tr th {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          border: 1px solid var(--secondary-light);

          @include base-font;
        }

        tr td {
          padding: 0 4px;

          a {
            pointer-events: all;
            user-select: none;
          }
        }

        tr.stripped {
          $strippedColor1: #f0f0f0;
          $strippedColor2: #eaeaea;

          &:nth-child(even) {
            background: repeating-linear-gradient(
              -45deg,
              $strippedColor1,
              $strippedColor1 15px,
              $strippedColor2 0,
              $strippedColor2 30px
            );
          }

          &:nth-child(odd) {
            background: repeating-linear-gradient(
              -45deg,
              $strippedColor2,
              $strippedColor2 15px,
              $strippedColor1 0,
              $strippedColor1 30px
            );
          }
        }
      }
    }
  }
</style>
