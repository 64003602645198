<template>
  <div class="s-gauge" :style="gaugeAStyle">
    <div class="gauge-container" :style="gaugeAStyle">
      <div class="gauge-a" :style="gaugeAStyle"></div>
      <div class="gauge-b" :style="gaugeBStyle"></div>
      <div class="gauge-c" :style="gaugeCStyle"></div>
      <div class="gauge-data" :style="valueStyle">
        <p v-if="!hideValue" class="value">{{ value }}%</p>
      </div>
    </div>
    <p class="label">{{ label }}</p>
  </div>
</template>

<script>
  import {getColorForPercentage} from '@veasel/core/tools';

  export default {
    name: 's-gauge',
    description: 'A gauge to show a percentage in a fancy way.',
    props: {
      value: {
        description: 'The gauge value.',
        type: Number,
        default: 0,
      },
      label: {
        description: 'The descriptive label to display below the gauge.',
        type: String,
        default: '',
      },
      colors: {
        description: 'The gauge color spectrum as an array of colors and values.',
        type: Array,
        default: undefined,
      },
      width: {
        description: 'The gague width.',
        type: Number,
        default: 100,
      },
      thickness: {
        description: 'The gague thickness.',
        type: Number,
        default: 20,
      },
      hideValue: {
        description: 'A flag to display or not the gauge textual value.',
        type: Boolean,
        default: false,
      },
    },
    computed: {
      rotation: function () {
        const rotation = (parseInt(this.value) / 100) * 180;
        return 'rotate(' + rotation + 'deg)';
      },
      color: function () {
        return getColorForPercentage(this.value, this.colors);
      },
      radiusB: function () {
        const radius = this.width / 2 + this.thickness;
        return radius + 'px ' + radius + 'px 0 0';
      },
      radiusC: function () {
        const radius = this.width / 2;
        return '0 0 ' + radius + 'px ' + radius + 'px';
      },
      gaugeAStyle: function () {
        return {
          height: this.width / 2 + 'px',
          width: this.width + 'px',
        };
      },
      gaugeBStyle: function () {
        return {
          height: this.width / 2 - this.thickness + 1 + 'px',
          width: this.width - this.thickness * 2 + 'px',
          top: this.thickness + 'px',
          left: this.thickness + 'px',
          'border-radius': this.radiusB,
        };
      },
      gaugeCStyle: function () {
        return {
          transform: this.rotation,
          'background-color': this.color,
          height: this.width / 2 + 'px',
          width: this.width + 'px',
          top: this.width / 2 + 'px',
          'border-radius': this.radiusC,
        };
      },
      valueStyle: function () {
        return {
          height: this.width / 2 + 'px',
          width: this.width + 'px',
          top: this.width / 4 + 'px',
        };
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@veasel/core/sass/conf';

  $gauge-size: 100px;
  $gauge-width: 20px;

  .s-gauge {
    position: relative;
    padding-bottom: 30px;

    .gauge-container {
      margin-top: 10px;
      position: absolute;
      top: 30%;
      left: 50%;
      overflow: hidden;
      text-align: center;
      transform: translate(-50%, -50%);

      .gauge-a {
        z-index: 1;
        position: absolute;
        background-color: $lightgray;
        top: 0%;
        border-radius: 250px 250px 0 0;
      }

      .gauge-b {
        z-index: 3;
        position: absolute;
        background-color: $white;
        margin-right: auto;
      }

      .gauge-c {
        z-index: 2;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        transform-origin: center top;
        transition: all 1.3s ease-in-out;
      }

      .gauge-data {
        z-index: 4;

        p.value {
          margin-top: 6px;
        }

        top: 22px;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        transition: all 1s ease-out;
      }
    }

    p.label {
      position: absolute;
      bottom: -20px;
      margin: auto;
      width: 100%;
      text-align: center;
    }
  }
</style>
