<template>
  <div :id="id" :name="name" class="progress" :class="[{[size]: true, square: square}, styling]">
    <div class="multiple-progress-container">
      <div
        v-for="(v, index) in blocks"
        :key="index"
        :style="{width: v.percentage + '%'}"
        class="meter"
        v-bind="meterColor(v.color)"
      >
        <template v-if="size !== 'tiny'">
          <template v-if="v.label && showLabel">
            <div class="percent">
              <span class="theme-style-base">{{ v.label }}</span>
            </div>
          </template>
          <template v-else>
            <div :id="`percentage-${id}-${index}`" class="percent">
              <span v-if="styling !== 'lite'" class="no-events theme-style-base">{{ v.percentage + '%' }}</span>
            </div>
            <s-tooltip v-if="v.label" :target="`#percentage-${id}-${index}`" :width="100">
              {{ v.label }} ({{ v.percentage + '%' }})
            </s-tooltip>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import {clone} from '@veasel/core/tools';
  import {id, name, size} from '@veasel/core/mixins';
  import tooltip from '@veasel/base/tooltip';
  import {defineComponent, computed} from 'vue';

  const DEFAULT_COLOUR = 'theme-active';
  const PROGRESS_STYLES = ['default', 'lite'];
  const MIN_EMPTY_SPACE = 0.1;
  const HEX_COLOR_REGEX = /^#(?:[0-9a-f]{3}){1,2}$/i;

  export default defineComponent({
    name: 's-progress',
    description: 'A progress bar handling multiple parts.',
    components: {
      's-tooltip': tooltip,
    },
    mixins: [id, name, size(['tiny', 'small', 'medium', 'large'], 'medium')],
    props: {
      value: {
        description: 'The progress color(s), value(s) and label(s)',
        type: [Array, Number],
        default: () => [{percentage: 0, color: 'blue'}],
      },
      square: {
        description: 'A flag to make squared corner.',
        type: Boolean,
        default: false,
      },
      showLabel: {
        description: 'A flag to show/hide progress label(s).',
        type: Boolean,
        default: false,
      },
      styling: {
        description: 'Component style: default, lite, etc.',
        type: String,
        values: PROGRESS_STYLES,
        default: PROGRESS_STYLES[0],
      },
    },
    setup: (props) => {
      const defaultColor = DEFAULT_COLOUR;
      const meterColor = (color) => {
        const hexRegex = new RegExp(HEX_COLOR_REGEX);
        const isRegex = hexRegex.test(color);

        if (isRegex) {
          return {style: {background: color}};
        }
        return {class: color || defaultColor};
      };
      const getRemainingSpace = (blocks) =>
        100 - blocks.reduce((usedSpace, current) => usedSpace + current.percentage, 0);
      const blocks = computed(() => {
        let blocks = [];

        // Value prop: array or single value
        if (Array.isArray(props.value)) {
          blocks = clone(props.value);
        } else {
          blocks.push({percentage: props.value, color: defaultColor});
        }
        // Guess percentages
        if (!blocks.every((block) => 'percentage' in block)) {
          const guessedPercentage = 100 / blocks.length;
          blocks.forEach((block) => (block['percentage'] = guessedPercentage));
        } else if (props.styling === PROGRESS_STYLES[1]) {
          // If not guessing && 'lite'
          const emptySpace = getRemainingSpace(blocks);
          if (emptySpace > MIN_EMPTY_SPACE) {
            blocks.push({percentage: emptySpace, color: 'empty'});
          }
        }
        return blocks;
      });

      return {defaultColor, blocks, meterColor};
    },
  });
</script>

<style scoped lang="scss">
  @import '@veasel/core';

  $radiusInside: $button-radius - 0.125rem;

  .progress {
    &.tiny {
      height: 4px;

      .multiple-progress-container {
        gap: 1px;
      }
    }

    &.small {
      height: 20px;

      .multiple-progress-container {
        gap: 2px;
      }
    }

    &.medium {
      height: 38px;

      .multiple-progress-container {
        gap: 4px;
      }
    }

    &.large {
      height: 78px;

      .multiple-progress-container {
        gap: 6px;
      }
    }

    width: calc(100%);
    position: relative;
    margin: 2px 0;

    .meter.empty {
      background-color: var(--secondary-50);
    }

    .meter {
      position: relative;
      min-width: 0;
      max-width: 100%;
      overflow: visible;
      display: block;
      float: left;
      text-align: center;
      height: 100%;
      word-wrap: break-word;
      text-overflow: ellipsis;
      white-space: nowrap;
      transition: 0.2s;
    }

    &.square {
      border-radius: 0;
    }

    &.default:not(.square) .meter {
      &:first-child {
        border-top-left-radius: $radiusInside;
        border-bottom-left-radius: $radiusInside;
      }

      &:last-child {
        border-top-right-radius: $radiusInside;
        border-bottom-right-radius: $radiusInside;
      }
    }

    .percent {
      width: 100%;
      height: 100%;
      position: absolute;
      text-align: center;
      vertical-align: middle;

      span {
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 22px;
        line-height: 22px;
        color: var(--background-main);
        border-radius: 7px;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .multiple-progress-container {
      position: absolute;
      height: 100%;
      width: 100%;
      overflow: hidden;
    }
  }

  // 'Styling' classes
  .default {
    background-color: var(--background-main);
    border: 1px solid var(--secondary-50);
    border-radius: $button-radius;
  }

  .lite .multiple-progress-container {
    display: flex;
  }
</style>
