<template>
  <div class="graph-wrap graph-line" data-chart>
    <graph-tooltip :showTooltip="showTooltip" :details="tooltipData" />
  </div>
</template>

<script>
  import {area, line, pointer} from 'd3';
  import SVGGraph from '../utils/svgGraphMixin.js';
  import graphTooltip from './graphTooltip.vue';
  import {colorsMixin} from '@veasel/core/colors';

  export default {
    name: 's-graph-line',
    components: {
      graphTooltip,
    },
    mixins: [SVGGraph, colorsMixin],
    props: {
      values: {
        description: 'The number values to be used when constructing the graph.',
        type: Array,
        required: true,
      },
      options: {
        description: 'The options object, to specify style.',
        type: Object,
        default: () => ({}),
      },
      trackId: {
        description: 'The unique identifier for the wrapper that the svg must be applied to',
        type: String,
        required: true,
      },
    },
    data() {
      return {
        markerDot: undefined,
        markerLine: undefined,
        minValue: 0,
        maxValue: 0,
        tooltipData: {},
      };
    },
    // Methods inspired by https://tympanus.net/codrops/2022/03/29/building-an-interactive-sparkline-graph-with-d3/
    methods: {
      drawLineGraph: function () {
        this.minValue = Math.min(...this.values);
        this.maxValue = Math.max(...this.values);
        this.tooltipData.dotColor = this.s_colors(this.suadeColors.darkBlue);

        this.setupLineGraphOptions();
        this.setupGraph();

        this.xLinear = this.getLinearScaleX(0, this.values.length - 1);
        this.yLinear = this.getLinearScaleY(0, this.maxValue);

        this.drawArea();
        this.drawLine();
        this.drawMarkers();

        this.handleLineGraphEvents();
      },
      setupLineGraphOptions: function () {
        const defaults = {
          width: 60,
          height: 30,
          lineWidth: 2,
          markerLineWidth: 1,
          markerRadius: 2.5,
          marginTop: 8,
          sparklineColor: this.s_colors(this.suadeColors.darkBlue),
          areaFillColor: this.s_colors(this.suadeColors.lightBlue),
          markerColor: this.s_colors(this.suadeColors.red),
          minMarkerColor: this.s_colors(this.suadeColors.red),
          maxMarkerColor: this.s_colors(this.suadeColors.red),
          highlightMarkerColor: this.s_colors(this.suadeColors.red),
          highlightMarkerLineColor: this.s_colors(this.suadeColors.red),
        };

        this.graphOptions = this.getGraphOptions(defaults);
      },
      drawArea: function () {
        const areaGenerator = area()
          .x((_data, index) => this.xLinear(index))
          .y1((_data, index) => this.yLinear(this.values[index]))
          .y0(this.graphOptions.height);

        this.svgGraph
          .append('path')
          .datum(this.values)
          .attr('d', areaGenerator)
          .attr('fill', this.graphOptions.areaFillColor);
      },
      drawLine: function () {
        const lineGenerator = line()
          .x((_data, index) => this.xLinear(index))
          .y((_data, index) => this.yLinear(this.values[index]));

        this.svgGraph
          .append('path')
          .datum(this.values)
          .attr('d', lineGenerator)
          .attr('stroke', this.graphOptions.sparklineColor)
          .attr('stroke-width', this.graphOptions.lineWidth)
          .attr('stroke-linejoin', 'round')
          .attr('fill', 'none');
      },
      drawMarkers: function () {
        this.svgGraph
          .selectAll('mark')
          .data([this.minValue, this.maxValue, this.values[this.values.length - 1]])
          .enter()
          .append('circle')
          .attr('r', this.graphOptions.markerRadius)
          .attr('cx', (data, index) => {
            if (index === 2) {
              return this.xLinear(this.values.length - 1) - index; // minus index to prevent marker cut-off
            }
            return this.xLinear(this.values.indexOf(data));
          })
          .attr('cy', (data) => this.yLinear(data))
          .attr('fill', (_data, index) => {
            if (index === 0) return this.graphOptions.minMarkerColor;
            if (index === 1) return this.graphOptions.maxMarkerColor;
            if (index === 2) return this.graphOptions.markerColor;
          })
          .attr('opacity', 1);

        this.markerLine = this.svgGraph
          .append('line')
          .attr('x1', 0)
          .attr('x2', 0)
          .attr('y1', 0)
          .attr('y2', this.graphOptions.height)
          .attr('stroke-width', this.graphOptions.markerLineWidth)
          .attr('stroke', this.graphOptions.highlightMarkerLineColor)
          .attr('opacity', 0);

        this.markerDot = this.svgGraph
          .append('circle')
          .attr('r', this.graphOptions.markerRadius)
          .attr('fill', this.graphOptions.highlightMarkerColor)
          .attr('opacity', 0);
      },
      handleLineGraphEvents: function () {
        this.svgGraph.on('mouseenter', () => {
          this.showTooltip = true;
        });

        this.svgGraph.on('mousemove', (e) => {
          const [posX, posY] = pointer(e);
          const xInv = this.xLinear.invert(posX);
          const index = Math.round(xInv);

          const x = this.xLinear(index);
          const y = this.yLinear(this.values[index]);

          this.markerLine.attr('x1', x).attr('x2', x).attr('opacity', 1);

          this.markerDot.attr('cx', x).attr('cy', y).attr('opacity', 1);

          this.tooltipData.posX = posX + 15;
          this.tooltipData.posY = posY - 25;
          this.tooltipData.text = this.values[index];
        });

        this.svgGraph.on('mouseleave', () => {
          this.showTooltip = false;
          this.markerDot.attr('opacity', 0);
          this.markerLine.attr('opacity', 0);
        });
      },
    },
    mounted() {
      this.drawLineGraph();
    },
  };
</script>
