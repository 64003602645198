/**
 *
 *  All Suade Product config related constants should be here
 *
 **/

export default {
  // List Sizes
  DATALIST_DEFAULT_PAGE_SIZE: 200,
  DATALIST_PAGE_SIZE_OPTIONS: [10, 15, 25, 50, 100, 150, 200],
  EVENT_DEFAULT_PAGE_SIZE: 15,
  USER_ACTIVITY_PAGE_SIZE: 10,
  USER_ANNOUNCEMENT_PAGE_SIZE: 10,
  FORM_DEFAULT_PAGE_SIZE: 10,
  REPORT_SELECTOR_DEFAULT_PAGE_SIZE: 10,

  // LIMITATIONS
  MAX_NUMBER_OF_REPORTS_TO_COMPARE: 4,

  // THRESHOLDS

  // system overview metrics
  CPU_NORMAL_THRESHOLD: 60, // %
  CPU_WARNING_THRESHOLD: 90, // %
  MEMORY_NORMAL_THRESHOLD: 50, // %
  MEMORY_WARNING_THRESHOLD: 80, // %
  // DISK_READ_NORMAL_THRESHOLD : 50, // bytes
  // DISK_READ_WARNING_THRESHOLD : 80, // bytes
  // DISK_WRITE_NORMAL_THRESHOLD : 50, // bytes
  // DISK_WRITE_WARNING_THRESHOLD : 80, // bytes
  // NET_SENT_NORMAL_THRESHOLD : 50, // bytes
  // NET_SENT_WARNING_THRESHOLD : 80, // bytes
  // NET_RECV_NORMAL_THRESHOLD : 50, // bytes
  // NET_RECV_WARNING_THRESHOLD : 80, // bytes
  DISK_USED_NORMAL_THRESHOLD: 50, // %
  DISK_USED_WARNING_THRESHOLD: 80, // %
  // ACTIVE_USERS_NORMAL_THRESHOLD : 50, // number
  // ACTIVE_USERS_WARNING_THRESHOLD : 80, // number

  // system overview line charts contains holes if the time difference between 2 values is superior to the resolution with this percentage tolerance
  SYSTEM_DOWN_RESOLUTION_PERCENTAGE_TOLERENCE: 1.1, // 10%

  // Identifies the UI to backend
  UI_CLIENT_ID: '2a04uDahonLC4Lx9dI0FxLERweRHCEE5PfHlEcPM3vmRgdtrgTorBvt46',

  // Number of milliseconds before session expiration to warn user (5 mins)
  SESSION_EXPIRATION_WARNING_MS: 1000 * 60 * 5,

  // Number of logs display by default
  LOGS_COUNT: 500,
  // Number of search result logs
  LOGS_SEARCH_COUNT: 10,
  // Number of logs displayed before and after the filtered logs
  LOGS_SEARCH_CONTEXT_NUMBER: 10,
  // Number of before of after logs load on caret click
  LOGS_CONTEXT_LOAD_STEP: 10,

  TAB_STYLE: 'flat',

  // Should Tutorial be globally enabled
  TUTORIAL_ENABLED: false,
};
