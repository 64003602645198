<template>
  <div ref="treechart" class="s-tree-chart">
    <div :id="uniqueID" ref="chart"></div>
  </div>
</template>

<script>
  import * as d3 from 'd3';
  import {id} from '@veasel/core/mixins';
  import {uniqId} from '@veasel/core/tools';
  import {humanizeCapitalize, uppercase} from '@veasel/core/formatters';

  const DEFAULT_OPTIONS = {
    circleOuterColor: 'var(--active)',
    circleInnerColorClose: 'var(--background-main)',
    circleInnerColorOpen: 'var(--secondary)',
    linkColor: 'var(--secondary)',
    customSteps: [],
    mergeLastStep: false,
    flat: false,
    openNodes: false,
    zoomAndDrag: true,
  };

  export default {
    name: 's-tree-chart',

    description: 'A collapsible tree diagram.',
    emits: ['labelClick'],
    props: {
      values: {
        description: 'List of values to display in the chart',
        type: Array,
      },
      options: {
        description: 'The options object, to specify advanced features and chart style.',
        type: Object,
        default: () => ({}),
      },
      treeSource: {
        description: 'The source of the tree',
        type: String,
      },
    },
    mixins: [id],
    data: function () {
      return {
        uniqueID: '',
        treechartNullVal: 'slnone',
        duration: 750,
        i: 0,
        margin: {top: 20, right: 0, bottom: 30, left: 150},
      };
    },
    computed: {
      _options() {
        return {...DEFAULT_OPTIONS, ...this.$props.options};
      },
      width() {
        return this._options.width
          ? this._options.width - this.margin.left - this.margin.right
          : this.parentContainer.getBoundingClientRect().width - this.margin.left - this.margin.right;
      },
      height() {
        return this._options.height
          ? this._options.height - this.margin.top - this.margin.bottom
          : this.parentContainer.getBoundingClientRect().height - this.margin.top - this.margin.bottom;
      },
      parentContainer() {
        return this.$refs.treechart;
      },
    },
    methods: {
      parseAndFlatten(groupedData, result = 'source,target\n', source = '', prefix = '') {
        Object.keys(groupedData).forEach((key) => {
          result += prefix + '__' + source + ',' + prefix + '__' + source + '__' + key + '\n';
          if (groupedData) {
            result += this.parseAndFlatten(groupedData[key], '', key, prefix + '__' + source);
          }
        });
        return result;
      },
      groupData(data, customSteps, step = 0, filters = {}) {
        const vue = this;

        if (step >= customSteps.length) {
          return false;
        }

        // last step! here we should merge the ids
        if (this._options.mergeLastStep && customSteps.length - 1 === step) {
          const reportIds = data
            .filter((d) => {
              return Object.keys(filters).every((f) => {
                const type = d[f] ? d[f] : vue.treechartNullVal;
                return type === filters[f];
              });
            })
            .map((d) => {
              return d[customSteps[step]];
            });
          return {[reportIds.join('||')]: false};

          // not the last step
        } else {
          return data
            .filter((d) => {
              return Object.keys(filters).every((f) => {
                const type = d[f] ? d[f] : vue.treechartNullVal;
                return type === filters[f];
              });
            })
            .map((d) => {
              if (d[customSteps[step]]) {
                return d[customSteps[step]];
              } else {
                return vue.treechartNullVal;
              }
            })
            .reduce((acc, d) => {
              return {
                ...acc,
                [d]: this.groupData(data, customSteps, step + 1, {...filters, [customSteps[step]]: d}),
              };
            }, {});
        }
      },
      collapse(d) {
        if (d.children) {
          d._children = d.children;
          d._children.forEach(this.collapse);
          d.children = null;
        }
      },
      makeTree(links) {
        const nodesByName = {};

        // Helper function to get or create a node by its name
        const getNodeByName = (name) => {
          if (!nodesByName[name]) {
            nodesByName[name] = {name};
          }
          return nodesByName[name];
        };

        // Process each link and build the tree structure
        links.forEach((link) => {
          const parentNode = (link.source = getNodeByName(link.source));
          const childNode = (link.target = getNodeByName(link.target));

          // Add the child node to the parent's children array
          if (parentNode.children) {
            parentNode.children.push(childNode);
          } else {
            parentNode.children = [childNode];
          }
        });

        return links;
      },
      expand(d) {
        const children = d.children ? d.children : d._children;
        if (d._children) {
          d.children = d._children;
          d._children = null;
        }
        if (children) {
          children.forEach(this.expand);
        }
      },
      expandAll(root) {
        this.expand(root);
      },
      generateFlatChartData() {
        const {customSteps} = this._options;
        const values = this.values;
        const data = customSteps.length ? this.groupData(values, customSteps) : values;
        const processedData = this.parseAndFlatten(
          data,
          'source,target\n',
          this.treeSource || Object.keys(data)[0].toLocaleUpperCase()
        );
        const csvData = d3.csvParse(processedData);

        return this.makeTree(csvData);
      },
      initializeSvg() {
        const {id} = this.$props;
        const chartContainer = this.$refs.chart;
        const container = d3.select(chartContainer);
        this.svg = container.select('svg');

        if (this.svg.empty()) {
          this.svg = container.append('svg');
        }

        this.svg.attr('id', id);
        this.svg
          .attr('width', this.width + this.margin.right + this.margin.left)
          .attr('height', this.height + this.margin.top + this.margin.bottom)
          .attr('preserveAspectRatio', 'xMinYMin meet')
          .attr(
            'viewBox',
            `0 0 ${this.width + this.margin.right + this.margin.left} ${
              this.height + this.margin.top + this.margin.bottom
            }`
          );
        this.svg.append('g');
      },
      setupGEnter() {
        this.gEnter = this.svg.select('g');
        this.gEnter.attr('transform', `translate(${this.margin.left}, ${this.margin.top + this.height / 2})`);
      },
      setupRoot(start) {
        // Declares a tree layout
        this.treemap = d3.tree();
        // Assigns parent, children, height, depth
        this.root = d3.hierarchy(start, function (d) {
          return d.children;
        });
        this.root.x0 = 0;
        this.root.y0 = this.width;
      },
      setupInitialCollapse() {
        const {openNodes} = this._options;

        if (openNodes) {
          this.expandAll(this.root);
        } else {
          this.root.children.forEach(this.collapse);
          this.collapse(this.root);
        }
      },
      setupZoomAndDrag() {
        const vue = this;
        const {zoomAndDrag} = this._options;

        if (zoomAndDrag) {
          this.svg.call(
            d3
              .zoom()
              .scaleExtent([0, 10])
              .extent([
                [0, 0],
                [this.width, this.height],
              ])
              .on('zoom', function (event) {
                if (vue.gEnter) {
                  vue.gEnter.attr('transform', event.transform);
                }
              })
          );
        }
      },
      update(source) {
        this.setNodeSizeAndSpacing();
        this.setSvgAttributes();
        const treeData = this.treemap(this.root);
        const nodes = treeData.descendants();
        const links = treeData.descendants().slice(1);

        this.updateNodes(nodes, source);
        this.updateLinks(links, source);
        this.storePositions(nodes);
      },
      setNodeSizeAndSpacing() {
        this.treemap.nodeSize([45, 275]).separation((a, b) => {
          return a.parent == b.parent ? 1 : 1.25;
        });
      },
      setSvgAttributes() {
        const {height, width, margin} = this;
        this.svg
          .attr('width', width + margin.right + margin.left)
          .attr('height', height + margin.top + margin.bottom)
          .attr('preserveAspectRatio', 'xMinYMin meet')
          .attr('viewBox', `0 0 ${width + margin.right + margin.left} ${height + margin.top + margin.bottom}`);
      },
      updateNodes(nodes, source) {
        const node = this.createNodeSelection(nodes);
        const nodeEnter = this.createNodeEnterSelection(node, source);

        this.addCircleForNodes(nodeEnter);
        this.addLabelsForNodes(nodeEnter);

        const nodeUpdate = nodeEnter.merge(node);
        this.updateNodeAttributesAndStyle(nodeUpdate);

        this.removeExitingNodes(node, source);
      },
      createNodeSelection(nodes) {
        const vue = this;
        return this.gEnter.selectAll('g.node-tree').data(nodes, (d) => {
          return d.id || (d.id = ++vue.i);
        });
      },
      createNodeEnterSelection(nodeEnter, source) {
        const vue = this;
        return nodeEnter
          .enter()
          .append('g')
          .attr('class', 'node-tree')
          .attr('transform', () => {
            return 'translate(' + source.y0 + ',' + source.x0 + ')';
          })
          .on('click', vue.click);
      },
      addCircleForNodes(nodeEnter) {
        const {circleInnerColorOpen, circleInnerColorClose} = this._options;
        const vue = this;

        nodeEnter
          .transition()
          .duration(vue.duration)
          .attr('transform', function (d) {
            return 'translate(' + d.y + ',' + d.x + ')';
          });

        nodeEnter
          .filter(function (d) {
            const nId = d.data.name?.split('__').slice(-1)[0].split('||')[0];
            return nId !== vue.treechartNullVal;
          })
          .append('circle')
          .attr('class', 'node-tree')
          .attr('r', 6)
          .style('fill', (d) => {
            return d._children ? circleInnerColorOpen : circleInnerColorClose;
          });
      },
      addLabelsForNodes(nodeEnter) {
        const vue = this;
        nodeEnter
          .append('text')
          .attr('x', (d) => {
            return d.children || d._children ? 0 : 10;
          })
          .attr('dy', (d) => {
            return d.children || d._children ? 16 : 3;
          })
          .attr('text-anchor', (d) => {
            return d.children || d._children ? 'middle' : 'start';
          })
          .each(function (d) {
            // change the arrow function to a regular function
            vue.processLabelText(d, this); // pass 'this' as a second argument
          });
      },
      processLabelText(d, element) {
        const formatHumanize = humanizeCapitalize;
        const upperCase = uppercase;
        const {customSteps} = this._options;
        const recognizedLables = [];
        const vue = this;

        const arrayIds = d.data.name?.split('__').slice(-1)[0].split('||');
        for (let i = 0; i < arrayIds.length; i++) {
          const id = arrayIds[i];
          const label = '';
          if (d.depth === customSteps.length) {
            const parentName = d.parent?.data?.name;
            const findLabel = recognizedLables.find((l) => {
              return l === parentName;
            });
            const lastLabel = d.depth === 0 ? '' : customSteps[d.depth - 1];
            if (!findLabel) {
              d3.select(element)
                .append('tspan')
                .text(upperCase(formatHumanize(lastLabel + ': ' + label)));
              recognizedLables.push(parentName);
            }
          } else {
            d3.select(element)
              .append('tspan')
              .text(upperCase(formatHumanize(label)) + '');
          }
          d3.select(element)
            .append('tspan')
            .attr('class', d.depth === customSteps.length ? 'anchor' : '')
            .text(id === vue.treechartNullVal ? '' : upperCase(formatHumanize(id)))
            .on('click', function (_, d) {
              if (d.depth === customSteps.length) {
                vue.$emit('labelClick', {
                  data: id,
                  label,
                });
              }
            });
          if (i < arrayIds.length - 1) {
            d3.select(element).append('tspan').text(', ');
          }
        }
      },
      updateNodeAttributesAndStyle(nodeUpdate) {
        const {circleInnerColorOpen, circleInnerColorClose} = this._options;
        const vue = this;

        // Transition to the proper position for the node
        nodeUpdate
          .transition()
          .duration(vue.duration)
          .attr('transform', function (d) {
            return 'translate(' + d.y + ',' + d.x + ')';
          });

        // Update the node attributes and style
        nodeUpdate
          .select('circle.node-tree')
          .attr('r', 6)
          .style('fill', function (d) {
            return d._children ? circleInnerColorOpen : circleInnerColorClose;
          })
          .attr('cursor', 'pointer');
      },
      removeExitingNodes(node, source) {
        const vue = this;
        const nodeExit = node
          .exit()
          .transition()
          .duration(vue.duration)
          .attr('transform', () => {
            return 'translate(' + source.y + ',' + source.x + ')';
          })
          .remove();

        // On exit reduce the node circles size to 0
        nodeExit.select('circle').attr('r', 1e-6);

        // On exit reduce the opacity of text labels
        nodeExit.select('text').style('fill-opacity', 1e-6);
      },
      updateLinks(links, source) {
        const link = this.createLinkSelection(links);
        const linkEnter = this.createLinkEnterSelection(link, source);

        const linkUpdate = linkEnter.merge(link);
        this.updateLinkAttributes(linkUpdate);

        this.removeExitingLinks(link, source);
      },
      createLinkSelection(links) {
        return this.gEnter.selectAll('path.link').data(links, (d) => {
          return d.id;
        });
      },
      createLinkEnterSelection(link, source) {
        const vue = this;
        return link
          .enter()
          .insert('path', 'g')
          .attr('class', 'link')
          .attr('d', () => {
            const o = {x: source.x0, y: source.y0};
            return vue.diagonal(o, o);
          });
      },
      updateLinkAttributes(linkUpdate) {
        const vue = this;
        linkUpdate
          .transition()
          .duration(vue.duration)
          .attr('d', function (d) {
            return vue.diagonal(d, d.parent);
          });
      },
      removeExitingLinks(link, source) {
        const vue = this;
        link
          .exit()
          .transition()
          .duration(vue.duration)
          .attr('d', () => {
            const o = {x: source.x, y: source.y};
            return vue.diagonal(o, o);
          })
          .remove();
      },
      storePositions(nodes) {
        nodes.forEach(function (d) {
          d.x0 = d.x;
          d.y0 = d.y;
        });
      },

      generateChart() {
        const {flat} = this._options;
        let start;

        if (flat) {
          const links = this.generateFlatChartData();
          start = links[0].source;
        } else {
          start = this.values[0];
        }

        this.initializeSvg();
        this.setupGEnter();
        this.setupRoot(start);
        this.setupInitialCollapse();
        this.update(this.root);
        this.setupZoomAndDrag();
      },

      // Toggle children on click.
      click(_, d) {
        if (d.children) {
          d._children = d.children;
          d.children = null;
        } else {
          d.children = d._children;
          d._children = null;
        }
        this.update(d);
      },

      // Creates a curved (diagonal) path from parent to the child nodes
      diagonal(s, d) {
        const path = `M ${s.y} ${s.x}
            C ${(s.y + d.y) / 2} ${s.x},
              ${(s.y + d.y) / 2} ${d.x},
              ${d.y} ${d.x}`;

        return path;
      },
    },
    watch: {
      values: {
        deep: true,
        handler() {
          this.generateChart();
        },
      },
    },
    created() {
      if (typeof this.$props.id === 'undefined') {
        this.uniqueID = uniqId('treechart_').replace('.', '');
      } else {
        this.uniqueID = this.$props.id;
      }
    },
    mounted() {
      this.$nextTick(async () => {
        this.generateChart();
      });
    },
  };
</script>

<style lang="scss">
  @import '@veasel/core';

  .s-tree-chart {
    overflow: auto;
  }

  .node-tree circle {
    stroke: v-bind('_options.circleOuterColor');
    stroke-width: 2px;
  }

  .node-tree text {
    & a {
      display: block;
    }

    font: calc(1em / 1.5) sans-serif;
  }

  .link {
    fill: none;
    stroke: v-bind('_options.linkColor');
    stroke-width: 1px;
  }

  .anchor {
    @include base-small-bold-active;

    fill: var(--active);
    cursor: pointer;
  }
</style>
