export default {
  install(Vue) {
    Vue.directive('drag-and-drop', {
      beforeMount: function (el, binding) {
        binding.handleDragStart = function (e) {
          e.target.classList.add('dragging');
          e.dataTransfer.effectAllowed = 'move';
          // Need to set to something or else drag doesn't start
          if (e.target.querySelector('.dragging-ghost')) {
            e.dataTransfer.setDragImage(e.target.querySelector('.dragging-ghost'), 0, 0);
          } else {
            e.dataTransfer.setData('text', '*');
          }
          binding.instance.$emit('drag-start');
        };

        binding.handleDragOver = function (e) {
          if (e.preventDefault) {
            // allows dropping
            e.preventDefault();
          }

          e.dataTransfer.dropEffect = 'move';
          e.target.classList.add('drag-over');
          binding.instance.$emit('drag-over');

          return false;
        };

        binding.handleDragEnter = function (e) {
          binding.instance.$emit('drag-enter');
          e.target.classList.add('drag-enter');
        };

        binding.handleDragLeave = function (e) {
          binding.instance.$emit('drag-leave');
          e.target.classList.remove('drag-enter', 'drag-over');
        };

        binding.handleDrag = function () {
          binding.instance.$emit('drag');
        };

        binding.handleDragEnd = function (e) {
          e.target.classList.remove('dragging', 'drag-over', 'drag-enter');
          binding.instance.$emit('drag-end');
        };

        binding.handleDrop = function (e) {
          e.preventDefault();
          if (e.stopPropagation) {
            // stops the browser from redirecting.
            e.stopPropagation();
          }

          // Don't do anything if dropping the same column we're dragging.
          binding.instance.$emit('drop');

          return false;
        };

        // setup the listeners
        el.setAttribute('draggable', 'true');
        el.addEventListener('dragstart', binding.handleDragStart, false);
        el.addEventListener('dragenter', binding.handleDragEnter, false);
        el.addEventListener('dragover', binding.handleDragOver, false);
        el.addEventListener('drag', binding.handleDrag, false);
        el.addEventListener('dragleave', binding.handleDragLeave, false);
        el.addEventListener('dragend', binding.handleDragEnd, false);
        el.addEventListener('drop', binding.handleDrop, false);
      },

      unmounted: function (el, binding) {
        // shut er' down
        el.classList.remove('dragging', 'drag-over', 'drag-enter');
        el.removeAttribute('draggable');
        el.removeEventListener('dragstart', binding.handleDragStart);
        el.removeEventListener('dragenter', binding.handleDragEnter);
        el.removeEventListener('dragover', binding.handleDragOver);
        el.removeEventListener('dragleave', binding.handleDragLeave);
        el.removeEventListener('drag', binding.handleDrag);
      },
    });
  },
};
