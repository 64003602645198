<template>
  <div
    :style="{height: height + 'px', '--topbar-height': height + 'px', paddingLeft: navbarWidth}"
    :class="{'s-topbar': true}"
  >
    <slot name="header"></slot>
    <slot name="content"></slot>
  </div>
</template>

<script>
  export default {
    name: 's-topbar',

    description: 'A topbar component.',

    props: {
      height: {
        description: 'The topbar height',
        type: Number,
        default: 42,
      },
      navbarExpandedWidth: {
        description: 'The navbar width when expanded',
        type: Number,
        default: 220,
      },
      isNavbarCollapsed: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      navbarWidth() {
        return (this.isNavbarCollapsed ? 50 : this.navbarExpandedWidth) + 'px';
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@veasel/core/sass/conf';

  // Color variables from settings if present
  $user-primary-color: var(--user-primary-color, var(--topbar));

  .s-topbar {
    background-color: $user-primary-color; // $blue;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 43;
    display: flex;
    flex-direction: row;
    justify-content: left;
  }
</style>
