<template>
  <div class="graph-wrap graph-box" data-chart>
    <graph-tooltip :showTooltip="showTooltip" :showDot="false" :details="tooltipData" />
  </div>
</template>

<script>
  import {pointer, quantile} from 'd3';
  import SVGGraph from '../utils/svgGraphMixin.js';
  import graphTooltip from './graphTooltip.vue';
  import {colorsMixin} from '@veasel/core/colors';

  export default {
    name: 's-graph-box',
    components: {
      graphTooltip,
    },
    mixins: [SVGGraph, colorsMixin],
    props: {
      values: {
        description: 'The number values to be used when constructing the graph.',
        type: Array,
        required: true,
      },
      options: {
        description: 'The options object, to specify style.',
        type: Object,
        default: () => ({}),
      },
      trackId: {
        description: 'The unique identifier for the wrapper that the svg must be applied to',
        type: String,
        required: true,
      },
    },
    data() {
      return {
        quantile1: 0,
        median: 0,
        quantile3: 0,
        markerLineHeight: 0,
        centerHeight: 0,
        boxStart: 0,
        sortedData: [],
      };
    },
    methods: {
      drawBoxGraph: function () {
        this.setupBoxGraphOptions();
        this.setupGraph();
        this.processData();

        this.drawXAxis();
        this.drawBox();
        this.drawVerticalMarkers();

        this.handleBoxMouseEvents();
      },
      setupBoxGraphOptions: function () {
        const defaults = {
          width: 60,
          height: 25,
          marginTop: 0,
          boxLineColor: this.s_colors(this.suadeColors.blue),
          boxFillColor: this.s_colors(this.suadeColors.lightBlue),
          whiskerColor: this.s_colors(this.suadeColors.blue),
          medianColor: this.s_colors(this.suadeColors.red),
        };
        this.graphOptions = this.getGraphOptions(defaults);
      },
      processData: function () {
        const data = [...this.values];
        this.sortedData = data.sort((a, b) => a - b); // ascending

        this.centerHeight = this.graphOptions.height / 2;
        this.markerLineHeight = this.graphOptions.height / 4;
        this.boxStart = this.markerLineHeight / 2;

        this.quantile1 = quantile(this.sortedData, 0.25);
        this.median = quantile(this.sortedData, 0.5);
        this.quantile3 = quantile(this.sortedData, 0.75);
        // Get the x value in relation to the width of the graph.
        this.xLinear = this.getLinearScaleX(this.values[0], this.values.at(-1));
      },
      drawXAxis: function () {
        this.svgGraph
          .append('line')
          .attr('x1', 0)
          .attr('x2', this.graphOptions.width)
          .attr('y1', this.centerHeight)
          .attr('y2', this.centerHeight)
          .attr('stroke', this.graphOptions.boxLineColor)
          .attr('stroke-width', 1);
      },
      drawBox: function () {
        const boxRange = this.quantile3 - this.quantile1;

        this.svgGraph
          .append('rect')
          .attr('x', this.xLinear(this.quantile1))
          .attr('y', this.boxStart)
          .attr('width', this.xLinear(boxRange))
          .attr('height', this.markerLineHeight + this.centerHeight)
          .attr('stroke', this.graphOptions.boxLineColor)
          .attr('fill', this.graphOptions.boxFillColor);
      },
      drawVerticalMarkers: function () {
        const boxHeight = this.markerLineHeight + this.centerHeight;

        this.svgGraph
          .selectAll('markerLine')
          .data([0, this.xLinear(this.median), this.graphOptions.width])
          .enter()
          .append('line')
          .attr('x1', (data) => data)
          .attr('x2', (data) => data)
          .attr('y1', (_data, index) => (index === 1 ? this.boxStart : this.markerLineHeight))
          .attr('y2', (_data, index) => (index === 1 ? this.boxStart + boxHeight : boxHeight))
          .attr('stroke', (_data, index) =>
            index === 1 ? this.graphOptions.medianColor : this.graphOptions.whiskerColor
          )
          .attr('stroke-width', 1);
      },
      handleBoxMouseEvents: function () {
        this.svgGraph.on('mousemove', (e) => {
          const [posX, posY] = pointer(e);

          // Formatting this way prevents leading white space in template literal with new lines
          this.tooltipData.text = `Lower Quartile: ${this.quantile1}\nMedian: ${this.median}\nUpper Quartile: ${this.quantile3}\n`;
          this.tooltipData.text += `Left Outlier: ${this.sortedData[0]}\nRight Outlier: ${this.sortedData.at(-1)}\n`;
          this.tooltipData.text += `Left Whisker: ${this.sortedData[0]}\nRight Whisker: ${this.sortedData.at(-1)}`;

          this.tooltipData.posX = posX + 20;
          this.tooltipData.posY = posY - 30;
        });

        this.svgGraph.on('mouseleave', () => {
          this.showTooltip = false;
        });
      },
    },

    mounted() {
      this.drawBoxGraph();
    },
  };
</script>
