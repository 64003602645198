import {debounce, isEmpty, clone} from '@veasel/core/tools';

export default {
  emits: ['filter-updated', 'filter-changed'],

  data() {
    return {
      throttledFilter: debounce(this.filterChange, 750),
    };
  },
  computed: {
    currentFilterCount() {
      return this.opts.additionalFilter.filters.filter(
        (filter) =>
          filter.default !== undefined &&
          filter.default !== null &&
          filter.default !== false &&
          filter.default &&
          filter.default.length !== 0
      ).length;
    },
  },

  methods: {
    setFilters(filter, value) {
      const results = this.opts.additionalFilter.filters.find((f) => f.key === filter);
      if (!isEmpty(results)) {
        results.default = value;
      }
      this.filterChange();
    },
    filterChange: function () {
      this.$emit(
        this.opts.additionalFilter.event,
        this.opts.additionalFilter.filters
          .filter((filter) => filter.default !== undefined && filter.default !== null)
          .map((filter) => ({[filter.key]: filter.default}))
      );
      this.opts.pagination.offset = 0;
      this.updateQuery();
    },
    removeFilterSelection: function (key, selected) {
      const filterKey = this.opts.additionalFilter.filters.findIndex((filter) => filter.key === key);
      if (filterKey > -1) {
        if (['select', 'date'].includes(this.opts.additionalFilter.filters[filterKey].field)) {
          this.opts.additionalFilter.filters[filterKey].selected = null;
        } else {
          this.opts.additionalFilter.filters[filterKey].options[selected] = false;
        }
      }
    },
    clearFilters() {
      const filtersToClear = clone(this.getCurrentFiltersForDisplay); // Clone it so we don't have weird update issues when clearing
      filtersToClear.forEach((filter) => {
        filter.selected.forEach((selected) => {
          this.removeFilterSelection(filter.key, selected);
        });
      });
    },
  },
  watch: {
    getCurrentFiltersForDisplay: {
      deep: true,
      handler(newValue, oldValue) {
        if (!(newValue.length === 0 && oldValue.length === 0)) {
          this.throttledFilter();
        }
      },
    },
  },
};
