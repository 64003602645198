<template>
  <div class="dropdown-item-wrapper">
    <div :id="uID" :class="['flex', 'align-items-center', 'dropdown-item', disable ? 'disabled' : '']">
      <s-icon
        v-if="!isEmpty(buttonIcon)"
        :type="buttonIcon"
        class="icon m-r-s"
        :color="disable ? 'theme-secondary-50' : 'theme-secondary'"
      />
      <div :class="[disable ? 'theme-style-caption-secondary-50' : 'theme-style-caption-alt', 'item-text']">
        {{ buttonText }}
        <slot></slot>
      </div>
    </div>
    <s-tooltip v-if="tooltipText" position="right" color="theme-background-main" :target="'#' + uID">
      {{ tooltipText }}
    </s-tooltip>
  </div>
</template>

<script>
  import {isEmpty, uniqId} from '@veasel/core/tools';
  import icon from '@veasel/base/icon';
  import tooltip from '@veasel/base/tooltip';

  export default {
    name: 's-dropdown-title',
    description: 'A component to display a item, built for use within the dropdown component',
    components: {
      's-icon': icon,
      's-tooltip': tooltip,
    },
    props: {
      buttonText: {
        description: 'The text to describe item action.',
        type: String,
        default: ' ',
      },
      buttonIcon: {
        description: 'The icon to have next to the text',
        type: String,
        default: '',
      },
      disable: {
        description: 'A flag to allow user to click on the item or not.',
        type: Boolean,
        default: false,
      },
      tooltipText: {
        description: 'Text to appear in a tooltip when hovered over',
        type: String,
        default: '',
      },
    },
    data() {
      return {
        uID: '',
      };
    },
    methods: {
      isEmpty,
    },
    created() {
      this.uID = uniqId('uid-', 0);
    },
  };
</script>

<style lang="scss" scoped>
  @import '@veasel/core';

  .icon {
    font-size: 14px;
  }

  .icon-spacer {
    width: 12px;
  }

  .dropdown-item {
    padding: rem(9px) rem(11px);

    &:not(.disabled) {
      transition: ease background $transition-time;
      cursor: pointer;
    }

    ::v-deep(.icon) {
      display: flex;
      align-items: center;
    }
  }
</style>
