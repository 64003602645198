import suadeIcons from '../svgIcons.svg?raw';

/**
 * Extract an icon from the svg bundle file
 * @param {String} type The icon to extract
 * @return {String} svg icon as a raw string
 */
export const getIconByType = (type) => {
  const regex = new RegExp(`<svg data-id="s-icon-${type}"[\\S\\s]+?</svg>`, 'g');
  return suadeIcons.toString().match(regex);
};
