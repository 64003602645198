export default {
  props: {
    placeholder: {
      description: 'A text to display in the input when value is null (useless if label is used)',
      type: [String, Array],
      default: '',
    },
    label: {
      description: 'The descriptive label for the input.',
      type: String,
    },
  },
  computed: {
    placeholderIsArray() {
      return this.placeholder instanceof Array;
    },
    _placeholder() {
      return this.placeholder || this.label;
    },
  },
};
