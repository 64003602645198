/**
 * Mixin that handles the logic of poisitioning an element around a target
 */
import {uniqId} from '@veasel/core/tools';
import resize from './resize';
export default {
  name: 'sPosition',
  mixins: [resize],
  props: {
    position: {
      description: 'The desired position of the element, relative to the target.',
      type: String,
      values: ['top', 'bottom', 'left', 'right', 'auto-vertical', 'auto-horizontal', 'auto'],
      validator: (value) =>
        ['top', 'bottom', 'left', 'right', 'auto-vertical', 'auto-horizontal', 'auto'].includes(value),
      default: 'auto',
    },
    align: {
      description: 'The desired alignment of the element relative to the target.',
      type: String,
      values: ['top', 'bottom', 'left', 'right', 'center', 'auto'],
      validator: (value) => ['top', 'bottom', 'left', 'right', 'center', 'auto'].includes(value),
      default: 'center',
    },
    target: {
      description: 'The selector of the target element.',
      type: String,
    },
    caret: {
      description: 'Whether or not the positioned element has a caret.',
      type: Boolean,
      default: true,
    },
    padding: {
      description: 'The padding between the positioned element and the target element.',
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      id: '',
      newPosition: {
        top: 0,
        left: 0,
      },
      caretPosition: {
        top: 0,
        left: 0,
      },
      targetBoundingRect: {
        top: 0,
        left: 0,
        height: 0,
        width: 0,
      },
      positionedBoundingRect: {
        top: 0,
        left: 0,
        height: 0,
        width: 0,
      },
      caretSize: {
        width: 16,
        height: 10,
      },
      borderSize: 0,
      actualPosition: '',
      tooltipContentStyle: {},
    };
  },

  computed: {
    elementSelector() {
      return this.id ? '#' + this.id : '';
    },
    positionStyleProps() {
      return {
        left: this.newPosition.left + 'px',
        top: this.newPosition.top + 'px',
      };
    },
    caretStyleProps() {
      return {
        left: this.caretPosition.left + 'px',
        top: this.caretPosition.top + 'px',
      };
    },
    elementPadding() {
      let padding = this.padding;
      if (this.caret) {
        padding += this.caretSize.height;
      }
      return padding;
    },
    // When padding prop is set, add padding around tooltip-content component
    paddingStyle() {
      switch (this.actualPosition) {
        case 'top':
          return {paddingBottom: this.elementPadding + 'px'};
        case 'bottom':
          return {paddingTop: this.elementPadding + 'px'};
        case 'left':
          return {paddingRight: this.elementPadding + 'px'};
        case 'right':
          return {paddingLeft: this.elementPadding + 'px'};
        case 'default':
          return {};
      }
    },
    spaceAbove() {
      return this.targetBoundingRect.top - this.positionedBoundingRect.height;
    },
    spaceBelow() {
      return (
        window.innerHeight -
        this.targetBoundingRect.top -
        this.targetBoundingRect.height -
        this.positionedBoundingRect.height
      );
    },
    spaceLeft() {
      return this.targetBoundingRect.left - this.positionedBoundingRect.width;
    },
    spaceRight() {
      return (
        window.innerWidth -
        this.targetBoundingRect.left -
        this.targetBoundingRect.width -
        this.positionedBoundingRect.width
      );
    },
    hasSpaceAbove() {
      return this.spaceAbove >= 0;
    },
    hasSpaceBelow() {
      return this.spaceBelow >= 0;
    },
    hasSpaceLeft() {
      return this.spaceLeft >= 0;
    },
    hasSpaceRight() {
      return this.spaceRight >= 0;
    },
  },

  methods: {
    /**
     * Set the position of the element relative to the target element based on position and alignment props.
     * e.g. if 'position' is 'left' the element will sit on the left side of the target and if 'alignment' is 'top' the element will be aligned with the top of the target
     * @param {String} position the desired position of the element being positioned (either: 'top', 'bottom', 'left', 'right', 'auto-horizontal', 'auto-vertical' or 'auto')
     * @param {String} alignment the desired alignment of the element being positioned (either: 'top', 'bottom', 'left', 'right' or 'auto')
     */
    setPosition(position, alignment) {
      // Set position of element if target and element exist
      if (this.elementSelector && document.querySelector(this.elementSelector) && document.querySelector(this.target)) {
        this.positionedBoundingRect = document.querySelector(this.elementSelector).getBoundingClientRect();
        this.targetBoundingRect = document.querySelector(this.target).getBoundingClientRect();

        // The actual position will be 'left', 'right', 'top' or 'bottom', this is used in the getAlignment method
        this.actualPosition = position;

        // Set the caretPosition to the center of the positioned element by default
        this.caretPosition = {
          top: this.positionedBoundingRect.height / 2 - this.caretSize.width / 2,
          left: this.positionedBoundingRect.width / 2 - this.caretSize.width / 2,
        };

        switch (position) {
          case 'top':
            this.newPosition = {
              top: this.targetBoundingRect.top - this.positionedBoundingRect.height,
              left: this.getAlignment(alignment).left,
            };
            this.caretPosition.top = this.positionedBoundingRect.height - this.borderSize - this.elementPadding;
            break;
          case 'bottom':
            this.newPosition = {
              top: this.targetBoundingRect.top + this.targetBoundingRect.height,
              left: this.getAlignment(alignment).left,
            };
            this.caretPosition.top = 0 - this.caretSize.height + this.borderSize + this.elementPadding;
            break;
          case 'left':
            this.newPosition = {
              top: this.getAlignment(alignment).top,
              left: this.targetBoundingRect.left - this.positionedBoundingRect.width,
            };
            this.caretPosition.left = this.positionedBoundingRect.width - this.borderSize - this.elementPadding;
            break;
          case 'right':
            this.newPosition = {
              top: this.getAlignment(alignment).top,
              left: this.targetBoundingRect.left + this.targetBoundingRect.width,
            };
            this.caretPosition.left = 0 - this.caretSize.height + this.borderSize + this.elementPadding;
            break;
          case 'auto-vertical':
            if (this.spaceAbove >= this.spaceBelow) {
              this.setPosition('top', alignment);
            } else {
              this.setPosition('bottom', alignment);
            }
            break;
          case 'auto-horizontal':
            if (this.spaceLeft >= this.spaceRight) {
              this.setPosition('left', alignment);
            } else {
              this.setPosition('right', alignment);
            }
            break;
          default: // defaults to 'auto'
            // Determine whether there is more space vertically or horizontally for the positioned element to fit
            if (this.hasSpaceAbove || this.hasSpaceBelow || this.hasSpaceLeft || this.hasSpaceRight) {
              if (this.spaceAbove + this.spaceBelow >= this.spaceLeft + this.spaceRight) {
                this.setPosition('auto-vertical', alignment);
              } else {
                this.setPosition('auto-horizontal', alignment);
              }
            } else {
              // position center of window
              this.newPosition = {
                top: window.innerHeight / 2 - this.positionedBoundingRect.height / 2,
                left: window.innerWidth / 2 - this.positionedBoundingRect.width / 2,
              };
              // Caret is centered by default and will be hidden behind the positioned element
            }
            break;
        }
        this.setOverflowStyle();
      }
    },

    /**
     * If tooltip overflows in the y direction, reduce height of tooltip content and scroll to see overflowed content
     */
    setOverflowStyle() {
      if (this.tooltipContentHovered || this.visible) {
        if (this.positionedBoundingRect.top + this.positionedBoundingRect.height > window.innerHeight) {
          this.tooltipContentStyle = {
            height: window.innerHeight - this.positionedBoundingRect.top - this.elementPadding - 24 + 'px', // 24 is the padding from .tooltip-content
            overflowY: 'scroll',
          };
        } else if (this.positionedBoundingRect.top < 0) {
          this.tooltipContentStyle = {
            height: this.targetBoundingRect.top - this.elementPadding - 24 + 'px',
            overflowY: 'scroll',
          };
        }
      } else {
        this.tooltipContentStyle = {
          height: '100%',
        };
      }
    },

    /**
     * Returns an object containing left and top properties that determine the alignment of the element.
     * Function also aligns the position of the caret based on the alignment of the main element
     * @param {String} alignment the desired alignment of the positioned element (either: 'top', 'bottom', 'left', 'right' or 'auto')
     * @return {Object} containing top and left properties for positioning the element
     */
    getAlignment(alignment) {
      // Center by default
      const align = {
        left: this.targetBoundingRect.left + this.targetBoundingRect.width / 2 - this.positionedBoundingRect.width / 2,
        top: this.targetBoundingRect.top + this.targetBoundingRect.height / 2 - this.positionedBoundingRect.height / 2,
      };
      switch (alignment) {
        case 'left':
          align.left = this.targetBoundingRect.left;
          this.caretPosition.left = this.caretSize.width;
          break;
        case 'right':
          align.left =
            this.targetBoundingRect.left + (this.targetBoundingRect.width - this.positionedBoundingRect.width);
          this.caretPosition.left = this.positionedBoundingRect.width - this.caretSize.width * 2;
          break;
        case 'top':
          align.top = this.targetBoundingRect.top;
          this.caretPosition.top = this.caretSize.width;
          break;
        case 'bottom':
          align.top =
            this.targetBoundingRect.top + (this.targetBoundingRect.height - this.positionedBoundingRect.height);
          this.caretPosition.top = this.positionedBoundingRect.height - this.caretSize.width * 2;
          break;
        case 'auto':
          // Depending on position (vertical or horizonal), determine whether the target element is on the left or top half of the page and align accordingly
          if (this.actualPosition == 'top' || this.actualPosition == 'bottom') {
            return this.targetBoundingRect.left + this.targetBoundingRect.width / 2 >= window.innerWidth / 2
              ? this.getAlignment('right')
              : this.getAlignment('left');
          } else {
            return this.targetBoundingRect.top + this.targetBoundingRect.height / 2 >= window.innerHeight / 2
              ? this.getAlignment('bottom')
              : this.getAlignment('top');
          }
        default: // Defaults to center
          // if element can fit around target
          if (this.hasSpaceAbove || this.hasSpaceBelow || this.hasSpaceLeft || this.hasSpaceRight) {
            // Check if element is within the window horizontally
            if (
              this.targetBoundingRect.left + this.targetBoundingRect.width / 2 - this.positionedBoundingRect.width / 2 <
              0
            )
              return this.getAlignment('left');
            if (
              this.targetBoundingRect.left + this.targetBoundingRect.width / 2 + this.positionedBoundingRect.width / 2 >
              window.innerWidth
            )
              return this.getAlignment('right');

            // Check if element is within the window vertically
            if (
              this.targetBoundingRect.top +
                this.targetBoundingRect.height / 2 -
                this.positionedBoundingRect.height / 2 <
              0
            )
              return this.getAlignment('top');
            if (
              this.targetBoundingRect.top +
                this.targetBoundingRect.height / 2 +
                this.positionedBoundingRect.height / 2 >
              window.innerHeight
            )
              return this.getAlignment('bottom');
          }
          // If all checks are ok, element is aligned center by default
          break;
      }
      return align;
    },

    // Reposition element when window is scrolled (as position of floating element wrapper is fixed)
    handleScroll() {
      this.setPosition(this.position, this.align);
    },

    // Function that is called by resize mixin when window is resized
    resize: function () {
      this.setPosition(this.position, this.align);
    },
  },

  created() {
    this.id = uniqId('tooltip-');
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },

  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
