import {closestOrSelf, minMax, validateCurrency, formatToLocaleNumber, copyStringToClipboard} from '@veasel/core/tools';

const MOUSEOUT_MARGIN = 5;

export default {
  data: function () {
    return {
      cellSelection: false,
      startCellSelected: false,
      endCellSelected: false,
      selectionTotal: false,
      lastSelection: {fromX: false, toX: false, fromY: false, toY: false},
    };
  },

  methods: {
    // start mouse selection
    selectionMouseStart: function (event) {
      // If the element has a custom event attached, lets emit that
      const elementToEmitEvent = closestOrSelf(event.target, '[data-event]');
      if (elementToEmitEvent !== null) {
        const emitEvent = elementToEmitEvent.getAttribute('data-event');
        const emitEventParams = elementToEmitEvent.getAttribute('data-event-params');
        if (emitEventParams !== null) {
          this.$emit(emitEvent, emitEventParams);
        } else {
          this.$emit(emitEvent);
        }
      }
      const target = closestOrSelf(event.target, '[cell-index]');
      if (this.opts.editable) {
        const isEditing = closestOrSelf(event.target, '.edit-cell');
        if (isEditing) {
          const colName = target.getAttribute('col-name');
          const y = target.getAttribute('cell-index').split('_')[0];
          this.editCell(
            this.visibleRows[y],
            this.visibleCols.find((col) => colName === col.data),
            parseInt(y) + parseInt(this.opts.pagination.offset)
          );
          return;
        }
      }
      this.startCellSelected = target;
      this.endCellSelected = target;
      if (this.opts.selection.multiple) {
        this.listenForSelectionEnd();
        this.listenForMouseSelection();
        this.listenForKeyboardSelection();
      }
      this.selectCells();
    },
    // add event listeners for mouse selection
    listenForMouseSelection: function () {
      document.addEventListener('mousemove', this.mousemoveHandler);
      document.addEventListener('mouseup', this.mouseupHandler);
    },
    // handle mouse move over cells
    mousemoveHandler: function (event) {
      const target = closestOrSelf(event.target, '[cell-index]');
      if (!target || target == this.endCellSelected || target.closest('.table-main-container') !== this.$el) {
        // should it scroll
        const elRect = this.$el.getBoundingClientRect();
        const tbodyRect = this.$el.querySelector('.table-content tbody').getBoundingClientRect();
        this.horizontalScroll(event.clientX);
        this.verticalScroll(event.clientY);

        const x = parseInt(
          minMax(
            elRect.left + MOUSEOUT_MARGIN + this.checkboxColumnWidth,
            event.clientX,
            elRect.right - MOUSEOUT_MARGIN
          )
        );
        const y = parseInt(minMax(tbodyRect.top + MOUSEOUT_MARGIN, event.clientY, tbodyRect.bottom - MOUSEOUT_MARGIN));

        this.throttleMmousemoveOutHandler(x, y);
        return;
      }
      this.endCellSelected = target;
      this.selectCells();
    },
    // handle mouse move outside table
    mousemoveOutHandler: function (x, y) {
      const element = document.elementFromPoint(x, y);
      const target = element && closestOrSelf(element, '[cell-index]');
      if (target) {
        this.endCellSelected = target;
        this.selectCells();
      }
    },
    // handle end of selection
    mouseupHandler: function () {
      document.removeEventListener('mousemove', this.mousemoveHandler);
      document.removeEventListener('mouseup', this.mouseupHandler);
      this.selectCells();
    },
    // add event listeners for keyboard selection
    listenForKeyboardSelection: function () {
      document.addEventListener('keydown', this.keydownHandler);
    },
    // handle keyboard arrow selection
    keydownHandler: function (event) {
      const toArray = this.endCellSelected.getAttribute('cell-index').split('_');
      const maxX = this.visibleCols.length - 1;
      const maxY = this.visibleRows.length - 1;
      let toX = parseInt(toArray[1]);
      let toY = parseInt(toArray[0]);
      switch (event.key) {
        case 'ArrowUp':
          event.preventDefault();
          toY = minMax(0, toY - 1, maxY);
          break;
        case 'ArrowDown':
          event.preventDefault();
          toY = minMax(0, toY + 1, maxY);
          break;
        case 'ArrowLeft':
          event.preventDefault();
          toX = minMax(0, toX - 1, maxX);
          break;
        case 'ArrowRight':
          event.preventDefault();
          toX = minMax(0, toX + 1, maxX);
          break;
      }
      const el = this.$el.querySelector('[cell-index="' + toY + '_' + toX + '"]');
      if (!event.shiftKey && !event.ctrlKey) {
        this.startCellSelected = el;
      }
      this.endCellSelected = el;
      this.selectCells();
    },
    // add event listeners for selection end
    listenForSelectionEnd: function () {
      document.addEventListener('mousedown', this.releaseSelection);
    },
    // remove all selection listeners
    releaseSelection: function (event) {
      if (
        !event ||
        ((closestOrSelf(event.target, '.sticky-scroller') || closestOrSelf(event.target, '.table-content')) &&
          event.target.closest('.table-main-container') === this.$el)
      ) {
        return;
      }
      this.cellSelection = false;
      this.startCellSelected = false;
      this.endCellSelected = false;
      this.selectionTotal = false;
      this.lastSelection = {fromX: false, toX: false, fromY: false, toY: false};
      document.removeEventListener('mousemove', this.mousemoveHandler);
      document.removeEventListener('mousedown', this.releaseSelection);
      document.removeEventListener('mouseup', this.mouseupHandler);
      document.removeEventListener('keydown', this.keydownHandler);
    },
    // select the current cells
    selectCells: function () {
      let from = this.startCellSelected;
      let to = this.endCellSelected || this.startCellSelected;
      const fromArray = from.getAttribute('cell-index').split('_');
      const toArray = to.getAttribute('cell-index').split('_');
      const fromX = Math.min(fromArray[1], toArray[1]);
      const toX = Math.max(fromArray[1], toArray[1]);
      const fromY = Math.min(fromArray[0], toArray[0]);
      const toY = Math.max(fromArray[0], toArray[0]);

      this.scrollOnSelection(to);

      from = this.$el.querySelector('[cell-index="' + fromY + '_' + fromX + '"]');
      to = this.$el.querySelector('[cell-index="' + toY + '_' + toX + '"]');
      // display selector
      const selector = this.$el.querySelector('.selector');
      if (selector) {
        selector.style.marginTop = from.offsetTop + 'px';
        selector.style.marginLeft = from.offsetLeft + 'px';
        selector.style.height = to.offsetTop - from.offsetTop + to.offsetHeight + 'px';
        selector.style.width = to.offsetLeft - from.offsetLeft + to.offsetWidth + 'px';
      }

      if (
        fromX === this.lastSelection.fromX &&
        fromY == this.lastSelection.fromY &&
        toX === this.lastSelection.toX &&
        toY === this.lastSelection.toY
      ) {
        return;
      } else {
        this.lastSelection = {fromX: fromX, toX: toX, fromY: fromY, toY: toY};
        this.throttledCellUpdate(fromX, fromY, toX, toY);
      }
    },
    // position the total cell
    positionTotal: function () {
      const selector = this.$el.querySelector('.selector');
      const rect = selector.getBoundingClientRect();
      const total = this.$el.querySelector('.selector-total');
      const totalLabel = total.querySelector('label');
      const totalSpan = total.querySelector('span');
      total.style.top = rect.bottom + 'px';
      total.style.left = rect.left - (this.isUpgradedComponent ? 61 : 54) + 'px';
      total.style.height = this.opts.fixedRowHeight + 1 + 'px';
      total.style.width = selector.offsetWidth + 53 + 'px';
      totalSpan.style.width = selector.offsetWidth + 3 + 'px';
      totalSpan.style.paddingRight = '4px';
      totalLabel.style.width = '50px';
      document.addEventListener('scroll', this.hideTotal);
      this.scrollers.forEach((scroller) => {
        scroller.addEventListener('scroll', this.hideTotal);
      });
    },
    // hide the total cell
    hideTotal: function () {
      this.selectionTotal = false;
      document.removeEventListener('scroll', this.hideTotal);
      this.scrollers.forEach((scroller) => {
        scroller.removeEventListener('scroll', this.hideTotal);
      });
    },
    // update the cells
    cellsUpdated: function (fromX, fromY, toX, toY) {
      // get list of cells
      const cells = [];
      for (let y = fromY; y < toY + 1; y++) {
        for (let x = fromX; x < toX + 1; x++) {
          const el = this.$el.querySelector('[cell-index="' + y + '_' + x + '"]');
          if (!el) {
            this.releaseSelection();
            return;
          }
          cells.push({
            x: x,
            y: y,
            el: el,
            col: el.getAttribute('col-name'),
            value: el.innerText,
          });
        }
      }
      // calculate and display total
      let total = false;
      const currencyDict = {};
      if (fromX === toX && fromY !== toY) {
        total = 0;
        for (const c in cells) {
          currencyDict[cells[c].value.replace(/[\d\s,.-]/g, '')] = true;
          const value = Number(cells[c].value.replace(/[^\d.-]/g, ''));
          if (isNaN(value) || Object.keys(currencyDict).length > 1) {
            total = false;
            break;
          } else {
            total += value;
          }
        }
      }
      const currency = Object.keys(currencyDict)[0];
      this.selectionTotal =
        validateCurrency(currency) && total !== false
          ? formatToLocaleNumber(total, currency)
          : currency === ''
          ? total
          : false;

      if (this.selectionTotal) {
        this.$nextTick(() => {
          this.positionTotal();
        });
      }
      // emit cell selection
      this.cellSelection = cells;
      this.copySelection(cells);
      this.$emit(this.opts.selection.event, cells);
    },
    // copy selection to clipboard every time a cell is selected
    copySelection: function (cells) {
      let lastCellY = -1;
      let content = '';
      for (const i in cells) {
        if (cells[i].y !== lastCellY) {
          content += '\n' + cells[i].value;
          lastCellY = cells[i].y;
        } else {
          content += '\t' + cells[i].value;
        }
      }
      copyStringToClipboard(content);
    },
  },
};
