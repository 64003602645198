<template>
  <div class="s-pie-chart">
    <div :id="uniqueID" ref="anchor" class="chart-inner" :style="style">{{ title }}</div>
  </div>
</template>

<script>
  import * as dc from '@suadelabs/dc';
  import * as d3 from 'd3';
  import {uniqId} from '@veasel/core/tools';
  import crossfilter from 'crossfilter2';

  import {id, chartResize} from '@veasel/core/mixins';

  const DEFAULT_OPTIONS = {
    title: '',
    width: 400,
    height: 300,
    valueKey: 'value',
    groupKey: 'type',
    radius: 80,
    innerRadius: 30,
    transitionDuration: 500,
    legend: {
      x: 5,
      y: 5,
      itemHeight: 13,
      gap: 5,
    },
    colours: ['#1f78b480', '#e31a1c80', '#33a02c80', '#ff7f0080', '#6a3d9a80', '#6eb5ff'],
    onClick: function () {},
  };

  export default {
    name: 's-pie-chart',

    description: 'A classic 1D chart represented as a circle or a donut (based on d3.js and dc).',

    props: {
      values: {
        description: 'List of values to display in the chart',
        type: Array,
        required: true,
      },
      options: {
        description: 'The options object, to specify advanced features and chart style.',
        type: Object,
        validator: (o) =>
          o.legend === null || o.legend === false || (o.legend?.x, o.legend?.y, o.legend?.itemHeight, o.legend?.gap),
        default: () => ({}),
      },
      dataFormat: {
        description: 'The logic used to parse data.',
        type: String,
        values: ['raw', 'grouped-array', 'key-value'],
        validator: (s) => ['raw', 'grouped-array', 'key-value'].includes(s),
        required: true,
      },
      orderByValue: {
        description: 'Orders by the value of the graph items',
        type: Boolean,
        default: false,
      },
    },

    mixins: [chartResize, id],

    data: function () {
      return {
        uniqueID: '',
        resizeObserver: null,
      };
    },

    computed: {
      style() {
        return {
          width: this.optOrDefault('width') + 'px',
          height: this.optOrDefault('height') + 'px',
        };
      },
      title() {
        return this.optOrDefault('title');
      },
    },

    methods: {
      // Get a value from the options property, or from defaults if not present
      optOrDefault(key) {
        return this.options[key] !== undefined ? this.options[key] : DEFAULT_OPTIONS[key];
      },

      // Initialise the crossfilters containing the data driving the chart
      createCrossFilter() {
        if (this.dataFormat === 'key-value') {
          return crossfilter(
            Object.keys(this.values[0]).map((key) => {
              const ret = {};

              ret[this.optOrDefault('groupKey')] = key;
              ret[this.optOrDefault('valueKey')] = this.values[0][key];

              return ret;
            })
          );
        } else {
          return crossfilter(this.values);
        }
      },

      // Create & render the chart
      buildChart() {
        // Needs to be mounted this way, not by ID, in order to be unit-test-able
        this.chart = dc.pieChart(this.$refs.anchor);

        const ndx = this.createCrossFilter();
        const typeDimension = ndx.dimension((d) => d[this.optOrDefault('groupKey')]);

        const group =
          this.dataFormat === 'raw'
            ? typeDimension.group().reduceCount() // If the data is raw, count the number of entries
            : typeDimension.group().reduceSum((d) => d[this.optOrDefault('valueKey')]); // If the data is an array of already calculated totals, 'sum' the single available value for each key

        this.chart
          .width(this.optOrDefault('width') || null)
          .height(this.optOrDefault('height') || null)
          .radius(this.optOrDefault('radius'))
          .innerRadius(this.optOrDefault('innerRadius'))
          .dimension(typeDimension)
          .group(group)
          .transitionDuration(this.optOrDefault('transitionDuration'))
          .ordinalColors(this.optOrDefault('colours'));

        // Inspired by https://stackoverflow.com/questions/64397341/how-to-sort-a-dc-js-pie-chart-by-its-values-instead-of-by-its-keys
        if (this.orderByValue) {
          this.chart.ordering((key) => -key.value.freq);
        }

        if (this.optOrDefault('legend') && this.optOrDefault('legend') !== null) {
          this.chart.legend(
            dc
              .legend()
              .x(this.optOrDefault('legend').x)
              .y(this.optOrDefault('legend').y)
              .itemHeight(this.optOrDefault('legend').itemHeight)
              .gap(this.optOrDefault('legend').gap)
          );
        }

        this.chart.onClick = this.optOrDefault('onClick');

        // If there is a click or hover handler
        if (this.options.onClick || this.options.onHover) {
          this.chart.on('pretransition', (chart) => {
            // Add the pointer class (cursor)
            chart.selectAll('g.pie-slice').classed('pointer', true);

            // If there are hover handlers
            if (this.options.onHover && this.options.onHover.enter && this.options.onHover.leave) {
              const h = this.options.onHover;

              // Add hover handler to slices
              chart
                .selectAll('g.pie-slice')
                .on('mouseover', function (item) {
                  h.enter(item, this);
                }) // Use non-arrow function to pass slice element as this
                .on('mouseout', function (item) {
                  h.leave(item, this);
                });
            }
          });
        }

        this.chart.render();
      },

      redrawDueToResize() {
        this.buildChart();
      },
    },
    created() {
      // Dc uses d3-schale-chromatic colour schemas from v3 and up, a list can be found here https://github.com/d3/d3-scale-chromatic
      dc.config.defaultColors(d3.schemeSet3);
      if (typeof this.id === 'undefined') {
        this.uniqueID = uniqId('chart_').replace('.', '');
      } else {
        this.uniqueID = this.id;
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.buildChart();
      });
      if (window.ResizeObserver) {
        this.resizeObserver = new window.ResizeObserver(() => {
          this.redrawDueToResize();
        });
        this.resizeObserver.observe(this.$el);
      }
    },

    watch: {
      values() {
        this.buildChart();
      },
    },

    beforeUnmount() {
      this.resizeObserver.unobserve(this.$el);
      // Clean up the chart before the component is destroyed
      if (this.chart) {
        dc.deregisterChart(this.chart);
      }
    },
  };
</script>

<style lang="scss" scoped>
  .s-pie-chart {
    width: 100%;
    height: 100%;
    padding: 10px;
    margin: auto;
  }

  .chart-inner {
    width: 100%;
    height: 100%;
  }
</style>

<style lang="scss">
  // Scoped CSS cannot effect chart SVG items, so wrap in the container class instead
  .s-pie-chart {
    .dc-chart .pie-slice {
      fill: black;
      font-size: 12px;

      &.pointer {
        cursor: pointer;
      }
    }

    .dc-chart .pie-slice :hover {
      fill-opacity: 0.8;
    }

    text {
      user-select: none;
      pointer-events: none;
    }
  }
</style>
