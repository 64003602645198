<template>
  <s-input-wrapper v-bind="$props">
    <input
      :id="id || $_name"
      ref="colorpicker"
      v-model="$_value"
      type="text"
      :name="name"
      :placeholder="_placeholder"
      :pattern="format"
      :disabled="disabled"
      class="s-input-group-field"
      @click="showPicker"
    />

    <div
      class="color-preview"
      :style="{'background-color': pickerColor.hex ? pickerColor.hex : '#000000'}"
      @click="togglePicker"
    ></div>
    <color-picker v-if="displayPicker" v-model="pickerColor" />
  </s-input-wrapper>
</template>

<script>
  import SInputWrapper from '@veasel/inputs/input-wrapper';
  import {
    syncValue,
    onChange,
    onClick,
    onBlur,
    onFocus,
    onKeyPressed,
    onPaste,
    id,
    name,
    placeholder,
    isDisabled,
    isRequired,
    pattern,
    maxLength,
    helperMessages,
  } from '@veasel/core/mixins';
  import chrome from './chrome.vue';

  // inspired by https://codepen.io/Brownsugar/pen/NaGPKy
  export default {
    name: 's-input-color',
    description: 'A user input for choosing a color (based on vue-color).',
    components: {SInputWrapper, 'color-picker': chrome},
    mixins: [
      syncValue(String),
      onChange(),
      onClick(),
      onBlur(),
      onFocus(),
      onKeyPressed(),
      onPaste(),
      id,
      name,
      placeholder,
      isDisabled,
      isRequired,
      pattern,
      maxLength,
      helperMessages,
    ],
    props: {
      format: {
        description: 'The color formatting.',
        type: String,
        values: ['hex', 'rgba'],
        validator: (val) => ['hex', 'rgba'].includes(val),
        default: 'hex',
      },
      overflow: {
        description: 'Indicates whether the input modal should escape from scrollable overflow containers',
        type: String,
        values: ['auto', 'enabled', 'disabled'],
        validator: (v) => ['auto', 'enabled', 'disabled'].includes(v),
        default: 'auto',
      },
    },
    computed: {
      pickerColor: {
        get() {
          let pickerColor = {hex: null};

          if (this.$_value != undefined) {
            if (this.$_value.slice(0, 1) == '#') {
              pickerColor = {hex: this.$_value};
            } else if (this.$_value.slice(0, 4) == 'rgba') {
              const rgba = this.$_value.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
              const hex =
                '#' +
                ((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2]))
                  .toString(16)
                  .slice(1);
              pickerColor = {hex: hex, a: rgba[3]};
            }
          }

          return pickerColor;
        },
        set(newValue) {
          this.updateFromPicker(newValue);
        },
      },
    },
    watch: {
      value: function (value) {
        if (value === undefined) {
          this.$el.querySelector('.color-preview').style.backgroundColor = 'transparent';
        }
      },
    },
    data: () => ({
      displayPicker: false,
      inputType: 'color',
    }),
    methods: {
      showPicker() {
        document.addEventListener('click', this.defocusEvent);
        document.addEventListener('scroll', this.defocusEvent, true);
        window.addEventListener('resize', this.defocusEvent);
        this.displayPicker = true;
      },
      hidePicker() {
        document.removeEventListener('click', this.defocusEvent);
        document.removeEventListener('scroll', this.defocusEvent, true);
        window.removeEventListener('resize', this.defocusEvent);
        this.displayPicker = false;
      },
      togglePicker() {
        this.displayPicker ? this.hidePicker() : this.showPicker();
      },
      defocusEvent(e) {
        const el = this.$refs.colorpicker;
        const target = e.target;
        if (el !== target && (target instanceof Window || !el.contains(target))) {
          this.hidePicker();
        }
      },
      updateFromPicker(color) {
        if (this.format == 'rgba') {
          this.$_value = `rgba(${color.rgba.r},${color.rgba.g},${color.rgba.b},${color.rgba.a})`;
        } else {
          this.$_value = color.a == 1 ? color.hex : color.hex8;
        }

        this.$_onChange();
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@veasel/core/sass/conf';

  .color-preview {
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    position: absolute;
    border-radius: 0 $button-radius $button-radius 0;
    margin: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }

  .small {
    .color-preview {
      cursor: pointer;
      height: 1.5rem;
      width: 1.5rem;
      position: absolute;
      border-radius: $button-radius;
      margin: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
    }
  }

  // COLOR
  .color-input {
    ::v-deep(.colorpicker) {
      .dropdown-menu::before,
      .dropdown-menu::after {
        display: none;
      }

      .dropdown-menu {
        border-radius: 0;
        margin-top: -3px;
        background: $input-background-color;
        padding: 4px;
        border: $input-border;
        position: absolute;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.075);
        z-index: 70;
        min-width: 120px;
      }

      &.colorpicker-position-bottom {
        top: 42px;
      }

      &.colorpicker-position-top {
        top: -123px !important;
      }
    }

    ::v-deep(.dropdown-menu) .close.close-colorpicker {
      display: none;
    }

    ::v-deep(input[colorpicker]),
    ::v-deep(.sl-input-group-label) {
      height: $row-medium-height - 8px;
      width: calc(100% - 18px);
      padding-top: 2px;
      padding-bottom: 0;
    }

    ::v-deep(.alpha) .dropdown-menu {
      min-width: 140px;
    }
  }

  .sl-input.small {
    .color-input {
      ::v-deep(.colorpicker) {
        &.colorpicker-position-bottom {
          top: 42px;
        }

        &.colorpicker-position-top {
          top: -128px;
        }
      }

      .color-preview {
        width: 18px;
        height: 100%;
        top: 1px;
      }

      ::v-deep(.dropdown-menu) .close.close-colorpicker {
        display: none;
      }

      ::v-deep(input[colorpicker]),
      ::v-deep(.sl-input-group-label) {
        height: $row-small-height - 9px;
      }
    }

    ::v-deep(.vc-chrome) {
      top: 19px;
      width: 187px;
    }
  }

  ::v-deep(.vc-chrome) {
    position: absolute;
    top: 37px;
    //right: 0;
    z-index: 9;
    border-radius: 2px;
    border: $input-border;
    box-shadow: none;
    box-sizing: initial;
    width: 225px;
    font-family: Arial, Helvetica, sans-serif;
    background-color: #fff;
  }

  ::v-deep(.current-color) {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-color: #000;
  }

  ::v-deep(.footer) {
    margin-top: 20px;
    text-align: center;
  }
</style>
