<template>
  <div class="s-page-header" :class="{'sticky-header': pageHeaderOptions.sticky}">
    <!-- BREADCRUMBS + SLOT:CONTENT-RIGHT -->
    <div class="header-main">
      <div class="breadcrumbs" data-cy="breadcrumbs">
        <!-- ICON -->
        <div v-if="pageHeaderOptions.icon" class="icon-box">
          <s-icon :type="pageHeaderOptions.icon" color="theme-main-dark" size="20"></s-icon>
        </div>
        <!-- LABELS -->
        <div v-for="(breadcrumb, index) in pageHeaderOptions.breadcrumbs" :key="breadcrumb.name" class="breadcrumb">
          <span
            :class="breadcrumb.to ? 'link' : 'label'"
            data-cy="breadcrumb-label"
            @click="visitBreadcrumbTo(breadcrumb)"
          >
            {{ breadcrumb.name }}
          </span>
          <s-icon
            v-if="index !== pageHeaderOptions.breadcrumbs.length - 1"
            type="arrow-drop-right"
            class="m-x-s"
            size="12"
          />
        </div>
      </div>
      <div>
        <slot name="content-right" />
      </div>
    </div>
    <!-- SLOT:CONTENT-BOTTOM -->
    <slot name="content-bottom" />
  </div>
</template>

<script>
  const DEFAULT_OPTIONS = {
    sticky: true,
    background: 'var(--background-secondary)',
    icon: undefined,
    breadcrumbs: [],
  };
  import icon from '@veasel/base/icon';

  export default {
    name: 's-page-header',
    components: {
      's-icon': icon,
    },
    props: {
      options: {
        description: 'Page Header options e.g. sticky, icon, breadcrumbs',
        type: Object,
        default: () => ({}),
      },
    },

    computed: {
      pageHeaderOptions() {
        const options = {...DEFAULT_OPTIONS, ...this.options};
        options.breadcrumbs = this.replaceBreadcrumbPlaceholders(options.breadcrumbs);
        return options;
      },
    },

    created() {
      DEFAULT_OPTIONS.breadcrumbs = this.$route.meta.breadcrumb || [];
      DEFAULT_OPTIONS.icon = this.$route.meta.icon || undefined;
    },

    methods: {
      visitBreadcrumbTo(breadcrumb) {
        this.$router.push({path: breadcrumb.to.path || breadcrumb.to, query: breadcrumb.to.query});
      },

      replaceBreadcrumbPlaceholders(breadcrumbs) {
        breadcrumbs.forEach((breadcrumb) => {
          if (breadcrumb.name.includes(':')) {
            const params = this.$route.params;
            const nameToUse = breadcrumb.customName || breadcrumb.name;

            const replacementName = params[nameToUse.substring(1)] || breadcrumb.name;

            // Need to keep a clean version of the name as a key for the query
            const cleanNameQueryKey = breadcrumb.name.substring(1);

            // Replaces breadcrumb name placeholder
            breadcrumb.name = replacementName;

            // Replaces breadcrumb path placeholder
            if (typeof breadcrumb.to === 'object') {
              breadcrumb.to.query[cleanNameQueryKey] = replacementName;
            }

            // Replaces multiple :id instances in a path string
            if (typeof breadcrumb.to === 'string') {
              breadcrumb.to = breadcrumb.to?.slice(0, breadcrumb.to?.indexOf(':')) + replacementName;
            }
          }
        });
        return breadcrumbs;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@veasel/core';

  .s-page-header {
    background: v-bind('pageHeaderOptions.background');
    padding: get-spacing('m') get-spacing('s');
    margin: 0 get-spacing('ns');
    top: 0;

    .header-main {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .breadcrumbs {
        display: flex;
        align-items: center;
        text-transform: capitalize;

        .breadcrumb {
          @include breadcrumb-active;

          display: flex;
          font-size: 24px;

          .label {
            color: var(--main-dark);
            pointer-events: none;
            cursor: default;
          }

          .link {
            user-select: none;
            cursor: pointer;
          }
        }

        .icon-box {
          background-color: var(--background-main);
          width: rem(32px);
          height: rem(32px);
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0 3px 6px #00000029;
          border-radius: $border-radius * 2;
          margin-right: get-spacing('m');
        }

        ::v-deep(.s-icon, .s-icon) {
          display: flex;
          border-radius: inherit;
        }
      }
    }
  }

  .sticky-header {
    position: sticky;
    z-index: 41;
  }
</style>
