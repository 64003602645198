// create sync 'value' prop and '$_value' computed
const optionalSyncValue = function (propTypes = null, defaultValue = null, name = 'modelValue') {
  const internalValue = '$_' + name;
  return {
    // value type can be defined in component
    props: {
      [name]: {
        description: 'The input value',
        type: propTypes,
        default: undefined,
      },
    },
    emits: ['update:' + name],
    data: function () {
      // Functions called to format value (truncate, trim...)
      return {
        [internalValue + '_formatersValue']: [],
        [internalValue + '_unformattersValue']: [],
        [internalValue + '_internalValue']: defaultValue,
      };
    },
    computed: {
      [internalValue]: {
        get() {
          if (this[name] !== undefined) {
            return this[internalValue + '_formatValue'](this[name]);
          }
          return this[internalValue + '_formatValue'](this.$data[internalValue + '_internalValue']);
        },
        set(val) {
          if (this[name] !== undefined) {
            this.$emit('update:' + name, this[internalValue + '_unformatValue'](val));
          } else {
            this.$data[internalValue + '_internalValue'] = this[internalValue + '_unformatValue'](val);
          }
        },
      },
    },
    watch: {
      [internalValue]: {
        immediate: true,
        deep: true,
        handler: function (newVal) {
          // if there is isRequired mixin
          if (this.required !== undefined && typeof this.$_requireValidate === 'function') {
            this.$_requireValidate(newVal);
          }
          // if there is pattern mixin
          if (this.pattern !== undefined) {
            this.$_patternValidate(newVal);
          }
        },
      },
    },
    methods: {
      [internalValue + '_formatValue']: function (val) {
        let formatterVal = val;

        for (let i = 0; i < this.$data[internalValue + '_formatersValue'].length; i++) {
          const formatter = this.$data[internalValue + '_formatersValue'][i];
          formatterVal = formatter(formatterVal);
        }
        return formatterVal;
      },
      [internalValue + '_unformatValue']: function (val) {
        let unformatedVal = val;
        for (let i = 0; i < this.$data[internalValue + '_unformattersValue'].length; i++) {
          const unformater = this.$data[internalValue + '_unformattersValue'][i];
          unformatedVal = unformater(unformatedVal);
        }

        return unformatedVal;
      },
    },
  };
};

export default optionalSyncValue;
