// Permissions configuration contains definitions for complex / multi-value permissions
import PermConfig from '@/config/permissions';

// Static function for comparing a key to the permissions config
export const CheckPermissionKey = function (list, type, key, def) {
  // If the list is not populated, return the default
  if (!list) {
    return def;
  }

  // If we are just checking a single permission or feature, do a simple includes check
  if (type === 'single') {
    return list.includes(key);
  }

  // If the type is invalid, or the key is not present, return default value
  if (!PermConfig[type] || !PermConfig[type][key]) {
    return def;
  }

  // 1st level array -> OR
  return PermConfig[type][key].some((scopes) => {
    if (scopes.find((scope) => scope.includes('NOT_ONLY_'))) {
      // 2nd level array contains "NOT_ONLY_" -> NOT ONLY
      return !(
        scopes
          .map((s) => s.replace('NOT_ONLY_', ''))
          .sort()
          .join(';') === list.sort().join(';')
      );
    } else if (scopes.find((scope) => scope.includes('ONLY_'))) {
      // 2nd level array contains "ONLY_" -> ONLY
      return (
        scopes
          .map((s) => s.replace('ONLY_', ''))
          .sort()
          .join(';') === list.sort().join(';')
      );
    } else {
      // 2nd level array -> AND
      return scopes.every((scope) => {
        // item contains "!" -> NOT
        const not = scope.includes('NOT_');
        const hasScope = list.includes(scope.replace(/!/g, ''));
        return not ? !hasScope : hasScope;
      });
    }
  });
};
