<template>
  <div>
    <pre>
      <code v-if="show" ref="container" :class="['hljs', lang]" :data-interactive-list="orderedInteractiveList" @click.capture="interactiveListClick">{{ code }}</code>
    </pre>
  </div>
</template>

<script>
  import hljs from 'highlight.js';

  import {isEmpty} from '@veasel/core/tools';

  export default {
    name: 's-code',
    description: 'A basic code display block (based on highlight.js).',
    props: {
      lang: {
        description: 'The language for syntax highlighting.',
        type: String,
        required: true,
      },
      code: {
        description: 'The code to indent and highlight.',
        type: [String, Object, Array, Function],
        required: true,
      },
      interactiveList: {
        /**
         * Object example
         * {
         *  text: String, // The text to target
         *  class: String, // The class to assign the target
         *  prefix: String // String to target that prefixes the target, but is not part of the target itself.
         *  suffix: String // String to target that suffixes the target, but is not part of the target itself. Can be regex
         * }
         */
        description: 'An array of objects to highlight differently and to emit a click when it is clicked',
        type: Array,
        default: () => [],
      },
    },
    emits: {interactiveListClick: null},
    data: () => {
      return {
        show: false,
      };
    },
    created() {
      // We only want to add this plugin once, so we check if our marker has been set yet or not
      if (hljs.hasInteractiveListPlugin !== true) {
        hljs.addPlugin({
          'after:highlightBlock': ({block, result}) => {
            JSON.parse(block.getAttribute('data-interactive-list')).forEach((item) => {
              const prefix = isEmpty(item.prefix) ? '' : item.prefix;
              const suffix = isEmpty(item.suffix) ? '(.)' : '(' + item.suffix + ')';
              const regex = new RegExp(
                '(?<!class="hljs-interactive-element.+</)w*(?<!_)' +
                  prefix.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&') +
                  item.text +
                  suffix,
                'gm'
              );
              const html = `${prefix}<span class="hljs-interactive-element ${item.class}" data-text="${item.text}">${item.text}</span>$1`;
              result.value = result.value.replace(regex, html);
            });
          },
        });
        hljs.hasInteractiveListPlugin = true;
      }
    },
    methods: {
      interactiveListClick(evt) {
        if (evt.target.classList.contains('hljs-interactive-element')) {
          const text = evt.target.getAttribute('data-text');
          this.$emit('interactiveListClick', text);
        }
      },
      updateCodeBlock() {
        // Highlight JS fights with Vue's reactivity, so we have to force the component to be removed
        // and re-added if the code changes. First make v-if false, then back to true on the next tick.
        // Finally apply the highlight on the tick after that.
        this.show = false;

        this.$nextTick(() => {
          this.show = true;

          this.$nextTick(() => {
            setTimeout(() => {
              try {
                hljs.highlightElement(this.$refs.container);
              } catch (e) {
                console.warn('HighlightJS error: ' + e);
              }
            });
          });
        });
      },
    },
    computed: {
      orderedInteractiveList() {
        return JSON.stringify(this.interactiveList.sortBy('text', true));
      },
    },
    watch: {
      code: {
        immediate: true,
        handler() {
          this.updateCodeBlock();
        },
      },
      orderedInteractiveList: {
        deep: true,
        handler() {
          this.updateCodeBlock();
        },
      },
    },
  };
</script>

<style scoped lang="scss">
  pre {
    display: grid;
    margin: 0;
  }

  .hljs {
    text-align: left;
    display: block;
    overflow-x: auto;
    padding: 0.5em;
    line-height: 1.3em;
    color: #abb2bf;
    background: #282c34;
    border-radius: 5px;
    font-size: 0.825rem;
  }

  ::v-deep(.hljs-keyword),
  ::v-deep(.hljs-operator) {
    color: #f92672;
  }

  ::v-deep(.hljs-pattern-match) {
    color: #f92672;
  }

  ::v-deep(.hljs-pattern-match) .hljs-constructor {
    color: #61aeee;
  }

  ::v-deep(.hljs-function) {
    color: #61aeee;
  }

  ::v-deep(.hljs-function) .hljs-params {
    color: #a6e22e;
  }

  ::v-deep(.hljs-function) .hljs-params .hljs-typing {
    color: #fd971f;
  }

  ::v-deep(.hljs-module-access) .hljs-module {
    color: #7e57c2;
  }

  ::v-deep(.hljs-constructor) {
    color: #e2b93d;
  }

  ::v-deep(.hljs-constructor) .hljs-string {
    color: #9ccc65;
  }

  ::v-deep(.hljs-comment),
  ::v-deep(.hljs-quote) {
    color: #b18eb1;
    font-style: italic;
  }

  ::v-deep(.hljs-doctag),
  ::v-deep(.hljs-formula) {
    color: #c678dd;
  }

  ::v-deep(.hljs-section),
  ::v-deep(.hljs-name),
  ::v-deep(.hljs-selector-tag),
  ::v-deep(.hljs-deletion),
  ::v-deep(.hljs-subst) {
    color: #e06c75;
  }

  ::v-deep(.hljs-literal) {
    color: #56b6c2;
  }

  ::v-deep(.hljs-string),
  ::v-deep(.hljs-regexp),
  ::v-deep(.hljs-addition),
  ::v-deep(.hljs-attribute),
  ::v-deep(.hljs-meta-string) {
    color: #98c379;
  }

  ::v-deep(.hljs-built_in),
  ::v-deep(.hljs-class) .hljs-title {
    color: #e6c07b;
  }

  ::v-deep(.hljs-attr),
  ::v-deep(.hljs-variable),
  ::v-deep(.hljs-template-variable),
  ::v-deep(.hljs-type),
  ::v-deep(.hljs-selector-class),
  ::v-deep(.hljs-selector-attr),
  ::v-deep(.hljs-selector-pseudo),
  ::v-deep(.hljs-number) {
    color: #d19a66;
  }

  ::v-deep(.hljs-symbol),
  ::v-deep(.hljs-bullet),
  ::v-deep(.hljs-link),
  ::v-deep(.hljs-meta),
  ::v-deep(.hljs-selector-id),
  ::v-deep(.hljs-title) {
    color: #61aeee;
  }

  ::v-deep(.hljs-emphasis) {
    font-style: italic;
  }

  ::v-deep(.hljs-strong) {
    font-weight: bold;
  }

  ::v-deep(.hljs-link) {
    text-decoration: underline;
  }
</style>
