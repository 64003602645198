<template>
  <div>
    <div
      v-if="hasUserID"
      :id="uID"
      class="thumbnail user inline-block align-middle"
      :title="title"
      :style="`fill: ${defaultIDColor};`"
    >
      <img v-if="picture" :src="picture" :alt="username" />
      <s-icon v-else type="default-userpic" class="icon default-icon" size="22" color="unset" />
    </div>
    <div v-else-if="username && username.toLowerCase() === 'system'" class="thumbnail system" :title="title">
      <s-icon type="jobs" class="icon" color="theme-secondary" size="22" />
    </div>
    <div v-else class="thumbnail user">
      <s-icon type="default-userpic" class="icon" color="theme-secondary" size="22" />
    </div>
  </div>
</template>

<script>
  import {uniqId} from '@veasel/core/tools';

  // User pic colours based on https://xd.adobe.com/view/b3023994-631d-404d-b9ae-826e4a693aa0-e651/
  const userColors = [
    '#06121D',
    '#005EB4',
    '#10599D',
    '#0A22C1',
    '#4B10D8',
    '#8113D4',
    '#C20CB0',
    '#DB077F',
    '#AC0C11',
    '#F10A11',
    '#F1490A',
    '#B13000',
    '#874A05',
    '#C26D0B',
    '#AB8B0B',
    '#A4A400',
    '#437D02',
    '#0F9500',
    '#12B200',
    '#008F56',
    '#008F86',
    '#008FBC',
    '#0088E2',
    '#7B7B7B',
    '#506A3A',
    '#46A0C8',
    '#FFC655',
    '#F9AEAE',
    '#65FFF5',
    '#71FFC1',
  ];
  export default {
    name: 'UserThumbnail',
    props: {
      url: String,
      username: {
        type: String,
        default: '',
      },
      title: String,
      profileId: [Number, String],
    },
    data() {
      return {
        uID: '',
      };
    },
    created() {
      this.uID = uniqId('uid-', 0);
    },

    computed: {
      systemUserIcons() {
        return {
          system: 'actions',
        };
      },
      systemUserIconColors() {
        return {
          system: 'theme-secondary',
        };
      },
      hasUserID() {
        return typeof this.profileId === 'number';
      },
      picture() {
        return !this.url || this.url.includes('null') ? '' : this.url;
      },
      defaultIDColor() {
        let colorID = this.profileId;

        while (colorID > 30) {
          colorID = colorID - 30;
        }
        return userColors[colorID - 1];
      },
    },
  };
</script>

<style scoped lang="scss">
  $thumbnailSize: 1.5rem;

  .thumbnail {
    position: relative;
    display: inline-block;
    border-radius: 100px;
    width: $thumbnailSize;
    height: $thumbnailSize;
    background: var(--background-main);
    text-align: center;

    ::v-deep(.svg-icon) {
      margin-top: 1px;
    }

    &.user {
      box-shadow: 0 3px 6px #00000029;

      ::v-deep(.svg-icon) {
        border-radius: 100px;
        box-sizing: border-box;
      }
    }

    &.system {
      line-height: 22px;
    }

    img {
      pointer-events: none;
      height: $thumbnailSize;
      width: $thumbnailSize;
      border-radius: $thumbnailSize;
      background-repeat: no-repeat;
      background-position: center;
      overflow: hidden;
      border-collapse: collapse;
      display: inline-block;
    }

    .default-icon ::v-deep(.s-icon-default-userpic) {
      color: inherit;
    }
  }
</style>
