<template>
  <div class="s-pagination" :style="{visibility: totalPages > 1 ? 'visible' : 'hidden', 'text-align': align}">
    <span v-show="pagesToDisplay.indexOf(1) == -1">
      <span v-for="page in beginningPages" :key="page" class="page-number-button">
        <a :title="'Go to page ' + page" :class="{current: page === currentPage}" @click="() => changePage(page)">
          {{ page }}
        </a>
      </span>
    </span>
    <span v-for="page in pagesToDisplay" :key="page" class="page-number-button">
      <a
        v-if="page != '...'"
        :title="'Go to page ' + page"
        :class="{current: page == currentPage}"
        @click="() => changePage(page)"
      >
        {{ page }}
      </a>
      <span v-else class="dots theme-style-caption-alt-secondary">
        {{ page }}
      </span>
    </span>
    <span v-show="pagesToDisplay.indexOf(totalPages) == -1">
      <span v-for="page in endPages" :key="page" class="page-number-button">
        <a :title="'Go to page ' + page" :class="{current: page === currentPage}" @click="() => changePage(page)">
          {{ page }}
        </a>
      </span>
    </span>
  </div>
</template>

<script>
  export default {
    name: 's-pagination',
    description: 'A simple pagination component for large lists/tables.',
    emits: ['change-page'],
    props: {
      offset: {
        description: 'The offset before the current page.',
        type: Number,
        required: true,
      },
      numEntries: {
        description: 'The total number of items.',
        type: Number,
        required: true,
      },
      limit: {
        description: 'The number of items displayed by page.',
        type: Number,
        validator: (val) => val > 0,
        required: true,
      },
      paginationSize: {
        description: 'The max number of pages links displayed in pagination.',
        type: Number,
        validator: (val) => {
          const isValid = !!(val % 2) && val >= 9;

          if (!isValid) {
            console.error('[Veasel]: "pagination-size" prop value ' + val + ' should be an odd number >= 9');
          }

          return isValid;
        },
        default: 9,
      },
      align: {
        description: 'The alignment of the pagination component - left, right, center',
        type: String,
        validator: (val) => {
          const isValid = ['left', 'center', 'right'].includes(val);
          if (!isValid) {
            console.warn(`[Veasel]: "align" prop value cannot be "${val}" please use "left", "center" or "right"`);
          }

          return isValid;
        },
        default: 'left',
      },
    },

    computed: {
      numberOfStaticPagesEachSide: function () {
        return this.paginationSize > 10 ? 3 : 2;
      },
      currentPage: function () {
        return this.offset / this.limit + 1;
      },
      showEndDots: function () {
        return (
          this.pagesToDisplay[0] !== this.totalPages - this.numberOfStaticPagesEachSide &&
          this.pagesToDisplay[this.pagesToDisplay.length - 1] !== this.totalPages - this.numberOfStaticPagesEachSide
        );
      },
      pagesToDisplay: function () {
        let results;
        // If we can show all the pages, then lets show all of them
        if (this.totalPages <= this.paginationSize) {
          results = this.generateCenterPagesToDisplay(
            this.numberOfStaticPagesEachSide + 1,
            this.totalPages - this.numberOfStaticPagesEachSide
          );
        } else {
          const numOfCenterPages = this.paginationSize - this.numberOfStaticPagesEachSide * 2;
          const numOfPagesToShowOnEachSide = (numOfCenterPages - 1) / 2;
          let min = this.currentPage - numOfPagesToShowOnEachSide;
          let max = this.currentPage + numOfPagesToShowOnEachSide;

          // If the min is lower than the number of static pages, then we need increase the max
          if (min <= this.numberOfStaticPagesEachSide) {
            const difference = this.numberOfStaticPagesEachSide - min;
            min = this.numberOfStaticPagesEachSide + 1;
            max = max + difference + 1;
          }

          // If the max is higher than the number of static pages, then we need decrease the min
          if (max >= this.totalPages - this.numberOfStaticPagesEachSide + 1) {
            const difference = max - (this.totalPages - this.numberOfStaticPagesEachSide);
            max = this.totalPages - this.numberOfStaticPagesEachSide;
            min = min - difference;
          }
          results = this.generateCenterPagesToDisplay(min, max);

          // If the top number does not flow into the static pages, then we want to replace that with dots
          if (results[0] !== this.numberOfStaticPagesEachSide + 1) {
            results.shift();
            results.unshift('...');
          }

          // If the bottom number does not flow into the static pages, then we want to replace that with dots
          if (results[results.length - 1] !== this.totalPages - this.numberOfStaticPagesEachSide) {
            results.pop();
            results.push('...');
          }
        }

        return results;
      },

      totalPages: function () {
        return Math.ceil(this.numEntries / this.limit);
      },
      beginningPages: function () {
        return this.generateCenterPagesToDisplay(1, this.numberOfStaticPagesEachSide);
      },
      endPages: function () {
        if (this.totalPages <= this.numberOfStaticPagesEachSide) {
          return [];
        }
        return this.generateCenterPagesToDisplay(
          Math.max(this.totalPages - this.numberOfStaticPagesEachSide + 1, this.numberOfStaticPagesEachSide + 1),
          this.totalPages
        );
      },
    },

    methods: {
      changePage: function (page) {
        this.$emit('change-page', page);
      },
      generateCenterPagesToDisplay: function (min, max) {
        const arrayOfPages = [];
        for (let i = min; i <= max; i++) {
          arrayOfPages.push(i);
        }
        return arrayOfPages;
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@veasel/core';

  .s-pagination {
    user-select: none;

    span {
      .dots {
        padding: rem(14px) rem(4px);
        border-bottom: 1px solid transparent;
      }

      a {
        @include caption-alt-active;

        cursor: pointer;
        display: inline-block;
        padding: rem(14px) rem(12px);
        border-bottom: 1px solid transparent;
        transition: ease color $transition-time;

        &:hover {
          @include caption-alt-active;
        }

        &.current {
          @include caption-alt;

          border-bottom-color: var(--active);
          pointer-events: none;
        }
      }
    }
  }
</style>
