<template>
  <section class="days-picker">
    <ol class="days-picker-list">
      <li v-for="nameOfDay in weekDaysName" :key="Math.random().toString() + nameOfDay" class="day-name">
        {{ nameOfDay }}
      </li>
      <li
        v-for="day in month.daysInMonth"
        :key="'d' + day"
        ref="day"
        class="day"
        :class="[
          {'first-day': parseInt(day) === 1},
          {
            active:
              parseInt(day) === parseInt(month.day) &&
              parseInt(selectedDate.month) + 1 === parseInt(month.month) &&
              parseInt(selectedDate.year) === parseInt(month.year),
          },
          {inactive: limitFrom(month.year, month.month, day) || limitTo(month.year, month.month, day)},
        ]"
        @click.stop="changeDay(day)"
      >
        <span
          :class="{
            current:
              parseInt(today.format({day: '2-digit'})) === day &&
              parseInt(today.format({month: 'numeric'})) === month.month,
          }"
        >
          {{ day }}
        </span>
      </li>
    </ol>
  </section>
</template>
<script>
  const MAX_WEEK_DAYS = 7;
  import mixins from '../utils/mixins';
  import {STime} from '@veasel/core/time';
  export default {
    name: 'calendarDays',
    emits: ['changeDay'],
    mixins: [mixins],
    data() {
      return {
        weekDaysName: Array.from({length: MAX_WEEK_DAYS}),
      };
    },
    props: {
      weekStart: {
        type: Number,
        description: 'Number. Shows first day of the week.',
        default: () => 1,
      },
      locale: {
        type: String,
        description: 'String representing current locale. Used to pick a format needed for STime. Ex. `en-GB`, `en-US`',
        default: STime.localeInfo().locale,
        validate: (val) => typeof val === 'string',
      },
    },
    methods: {
      changeDay(day) {
        this.$emit('changeDay', day);
      },
    },
    computed: {
      firstDayInWeek() {
        return ((this.$props.weekStart + (7 - this.firstDayIndexOfTheWeek)) % 7) + 1;
      },
      month() {
        return {
          month: this.$props.date.format({month: 'numeric'}),
          year: this.$props.date.format({year: 'numeric'}),
          day: this.$props.date.format({day: 'numeric'}),
          daysInMonth: new STime(this.$props.date, 'naive').daysInMonth(),
        };
      },
      today() {
        return new STime();
      },
      firstDayIndexOfTheWeek() {
        // if weekInfo not handled (firefox), default to Monday being the first day of the week (ISO)
        return new Intl.Locale(this.locale).weekInfo?.firstDay || 1;
      },
    },
    mounted() {
      this.weekDaysName.forEach((_, i) => {
        // using a year starting on a Monday to make a list of weekdays, and shift it using the locale
        this.weekDaysName[i] = new STime([2001, 1, i + this.firstDayIndexOfTheWeek]).format({weekday: 'narrow'});
      });
    },
  };
</script>

<style lang="scss" scoped>
  @import '@veasel/core/sass/colors';
  @import '@veasel/core/sass/export';
  $height: 32px;
  $width: 32px;

  .inactive {
    color: var(--secondary-50) !important;
    pointer-events: none;
  }

  .s-icon {
    @include title;

    &:hover {
      background: var(--secondary-light);
    }
  }

  .days-picker {
    display: grid;
    grid-area: calendar;

    &-list {
      display: grid;
      grid-template-columns: repeat(7, minmax(32px, auto));
      grid-template-rows: repeat(7, $height);
      place-items: center;
      list-style: none;
      margin: 0;
      padding: 0;
      text-align: center;
    }
  }

  .day {
    cursor: pointer;
    display: grid;
    place-items: center;
    text-align: center;
    grid-template-columns: $width;
    grid-template-rows: $height;

    &:not(.day-name):hover {
      background: var(--secondary-light);
    }

    &-name {
      color: var(--secondary-50);
      pointer-events: none;
    }
  }

  .current {
    color: var(--active);
    border-bottom: 1px solid var(--active);
  }

  .active {
    color: white;
    background: var(--active);

    & > .current {
      color: white;
    }
  }

  .first-day {
    display: grid;
    grid-column-start: v-bind(firstDayInWeek);
  }
</style>
