<!--
This is a wrapper for the vue-currency-input. This library connects to the parent component directly via modelValue,
 and currently cannot be changed. This causes issues when we want everything in Suade Libs to run through the $_value var,
 as other mixins like labels rely on this. So this component acts as a bridge between the inputMonetary component,
 and the vue-currency-input library
-->

<template>
  <input ref="inputRef" :value="formattedValue" />
</template>

<script>
  import {clone, isDeepEqual} from '@veasel/core/tools';
  import useCurrencyInput from 'vue-currency-input';
  import {watch} from 'vue';

  export default {
    name: 'CurrencyInputWrapper',
    props: {
      modelValue: Number,
      options: Object,
    },
    // emits: ['update:modelValue'], // Commented out, as while this fixes a js warning appearing, this appears to stop the vue-currency-input from working

    setup(props) {
      const getOptions = (rawOptions) => {
        const options = clone(rawOptions);
        options.precision = '2';
        if (options.currency === null) {
          options.currency = 'GBP'; // Doesn't matter, won't be displayed
          options.currencyDisplay = 'hidden';
        }
        return options;
      };

      const {formattedValue, inputRef, setOptions, setValue} = useCurrencyInput(getOptions(props.options));

      watch(
        () => props.modelValue,
        (value) => {
          setValue(value);
        }
      );

      watch(
        () => props.options,
        (options, prevOptions) => {
          // We don't want to update the component if there isn't actually any changes.
          if (!isDeepEqual(options, prevOptions)) {
            setOptions(getOptions(options));
          }
        },
        {deep: true}
      );

      return {inputRef, formattedValue};
    },
  };
</script>
