import {mapState} from 'vuex';
import SuadeConfig from '@/config/suade';
import {SDuration} from '@veasel/core/time';

import API from '@/services/api';

const sessionTimeoutMixin = {
  data: function () {
    return {
      sessionTimeout: null,
      logoutTimeout: null,
      timeUpdateInterval: null,
    };
  },

  computed: {
    ...mapState('user', ['expiration', 'isAuth']),
  },

  methods: {
    closeDialog() {
      if (Popup.getState().isOpen) {
        Popup.close();
      }
    },
  },

  watch: {
    expiration: {
      deep: true,
      handler() {
        // Time remaining until session timeout warning dialog shold be displayed
        const timeToWarning = this.expiration.seconds_remaining * 1000 - SuadeConfig.SESSION_EXPIRATION_WARNING_MS;

        if (timeToWarning > 0) {
          // If we are outside the warning threshold time, check again when that time is reached
          window.clearTimeout(this.sessionTimeout);
          this.sessionTimeout = window.setTimeout(API.getSessionExpiration, timeToWarning);

          // Clear the log-out timeout if set
          window.clearTimeout(this.logoutTimeout);
        } else {
          // If we are within the warning threshold time, show the warning popup
          Popup.show({
            title: 'Automatic Timeout',
            text: 'Your session will expire in ' + new SDuration(this.expiration.seconds_remaining * 1000).format(),
            icon: 'warning',
            buttons: {
              logout: {
                text: 'Logout Now',
                value: false,
                className: 'theme-error',
                closeModal: true,
                color: '#DD6B55',
              },
              extend: {
                text: 'Extend Session',
                value: true,
                className: 'theme-warning',
                closeModal: true,
                color: '#A5DC86',
              },
            },
            closeOnEsc: false,
            closeOnClickOutside: false,
          })
            .then((extend) => {
              if (extend) {
                API.getCurrentUser()
                  .then(() => API.getSessionExpiration().catch((err) => console.warn(err)))
                  .catch((err) => console.warn(err));
              } else {
                Tutorial.destroy();
                this.logOut();
              }
            })
            .catch((err) => {
              console.error(err);
            })
            .then(() => {
              window.clearInterval(this.timeUpdateInterval);
            });

          // Also start an interval to update the time remaining
          window.clearInterval(this.timeUpdateInterval);
          this.timeUpdateInterval = window.setInterval(() => {
            const timeLeft =
              (this.expiration.seconds_remaining - Math.floor((Date.now() - this.expiration.received_at) / 1000)) *
              1000;
            document.querySelector('.s-popup-text').innerHTML =
              'Your session will expire in ' + new SDuration(timeLeft).format();
          }, 1000);

          // Finally set a timeout to log the user out if they take no action
          window.clearTimeout(this.logoutTimeout);

          this.logoutTimeout = window.setTimeout(this.logOut, Math.floor(this.expiration.seconds_remaining * 1000));
        }
      },
    },

    isAuth() {
      // If the user logs out in another way, close the dialog stop the timers
      if (this.isAuth === false) {
        this.closeDialog();

        window.clearTimeout(this.sessionTimeout);
        window.clearTimeout(this.logoutTimeout);
        window.clearInterval(this.timeUpdateInterval);
      }
    },

    beforeUnmount() {
      // Close dialog and stop the timers before the Vue app is destroyed
      this.closeDialog();

      window.clearTimeout(this.sessionTimeout);
      window.clearTimeout(this.logoutTimeout);
      window.clearInterval(this.timeUpdateInterval);
    },
  },
};

export default sessionTimeoutMixin;
