<template>
  <img v-if="!!url && isImageValid" :src="url" :height="height" :width="width" />
  <s-placeholder v-else />
</template>

<script>
  import placeholder from '@veasel/base/placeholder';

  export default {
    name: 's-image',
    description: 'A wrapper for the <img> tag to allow for a placeholder and validation of the image passed in.',
    components: {
      's-placeholder': placeholder,
    },
    props: {
      url: {
        description: 'The image src',
        type: String,
      },
    },

    data() {
      return {
        isImageValid: false,
        height: undefined,
        width: undefined,
      };
    },

    methods: {
      // inspired by Caleb Taylor - https://stackoverflow.com/a/68333175
      validateImage: async function () {
        this.isImageValid = await new Promise((resolve) => {
          const img = new Image();

          img.src = this.url;
          img.onload = (event) => {
            // Adding height and width to img tag helps browser performance
            // https://www.smashingmagazine.com/2020/03/setting-height-width-images-important-again/
            this.height = this.height ? this.height : event.target.height;
            this.width = this.width ? this.width : event.target.width;
            return resolve(true);
          };
          img.onerror = () => resolve(false);
        });
      },
    },

    created: async function () {
      if (this.url) {
        await this.validateImage();
      }
    },
  };
</script>
