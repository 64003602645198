import {getStorage, setStorage, setStorageDict} from '@veasel/core/tools';

// In order to improve load experience, initialize some values from local storage.
function initialStateFromLocalStorage() {
  return {
    tutorialSeen: getStorage('tutorial-seen') || {},
    tutorialDisabled: getStorage('tutorial-disabled') || false,
  };
}

// var tutorialScope = false;

export default {
  name: 'tutorial',

  namespaced: true,

  state: {
    currentTutorial: false,
    tutorialForPage: [],
    tutorialSeen: {},
    tutorialDisabled: false,

    sharedScope: {},

    ...initialStateFromLocalStorage(),
  },

  getters: {
    tutorialScope(state) {
      return state.tutorialScope;
    },
  },

  mutations: {
    updateTutorial(state, value) {
      state.currentTutorial = value;
    },
    setTutorialForPage(state, value) {
      state.tutorialForPage = value;
    },
    toggleTutorial(state, value) {
      state.tutorialDisabled = value;
    },
    setTutorialAsSeen(state, value) {
      state.tutorialSeen[value] = true;
    },
    resetTutorial(state) {
      state.tutorialSeen = {};
    },
    updateSharedScope(state, value) {
      state.sharedScope = {...state.sharedScope, ...value};
    },
    resetSharedScope(state) {
      state.sharedScope = {};
    },
  },

  actions: {
    openTutorial(context, value) {
      context.commit('updateTutorial', value);
    },
    closeTutorial(context) {
      context.commit('updateTutorial', false);
    },
    setTutorialForPage(context, value) {
      context.commit('setTutorialForPage', value);
    },
    toggleTutorial(context, value) {
      setStorage('tutorial-disabled', value);
      context.commit('toggleTutorial', value);
    },
    setTutorialAsSeen(context, value) {
      setStorageDict('tutorial-seen', value, true);
      context.commit('setTutorialAsSeen', value);
    },
    resetTutorial(context) {
      setStorage('tutorial-seen', {});
      context.commit('resetTutorial');
    },
    updateSharedScope(context, value) {
      context.commit('updateSharedScope', value);
    },
    resetSharedScope(context) {
      context.commit('resetSharedScope');
    },
  },
};
