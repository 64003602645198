<template>
  <s-input-wrapper v-bind="$props">
    <input
      :id="id || $_name"
      v-model.number="$_value"
      :name="name"
      type="number"
      :placeholder="_placeholder"
      :min="min"
      :max="max"
      :step="step"
      :disabled="disabled"
      :readonly="readonly"
      class="s-input-group-field"
      @keydown="allowDecimalSeparator"
      @change="$_onChange"
      @focus="$_onFocus"
      @focusout="$_onBlur"
      @paste="$_onPaste"
    />
  </s-input-wrapper>
</template>

<script>
  import SInputWrapper from '@veasel/inputs/input-wrapper';
  import {
    syncValue,
    onChange,
    onClick,
    onBlur,
    onFocus,
    onKeyPressed,
    onPaste,
    id,
    name,
    readonly,
    placeholder,
    isDisabled,
    isRequired,
    pattern,
    maxLength,
    helperMessages,
  } from '@veasel/core/mixins';

  export default {
    name: 's-input-number',
    description: 'A user input for selecting number value.',
    components: {
      SInputWrapper,
    },
    mixins: [
      syncValue(Number),
      onChange(),
      onClick(),
      onBlur(),
      onFocus(),
      onKeyPressed(),
      onPaste(),
      id,
      name,
      readonly,
      placeholder,
      isDisabled,
      isRequired,
      pattern,
      maxLength,
      helperMessages,
    ],
    props: {
      min: {
        description: 'The minimum value that can be selected',
        type: Number,
      },
      max: {
        description: 'The maximum value that can be selected',
        type: Number,
      },
      step: {
        description: 'The minimal step between each valid value',
        type: [Number, String],
        default: 'any',
      },
    },
    data() {
      return {
        inputType: 'number',
      };
    },
    computed: {
      isInteger: function () {
        return this.step >= 1;
      },
    },
    methods: {
      // used for copy/past
      // overrides syncValue mixin '$_formatValue' function
      $_formatValue: function (val) {
        let formatedVal;
        if (val === '') {
          formatedVal = undefined;
        } else {
          formatedVal = this.isInteger ? Math.round(val) : Number.parseFloat(val);
          if (formatedVal >= this.max) {
            formatedVal = this.max;
            this.$_value = formatedVal;
          } else if (formatedVal <= this.min) {
            formatedVal = this.min;
            this.$_value = formatedVal;
          }
          formatedVal = Number.isNaN(formatedVal) ? undefined : formatedVal;
        }

        return formatedVal;
      },
      allowDecimalSeparator: function (event) {
        this.$_onKeyPressed(event);
        if (this.isInteger && ['Period', 'Comma', 'NumpadDecimal'].includes(event.code)) {
          event.preventDefault();
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  input.s-input-group-field {
    font-family: var(--monotype-caption-font-family) !important;
  }
</style>
