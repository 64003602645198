<template>
  <div class="query-builder text-left">
    <s-query-group
      v-model:group="group"
      v-model:editing="editing"
      :options="options"
      :removable="false"
      :root="true"
      :allowImport="allowImport"
      :uniqId="uniqId"
      data-cy="query-group-root"
      @import-clicked="importClicked"
    />
    <s-button
      v-if="hideLockButton === false"
      :text="editing ? 'Lock' : 'Edit'"
      :onClick="lockClicked"
      color="blue"
      class="term-button edit-lock-toggle"
      size="small"
      data-cy="button-query-builder-lock"
    />
  </div>
</template>

<script>
  import {uniqId} from '@veasel/core/tools';
  import {syncValue} from '@veasel/core/mixins';
  import {sql} from '@veasel/core/formatters';
  import sQueryGroup from './queryGroup.vue';
  import button from '@veasel/base/button';

  export default {
    name: 's-query-builder',
    emits: ['update:sql', 'import-clicked'],
    description: 'A complex component to build logic queries potential nested groups.',

    components: {
      's-query-group': sQueryGroup,
      's-button': button,
    },

    mixins: [syncValue(Object)],

    props: {
      options: {
        description: 'The options object to populate the search input',
        type: Object,
      },

      sql: {
        description: 'The query in SQL form',
        type: String,
        code: 'sql',
      },

      allowImport: {
        description: 'True if this query builder allows importing existing logic',
        type: Boolean,
        default: false,
      },

      startUnlocked: {
        description: 'True if the query builder should be initialised unlocked',
        type: Boolean,
        default: false,
      },
      hideLockButton: {
        description: 'Hide the lock button so lock/unlock must be programatic',
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      // Initial group with empty terms
      group: {
        operator: 'and',
        terms: [],
      },
      editing: false,
    }),

    methods: {
      // Wrap in a method to be replaceable when unit testing
      uniqId(str) {
        return uniqId(str);
      },

      initGroup(value = null) {
        // During first time initialisation, use the current synced query value
        if (!value) {
          value = this.$_value;
        }

        if (value && Object.keys(value).length > 0) {
          // Generate the data structure from the query
          this.group = this.inflateGroup(value).group;
        } else {
          // No initial value, so add an empty term
          this.group.terms.splice(0, this.group.terms.length, {
            type: 'term',
            query: [],
            uid: this.uniqId('term_'),
          });
        }
      },

      // Given a query object, recursivley inflate it into group data
      inflateGroup(query) {
        if (query.rules) {
          return {
            type: 'group', // If rules is present, this is a group
            group: {
              operator: (query.not ? 'n' : '') + query.condition.toLowerCase(),
              terms: query.rules.map((rule) => this.inflateGroup(rule)), // Recurse here to inflate nested rules into groups and terms
            },
            uid: this.uniqId('group_'),
          };
        } else if (query.field) {
          return {
            type: 'term', // If field is present, this is a term
            query: [
              {
                field: query.field,
                operator: query.operator,
                value: query.value === null ? undefined : query.value, // is_null and is_not_null operators do not require value field
              },
            ],
            uid: this.uniqId('term_'),
          };
        }

        // Warn if not recognised as a group or a term
        console.warn('Could not inflate query builder term', query);
        return {};
      },

      // Update the synced query value after some user input changes the query
      updateValue() {
        const value = this.renderQuery({
          type: 'group',
          group: this.group,
        });

        this.$_value = value ? value : {};

        // Update the SQL string using the same value
        const sqlCode = sql(value ? [value] : []);
        this.$emit('update:sql', sqlCode);
      },

      // Recursively render a term within the query data into the correct form for external use
      renderQuery(term) {
        let value = null;

        if (term.type === 'group' && term.group.terms.length > 0) {
          // Recurse here for each nested term, ignore null terms
          const terms = term.group.terms.map(this.renderQuery).filter((t) => t != null);

          if (terms.length > 0) {
            value = {
              ...this.expandOperator(term.group.operator),
              rules: terms,
            };
          }
        } else if (term.type === 'term' && term.query[0]) {
          value = {...term.query[0], id: term.query[0].field};

          if (!value.value && value.value !== 0) {
            value.value = [null];
          }
        }

        return value;
      },

      // Expand the 'and' 'or' 'nand' and 'nor' operators into 'condition' (and|or) and 'not' (true|false) fields
      expandOperator(operator) {
        return {
          condition: operator.includes('and') ? 'AND' : 'OR', // Rules require capitalised conditions
          not: operator.startsWith('n') ? true : false,
        };
      },

      lockClicked() {
        this.editing = !this.editing;

        // When locking, remove uneccessary empty elements from the treee
        if (this.editing === false) {
          this.pruneEmpties(this.group, true);
        }
      },

      // Lock and unlock functions can be called externally via $ref to lock the component
      lock() {
        this.editing = false;
        this.pruneEmpties(this.group, true);
      },

      unlock() {
        this.editing = true;
      },

      // Recursively remove uneccessary empty elements
      pruneEmpties(group) {
        const remove = [];
        const hasNonEmpty = this.hasNonEmpty(group.terms);

        group.terms.forEach((term, index) => {
          // Recurse for each nested group
          if (term.type === 'group') {
            if (!this.pruneEmpties(term.group)) {
              // If the group is empty after pruning (returns false) it can also be removed
              remove.push(term);
            }
          } else if (term.query.length === 0 && (index !== 0 || hasNonEmpty)) {
            // Mark this term for removal if the query is empty and it is either not the first term, or there are non-empty terms afterwards
            remove.push(term);
          }
        });

        // Remove the marked items
        remove.forEach((term) => group.terms.splice(group.terms.indexOf(term), 1));

        // Return false if this is now an empty group
        if (
          group.terms.length === 0 ||
          (group.terms.length === 1 && group.terms[0].type === 'term' && group.terms[0].query.length === 0)
        ) {
          return false;
        }

        return true;
      },

      // Return true if this array of terms includes atleast one which is a non empty query (and not a group)
      hasNonEmpty(terms) {
        for (let i = 0; i < terms.length; i++) {
          if (terms[i].query && terms[i].query.length > 0) {
            return true;
          }
        }

        return false;
      },

      // The user has clicked the import buttons for one of the groups
      importClicked(callback) {
        // Emit the import event with a callback to be called with the selected rules to import
        this.$emit('import-clicked', (rules) => {
          // Inflate the imported rules into a group
          const inflated = this.inflateGroup(rules[0]);

          // Call the callback provided by the query group that was clicked initially
          callback(inflated.group);
        });
      },
    },

    // Watch the top level group and update the synced values when a change occurs
    watch: {
      group: {
        deep: true,
        handler() {
          this.updateValue();
        },
      },
    },

    mounted() {
      if (this.startUnlocked) {
        this.editing = true;
      }
    },

    // When the component is created initialise the query from the initial value
    created() {
      this.initGroup();
    },
  };
</script>
