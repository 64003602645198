<template>
  <section class="months-picker">
    <div class="months-picker-controller">
      <s-icon type="arrow-drop-left" size="14" @click="prevMonth" />
    </div>
    <div ref="monthsList" class="months-picker-list">
      <div
        v-for="month in months"
        :key="month"
        class="month"
        :class="[
          {active: parseInt(month.month) === parseInt(activeMonth)},
          {
            inactive:
              parseInt(month.month) !== parseInt(activeMonth) &&
              (limitFrom(month.year, month.month) || limitTo(month.year, month.month)),
          },
        ]"
        @click.stop="selectMonth(month, false)"
      >
        {{ month.name }}
      </div>
    </div>
    <div class="months-picker-controller">
      <s-icon type="arrow-drop-right" size="14" @click="nextMonth" />
    </div>
  </section>
</template>

<script>
  import {STime} from '@veasel/core/time';
  import mixins from '../utils/mixins';
  import functions from '../utils/functions';
  const MAX_NR_MONTHS = 5;

  export default {
    name: 'calendarMonths',
    emits: ['changeMonth'],
    data() {
      return {
        months: [],
        selectedMonth: {},
      };
    },
    mixins: [mixins],
    props: {
      activeMonth: {
        type: [Number, String],
        description: 'Active month represented by a number (ex April = 4).',
      },
    },
    computed: {
      getDate() {
        return this.$props.date;
      },
      monthsListMidPoint() {
        return Math.floor(MAX_NR_MONTHS / 2);
      },
    },
    methods: {
      prevMonth() {
        this.changeMonth(this.months[this.monthsListMidPoint - 1]);
      },
      nextMonth() {
        this.changeMonth(this.months[this.monthsListMidPoint + 1]);
      },
      selectMonth(month) {
        month.year = `${this.date.year}`;

        this.changeMonth(month);
      },
      changeMonth(month, update = false) {
        this.$emit('changeMonth', month, update);

        this.$nextTick(() => {
          const updatedMonth = new STime([month.year, month.month], 'UTC');
          this.buildMonthsList(updatedMonth);
        });
      },
      buildMonthsList(month) {
        this.months = [];

        for (let i = 0; i < MAX_NR_MONTHS; i++) {
          // Centre active month
          if (i === 0) {
            month.subtract(this.monthsListMidPoint, 'month');
          } else {
            month.add(1, 'month');
          }

          this.months.push({
            month: month.format({month: 'numeric'}),
            name: month.format({month: 'long'}),
            year: month.format({year: 'numeric'}),
            weekday: month.format({weekday: 'long'}),
            day: month.format({day: 'numeric'}),
          });
        }
      },
      addListScrollEventListener() {
        const monthsListRef = this.$refs.monthsList;
        const opts = {
          downfn: this.prevMonth,
          upfn: this.nextMonth,
        };
        monthsListRef.addEventListener('wheel', (e) => functions.scrollWheelEventListener(e, opts));
      },
      removeListScrollEventListener() {
        const monthsListRef = this.$refs.monthsList;
        const opts = {
          downfn: this.prevMonth,
          upfn: this.nextMonth,
        };
        monthsListRef.removeEventListener('wheel', (e) => functions.scrollWheelEventListener(e, opts));
      },
    },
    created() {
      // Set this for reference when navigating through months
      this.selectedMonth = this.getDate.clone();

      this.buildMonthsList(this.selectedMonth);

      this.$nextTick(() => {
        this.addListScrollEventListener();
      });
    },
    beforeUnmount() {
      this.removeListScrollEventListener();
    },
    watch: {
      date(newVal) {
        if (newVal !== null) {
          this.buildMonthsList(newVal.clone());
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@veasel/core/sass/export';
  $height: 32px;
  $width: 32px;

  .inactive {
    color: var(--secondary-50) !important;
    pointer-events: none;
  }

  .s-icon {
    @include title;

    &:hover {
      background: var(--secondary-light);
    }
  }

  $itemHeight: calc(100% - 2px);
  $gridColumnMinMax: 58px;

  .months-picker {
    display: grid;
    grid-template-columns: $width auto $width;
    grid-template-rows: $height;
    grid-area: months;
    position: relative;
    gap: 5px;
    width: 100%;
    user-select: none;
    border-bottom: 1px solid var(--background-secondary);

    & .months-picker-list {
      display: grid;
      grid-template-columns: repeat(5, minmax($gridColumnMinMax, auto));
      grid-template-rows: $itemHeight;
      overflow: hidden;
      position: relative;
    }

    & .month {
      border-bottom: 2px solid transparent;
      cursor: pointer;
      place-items: center;
      place-self: center;
      align-self: center;
      display: grid;
      height: $itemHeight;
      font-weight: bold;
      color: var(--active);
      padding: 0 2px;

      &:hover {
        background: var(--secondary-light);
      }
    }

    & .active {
      position: relative;
      border-bottom: 2px solid var(--active);
      color: black;
    }

    &-controller {
      cursor: pointer;

      .s-icon {
        height: $itemHeight;
        display: grid;
        place-content: center;
      }
    }
  }
</style>
