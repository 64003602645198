import {throttle} from '@veasel/core/tools';

export default {
  props: {
    resizeTimeLimit: {
      type: Number,
      default: 100,
    },
  },
  data: function () {
    return {
      windowWidth: 0,
      windowHeight: 0,
      resizeFunction: () => {
        const parent = this.$el?.parentNode;
        if (parent && (this.windowWidth !== parent.clientWidth || this.windowHeight !== parent.clientHeight)) {
          this.windowWidth = parent.clientWidth;
          this.windowHeight = parent.clientHeight;
          this.throttledResize();
        }
      },
    };
  },
  mounted: function () {
    this.throttledResize = throttle(this.$_throttledResize, this.resizeTimeLimit);
    if (this.$el) {
      const parent = this.$el?.parentNode;
      if (parent) {
        this.windowWidth = parent.clientWidth;
        this.windowHeight = parent.clientHeight;
      }
      if ('ResizeObserver' in window) {
        const myObserver = new ResizeObserver(this.throttledResize);
        myObserver.observe(parent);
      } else {
        window.addEventListener('resize', this.resizeFunction);
      }
    }
  },
  methods: {
    $_throttledResize: function () {
      this.resize({width: this.windowWidth, height: this.windowHeight});
    },
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resizeFunction);
  },
};
