<template>
  <s-input-wrapper v-bind="$props" :class="['input-textarea', isFocused || isHovering ? 'input-textarea-focused' : '']">
    <textarea
      :id="id"
      v-model="$_value"
      :name="name"
      :maxlength="maxlength"
      :placeholder="_placeholder"
      :disabled="disabled"
      :readonly="readonly"
      class="s-input-group-field"
      @change="$_onChange"
      @click="$_onClick"
      @keydown="$_onKeyPressed"
      @focus="onFocused"
      @blur="onBlured"
      @mouseenter="() => (isHovering = true)"
      @mouseleave="() => (isHovering = false)"
      @animationstart="animationStart"
    />
  </s-input-wrapper>
</template>

<script>
  import SInputWrapper from '@veasel/inputs/input-wrapper';
  import {
    syncValue,
    onChange,
    onClick,
    onBlur,
    onFocus,
    onKeyPressed,
    onPaste,
    id,
    name,
    readonly,
    placeholder,
    isDisabled,
    isRequired,
    pattern,
    maxLength,
    helperMessages,
  } from '@veasel/core/mixins';

  export default {
    name: 's-input-textarea',
    description: 'A user input for text value.',
    components: {
      SInputWrapper,
    },
    mixins: [
      syncValue(String),
      onChange(),
      onClick(),
      onBlur(),
      onFocus(),
      onKeyPressed(),
      onPaste(),
      id,
      name,
      readonly,
      placeholder,
      isDisabled,
      isRequired,
      pattern,
      maxLength,
      helperMessages,
    ],
    data: function () {
      return {
        isFocused: false,
        isHovering: false,
      };
    },
    created() {
      const truncate = (val) => (val === undefined || this.maxlength === -1 ? val : val.substring(0, this.maxlength));
      this.$data.$_formatersValue.push(truncate);
    },
    methods: {
      onFocused() {
        this.isFocused = true;
        this.$_onFocus();
      },
      onBlured() {
        this.isFocused = false;
        this.$_onBlur();
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@veasel/core';

  textarea {
    @include base;
  }
</style>
