import {uniqId} from '@veasel/core/tools';

export default {
  props: {
    name: {
      description: 'The html name for the input.',
      type: String,
    },
  },
  data() {
    return {
      $_name: '',
    };
  },
  created() {
    this.$_name = this.name || (this.label || '') + '-' + uniqId();
  },
};
