<template>
  <teleport to="#tooltip-wrapper">
    <div class="s-tooltip" :class="{visible: forceVisible || visible}" @mouseout="onMouseOut">
      <div :id="id" class="tooltip-container absolute" :style="{...positionStyleProps, ...paddingStyle}">
        <div v-if="caret" class="caret" :class="[actualPosition]" :style="caretStyleProps">
          <caret :color="hexColor" />
        </div>
        <div
          class="tooltip-content"
          :class="[color, actualPosition, 'theme-style-base-small']"
          :style="{maxWidth: width ? width + 'px' : 'auto', ...tooltipContentStyle}"
          @mouseenter="tooltipContentHovered = true"
          @mouseleave="tooltipContentHovered = false"
        >
          <slot></slot>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
  import {position} from '@veasel/core/mixins';
  import caret from './caret.vue';
  import {themeColorsList, getThemeColors, getLegacyColors} from '@veasel/core/themes';

  export default {
    name: 's-tooltip',
    description: 'A simple tooltip component.',
    components: {caret},
    mixins: [position],
    props: {
      color: {
        description: 'The tooltip color.',
        type: String,
        values: [
          'black',
          'white',
          'gray',
          'lightgray',
          'blue',
          'focus-blue',
          'red',
          'green',
          'orange',
          ...themeColorsList,
        ],
        validator: (val) =>
          [
            'black',
            'white',
            'gray',
            'lightgray',
            'blue',
            'focus-blue',
            'red',
            'green',
            'orange',
            ...themeColorsList,
          ].includes(val),
        default: 'white',
      },
      width: {
        description: 'The tooltip max width.',
        type: Number,
      },
      forceVisible: {
        description: 'A flag to force a tooltip to always be visible.',
        type: Boolean,
      },
    },

    computed: {
      hexColor() {
        if (this.color.includes('theme')) {
          return getThemeColors()[this.color.split('theme-')[1]];
        }
        return getLegacyColors()[this.color];
      },
    },

    data: function () {
      return {
        targetElement: undefined,
        visible: false,
        overflowScroll: false,
        tooltipContentHovered: false,
      };
    },

    watch: {
      target: function () {
        this.updateTargetElement();
      },
      position: function () {
        this.setPosition(this.position, this.align);
      },
      align: function () {
        this.setPosition(this.position, this.align);
      },
    },
    methods: {
      updateTargetElement: function () {
        const result = document.querySelector(this.target);
        if (result) {
          this.targetElement = result;
          this.targetElement.addEventListener('mouseenter', this.onMouseEnter, true);
          this.targetElement.addEventListener('mousemove', this.onMouseEnter, true);
          this.targetElement.addEventListener('mouseout', this.onMouseOut, true);
        }
      },
      onMouseEnter: function () {
        this.visible = true;
        this.$nextTick().then(() => {
          this.setPosition(this.position, this.align);
          this.targetElement?.removeEventListener('mousemove', this.onMouseEnter, true);
        });
      },
      onMouseOut: function () {
        this.visible = false;
      },
    },
    mounted() {
      this.updateTargetElement();
      this.setPosition(this.position, this.align);
    },
  };
</script>

<style scoped lang="scss">
  @import '@veasel/core';

  .s-tooltip {
    width: 0;
    height: 0;
    visibility: hidden;
    position: relative;
    float: left;
    font-size: 14px;

    // eslint-disable-next-line
    .caret {
      position: absolute;
      display: flex;
      z-index: 81;
      transform-origin: center;

      &.left {
        transform: rotate(90deg);
        margin-left: -3px;
        margin-top: 2px;
      }

      &.top {
        transform: rotate(180deg);
      }

      &.right {
        transform: rotate(270deg);
        margin-left: -3px;
        margin-top: 2px;
      }
    }

    .tooltip-container {
      .tooltip-content {
        width: max-content;
        z-index: 80;
        position: relative;
        border-radius: $button-radius;
        box-shadow: 0 1px 6px #00000029;
        padding: 5px get-spacing('m');
      }
    }

    &.visible {
      visibility: visible;
    }
  }

  .s-tooltip:hover .tooltip-container:hover,
  .tooltip-container:hover {
    visibility: visible;
  }
</style>
