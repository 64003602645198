export default {
  scrollWheelEventListener: (e, options) => {
    e.preventDefault();
    if (e.deltaY < 0) {
      options.downfn(options.value);
    } else {
      options.upfn(options.value);
    }
  },
};
