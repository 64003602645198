<template>
  <div class="x-grid" :class="`style-${styling}`">
    <div v-if="$slots.header" class="expandable-header pointer" @click="toggle">
      <div class="row x-row align-items-center">
        <slot name="header" />
        <div class="col-2 align-right">
          <div v-if="position === 'top' && hasContent" class="expander" :class="['align-' + align]">
            {{ $_modelValue ? textCollapse : textExpand }}
            <s-icon
              type="arrow-drop-down"
              color="theme-secondary"
              class="flip"
              :class="{'is-flip': $_modelValue}"
              size="8"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row x-row align-items-center">
        <div v-if="position === 'top' && hasContent" class="expander" :class="['align-' + align]">
          <a class="theme-style-subtitle-active" @click="toggle">
            {{ $_modelValue ? textCollapse : textExpand }}
            <s-icon :type="$_modelValue ? 'arrow-drop-up' : 'arrow-drop-down'" color="theme-secondary" size="8" />
          </a>
        </div>
      </div>
    </div>
    <div ref="container" class="expandable-content" :class="{hidden: !$_modelValue}">
      <div ref="content">
        <slot></slot>
      </div>
    </div>
    <div v-if="position === 'bottom' && hasContent" class="expander" :class="['align-' + align]">
      <a class="theme-style-subtitle-active" @click="toggle">
        {{ $_modelValue ? textCollapse : textExpand }}
        <s-icon :type="$_modelValue ? 'arrow-drop-up' : 'arrow-drop-down'" color="theme-secondary" size="8" />
      </a>
    </div>
  </div>
</template>

<script>
  const TRANSITION_TIME = 0.2; // in seconds
  const EXPANDABLE_STYLES = ['default', 'no-padding'];
  import {optionalSyncValue} from '@veasel/core/mixins';
  import icon from '@veasel/base/icon';

  export default {
    name: 's-expandable',
    components: {
      's-icon': icon,
    },
    mixins: [optionalSyncValue(Boolean, false)],
    description: 'A simple wrapper to show/hide the content.',
    slots: {
      default: 'Contains what gets shown and hidden',
      header:
        'Allows for customisation of the header/selector area, beyond what `text-collapse` & `text-expand` offers',
    },
    props: {
      textExpand: {
        description: 'The text to display for expand link.',
        type: String,
        default: 'show more',
      },

      textCollapse: {
        description: 'The text to display for collapse link.',
        type: String,
        default: 'show less',
      },

      align: {
        description: 'The link alignment.',
        type: String,
        values: ['right', 'left'],
        validator: (val) => ['right', 'left'].includes(val),
        default: 'right',
      },
      position: {
        description: 'The link position.',
        type: String,
        values: ['top', 'bottom'],
        validator: (val) => ['top', 'bottom'].includes(val),
        default: 'bottom',
      },
      expandedWhenCreated: {
        description: 'When the component is created, should it be expanded already',
        type: Boolean,
        default: undefined,
      },
      styling: {
        description: 'Component style: default, no-padding, etc.',
        type: String,
        values: EXPANDABLE_STYLES,
        default: EXPANDABLE_STYLES[0],
      },
    },

    data: function () {
      return {
        transitionTimeout: null,
        heightPoll: null,
      };
    },

    computed: {
      hasContent() {
        return typeof this.$slots.default !== 'undefined';
      },
    },

    mounted() {
      if (this.$data.$_modelValue === undefined && this.expandedWhenCreated !== undefined) {
        this.$_modelValue = this.expandedWhenCreated;
      }
      if (this.$_modelValue) {
        this.$refs.container.style.transition = TRANSITION_TIME + 's';
        this.$refs.container.style.display = 'block';
        this.$refs.container.style.height = this.$refs.content.offsetHeight + 'px';
      } else {
        this.animClose();
      }
      this.heightPoll = window.setInterval(this.pollHeight, 20);
    },

    beforeUnmount() {
      window.clearTimeout(this.transitionTimeout);
      window.clearInterval(this.heightPoll);
    },

    watch: {
      $_modelValue(newV) {
        if (newV) {
          this.animOpen();
        } else {
          this.animClose();
        }
      },
    },

    methods: {
      toggle: function () {
        this.$_modelValue = !this.$_modelValue;
      },

      animClose() {
        this.$refs.container.style.transition = TRANSITION_TIME + 's';
        this.$refs.container.style.height = 0;
        this.$refs.container.style.overflow = 'hidden';

        window.clearTimeout(this.transitionTimeout);

        this.transitionTimeout = setTimeout(() => {
          this.$refs.container.style.display = 'none';
        }, TRANSITION_TIME * 1000);
      },

      animOpen() {
        this.$refs.container.style.transition = TRANSITION_TIME + 's';
        this.$refs.container.style.display = 'block';
        this.$refs.container.style.height = this.$refs.content.offsetHeight + 'px';

        window.clearTimeout(this.transitionTimeout);

        this.transitionTimeout = setTimeout(() => {
          this.$refs.container.style.overflow = 'visible';
        }, TRANSITION_TIME * 1000);
      },

      pollHeight() {
        if (this.$_modelValue && this.$refs.container.style.height !== this.$refs.content.offsetHeight + 'px') {
          this.$refs.container.style.height = this.$refs.content.offsetHeight + 'px';
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@veasel/core';

  ::v-deep(.expander) {
    &.align-right {
      text-align: right;
    }

    &.align-left {
      text-align: left;
    }

    &.hidden {
      display: none;
    }

    .svg-icon {
      width: 0.5rem;
      pointer-events: none;
      margin-left: 2px;
      transition: ease all $transition-time;
      transform: scale(1);
      transform-origin: center;
      display: inline-block;
    }

    a {
      cursor: pointer;
      text-decoration: none;
    }

    &:hover .svg-icon {
      fill: var(--hover);
      transform: scale(1.333333);
    }
  }

  .expandable-content.hidden {
    height: 0;
    display: none;
    overflow: hidden;
  }

  .flip {
    position: relative;
    transform: rotate(0deg);
    transition: ease all $transition-time;
    display: inline-block;
    transform-origin: center;

    &.is-flip {
      transform: rotate(180deg);
    }
  }

  .style-default > .expandable-header {
    padding: 16px 24px;
    justify-content: center;
  }
</style>
