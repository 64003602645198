<template>
  <s-input-wrapper v-bind="$props" isBoolean>
    <div
      class="pointer"
      :class="[size, disabled ? 'disabled' : '', 'flex', 'align-items-center', styling]"
      @click="checkValue"
    >
      <div :id="id" class="radio" :class="[$_value ? 'active' : '']" :name="name"></div>
      <div v-if="placeholder || (label && labelMod === 'inside')" class="label" :class="{active: $_value}">
        {{ _placeholder }}
      </div>
    </div>
  </s-input-wrapper>
</template>

<script>
  import SInputWrapper from '@veasel/inputs/input-wrapper';
  import {
    syncValue,
    onChange,
    onClick,
    onBlur,
    onFocus,
    id,
    name,
    isDisabled,
    isRequired,
    placeholder,
    label,
    size,
  } from '@veasel/core/mixins';

  const INPUT_RADIO_STYLES = ['default', 'button'];

  export default {
    name: 's-input-radio',
    description: 'A user input as a radio button for boolean value.',
    components: {SInputWrapper},
    mixins: [
      syncValue([Boolean, String]),
      onChange(),
      onClick(),
      onBlur(),
      onFocus(),
      id,
      name,
      isDisabled,
      isRequired,
      placeholder,
      label,
      size(),
    ],
    props: {
      label: {
        description: 'A label to describe the input.',
        type: String,
        default: '',
      },
      group: {
        description: 'The group of radio button, when one is selected the other ones are unselected.',
        type: String,
        default: '',
      },
      styling: {
        description: 'Component style: default, button, etc.',
        type: String,
        values: INPUT_RADIO_STYLES,
        default: INPUT_RADIO_STYLES[0],
      },
    },
    methods: {
      checkValue: function () {
        if (!this.disabled) {
          if (this.required && !this.$_value) {
            this.$_value = true;
          } else if (!this.required) {
            this.$_value = !this.$_value;
          }

          this.$_onChange();
          if (this.$_value !== true) {
            if (this.group) {
              document.dispatchEvent(
                new CustomEvent('update-radio-button:' + this.group, {detail: {emitter: this.id}})
              );
            }
          }
        }
      },
      uncheckValue({detail: {emitter}}) {
        if (emitter === this.id) {
          return;
        }
        this.$_value = false;
      },
    },
    created() {
      if (this.group) {
        document.addEventListener('update-radio-button:' + this.group, this.uncheckValue);
      }
    },
    beforeUnmount() {
      if (this.group) {
        document.removeEventListener('update-radio-button:' + this.group, this.uncheckValue);
      }
    },
  };
</script>

<style lang="scss" scoped>
  @import '@veasel/core';

  .default {
    display: flex;
    justify-content: flex-start;

    .radio {
      background: var(--background-main);
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 50%;
      position: relative;
      box-sizing: border-box;
      border: 1px solid var(--secondary);
      transition: ease border-color $transition-time;

      &.active {
        border: 0.4rem solid var(--active);
      }

      &.active:hover {
        border: 0.325rem solid var(--active);
      }
    }

    .label {
      @include base-small;

      margin-left: get-spacing('s');
    }

    &.small {
      .radio {
        width: 1rem;
        height: 1rem;

        &.active {
          border: 0.25rem solid var(--active);
        }
      }
    }

    &.disabled {
      background: transparent;

      .radio {
        border-color: var(--secondary-50);

        &.active {
          border-color: var(--secondary-50);
        }
      }
    }
  }

  .button {
    width: 100%;

    &.small .label {
      height: 24px;
    }

    &.medium .label {
      height: 32px;
    }

    .radio {
      display: none;
    }

    .label {
      @include base;

      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--background-main);
      text-align: center;
      border-radius: $border-radius;
      user-select: none;
      width: 100%;
      transition: $transition-time;

      &:not(.active) {
        background-color: var(--secondary);
      }

      &.active {
        background-color: var(--active);
      }
    }

    &.disabled .label {
      background-color: var(--secondary-50);
    }
  }

  .disabled {
    pointer-events: none;
  }
</style>
