export default {
  props: {
    offset: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      currentGroup: null,
      groupCounts: {},
      allCount: null,
    };
  },
  computed: {
    groupedRows: function () {
      return this.getGroups(this.alteredRows, this.currentGroup && this.currentGroup.toLowerCase());
    },
  },
  methods: {
    /**
     * Group objects in an array by property (new)
     * @param {Array} collection
     * @param {String} property
     * @return {Array} array of arrays of objects
     */
    getGroups: function (collection, property) {
      const results = [];
      const values = [];
      for (let i = 0; i < collection.length; i++) {
        const val = collection[i][property];
        const index = values.indexOf(val);
        if (index > -1) {
          results[index].push(collection[i]);
        } else {
          // Set the group count for group 'pagination'
          if (val) {
            this.groupCounts[val] = this.offset;
          } else {
            this.allCount = this.offset;
          }
          values.push(val);
          results.push([collection[i]]);
        }
      }
      return results;
    },
    filteredGroup: function (group) {
      return group.slice(
        0,
        this.currentGroup ? this.groupCounts[group[0][this.currentGroup.toLowerCase()]] : this.allCount
      );
    },
  },
};
