<template>
  <div class="s-loading-panel relative theme-style-base">
    <div
      v-if="state !== 'completed'"
      class="loading-overlay absolute"
      :class="{striped: striped, background: background}"
    >
      <div v-if="state === 'loading'" class="loading-center">
        <div class="suade-loader">
          <s-loader />
        </div>
      </div>
      <div v-if="state === 'failed'" class="loading-center">
        <s-icon type="warning" color="orange"></s-icon>
      </div>
      <p v-if="state === 'loading' && showText" class="loading-message ellipsis">{{ loadingText }}</p>
      <p v-if="state === 'failed' && showText" class="loading-message ellipsis">Error: {{ error }}</p>
    </div>
  </div>
</template>

<script>
  import icon from '@veasel/base/icon';
  import loader from '@veasel/base/loader';
  import {resize} from '@veasel/core/mixins';

  export default {
    name: 's-loading-panel',
    description: 'A loading panel to handle loading states on a specific part of the screen.',
    components: {
      's-icon': icon,
      's-loader': loader,
    },
    mixins: [resize],
    props: {
      state: {
        description: 'The current state of the panel.',
        type: String,
        values: ['waiting', 'loading', 'failed', 'completed'],
        validator: (val) => ['waiting', 'loading', 'failed', 'completed'].includes(val),
        default: 'waiting',
      },
      error: {
        description: 'The error message to display if state is <em>failed</em>.',
        type: String,
        default: 'An error has been encountered',
      },
      striped: {
        description: 'A flag to display stripes on the panel.',
        type: Boolean,
        default: false,
      },
      showText: {
        description: 'A flag to display or hide state text.',
        type: Boolean,
        default: true,
      },
      widthAdjust: {
        description: 'A way to adjust panel width.',
        type: Number,
        default: -2,
      },
      heightAdjust: {
        description: 'A way to adjust panel height.',
        type: Number,
        default: -2,
      },
      zeroLeft: {
        description: 'A flag to force panel to stick left.',
        type: Boolean,
        default: false,
      },
      loadingText: {
        description: 'Text to display for the loading state',
        type: String,
        default: 'Loading...',
      },
      background: {
        description: 'Flag to show or hide the grey background',
        type: Boolean,
        default: true,
      },
    },
    watch: {
      state: function () {
        this.resize();
      },
    },
    mounted: function () {
      this.resize();
    },
    methods: {
      resize: function () {
        if (this.$el) {
          const loadingPanel = this.$el;
          const loadingOverlay = this.$el.querySelector('.loading-overlay');
          const panelContent = loadingPanel.previousElementSibling;
          if (panelContent) {
            loadingPanel.style.top = panelContent.offsetTop + 'px';
            loadingPanel.style.height = panelContent.offsetHeight + this.heightAdjust + 'px';
            loadingPanel.style.width = panelContent.offsetWidth + this.widthAdjust + 'px';
            if (this.zeroLeft) {
              loadingPanel.style.left = 0;
            }
            if (loadingOverlay) {
              loadingOverlay.style.top = 0;
              loadingOverlay.style.left = 0;
              loadingOverlay.style.height = panelContent.offsetHeight + 'px';
              loadingOverlay.style.width = panelContent.offsetWidth + this.widthAdjust + 'px';
            }
          }
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@veasel/core';

  .s-loading-panel {
    padding: 0;
    position: absolute;
    pointer-events: none;

    .loading-overlay {
      left: 0;
      top: 0;
      position: absolute;
      z-index: 38;
      height: 100%;
      width: 100%;

      &.background {
        box-shadow: inset 0 0 2px 0 $gray;
        background-color: $opacity;
      }

      &.striped {
        background-image: repeating-linear-gradient(
          -45deg,
          $opacity,
          $opacity,
          20px,
          rgba(120, 120, 120, 0.2) 20px,
          rgba(120, 120, 120, 0.2) 40px
        );
      }

      & .loading-center {
        position: absolute;
        top: calc(50% - 28px);
        left: calc(50% - 28px);
        width: 56px;
        height: 56px;

        & .s-icon {
          font-size: 56px;
        }
      }

      .loading-message {
        width: 100%;
        top: calc(50% + 25px);
        position: absolute;
        text-align: center;
      }
    }
  }
</style>
