export default [
  {name: 'Home', icon: 'house', id: 'home', to: {path: '/home'}, match: '/home'},
  {
    name: 'Data',
    icon: 'layers',
    id: 'data',
    to: {path: '/data/overview'},
    match: '/data',
    submenu: [
      {name: 'Data Overview', id: 'data', to: {path: '/data/overview'}},
      {
        name: 'Data Collections',
        id: 'data_collections',
        to: {path: '/data/collection/overview'},
        service: 'data_collections',
      },
      {name: 'Decision Trees', id: 'decision', to: {path: '/data/decision/overview'}},
    ],
  },

  {
    name: 'Reports',
    icon: 'clipboard',
    id: 'report',
    to: {path: '/report/overview'},
    match: '/report',
    submenu: [
      {name: 'Reports Overview', id: 'report', to: {path: '/report/overview'}},
      {name: 'Custom Types', id: 'report_custom', to: {path: '/report/custom/overview'}, service: 'dev'},
      {name: 'Report Collections', id: 'collections', to: {path: '/report/collection/overview'}},
      {name: 'Cell Comparison', id: 'cell_comparison', to: {path: '/report-cell-compare'}},
      {
        name: 'Data Magnets',
        id: 'data_magnets',
        to: {path: '/report/data-magnet/overview'},
        calculators: 'data-magnets',
      },
      {name: 'Rulesets', id: 'rulesets', to: {path: '/report/ruleset/overview'}, calculators: 'rulesets'},
      {
        name: 'Custom Validations',
        id: 'validations',
        to: {path: '/report/validations/overview'},
        service: 'validations',
      },
      {name: 'Templates', id: 'report_templates', to: {path: '/report/template/overview'}},
      {name: 'Submissions', id: 'regulator_submissions', to: {path: '/report/submissions'}},
    ],
  },

  {
    name: 'Regina',
    icon: 'crown',
    id: 'regina',
    to: {path: '/regina/analyser'},
    match: '/regina',
    submenu: [
      {name: 'Analyser', id: 'analyser', to: {path: '/regina/analyser'}},
      {name: 'Product Examples', id: 'product_examples', to: {path: '/regina/product-examples'}},
      {name: 'Drill-up', id: 'drill_up', to: {path: '/regina/drill-up'}},
      {name: 'What-if', id: 'what_if', to: {path: '/regina/what-if'}},
      {name: 'Fireman', id: 'fireman', to: {path: '/regina/fireman'}, service: 'fireman'},
    ],
  },

  {
    name: 'Logic',
    icon: 'bonding',
    id: 'logic',
    to: {path: '/logic/overview'},
    match: '/logic',
    submenu: [
      {name: 'Logic Overview', id: 'logic_overview', to: {path: '/logic/overview'}},
      {name: 'Rules', id: 'rules', to: {path: '/logic/rules'}, calculators: 'rules'},
      {name: 'Custom Rules', id: 'custom_rules', to: {path: '/logic/custom-rules'}, calculators: 'custom-rules'},
    ],
  },

  {
    name: 'System',
    icon: 'options',
    id: 'system',
    to: {path: '/system/monitor/overview'},
    match: '/system',
    submenu: [
      {name: 'Monitor', id: 'monitor', to: {path: '/system/monitor'}, match: '/system/monitor'},
      {name: 'Manage', id: 'manage', to: {path: '/system/manage'}, match: '/system/manage'},
      {name: 'Updates & Releases', id: 'releases', to: {path: '/system/releases'}, match: '/system/releases'},
      {name: 'STARS', id: 'stars', to: {path: '/system/stars'}, match: '/system/stars', service: 'stars'},
    ],
  },
];
