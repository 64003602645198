import {setStorage} from '@veasel/core/tools';

export default {
  emits: ['page-updated'],
  data: function () {
    return {
      currentPage: 1,
    };
  },

  computed: {
    // rows after paginating
    visibleRows: function () {
      if (this.opts.frontend) {
        const from = this.opts.pagination ? this.opts.pagination.offset : 0;
        const to = this.opts.pagination ? this.opts.pagination.offset + this.settings.pageSize : -1;
        return this.sortedRows.slice(from, to);
      } else {
        return this.sortedRows;
      }
    },
    totalVisibleRows: function () {
      return this.visibleRows.filter((item) => item.datalistType !== 'special').length;
    },
  },

  methods: {
    // update page index or page size
    changePage: function (page) {
      this.opts.pagination.offset = (page - 1) * this.settings.pageSize;
      const pagination = {
        limit: this.opts.pagination.limit,
        offset: this.opts.pagination.offset,
        num_entries: this.opts.pagination.num_entries,
      };
      this.updateQuery();
      this.$emit(this.opts.pagination.event, pagination, page);
      this.$nextTick(() => {
        this.$refs.datalist.scrollIntoView();
      });
    },
    updateRowsOnPage() {
      this.changePage(1);
      if (this.opts.settings.storeSettings) {
        setStorage('datatable-pagination-limit-' + this.opts.settings.storeSettings, this.opts.pagination.limit);
      }
    },
    // meant to be called from outside the component
    // getInitialPagination: function(){
    //   return {
    //     limit: this.opts.pagination.limit || 10,
    //     offset: this.opts.pagination.offset || 0,
    //     total: this.opts.pagination.total || 0,
    //   }
    // },
  },
};
