import {Roles, Fields, Placeholders, HintMessages} from './optionsConst';

// TODO: keep only the generic step with the generic props value (ex: sl_value_*)

// generic steps options overidable by 'options' prop
const OPTIONS_HELPER = {
  // options for the column to filter on selection
  sl_column_filter: {
    role: Roles.FIELD,
    field: Fields.SELECT,
    placeholder: Placeholders.COLUMN,
    hint: HintMessages.FILTER_COLUMNS,
    required: true,
    // options: [{ key: 'account_ids', label: 'Account Ids', NEXT_STEP: 'sl_value_text' }]
  },

  // options for the column to group by on
  sl_column_group: {
    role: Roles.GROUPED_COLUMN,
    field: Fields.SELECT,
    placeholder: Placeholders.COLUMN,
    hint: HintMessages.GROUP_COLUMN,
    // options: [{ key: 'account_ids', label: 'Account Ids' }]
  },

  // options for the column to aggregate on
  sl_column_aggregate: {
    role: Roles.FIELD,
    field: Fields.SELECT,
    placeholder: Placeholders.COLUMN,
    hint: HintMessages.AGG_COLUMN,
    // options: [{ key: 'account_ids', label: 'Account Ids' }]
    NEXT_STEP: 'sl_aggregation_selection', // same next step for each value selected
  },

  // options for each filter type selection step
  // filter base step options
  sl_filter: {
    role: Roles.OPERATOR,
    field: Fields.SELECT,
    highlight: true,
    placeholder: Placeholders.FILTER,
    hint: HintMessages.FILTER_FILTER,
  },

  sl_filter_text: {
    INHERITED_STEP: 'sl_filter',
    options: [
      {key: 'in', label: 'equals', NEXT_STEP: {INHERITED_STEP: 'sl_value_text', multiple: true}},
      {key: 'not_in', label: 'not equals', NEXT_STEP: {INHERITED_STEP: 'sl_value_text', multiple: true}},
      {key: 'is_null', label: 'is null'},
      {key: 'is_not_null', label: 'is not null'},
    ],
    substitute: [
      {replace: 'equal', with: 'in', makeArray: true},
      {replace: 'not_equal', with: 'not_in', makeArray: true},
    ],
  },
  sl_filter_float: {
    INHERITED_STEP: 'sl_filter',
    options: [
      {
        key: 'in',
        label: 'equals',
        NEXT_STEP: {INHERITED_STEP: 'sl_value_number', multiple: true, hint: HintMessages.FILTER_VALUE.MANY_FLOAT},
      },
      {
        key: 'not_in',
        label: 'not equals',
        NEXT_STEP: {INHERITED_STEP: 'sl_value_number', multiple: true, hint: HintMessages.FILTER_VALUE.MANY_FLOAT},
      },
      {key: 'greater', label: 'greater than', NEXT_STEP: 'sl_value_number'},
      {key: 'less', label: 'less than', NEXT_STEP: 'sl_value_number'},
      {key: 'greater_or_equal', label: 'greater or equal to', NEXT_STEP: 'sl_value_number'},
      {key: 'less_or_equal', label: 'less or equal to', NEXT_STEP: 'sl_value_number'},
      {
        key: 'between',
        label: 'between',
        NEXT_STEP: {INHERITED_STEP: 'sl_value_number', multiple: 2, separator: ' and '},
      },
      {key: 'is_null', label: 'is null'},
      {key: 'is_not_null', label: 'is not null'},
    ],
    substitute: [
      {replace: 'equal', with: 'in', makeArray: true},
      {replace: 'not_equal', with: 'not_in', makeArray: true},
    ],
  },
  sl_filter_float2d: {
    INHERITED_STEP: 'sl_filter',
    options: [
      {
        key: 'in',
        label: 'equals',
        NEXT_STEP: {INHERITED_STEP: 'sl_value_float2d', multiple: true, hint: HintMessages.FILTER_VALUE.MANY_FLOAT},
      },
      {
        key: 'not_in',
        label: 'not equals',
        NEXT_STEP: {INHERITED_STEP: 'sl_value_float2d', multiple: true, hint: HintMessages.FILTER_VALUE.MANY_FLOAT},
      },
      {key: 'greater', label: 'greater than', NEXT_STEP: 'sl_value_float2d'},
      {key: 'less', label: 'less than', NEXT_STEP: 'sl_value_float2d'},
      {key: 'greater_or_equal', label: 'greater or equal to', NEXT_STEP: 'sl_value_float2d'},
      {key: 'less_or_equal', label: 'less or equal to', NEXT_STEP: 'sl_value_float2d'},
      {
        key: 'between',
        label: 'between',
        NEXT_STEP: {INHERITED_STEP: 'sl_value_float2d', multiple: 2, separator: ' and '},
      },
      {key: 'is_null', label: 'is null'},
      {key: 'is_not_null', label: 'is not null'},
    ],
    substitute: [
      {replace: 'equal', with: 'in', makeArray: true},
      {replace: 'not_equal', with: 'not_in', makeArray: true},
    ],
  },
  sl_filter_integer: {
    INHERITED_STEP: 'sl_filter',
    options: [
      {
        key: 'in',
        label: 'equals',
        NEXT_STEP: {
          INHERITED_STEP: 'sl_value_number',
          type: 'int',
          multiple: true,
          hint: HintMessages.FILTER_VALUE.MANY_FLOAT,
        },
      },
      {
        key: 'not_in',
        label: 'not equals',
        NEXT_STEP: {
          INHERITED_STEP: 'sl_value_number',
          type: 'int',
          multiple: true,
          hint: HintMessages.FILTER_VALUE.MANY_FLOAT,
        },
      },
      {key: 'greater', label: 'greater than', NEXT_STEP: {INHERITED_STEP: 'sl_value_number', type: 'int'}},
      {key: 'less', label: 'less than', NEXT_STEP: {INHERITED_STEP: 'sl_value_number', type: 'int'}},
      {
        key: 'greater_or_equal',
        label: 'greater or equal to',
        NEXT_STEP: {INHERITED_STEP: 'sl_value_number', type: 'int'},
      },
      {key: 'less_or_equal', label: 'less or equal to', NEXT_STEP: {INHERITED_STEP: 'sl_value_number', type: 'int'}},
      {
        key: 'between',
        label: 'between',
        NEXT_STEP: {INHERITED_STEP: 'sl_value_number', type: 'int', multiple: 2, separator: ' and '},
      },
      {key: 'is_null', label: 'is null'},
      {key: 'is_not_null', label: 'is not null'},
    ],
    substitute: [
      {replace: 'equal', with: 'in', makeArray: true},
      {replace: 'not_equal', with: 'not_in', makeArray: true},
    ],
  },
  sl_filter_date: {
    INHERITED_STEP: 'sl_filter',
    options: [
      {key: 'equal', label: 'equals', NEXT_STEP: 'sl_value_date'},
      {key: 'less', label: 'before', NEXT_STEP: 'sl_value_date'},
      {key: 'greater', label: 'after', NEXT_STEP: 'sl_value_date'},
      {
        key: 'between',
        label: 'between',
        NEXT_STEP: {
          INHERITED_STEP: 'sl_value_date',
          multiple: 2,
          separator: ' and ',
        },
      },
      {key: 'is_null', label: 'is null', makeArray: true},
      {key: 'is_not_null', label: 'is not null', makeArray: true},
    ],
  },
  sl_filter_enum: (enumOptions, customLabel, hint) => ({
    INHERITED_STEP: 'sl_filter',
    options: [
      {
        key: 'in',
        label: 'equals',
        NEXT_STEP: {
          INHERITED_STEP: 'sl_value_enum',
          options: enumOptions,
          customLabel: customLabel,
          hint: hint,
          multiple: true,
        },
      },
      {
        key: 'not_in',
        label: 'not equals',
        NEXT_STEP: {
          INHERITED_STEP: 'sl_value_enum',
          options: enumOptions,
          customLabel: customLabel,
          hint: hint,
          multiple: true,
        },
      },
      {key: 'is_null', label: 'is null'},
      {key: 'is_not_null', label: 'is not null'},
    ],
    substitute: [
      {replace: 'equal', with: 'in', makeArray: true},
      {replace: 'not_equal', with: 'not_in', makeArray: true},
    ],
  }),
  sl_filter_bool: {
    INHERITED_STEP: 'sl_filter',
    options: [
      {key: 'is_true', label: 'is true'},
      {key: 'is_false', label: 'is false'},
      {key: 'is_null', label: 'is null'},
      {key: 'is_not_null', label: 'is not null'},
    ],
  },
  sl_filter_array: (valueHelper) => ({
    INHERITED_STEP: 'sl_filter',
    options: [{key: 'in', label: 'in', NEXT_STEP: {INHERITED_STEP: valueHelper, multiple: true}}],
  }),

  // options for each value type step

  sl_value_text: {
    role: Roles.VALUE,
    field: Fields.TEXT,
    placeholder: Placeholders.MULTIPLE_STRING,
    hint: HintMessages.FILTER_VALUE.MANY_STRING,
  },
  sl_value_enum: {
    role: Roles.VALUE,
    field: Fields.SELECT,
    placeholder: Placeholders.MULTIPLE_STRING,
    hint: HintMessages.FILTER_VALUE.MANY_STRING,
    // options: [{ key: 'account_ids', label: 'Account Ids', NEXT_STEP: 'sl_value_text' }] select options must be set in 'options' prop
  },
  sl_value_number: {
    role: Roles.VALUE,
    field: Fields.NUMBER,
    placeholder: Placeholders.NUMBER,
    hint: HintMessages.FILTER_VALUE.ONE_FLOAT,
    type: 'float',
  },
  sl_value_int: {
    INHERITED_STEP: 'sl_value_number',
    type: 'int',
    placeholder: Placeholders.NUMBER,
    hint: HintMessages.FILTER_VALUE.ONE_INTEGER,
  },
  sl_value_float2d: {
    INHERITED_STEP: 'sl_value_number',
    pattern: 'float2d',
    errorMessage: 'Number must have a maximum of 2 decimal places',
  },
  sl_value_date: {
    role: Roles.VALUE,
    field: Fields.DATE,
    placeholder: Placeholders.DATE,
    hint: HintMessages.FILTER_VALUE.ONE_DATE,
    format: 'YYYY-MM-DD',
  },
  sl_value_datetime: {
    role: Roles.VALUE,
    field: Fields.DATE,
    placeholder: Placeholders.DATE,
    hint: HintMessages.FILTER_VALUE.ONE_DATE,
    format: 'DD/MM/YYYY HH:mm',
  },
  sl_value_bool: {
    role: Roles.VALUE,
    field: Fields.SELECT,
    type: 'bool',
    placeholder: Placeholders.FILTER,
    options: [{key: 'true', label: 'True'}],
  },

  // options for the aggregation types
  sl_aggregation_selection: {
    role: Roles.AGGREGATION_FUN,
    field: Fields.SELECT,
    placeholder: Placeholders.FILTER,
    hint: HintMessages.AGG_TYPE,
    options: [
      {key: 'sum', label: 'total'},
      {key: 'min', label: 'min'},
      {key: 'max', label: 'max'},
      {key: 'avg', label: 'average'},
    ],
  },
};

export default OPTIONS_HELPER;
