<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="16"
    height="10"
    viewBox="0 0 16 10"
  >
    <defs>
      <clipPath :id="clipPathID">
        <rect width="16" height="10" transform="translate(900 1414)" :fill="color" stroke="#707070" stroke-width="1" />
      </clipPath>
      <filter :id="filterID" x="-7.5" y="-8.5" width="31" height="29" filterUnits="userSpaceOnUse">
        <feOffset input="SourceAlpha" />
        <feGaussianBlur stdDeviation="3.5" result="blur" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g transform="translate(-900 -1414)" :clip-path="'url(#' + clipPathID + ')'">
      <g transform="matrix(1, 0, 0, 1, 900, 1414)" :filter="'url(#' + filterID + ')'">
        <path d="M5,0l5,8H0Z" transform="translate(3 2)" :fill="color" />
      </g>
    </g>
  </svg>
</template>

<script>
  import {uniqId} from '@veasel/core/tools';

  export default {
    name: 'Caret',
    props: {
      color: {
        type: String,
        default: '#fff',
      },
    },
    created() {
      this.filterID = uniqId('clipPath-', 1);
      this.clipPathID = uniqId('filter-', 1);
    },
    data() {
      return {
        filterID: '',
        clipPathID: '',
      };
    },
  };
</script>
