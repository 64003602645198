export default {
  methods: {
    hoverElem($event) {
      let parenthesisCounter = 0;
      let nodeToHighlight;
      const index = parseInt($event.target.dataset.index);
      if ($event.target.className.includes('groupStart')) {
        const nodes = this.$el.querySelectorAll('.element.groupStart, .element.groupEnd');
        for (let i = 1; i < nodes.length; i++) {
          const node = nodes[i];
          if (index >= parseInt(node.dataset.index)) {
            continue;
          }
          if (node.className.includes('groupStart')) {
            parenthesisCounter++;
          } else if (node.className.includes('groupEnd')) {
            if (parenthesisCounter === 0) {
              nodeToHighlight = node;
              break;
            } else {
              parenthesisCounter--;
            }
          }
        }
      } else if ($event.target.className.includes('groupEnd')) {
        const nodes = this.$el.querySelectorAll('.element.groupStart, .element.groupEnd');
        for (let i = nodes.length - 1; i >= 0; i--) {
          const node = nodes[i];
          if (index <= parseInt(node.dataset.index)) {
            continue;
          }
          if (node.className.includes('groupEnd')) {
            parenthesisCounter++;
          } else if (node.className.includes('groupStart')) {
            if (parenthesisCounter === 0) {
              nodeToHighlight = node;
              break;
            } else {
              parenthesisCounter--;
            }
          }
        }
      }
      if (nodeToHighlight) {
        nodeToHighlight.classList.add('highlight');
        $event.target.classList.add('highlight');
      }
    },

    unhoverElem() {
      [].forEach.call(this.$el.querySelectorAll('.element.highlight'), function (el) {
        el.classList.remove('highlight');
      });
    },
  },
};
