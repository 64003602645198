/**
 * Allow a component to call a specific function or emit an event when user paste data into the input
 * @param {boolean} required
 * @return {object}
 */
const onPaste = function (required = false) {
  return {
    props: {
      onPaste: {
        description: 'Function called or event emitted on input paste',
        type: [Function, String],
        required: required,
      },
    },
    methods: {
      $_onPaste: function (event) {
        if (typeof this.onPaste == 'function') {
          this.onPaste(event);
        } else if (typeof this.onPaste == 'string') {
          this.$el.dispatchEvent(new Event(this.onPaste));
        } else if (required) {
          console.error(this.$options.name + ' requires onPaste prop');
        }
      },
    },
  };
};

export default onPaste;
