<template>
  <div class="outer-container">
    <div class="inner-container">
      <ul class="tree">
        <tree-node
          v-for="opt in nestedOptions"
          :ref="'node-' + opt.key"
          :key="opt.key"
          :node="opt"
          :state="model"
          root
          :defaultCategory="defaultCategory"
          :logicMod="logicMod"
          :disabled="disabled"
          @node-updated="nodeUpdated"
        ></tree-node>
      </ul>
    </div>
  </div>
</template>

<script>
  import {syncValue, onChange, id, name, readonly, isDisabled} from '@veasel/core/mixins';
  import TreeNode from './treeNode.vue';

  export default {
    name: 's-tree-selector',
    description: 'A user input for selecting options organised in a tree.',
    components: {
      TreeNode,
    },
    mixins: [syncValue(Object), onChange(), id, name, readonly, isDisabled],
    props: {
      options: {
        type: Array,
        description: 'The tree array containing all branches and options',
        default: () => [],
      },
      categories: {
        type: Boolean,
        description: 'The flag to organise the results by categories',
        default: true,
      },
      defaultCategory: {
        type: String,
        description: 'The default category if there are more than one',
        default: 'others',
      },
      logicMod: {
        type: String,
        description: 'The Logic mode for parent/children interactions',
        validator: (value) => ['none', 'child-needs-parent', 'parent-needs-children'].includes(value),
        default: 'none',
      },
      disabled: {
        type: Boolean,
        description: 'The flag to disable the whole input',
        default: false,
      },
      label: {
        type: String,
        description: 'The label to display on the root node',
        default: '/',
      },
    },

    data() {
      return {
        model: {},
      };
    },

    computed: {
      nestedOptions() {
        return [
          {
            label: this.label || ' ',
            key: '__root__',
            checkable: false,
            children: this.options,
          },
        ];
      },
    },

    watch: {
      $_value: {
        handler(value) {
          this.model = this.parse(value);
        },
        immediate: true,
        deep: true,
      },
    },

    methods: {
      nodeUpdated({category, key, flag}) {
        if (key === '__root__') {
          return;
        }
        if (!this.model) {
          this.model = {};
        }
        if (!this.model[category]) {
          this.model[category] = {};
        }
        this.model[category][key] = flag;
        this.$_value = this.format(this.model);
      },
      format(internal) {
        let result;
        if (!internal) {
          result = {};
        } else {
          result = Object.keys(internal)
            .map((key) => ({
              [key]: Object.keys(internal[key])
                .sort()
                .filter((k) => internal[key][k]),
            }))
            .reduce((acc, p) => ({...acc, ...p}), {});
        }
        if (this.categories) {
          return result;
        } else {
          return result[this.defaultCategory];
        }
      },
      parse(external) {
        let result;
        if (!external) {
          result = {};
        } else {
          result = Object.keys(external)
            .map((key) => ({
              [key]: external[key].reduce((acc, k) => ({...acc, [k]: true}), {}),
            }))
            .reduce((acc, p) => ({...acc, ...p}), {});
        }
        if (this.categories) {
          return result;
        } else {
          return {[this.defaultCategory]: result};
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@veasel/core';
  $input-box-shadow-focus: 0 0 6px #00000029;

  .outer-container {
    @include base;

    overflow: auto;
    min-height: 300px;
    min-width: 300px;
    background-color: var(--secondary-light);
    padding: 0.75rem 0.25rem;
    transition: ease background $transition-time, ease box-shadow $transition-time;
    border: none;
    border-radius: $button-radius;
    box-shadow: 0 0 0 #00000029;

    &:hover {
      background: var(--background-main);
      -webkit-box-shadow: $input-box-shadow-focus;
      box-shadow: $input-box-shadow-focus;
    }

    .inner-container {
      min-height: 100%;
      transition: 0.2s all ease;
    }
  }
</style>
