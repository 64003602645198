<template>
  <s-input-wrapper v-bind="$props" :class="['design-style-' + designStyle]">
    <input
      :id="id"
      ref="input"
      v-model="$_value"
      :name="name"
      :type="type"
      :placeholder="_placeholder"
      :autocomplete="autocomplete"
      :maxlength="maxlength"
      :disabled="disabled"
      :readonly="readonly"
      :class="['s-input-group-field', password ? 'v-record-mask' : '']"
      @change="$_onChange"
      @click="$_onClick"
      @keydown="$_onKeyPressed"
      @focus="$_onFocus"
      @blur="$_onBlur"
      @paste="$_onPaste"
      @animationstart="animationStart"
    />
    <s-icon v-if="designStyle === 'search'" type="search" class="search-icon" size="17" />
  </s-input-wrapper>
</template>

<script>
  import SInputWrapper from '@veasel/inputs/input-wrapper';
  import {
    syncValue,
    onChange,
    onClick,
    onBlur,
    onFocus,
    onKeyPressed,
    onPaste,
    id,
    name,
    readonly,
    placeholder,
    isDisabled,
    isRequired,
    pattern,
    maxLength,
    helperMessages,
  } from '@veasel/core/mixins';

  export default {
    name: 's-input-text',
    description: 'A user input for text value.',
    components: {
      SInputWrapper,
    },
    mixins: [
      syncValue(String),
      onChange(),
      onClick(),
      onBlur(),
      onFocus(),
      onKeyPressed(),
      onPaste(),
      id,
      name,
      readonly,
      placeholder,
      isDisabled,
      isRequired,
      pattern,
      maxLength,
      helperMessages,
    ],
    props: {
      autocomplete: {
        description: 'A flag to allow browser auto-completion.',
        type: String,
        default: 'off',
      },
      password: {
        description: 'A flag to hide the content of the input for password field.',
        type: Boolean,
      },
      designStyle: {
        description: 'Design of the text input',
        type: String,
        values: ['legacy', 'search', 'standard'],
        validator: (val) => ['legacy', 'search', 'standard'].includes(val),
        default: 'legacy',
      },
    },
    data: function () {
      return {
        inputType: 'text',
      };
    },
    computed: {
      type: function () {
        return this.password ? 'password' : 'text';
      },
    },
    created() {
      const truncate = (val) =>
        val === undefined || val === null || this.maxlength === -1 ? val : val.substring(0, this.maxlength);

      this.$data.$_formatersValue.push(truncate);
    },
  };
</script>

<style lang="scss" scoped>
  @import '@veasel/core';

  .design-style-search {
    input.s-input-group-field {
      @include caption;

      border: 2px solid var(--background-secondary);
      background: var(--background-secondary);
      text-indent: 29px;
      box-sizing: content-box;
      min-height: 26px;
      transition: ease all $transition-time;

      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--secondary);
        opacity: 1; /* Firefox */
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: var(--secondary);
      }

      &:focus {
        border: 2px solid var(--active);
        background: var(--background-main);

        + .search-icon ::v-deep(.svg-icon.icon) {
          color: var(--active);
        }
      }
    }

    .search-icon {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: get-spacing('s');
      transform: translate(0, -50%);
      margin-top: 1px;
      width: 17px;

      ::v-deep(.svg-icon.icon) {
        transition: ease all $transition-time;
        fill: var(--secondary-50);
      }
    }
  }
</style>
