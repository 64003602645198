import {rgbToHex} from '@veasel/core/tools';

export default {
  methods: {
    excelExport: function (name, offset, includeCurrency = true) {
      const TOP_OFFSET = offset + 2 || 2;
      const payload = {
        rows: [],
        mergedCells: [],
        styledCells: [],
        name: name || 'Aggregation Export',
        auto_column_width: true,
      };

      // Build Titles
      const drilledBy = [];

      // build table header line
      payload.rows[TOP_OFFSET + 1] = [];
      const columns = this.cleanQuery.groups.concat(this.cleanQuery.aggregates);
      for (const columnsKey in columns) {
        payload.rows[TOP_OFFSET + 1].push(columns[columnsKey].title);
        drilledBy.push(columns[columnsKey].title);
        payload.styledCells.push({
          col: parseInt(columnsKey),
          row: TOP_OFFSET + 1,
          background: '#eeeeee',
          bold: 'true',
          alignV: 'top',
        });
      }
      payload.rows[0] = ['Drill by:', drilledBy.join(' > ')];
      payload.styledCells.push(
        {
          col: 0,
          row: 0,
          bold: true,
        },
        {
          col: 0,
          row: 1,
          bold: true,
        }
      );
      payload.rows[1] = ['Aggregate On:', this.aggregateHeaders.map((el) => el.title).join(', ')];

      // build table content (group columns)
      for (const columnIndex in this.cleanQuery.groups) {
        const listOfCell = this.$el.querySelectorAll(
          '[agg-index^="' + columnIndex + '_"]:not(.agg-cell), [empty-col-index="' + columnIndex + '"]'
        );
        let rowIndex = TOP_OFFSET + 2;
        listOfCell.forEach(function (el) {
          const length = el.querySelectorAll('.agg-cell[col-index="0"]').length;
          const value = el.querySelector('.group-cell>.group-title')?.textContent || '';
          const backgroundColor = rgbToHex(getComputedStyle(el)?.backgroundColor);
          payload.styledCells.push({
            col: parseInt(columnIndex),
            row: rowIndex,
            background: backgroundColor,
            alignV: 'top',
          });
          if (length === 0) {
            payload.rows[rowIndex][columnIndex] = value;
            rowIndex++;
          } else {
            if (length > 1) {
              payload.mergedCells.push({col: parseInt(columnIndex), row: rowIndex, rowspan: length, colspan: 1});
            }
            for (let j = 0; j < length; j++) {
              if (!payload.rows[rowIndex]) {
                payload.rows[rowIndex] = [];
              }
              payload.rows[rowIndex][columnIndex] = value;
              rowIndex++;
            }
          }
        });
      }
      // build table content (results columns)
      const groupColsOffset = this.cleanQuery.groups.length;
      for (const i in this.cleanQuery.aggregates) {
        let rowIndex = TOP_OFFSET + 2;
        const listOfCell = this.$el.querySelectorAll('[col-index="' + i + '"].agg-cell');
        listOfCell.forEach(function (el) {
          let value = el.textContent;

          // If we don't want to include the currency, we want to remove the 3 letter currency.
          if (includeCurrency === false) {
            value = value.replace(/[a-z]{3}\s/gim, '');
          }

          const backgroundColor = rgbToHex(getComputedStyle(el).backgroundColor);
          payload.styledCells.push({
            col: parseInt(i) + groupColsOffset,
            row: rowIndex,
            background: backgroundColor,
            alignH: 'right',
          });
          payload.rows[rowIndex][parseInt(i) + groupColsOffset] = value.trim();
          rowIndex++;
        });
      }

      payload.rows[TOP_OFFSET] = []; // Clean out the top offset row
      return payload;
    },
  },
};
