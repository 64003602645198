/**
 * The consolidateDomains function takes in an array of different series data and outputs an array of objects that contain
 * the range to use for each series along with updated min, max, type meta data.
 * The ranges are compared against each other to see a new should be created for sets that fall within a similar range.
 * @param {Array} series is an object array representing all the series sets of data coupled with the series name.
 * @return Array of Objects consisting of data and range to use for each series.
 * @example consolidateDomains([{name: 'series1', data: [1,1000]}, {name: 'series2', data: [200, 300]}])
 */

function consolidateDomains(series) {
  let isRightAxis = true;

  const domainsPerAxis = [];

  for (const match of series) {
    matchesExistingSeries(series, domainsPerAxis, match);
  }

  for (const range of domainsPerAxis) {
    if (typeof range.domain !== 'string') {
      isRightAxis = !isRightAxis;
      const min = Math.min(preventInfinity(range.min), preventInfinity(range.domain[0]));
      const max = Math.max(range.max, range.domain[1]);
      range.domain = [min, max];
      range.right = isRightAxis;
    }
  }

  return domainsPerAxis;
}

/**
 * The matchesExistingSeries function does the work to compare the series and ranges against each other and
 * populates the domainsPerAxis array with the range Objects.
 * @param {Array} series is an object array representing all the series sets of data coupled with the series name.
 * @param {Array} domainsPerAxis Array of Objects consisting of the chart type, min and max y domain values
 * @param {Array} match is the series that will be compared against the original
 */
function matchesExistingSeries(series, domainsPerAxis, match) {
  const S2Min = preventZero(Math.min(...match.data));
  const S2Max = Math.max(...match.data);

  if (domainsPerAxis.length === 0) {
    // Need Math.abs specifically here and below to prevent negative numbers skewing the range data.
    // NB: We still need the negative values when assigning the min and max values as well as final domain value.
    const X = Math.abs(S2Max) / Math.abs(S2Min);

    const S2MinClean = preventInfinity(S2Min);
    const type = X < 1000 ? 'linear' : 'log';
    domainsPerAxis.push({domain: [S2MinClean, S2Max], type, min: S2MinClean, max: S2Max});
  } else {
    for (let i = 0; i < domainsPerAxis.length; i++) {
      const range = domainsPerAxis[i];
      const S1Min = preventZero(range.min);
      const S1Max = range.max;
      const X = Math.max(Math.abs(S1Max), Math.abs(S2Max)) / Math.min(Math.abs(S1Min), Math.abs(S2Min));

      if (fallsIntoStoredRange(X, range)) {
        range.min = Math.min(preventInfinity(S1Min), preventInfinity(S2Min));
        range.max = Math.max(S1Max, S2Max);

        domainsPerAxis.push({
          domain: series[i].name,
          type: range.type,
          min: range.min,
          max: range.max,
        });
        return;
      }
    }

    const type = S2Max / S2Min < 1000 ? 'linear' : 'log';
    domainsPerAxis.push({domain: [S2Min, S2Max], type, min: S2Min, max: S2Max});
  }
}

function fallsIntoStoredRange(X, range) {
  return (X < 1000 && range.type === 'linear') || (X < 1000000 && range.type === 'log');
}

function preventInfinity(num) {
  return num === Infinity ? 0 : num;
}

function preventZero(num) {
  return num === 0 ? Infinity : num;
}

export default consolidateDomains;
