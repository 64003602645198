<template>
  <div class="s-par-coords">
    <div :id="uniqID" style="width: 100%; height: 800px"></div>
  </div>
</template>

<script>
  import * as d3 from 'd3';
  import * as d3Sankey from 'd3-sankey';

  import {uniqId} from '@veasel/core/tools';

  const DEFAULT_OPTIONS = {
    width: '100%',
    marginTop: 25,
    marginRight: 10,
    marginBottom: 30,
    marginLeft: 10,
    composite: 'darker',
    renderMode: 'queue',
    renderRate: 50,
    colors: ['red', 'orange', 'green'],
    alpha: 0.2,
    brush: true,
    brushMode: '1D-axes',
    reorder: true,
    dynamicColor: true,
  };

  export default {
    name: 's-sankey',

    description:
      'A graph to display data as lines over many dimensions. Ideal to identify relations and tendancies in large dataset (based on d3.js, dc and parcoord-es).',

    emits: ['sankey-selection'],
    props: {
      data: {
        description: 'The granular data set to display in the parallel coordinates chart.',
        type: Array,
      },
      dimensions: {
        description: 'The list of dimensions (vertical bars).',
        type: Array,
      },
      options: {
        description: 'The options object, to specify advanced features and table style.',
        type: Object,
        default: () => ({}),
      },
    },

    computed: {
      uniqID: () => uniqId('chart_').replace('.', ''),
      // options
      marginTop: function () {
        return this.getDefault('marginTop');
      },
      marginRight: function () {
        return this.getDefault('marginRight');
      },
      marginBottom: function () {
        return this.getDefault('marginBottom');
      },
      marginLeft: function () {
        return this.getDefault('marginLeft');
      },
      composite: function () {
        return this.getDefault('composite');
      },
      renderMode: function () {
        return this.getDefault('renderMode');
      },
      renderRate: function () {
        return this.getDefault('renderRate');
      },
      colors: function () {
        return this.getDefault('colors');
      },
      alpha: function () {
        return this.getDefault('alpha');
      },
      brush: function () {
        return this.getDefault('brush');
      },
      brushMode: function () {
        return this.getDefault('brushMode');
      },
      reorder: function () {
        return this.getDefault('reorder');
      },
      dynamicColor: function () {
        return this.getDefault('dynamicColor');
      },
      // chart size
      width: function () {
        return this.$el.clientWidth;
      },
      height: function () {
        return this.$el.clientHeight;
      },
      // dimensions
      computedDimensions: function () {
        const computed = {};
        for (const i in this.dimensions) {
          const key = typeof this.dimensions[i] === 'string' ? this.dimensions[i] : this.dimensions[i].key;
          computed[key] = {
            title:
              this.dimensions[i].title ||
              (this.dimensions[i].key ? this.dimensions[i].key.toUpperCase() : this.dimensions[i]),
            orient: this.dimensions[i].orient || (i == this.dimensions.length - 1 ? 'right' : 'left'),
            ticks: this.dimensions[i].tickPadding || 6,
            innerTickSize: this.dimensions[i].innerTickSize || 0,
            type: this.dimensions[i].type || typeof this.data[0][this.dimensions[i].key || this.dimensions[i]],
          };
        }
        return computed;
      },
    },
    watch: {
      data: function () {
        this.initSankey();
      },
    },
    mounted: function () {
      this.initSankey();
    },
    methods: {
      // get options from default and passed options
      getDefault: function (key) {
        return this.options[key] !== undefined ? this.options[key] : DEFAULT_OPTIONS[key];
      },
      // init, build, and draw the diagram
      initSankey: function () {
        // clean the canvas layout to avoid duplicated axis
        document.getElementById(this.uniqID).innerHTML = '';

        if (this.data.length) {
          // init the column of reference for the color on the first dimension
          this.referenceDimension = Object.keys(this.computedDimensions)[0];

          console.log('d3.schemeTableau10', d3.schemeTableau10);
          // create color domain for the column
          this.updateColorDomain();

          // build and draw the diagram
          document.getElementById(this.uniqID).append(this.SankeyChart({links: this.data}, this.options));
        }
      },
      // set the color for lines
      colorLines: function (d) {
        return this.colorGradient(d[this.referenceDimension]);
      },
      // update the column of reference and redraw colors
      updateColor: function (dimensionKey) {
        this.referenceDimension = dimensionKey;
        this.updateColorDomain();
        this.parcoords.color(this.colorLines).render();
        this.updateAxisTitle();
      },
      // update color domain according of the axis of the column of reference
      updateColorDomain: function () {
        if (typeof this.data[0][this.referenceDimension] === 'string') {
          this.domain = [...new Set(this.data.map((d) => d[this.referenceDimension]))].sort();
          this.enumDomain = this.domain.slice(0);
          const min = d3.min(this.domain, (_d, i) => i);
          const max = d3.max(this.domain, (_d, i) => i);
          this.buildDomain(min, max);
          const enumColorGradient = d3
            .scaleLinear()
            .domain(this.domain)
            .range(this.colors)
            .interpolate(d3.interpolateLab);
          this.colorGradient = (d) => enumColorGradient(this.enumDomain.indexOf(d));
        } else {
          const min = d3.min(this.data, (d) => d[this.referenceDimension]);
          const max = d3.max(this.data, (d) => d[this.referenceDimension]);
          this.buildDomain(min, max);
          this.colorGradient = d3.scaleLinear().domain(this.domain).range(this.colors).interpolate(d3.interpolateLab);
        }
      },
      // build a domain according to the number of colors
      buildDomain: function (min, max) {
        this.domain = [min, max];
        const additionalSteps = this.colors.length - 1;
        const step = (max - min) / Math.max(1, additionalSteps);
        for (let i = 1; i <= this.colors.length - 2; i++) {
          this.domain.splice(i, 0, min + i * step);
        }
      },
      // highlight axis titles
      updateAxisTitle: function () {
        d3.select('.color-selected').attr('class', 'label');
        d3.select('.label#' + this.referenceDimension).attr('class', 'label color-selected');
      },
      // emit selection after brush filters applied
      emitSelection: function (selection) {
        this.$emit('parcoords-selection', selection);
      },
      // highlight a given row
      highlightRow: function (row) {
        if (this.data.indexOf(row) !== -1) {
          this.parcoords.highlight([row]);
        }
      },
      // reset all brush filters
      resetFilters: function () {
        this.parcoords.data(this.data).brushable().render().updateAxes();
      },
      SankeyChart(
        {
          nodes, // an iterable of node objects (typically [{id}, …]); implied by links if missing
          links, // an iterable of link objects (typically [{source, target}, …])
        },
        {
          format = ',', // a function or format specifier for values in titles
          align = 'justify', // convenience shorthand for nodeAlign
          nodeId = (d) => d.id, // given d in nodes, returns a unique identifier (string)
          nodeGroup, // given d in nodes, returns an (ordinal) value for color
          nodeGroups, // an array of ordinal values representing the node groups
          nodeLabel, // given d in (computed) nodes, text to label the associated rect
          nodeTitle = (d) => `${d.id}\n${format(d.value)}`, // given d in (computed) nodes, hover text
          nodeAlign = align, // Sankey node alignment strategy: left, right, justify, center
          nodeSort, // comparator function to order nodes
          nodeWidth = 15, // width of node rects
          nodePadding = 10, // vertical separation between adjacent nodes
          nodeLabelPadding = 6, // horizontal separation between node and label
          nodeStroke = 'currentColor', // stroke around node rects
          nodeStrokeWidth, // width of stroke around node rects, in pixels
          nodeStrokeOpacity, // opacity of stroke around node rects
          nodeStrokeLinejoin, // line join for stroke around node rects
          linkSource = ({source}) => source, // given d in links, returns a node identifier string
          linkTarget = ({target}) => target, // given d in links, returns a node identifier string
          linkValue = ({value}) => value, // given d in links, returns the quantitative value
          linkPath = d3Sankey.sankeyLinkHorizontal(), // given d in (computed) links, returns the SVG path
          linkTitle = (d) => `${d.source.id} → ${d.target.id}\n${format(d.value)}`, // given d in (computed) links
          linkColor = 'source-target', // source, target, source-target, or static color
          linkStrokeOpacity = 0.3, // link stroke opacity
          linkMixBlendMode = 'multiply', // link blending mode
          colors = d3.schemeTableau10, // array of colors
          width = 1800, // outer width, in pixels
          height = 0, // outer height, in pixels
          marginTop = 5, // top margin, in pixels
          marginRight = 1, // right margin, in pixels
          marginBottom = 5, // bottom margin, in pixels
          marginLeft = 1, // left margin, in pixels
        } = {}
      ) {
        // Convert nodeAlign from a name to a function (since d3-sankey is not part of core d3).
        if (typeof nodeAlign !== 'function')
          nodeAlign =
            {
              left: d3Sankey.sankeyLeft,
              right: d3Sankey.sankeyRight,
              center: d3Sankey.sankeyCenter,
            }[nodeAlign] ?? d3Sankey.sankeyJustify;

        // Compute values.
        const LS = d3.map(links, linkSource).map(intern);
        const LT = d3.map(links, linkTarget).map(intern);
        const LV = d3.map(links, linkValue);
        const OV = d3.map(links, (a) => a).map(intern);
        if (nodes === undefined) nodes = Array.from(d3.union(LS, LT), (id) => ({id}));
        const N = d3.map(nodes, nodeId).map(intern);
        const G = nodeGroup == null ? null : d3.map(nodes, nodeGroup).map(intern);

        // Replace the input nodes and links with mutable objects for the simulation.
        nodes = d3.map(nodes, (_, i) => ({id: N[i]}));
        links = d3.map(links, (_, i) => ({source: LS[i], target: LT[i], value: LV[i], original: OV[i]}));

        // Ignore a group-based linkColor option if no groups are specified.
        if (!G && ['source', 'target', 'source-target'].includes(linkColor)) linkColor = 'currentColor';

        // Compute default domains.
        if (G && nodeGroups === undefined) nodeGroups = G;

        // Construct the scales.
        const color = nodeGroup == null ? null : d3.scaleOrdinal(nodeGroups, colors);

        const computedHeight = height ? height : Math.max(100, nodes.length * 30 + marginTop + marginBottom);

        // Compute the Sankey layout.
        d3Sankey
          .sankey()
          .nodeId(({index: i}) => N[i])
          .nodeAlign(nodeAlign)
          .nodeWidth(nodeWidth)
          .nodePadding(nodePadding)
          .nodeSort(nodeSort)
          .extent([
            [marginLeft, marginTop],
            [width - marginRight, computedHeight - marginBottom],
          ])({nodes, links});

        // Compute titles and labels using layout nodes, so as to access aggregate values.
        if (typeof format !== 'function') format = d3.format(format);
        const Tl = nodeLabel === undefined ? N : nodeLabel == null ? null : d3.map(nodes, nodeLabel);
        const Tt = nodeTitle == null ? null : d3.map(nodes, nodeTitle);
        const Lt = linkTitle == null ? null : d3.map(links, linkTitle);

        // A unique identifier for clip paths (to avoid conflicts).
        const uid = `O-${Math.random().toString(16).slice(2)}`;

        const svg = d3
          .create('svg')
          .attr('width', width)
          .attr('height', computedHeight)
          .attr('viewBox', [0, 0, width, computedHeight])
          .attr('style', 'max-width: 100%; height: auto; height: intrinsic;');

        const node = svg
          .append('g')
          .attr('stroke', nodeStroke)
          .attr('stroke-width', nodeStrokeWidth)
          .attr('stroke-opacity', nodeStrokeOpacity)
          .attr('stroke-linejoin', nodeStrokeLinejoin)
          .selectAll('rect')
          .data(nodes)
          .join('rect')
          .attr('x', (d) => d.x0)
          .attr('y', (d) => d.y0)
          .attr('height', (d) => d.y1 - d.y0)
          .attr('width', (d) => d.x1 - d.x0);

        if (G) node.attr('fill', ({index: i}) => color(G[i]));
        if (Tt) node.append('title').text(({index: i}) => Tt[i]);

        const link = svg
          .append('g')
          .attr('fill', 'none')
          .attr('stroke-opacity', linkStrokeOpacity)
          .selectAll('g')
          .data(links)
          .join('g')
          .style('mix-blend-mode', linkMixBlendMode);

        if (linkColor === 'source-target')
          link
            .append('linearGradient')
            .attr('id', (d) => `${uid}-link-${d.index}`)
            .attr('gradientUnits', 'userSpaceOnUse')
            .attr('x1', (d) => d.source.x1)
            .attr('x2', (d) => d.target.x0)
            .call((gradient) =>
              gradient
                .append('stop')
                .attr('offset', '0%')
                .attr('stop-color', ({source: {index: i}}) => color(G[i]))
            )
            .call((gradient) =>
              gradient
                .append('stop')
                .attr('offset', '100%')
                .attr('stop-color', ({target: {index: i}}) => color(G[i]))
            );

        link
          .append('path')
          .attr('d', linkPath)
          .attr(
            'stroke',
            linkColor === 'source-target'
              ? ({index: i}) => `url(#${uid}-link-${i})`
              : linkColor === 'source'
              ? ({source: {index: i}}) => color(G[i])
              : linkColor === 'target'
              ? ({target: {index: i}}) => color(G[i])
              : linkColor
          )
          .attr('stroke-width', ({width}) => Math.max(1, width))
          .call(Lt ? (path) => path.append('title').text(({index: i}) => Lt[i]) : () => {});

        if (Tl)
          svg
            .append('g')
            .attr('font-family', 'sans-serif')
            .attr('font-size', 14)
            .selectAll('text')
            .data(nodes)
            .join('text')
            .attr('x', (d) => (d.x0 < width / 2 ? d.x1 + nodeLabelPadding : d.x0 - nodeLabelPadding))
            .attr('y', (d) => (d.y1 + d.y0) / 2)
            .attr('dy', '0.35em')
            .attr('text-anchor', (d) => (d.x0 < width / 2 ? 'start' : 'end'))
            .text(({index: i}) => Tl[i]);

        function intern(value) {
          return value !== null && typeof value === 'object' ? value.valueOf() : value;
        }

        return Object.assign(svg.node(), {scales: {color}});
      },
    },
    beforeUnmount() {
      if (this.parcoords) {
        d3.select('#' + this.uniqID)
          .selectAll('div')
          .data(this.data)
          .exit()
          .remove();
      }
    },
  };
</script>

<style lang="scss">
  @import 'parcoord-es/dist/parcoords.css';

  .s-sankey {
    position: relative;
    height: 100%;
    width: 100%;
  }
</style>
