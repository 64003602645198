<template>
  <div class="flex m-x-nxs">
    <s-input-radio
      v-for="(option, index) in options"
      :key="index"
      v-model="inputValues[option.key]"
      :label="option.label"
      :group="uniqueGroup"
      :on-change="() => onInputChange(inputValues[option.key], option)"
      v-bind="config"
      :style="{width: `${100 / options.length}%`}"
      class="m-x-xs"
    />
  </div>
</template>

<script>
  import {uniqId} from '@veasel/core/tools';
  import {syncValue, onChange} from '@veasel/core/mixins';

  export default {
    name: 's-input-radio-group',
    description: 'A user input as a radio button for boolean value.',
    mixins: [syncValue([String, Number, Object]), onChange()],
    props: {
      options: {
        description: 'Key/Label pair for input options',
        type: Array,
        default: () => [],
      },
      config: {
        description: 'Component configuration options: disabled, required, styling, etc',
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        uniqueGroup: uniqId('group_'),
        inputValues: {},
      };
    },
    methods: {
      onInputChange(newState, option) {
        if (this.config.clearOnSelection !== true) {
          if (newState) {
            this.$_value = option;
          } else {
            this.$_value = {};
          }
        } else {
          this.inputValues[option.key] = false;
        }
        this.$_onChange(option);
      },
      updateInputValues(value) {
        if (value && value.key !== undefined) {
          this.inputValues[value.key] = true;
        }
      },
    },
    watch: {
      modelValue: {
        deep: true,
        handler() {
          if (this.modelValue) {
            this.updateInputValues(this.modelValue);
          }
        },
      },
    },
    created() {
      if (this.modelValue) {
        this.updateInputValues(this.modelValue);
      }
    },
  };
</script>
