<template>
  <div class="table-main-container">
    <div v-if="displayHeader" class="heading-row">
      <div v-if="opts.settings.display" class="table-settings">
        <!-- SETTINGS -->
        <!--suppress HtmlDeprecatedAttribute -->
        <s-dropdown ref="tableSettings" button-text="Settings" align="right">
          <div class="settings-content p-s theme-style-base align-left">
            <!-- TABLE COLUMNS -->
            <div class="agg-table-ordering">
              <div class="m-b-xs">Aggregation columns visibility</div>
              <s-dropdown-item v-for="(col, index) in aggregateHeaders" :key="index">
                <s-input-checkbox
                  v-model="col.visible"
                  :label="col.title"
                  size="small"
                  :on-change="() => updateColumnVisibility(index, col)"
                />
              </s-dropdown-item>
              <hr />
            </div>
            <div class="agg-table-settings-buttons">
              <s-button
                size="small"
                :on-click="displayAllColumns"
                text="Display all"
                class="m-r-xs"
                :disable="hasAllColumnsVisible"
              ></s-button>
              <s-button
                size="small"
                :on-click="hideAllColumns"
                text="Hide all"
                :disable="hasAllColumnsHidden"
              ></s-button>
            </div>
          </div>
        </s-dropdown>
      </div>
      <div v-if="opts.customColumns.display" class="table-settings custom-columns margin-right">
        <!-- CUSTOM COLUMNS -->
        <div class="custom-column-adder-container">
          <label class="theme-style-base-alt">Custom Column</label>
          <s-input-select
            v-model="customCol.col1"
            class="column-select m-x-xs"
            :options="aggregateHeaders"
            :label-key="'title'"
            size="small"
            :multiple="false"
            track-by="index"
          />
          <s-input-select
            v-model="customCol.op"
            class="op-select m-x-xs"
            :options="opts.customColumns.operators"
            label-key="key"
            size="small"
            :multiple="false"
            track-by="value"
          />
          <s-input-select
            v-model="customCol.col2"
            class="column-select m-x-xs"
            :options="aggregateHeaders"
            :label-key="'title'"
            size="small"
            :multiple="false"
            track-by="index"
          />
          <s-button
            class="m-l-xs"
            size="small"
            :on-click="() => addCustomColumn(customCol.col1, customCol.op, customCol.col2)"
            text="Add"
            color="theme-secondary"
            :disable="customCol.col1 === undefined || !customCol.op || customCol.col2 === undefined"
          ></s-button>
        </div>
      </div>
    </div>
    <div class="table-container">
      <div class="table-header sticky-header">
        <div ref="headerScroller" class="table-content-outer" :style="{width: containerWidth + 'px'}">
          <div class="table-content-inner">
            <div class="header-group" :style="{width: groupInnerWidth + 'px'}">
              <div
                v-for="(header, index) in groupHeaders"
                :key="index"
                class="header-cell"
                :style="{width: Math.round(groupColWidth) + 'px'}"
                :title="header.title"
                @dblclick="expandAll(index)"
              >
                {{ header.title }}
                <span class="expand-all" :col-index="index" @click="expandAll(index)">
                  <s-icon v-if="expandColumnStatus[index]" type="minus-square" size="14"></s-icon>
                  <s-icon v-else type="plus-square" size="14"></s-icon>
                </span>
              </div>
            </div>
            <div class="header-agg-main-container" :style="{width: aggOuterWidth + 'px'}">
              <div v-if="isAggScrolling" :style="{width: aggOuterWidth + 'px'}">
                <div v-if="isAggScrollLeftAllowed" class="agg-scroller left" @click="scrollAggLeft">
                  <s-icon type="arrow-drop-left" size="16"></s-icon>
                </div>
                <div v-if="isAggScrollRightAllowed" class="agg-scroller right" @click="scrollAggRight">
                  <s-icon type="arrow-drop-right" size="16"></s-icon>
                </div>
              </div>
              <div ref="headerAggScroller" class="header-agg" :style="{width: aggOuterWidth + 'px'}">
                <div class="header-agg-container" :style="{width: aggInnerWidth + 'px'}">
                  <div
                    v-for="(header, index) in visibleAggregates"
                    :key="index"
                    class="header-cell"
                    :style="{width: Math.round(opts.aggColWidth) + 'px'}"
                    :title="header.title"
                  >
                    {{ header.title }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="table-content" :style="{width: containerWidth + 'px'}">
        <div ref="tableScroller" class="table-content-outer" :style="{width: containerWidth + 'px'}">
          <div class="table-content-inner" :style="{width: groupInnerWidth + 'px'}">
            <s-agg-group
              v-for="(group, index) in aggregatedResult"
              :key="index"
              :ref="setAggGroupRefs"
              :data="group"
              :rawData="data[index]"
              :query="cleanQuery"
              :visible-aggregates="visibleAggregates"
              :col-width="Math.round(groupColWidth)"
              :agg-outer-width="aggOuterWidth"
              :agg-inner-width="aggInnerWidth"
              :y="'' + index"
              :options="opts"
              :currentAggScroll="currentAggScroll"
              @group-selected="groupSelected"
              @agg-selected="aggSelected"
              @drill-down="emitDrillDown"
              @collapsed="collapsed"
            ></s-agg-group>
          </div>
        </div>
      </div>

      <div class="table-scroller" :style="{width: containerWidth + 'px'}">
        <div
          ref="scrollScroller"
          class="table-content-outer"
          :style="{width: containerWidth + 'px'}"
          @scroll="(e) => scrollSync(e, 'scroller')"
        >
          <div class="table-content-inner" :style="{width: groupInnerWidth + 'px'}">&nbsp;</div>
        </div>
      </div>

      <div class="table-group-selector" :style="{width: containerWidth + 'px'}">
        <div ref="selectorScroller" class="table-selector-outer" :style="{width: groupOuterWidth + 'px'}">
          <div class="table-selector-inner" :style="{width: groupInnerWidth + 'px'}">
            <div ref="groupSelector" class="selector-frame" :style="{width: groupInnerWidth + 'px'}"></div>
            &nbsp;
          </div>
        </div>
      </div>

      <div class="table-agg-selector">
        <div ref="aggSelector" class="selector-frame"></div>
      </div>

      <div ref="total" class="selector-total">
        <span class="label">Total:</span>
        <span class="value">{{ monetary(totalValue) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  // dependencies
  import {
    uniqId,
    extend,
    humanizeString,
    clone,
    throttle,
    getAllScrollParents,
    getValueAtPath,
  } from '@veasel/core/tools';
  import {monetary} from '@veasel/core/formatters';
  import {resize} from '@veasel/core/mixins';

  // components
  import {dropdown, dropdownItem} from '@veasel/base/dropdown';
  import button from '@veasel/base/button';
  import icon from '@veasel/base/icon';
  import inputCheckbox from '@veasel/inputs/input-checkbox';
  import inputSelect from '@veasel/inputs/input-select';

  import aggGroup from './aggGroup.vue';

  // mixins
  import aggregationLogic from '../utils/aggregationLogic';
  import columnsLogic from '../utils/columnsLogic';
  import selectionLogic from '../utils/selectionLogic';
  import scrollingLogic from '../utils/scrollingLogic';
  import exportLogic from '../utils/exportLogic';

  // Default options, can be overwritten
  const DEFAULT_OPTIONS = {
    isConvertedToCurrency: false,
    currencyToConvertTo: 'GBP',
    total: true,
    stickyHeader: 0,
    stickyScroller: 0,
    stickyAggregation: true,
    minGrpColWidth: 240,
    aggColWidth: 180,
    nullPlaceholder: 'not found',
    undefinedPlaceholder: 'none',
    baseGrayColor: '--secondary-50',
    baseBlueColor: '--secondary-light',
    disabledColor: 'var(--label-1)',
    aggResultColor: '--background-main',
    countColumnColor: '--background-secondary',
    countColumnColorNegative: false,
    aggregationColumnColor: '--light-active',
    aggregationColumnColorNegative: false,
    customColumnColor: '--light-hover',
    customColumnColorNegative: true,
    drillDownOperator: 'equals',
    drillDownValueAsAnArray: false,
    settings: {
      display: true,
    },
    customColumns: {
      display: true,
      operators: [
        {key: 'plus', value: '+'},
        {key: 'minus', value: '-'},
        {key: 'multiplied by', value: 'x'},
        {key: 'divided by', value: '/'},
      ],
    },
  };

  export default {
    name: 's-agg-table',

    description:
      'A dynamic datatable with row aggregation, inspired by Pivot tables. Ideal to group row by column value.',

    components: {
      's-agg-group': aggGroup,
      's-dropdown': dropdown,
      's-dropdown-item': dropdownItem,
      's-button': button,
      's-icon': icon,
      's-input-checkbox': inputCheckbox,
      's-input-select': inputSelect,
    },

    mixins: [aggregationLogic, columnsLogic, selectionLogic, scrollingLogic, exportLogic, resize],

    emits: ['selection', 'drill-down'],

    props: {
      data: {
        description: 'The list of data, nested by groups.',
        type: Array,
        default: () => [],
      }, // array of objects [{colA:'1A', colB:'1B'}, {colA:'2A', colB:'2B'}]
      query: {
        description:
          'The query used to retrieve the nested rows, usefull to know the list of "group by" and "aggregate on" columns.',
        type: Object,
        default: () => ({}),
      }, // see above
      options: {
        description: 'The options object, to specify advanced features and table style.',
        type: Object,
        default: () => ({}),
      }, // see above
    },

    data: function () {
      return {
        containerWidth: 0,
        groupHeaders: [],
        aggregateHeaders: [],
        updatedData: [],
        displayCustom: false,
        aggGroupRefs: [],
      };
    },
    beforeUpdate() {
      // Flush out old references
      this.aggGroupRefs = [];
    },

    computed: {
      opts: function () {
        return extend(DEFAULT_OPTIONS, this.options, {uuid: uniqId('agg-table-')});
      },
      aggregates: function () {
        return this.aggregateHeaders;
      },
      cleanQuery: function () {
        return {
          groups: this.groupHeaders,
          aggregates: this.visibleAggregates,
        };
      },
      displayHeader: function () {
        return this.query.aggregate_on.length > 1;
      },
    },

    watch: {
      // watch sticky top
      'options.stickyHeader': {
        immediate: true,
        handler: function (stickyTop) {
          this.$nextTick(() => {
            if (typeof stickyTop === 'number') {
              this.$el.querySelector('.sticky-header').style.top = stickyTop + 'px';
            } else {
              this.$el.querySelector('.sticky-header').style.top = 0;
            }
          });
        },
      },
      // watch sticky top
      'query.aggregate_on': {
        immediate: true,
        deep: true,
        handler: function () {
          this.aggregateHeaders = this.query.aggregate_on.map((c, i) => ({
            title: (
              (c.aggregation_fun !== 'count' ? humanizeString(c.aggregation_fun) : '') +
              (c.field ? ' ' + humanizeString(c.field) : '')
            ).trim(),
            key: c.field,
            type: c.aggregation_fun,
            visible: true,
            index: '' + i,
            monetary: c.monetary,
          }));
        },
      },

      'query.group_by': {
        immediate: true,
        deep: true,
        handler: function (val) {
          this.groupHeaders = val
            ? val.map((c) => ({
                title: humanizeString(c),
                key: c,
                expandAll: false,
              }))
            : [];
        },
      },
      data: {
        immediate: true,
        deep: true,
        handler: function () {
          this.updatedData = clone(this.data);
          this.emitGlobalGroup();
        },
      },
    },

    mounted: function () {
      this.resize();
      this.throttleMmousemoveHandler = throttle(this.mousemoveHandler, 100);
      this.scrollers = getAllScrollParents(this.$el);
    },

    methods: {
      setAggGroupRefs(el) {
        this.aggGroupRefs.push(el);
      },
      // container resize handler
      resize: function () {
        this.containerWidth = this.$el.offsetWidth;
      },

      // emit an object when group selected
      emitGroup: function (index) {
        const path = index.split('_')[1].replace(/-/g, '.groups.');
        const value = getValueAtPath(this.updatedData, path);
        this.$emit('selection', value);
      },

      // emit an object when group selected
      emitGlobalGroup: function () {
        this.$nextTick(() => {
          if (this.updatedData.length) {
            const value = {
              property: 'Global',
              groups: this.updatedData,
              aggregations: this.updatedData[0].aggregations,
            };
            this.$emit('selection', value);
          }
        });
      },

      // emit list of filters when aggregation cell double clicked
      emitDrillDown: function (index) {
        const yArray = index.split('_')[1].split('-');
        const filters = [];
        for (const i in yArray) {
          const path = yArray.slice(0, parseInt(i) + 1).join('.groups.');
          const value = getValueAtPath(this.updatedData, path);
          if (value.value === null) {
            filters.push(
              clone({
                field: value.property,
                operator: 'is_null',
              })
            );
          } else {
            filters.push(
              clone({
                field: value.property,
                operator: this.opts.drillDownOperator,
                value: this.opts.drillDownValueAsAnArray ? [value.value] : value.value,
              })
            );
          }
        }
        this.$emit('drill-down', filters);
      },
      monetary,
    },
  };
</script>

<style scoped lang="scss">
  @import '@veasel/core';

  .table-main-container {
    user-select: none;
    position: relative;
    @include base;

    // Override default base line-height, otherwise the height of the cells will be bigger than what they are designed for
    line-height: 19px;

    .heading-row {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row-reverse;
      padding-bottom: 20px;

      .table-settings {
        position: relative;

        ::v-deep(.dropdown-content) {
          width: 300px;
          text-align: left;
        }
      }

      .custom-column-adder-container {
        flex-wrap: wrap;
        align-content: space-between;
        align-items: center;
        display: flex;
        justify-content: space-between;

        .column-select {
          width: 240px;
          margin-left: 10px;
          margin-bottom: 10px;
          flex-grow: 1;
        }

        .op-select {
          width: 270px;
          margin-bottom: 10px;
          margin-left: 10px;
          flex-grow: 1;
        }
      }
    }

    .table-container {
      position: relative;
    }

    .table-header {
      display: block;
      position: sticky;
      z-index: 28;
      top: 0;

      .table-content-outer {
        overflow: hidden;
        display: block;
      }

      .header-agg-main-container {
        right: 0;
        position: absolute;

        .agg-scroller {
          position: absolute;
          z-index: 29;
          background-color: var(--secondary-light);
          color: var(--background-main);
          font-size: 16px;
          border: 1px solid var(--main-dark);
          cursor: pointer;
          padding-top: 3px;

          &.left {
            left: 0;
          }

          &.right {
            right: 0;
          }
        }
      }

      .header-group {
        float: left;
        border-top: 1px solid var(--main-dark);
        border-bottom: 1px solid var(--main-dark);
      }

      .header-agg {
        position: absolute;
        right: 0;
        float: left;
        border-top: 1px solid var(--main-dark);
        border-bottom: 1px solid var(--main-dark);
        height: 25px;
        overflow: hidden;
        box-sizing: border-box;

        .header-cell:last-child {
          border-right: 1px solid var(--main-dark);

          .expand-all {
            display: none;
          }
        }
      }

      .header-cell {
        min-height: 22px;
        padding: 2px 15px;
        text-align: center;
        box-sizing: border-box;
        float: left;
        border-left: 1px solid var(--main-dark);
        background-color: var(--secondary-light);
        width: inherit;
        display: block;
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .expand-all {
          cursor: pointer;
          position: absolute;
          left: 5px;
          top: 4px;
        }

        &:last-child {
          .expand-all {
            display: none;
          }
        }
      }
    }

    .table-content {
      .table-content-outer {
        overflow-x: hidden;
        display: block;
      }
    }

    .table-group-selector,
    .table-agg-selector {
      position: absolute;
      pointer-events: none;
      top: 0;
      width: 100%;
      overflow: hidden;
      padding-bottom: 20px;

      .table-selector-outer {
        overflow-x: hidden;
        display: block;
      }

      .selector-frame {
        z-index: 23;
        position: relative;
        content: ' ';
        width: 100%;
        height: 100%;
        box-shadow: 0 0 2px 3px var(--active);
        display: none;
      }
    }

    .table-group-selector {
      z-index: 23;
    }

    .table-agg-selector {
      z-index: 26;
    }

    .selector-total {
      pointer-events: none;
      position: fixed;
      z-index: 29;
      display: none;
      color: #fff;
      padding: 2px 10px;
      box-sizing: border-box;
      background-color: var(--active);
      justify-content: space-between;

      .label {
        text-align: left;
        font-weight: bold;
        margin-right: 2px;
      }

      .value {
        text-align: right;
      }
    }

    .table-scroller {
      display: block;
      position: sticky;
      z-index: 28;
      bottom: 0;

      .table-content-outer {
        overflow-y: hidden;
        overflow-x: auto;
        display: block;
        height: 15px;
      }
    }
  }

  .settings-content hr {
    border-style: solid;
    border-bottom: 0;
    margin: get-spacing('m') 0;
  }

  .agg-table-ordering {
    & ::v-deep(.dropdown-item) {
      padding: 0;
    }
  }
</style>
