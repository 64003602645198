<template>
  <div>
    <s-dropdown
      button-text="Filters"
      button-icon="filtering"
      button-icon-color="theme-active"
      closeOnScroll
      class="list-additional-filter"
      data-cy="datalist-dropdown-filter"
      @change-visible="dropdownVisibilityChange"
    >
      <div
        v-for="(filter, filterIndex) in options"
        :key="filter.key"
        class="filter-menu-item"
        :class="[getDisabledValue(filter.disabled) ? 'filter-disabled' : '']"
        @mouseenter="(event) => showMenu(filterIndex, event)"
      >
        <div
          class="filter-name flex justify-content-space-between align-items-center"
          :class="[showFilterItems[filterIndex] ? 'active' : '']"
        >
          <span class="theme-style-caption">{{ filter.label }}</span>
          <s-icon type="mini-arrow-right" color="theme-secondary-light" />
        </div>
        <div v-if="showFilterItems[filterIndex]" class="filter-options absolute">
          <!-- Data Type is Checkbox -->
          <div v-if="filter.type === 'checkbox'">
            <div v-if="filter.options.length === 0" class="checkbox option-item theme-style-base-small">
              No options currently available
            </div>
            <div v-else-if="filter.options.length < 10" class="m-y-xs">
              <div v-for="key in filter.options" :key="key" class="checkbox option-item">
                <s-input-checkbox
                  v-model="values[filter.key][key]"
                  :label="humanizeCapitalizeString(key)"
                  size="small"
                  :data-cy="'checkbox-' + key.toLowerCase().replace(/ /g, '-')"
                />
              </div>
            </div>
            <div v-else class="option-item select">
              <s-input-select
                v-model="values[filter.key]"
                :options="filter.options.map((o) => ({key: o, label: humanizeCapitalizeString(o)}))"
                track-by="key"
                :placeholder="filter.label"
                multiple
                size="small"
                v-bind="filter.additionalProperties"
                :data-cy="'select-' + filter.label.toLowerCase().replace(' ', '-')"
              />
            </div>
          </div>

          <!-- Data Type is Select -->
          <div v-else-if="filter.type === 'select'" class="option-item select">
            <s-input-select
              v-model="values[filter.key]"
              :options="filter.options"
              trackBy="key"
              :placeholder="filter.label"
              size="small"
              v-bind="filter.additionalProperties"
              :data-cy="'select-' + filter.label.toLowerCase().replace(' ', '-')"
            />
          </div>

          <!-- Data Type is date -->
          <div v-else-if="filter.type === 'date'" class="date">
            <div v-for="dateFilter in dateFilters" :key="dateFilter.key" class="option-item date">
              <s-input-radio
                v-model="values[filter.key].filter[dateFilter.key]"
                :group="'radio-' + filter.key"
                size="small"
                :label="dateFilter.label"
                :data-cy="'radio-' + dateFilter.label.replace(' ', '-')"
              />
              <div v-if="values[filter.key].filter[dateFilter.key]" class="date-picker">
                <template v-if="dateFilter.range">
                  <s-input-datetime
                    v-model="values[filter.key].date_from"
                    label="From"
                    data-cy="filter-date-between-from"
                    :to="values[filter.key].date_to"
                    size="small"
                    class="p-t-s"
                    v-bind="filter.additionalProperties"
                  />
                  <s-input-datetime
                    v-model="values[filter.key].date_to"
                    label="To"
                    class="p-t-s"
                    data-cy="filter-date-between-to"
                    :from="values[filter.key].date_from"
                    size="small"
                    v-bind="filter.additionalProperties"
                  />
                </template>
                <template v-else>
                  <s-input-datetime
                    v-model="values[filter.key].date"
                    class="m-t-s"
                    label="Date"
                    data-cy="filter-date"
                    size="small"
                    overflow="enabled"
                    v-bind="filter.additionalProperties"
                  />
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </s-dropdown>
  </div>
</template>

<script>
  import {clone, isDeepEqual, humanizeCapitalizeString} from '@veasel/core/tools';
  import icon from '@veasel/base/icon';
  import inputCheckbox from '@veasel/inputs/input-checkbox';
  import inputDatetime from '@veasel/inputs/input-datetime';
  import inputRadio from '@veasel/inputs/input-radio';
  import inputSelect from '@veasel/inputs/input-select';
  import parsingMethods from '../utils/parsingMethods';

  export default {
    name: 'filters',
    components: {
      's-icon': icon,
      's-input-checkbox': inputCheckbox,
      's-input-datetime': inputDatetime,
      's-input-radio': inputRadio,
      's-input-select': inputSelect,
    },
    props: {
      modelValue: {
        description: 'Query Filters',
        type: Object,
        default: () => ({}),
      },
      options: {
        description: 'Filter options',
        type: Array,
        default: () => [],
      },
      parsing: {
        description: 'Query Parsing functions',
        type: [Object, String],
        default: () => ({
          parseQuery: (query) => query,
          serializeQuery: (query) => query,
        }),
      },
    },
    emits: ['update:modelValue'],
    data() {
      return {
        values: {},
        dateFilters: [
          {key: 'is', label: 'is'},
          {key: 'is_before', label: 'is before'},
          {key: 'is_after', label: 'is after'},
          {key: 'is_between', label: 'is between', range: true},
        ],
        showFilterItems: [],
      };
    },

    computed: {
      parsingFunc() {
        if (typeof this.parsing === 'string' && parsingMethods[this.parsing.toLowerCase()]) {
          return parsingMethods[this.parsing.toLowerCase()].parseQuery;
        } else {
          return this.parsing.parseQuery || ((query) => query);
        }
      },
      serializingFunc() {
        if (typeof this.parsing === 'string' && parsingMethods[this.parsing.toLowerCase()]) {
          return parsingMethods[this.parsing.toLowerCase()].serializeQuery;
        } else {
          return this.parsing.serializeQuery || ((query) => query);
        }
      },
    },

    watch: {
      modelValue: {
        immediate: true,
        deep: true,
        handler(newValue, oldValue) {
          if (isDeepEqual(newValue, oldValue)) {
            return;
          }
          this.values = this.queryToValuesParser(this.serializingFunc(newValue, this.options), this.options);
        },
      },
      values: {
        deep: true,
        handler(newValue) {
          this.$emit(
            'update:modelValue',
            this.parsingFunc(this.valuesToQueryParser(newValue, this.options), this.options)
          );
        },
      },

      options: {
        deep: true,
        handler() {
          this.values = this.queryToValuesParser(this.serializingFunc(this.modelValue, this.options), this.options);
        },
      },
    },

    methods: {
      wrapProps(filter) {
        return {
          ...filter.additionalProperties,
        };
      },

      queryToValuesParser(query, filters) {
        const values = {};
        for (const i in filters) {
          const filter = filters[i];
          switch (filter.type) {
            case 'checkbox':
              if (filter.options.length < 10) {
                values[filter.key] = filter.options.reduce(
                  (acc, o) => ({...acc, [o]: (query[filter.key] || []).includes(o)}),
                  {}
                );
              } else {
                values[filter.key] = query[filter.key] || [];
              }
              break;
            case 'select':
              if (filter.additionalProperties && filter.additionalProperties.multiple) {
                values[filter.key] = query[filter.key] || null;
              } else {
                values[filter.key] = query[filter.key] || null;
              }
              break;
            case 'date':
              values[filter.key] = {
                filter: this.dateFilters.reduce(
                  (acc, f) => ({...acc, [f.key]: (query[filter.key] || {}).filter === f.key}),
                  {}
                ),
                date:
                  (query[filter.key] && query[filter.key].filter !== 'is_between' && query[filter.key].date) ||
                  undefined,
                date_from:
                  (query[filter.key] && query[filter.key].filter === 'is_between' && query[filter.key].date_from) ||
                  undefined,
                date_to:
                  (query[filter.key] && query[filter.key].filter === 'is_between' && query[filter.key].date_to) ||
                  undefined,
              };
              break;
          }
        }
        return values;
      },

      valuesToQueryParser(values, filters) {
        const query = {};
        for (const i in filters) {
          let dateFilter;
          let expectedKeys;
          const filter = filters[i];
          switch (filter.type) {
            case 'checkbox':
              if (filter.options.length < 10) {
                query[filter.key] = Object.keys(values[filter.key]).filter((k) => values[filter.key][k]);
              } else {
                query[filter.key] = values[filter.key] || undefined;
              }
              query[filter.key] = query[filter.key].length ? query[filter.key] : undefined;
              break;
            case 'select':
              if (filter.additionalProperties && filter.additionalProperties.multiple) {
                query[filter.key] = values[filter.key] && values[filter.key].length ? values[filter.key] : undefined;
              } else {
                query[filter.key] = values[filter.key] || undefined;
              }
              break;
            case 'date':
              dateFilter = Object.keys(values[filter.key].filter).filter((k) => values[filter.key].filter[k])[0];
              query[filter.key] = {
                filter:
                  Object.keys(values[filter.key].filter).filter((k) => values[filter.key].filter[k])[0] || undefined,
                date: (values[filter.key] && dateFilter !== 'is_between' && values[filter.key].date) || undefined,
                date_from:
                  (values[filter.key] && dateFilter === 'is_between' && values[filter.key].date_from) || undefined,
                date_to: (values[filter.key] && dateFilter === 'is_between' && values[filter.key].date_to) || undefined,
              };
              expectedKeys = dateFilter === 'is_between' ? 3 : 2;
              query[filter.key] =
                Object.keys(query[filter.key]).filter((k) => query[filter.key][k]).length >= expectedKeys
                  ? query[filter.key]
                  : undefined;
              break;
          }
        }
        return JSON.parse(JSON.stringify(query));
      },

      initOptionValues(values) {
        const defaultValues = {};
        this.options.forEach((option) => {
          if (!values[option.key]) {
            defaultValues[option.key] = {};
          } else {
            defaultValues[option.key] = clone(values[option.key]);
          }
        });
        return defaultValues;
      },
      createSelectObjectFromKey(key) {
        return {
          label: humanizeCapitalizeString(key),
          id: key,
        };
      },

      showMenu(filterIndex, event) {
        // Hide all others
        this.showFilterItems.forEach((_el, index) => (this.showFilterItems[index] = false));

        this.showFilterItems[filterIndex] = true;
        this.$nextTick(() => {
          this.positionDropdown(event.target);
        });
      },
      positionDropdown($el) {
        const submenu = $el.querySelector('.filter-options');

        // Sort the alignment
        if (submenu && submenu.style) {
          submenu.style.left = $el.offsetWidth + 'px';
          submenu.style.top = $el.offsetTop + 'px';
        }
      },
      dropdownVisibilityChange(isDropdownOpen) {
        if (isDropdownOpen === false) {
          this.showFilterItems.forEach((_value, index) => {
            this.showFilterItems[index] = false;
          });
        }
      },
      getDisabledValue(disabledFunc) {
        if (typeof disabledFunc === 'function') {
          return disabledFunc();
        }
        return false;
      },
      humanizeCapitalizeString: humanizeCapitalizeString,
    },
  };
</script>

<style lang="scss" scoped>
  @import '@veasel/core';

  .filter-disabled {
    pointer-events: none;

    .filter-name span {
      @include caption-secondary-50;
    }

    .s-icon {
      visibility: hidden;
    }
  }

  .filter-name {
    padding: get-spacing('s') get-spacing('s') get-spacing('s') 14px;
    background: var(--background-main);
    transition: ease background $transition-time;
    cursor: pointer;

    ::v-deep(i.icon) {
      font-size: 12px;
      pointer-events: none;
    }

    &:hover,
    &.active {
      background: var(--secondary-light);

      ::v-deep(i.icon) {
        color: var(--main-dark);
      }
    }
  }

  .filter-options {
    background: var(--background-main);
    width: 168px;
    max-height: 315px;
    overflow-y: auto;
    border: 1px solid var(--secondary-light);

    .option-item {
      background: var(--background-main);
      padding-left: get-spacing('s');
      padding-right: get-spacing('s');

      &.select,
      &.date {
        padding-top: get-spacing('s');
        padding-bottom: get-spacing('s');
      }
    }
  }

  .date {
    .radio-button {
      display: flex;
      justify-content: flex-start;

      .radio {
        background: var(--secondary-light);
        height: 12px;
        width: 12px;
        border-radius: 20px;
        position: relative;
        margin-right: 18px;
      }

      &.active .radio::before {
        position: absolute;
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 20px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: var(--main-dark);
      }
    }
  }
</style>
