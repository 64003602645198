<template>
  <div class="placeholder" :style="getSize">
    <div class="placeholder-inner"></div>
  </div>
</template>

<script>
  export default {
    name: 's-placeholder',
    description: 'A component to provide placeholder for a missing or invalid content',
    props: {
      fill: {
        description: 'The amount of space that the placeholder should take up. Accepts a number between 0 and 100',
        type: Number,
        default: 100,
      },
    },
    computed: {
      getSize() {
        let fillSize = this.fill;
        if (this.fill > 100) {
          fillSize = 100;
        } else if (this.fill < 0) {
          fillSize = 0;
        }
        return `width: ${fillSize}%; height: ${fillSize}%`;
      },
    },
  };
</script>

<style scoped lang="scss">
  $background-container: #d7dde5b3;
  $background-inner: #c0c5ce;

  .placeholder {
    position: relative;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
    min-height: 20px;
    border-radius: inherit;
    overflow: hidden;
    background: $background-container;

    &-inner {
      position: absolute;
      height: 120%;
      top: 50%;
      width: 30%;
      background: linear-gradient(90deg, $background-container 0%, $background-inner 60%, $background-container 100%);
      animation: 1.8s infinite loading;
      transform: translateY(-50%);
    }
  }

  .fill-space {
    &-full {
      width: 100%;
      height: 100%;
    }
    &-half {
      width: 50%;
      height: 50%;
    }
  }

  @keyframes loading {
    from {
      left: -80%;
    }

    to {
      left: 100%;
    }
  }
</style>
