import {clone} from '@veasel/core/tools';

export default {
  data: function () {
    return {
      sortingMap: {},
      sortingArray: [],
    };
  },

  computed: {
    // rows after sorting
    sortedRows: function () {
      if (this.opts.frontend) {
        return this.searchedRows.sortByMultiple(this.sortingArray);
      } else {
        return this.searchedRows;
      }
    },
  },

  methods: {
    // build and emit a sorting array
    sortBy: function (event, col) {
      let newMap = clone(this.sortingMap);
      // define sorting order
      let sortType;
      if (!newMap[col.data]) {
        sortType = 'asc';
      } else if (newMap[col.data].order == 'asc') {
        sortType = 'desc';
      } else {
        sortType = false;
      }
      // update sorting map
      if (this.opts.sorting.multiple && (event.shiftKey || event.ctrlKey)) {
        let index = 0;
        for (const key in newMap) {
          index++;
          newMap[key].index = index;
        }
        if (sortType) {
          newMap[col.data] = {property: col.data, order: sortType, index: 0};
        } else {
          delete newMap[col.data];
        }
      } else {
        newMap = {};
        if (sortType) {
          newMap[col.data] = {property: col.data, order: sortType, index: 0};
        }
      }
      this.sortingMap = clone(newMap);
      // build sorting array
      const sortingArray = [];
      for (const key in newMap) {
        sortingArray.push(newMap[key]);
      }
      this.sortingArray = sortingArray.sortBy('index').map((c) => {
        delete c.index;
        c.order = c.order.toUpperCase();
        return c;
      });
      // emit sorting event
      this.opts.pagination.offset = 0;
      this.updateQuery();
      this.$emit(this.opts.sorting.event, sortingArray);
    },
  },
};
