<template>
  <div ref="lottieContainer" :style="style" />
</template>

<script>
  import lottie from '../utils/lottie';

  import {isEmpty} from '@veasel/core/tools';

  export default {
    name: 's-animation',
    description:
      'A component for displaying animations. Wraps around the "lottie-web" (https://github.com/airbnb/lottie-web) component, to display lottie animations.',
    props: {
      modelValue: {
        type: Object,
        description: 'Animation object that will hold lottie-web object',
        default: () => ({}),
        required: true,
      },
      animationData: {
        type: Object,
        description: 'Lottie JSON animation data',
        default: () => ({}),
        required: true,
      },
      options: {
        type: Object,
        default: () => ({
          loop: true,
          autoPlay: true,
        }),
      },
      height: {
        type: String,
        default: 'auto',
      },
      width: {
        type: String,
        default: '100%',
      },
    },
    emits: ['update:modelValue'],
    data() {
      return {
        rendererSettings: {
          scaleMode: 'centerCrop',
          clearCanvas: true,
          progressiveLoad: false,
          hideOnTransparent: true,
        },
      };
    },
    mounted() {
      this.init();
    },
    watch: {
      options() {
        this.init();
      },
    },
    computed: {
      style() {
        return {
          width: this.width,
          height: this.height,
          overflow: 'hidden',
          margin: '0 auto',
        };
      },
      animation: {
        get() {
          return this.modelValue;
        },
        set(value) {
          this.$emit('update:modelValue', value);
        },
      },
    },
    methods: {
      init() {
        if (this.animation && !isEmpty(this.animation)) {
          this.animation.destroy(); // Releases resources. The DOM element will be emptied.
        }
        this.animation = lottie.loadAnimation({
          container: this.$refs.lottieContainer,
          renderer: 'svg',
          loop: this.options.loop,
          autoplay: this.options.autoPlay,
          animationData: this.animationData,
          rendererSettings: this.rendererSettings,
        });
      },
    },
  };
</script>
