<template>
  <span v-if="showWarning" class="m-t-z theme-style-caption-alt-error">
    <s-label type="error" title="Software is outdated">
      <template v-slot:tooltip>
        Your Suade software version is more than 3 months old. Please contact Suade support to upgrade your version.
      </template>
    </s-label>
  </span>
</template>

<script>
  import API from '@/services/api';

  export default {
    name: 'VersionWarning',
    props: ['env'],
    data() {
      return {
        suadeVersion: 'dev',
      };
    },
    computed: {
      versionAge() {
        if (this.suadeVersion === 'dev') {
          return 0;
        } else {
          let dateString = this.suadeVersion.split('-')[0];
          let date = new Date(
            '20' + dateString.substr(0, 2),
            dateString.substr(2, 2) - 1,
            dateString.substr(4, 2),
            dateString.substr(6, 2),
            dateString.substr(8, 2)
          );
          return Date.now() / 1000 - date.getTime() / 1000;
        }
      },
      showWarning() {
        return this.versionAge > 90 * 24 * 3600;
      },
    },
    created() {
      API.getSystemVersion()
        .then((version) => {
          this.$store.dispatch('system/setVersion', version);
          this.suadeVersion = version;
        })
        .catch(() => {
          // noop to handle uncaught exception error that is triggered here in prod when logging out via logout url
          // any errors that we get are already caught in api.js
        });
    },
  };
</script>
