<template>
  <div class="x-grid">
    <div class="row x-row">
      <div class="circuit-wrapper m-l-xxxl" :class="{neutral: noValues}">
        <div class="root-box ellipsis" :class="{'multi-line-content': startMultiline}">
          <p v-html="startClean || 'START'"></p>
        </div>

        <div ref="circuit" class="circuit"></div>

        <div
          class="root-box ellipsis"
          :class="{
            'multi-line-content': endMultiline,
            red: !globallyValid,
            green: globallyValid,
          }"
        >
          <p v-html="endClean || 'END'"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as d3 from 'd3';

  const AND_STRING = 'and';
  const OR_STRING = 'or';

  export default {
    name: 's-logic-circuit',
    props: {
      logic: {
        description: 'The logic nested object.',
        type: Object,
        required: true,
      },
      dictionary: {
        description: 'The dictionary to map function names to keys (value to display) and values (function result).',
        type: Object,
        required: true,
      },
      noValues: {
        description: "A flag to use when the functions haven't been resolved.",
        type: Boolean,
        default: false,
      },
      uncertain: {
        description: 'Used to display some steps as uncertain in orange.',
        type: Array,
        default: () => [],
      },
      start: {
        description: 'The label for the starting box',
        type: String,
        default: 'START',
      },
      end: {
        description: 'The label for the ending box',
        type: String,
        default: 'END',
      },
    },
    data() {
      return {
        /**
         * If the logic circuit needs to be resized at some point,
         * only the nodeDimensions property needs to be updated.
         * ALL other measurements are calculated based off that property.
         */
        nodeDimensions: 50,
        alignment: 0,
        padding: 0,
        paddingHalf: 0,
        notOffset: 0,
        notOffsetHalf: 0,
        nodeNotWidth: 0,
        nodeBoxHeight: 0,
        nodeBoxWidth: 0,
        nodesArr: ['start'], // start is always index 0
        collectiveWidth: 0,
        nodeId: 0,
        parsedData: undefined,
        largestOrArrayLength: 0,
        totalWrapperHeight: 0,
        lastSelectedNodeId: 0,
        svgContainer: undefined,
        startMultiline: false,
        endMultiline: false,
        globallyValid: true,
        validItemCount: 0,
        orItemAlreadyCounted: false,
        linePathGenerator: d3
          .line()
          .x(function (d) {
            return d.X;
          })
          .y(function (d) {
            return d.Y;
          }),
      };
    },
    watch: {
      startClean: {
        immediate: true,
        handler: function (val) {
          if (val && val.indexOf('<br />') !== -1) {
            this.startMultiline = true;
          }
        },
      },
      endClean: {
        immediate: true,
        handler: function (val) {
          if (val && val.indexOf('<br />') !== -1) {
            this.endMultiline = true;
          }
        },
      },
    },
    emits: ['item-selected'],
    mounted() {
      // init and build the logic circuit schema after DOM has been processed
      this.$nextTick(() => {
        this.init(this.logic);
      });
    },
    computed: {
      startClean: function () {
        if (this.start && this.start.indexOf('\\n') !== -1) {
          return this.start.replace(/\\n/g, '<br />');
        } else {
          return this.start;
        }
      },
      endClean: function () {
        if (this.end && this.end.indexOf('\\n') !== -1) {
          return this.end.replace(/\\n/g, '<br />');
        } else {
          return this.end;
        }
      },
    },
    methods: {
      init(logic) {
        // Make common calcs on init
        this.alignment = this.nodeDimensions / 10;
        this.padding = this.nodeDimensions / 2;
        this.paddingHalf = this.padding / 2;
        this.nodeBoxDimensions = this.nodeDimensions + this.padding * 2;
        this.notOffset = this.padding - this.alignment;
        this.nodeNotWidth = this.nodeDimensions + this.notOffset;
        this.notOffsetHalf = this.notOffset / 2;

        this.parseLogic(logic);

        this.globallyValid = logic.and.length === this.validItemCount;
        this.totalWrapperHeight = this.calculateWrapperHeight();

        this.drawCircuit();
      },

      parseLogic(logic) {
        this.parsedData = logic.and
          .map((node) => {
            if (node?.or) {
              this.orItemAlreadyCounted = false;
              const nodePosition = this.handleVerticalPositions(node.or.length);
              const isEvenNumOfNodes = node.or.length % 2 === 0;

              // Needed to calculate final height of wrapper as 'or' nodes are laid out vertically
              if (node.or.length > this.largestOrArrayLength) {
                this.largestOrArrayLength = node.or.length;
              }

              const orItems = node.or.map((orNodeFunctionName, index) => {
                const isFirstNode = index === 0;

                return this.dataObject(
                  OR_STRING,
                  orNodeFunctionName,
                  nodePosition[index],
                  isFirstNode,
                  isEvenNumOfNodes
                );
              });

              return orItems;
            }

            return this.dataObject(AND_STRING, node);
          })
          .flat();
      },

      dataObject(type, nodeFunctionName, position = 0, isFirstOrOnlyNode = true, isEvenNumOfNodes = false) {
        let notStatus = false;

        if (nodeFunctionName[0] === '~') {
          nodeFunctionName = nodeFunctionName.slice(1);
          notStatus = true;
        }

        if (nodeFunctionName.indexOf(':') !== -1) {
          nodeFunctionName = nodeFunctionName.split(':')[0];
        }

        this.countValidItems(nodeFunctionName, type, notStatus);

        return {
          id: this.nodeId++,
          label: this.getNodeId(nodeFunctionName),
          type: type,
          isNot: notStatus,
          nodePosition: position,
          isFirstOrOnlyNode: isFirstOrOnlyNode,
          validityIndicator: this.determineValidity(nodeFunctionName, notStatus),
          isEvenNumOfNodes: isEvenNumOfNodes,
          nodeCoords: {X: 0, Y: 0},
          lineData: [],
        };
      },

      countValidOrItem() {
        if (!this.orItemAlreadyCounted) {
          this.validItemCount++;
          this.orItemAlreadyCounted = true;
        }
      },

      countValidItems(node, type, notStatus) {
        if ((this.dictionary[node]?.value && !notStatus) || (!this.dictionary[node]?.value && notStatus)) {
          type === OR_STRING ? this.countValidOrItem() : this.validItemCount++;
        }
      },

      determineValidity(nodeFunctionName, notStatus) {
        const value = this.dictionary[nodeFunctionName]?.value;
        const isNodeValid = (value && !notStatus) || (!value && notStatus);

        if (!isNodeValid && this.globallyValid) {
          this.globallyValid = false;
        }

        if (this.uncertain.indexOf(nodeFunctionName) !== -1) {
          return 'orange';
        }

        return isNodeValid ? 'green' : 'red';
      },

      getNodeId(node) {
        if (!this.nodesArr.includes(node)) {
          this.nodesArr.push(node);
        }
        return this.nodesArr.indexOf(node);
      },

      getNodeFunctionNameByLabel(label) {
        if (this.nodesArr[label]) {
          return this.nodesArr[label];
        }
        console.warn(`[Logic Circuit] - No label to retrieve. Node with label: ${label} does not exist.`);
      },

      handleVerticalPositions(arrayLength) {
        const positionArr = [];
        const topEndIndex = arrayLength / 2;
        let positionIndex = Math.floor(topEndIndex);
        let midPoint = false;
        let count = 0;

        if (arrayLength % 2 !== 0) {
          midPoint = true;
        }

        while (count !== Math.floor(topEndIndex)) {
          count++;
          positionArr.push(positionIndex);
          positionIndex--;
        }

        if (midPoint) {
          count++;
          positionArr.push(0);
        }

        positionIndex = 0;
        while (count !== arrayLength) {
          count++;
          positionIndex--;
          positionArr.push(positionIndex);
        }

        return positionArr;
      },

      calculateWrapperHeight() {
        if (this.largestOrArrayLength > 0) {
          return this.nodeBoxDimensions * this.largestOrArrayLength;
        }
        return this.nodeBoxDimensions * 2;
      },

      drawCircuit() {
        // Creates and places SVG container
        const circuit = this.$refs.circuit;
        const circuitContainer = d3.select(circuit);
        this.svgContainer = circuitContainer.append('svg:svg');
        this.svgContainer.attr('viewbox', '0 0 100 100');

        // Appends defs element - must be first child
        const defs = this.svgContainer.append('defs');

        // Sets up group elements that hold each node
        this.createGroupElements();
        // Sets up 'NOT' element and places in the defs tag for reuse later
        this.createNotElements(defs);

        // Creates all the nodes, text and links
        const nodes = this.svgContainer.selectAll('g.item');
        this.createNodesAndLinks(nodes);
        this.createLabels(nodes);
        this.applyNotElementsToNodes();

        // Gets all node links and updates the line data for each
        this.svgContainer
          .selectAll('path.node-link')
          .data(this.parsedData, (_node, index) => index)
          .attr('d', (node) => this.linePathGenerator(node.lineData));

        // Creates points at either side of a node using node coords set earlier
        this.createNodeEndpoints(nodes);

        // Sets final width and height of the container
        this.svgContainer
          .attr('width', this.collectiveWidth + this.nodeBoxDimensions)
          .attr('height', this.totalWrapperHeight);
      },

      createGroupElements() {
        // Create 'g' tags based on data from request, append a path element for use later
        this.svgContainer
          .selectAll('g')
          .data(this.parsedData, (_node, index) => index) // returns key for indexing
          .join((enter) =>
            enter // join and apply group nodes based on data received
              .append('g')
              .attr('class', (node) => `node-${node.label} ${node.validityIndicator}`)
              .classed('item', true)
              .classed('orNode', (node) => node.type === OR_STRING)
              .classed('isNot', (node) => node.isNot)
              .on('click', (_event, node) => {
                const nodeFunctionName = this.getNodeFunctionNameByLabel(node.label);
                this.selectLogicItem(nodeFunctionName, node.label);
              })
              .append('path')
              .classed('node-link', true)
              .attr('stroke-width', '2px')
              .attr('fill', 'none')
          );
      },

      createNotElements(defs) {
        defs.append('g').attr('id', 'notText').classed('notTextAndDivder', true);

        const defsNotGroup = this.svgContainer.select('#notText');
        defsNotGroup.append('text').text('NOT');
        defsNotGroup
          .append('path')
          .classed('notDivider', true)
          .attr('fill', 'none')
          .attr('stroke-width', '2')
          .attr('d', `M-${this.paddingHalf - this.alignment} 10 h${this.nodeDimensions - this.alignment}`);
      },

      createNodesAndLinks(nodes) {
        // Setup rect elements
        nodes
          .data(this.parsedData, (_node, index) => index) // returns key for indexing
          .append('rect')
          .attr('width', (node) => (node.isNot ? this.nodeNotWidth : this.nodeDimensions))
          .attr('height', this.nodeDimensions)
          .attr('rx', (node) => (node.isNot ? this.padding : this.nodeDimensions * 2))
          .attr('x', (currentNode, index) => this.setPositionX(currentNode, index))
          .attr('y', (currentNode, index) => this.setPositionY(currentNode, index))
          .classed('node', true);
      },

      createLabels(nodes) {
        // Create text elements and append them in 'g'
        nodes
          .data(this.parsedData, (_node, index) => index) // returns key for indexing
          .append('text')
          .attr('transform', (currentNode) => {
            const textX = currentNode.nodeCoords.X + this.notOffset;
            const textY = currentNode.nodeCoords.Y + this.padding + this.alignment;

            // size of font will require fine-tuning the text position
            let textAdustmentX = 1;
            const textAdustmentY = 2;

            if (currentNode.isNot) {
              textAdustmentX -= this.padding;
            }

            // cater for double digit spacing
            if (currentNode.label > 9) {
              const doubleDigitSpace = this.alignment + textAdustmentX;
              textAdustmentX = currentNode.isNot ? doubleDigitSpace : doubleDigitSpace + textAdustmentX;
            }
            return `translate(${textX - textAdustmentX}, ${textY + textAdustmentY})`;
          })
          .attr('font-size', `${this.notOffset}px`)
          .text((node) => `${node.label}`);
      },

      setPositionX(currentNode, index) {
        // Only update collective width if it is a single node or
        // if it is the first node in an 'or' list to prevent stepping
        if (currentNode.isFirstOrOnlyNode) {
          this.collectiveWidth += this.nodeBoxDimensions;

          if (this.previousNode?.type === OR_STRING || currentNode.type === OR_STRING) {
            // Add padding space if is 'or' list or node comes after 'or' list
            this.collectiveWidth = index === 0 ? this.nodeDimensions : this.collectiveWidth + this.padding;
          }
        }

        if (index === 0) {
          this.collectiveWidth = this.nodeDimensions;
        }

        // cater for extra width of node that is a 'Not'
        if (currentNode.isNot) {
          this.collectiveWidth -= this.notOffsetHalf;
        }

        // if an item is a 'Not' it will mess up the layout for the
        // next node in the sequence if the width isn't corrected below
        if (this.previousNode?.isNot) {
          this.collectiveWidth += this.notOffsetHalf;
        }

        currentNode.nodeCoords.X = this.collectiveWidth;
        this.setLineDataPositionX(currentNode, index);

        this.previousNode = currentNode;

        this.clearPreviousNodeData(index);

        return this.collectiveWidth;
      },

      setLineDataPositionX(currentNode, index) {
        let lineDataStartOffset = 0;

        if (index === 0) {
          lineDataStartOffset = currentNode.type !== OR_STRING ? this.padding : 0;
        }

        let lineDataStartX = currentNode.nodeCoords.X - this.padding - lineDataStartOffset;
        let lineDataEndX = currentNode.nodeCoords.X + this.nodeDimensions + this.padding;

        if (currentNode.type === AND_STRING && index === this.parsedData.length - 1) {
          // last node needs to connect to END block
          lineDataEndX += this.padding;
        }

        if (currentNode.type === OR_STRING && currentNode.nodePosition === 0) {
          lineDataStartX -= this.padding;
          lineDataEndX += this.padding;
        }

        if (currentNode.isNot) {
          lineDataStartX += this.notOffsetHalf;
          lineDataEndX += this.notOffsetHalf;
        }

        this.setComplexLinkXValues(currentNode, lineDataStartX, lineDataEndX);
      },

      setPositionY(currentNode, index) {
        const middle = this.totalWrapperHeight / 2 - this.padding;
        const offset = currentNode.isEvenNumOfNodes ? this.padding + this.paddingHalf : 0;
        const heightWithPadding = this.nodeDimensions + this.padding;

        let rectY = middle;
        let offsetSide = 0;
        let offsetMiddle = 0;

        if (currentNode.type === OR_STRING) {
          if (currentNode.nodePosition > 0) {
            // top
            rectY = middle - heightWithPadding * currentNode.nodePosition + offset;
            offsetSide = rectY + this.nodeDimensions * 2;
            offsetMiddle = rectY + this.padding;
          } else if (currentNode.nodePosition < 0) {
            // bottom
            rectY = middle + heightWithPadding * -currentNode.nodePosition - offset;
            offsetSide = rectY - this.nodeDimensions;
            offsetMiddle = rectY + this.padding;
          }
          this.setComplexLinkYValues(currentNode, offsetSide, offsetMiddle, index);
        }

        this.setLinkYValues(currentNode);

        currentNode.nodeCoords.Y = rectY;

        return rectY;
      },

      setLinkYValues(currentNode) {
        const middle = this.totalWrapperHeight / 2;

        if (currentNode.nodePosition === 0) {
          if (currentNode.type === OR_STRING) {
            currentNode.lineData.forEach((node) => (node.Y = middle));
          } else {
            currentNode.lineData[0].Y = currentNode.lineData[1].Y = middle;
          }
        }
      },

      clearPreviousNodeData(index) {
        if (index === this.parsedData.length - 1) {
          this.previousNode = undefined;
        }
      },

      applyNotElementsToNodes() {
        // svg use - the NOT text and divider
        this.svgContainer
          .selectAll('g.isNot')
          .append('use')
          .attr('href', '#notText')
          .attr('font-size', `${this.notOffset - this.alignment}px`)
          .attr('transform', (node) => {
            const x = node.nodeCoords.X + this.padding;
            const y = node.nodeCoords.Y + this.padding + this.paddingHalf + this.alignment / 2;

            return `translate(${x}, ${y}) rotate(-90)`;
          });
      },

      createNodeEndpoints(nodes) {
        nodes
          .append('circle')
          .classed('node-start', true)
          .attr('r', this.alignment + 1)
          .attr('cx', (node) => {
            return node.nodeCoords.X - 2;
          })
          .attr('cy', (node) => {
            return node.nodeCoords.Y + this.padding;
          });

        nodes
          .append('circle')
          .classed('node-end', true)
          .attr('r', this.alignment + 1)
          .attr('cx', (node) => {
            const x = node.nodeCoords.X + this.nodeDimensions + 2;
            const coords = node.isNot ? x + this.notOffset : x;
            return coords;
          })
          .attr('cy', (node) => {
            return node.nodeCoords.Y + this.padding;
          });
      },

      selectLogicItem: function (nodeFunctionName, nodeId = undefined) {
        this.svgContainer.selectAll(`.node-${this.lastSelectedNodeId}`).classed('selected', false);

        nodeId = !nodeId ? this.getNodeId(nodeFunctionName) : nodeId;
        this.svgContainer.selectAll(`.node-${nodeId}`).classed('selected', true);
        this.lastSelectedNodeId = nodeId;

        this.$emit('item-selected', nodeFunctionName);
      },

      setComplexLinkXValues(node, start, end) {
        // set link X start and end values and prep for Y
        if (node.type === OR_STRING) {
          if (node.nodePosition === 1 && node.isEvenNumOfNodes) {
            node.lineData[0] = {X: start - this.padding, Y: 0};
            node.lineData[1] = {X: start, Y: 0};
            node.lineData[2] = {X: start, Y: 0}; //    ____
            node.lineData[3] = {X: end, Y: 0}; //   __|    |__
            node.lineData[4] = {X: end, Y: 0};
            node.lineData[5] = {X: end + this.padding, Y: 0};
          } else {
            node.lineData[0] = {X: start, Y: 0};
            node.lineData[1] = {X: start, Y: 0}; //    ____
            node.lineData[2] = {X: end, Y: 0}; //      |    |   or   |____|
            node.lineData[3] = {X: end, Y: 0};
          }
        } else {
          node.lineData[0] = {X: start, Y: 0};
          node.lineData[1] = {X: end, Y: 0};
        }
      },

      setComplexLinkYValues(node, offsetSide, offsetMiddle) {
        if (node.type === OR_STRING && node.isEvenNumOfNodes && node.nodePosition === 1) {
          offsetSide -= this.padding * 2 - this.paddingHalf;

          node.lineData[0].Y = offsetSide;
          node.lineData[1].Y = offsetSide;
          node.lineData[2].Y = offsetMiddle; //    ____
          node.lineData[3].Y = offsetMiddle; // __|    |__
          node.lineData[4].Y = offsetSide;
          node.lineData[5].Y = offsetSide;
        } else {
          if (node.isEvenNumOfNodes && node.nodePosition === -1) {
            offsetSide = offsetMiddle - (this.padding + this.padding / 4) - this.alignment;
          }

          node.lineData[0].Y = offsetSide;
          node.lineData[1].Y = offsetMiddle; //    ____
          node.lineData[2].Y = offsetMiddle; //   |    |   or   |____|
          node.lineData[3].Y = offsetSide;
        }
      },
    },
  };
</script>

<!-- eslint-disable-next-line vue-scoped-css/enforce-style-type -->
<style lang="scss">
  .circuit-wrapper {
    display: flex;
    align-items: center;

    & .root-box {
      position: relative;
      display: inline-block;
      vertical-align: middle;

      &:not(.circuit-node) {
        flex: 0 0 auto;
        min-width: 170px;
        color: var(--background-main);
        background-color: var(--secondary);
        text-align: center;
        line-height: 40px;
        border-radius: 5px;
        bottom: 2px;
      }

      &.red {
        background-color: var(--error);
      }

      &.green {
        background-color: var(--approved);
      }

      p {
        margin: 0;
        padding: 10px;
        font-weight: bold;
      }
    }

    .node {
      display: inline-block;
      position: relative;
      margin: 10px;
      cursor: pointer;
      fill: var(--secondary);

      &:hover {
        fill: var(--hover);
      }

      &-start,
      &-end {
        fill: var(--secondary);
        stroke-width: 2;
        stroke: var(--background-main);
      }
    }

    .red .node {
      fill: var(--error);
      background-color: var(--error);
    }

    .green .node {
      fill: var(--approved);
      background-color: var(--approved);
    }

    .orange .node {
      fill: var(--warning);
      background-color: var(--warning);
    }

    .red .node-link {
      stroke: var(--error);
      stroke-dasharray: 6;
      stroke-dashoffset: 2;
    }

    .green .node-link {
      stroke: var(--approved);
    }

    .red .node-start,
    .red .node-end {
      fill: var(--error);
    }

    .green .node-start,
    .green .node-end {
      fill: var(--approved);
    }

    .orange .node-start,
    .orange .node-end {
      fill: var(--warning);
    }

    &.neutral {
      .red .node,
      .green .node,
      .orange .node,
      .root-box.red,
      .root-box.green {
        fill: var(--secondary);
        background-color: var(--secondary);
      }

      .red .node-link,
      .green .node-link {
        stroke: var(--secondary);
        stroke-dasharray: 0;
      }

      .red .node-start,
      .red .node-end,
      .green .node-start,
      .green .node-end,
      .orange .node-start,
      .orange .node-end {
        fill: var(--secondary);
        stroke-dasharray: 0;
        stroke: var(--background-main);
      }
    }

    .node-link {
      position: relative;
      stroke-linecap: square;
      stroke: var(--secondary);
    }

    text {
      pointer-events: none;
      font-weight: bold;
      fill: var(--background-main);
    }

    .selected .node {
      stroke: var(--active);
      stroke-width: 7px;
    }

    .isNot {
      position: relative;

      .notWrap {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .notTextAndDivder {
    fill: var(--background-main);
    font-weight: 700;

    .notDivider {
      stroke: var(--background-main);
    }
  }

  .circuit {
    position: relative;
    display: inline-block;
    padding: 0;
    vertical-align: middle;
  }
</style>
