<template>
  <div class="absolute-wrapper">
    <div v-if="fullOverlay" class="full-overlay"></div>

    <div v-else class="partial-overlay">
      <div class="partial-overlay-part left" :style="{width: targetRect.left + 'px'}"></div>
      <div
        class="partial-overlay-part top"
        :style="{left: targetRect.left + 'px', width: targetRect.width + 'px', height: targetRect.top + 'px'}"
      ></div>
      <div
        class="partial-overlay-part bottom"
        :style="{
          left: targetRect.left + 'px',
          width: targetRect.width + 'px',
          top: targetRect.top + targetRect.height + 'px',
          height: 'calc(100% - ' + targetRect.bottom + 'px)',
        }"
      ></div>
      <div
        class="partial-overlay-part right"
        :style="{left: targetRect.left + targetRect.width + 'px', width: 'calc(100% - ' + targetRect.right + 'px)'}"
      ></div>
      <div
        class="partial-overlay-target"
        :style="{
          left: targetRect.left + 'px',
          top: targetRect.top + 'px',
          width: targetRect.width + 'px',
          height: targetRect.height + 'px',
        }"
      >
        <div
          v-show="!TWEAK_CORNERS || displayPosition !== 'bottom-right'"
          class="partial-overlay-target-part top-left"
        ></div>
        <div
          v-show="!TWEAK_CORNERS || displayPosition !== 'bottom-left'"
          class="partial-overlay-target-part top-right"
        ></div>
        <div
          v-show="!TWEAK_CORNERS || displayPosition !== 'top-right'"
          class="partial-overlay-target-part bottom-left"
        ></div>
        <div
          v-show="!TWEAK_CORNERS || displayPosition !== 'top-left'"
          class="partial-overlay-target-part bottom-right"
        ></div>
      </div>
      <div
        v-if="currentStep.clickableTarget"
        class="partial-overlay-target-clickable"
        :style="{
          left: originalTargetRect.left + 'px',
          top: originalTargetRect.top + 'px',
          width: originalTargetRect.width + 'px',
          height: originalTargetRect.height + 'px',
        }"
        @click="next"
      ></div>
    </div>

    <div ref="step" class="s-tutorial-step" :style="position">
      <h2>{{ currentStep.title }}</h2>
      <a class="close-button" @click="() => close(false)"><s-icon type="cancel" /></a>
      <div class="row">
        <div class="doc-content" v-html="currentStep.content"></div>
      </div>
      <div class="row end">
        <a class="disable-tuto-link col-x s-link" @click="() => close(true)">Got it, don't show anymore!</a>
        <s-button v-if="isLastStep" class="col-x" color="theme-approved" text="Finish" :onClick="() => close(true)" />
        <s-button v-else class="col-x" color="theme-approved" text="Next" :onClick="next" />
      </div>
    </div>
  </div>
</template>

<script>
  import {emitEvent} from '@veasel/core/tools';

  export default {
    name: 's-tutorial-step',
    description: 'A tutorial step.',
    data() {
      return {
        fullOverlay: true,
        position: {},
        targetRect: {},
        TWEAK_CORNERS: false,
        keys: {37: 1, 38: 1, 39: 1, 40: 1},
        wheelOpt: {passive: false},
        wheelEvent: 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel',
      };
    },
    computed: {
      currentStep() {
        return this.section.steps[this.index];
      },
      isLastStep() {
        return this.section.steps.length - 1 === this.index;
      },
      isFirstStep() {
        return this.index === 0;
      },
    },
    methods: {
      initializeStep() {
        if (this.section.steps[this.index].preAction) {
          emitEvent(this.section.steps[this.index].preAction, {}, () => {
            this.$nextTick(() => {
              this.positioning();
            });
          });
        } else {
          this.$nextTick(() => {
            this.positioning();
          });
        }
      },
      positioning(counter = 0) {
        if (!this.$refs || !this.$refs.step) {
          return;
        }
        const stepRect = this.$refs.step.getBoundingClientRect();
        let targetElement;
        let targetRect;
        if (!this.currentStep.target) {
          this.position = {
            margin: 'auto',
            top: window.innerHeight / 2 - stepRect.height / 2 + 'px',
          };
          this.fullOverlay = true;
          return;
        }
        // delay and retry if target is not existing yet
        if (this.currentStep.target.split(',').some((t) => document.querySelectorAll(t).length === 0)) {
          if (counter < 50) {
            counter++;
            setTimeout(() => {
              this.positioning(counter);
            }, 100);
            return;
          } else {
            console.warn("Can't find all targets for tutorial step (" + this.currentStep.target + ')');
            return;
          }
        }
        // find if scrolling is needed
        if (document.querySelectorAll(this.currentStep.target).length > 1) {
          targetElement = document.querySelectorAll(this.currentStep.target);
          targetElement[0].scrollIntoView({behavior: 'auto', block: 'center', inline: 'center'});
          targetRect = [].slice
            .bind(targetElement)()
            .map((el) => el.getBoundingClientRect())
            .reduce(
              (acc, el) => ({
                top: Math.min(acc.top, el.top),
                bottom: Math.max(acc.bottom, el.bottom),
                left: Math.min(acc.left, el.left),
                right: Math.max(acc.right, el.right),
                height: Math.max(acc.bottom, el.bottom) - Math.min(acc.top, el.top),
                width: Math.max(acc.right, el.right) - Math.min(acc.left, el.left),
              }),
              targetElement[0].getBoundingClientRect()
            );
        } else {
          targetElement = document.querySelector(this.currentStep.target);
          targetElement.scrollIntoView({behavior: 'auto', block: 'center', inline: 'center'});
          targetRect = targetElement.getBoundingClientRect();
        }

        // deduct the orientation of the tutorial in relation to the target
        const hasSpaceOnTop = targetRect.top > stepRect.height;
        const hasSpaceOnBottom = window.innerHeight - targetRect.bottom > stepRect.height;
        const hasSpaceOnLeft = targetRect.left > stepRect.width;
        const hasSpaceOnRight = window.innerWidth - targetRect.right > stepRect.width;
        const verticalyCentered = hasSpaceOnLeft || hasSpaceOnRight || (!hasSpaceOnTop && !hasSpaceOnBottom);
        const horizontalyCentered =
          (!hasSpaceOnLeft && !hasSpaceOnRight) ||
          (!hasSpaceOnLeft && !hasSpaceOnRight && !hasSpaceOnTop && !hasSpaceOnBottom);

        const verticalPosition = verticalyCentered ? 'center' : targetRect.top >= stepRect.height ? 'bottom' : 'top';
        const horizontalPosition = horizontalyCentered
          ? 'center'
          : targetRect.left >= stepRect.width
          ? 'right'
          : 'left';
        this.displayPosition = verticalPosition + '-' + horizontalPosition;

        const padding = 10;
        this.originalTargetRect = targetRect;
        this.targetRect = {
          top: targetRect.top - padding,
          left: targetRect.left - padding,
          height: targetRect.height + padding * 2,
          width: targetRect.width + padding * 2,
        };
        this.fullOverlay = false;

        switch (this.displayPosition) {
          case 'center-left':
            this.position = {
              left: targetRect.left + targetRect.width + padding * 2 + 'px',
              top: targetRect.top + targetRect.height / 2 - stepRect.height / 2 + padding + 'px',
            };
            break;
          case 'center-right':
            this.position = {
              left: targetRect.left - stepRect.width - padding * 2 + 'px',
              top: targetRect.top + targetRect.height / 2 - stepRect.height / 2 + padding + 'px',
            };
            break;
          case 'top-left':
            this.position = {
              left: targetRect.left + targetRect.width + padding + 'px',
              top: targetRect.top + targetRect.height + padding + 'px',
              ...(this.TWEAK_CORNERS ? {['border-' + this.displayPosition + '-radius']: 0} : {}),
            };
            break;
          case 'top-right':
            this.position = {
              left: targetRect.left - stepRect.width - padding + 'px',
              top: targetRect.top + targetRect.height + padding + 'px',
              ...(this.TWEAK_CORNERS ? {['border-' + this.displayPosition + '-radius']: 0} : {}),
            };
            break;
          case 'top-center':
            this.position = {
              left: targetRect.left + targetRect.width / 2 - stepRect.width / 2 + 'px',
              top: targetRect.top + targetRect.height + padding * 2 + 'px',
            };
            break;
          case 'bottom-left':
            this.position = {
              left: targetRect.left + targetRect.width + padding + 'px',
              top: targetRect.top - stepRect.height - padding + 'px',
              ...(this.TWEAK_CORNERS ? {['border-' + this.displayPosition + '-radius']: 0} : {}),
            };
            break;
          case 'bottom-right':
            this.position = {
              left: targetRect.left - stepRect.width - padding + 'px',
              top: targetRect.top - stepRect.height - padding + 'px',
              ...(this.TWEAK_CORNERS ? {['border-' + this.displayPosition + '-radius']: 0} : {}),
            };
            break;
          case 'bottom-center':
            this.position = {
              left: targetRect.left + targetRect.width / 2 - stepRect.width / 2 + 'px',
              top: targetRect.top - stepRect.height - padding * 2 + 'px',
            };
            break;
          case 'center-center':
            this.position = {
              left: targetRect.left + targetRect.width / 2 - stepRect.width / 2 + 'px',
              top: targetRect.top + targetRect.height / 2 - stepRect.height / 2 + 'px',
            };
            break;
        }
      },
      finalizeStep(callback) {
        if (this.section.steps[this.index].postAction) {
          emitEvent(this.section.steps[this.index].postAction, {}, callback);
        } else {
          callback();
        }
      },
      close(state) {
        this.finalizeStep(() => {
          setTimeout(() => {
            this.closeCallback(state);
          });
        });
      },
      next() {
        this.finalizeStep(() => {
          this.index++;
          this.nextCallback();
        });
      },

      preventDefault(e) {
        e.preventDefault();
      },

      preventDefaultForScrollKeys(e) {
        if (this.keys[e.keyCode]) {
          this.preventDefault(e);
          return false;
        }
      },
      // call this to Disable
      disableScroll() {
        window.addEventListener('DOMMouseScroll', this.preventDefault, false); // older FF
        window.addEventListener(this.wheelEvent, this.preventDefault, this.wheelOpt); // modern desktop
        window.addEventListener('touchmove', this.preventDefault, this.wheelOpt); // mobile
        window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
      },

      // call this to Enable
      enableScroll() {
        window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
        window.removeEventListener(this.wheelEvent, this.preventDefault, this.wheelOpt);
        window.removeEventListener('touchmove', this.preventDefault, this.wheelOpt);
        window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
      },
    },
    watch: {
      currentStep: {
        handler() {
          this.$nextTick(this.initializeStep);
        },
        immediate: true,
        deep: true,
      },
    },
    created() {
      // modern Chrome requires { passive: false } when adding event
      let supportsPassive = false;
      try {
        window.addEventListener(
          'test',
          null,
          Object.defineProperty({}, 'passive', {
            get: () => (supportsPassive = true),
          })
        );
      } catch (e) {
        supportsPassive = false;
      }
      this.wheelOpt = supportsPassive ? {passive: false} : false;
    },
    mounted() {
      this.disableScroll();
      window.addEventListener('resize', this.positioning);
    },
    unmounted() {
      this.enableScroll();
      window.removeEventListener('resize', this.positioning);
    },
  };
</script>

<style scoped lang="scss">
  @use 'sass:color';
  @import '@veasel/core';

  .absolute-wrapper {
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 110;
    height: 100%;
    width: 100%;
  }

  .full-overlay {
    pointer-events: all;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(120, 120, 120, 0.5);
  }

  .partial-overlay {
    pointer-events: all;
    position: absolute;
    height: 100%;
    width: 100%;

    .partial-overlay-part {
      pointer-events: none;
      position: absolute;
      background-color: rgba(120, 120, 120, 0.5);
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }

    .partial-overlay-target {
      pointer-events: none;
      position: absolute;
      overflow: hidden;

      .partial-overlay-target-part {
        pointer-events: none;
        position: absolute;
        height: 20px;
        width: 20px;
      }

      .partial-overlay-target-part.top-left {
        border-radius: 10px 0 0 0;
        box-shadow: 0 -10px 0 0 rgba(120, 120, 120, 0.5);
        top: 0;
      }

      .partial-overlay-target-part.top-right {
        border-radius: 0 10px 0 0;
        box-shadow: 0 -10px 0 0 rgba(120, 120, 120, 0.5);
        top: 0;
        right: 0;
      }

      .partial-overlay-target-part.bottom-left {
        border-radius: 0 0 0 10px;
        box-shadow: -10px 0 0 0 rgba(120, 120, 120, 0.5);
        bottom: 0;
      }

      .partial-overlay-target-part.bottom-right {
        border-radius: 0 0 10px 0;
        box-shadow: 10px 0 0 0 rgba(120, 120, 120, 0.5);
        bottom: 0;
        right: 0;
      }
    }

    .partial-overlay-target-clickable {
      pointer-events: all;
      cursor: pointer;
      position: absolute;
      z-index: 112;
    }
  }

  .s-tutorial-step {
    pointer-events: all;
    position: relative;
    color: $gray-darker;
    background-color: color.adjust($lightblue, $lightness: 30%);
    border: 2px solid $lightblue;
    border-radius: 20px;
    box-shadow: 0 0 10px 0 $lightblue;
    padding: 10px;
    width: 600px;
    height: auto;
    z-index: 111;

    .doc-content {
      padding: 10px;

      ::v-deep(ul) {
        list-style: disc inside;
      }
    }

    .close-button {
      position: absolute;
      right: 5px;
      top: 10px;
    }

    .disable-tuto-link {
      margin-top: 12px;
      margin-right: 20px;
    }
  }
</style>
