import themeData from '@veasel/core/sass/themes/theme-suade.json';

export const themeColorsList = [
  'theme-main-dark',
  'theme-secondary',
  'theme-active',
  'theme-hover',
  'theme-secondary',
  'theme-secondary-50',
  'theme-secondary-light',
  'theme-background-main',
  'theme-background-secondary',
  'theme-approved',
  'theme-warning',
  'theme-error',
  'theme-topbar',
  'theme-light-hover',
];
export const getThemeColors = () => {
  const colors = {};
  themeData.colors.forEach((color) => {
    colors[color.cssName.split('--')[1]] = color.color;
  });
  return colors;
};

export const getLegacyColors = () => {
  return {
    black: '#212121',
    white: '#fefefe',
    gray: '#787878',
    lightgray: '#ededed',
    blue: '#001a66',
    'focus-blue': '#3297fd',
    red: '#fb4848',
    green: '#5cb85c',
    orange: '#f0ad4e',
  };
};
