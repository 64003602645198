import {getStorage, setStorage, extend, existStorage, deleteCookie, removeStorage} from '@veasel/core/tools';

// In order to improve load experience, initialize some values from local storage.
// Provided this user has logged in previously, this will get the right values.
// If not, these values will be overwritten anyway once the first 'getCurrentUser'
// request resolves.
function initialStateFromLocalStorage() {
  let permissions = getStorage('user-permissions');
  let features = getStorage('user-features');

  if (permissions) {
    permissions = permissions.split(',');
  }

  if (features) {
    features = features.split(',');
  }

  return {
    permissions: permissions,
    features: features,
    isAuth: getStorage('user-isAuth'),
    pass_changed: getStorage('user-pass-changed'),
    color_primary: getStorage('user-color-primary'),
    navbarCollapsed: getStorage('navbar-collapse-state'),
  };
}

export default {
  name: 'user',

  namespaced: true,

  state: {
    current: false,
    color_primary: false,
    expiration: {
      seconds_remaining: -1,
      received_at: 0,
    },
    todo: {
      count: 0,
      items: [],
    },
    notifications: {
      count: 0,
      items: [],
    },

    // Inflate initial values from local storage, if present
    ...initialStateFromLocalStorage(),
  },

  getters: {
    needsMFASetup(state, _getters, rootState) {
      return state.current && state.current.is_using_mfa === false && rootState.system.mfa_setting === 'enforced';
    },
    myEntities(state) {
      return state.current.entities || [];
    },
  },

  mutations: {
    setAuth(state, value) {
      state.isAuth = value;

      // Store auth state in local storage, to use on page-reload before getCurrentUser has returned
      setStorage('user-isAuth', value);
    },

    setCurrent(state, value) {
      state.current = value;

      if (value.settings) {
        state.color_primary = value.settings.color_primary;
      }
    },

    setPermissions(state, value) {
      let permissions = [];

      for (const i in value.roles) {
        permissions = permissions.concat(value.roles[i].scopes);
      }

      permissions = permissions.unique();
      permissions.unshift('any');

      state.permissions = permissions;

      // Store permissions in local storage so they are available immediately on page refresh
      setStorage('user-permissions', state.permissions.join(','));
    },

    setFeatures(state, value) {
      const features = value.capabilities == undefined ? state.features : value.capabilities;

      const localStorageDevMode = getStorage('dev_mode');
      const localStorageDemoMode = getStorage('demo_mode');

      if (value.id) {
        // save the dev_mode sent by the API (could be reused if user enter 'devnull'/`demonull` on the System Overview page)
        state.current.api_dev_mode = value.dev_mode;
        state.current.api_demo_mode = value.demo_mode;
      }

      // add 'dev_mode' feature ony if
      //   it is not already in the user available features
      //   'dev_mode' in local storage is true (after user enter 'devon' keys in System Overview page)
      //   or if API send user have dev_mode ON and 'dev_mode' in local storage is not false
      if (
        Object.keys(value).includes('dev_mode') &&
        !features.includes('dev_mode') &&
        (localStorageDevMode === 'true' || (value.dev_mode && localStorageDevMode !== 'false'))
      ) {
        features.push('dev_mode');
        state.current.dev_mode = true;
      }

      // remove the 'dev_mode' feature only if
      //   it is in the user available features
      //   'dev_mode' in local storage is false (after user enter 'devoff' keys in System Overview page)
      //   or if API send user have dev_mode OFF and 'dev_mode' in local storage is not true
      if (
        Object.keys(value).includes('dev_mode') &&
        features.includes('dev_mode') &&
        (localStorageDevMode === 'false' || (!value.dev_mode && localStorageDevMode !== 'true'))
      ) {
        features.splice(features.indexOf('dev_mode'), 1);
        state.current.dev_mode = false;
      }

      // add 'demo_mode' feature ony if
      //   it is not already in the user available features
      //   'demo_mode' in local storage is true (after user enter 'demoon' keys in System Overview page)
      //   or if API send user have demo_mode ON and 'demo_mode' in local storage is not false
      if (
        Object.keys(value).includes('demo_mode') &&
        !features.includes('demo_mode') &&
        (localStorageDemoMode === 'true' || (value.demo_mode && localStorageDemoMode !== 'false'))
      ) {
        features.push('demo_mode');
        state.current.demo_mode = true;
      }

      // remove the 'demo_mode' feature only if
      //   it is in the user available features
      //   'demo_mode' in local storage is false (after user enter 'demooff' keys in System Overview page)
      //   or if API send user have demo_mode OFF and 'demo_mode' in local storage is not true
      if (
        Object.keys(value).includes('demo_mode') &&
        features.includes('demo_mode') &&
        (localStorageDemoMode === 'false' || (!value.demo_mode && localStorageDemoMode !== 'true'))
      ) {
        features.splice(features.indexOf('demo_mode'), 1);
        state.current.demo_mode = false;
      }

      if (value.from_sso) {
        features.push('from_sso');
      }

      if (!features.includes('any')) {
        features.unshift('any');
      }

      state.features = features;

      // Store features in local storage so they are available immediately on page refresh
      setStorage('user-features', state.features.join(','));
    },

    setPassChanged(state, value) {
      state.pass_changed = value;

      // Store pass changed state in local storage, to use on page-reload before getCurrentUser has returned
      setStorage('user-pass-changed', value);
    },

    setExpiration(state, value) {
      state.expiration.seconds_remaining = value;
      state.expiration.received_at = Date.now();
    },

    setColorPrimary(state, value) {
      state.color_primary = value;

      setStorage('user-color-primary');
    },

    setTodos(state, values) {
      state.todo.count = values.params.num_entries;
      state.todo.items = values.data;
    },

    setNavbarCollapsed(state, value) {
      state.navbarCollapsed = value;
    },

    setNotifications(state, values) {
      state.notifications.count = values.params.num_entries;
      state.notifications.items = values.data;
    },

    setEntities(state, values) {
      state.current.entities = values;
    },

    updateDevmodeSource(state) {
      state.current = extend(state.current, {dev_mode_source: existStorage('dev_mode') ? 'local' : 'api'});
    },

    updateDemomodeSource(state) {
      state.current = extend(state.current, {
        demo_mode_source: existStorage('demo_mode') ? 'local' : 'api',
      });
    },
  },

  actions: {
    login(context, user) {
      if (user) {
        context.commit('setCurrent', user);
        context.commit('setPermissions', user);
        context.commit('setFeatures', user);
        context.commit('updateDevmodeSource');
        context.commit('setAuth', true);
        context.commit('setPassChanged', user.pass_changed);

        setStorage('mfa-enabled', user.is_using_mfa);
      } else {
        context.commit('setAuth', false);
      }
    },

    logout(context) {
      context.commit('setCurrent', {});
      context.commit('setAuth', false);

      deleteCookie('token');
      setStorage('token', null);
    },

    isAuth(context) {
      context.commit('setAuth', {value: true});
    },

    setExpiration(context, expiration) {
      context.commit('setExpiration', expiration.seconds_remaining);
    },

    updateColorPrimary(context, colorPrimary) {
      context.commit('setColorPrimary', colorPrimary);
    },

    setTodos(context, todo) {
      context.commit('setTodos', todo);
    },

    setNotifications(context, notis) {
      context.commit('setNotifications', notis);
    },

    setNavbarCollapsed(context, value) {
      context.commit('setNavbarCollapsed', value);
    },

    setCurrent(context, current) {
      context.commit('setCurrent', current);
    },

    setEntities(context, current) {
      context.commit('setEntities', current);
    },

    setLocalDevMode(context, devModeStatus) {
      if (devModeStatus === null) {
        removeStorage('dev_mode');
        // use the dev_mode send by the API
        devModeStatus = context.state.current.api_dev_mode;
      } else {
        setStorage('dev_mode', devModeStatus ? 'true' : 'false');
      }
      context.commit('updateDevmodeSource');

      context.commit('setFeatures', {dev_mode: devModeStatus});
    },

    setLocalDemoMode(context, demoModeStatus) {
      if (demoModeStatus === null) {
        removeStorage('demo_mode');
        // use the demo_mode send by the API
        demoModeStatus = context.state.current.api_demo_mode;
      } else {
        setStorage('demo_mode', demoModeStatus ? 'true' : 'false');
      }
      context.commit('updateDemomodeSource');

      context.commit('setFeatures', {demo_mode: demoModeStatus});
    },
  },
};
