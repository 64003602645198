<template>
  <div
    :id="id"
    :name="name"
    class="s-dropdown relative"
    :class="[size, visible ? 'visible' : '', buttonStyling === 'block' ? 'full-height' : '']"
  >
    <s-button
      ref="button"
      :text="buttonText"
      :size="size"
      :icon="buttonIcon"
      :color="buttonColorDisplay"
      :on-click="show"
      :disable="disable"
      :sticker="buttonSticker"
      :styling="buttonStyling"
      :iconColor="buttonIconColor"
      style="width: 100%"
      :class="[visible ? ' dropdown-open' : '', 'button']"
    >
      <slot name="button"></slot>
    </s-button>

    <s-icon class="arrow-icon" type="arrow-drop-down" color="theme-secondary" :size="setIconSize" />

    <div v-if="visible" ref="container" class="dropdown-container" :class="'align-' + align">
      <div class="dropdown-content relative" :class="color">
        <slot></slot>
      </div>
    </div>
    <s-tooltip v-if="tooltipText" :position="tooltipPosition" color="theme-background-main" :target="'#' + id">
      {{ tooltipText }}
    </s-tooltip>
  </div>
</template>

<script>
  import {id, name, size} from '@veasel/core/mixins';
  import {themeColorsList} from '@veasel/core/themes';
  import icon from '@veasel/base/icon';
  import button from '@veasel/base/button';

  export default {
    name: 's-dropdown',
    description: 'A basic dropdown button.',
    components: {
      's-icon': icon,
      's-button': button,
    },
    slots: {
      default: 'Contents of the dropdown menu',
      button: 'Custom Button Design. Handy for a different style of button, for example animations',
    },
    emits: ['change-visible'],
    mixins: [id, name, size(['small', 'medium', 'large'], 'medium')],
    props: {
      buttonText: {
        description: 'The text to describe button action.',
        type: String,
        default: '',
      },
      buttonIcon: {
        description: 'An icon to display inside the button.',
        type: String,
      },
      buttonIconColor: {
        description: 'The icon color to display in the button',
        type: String,
        default: 'theme-active',
      },
      buttonStyling: {
        description: 'Style of the button',
        type: String,
      },
      buttonColor: {
        description: 'The button color.',
        type: String,
        values: [
          'blue',
          'lightblue',
          'red',
          'green',
          'orange',
          'gray',
          'lightgray',
          'white',
          'focus-blue',
          ...themeColorsList,
        ],
        validator: (val) =>
          [
            'blue',
            'lightblue',
            'red',
            'green',
            'orange',
            'gray',
            'lightgray',
            'white',
            'focus-blue',
            ...themeColorsList,
          ].includes(val),
        default: 'theme-background-secondary',
      },
      buttonSticker: {
        description: 'A sticker to display inside the button.',
        type: [String, Number, Boolean],
      },
      disable: {
        description: 'A flag to allow user to use the dropdown or not.',
        type: Boolean,
        default: false,
      },
      color: {
        description: 'The dropdown color.',
        type: String,
        default: 'white',
      },
      align: {
        description: 'Dropdown alignment relative to button',
        type: String,
        values: ['left', 'right'],
        validator: (value) => ['left', 'right'].includes(value),
        default: 'left',
      },
      closeOnScroll: {
        description: 'Close dropdown when scroll detected',
        type: Boolean,
        default: false,
      },
      tooltipText: {
        description: 'Text to appear in a tooltip when hovered over',
        type: String,
        default: '',
      },
      tooltipPosition: {
        description: 'Position of tooltip when hovered over',
        type: String,
        default: 'right',
      },
    },
    data: function () {
      return {
        visible: false,
      };
    },
    computed: {
      buttonColorDisplay() {
        if (this.visible) {
          if (this.buttonColor === 'theme-topbar') {
            return 'theme-hover';
          }
          return 'theme-background-main';
        }
        return this.buttonColor;
      },
      setIconSize() {
        switch (this.size) {
          case 'large':
            return 20;
          case 'medium':
            return 10;
          default:
            return 5;
        }
      },
    },
    methods: {
      show: async function (event) {
        // If this is already visible, then we want to hide the menu
        if (this.visible) {
          this.hide();
          return true;
        }
        this.$emit('change-visible', true);
        this.showEvent = event;
        this.visible = !this.visible;
        this.listenForClosingEvent(event);

        // We need the dropdown to appear before we start figuring out where it should be positioned
        await this.$nextTick();

        // If the dropdown just became visible
        if (this.visible) {
          this.positionDropdown();
        }
      },
      positionDropdown() {
        const buttonBcr = this.$refs.button.$el.getBoundingClientRect();

        // Sort the alignment
        if (this.align === 'left') {
          this.$nextTick(() => {
            if (this.$refs.container && this.$refs.container.style) {
              this.$refs.container.style.left = buttonBcr.left + 'px';
              this.$refs.container.style.top = buttonBcr.bottom + 'px';
            }
          });
        } else if (this.align === 'right') {
          // Calculate the distance from the right edge
          const right = document.body.clientWidth - buttonBcr.right;

          this.$nextTick(() => {
            if (this.$refs.container && this.$refs.container.style) {
              this.$refs.container.style.right = right + 'px';
              this.$refs.container.style.top = buttonBcr.bottom + 'px';
            }
          });
        }
      },
      hide() {
        this.visible = false;
        this.destroyListener();
        this.$emit('change-visible', false);
      },
      checkIfInsideDropdown: function (event) {
        const isSelf = event.target.closest('.s-dropdown') == this.$el;
        const isOpeningClick = this.showEvent == event;
        const isClickingOnOption = !!event.target.closest('.dropdown-content .dropdown-item-wrapper');
        const isClickingFilter = !!event.target.closest('.filter-menu-item');
        const isOptionSubDropdown =
          event.target.closest('li') && event.target.closest('li')?.classList.contains('sub-dropdown');
        if (
          (!isSelf && !isOpeningClick) ||
          (isSelf && isClickingOnOption && !isOptionSubDropdown && !isClickingFilter)
        ) {
          this.hide();
        }
      },
      listenForClosingEvent: function () {
        document.addEventListener('click', this.checkIfInsideDropdown);
        document.addEventListener('scroll', this.positionDropdown);
        if (this.closeOnScroll) {
          window.addEventListener('scroll', this.hide);
        }
      },
      destroyListener: function () {
        document.removeEventListener('click', this.checkIfInsideDropdown);
        document.removeEventListener('scroll', this.positionDropdown);
        window.removeEventListener('scroll', this.hide);
      },
    },
    beforeUnmount() {
      this.destroyListener();
    },
  };
</script>

<style scoped lang="scss">
  @import '@veasel/core';

  .s-dropdown {
    width: fit-content;
    position: relative;

    .button {
      box-shadow: none;
      transition: ease all $transition-time;

      &:not(.styling-block):hover {
        box-shadow: 0 0 6px #00000029;
      }
    }

    & .arrow-icon {
      position: absolute;
      top: 50%;
      right: 14px;
      transform: translate(0, -50%);
      transform-origin: center;
      transition: ease transform $transition-time, ease margin-top $transition-time;
      pointer-events: none;
    }

    &.visible {
      box-shadow: 0 0 6px #00000029;

      & .arrow-icon {
        transform: translate(0, -50%) rotate(180deg);
      }
    }

    & > .s-button {
      padding-right: get-spacing('xl');

      @each $external-theme-color in $external-theme-colors {
        &.theme-#{$external-theme-color}::after {
          color: var(--background-main);
        }
      }

      .button-sticker {
        padding: 0 6px 0 6px;
        margin-right: 6px;
        margin-left: 6px;
      }

      &[disabled] + .arrow-icon ::v-deep(svg) {
        fill: var(--secondary-50);
      }
    }

    .caret-container {
      z-index: 71;

      .dropdown-caret {
        left: calc(50% - 4px);
        top: 0;
      }

      .dropdown-caret.second {
        left: calc(50% - 4px);
        top: 2px;
        z-index: 60;
      }
    }

    .dropdown-container.align-left {
      ::v-deep(ul.options) .dropdown-content {
        left: 198px;
      }
    }

    .dropdown-container.align-right {
      ::v-deep(ul.options) .dropdown-content {
        right: 198px;
      }
    }

    .dropdown-container {
      position: fixed;
      z-index: 71;
    }

    ::v-deep(.dropdown-content) {
      color: $black;
      width: fit-content;
      z-index: 60;
      box-shadow: 0 3px 6px #00000029;
      border: 0;
      padding-bottom: get-spacing(s);
      min-width: 168px;

      .sub-dropdown {
        position: relative;
      }

      ul.options {
        width: 200px;
        padding: 0;
        list-style: none;
        z-index: 61;

        li {
          width: calc(100% - 4px);
          margin: auto;
          text-align: left;
          padding: 0;
          cursor: pointer;

          &.disable {
            cursor: default;

            a {
              opacity: 0.6;
              pointer-events: none;
            }

            &:hover .disable-tooltip {
              display: block;
              opacity: 1;
              position: absolute;
              margin-top: -28px;
              width: fit-content;
              z-index: 80;
              background-color: $lightgray;
              padding: 6px 12px 6px 36px;
              border: 1px solid $black;
              min-width: 200px;

              i.s-icon-help {
                color: $orange;
                font-size: 24px;
                margin-top: -4px;
                vertical-align: middle;
                position: absolute;
                left: 6px;
              }

              &::after,
              &::before {
                position: absolute;
                content: ' ';
                height: 0;
                width: 0;
                background-color: transparent;
                border-top: 4px solid transparent;
                border-bottom: 4px solid transparent;
              }

              &.right {
                left: 196px;

                &::before {
                  left: -7px;
                  top: 9px;
                  border-right: 6px solid $black;
                  border-left: 0 solid transparent;
                }

                &::after {
                  left: -5px;
                  top: 9px;
                  border-right: 6px solid $lightgray;
                  border-left: 0 solid transparent;
                }
              }

              &.left {
                right: 196px;

                &::before {
                  right: -7px;
                  top: 9px;
                  border-left: 6px solid $black;
                  border-right: 0 solid transparent;
                }

                &::after {
                  right: -5px;
                  top: 9px;
                  border-left: 6px solid $lightgray;
                  border-right: 0 solid transparent;
                }
              }
            }
          }

          .disable-tooltip {
            display: none;
          }

          &:hover:not(.disable),
          &.active:not(.disable) {
            background-color: $focus-blue;

            & > a {
              background-color: $focus-blue;
              color: $white;

              & > i {
                color: $white;
              }
            }
          }

          .dropdown-content {
            z-index: 62;
            display: none;
            position: absolute;
            top: 0;
            margin-top: 0;
            margin-left: 0;
          }

          &:hover .dropdown-content {
            display: block;
          }

          & > a {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: calc(100% - 24px);
            display: list-item;
            padding: 6px 12px;
            color: $black;

            i {
              color: $black;
              position: absolute;
              right: 6px;
            }
          }
        }
      }

      hr {
        height: 1px;
        width: calc(100% - 10px);
        border-style: none;
        background: var(--secondary);
      }
    }
  }
</style>
