/**
 *
 *  Websocket Configuration (describing which message to subscribe to in each screen)
 *
 *    <ScreenName>: {
 *      <websocket_channel>: {
 *        <event_type>:  <filter>
 *      }
 *    }
 *
 * *********************************** PLEASE NOTE: ************************************
 *  Be very careful subscribing multiple components (especially modals) to the same ScreenName.
 *  This is because if a parent, child and/or sibling that are all mounted in one instance of a page and they
 *  all subscribe using the same ScreenName, unmounting one of them will trigger an unsubscribe that will also
 *  unintentionally unsubscribe the others from the message breaking functionality. For example, if you were on the
 *  Reports page and it subscribes to ReportDetail in the list below but one of its children (or modals) does too,
 *  moving to another tab or closing the modal (triggering an unmount) will end up unubscribing the Reports parent from
 *  the ReportDetail messages as well.
 *
 **/

export default {
  Home: {
    // events websocket
    events: {
      report_created: 'all',
      data_ingestion_succeeded: 'all',
    },
  },
  DataOverview: {
    // events websocket
    events: {
      data_batch_archived: 'all',
      data_ingestion_started: 'all',
      data_ingestion_succeeded: 'all',
      data_ingestion_failed: 'all',
    },
  },
  DataBatch: {
    // events websocket
    events: {
      data_batch_archived: 'all',
    },
  },
  ReportOverview: {
    // events websocket
    events: {
      report_created: 'all',
      report_uploaded: 'all',
      report_merged: 'all',
      report_archived: 'all',
      report_generation_failed: 'all',
      report_generation_started: 'all',
      report_generation_finished: 'all',
      report_signed_off: 'all',
      report_submitted: 'all',
    },
    jobs: {
      all: 'all',
    },
  },
  ReportDetail: {
    // events websocket
    events: {
      report_archived: 'target.id=$(id)',
      report_comment: 'target.id=$(id)',
      report_data_commented: 'target.id=$(id)',
      report_data_updated: 'target.id=$(id)',
      report_generation_failed: 'target.id=$(id)',
      report_generation_started: 'target.id=$(id)',
      report_generation_finished: 'target.id=$(id)',
      report_merged: 'target.id=$(id)',
      report_reviewed: 'target.id=$(id)',
      report_schedule_archived: 'target.id=$(id)',
      report_schedule_created: 'target.id=$(id)',
      report_schedule_updated: 'target.id=$(id)',
      report_signed_off: 'target.id=$(id)',
      report_simple_update: 'target.id=$(id)',
      report_submitted: 'target.id=$(id)',
      report_updated: 'target.id=$(id)',
      report_uploaded: 'target.id=$(id)',
      report_collection_updated: 'target.id=$(id)',
      report_collection_archived: 'target.id=$(id)',
      visibility_updated: 'target.id=$(id)',
    },
    jobs: {
      all: 'target.id=$(id)',
    },
  },
  CollectionOverview: {
    // events websocket
    events: {
      report_collection_created: 'all',
      report_collection_updated: 'all',
      report_collection_archived: 'all',
    },
  },
  CollectionDetail: {
    // events websocket
    events: {
      report_collection_created: 'target.id=$(id)',
      report_collection_comment: 'target.id=$(id)',
      report_collection_updated: 'target.id=$(id)',
      report_collection_archived: 'target.id=$(id)',
      report_data_updated: 'target.id=$(id)',
    },
    jobs: {
      all: 'target.id=$(id)',
    },
  },
  DataCollectionOverview: {
    // events websocket
    events: {
      data_ingestion_succeeded: 'all',
    },
  },
  DataCollectionDetail: {
    // events websocket
    events: {
      data_ingestion_succeeded: 'all',
    },
  },
  WorkflowOverview: {
    // waiting to have workflow events
  },
  WorkflowDetail: {
    // waiting to have workflow events
  },
  DataMagnetOverview: {
    // events websocket
    events: {
      data_magnet_archived: 'all',
      data_magnet_comment: 'all',
      data_magnet_created: 'all',
      data_magnet_signed_off: 'all',
      data_magnet_updated: 'all',
    },
  },
  DataMagnetDetail: {
    // events websocket
    events: {
      data_magnet_archived: 'target.id=$(id)',
      data_magnet_comment: 'target.id=$(id)',
      data_magnet_created: 'target.id=$(id)',
      data_magnet_signed_off: 'target.id=$(id)',
      data_magnet_updated: 'target.id=$(id)',
    },
  },
  RulesetOverview: {
    // events websocket
    events: {
      ruleset_archived: 'all',
      ruleset_comment: 'all',
      ruleset_created: 'all',
      ruleset_signed_off: 'all',
      ruleset_updated: 'all',
    },
  },
  RulesetDetail: {
    // events websocket
    events: {
      ruleset_archived: 'target.id=$(id)',
      ruleset_comment: 'target.id=$(id)',
      ruleset_created: 'target.id=$(id)',
      ruleset_signed_off: 'target.id=$(id)',
      ruleset_updated: 'target.id=$(id)',
    },
  },
  Regina: {
    // events websocket
    events: {
      data_ingestion_succeeded: 'all',
    },
  },
  SystemOverview: {
    // events websocket
    events: {
      all: 'visibility:all',
    },
    // stats websocket
    stats: {
      all: 'all',
    },
  },
  SystemEvents: {
    // events websocket
    events: {
      all: 'all',
    },
  },
  SystemManage: {
    // events websocket
    events: {
      client_created: 'all',
      client_deleted: 'all',
      client_updated: 'all',
      role_created: 'all',
      role_deleted: 'all',
      role_updated: 'all',
      user_created: 'all',
      user_updated: 'all',
      sso_settings_updated: 'all',
    },
  },
  SystemSettings: {
    // events websocket
    events: {
      config_deleted: 'all',
      config_updated: 'all',
      report_date_range_archived: 'all',
      report_date_range_created: 'all',
      report_date_range_updated: 'all',
    },
  },
  SystemLogs: {
    // logs websocket
    logs: {
      all: 'all',
    },
  },
  RunningJobs: {
    // jobs websocket
    jobs: {
      all: 'all',
    },
  },
  AllJobs: {
    // jobs websocket
    jobs: {
      all: 'all',
    },
  },
  ReportLog: {
    report: {
      all: 'all',
    },
    events: {
      report_generation_finished: 'target.id=$(id)',
    },
  },
};
