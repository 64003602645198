<template>
  <div class="step-timeline-container">
    <template v-for="(s, index) in steps" :key="'step-' + index + '-' + s.label">
      <div class="step" :title="s.label">
        <div
          class="circle"
          :style="{
            'border-style': dynamicProp(index, 'circle.line'),
            'border-color': 'var(--' + dynamicProp(index, 'circle.color') + ')',
            'background-color': 'var(--' + dynamicProp(index, 'circle.bgcolor') + ')',
          }"
          @click="$emit('selected', index)"
        >
          <s-icon
            :type="dynamicProp(index, 'icon.type')"
            :color="'theme-' + dynamicProp(index, 'icon.color')"
            size="large"
          />
        </div>
        <div v-if="labels" class="step-label m-t-s ellipsis" @click="$emit('selected', index)">{{ s.label }}</div>
      </div>
      <div
        v-if="index < steps.length - 1"
        :key="'connector-' + index + '-' + s"
        class="connector"
        :class="dynamicProp(index, 'connector.class')"
        :style="{
          'border-bottom-style': dynamicProp(index, 'connector.line'),
          'border-bottom-color': 'var(--' + dynamicProp(index, 'connector.color') + ')',
        }"
      >
        <div
          class="link-bubble left"
          :style="{'background-color': 'var(--' + dynamicProp(index, 'connector.color') + ')'}"
        ></div>
        <s-icon
          v-if="dynamicProp(index, 'connector.icon.type')"
          class="connector-icon"
          :type="dynamicProp(index, 'connector.icon.type')"
          :color="'theme-' + dynamicProp(index, 'connector.icon.color')"
        />
        <div
          class="link-bubble right"
          :style="{'background-color': 'var(--' + dynamicProp(index, 'connector.color') + ')'}"
        ></div>
      </div>
    </template>
  </div>
</template>

<script>
  import {getValueAtPath} from '@veasel/core/tools';
  import icon from '@veasel/base/icon';

  const defaultValues = {
    'icon.type': ['checkmark', 'button', 'clock'],
    'icon.color': ['approved', 'main-dark', 'secondary-light'],
    'circle.color': ['secondary', 'secondary', 'secondary-light'],
    'circle.bgcolor': ['background-main', 'background-main', 'background-main'],
    'circle.line': ['solid', 'solid', 'solid'],
    'connector.icon.type': ['', '', ''],
    'connector.icon.color': ['active', 'active', 'active'],
    'connector.line': ['solid', 'solid', 'solid'],
    'connector.color': ['main-dark', 'secondary-light', 'secondary-light'],
    'connector.class': ['active', 'active', ''],
  };

  // const stepExample = {
  //   label: 'step name',
  //   icon: {
  //     type: {
  //       default: 'default icon',
  //       pre: 'icon when step index < current step',
  //       current: 'icon when step index = current step',
  //       post: 'icon when step index > current step',
  //     },
  //     color: {
  //       default: 'default icon color',
  //       pre: 'icon color when step index < current step',
  //       current: 'icon color when step index = current step',
  //       post: 'icon color when step index > current step',
  //     },
  //   },
  //   circle: {
  //     color: {
  //       default: 'default circle color',
  //       pre: 'circle color when step index < current step',
  //       current: 'circle color when step index = current step',
  //       post: 'circle color when step index > current step',
  //     },
  //     bgcolor: {
  //       default: 'default circle background color',
  //       pre: 'circle background color when step index < current step',
  //       current: 'circle background color when step index = current step',
  //       post: 'circle background color when step index > current step',
  //     },
  //     line: {
  //       default: 'default circle line type',
  //       pre: 'circle line type when step index < current step',
  //       current: 'circle line type when step index = current step',
  //       post: 'circle line type when step index > current step',
  //     },
  //   },
  //   connector: {
  //     icon: {
  //       type: {
  //         default: 'default icon on the connector',
  //         pre: 'icon on the connector when step index < current step',
  //         current: 'icon on the connector when step index = current step',
  //         post: 'icon on the connector when step index > current step',
  //       },
  //       color: {
  //         default: 'default color of the icon on the connector',
  //         pre: 'color of the icon on the connector when step index < current step',
  //         current: 'color of the icon on the connector when step index = current step',
  //         post: 'color of the icon on the connector when step index > current step',
  //       },
  //     },
  //     color: {
  //       default: 'default color of the connector',
  //       pre: 'color of the connector when step index < current step',
  //       current: 'color of the connector when step index = current step',
  //       post: 'color of the connector when step index > current step',
  //     },
  //     line: {
  //       default: 'default line type of the connector',
  //       pre: 'line type of the connector when step index < current step',
  //       current: 'line type of the connector when step index = current step',
  //       post: 'line type of the connector when step index > current step',
  //     },
  //     class: {
  //       default: 'default css class of the connector',
  //       pre: 'css class of the connector when step index < current step',
  //       current: 'css class of the connector when step index = current step',
  //       post: 'css class of the connector when step index > current step',
  //     }
  //   },
  // }

  export default {
    name: 's-step-timeline',
    components: {
      's-icon': icon,
    },
    props: {
      step: {
        description: 'Positive integer index of the current step starting at zero',
        type: Number,
        required: true,
        validator: (s) => s >= 0 && Number.isInteger(s),
      },
      steps: {
        description: 'Array of possible steps as strings in sequential order',
        type: Array,
        required: true,
        validator: (a) => a.every((s) => typeof s === 'object'),
      },
      labels: {
        description: 'True if the text labels for each step should be shown',
        type: Boolean,
        default: true,
      },
    },

    emits: ['selected'],

    methods: {
      dynamicProp(index, dottedKey) {
        const currentStep = this.steps[index];
        return this.step === index
          ? getValueAtPath(currentStep, dottedKey + '.current') ||
              getValueAtPath(currentStep, dottedKey + '.default') ||
              defaultValues[dottedKey][1]
          : this.step < index
          ? getValueAtPath(currentStep, dottedKey + '.post') ||
            getValueAtPath(currentStep, dottedKey + '.default') ||
            defaultValues[dottedKey][2]
          : getValueAtPath(currentStep, dottedKey + '.pre') ||
            getValueAtPath(currentStep, dottedKey + '.default') ||
            defaultValues[dottedKey][0];
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@veasel/core/sass/conf';

  .step-timeline-container {
    padding: 10px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .step {
      position: relative;
      flex: 0 1 auto;
      font-size: 18px;

      .step-label {
        position: absolute;
        font-size: 14px;
        white-space: nowrap;
        width: 10rem;
        margin-left: -3.5rem;
        cursor: pointer;
        text-align: center;
      }

      &:first-child {
        margin-left: 30px;
      }

      &:last-child {
        margin-right: 30px;
      }

      .circle {
        margin-top: -0.55rem;
        height: 3rem;
        width: 3rem;
        border-width: 2px;
        border-style: solid;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.2s ease;
      }
    }

    .connector {
      position: relative;
      flex: 1 0 auto;
      vertical-align: middle;
      max-height: 8px;
      margin-top: 0.5rem;
      margin-left: 4px;
      margin-right: 4px;
      border-bottom: 2px solid $lightgray;
      transition: all 0.2s ease;

      .link-bubble {
        content: '';
        position: absolute;
        height: 0.85rem;
        width: 0.85rem;
        border-radius: 0.425rem;
        border: 0.125rem solid white;
        background-color: red;
        z-index: 3;
        pointer-events: none;

        &.left {
          top: 0.05rem;
          left: -0.625rem;
        }

        &.right {
          top: 0.05rem;
          right: -0.625rem;
        }
      }

      .connector-icon {
        position: absolute;
        margin-left: calc(50% - 0.75rem);
        background-color: $white;
        padding: 0 0.25rem;
      }
    }
  }
</style>
