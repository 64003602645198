<template>
  <s-input-wrapper v-bind="$props" isBoolean :alignRight="!!alignRight">
    <div class="switch-container">
      <label
        v-if="placeholderIsArray"
        :for="id"
        class="input-label-bool theme-style-base-small m-t-xs label-left"
        :disabled="disabled"
        @click="switchValue"
      >
        {{ placeholder[0] }}
      </label>
      <div
        :id="id"
        :name="name"
        class="switch-wrapper s-input-group-switch"
        :class="{disabled: disabled, active: $_value}"
        @click="switchValue"
      >
        <div class="switch-button" :disabled="disabled"></div>
      </div>
      <label
        v-if="placeholder || (label && labelMod === 'inside')"
        :for="id"
        class="input-label-bool label-right"
        :class="[size === 'small' ? 'theme-style-base-small' : 'theme-style-base']"
        :disabled="disabled"
        @click="switchValue"
      >
        {{ placeholderIsArray ? placeholder[1] : _placeholder }}
      </label>
    </div>
  </s-input-wrapper>
</template>

<script>
  import SInputWrapper from '@veasel/inputs/input-wrapper';
  import {isEmpty} from '@veasel/core/tools';
  import {
    syncValue,
    onChange,
    onClick,
    onBlur,
    onFocus,
    id,
    name,
    isDisabled,
    isRequired,
    placeholder,
    label,
    size,
  } from '@veasel/core/mixins';

  export default {
    name: 's-input-bool',
    description: 'A user input as a switch button for boolean value.',
    components: {SInputWrapper},
    mixins: [
      syncValue([Boolean, String]),
      onChange(),
      onClick(),
      onBlur(),
      onFocus(),
      id,
      name,
      isDisabled,
      isRequired,
      placeholder,
      label,
      size(),
    ],
    props: {
      label: {
        description: 'A label to describe the input.',
        type: String,
        default: '',
      },
      alignRight: {
        description: 'A flag to align the input to the left.',
        type: Boolean,
        default: false,
      },
    },
    computed: {
      currentLabel: function () {
        return this.$_value ? this.labelOn : this.labelOff;
      },
      hasLabel: function () {
        return !isEmpty(this.label);
      },
    },
    methods: {
      switchValue: function () {
        if (!this.disabled) {
          this.$_value = !this.$_value;
          this.$_onChange();
        }
      },
      $_formatValue: function (val) {
        if (typeof val === 'string') {
          if (val.toLowerCase() === 'true') {
            return true;
          } else if (val.toLowerCase() === 'false') {
            return false;
          }
        }
        return val;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@veasel/core/sass/conf';

  // BOOLEAN SWITCH

  .switch-container {
    display: flex;
    align-items: center;
  }

  .s-input.medium .s-input-group-switch.switch-wrapper {
    height: 1.5rem;
    min-width: 2.5rem;
    border-radius: 1.5rem;

    .switch-button {
      height: 1.125rem;
      width: 1.125rem;
      left: 0.2rem;
      top: 0.2rem;
      border-radius: 50%;
    }

    &.active .switch-button {
      left: 1.25rem;
    }

    &::after {
      border-radius: 1rem;
    }
  }

  .s-input.small .s-input-group-switch.switch-wrapper {
    height: 1rem;
    min-width: 1.5rem;
    border-radius: 1rem;

    .switch-button {
      height: 0.75rem;
      width: 0.75rem;
      left: 0.2rem;
      top: 0.125rem;
      border-radius: 1rem;
    }

    &.active .switch-button {
      left: calc(100% - 0.875rem);
    }

    &::after {
      border-radius: 1rem;
    }
  }

  .s-input {
    position: relative;

    .s-input-group.switch-group {
      background: none;
      width: 100%;
    }

    .s-input-group-switch.switch-wrapper {
      background: var(--background-main);
      //background: var(--secondary-light);
      position: relative;
      box-sizing: border-box;
      cursor: pointer;
      border: 0;
      text-align: center;

      &.disabled {
        cursor: not-allowed;
        border-color: var(--secondary-50);
        pointer-events: none;

        &.active {
          background-color: var(--secondary-50);

          &::after {
            display: none;
          }
        }

        .switch-button {
          pointer-events: none;
          cursor: not-allowed;
          background-color: var(--secondary-50);
        }
      }

      &.active {
        background-color: var(--active);
        border: 0 solid var(--active);

        .switch-button {
          background-color: var(--background-main);
        }

        &::after {
          border: 2px solid var(--active);
        }
      }

      &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        box-sizing: border-box;
        left: 0;
        border: 1px solid var(--secondary);
      }

      &:hover {
        &::after {
          border: 2px solid var(--active);
        }
      }

      .switch-button {
        position: absolute;
        border: 0;
        background-color: var(--secondary);
        transition: 0.2s;
      }
    }

    .input-label-bool {
      margin-left: 8px;
      margin-right: 8px;
      cursor: pointer;

      &[disabled] {
        pointer-events: none;
        cursor: default;
      }
    }
  }
</style>
