<template>
  <s-modal
    id="modal6"
    v-model="internalVisible"
    title="Edit table cell"
    size="medium"
    required
    class="datatable-cell-edit theme-style-base"
  >
    <div v-if="internalVisible" class="x-grid">
      <div>
        <div class="align-center">
          Editing
          <b>{{ data.col.title }}</b>
          for row {{ data.rowIndex + 1 }} (
          <b>{{ rowIdentifier }}</b>
          )
        </div>
      </div>
      <div class="m-t-m m-b-m">
        <p>{{ data.col.description }}</p>
      </div>
      <!-- Nested object inputs -->
      <div v-if="data.input.type === 'object'" class="row x-row">
        <div v-for="input in data.input.schema" :key="input.key" :title="input.description" class="col-12 m-b-s">
          <component
            :is="'s-input-' + (input.type || 'text')"
            v-bind="input.options"
            v-model="value[input.key]"
            :label="input.title || input.key"
          ></component>
        </div>
      </div>
      <!-- Nested array inputs -->
      <div v-else-if="data.input.type === 'array'" class="relative">
        <!-- Array of objects -->
        <div v-if="data.input.schema instanceof Array" class="array-container">
          <div v-if="!value.length" class="m-t-m align-center">Array is empty</div>
          <div v-for="(v, index) in value" :key="index" class="row array-line x-row m-b-m">
            <div
              v-for="input in data.input.schema"
              :key="input.key"
              :title="input.description"
              :class="'col-' + Math.max(parseInt(11 / data.input.schema.length), 2)"
            >
              <component
                :is="'s-input-' + (input.type || 'text')"
                v-bind="input.options"
                v-model="v[input.key]"
                :label="input.title || input.key"
              ></component>
            </div>
            <div class="col-1">
              <s-button class="remove-line" color="theme-error" icon="cross" :on-click="() => value.splice(index, 1)" />
            </div>
          </div>
          <s-button class="add-line" text="Add" icon="plus" color="theme-active" :on-click="() => value.push({})" />
        </div>
        <!-- Array of values -->
        <div v-else-if="data.input.schema instanceof Object" class="array-container">
          <div v-if="!value.length" class="m-t-m align-center">Array is empty</div>
          <div v-for="(v, index) in value" :key="index" class="row array-line x-row m-b-m">
            <div class="col-11">
              <component
                :is="'s-input-' + (data.input.schema.type || 'text')"
                v-bind="data.input.schema.options"
                v-model="value[index]"
                class="cell-input"
                :label="data.input.schema.title || humanize(data.col.data) + ' ' + (index + 1)"
              ></component>
            </div>
            <div class="col-1">
              <s-button class="remove-line" color="theme-error" icon="cross" :on-click="() => value.splice(index, 1)" />
            </div>
          </div>
          <div class="col-2">
            <s-button class="add-line" text="Add" icon="plus" color="theme-active" :on-click="() => value.push(' ')" />
          </div>
        </div>
      </div>
      <!-- Basic input -->
      <div v-else>
        <component
          :is="'s-input-' + (data.input.type || 'text')"
          v-bind="data.input.options"
          v-model="value"
          class="cell-input"
          :label="data.col.title"
        ></component>
      </div>
      <div class="flex justify-content-space-between m-t-l">
        <s-button class="cell-cancel" text="Cancel" color="theme-background-secondary" :on-click="cancel" />
        <s-button class="cell-save" text="Save" color="theme-active" :on-click="saveEdit" data-cy="save-cell-edit" />
      </div>
    </div>
  </s-modal>
</template>

<script>
  import {clone, escapeHtml, getValueAtPath} from '@veasel/core/tools';
  import {humanize} from '@veasel/core/formatters';

  import button from '@veasel/base/button';

  import inputCheckbox from '@veasel/inputs/input-checkbox';
  import inputColor from '@veasel/inputs/input-color';
  import inputDatetime from '@veasel/inputs/input-datetime';
  import inputFile from '@veasel/inputs/input-file';
  import inputMonetary from '@veasel/inputs/input-monetary';
  import inputNumber from '@veasel/inputs/input-number';
  import inputQuery from '@veasel/inputs/input-query';
  import inputRadio from '@veasel/inputs/input-radio';
  import inputRadioGroup from '@veasel/inputs/input-radio-group';
  import inputSelect from '@veasel/inputs/input-select';
  import inputSlider from '@veasel/inputs/input-slider';
  import inputText from '@veasel/inputs/input-text';
  import inputTextarea from '@veasel/inputs/input-textarea';

  export default {
    name: 'data-ingestion-cell-edit',

    components: {
      's-button': button,
      's-input-checkbox': inputCheckbox,
      's-input-color': inputColor,
      's-input-datetime': inputDatetime,
      's-input-file': inputFile,
      's-input-monetary': inputMonetary,
      's-input-number': inputNumber,
      's-input-query': inputQuery,
      's-input-radio': inputRadio,
      's-input-radioGroup': inputRadioGroup,
      's-input-select': inputSelect,
      's-input-slider': inputSlider,
      's-input-text': inputText,
      's-input-textarea': inputTextarea,
    },

    emits: ['update:visible', 'save-cell-edit'],

    props: {
      visible: Boolean,
      data: Object,
      currencyExponentDefinition: Object,
    },

    data: function () {
      return {
        value: '',
      };
    },

    watch: {
      visible() {
        if (this.visible) {
          this.initModal();
        } else {
          this.destroyModal();
        }
      },
      data: function (data) {
        if (data.input && data.input.options) {
          this.retrieveAsynchronousOptions(data.input.options);
        }
      },
    },

    computed: {
      internalVisible: {
        get() {
          return this.visible;
        },
        set(val) {
          this.$emit('update:visible', val);
        },
      },
      rowIdentifier: function () {
        const value = getValueAtPath(this.data.row, this.data.uniqId) || 'unknown';
        return value;
      },
    },

    methods: {
      initModal: function () {
        if (!this.data.value) {
          if (this.data.input.type === 'array') {
            if (this.data.input.schema instanceof Array) {
              this.value = [{}];
            } else {
              this.value = [null];
            }
          } else if (this.data.input.type === 'object') {
            this.value = {};
          } else {
            this.value = undefined;
          }
        } else {
          this.value = clone(this.data.value);

          // Add < and > back in for visual purposes only. These will be replaced again later
          if (typeof this.value === 'string') {
            this.value = this.convertToTag(this.value);
          }
        }
        document.addEventListener('keyup', this.enterHandler);
      },
      destroyModal() {
        document.removeEventListener('keyup', this.enterHandler);
      },
      convertToTag(value) {
        return value.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
      },
      enterHandler(e) {
        if (e.key === 'Enter') {
          this.saveEdit();
        } else if (e.key === 'Escape') {
          this.cancel();
        }
      },
      saveEdit: function () {
        this.value = this.value === null || this.value === undefined ? undefined : this.value;

        if (typeof this.value === 'string') {
          this.value = this.convertToTag(escapeHtml(this.value));
        }

        this.$emit('save-cell-edit', this.value);
        this.value = undefined;
      },
      cancel() {
        this.internalVisible = false;
      },
      retrieveAsynchronousOptions: function (input) {
        if (typeof input.dynamicOptions === 'function') {
          input.dynamicOptions(this.data.row).then((options) => {
            input['options'] = options;
          });
        }
        if (input.currency_to_use === 'row_currency') {
          input['currency'] = this.data.row.currency_code || this.data.row.base_currency_code;
        }
        if (input.currency_to_use === 'row_currency' && this.currencyExponentDefinition[this.data.row.currency_code]) {
          input['currencyExponent'] =
            this.currencyExponentDefinition[this.data.row.currency_code || this.data.row.base_currency_code].exponent;
        }
      },
      humanize,
    },
  };
</script>

<style lang="scss" scoped>
  .array-container {
    min-height: 50px;
  }
</style>
