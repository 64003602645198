<template>
  <div class="group-outer theme-style-base">
    <div class="group-settings">
      <div v-if="editing" class="group-settingsl-row">
        <span v-if="!root && editing" class="draggable-icon"><s-icon type="drag-indicator" color="black" /></span>
        <s-button
          v-if="removable"
          text=""
          :onClick="removeGroup"
          color="theme-error"
          icon="bin"
          class="term-button remove-group"
        />
        <s-input-select
          v-model="groupOperator"
          :options="operatorOptions"
          track-by="key"
          :required="true"
          class="operator-select"
          data-cy="input-select-query-group-operator"
        />
      </div>
      <div v-else>
        <div class="center-text operator-label theme-style-base">{{ operatorLabel(group.operator) }}</div>
      </div>
    </div>
    <draggable
      v-model="draggableTerms"
      draggable=".draggable"
      :disabled="!editing || group.terms.length < 2"
      class="group-container"
      item-key="uid"
    >
      <template #item="{element, index}">
        <div v-if="element.type === 'term'" :id="element.uid" class="term-container draggable">
          <span v-if="editing && group.terms.length > 1" class="draggable-icon">
            <s-icon type="drag-indicator" color="black" />
          </span>
          <s-button
            v-if="editing && group.terms.length > 1"
            text=""
            :onClick="() => removeTerm(index)"
            color="theme-error"
            icon="bin"
            class="term-button remove"
          />
          <s-button
            v-else-if="editing && group.terms.length == 1 && simplifyable"
            text=""
            :onClick="simplify"
            color="theme-warning"
            icon="arrow-drop-left"
            class="term-button simplify"
          />
          <s-input-query
            v-model="element.query"
            :options="options"
            :multiple="false"
            :editable="isEditing"
            :clearable="false"
            class="term-search"
            :data-cy="`search-query-group-term-${index}`"
          />
        </div>
        <div v-else :id="element.uid" class="term-container draggable">
          <s-query-group
            v-model:group="element.group"
            :options="options"
            :removable="group.terms.length > 1"
            :simplifyable="element.group.terms.length === 1"
            :editing="editing"
            :allowImport="allowImport"
            :uniqId="uniqId"
            @remove="() => removeTerm(index)"
            @simplify="() => simplifyTerm(index)"
            @import-clicked="importClickedInChild"
          />
        </div>
      </template>
      <template #footer>
        <div v-if="editing" class="term-container">
          <s-button
            text="Add Term"
            :onClick="addTerm"
            color="theme-active"
            icon="plus"
            class="term-button add"
            data-cy="button-query-group-add-term"
          />
          <s-button
            text="Add Group"
            :onClick="addGroup"
            color="theme-active"
            icon="plus"
            class="term-button add-group"
            data-cy="button-query-group-add-group"
          />
          <s-button
            v-if="allowImport"
            text="Import Logic"
            :onClick="importClicked"
            color="theme-active"
            icon="download"
            class="term-button import"
            data-cy="button-query-group-import"
          />
        </div>
      </template>
    </draggable>
  </div>
</template>

<script>
  import draggable from 'vuedraggable';
  import button from '@veasel/base/button';
  import icon from '@veasel/base/icon';
  import inputQuery from '@veasel/inputs/input-query';
  import inputSelect from '@veasel/inputs/input-select';

  export default {
    name: 's-query-group',

    components: {
      draggable: draggable,
      's-button': button,
      's-icon': icon,
      's-input-select': inputSelect,
      's-input-query': inputQuery,
    },

    props: {
      group: Object,
      options: Object,

      // Flag if this group can be removed
      removable: {
        type: Boolean,
        default: true,
      },

      // Flag if this group can be simplified (reduced to a single term instead of a group)
      simplifyable: {
        type: Boolean,
        default: false,
      },

      // Used to show/hide the buttons for adding and removing terms and groups and selecting operators
      editing: {
        type: Boolean,
        required: true,
      },

      // True if this is the root group, hide some features
      root: {
        type: Boolean,
        default: false,
      },

      // True if the import button should be shown to allow existing logic to be imported
      allowImport: {
        type: Boolean,
        default: false,
      },

      uniqId: {
        type: Function,
        required: true,
      },
    },

    data: () => ({
      operatorOptions: [
        {key: 'and', label: 'AND'},
        {key: 'or', label: 'OR'},
        {key: 'nand', label: 'AND NOT'},
        {key: 'nor', label: 'OR NOT'},
      ],
      isEditing: true,
    }),
    emits: ['update:group', 'remove', 'simplify', 'import-clicked', 'update:editing'],
    computed: {
      groupOperator: {
        get() {
          return this.group.operator;
        },
        set(val) {
          // Overwrite by emitting an updated group object
          const group = {...this.group};
          group.operator = val;
          this.$emit('update:group', group);
        },
      },

      // This property is read and modified by draggable when dragging terms around
      draggableTerms: {
        get() {
          return this.group.terms;
        },
        set(val) {
          // Overwrite by emitting an updated group object
          this.$emit('update:group', {...this.group, terms: val});
        },
      },
    },

    watch: {
      // It appears that when editing gets updated, properties that rely on this does not get re-rendered :(. So this little hack helps force Vue to rerender
      editing(newValue) {
        this.isEditing = newValue;
      },
    },

    methods: {
      // Add a new empty term to this group
      addTerm() {
        // Create a copy of the group object - do not mutate the group property directly
        const newGroup = {...this.group};

        // Add the term
        newGroup.terms.push({
          type: 'term',
          query: [],
          uid: this.uniqId('term_'), // All terms need unique IDs to update and render correctly
        });

        // Emit the change
        this.$emit('update:group', newGroup);
      },

      // Add a nested group as a term within this group
      addGroup() {
        // Create a copy of the group object - do not mutate the group property directly
        const newGroup = {...this.group};

        // Add the new group with one empty term
        newGroup.terms.push({
          type: 'group',
          group: {
            operator: 'and',
            terms: [
              {
                type: 'term',
                query: [],
                uid: this.uniqId('term_'),
              },
            ],
          },
          uid: this.uniqId('group_'), // Group terms also need unique IDs
        });

        // Emit the change
        this.$emit('update:group', newGroup);
      },

      // Remove an existing term from the group, using the copy/mutate/emit strategy
      removeTerm(index) {
        const newGroup = {...this.group};

        newGroup.terms.splice(index, 1);

        this.$emit('update:group', newGroup);
      },

      // Remove button pressed, emit remove event to parent
      removeGroup() {
        this.$emit('remove');
      },

      // Simplify button pressed, emit simplify event to parent
      simplify() {
        this.$emit('simplify');
      },

      // Called when a child (nested) group emits a simplify event
      simplifyTerm(index) {
        const newGroup = {...this.group};

        // Find the term to be simplified, which should be a group, and replace it with the first (and only) term it contains
        if (newGroup.terms[index].type === 'group') {
          newGroup.terms.splice(index, 1, newGroup.terms[index].group.terms[0]);
        }

        this.$emit('update:group', newGroup);
      },

      operatorLabel(key) {
        for (let i = 0; i < this.operatorOptions.length; i++) {
          if (this.operatorOptions[i].key === key) {
            return this.operatorOptions[i].label;
          }
        }
      },

      editClicked() {
        this.$emit('update:editing', !this.editing);
      },

      // The user has clicked import on this query group, pass up the importLogic function as callback
      importClicked() {
        this.$emit('import-clicked', this.importLogic);
      },

      // The user has clicked import on a nested group, pass the callback upwards
      importClickedInChild(callback) {
        this.$emit('import-clicked', callback);
      },

      // This callback is called once the user has selected a set of rules to import, and
      // those rules have been inflated into a group in the queryBuilder component
      importLogic(importedGroup) {
        this.$emit('update:group', importedGroup);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .term-container {
    display: flex;
    flex-direction: row;
    justify-content: left;
    transform: unset !important;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    &:not(:first-child) {
      .group-outer {
        margin-top: 10px;
      }
    }

    .term-button {
      margin-right: 5px;
    }

    .term-search {
      flex: 0 0 auto;
    }
  }

  ::v-deep(.search.relative.s-input-group-field) {
    display: flex !important;
    align-items: center !important;
  }

  ::v-deep(.setting-container) {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  .group-outer {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-bottom: 10px;
  }

  .group-settings {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    ::v-deep(.operator-button) {
      min-width: 86px;

      &.inactive {
        opacity: 0.3;
      }
    }

    .operator-select {
      min-width: 125px;
    }

    .group-settingsl-row {
      display: flex;
      flex-direction: row;
      justify-content: left;
      min-width: 75px;
      width: min-content;
    }
  }

  .group-container {
    flex: 0 1 auto;
    border-left: 1px solid black;
    border-radius: 3px;
    padding-left: 10px;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .draggable-icon {
    line-height: 36px;
    padding-top: 2px;
    cursor: grab;
  }
</style>
