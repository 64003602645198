import {uniqId} from '@veasel/core/tools';

export default {
  data() {
    return {
      isEditing: false,
      isReplacing: false,
      editingIndex: 0,
      isAddVisible: false,
      addIndex: 0,
      isSelectingAttributes: false,
      isEditingAttributes: false,
      attrKey: undefined,
      attrValue: undefined,
      selectedValue: undefined,
      selectedAttribute: undefined,
      anonymousAttrIndex: 0,
    };
  },

  methods: {
    addElem(index) {
      this.currentType = (this.elements[index] || {type: 'start'}).type;
      this.isEditing = true;
      this.editingIndex = index;
      this.openInput('inputSelect');
    },

    editElem(index) {
      this.currentType = (this.elements[index - 1] || {type: 'start'}).type;
      this.isEditing = true;
      this.isReplacing = true;
      this.editingIndex = index;
      this.openInput('inputSelect');
    },

    editAttrByKey(index, key) {
      this.isEditing = true;
      this.isEditingAttributes = true;
      this.isReplacing = true;
      this.editingIndex = index;
      this.isSelectingAttributes = true;
      this.attrKey = key;
      this.attrValue = undefined;
      this.openInput('inputValueSelect');
    },

    editAttrByIndex(index, attrIndex) {
      this.isEditing = true;
      this.isEditingAttributes = true;
      this.isReplacing = true;
      this.editingIndex = index;
      this.isSelectingAttributes = true;
      this.anonymousAttrIndex = attrIndex;
      this.selectedValue = undefined;
      this.attrKey = this.elements[index].attrs[attrIndex].key;
      this.attrValue = undefined;
      this.openInput('inputValueSelect');
    },

    stopEditing() {
      this.isEditing = false;
      this.isEditingAttributes = false;
      this.isReplacing = false;
      this.editingIndex = 0;
      this.isSelectingAttributes = false;
      this.anonymousAttrIndex = 0;
      this.selectedValue = undefined;
      this.attrKey = undefined;
      this.attrValue = undefined;
    },

    removeElem(index) {
      this.elements.splice(index, 1);
      this.currentType = (this.elements[this.elements.length - 1] || {type: 'start'}).type;
      this.stopEditing();
      this.saveValue();
    },

    clearAll() {
      this.elements = [];
      this.currentType = 'start';
      this.warnings = {};
      this.isEditing = false;
      this.isReplacing = false;
      this.editingIndex = 0;
      this.isLeftHovered = {};
      this.isRightHovered = {};
      this.isSelectingAttributes = false;
      this.isEditingAttributes = false;
      this.attrKey = undefined;
      this.attrValue = undefined;
      this.selectedOption = undefined;
      this.selectedValue = undefined;
      this.selectedAttribute = undefined;
      this.anonymousAttrIndex = 0;
      this.saveValue();
    },

    optionSelected() {
      this.currentType = this.selectedOption?.type || 'start';

      const element = {
        ...this.selectedOption,
        attributes: this.selectedOption.attrs && this.selectedOption.named ? {} : undefined,
        anonymousAttributes: this.selectedOption.attrs && !this.selectedOption.named ? [] : undefined,
        id: uniqId(),
      };

      if (this.isEditing) {
        if (this.isReplacing) {
          // REPLACE EXISTING ELEMENT
          this.elements.splice(this.editingIndex, 1, element);
          this.isReplacing = element.attrs;
          this.isEditing = element.attrs;
        } else {
          // ADD ELEMENT IN THE MIDDLE
          this.editingIndex++;
          this.elements.splice(this.editingIndex, 0, element);
        }
      } else {
        // APPEND AN ELEMENT AT THE END
        this.elements.push(element);
      }
      if (element.attrs) {
        // SELECTED FUNCTION
        this.isSelectingAttributes = true;

        if (element.named) {
          this.openInput('inputAttributeSelect');
        } else {
          this.anonymousAttrIndex = 0;
          this.attrKey = element.attrs[this.anonymousAttrIndex].key;
          this.openInput('inputValueSelect');
        }
      } else {
        // SELECTED BASIC OPTION
        this.openInput('inputSelect');
        this.checkValidity();
      }
    },

    attributeSelected() {
      // SELECTED ATTRIBUTE KEY
      if (this.selectedAttribute.key === 'done') {
        this.finalizeFunctionElement();
      } else {
        this.attrKey = this.selectedAttribute.key;
        // this.currentAttributes = {...this.currentAttributes, [this.attrKey]: null};
        this.elements[this.elemIndex].attributes[this.attrKey] = null;
        this.openInput('inputValueSelect');
      }
    },

    valueSelected(origin) {
      // SELECTED ATTRIBUTE VALUE
      if ((this.currentAttr.multiple && origin === 'change') || (!this.currentAttr.multiple && origin === 'blur')) {
        return;
      }
      if (
        this.isEditing === false &&
        this.isReplacing === false &&
        this.isEditingAttributes === false &&
        this.isSelectingAttributes === false
      ) {
        // We're no longer editing, so we don't need to do anything here
        return true;
      }

      if (this.elements[this.elemIndex].named) {
        if (this.attrKey === undefined) {
          return;
        }
        if (this.currentAttr.multiple && this.currentAttr.type !== 'select') {
          if (this.selectedValue !== null && this.selectedValue !== undefined && this.selectedValue !== '') {
            this.attrValue = [
              ...(this.isReplacing ? [] : this.elements[this.elemIndex].attributes[this.attrKey] || []),
              this.selectedValue,
            ];
            this.isReplacing = false;
            this.elements[this.elemIndex].attributes[this.attrKey] = this.attrValue;
            if (origin === 'enter' || (this.currentAttr.type === 'datetime' && this.currentAttr.multiple)) {
              this.openInput('inputValueSelect');
              return;
            }
          }
        } else {
          this.attrValue = this.selectedValue && this.selectedValue.key ? this.selectedValue.key : this.selectedValue;
        }

        if (this.attrValue !== null && this.attrValue !== undefined) {
          this.elements[this.elemIndex].attributes[this.attrKey] = this.attrValue;
        }
        this.attrKey = undefined;
        if (this.attributesOptions.length === 1 || this.isEditingAttributes) {
          this.finalizeFunctionElement();
          return;
        } else {
          this.openInput('inputAttributeSelect');
        }
      } else {
        this.attrValue = this.selectedValue.key || this.selectedValue;
        if (this.isEditingAttributes) {
          this.elements[this.elemIndex].anonymousAttributes.splice(this.anonymousAttrIndex, 1, this.attrValue);
        } else {
          this.elements[this.elemIndex].anonymousAttributes.push(this.attrValue);
        }
        this.anonymousAttrIndex++;
        this.attrValue = undefined;
        if (this.anonymousAttrIndex === this.elements[this.elemIndex].attrs.length || this.isEditingAttributes) {
          this.finalizeFunctionElement();
        } else {
          this.attrKey = this.elements[this.elemIndex].attrs[this.anonymousAttrIndex].key;
          this.openInput('inputValueSelect');
        }
      }
      if (this.isEditingAttributes) {
        this.stopEditing();
      }
    },

    setDefaultAttrs(element) {
      element.attrs
        .filter((a) => a.default)
        .forEach((a) => {
          if (element.attributes[a.key] === undefined) {
            element.attributes[a.key] = a.default;
          }
        });
    },

    finalizeFunctionElement() {
      this.setDefaultAttrs(this.elements[this.elements.length - 1]);
      this.currentType = (this.elements[this.elements.length - 1] || {type: 'start'}).type;
      this.isSelectingAttributes = false;
      this.attrKey = undefined;
      this.attrValue = undefined;
      this.isEditingAttributes = false;
      this.isSelectingAttributes = false;
      this.anonymousAttrIndex = 0;
      if (this.isReplacing) {
        this.isReplacing = false;
        this.isEditing = false;
      } else {
        this.openInput('inputSelect');
      }
      this.saveValue();
    },

    finishSelection() {
      this.isRightHovered = {};
      this.isLeftHovered = {};

      if (!this.isSelectingAttributes) {
        this.isEditing = false;
      }
      if (this.isEditingAttributes) {
        this.isEditingAttributes = false;
      }

      this.selectedOption = undefined;
      this.selectedAttribute = undefined;
      this.selectedValue = undefined;

      if (!this.isSelectingAttributes) {
        this.saveValue();
      }
    },

    openInput(inputRef) {
      this.$nextTick(() => {
        this.selectedOption = undefined;
        this.selectedAttribute = undefined;
        this.selectedValue = undefined;
        if (this.$refs[inputRef]) {
          this.$refs[inputRef].$el.querySelector('input').focus();
        }
      });
    },
  },
};
