// This directive allows the modal section of an sl-input-datetime component to escape
// from a scrollable container, by temporarily taking the element out of the
// standard page flow by setting its position to 'fixed' and faking it's position.
export default {
  install(Vue) {
    Vue.directive('color-overflow', {
      mounted: (el, binding, vnode) => {
        // If the overflow is explicitly disabled, return early
        if (binding.value === 'disabled') {
          return;
        }

        // If the overflow behaviour is explicitly enabled, set active to true
        let active = binding.value === 'enabled';

        // If not explicitly enabled, attempt to auto detect if the behaviour is necessary
        if (!active && binding.value === 'auto') {
          let inScrollableContainer = false;
          let parent = el.parentElement;

          while (parent && !inScrollableContainer) {
            inScrollableContainer = ['auto', 'scroll'].includes(getComputedStyle(parent).overflowY);

            parent = parent.parentElement;
          }

          active = inScrollableContainer;
        }

        // If not explicitly enabled or auto detected, return early
        if (!active) {
          return;
        }

        // Target the actual input element, not the container
        const input = el.childNodes[1];

        // Runtime state variables
        let inputIsOpen = false;

        let originalWidth;
        let originalPosition;
        let originalZIndex;
        let originalTop;
        let originalMarginTop;

        let placeholder;

        let debouncedWidth = null;
        let pendingWidth = null;
        let widthDebounceCount = 0;

        // Attach a watcher to the input open state
        binding.instance.$watch('displayPicker', (isOpen) => {
          // State changed to open
          if (isOpen && !inputIsOpen) {
            // Get top position and width
            const top = el.getBoundingClientRect().top;
            const width = (debouncedWidth || input.offsetWidth) + 1;

            // Stash original values
            originalWidth = input.style.width;
            originalPosition = input.style.position;
            originalZIndex = input.style.zIndex;
            originalTop = input.style.top;

            // Switch to fixed position
            input.style.width = `${width}px`;
            input.style.position = 'fixed';
            input.style.zIndex = 71;
            input.style.top = `${top}px`;

            // Create a placeholder element to preserve the components effect on the layout flow
            placeholder = document.createElement('div');
            placeholder.style.minHeight = '38px';
            input.parentNode.insertBefore(placeholder, input.nextSibling);
          } else if (!isOpen && inputIsOpen) {
            // State changed to closed, switch back to original values
            input.style.position = originalPosition;
            input.style.width = originalWidth;
            input.style.zIndex = originalZIndex;
            input.style.marginTop = originalMarginTop;
            input.style.top = originalTop;

            // Remove the placeholder
            placeholder.remove();
          }

          // Record change of state
          inputIsOpen = isOpen;
        });

        // Monitor and debounce the width of the input to smooth out
        // popping jank due to scroll bar
        vnode.widthWatcher = window.setInterval(() => {
          // Only interested in the width of the input when closed
          if (inputIsOpen) {
            return;
          }

          // If the width does not match the debounced value
          if (input.offsetWidth !== debouncedWidth) {
            // If it is a repetition of the previous value, increment the count
            if (input.offsetWidth === pendingWidth) {
              widthDebounceCount++;
            } else {
              // Reset the count if this is a new width
              widthDebounceCount = 0;
            }

            // Record the width value for the next iteration
            pendingWidth = input.offsetWidth;

            // If the value has been stable for 5 iterations update the debounced width
            if (widthDebounceCount >= 5) {
              debouncedWidth = pendingWidth;
            }
          } else {
            // Reset all variables if the value matches the debounced value
            pendingWidth = debouncedWidth;
            widthDebounceCount = 0;
          }
        }, 100);
      },

      // When the component is removed, cancel the interval watching the width
      unmounted: (_, __, vnode) => {
        window.clearInterval(vnode.widthWatcher);
      },
    });
  },
};
