<template>
  <div class="graph-wrap graph-pie" data-chart>
    <graph-tooltip :showTooltip="showTooltip" :details="tooltipData" />
  </div>
</template>

<script>
  import {arc, pie, pointer, scaleOrdinal, schemeSet3} from 'd3';
  import SVGGraph from '../utils/svgGraphMixin.js';
  import graphTooltip from './graphTooltip.vue';

  export default {
    name: 's-graph-pie',
    components: {
      graphTooltip,
    },
    props: {
      values: {
        description: 'The number values to be used when constructing the graph.',
        type: Array,
        required: true,
      },
      options: {
        description: 'The options object, to specify style.',
        type: Object,
        default: () => ({}),
      },
      trackId: {
        description: 'The unique identifier for the wrapper that the svg must be applied to',
        type: String,
        required: true,
      },
    },
    mixins: [SVGGraph],
    data() {
      return {
        dotColors: [],
      };
    },
    methods: {
      drawPieGraph: function () {
        this.setupPieGraphOptions();
        this.setupGraph();

        this.drawArcs();

        this.svgGraph.on('mouseleave', () => {
          this.showTooltip = false;
        });
      },
      drawArcs: function () {
        // divide by height because all graph types need to be the same height
        const radius = this.graphOptions.height / 2;
        const schema = scaleOrdinal().domain(this.values).range(schemeSet3);
        const group = this.svgGraph.append('g').attr('transform', `translate(${radius}, ${radius})`);

        group
          .selectAll('arc')
          .data(pie()(this.values))
          .enter()
          .append('path')
          .attr('fill', (data) => this.getColorFromSchema(data, schema))
          .attr('d', this.generateArcs(radius)) // set arc coords on path
          .on('mousemove', (event, data) => {
            this.handleMouseMove(event, data);
          });
      },
      handleMouseMove: function (event, data) {
        const [posX, posY] = pointer(event);

        this.tooltipData.dotColor = this.dotColors[data.index];

        this.tooltipData.text = data.value;
        this.tooltipData.posX = posX + 15;
        this.tooltipData.posY = posY - 10;
      },
      getColorFromSchema: function (data, schema) {
        const schemeColor = schema(data);
        this.dotColors.unshift(schemeColor); // last item needs to be first

        return schemeColor;
      },
      generateArcs: function (radius) {
        return arc()
          .innerRadius(0) // 0 to prevent donut effect
          .outerRadius(radius);
      },
      setupPieGraphOptions: function () {
        const defaults = {
          width: 30,
          height: 30,
          marginTop: 0,
        };
        this.graphOptions = this.getGraphOptions(defaults);
      },
    },

    mounted() {
      this.drawPieGraph();
    },
  };
</script>
