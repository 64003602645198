import {safeGet} from '@veasel/core/tools';

export default {
  data: function () {
    return {
      checkboxSelectAll: false,
      checkboxSelectionMap: {},
      checkboxSelectionMemory: {},
    };
  },

  computed: {
    selectAllLabel() {
      if (this.opts.checkboxes.actions.some((a) => a.visible) || this.opts.additionalFilter.display) {
        return undefined;
      } else {
        return (
          'Select All' + (this.checkboxSelection.length > 0 ? ' (' + this.checkboxSelection.length + ' selected)' : '')
        );
      }
    },
    actions() {
      return this.opts.exportView.listing
        ? [
            ...this.opts.checkboxes.actions,
            {
              name: 'Export listing',
              visible: true,
              callback: this.exportListing,
              safeVisible: () => true,
              safeDisable: () => false,
            },
          ]
        : this.opts.checkboxes.actions;
    },
    // store selection status as an object map
    // store selection as an array of checkboxes
    checkboxSelection: function () {
      const selection = [];
      for (const key in this.checkboxSelectionMap) {
        const row =
          this.checkboxSelectionMemory[key] ||
          this.opts.checkboxes.selection.filter((r) => safeGet(r, this.opts.uniqId) == key)[0] ||
          this.visibleRows.filter((r) => safeGet(r, this.opts.uniqId) == key)[0] ||
          key;
        selection.push(row);
        this.checkboxSelectionMemory[key] = row;
      }
      return selection;
    },
    // store selection as an array of ids (using provided key)
    checkboxSelectionIds: function () {
      return this.checkboxSelection.map((s) => (typeof s == 'string' ? s : safeGet(s, this.opts.uniqId)));
    },
    hasOnlyOneEnabledAction: function () {
      return this.actions.length === 1 && (this.actions[0].disable === undefined || this.actions[0].disable === false);
    },
  },

  watch: {
    // store selection status as an object map
    checkboxSelectionMap: {
      handler: function (value) {
        let shouldUpdate = true;
        for (const key in value) {
          if (value[key] === false) {
            shouldUpdate = false;
          }
        }
        if (shouldUpdate) {
          this.checkboxSelectionUpdated();
        } else {
          for (const i in this.visibleRows) {
            const row = this.visibleRows[i];
            if (value[safeGet(row, this.opts.uniqId)]) {
              this.checkboxSelectionMemory[safeGet(row, this.opts.uniqId)] = row;
            } else if (value[safeGet(row, this.opts.uniqId)] !== undefined) {
              delete this.checkboxSelectionMemory[safeGet(row, this.opts.uniqId)];
              delete this.checkboxSelectionMap[safeGet(row, this.opts.uniqId)];
            }
          }
        }
      },
      deep: true,
    },
    rows: {
      handler() {
        this.$nextTick(() => {
          this.updateCheckboxSelectAllState();
          this.updateSelectedCheckboxes();
        });
      },
      deep: true,
    },
  },
  methods: {
    // the select all checkboxes has been updated
    updateCheckboxSelectAll() {
      if (this.checkboxSelectAll) {
        for (const i in this.visibleRows) {
          const row = this.visibleRows[i];
          if (this.hasOneActionAvailable(row)) {
            this.checkboxSelectionMemory[safeGet(row, this.opts.uniqId)] = row;
            this.checkboxSelectionMap[safeGet(row, this.opts.uniqId)] = true;
          }
        }
      } else {
        this.unselectAll();
      }
    },
    // If the rows have updated, and the row that was selected are no longer visit, then lets clear it
    updateSelectedCheckboxes() {
      if (safeGet(this.opts, 'checkboxes.resetSelectionAfterRowChange') === false) {
        const selectionMap = {};
        this.visibleRows
          .filter((row) => {
            return Object.keys(this.checkboxSelectionMap).includes(safeGet(row, this.opts.uniqId) + ''); // Make sure we are comparing strings with strings
          })
          .forEach((row) => {
            selectionMap[safeGet(row, this.opts.uniqId)] = true;
          });
        this.checkboxSelectionMap = selectionMap;
      }
    },
    updateCheckboxSelectAllState() {
      const allRowsFromThisPageAreAlreadyChecked = this.visibleRows.every((row) =>
        this.checkboxSelectionIds.includes(safeGet(row, this.opts.uniqId))
      );
      this.checkboxSelectAll = allRowsFromThisPageAreAlreadyChecked;
    },
    // emit checkboxes selection
    checkboxSelectionUpdated: async function () {
      await this.$nextTick();
      this.$emit(this.opts.checkboxes.event, this.checkboxSelectionIds, this.checkboxSelection);
    },
    hasOneActionAvailable: function (row) {
      if (this.actions.length === 0) {
        return true;
      }
      for (const action of this.actions) {
        if (action.disable === false || (typeof action.disable === 'function' && !action.disable([row]))) {
          return true;
        }
      }
      return false;
    },
    unselectAll: function () {
      this.checkboxSelectionMap = {};
      this.checkboxSelectionMemory = {};
    },
    callTheActionCallback: function () {
      this.actions[0].callback(this.checkboxSelectionIds, this.checkboxSelection);
    },
  },
};
