/**
 * Allow a component to call a specific function or emit an event when element is clicked
 * @param {boolean} required
 * @return {object}
 */
const onClick = function (required = false) {
  return {
    props: {
      onClick: {
        description: 'Function called or event emitted on component click',
        type: [Function, String],
        required: required,
      },
    },
    methods: {
      $_onClick: function (event) {
        if (typeof this.onClick == 'function') {
          this.onClick(event);
        } else if (typeof this.onClick == 'string') {
          this.$emit(this.onClick, event);
          this.$el.dispatchEvent(new Event(this.onClick));
        } else if (required) {
          console.error(this.$options.name + ' requires onClick prop');
        }
      },
    },
  };
};

export default onClick;
