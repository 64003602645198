export default {
  name: 'dataIngestion',
  namespaced: true,
  state: {
    selectedCells: [],
  },
  mutations: {
    setSelectedCells: function (state, cells) {
      state.selectedCells = cells;
    },
  },
  actions: {
    setSelectedCells: function (context, cells) {
      if (cells) {
        context.commit('setSelectedCells', cells);
      }
    },
  },
};
