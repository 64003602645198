<template>
  <s-input-wrapper v-bind="$props">
    <div class="search relative s-input-group-field">
      <div class="elements-clear-all">
        <!--ELEMENTS CONTAINER-->
        <draggable
          v-model="displayedElements"
          :move="checkMove"
          draggable=".draggable"
          class="elements-container"
          :class="{full: isFull}"
          :disabled="!draggable || hasEditingElement"
          item-key="id"
        >
          <template #item="{element, index}">
            <s-element
              :ref="addToElementRefs"
              :options="mergeOptions"
              :elementToEdit="element"
              :draggable="draggable && multiple && !hasEditingElement"
              :full="isFull"
              :editable="editable"
              :removable="clearable"
              :overflow="overflow"
              :size="size"
              @update:element="(el) => $emit('update:modelValue', el)"
              @previous-element="toPreviousElement(index)"
              @remove-element="onRemoveElement(index)"
              @label-on-top="labelOnTopChanged"
            />
          </template>
        </draggable>

        <!--        REMOVE BUTTON-->
        <div v-show="elements.length > 1 && clearable" class="clear-search-button" role="button">
          <s-icon type="cross" :on-click="clearAll" size="10" />
        </div>
      </div>
    </div>
  </s-input-wrapper>
</template>

<script>
  import SInputWrapper from '@veasel/inputs/input-wrapper';
  import {isEmpty} from '@veasel/core/tools';
  import {
    syncValue,
    onChange,
    onBlur,
    onFocus,
    onKeyPressed,
    onPaste,
    label,
    placeholder,
    isDisabled,
    isRequired,
    pattern,
    maxLength,
    helperMessages,
    size,
  } from '@veasel/core/mixins';
  import optionsFactory from '../utils/optionsFactory';
  import sElement from './element.vue';
  import draggable from 'vuedraggable';

  export default {
    name: 's-input-query',

    description: 'A complex search bar handling multiple filters with any number of steps.',

    components: {
      SInputWrapper,
      's-element': sElement,
      draggable: draggable,
    },

    mixins: [
      syncValue([Object, Array]),
      onChange(),
      onBlur(),
      onFocus(),
      onKeyPressed(),
      onPaste(),
      label,
      placeholder,
      isDisabled,
      isRequired,
      pattern,
      maxLength,
      helperMessages,
      size(),
    ],

    props: {
      options: {
        description: 'The options object, to specify advanced features.',
        type: Object,
      },
      multiple: {
        description: 'A flag to allow multiple filters.',
        type: Boolean,
        default: true,
      },
      draggable: {
        description: 'A flag to allow reordering filters by drag and drop.',
        type: Boolean,
        default: true,
      },
      editable: {
        description: 'A flag to allow updating existing filters.',
        type: Boolean,
        default: true,
      },
      clearable: {
        description: 'A flag to allow deleting existing filters.',
        type: Boolean,
        default: true,
      },
      label: {
        description: 'The descriptive label for the input.',
        type: String,
      },
      overflow: {
        description: 'Indicates whether the input dropdowns within should escape from scrollable overflow containers',
        type: String,
        values: ['auto', 'enabled', 'disabled'],
        validator: (v) => ['auto', 'enabled', 'disabled'].includes(v),
        default: 'auto',
      },
    },
    emits: ['update:modelValue'],

    data: function () {
      return {
        // Contains each query element. Each element is made up of a number of steps, and has a corresponding s-element instance
        elements: [],

        // Unique IDs are needed for each element to update the elements in the for loop
        uid: 0,

        labelOnTop: false,

        elementRefs: [],
      };
    },

    computed: {
      mergeOptions() {
        return optionsFactory(this.options);
      },

      hasEditingElement() {
        // find an editing element (except the last one: the empty element which must be displayed or not)
        for (let i = 0; i < this.elements.length - 1; i++) {
          if (this.elements[i].isEditing) {
            return true;
          }
        }

        return false;
      },

      // The last empty element is displayed only when not currently editing an element
      displayedElements: {
        get() {
          return this.elements
            .slice(0, this.hasEditingElement ? this.elements.length - 1 : this.elements.length)
            .map((t) => {
              t.editable = this.editable;
              return t;
            });
        },
        set(val) {
          // This setter is only called when the order of elements is updated by dragging. The final (empty) element will
          // always be included because elements are only draggable when none of the elements are being edited.
          this.elements = val;
        },
      },

      // If in single mode (:multiple = false), return whether or not there is an element in place
      isFull() {
        if (!this.multiple && this.elements.length > 0 && this.elements[0].isEditing === false) {
          return true;
        }

        return false;
      },
    },

    watch: {
      // When any of the elements change, update the query
      elements: {
        handler() {
          this.updateQueryValue();

          // There should always be an extra, empty element at the end to allow further input (unless multiple = false and one already exists)
          this.ensureHasEmptyElement();
        },
        deep: true,
      },
    },

    methods: {
      // Initialise the search component. Can provide a query to initialize from
      initElements(query = null) {
        const elementsFromValue = [];

        if (!query) {
          // During first time initialisation, use the current query value
          query = this.$_value;
        }

        for (let i = 0; i < query.length; i++) {
          // If multiple values not supported, limit to just the first
          if (!this.multiple && i > 0) {
            break;
          }

          const queryValue = query[i];

          this.uid = i;
          const element = {id: this.uid, isEditing: false, steps: []};

          const props = Object.keys(queryValue);
          let stepOption = this.mergeOptions.NEXT_STEP;

          let makeArray = false;
          let makeNextArray = false;

          // For each element in the query iterate through the properties
          for (let j = 0; j < props.length; j++) {
            if (!stepOption) {
              break;
            }

            let value = queryValue[props[j]];
            element.steps[j] = {STEP_OPT: stepOption, value: value};

            // if it's a select with object options
            if (stepOption && stepOption.options && typeof stepOption.options === 'object') {
              // if it's a multiple select
              if (stepOption.multiple) {
                element.steps[j].value = [];

                for (let k = 0; k < value.length; k++) {
                  const oneValue = value[k];
                  let findOption = false;

                  for (let l = 0; !findOption && l < stepOption.options.length; l++) {
                    const opt = stepOption.options[l];
                    if (opt.key === oneValue) {
                      findOption = true;
                      // add the object option in the array
                      element.steps[j].value.push(opt);
                    }
                  }

                  if (!findOption) {
                    // TODO: warn the user
                    console.warn(
                      '"' +
                        oneValue +
                        '" is not in the list of available select options for the field "' +
                        stepOption.role +
                        '" (hint: "' +
                        stepOption.hint +
                        '")'
                    );
                  }
                }
              } else {
                let findOption = false;

                // If substitutes exist, apply them first (substitute 'equals' operator for 'in' for example)
                if (stepOption.substitute) {
                  for (let k = 0; k < stepOption.substitute.length; k++) {
                    const sub = stepOption.substitute[k];

                    if (sub.replace === value) {
                      value = sub.with;

                      // When substituting operators, this field indicates whether the next step (value) needs to be converted to an array
                      makeNextArray = sub.makeArray;
                    }
                  }
                }

                for (let k = 0; !findOption && k < stepOption.options.length; k++) {
                  const opt = stepOption.options[k];
                  if (opt.key === value) {
                    findOption = true;
                    element.steps[j].value = opt;
                    // step options for the next prop
                    stepOption = opt.NEXT_STEP;
                  }
                }

                if (!findOption) {
                  // TODO: warn the user
                  console.warn(
                    '"' +
                      value +
                      '" is not in the list of available select options for the field "' +
                      stepOption.role +
                      '" (hint: "' +
                      stepOption.hint +
                      '")'
                  );
                  element.warn = true;
                }
              }
            }

            // If set, make sure the value for this step is an array
            if (makeArray) {
              if (!Array.isArray(element.steps[j].value)) {
                element.steps[j].value = [element.steps[j].value];
              }

              makeArray = false;
            }

            // If set, the next iteration the value needs to be made into an array
            if (makeNextArray) {
              makeNextArray = false;
              makeArray = true;
            }
          }

          if (element.steps.length > 0) {
            elementsFromValue.push(element);
          }
        }

        this.elements = elementsFromValue;
      },

      addToElementRefs(element) {
        this.elementRefs.push(element);
      },

      // There should always be an extra, empty element at the end to allow further input (unless multiple = false and one already exists)
      ensureHasEmptyElement() {
        if (
          this.elements.length === 0 ||
          (this.multiple && this.elements.length > 0 && this.elements[this.elements.length - 1].steps.length > 0)
        ) {
          this.elements.push({id: (this.uid += 1), isEditing: true, steps: []});
        }
      },

      toPreviousElement(index) {
        // Delete even if no previous element exists (so that the first element can be deleted by pressing backspace)
        this.elements.splice(index, 1);

        // If there is a previous element attempt to edit it
        if (this.elementRefs[index - 1]) {
          this.elementRefs[index - 1].editLastValue();
        }
      },

      // Attempt to remove an element
      onRemoveElement(index) {
        if (this.elements.length > 0) {
          this.elements.splice(index, 1);
        }
      },

      clearAll() {
        this.elements = [];
      },

      updateQueryValue() {
        const query = [];
        for (let i = 0; i < this.elements.length; i++) {
          const element = this.elements[i];

          if (!element.isEditing) {
            const queryEl = {};

            for (let j = 0; j < element.steps.length; j++) {
              const step = element.steps[j];

              if (step && step.STEP_OPT) {
                queryEl[step.STEP_OPT.role] = Array.isArray(step.value) // If the value is an array, map results to an array.
                  ? step.value.map((val) =>
                      typeof val == 'object' // If the values within the array are objects
                        ? val.key //  take only the key field,
                        : val
                    ) //  otherwise take the value itself.
                  : typeof step.value == 'object' // If the value is not an array,
                  ? step.value.key //  take the key field if the value is an object,
                  : step.value; //  otherwise take the value itself.
              }
            }

            if (!isEmpty(queryEl)) {
              query.push(queryEl);
            }
          }
        }
        this.$_value = query;
      },

      checkMove(evt) {
        // The last postion is always for the empty element
        return evt.draggedContext.futureIndex !== this.elements.length - 1;
      },

      labelOnTopChanged(value) {
        this.labelOnTop = value;
      },
    },

    created() {
      this.initElements();
    },

    beforeUpdate() {
      this.elementRefs = [];
    },
  };
</script>

<style lang="scss" scoped>
  @import '@veasel/core';

  $input-box-shadow-focus: 0 0 6px #00000029;

  .search {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .s-input :focus {
    outline: 1px solid transparent;
    background: var(--background-main);
    -webkit-box-shadow: $input-box-shadow-focus;
    box-shadow: $input-box-shadow-focus;
  }

  .elements-clear-all {
    .clear-search-button {
      pointer-events: none;
      display: block;
      position: absolute;
      opacity: 0;
      right: 6px;
      top: 10px;
      padding: 0 2px;
      background: var(--background-main);
      transition: ease all 0.1s;

      &:hover ::v-deep(svg) {
        cursor: pointer;
        fill: var(--hover);
      }
    }

    &:hover .clear-search-button {
      display: block;
      opacity: 1;
      pointer-events: auto;
    }
  }

  .element-container:first-child:not(.empty-element) {
    margin-left: get-spacing('s');
  }

  .elements-container {
    overflow: visible;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: content-box;

    ::v-deep(*) {
      box-sizing: content-box;
    }

    &.full {
      width: unset;
    }
  }

  .s-input-group.full {
    background: transparent;
  }
</style>
