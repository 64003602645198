<template>
  <div class="organization-chart">
    <div ref="chartContainer"></div>
  </div>
</template>

<script>
  import {OrgChart} from 'd3-org-chart';

  const DEFAULT_OPTIONS = {
    nodeWidth: 400,
    nodeHeight: 150,
    nodeBorderWidth: 2,
    nodeBorderRadius: 15,
    cornerShape: 'CIRCLE', // ['ORIGINAL','ROUNDED','CIRCLE'][Math.round(Math.random()*2)];
    imageWidth: 155,
    imageHeight: 155,
    centerTopDistance: 76,
    centerLeftDistance: 25,
  };

  export default {
    name: 's-organization-chart',
    props: {
      groups: {
        description: 'The groups each node falls under. Each group must have an "id" and "color" field',
        type: Array,
        default: () => [
          {
            id: 1,
            color: {red: 0, green: 0, blue: 0},
          },
        ],
      },
      nodes: {
        description:
          'Array of individual node data. Each object must have an "id", "parent_id", "group" and optional "image" field.',
        type: Array,
        default: () => [
          {
            id: 1,
            parent_id: null,
            group: 1,
          },
        ],
      },
      nodeTemplate: {
        description:
          'A function that returns an html template to be displayed on each node card. Accepts "node" as a function parameter',
        type: Function,
      },
      options: {
        description: 'Node data of the organization chart',
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        chartReference: null,
      };
    },
    computed: {
      nodeData() {
        return this.nodes.map(this.getNode);
      },
    },
    watch: {
      data: {
        handler(value) {
          if (value instanceof Array && value.length) {
            this.renderChart(value);
          }
        },
        deep: true,
      },
    },
    methods: {
      renderChart(data) {
        if (!this.chartReference) {
          this.chartReference = new OrgChart();
        }
        this.chartReference
          .container(this.$refs.chartContainer) // node or css selector
          .data(data)
          .svgWidth(800)
          .svgHeight(600)
          .initialZoom(0.5)
          .render();
      },

      getNode(node) {
        const expanded = !node.parent_id;
        const group = this.groups.find((t) => t.id === node.group);
        const parent = this.nodes.find((e) => e.id == node.parent_id) || {};
        const parentGroup = this.groups.find((t) => t.id === parent.group) || {};
        const picture = node.image || null;

        return {
          nodeId: node.id, // node identificator
          parentNodeId: node.parent_id, // parent node identificator
          width: this.optOrDefault('nodeWidth'), // node card width
          height: this.optOrDefault('nodeHeight'), // node card height
          borderWidth: this.optOrDefault('nodeBorderWidth'), // node card border width
          borderRadius: this.optOrDefault('nodeBorderRadius'), // node card border radius
          borderColor: {
            // border color
            red: 0,
            green: 0,
            blue: 0,
            alpha: 1,
          },
          backgroundColor: {
            // background color of node card
            ...group.color,
            alpha: 1,
          },
          nodeImage: {
            // node image properties
            url: picture, // url to image
            width: this.optOrDefault('imageWidth'), // image width
            height: this.optOrDefault('imageHeight'), // image height
            centerTopDistance: this.optOrDefault('centerTopDistance'), // vertical distance from center
            centerLeftDistance: this.optOrDefault('centerLeftDistance'), // horizontal distance from node center
            cornerShape: this.optOrDefault('cornerShape'), // corner shape, can be 'ORIGINAL','ROUNDED','CIRCLE'
            shadow: false, // if image has shadow (if yes, performance may be poor for 50+ visible nodes)
            borderWidth: 2, // image border width
            borderColor: {
              // image border color
              red: 0,
              green: 0,
              blue: 0,
              alpha: 1,
            },
          },
          // node card content
          template: this.nodeTemplate
            ? this.nodeTemplate(node)
            : `<div style="margin-top:20px; margin-left:125px;">
                      <div style="margin-top:10px; font-size:26px; font-weight:bold;">${node.name} </div>
                      <div style="margin-top:3px; font-size:22px;">${node.position}</div>
                      <div style="margin-top:3px; font-size:18px;">Group: ${node.group}</div>`,
          connectorLineColor: {
            // Edge color
            ...(parentGroup ? parentGroup.color : {red: 0, green: 0, blue: 0}),
            alpha: 1,
          },
          connectorLineWidth: 5, // Edge width
          dashArray: '', // We can have stripped edges, you should specify dashArray for it (google: svg dashArray)
          expanded: expanded, // If we want node to be expanded
        };
      },

      // Get a value from the options property, or from defaults if not present
      optOrDefault(key) {
        return this.options && this.options[key] !== undefined ? this.options[key] : DEFAULT_OPTIONS[key];
      },
    },
    mounted() {
      if (this.nodeData.length) {
        this.renderChart(this.nodeData);
      }
    },
  };
</script>
