const YYYYMM_FORMAT = 'year-numeric-month-numeric';

export default {
  computed: {
    months: function () {
      const months = [];

      const day = this.firstYearDay.clone();

      while (day.isBefore(this.lastYearDay)) {
        months.push({
          date: day.clone(),
        });
        day.add(1, 'month');
      }

      this.emitDayRangeEvent();
      return months;
    },

    // first day of the year
    firstYearDay: function () {
      return this.internalDatetimeFocused.startOf('year');
    },

    // last day of the year
    lastYearDay: function () {
      return this.internalDatetimeFocused.endOf('year');
    },
  },

  methods: {
    switchToMonthlyView: function (monthDate) {
      this.internalDatetimeFocused = monthDate;
      this.internalView = 'monthly';
      this.monthlyEventsByDay = [];
    },

    getEventsOfTheMonth: function (monthDate) {
      const month = monthDate.format(YYYYMM_FORMAT);

      const eventsOfTheMonth = this.events.filter((event) => {
        if (!event.end && event.start) {
          return event.start.format(YYYYMM_FORMAT) === month;
        } else if (event.start && event.end) {
          return (
            event.start.format(YYYYMM_FORMAT) === month ||
            event.end.format(YYYYMM_FORMAT) === month ||
            (event.start.isBefore(monthDate) && event.end.isAfter(monthDate))
          );
        }
      });

      eventsOfTheMonth.sort((a, b) => a.start.valueOf() - b.start.valueOf());

      return eventsOfTheMonth;
    },
  },
};
