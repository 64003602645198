<template>
  <div class="relative flex">
    <!-- FILTERS PICKER -->
    <filters v-if="showFilters" v-model="query.filters" :options="options" class="flex-shrink-0" />
    <div class="flex flex-wrap flex-grow m-l-xs">
      <!-- CURRENT FILTERS -->
      <div v-if="filtersToDisplay && filtersToDisplay.length" class="flex-wrap flex-grow active-filters-wrapper">
        <div class="active-filters">
          <span v-for="filter in filtersToDisplay" :key="filter.key" class="active-filter">
            <span class="filter-name theme-style-base-small-background-main">
              <span class="theme-style-base-small-bold-background-main">{{ filter.label }}</span>
              {{ filter.filter }}
            </span>
            <span
              class="filters-selected theme-style-base-small-bold"
              :data-cy="'filters-selected-' + filter.label.toLowerCase().replace(' ', '-')"
            >
              <span class="filter-selected">
                <span class="selected-name">{{ filter.values }}</span>
                <s-icon
                  type="cross"
                  class="remove"
                  color="theme-secondary"
                  hover-color="theme-main-dark"
                  size="8"
                  @click="() => removeFilter(filter.key)"
                />
              </span>
            </span>
          </span>
          <s-button
            v-if="filtersToDisplay.length > 1"
            text="Clear All"
            color="theme-secondary-light"
            class="clear-all m-r-s"
            @click="clearFilters"
          />
        </div>
      </div>
      <!-- SEARCH BOX -->
      <div v-if="showSearch" class="search">
        <s-input-text
          v-model="query.search"
          class="list-search"
          placeholder="Search"
          data-cy="datalist-search-input"
          design-style="search"
        ></s-input-text>
      </div>
    </div>
  </div>
</template>

<script>
  import parsingMethods from '../utils/parsingMethods.js';

  import button from '@veasel/base/button';
  import icon from '@veasel/base/icon';
  import inputText from '@veasel/inputs/input-text';
  import filters from './filters.vue';

  import {debounce, isDeepEqual, humanizeCapitalizeString} from '@veasel/core/tools';
  import {STime} from '@veasel/core/time';

  export default {
    name: 's-filtered-search',
    description: 'Search with optional filtering',
    components: {
      's-button': button,
      's-icon': icon,
      's-input-text': inputText,
      filters,
    },
    emits: ['update:modelValue', 'change'],
    props: {
      options: {
        description: 'Search filters',
        type: Object,
        default: () => ({}),
      },
      modelValue: {
        description: 'Search query filters',
        type: Object,
        default: () => ({search: '', filters: {}}),
      },
      showFilters: {
        description: 'Toggles filters',
        type: Boolean,
        default: true,
      },
      showSearch: {
        description: 'Toggles search',
        type: Boolean,
        default: true,
      },
      parsing: {
        description: 'Query Parsing functions',
        type: [Object, String],
        default() {
          return {
            parseQuery: (query) => query,
            serializeQuery: (query) => query,
          };
        },
      },
    },
    data() {
      return {
        throttledFilter: debounce(this.filterChange, 750),
        query: {},
      };
    },
    computed: {
      parsingFunc() {
        if (typeof this.parsing === 'string' && parsingMethods[this.parsing.toLowerCase()]) {
          return parsingMethods[this.parsing.toLowerCase()].parseQuery;
        } else {
          return this.parsing.parseQuery || ((query) => query);
        }
      },
      serializingFunc() {
        if (typeof this.parsing === 'string' && parsingMethods[this.parsing.toLowerCase()]) {
          return parsingMethods[this.parsing.toLowerCase()].serializeQuery;
        } else {
          return this.parsing.serializeQuery || ((query) => query);
        }
      },

      filtersToDisplay() {
        return Object.keys(this.query.filters || {})
          .filter((f) => this.options.find((o) => o.key === f))
          .map((key) => {
            const valueType = this.query.filters[key].constructor.name;
            const option = this.options.find((o) => o.key === key);
            const getValueFromOptions = (value) => {
              let returnValue;
              const foundOption =
                option.options && option.type === 'select'
                  ? option.options.find((o) => o[option.additionalProperties.trackBy] == value || o == value)
                  : null; // TWO equals to avoid '6' != 6 (e.g. comes from route query as string)
              if (foundOption) {
                returnValue = foundOption[option.additionalProperties.displayKey || 'label'];
              } else {
                returnValue = value;
              }
              return humanizeCapitalizeString(returnValue);
            };
            return {
              key: key,
              label: option.label,
              filter: valueType === 'Object' ? this.query.filters[key].filter.replace(/_+/g, ' ') : '',
              values:
                valueType === 'Object'
                  ? Object.keys(this.query.filters[key])
                      .filter((k) => k !== 'filter')
                      .sort()
                      .map((k) =>
                        new STime(this.query.filters[key][k], 'UTC').format(
                          option.additionalProperties &&
                            option.additionalProperties.scope &&
                            option.additionalProperties.scope === 'datetime'
                            ? 'date-medium-time-short'
                            : 'date-medium'
                        )
                      )
                      .join(' and ')
                  : valueType === 'Array'
                  ? this.query.filters[key].map(getValueFromOptions).join(', ')
                  : getValueFromOptions(this.query.filters[key]),
            };
          });
      },
    },

    watch: {
      modelValue: {
        immediate: true,
        deep: true,
        handler(newValue) {
          const newQuery = {filters: this.serializingFunc(newValue.filters, this.options), search: newValue.search};
          // If the query is actually different, then save it
          if (!isDeepEqual(newQuery, this.query)) {
            this.query = newQuery;
          }
        },
      },
      query: {
        deep: true,
        handler(newValue) {
          const parsedValue = {filters: this.parsingFunc(newValue.filters, this.options), search: newValue.search};
          this.$emit('update:modelValue', parsedValue);
          this.throttledFilter();
        },
      },
    },

    methods: {
      clearFilters() {
        this.query = {filters: {}, search: this.query.search};
      },
      removeFilter(key) {
        this.query = {
          filters: this.serializingFunc(
            JSON.parse(JSON.stringify({...this.query.filters, [key]: undefined})),
            this.options
          ),
          search: this.query.search,
        };
      },
      filterChange() {
        this.$emit('change', this.modelValue);
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@veasel/core/sass/conf';
  @import '@veasel/core/sass/base/spacing';

  /**
  There are lots of nibbly 1px hackery below to get the filters working when there are too many of them to fit on one line.
  If you edit these, you should test this scenario and make sure it all works.
  Failure to do so will bring curses on your children and your children's children. You have been warned.
  **/
  .active-filters-wrapper {
    padding: 0;
  }

  .active-filters {
    line-height: 36px;

    .active-filter {
      overflow: hidden;
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
      margin-right: 4px;
      box-sizing: border-box;
      user-select: none;

      .filter-name {
        background: var(--label-1);
        padding: 10px 7px 9px 8px;
        vertical-align: top;
        line-height: 34px;
        border-top-left-radius: $button-radius;
        border-bottom-left-radius: $button-radius;
      }

      .filters-selected {
        vertical-align: top;
        line-height: 30px;
      }

      .filter-selected {
        border-top: 1px solid var(--label-1);
        border-right: 1px solid var(--label-1);
        border-left: 1px solid var(--label-1);
        border-bottom: 1px solid var(--label-1);
        padding-left: 8px;
        padding-right: 8px;
        cursor: default;
        background: var(--background-main);
        display: inline-block;
        user-select: none;
        margin-right: -1px; // Overlaps the previous filter-selected to make it look like 1px border between the two

        .selected-name {
          margin-right: 5px;
          position: relative;
          top: 1px;
        }

        ::v-deep(.s-icon) {
          display: inline-block;
          width: 8px;
          position: relative;
          top: 1px;
          pointer-events: all;
          cursor: pointer;
        }

        &:last-of-type {
          border-top-right-radius: $button-radius;
          border-bottom-right-radius: $button-radius;
        }
      }
    }

    .clear-all {
      vertical-align: top;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .search {
    flex-grow: 200;
    flex-basis: 360px;
    flex-shrink: 0;
    width: 100%;

    ::v-deep(input) {
      border-top-right-radius: $button-radius;
      border-bottom-right-radius: $button-radius;
    }
  }

  .list-search {
    flex: 1 0 auto;
    min-height: 38px;

    span {
      position: absolute;
      top: 25px;
      right: 3px;
      font-size: 10px;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  // --- End Death Warning ---
</style>
