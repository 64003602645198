export default {
  data: function () {
    return {
      scrollers: false,
    };
  },

  methods: {
    // hide the scroller if not needed
    hideScroller: function () {
      if (this.$refs.tableScroller) {
        const scrollerSize = this.$refs.tableScroller.offsetHeight - this.$refs.tableScroller.clientHeight;
        if (scrollerSize) {
          this.$refs.tableScroller.style.marginBottom = -(scrollerSize - 1) + 'px';
        } else {
          this.$refs.tableScroller.style.marginBottom = '-1px';
        }
      }
    },
    // synchronize scroll between different parts
    scrollSync: function (event, origin) {
      this.selectionTotal = false;
      if (origin == 'table') {
        if (this.$refs.headerScroller) {
          this.$refs.headerScroller.scrollLeft = event.target.scrollLeft;
        }
        if (this.$refs.selectorScroller) {
          this.$refs.selectorScroller.scrollLeft = event.target.scrollLeft;
        }
        if (this.$refs.scrollScroller) {
          this.$refs.scrollScroller.scrollLeft = event.target.scrollLeft;
        }
      } else if (origin == 'scroller') {
        if (this.$refs.headerScroller) {
          this.$refs.headerScroller.scrollLeft = event.target.scrollLeft;
        }
        if (this.$refs.selectorScroller) {
          this.$refs.selectorScroller.scrollLeft = event.target.scrollLeft;
        }
        if (this.$refs.tableScroller) {
          this.$refs.tableScroller.scrollLeft = event.target.scrollLeft;
        }
      }
    },
    // when mouse move out of the datatable, containers should scroll vertically
    verticalScroll: function (y) {
      for (let i = 0; i < this.scrollers.length; i++) {
        const firstScroller = this.scrollers[i];
        if (y > firstScroller.offsetTop + firstScroller.offsetHeight) {
          firstScroller.scrollTop += 5;
          break;
        } else if (y < firstScroller.offsetTop) {
          firstScroller.scrollTop -= 5;
          break;
        }
      }
    },
    // when mouse move out of the datatable, table should scroll horizontally
    horizontalScroll: function (x) {
      const elRect = this.$el.getBoundingClientRect();
      if (x > elRect.right) {
        this.$refs.tableScroller.scrollLeft += 5;
      } else if (x < elRect.left) {
        this.$refs.tableScroller.scrollLeft -= 5;
      }
    },
    // when selecting a cell, should scroll for the cell to be visible
    scrollOnSelection: function (target) {
      for (let i = 0; i < this.scrollers.length; i++) {
        const firstScroller = this.scrollers[i];
        const item = target;
        if (item.offsetTop < firstScroller.offsetTop + 22) {
          firstScroller.scrollTop -= 22;
          break;
        } else if (item.offsetTop + item.offsetHeight > firstScroller.offsetTop + firstScroller.offsetHeight - 22) {
          firstScroller.scrollTop += 22;
          break;
        }
      }
      const left = target.offsetLeft - this.$refs.tableScroller?.scrollLeft;
      const right = target.offsetLeft - this.$refs.tableScroller?.scrollLeft + 2 + target.offsetWidth - 3;
      if (right > this.outerTableWidth && left > this.checkboxColumnWidth) {
        this.$refs.tableScroller.scrollLeft = this.$refs.tableScroller.scrollLeft + (right - this.outerTableWidth) + 1;
      } else if (left < this.checkboxColumnWidth) {
        this.$refs.tableScroller.scrollLeft = this.$refs.tableScroller.scrollLeft + (left - this.checkboxColumnWidth);
      }
    },
  },
};
