/**
 * Allow a component to call a specific function or emit an event when blur the input
 * @param {boolean} required
 * @return {object}
 */
const onBlur = function (required = false) {
  return {
    props: {
      onBlur: {
        description: 'Function called or event emitted on input blur',
        type: [Function, String],
        required: required,
      },
    },
    methods: {
      $_onBlur: function () {
        this.$data.$_isFocused = false;
        if (typeof this.onBlur == 'function') {
          this.onBlur();
        } else if (typeof this.onBlur == 'string') {
          this.$el.dispatchEvent(new Event(this.onBlur));
        } else if (required) {
          console.error(this.$options.name + ' requires onBlur prop');
        }
      },
    },
  };
};

export default onBlur;
