<template>
  <div class="s-cross-filter">
    <slot :facts="facts" :all="all" :filtered="filtered"></slot>
  </div>
</template>

<script>
  import crossfilter from 'crossfilter2';

  export default {
    name: 's-cross-filter-wrapper',

    description:
      'List of bar interactive charts to display a granular data set. Ideal to easily filter by dimensions (based on d3.js and crossfilter2).',

    props: {
      data: {
        description: 'The granular data set to display in the cross filter chart.',
        type: Array,
      },
    },

    data() {
      return {
        facts: false,
        all: [],
        filtered: [],
      };
    },

    methods: {
      initCrossFilter() {
        if (this.facts) {
          this.facts.remove();
          this.facts = {};
        }

        this.facts = crossfilter(this.data);
        this.updateFacts();
      },

      updateFacts() {
        this.all = this.facts.all();
        this.filtered = this.facts.allFiltered();
      },
    },

    watch: {
      data() {
        this.initCrossFilter();
      },
    },

    mounted() {
      this.initCrossFilter();
    },
  };
</script>
