/* eslint-disable require-jsdoc */
/* We should have proper jsdocs here */

import {stringifyValuesOnly} from '@veasel/core/tools';

(function () {
  Object.defineProperty(String.prototype, 'replaceSpecialCharBySpaces', {
    value: replaceSpecialCharBySpaces,
    enumerable: false,
    writable: false,
    configurable: true,
  });

  Object.defineProperty(String.prototype, 'splice', {
    value: splice,
    enumerable: false,
    writable: false,
    configurable: true,
  });

  Object.defineProperty(String.prototype, 'indexOfRegexp', {
    value: indexOfRegexp,
    enumerable: false,
    writable: false,
    configurable: true,
  });

  Object.defineProperty(Array.prototype, 'sortBy', {
    value: sortBy,
    enumerable: false,
    writable: false,
    configurable: true,
  });

  Object.defineProperty(Array.prototype, 'sortByMultiple', {
    value: sortByMultiple,
    enumerable: false,
    writable: false,
    configurable: true,
  });

  Object.defineProperty(Array.prototype, 'unique', {
    value: unique,
    enumerable: false,
    writable: false,
    configurable: true,
  });

  Object.defineProperty(Array.prototype, 'indexOfObject', {
    value: indexOfObject,
    enumerable: false,
    writable: false,
    configurable: true,
  });

  Object.defineProperty(Array.prototype, 'removeWhereEqual', {
    value: removeWhereEqual,
    enumerable: false,
    writable: false,
    configurable: true,
  });

  Object.defineProperty(Array.prototype, 'searchText', {
    value: searchText,
    enumerable: false,
    writable: false,
    configurable: true,
  });

  Object.defineProperty(Array.prototype, 'toDictionary', {
    value: toDictionary,
    enumerable: false,
    writable: false,
    configurable: true,
  });
})();

// [String] Replaces all special characters by spaces
function replaceSpecialCharBySpaces() {
  return this.replace(/[^a-zA-Z0-9]/g, ' ');
}

// [String] Remove or replace bits of string at given position.
function splice(index, count, add) {
  if (index < 0) {
    index = this.length + index;
    if (index < 0) {
      index = 0;
    }
  }
  return this.slice(0, index) + (add || '') + this.slice(index + count);
}

// [String] Search for the first index of a regexp match and return the index
function indexOfRegexp(regex, startpos) {
  const indexOf = this.substring(startpos || 0).search(new RegExp(regex));
  return indexOf >= 0 ? indexOf + (startpos || 0) : indexOf;
}

// [Array] Sort an array using a key to sort by and ascending/descending flag.
function sortBy(key, desc) {
  if (desc === true || (desc && desc.toLowerCase().indexOf('desc') == 0)) {
    return this.sort(function (a, b) {
      if (a[key] > b[key]) {
        return -1;
      }
      if (a[key] < b[key]) {
        return 1;
      }
      return 0;
    });
  } else {
    return this.sort(function (a, b) {
      if (a[key] > b[key]) {
        return 1;
      }
      if (a[key] < b[key]) {
        return -1;
      }
      return 0;
    });
  }
}

// [Array] Sort an array using a list of sort objects, each mafe of a key to sort by and ascending/descending flag.
function sortByMultiple(filters) {
  let array = this;
  for (let i = filters.length - 1; i >= 0; i--) {
    const key = filters[i].property;
    const desc = filters[i].order;
    array = array.sortBy(key, desc);
  }
  return array;
}

// [Array] Remove duplicates elements in an array
function unique(deep = false) {
  if (deep) {
    return Array.from(new Set(this.map((e) => JSON.stringify(e)))).map((e) => JSON.parse(e));
  } else {
    return Array.from(new Set(this));
  }
}

// [Array] Remove all elements of an array of object where the given key is equal to the given value.
function removeWhereEqual(key, value) {
  for (const i in this) {
    if (this[i][key] === value) {
      delete this[i];
    }
  }
  return this;
}

// [Array] Execute full text search in an array of object, and filter the array to return only matching items.
function searchText(search) {
  search = search.toLowerCase().split(' ');
  return this.filter(function (row) {
    const text = stringifyValuesOnly(row).toLowerCase();
    for (const i in search) {
      if (text.indexOf(search[i]) === -1) {
        return false;
      }
    }
    return true;
  });
}

// [Array] Find the index of the object having the given key equal to a given value.
function indexOfObject(key, value) {
  const map = this.map(function (e) {
    return e[key];
  });
  return map.indexOf(value);
}

// [Array] Convert an array to a dictionary using two functions to get the keys and the values.
function toDictionary(funcKey, funcValue) {
  return this.reduce((map, obj, i) => ((map[funcKey(obj, i)] = funcValue(obj, i)), map), {});
}
