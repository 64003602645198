import {isEmpty, clone, debounce, isDeepEqual, setStorage} from '@veasel/core/tools';

const NON_ROUTE_KEYS = ['limit', 'num_entries', 'offset', 'pageSize', 'search_query'];

export default {
  data() {
    return {
      lastQueryObject: null,
      debouncedEmit: debounce(this.emitNewQuery, 750), // Needs bigger time on init to avoid duplicated requests
      loadingOffset: false,
      hasInit: false,
      debouncedInit: debounce(() => (this.hasInit = true), 250),
    };
  },

  computed: {
    // Base query: pagination + search + sorting, etc.
    baseQuery() {
      const query = {};
      if (!isEmpty(this.opts)) {
        query[this.opts.queryMap.limit] = this.opts.pagination.limit || 0;
        query[this.opts.queryMap.offset] = this.opts.pagination.offset || 0;
        query[this.opts.queryMap.numEntries] = this.opts.pagination.num_entries || 0;
        if (this.opts.pagination.offset > 0) {
          query['pageSize'] = this.settings.pageSize;
        }
        if (this.opts.sorting.multiple) {
          query[this.opts.queryMap.orderBy] = this.sortingArray;
        } else if (this.sortingArray && this.sortingArray.length) {
          query[this.opts.queryMap.orderBy] = this.sortingArray[0].property;
          query[this.opts.queryMap.sort] = this.sortingArray[0].order;
        }
      }
      return query;
    },
    // Full query: base + tab + filters
    fullQuery() {
      const routeTabs = {};
      if (this.$route) {
        // Get all route query tabs
        Object.keys(this.$route.query).forEach((key) => {
          if (key.toLowerCase().includes('tab')) {
            routeTabs[key] = this.$route.query[key];
          }
        });
      }

      let query = {...routeTabs, ...this.baseQuery};
      if (!isEmpty(this.opts) && !isEmpty(this.filteringQuery)) {
        query[this.opts.queryMap.search] = this.filteringQuery.search;
        if (this.filteringQuery.filters && Object.keys(this.filteringQuery.filters).length) {
          if (this.opts.additionalFilter.parsing === 'flat') {
            query = {...query, ...this.filteringQuery.filters};
          } else {
            query[this.opts.queryMap.filters] = this.filteringQuery.filters;
          }
        }
      }
      return query;
    },
    // Query extracted from URL: filters, offset (pagination), tab and search
    routeQuery() {
      const query = {};
      if (this.$route) {
        const filterQuery = {...this.baseQuery, ...this.$route.query};
        query.search_query = filterQuery.search_query || '';

        Object.keys(filterQuery).forEach((key) => {
          // Delete key if it's not a route query one
          if (key.toLowerCase().includes('tab') || NON_ROUTE_KEYS.includes(key)) {
            delete filterQuery[key];
          } else if (!Array.isArray(filterQuery[key]) && !key.toLowerCase().includes('date')) {
            // All filter keys must be arrays (except dates)
            filterQuery[key] = [filterQuery[key]];
          }
        });

        query.filters = filterQuery;
      }
      return query;
    },
    // Query Object: base + filters (or: fullQuery - tab)
    queryObject() {
      const query = clone(this.fullQuery);
      delete query.tab;
      return query;
    },
  },

  watch: {
    'filteringQuery.filters': {
      deep: true,
      handler(newValue, oldValue) {
        this.handleFilteringQueryChange(newValue, oldValue);
      },
    },

    'filteringQuery.search': {
      handler(newValue, oldValue) {
        debounce(this.handleFilteringQueryChange, this.opts.search.timeLimit)(newValue, oldValue);
      },
    },

    'opts.additionalFilter.filters': {
      deep: true,
      handler() {
        // Determine whether init has finished to prevent the URL to change while it's loading its query filters
        if (isEmpty(this.routeQuery.filters) && !this.routeQuery.search_query) {
          this.hasInit = true; // Nothing to load from route query => no init to wait for
        } else {
          this.debouncedInit();
        }
        if (!isEmpty(this.opts)) {
          this.updateViewFromRouteQuery();
        }
      },
    },
  },

  methods: {
    /**
     * Emits a query update via the components debounced system. This means that if filters are updated outside of the component, and the update needs a query update, this can be called.
     * Directly calling the method attached to the parent component's query updated event may cause multiple unexpected firing of the method
     * @public
     *
     */
    updateQuery() {
      this.updateURLQuery();
      this.debouncedEmit(this.queryObject);
    },
    emitNewQuery(query) {
      // Reduce debounce time after init (first emit) => +performance
      if (!this.lastQueryObject) {
        this.debouncedEmit = debounce(this.emitNewQuery, 250);
      }
      this.lastQueryObject = clone(this.queryObject);
      if (this.opts && !this.opts.frontend) {
        this.$emit(this.opts.backendEvent, query);
      }
    },
    updateURLQuery() {
      if (this.$router && this.opts && this.opts.queryInUrl && this.hasInit) {
        setTimeout(() => {
          const updateQuery = clone(this.fullQuery);

          delete updateQuery.limit;
          delete updateQuery.num_entries;

          if (updateQuery.offset === 0) {
            delete updateQuery.offset; // offset 0 === first page => default state
          }

          if (updateQuery.search_query === '' || typeof updateQuery.search_query !== 'string') {
            delete updateQuery.search_query;
          }

          // Turn off the flag after route query initialisation
          if (this.loadingOffset) {
            this.loadingOffset = false;
          }

          this.$router.replace({query: updateQuery});
        }, 0); // It just needs to go to the back of the queue so the tab replace executes first
      }
    },
    handleFilteringQueryChange(newValue, oldValue) {
      if (!isDeepEqual(newValue, oldValue) && !this.loadingOffset) {
        this.opts.pagination.offset = 0;
        if (this.changePage && this.currentPage !== 1) {
          this.changePage(1);
        } else {
          this.updateQuery();
        }
      }
    },
    updateViewFromRouteQuery() {
      if (this.opts.queryInUrl && this.$route) {
        // Update view state based on route query (if any)
        const isRouteQueryEmpty = isEmpty(this.routeQuery.filters) && !this.routeQuery.search_query;
        const isRouteQuerySameAsDefault =
          isDeepEqual(this.routeQuery.filters, this.filteringQuery.filters) &&
          this.routeQuery.search_query === this.filteringQuery.search;

        // Handle offset&pageSize
        if (this.$route.query.offset > 0) {
          this.opts.pagination.offset = Number(this.$route.query.offset);
          this.opts.pagination.limit = Number(this.$route.query.pageSize);
          setStorage('datatable-pagination-limit-' + this.opts.settings.storeSettings, this.opts.pagination.limit);
          this.loadingOffset = true;
          this.debouncedEmit(this.queryObject);
        }
        // There are some filters to apply and they are not the same as comp default ones
        if (!isRouteQueryEmpty && !isRouteQuerySameAsDefault) {
          this.filteringQuery.filters = this.routeQuery.filters;
          this.filteringQuery.search = this.routeQuery.search_query;
        } else if (!this.loadingOffset || isRouteQuerySameAsDefault) {
          // Update query if there's no offset or there are filters in the URL
          this.updateQuery();
        } else {
          // Reset filters in any other case just to make sure that we start with an initial expected state
          this.filteringQuery.filters = {};
          this.filteringQuery.search = '';
        }
      }
    },
  },
};
