export default {
  email: /^([a-z0-9_.-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/,
  ip: /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
  html: /^<([a-z0-9]+)([^<]+)*(?:>(.*)<\/\1>|\s+\/>)$/,
  number: /^[-+]?[0-9., ]*$/,
  float: /^[-+]?[0-9., ]*$/,
  float2d: /^[-+]?\d+(\.\d{1,2})?$/,
  integer: /^[-+]?[0-9]*$/,
  string: /^.*$/,
  noSpecials: /^[A-Za-z0-9\-._ àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]*$/,
  json: function (str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  },
  hex: /^#([A-Fa-f0-9]{8}|[A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
  rgba: /^rgba\((\d{1,3}%?),\s*(\d{1,3}%?),\s*(\d{1,3}%?),\s*(\d*(?:\.\d+)?)\)$/,
  // Complex URL regex courtesy of Pavlos
  url: new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d](\\.?[a-z\\d-]*[a-z\\d])*))+\\.[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ), // fragment locator
};
