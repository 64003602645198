import {clone, numberToLetters} from '@veasel/core/tools';
import {colorsMixin} from '@veasel/core/colors';

export default {
  mixins: [colorsMixin],
  methods: {
    // export using a built-in function or a custom one
    exportView: function (type) {
      let payload;
      if (typeof type.logic == 'function') {
        try {
          payload = type.logic(this.visibleRows, this.visibleCols, this.$el);
        } catch (err) {
          console.warn('Error in custom export logic (' + type.name + ')', err);
        }
      } else {
        payload = this[type.logic + 'Export']();
      }
      this.$emit(this.opts.exportView.event, payload);
    },
    // export using a list of columns (for datalist custom export to excel)
    exportListing: function () {
      const rows = [];
      const styles = [];
      const header = [];
      const colorCell = this.s_colors('--gray').trim();
      for (const l in this.opts.exportView.listing) {
        const col = this.opts.exportView.listing[l];
        header.push(col.title);
        if (col.width) {
          styles.push({
            row: 0,
            col: parseInt(l),
            width: parseInt(col.width),
            alignH: 'center',
            bold: true,
            background: colorCell,
          });
        }
      }
      rows.push(header);

      // Looping through the rows (row === event)
      for (const i in this.alteredRows) {
        const row = this.alteredRows[i];
        const processedRows = [[]]; // Array of processed rows (to handle initial and cloned rows)
        // Looping through the cols
        for (const j in this.opts.exportView.listing) {
          const col = this.opts.exportView.listing[j];
          try {
            // It's array => logic to create and handle clones
            const colValues = col.value(row);
            if (col.isList) {
              const clonesCount = colValues.length - 1;
              // Creates all necessary partial clones
              if (processedRows.length <= 1) {
                // Only create the clones once
                for (let i = 0; i < clonesCount; i++) {
                  processedRows.push(clone(processedRows[0])); // Clone is not complete
                }
              }
              // Pushes the correspondent value to each clone (and original one)
              for (let i = 0; i < processedRows.length; i++) {
                // If the value is a list of arrays, then we list the values in the one cell
                let colValue;
                if (Array.isArray(colValues[i])) {
                  colValue = colValues[i].join(', ');
                } else if (typeof colValues[i] === 'object') {
                  colValue = JSON.stringify(colValues[i]);
                } else {
                  colValue = colValues[i];
                }
                processedRows[i].push(colValue);
              }
            } else {
              // Not an array: complete the remaining cols of all clones as well
              processedRows.forEach((r) => {
                r.push(col.value(row));
              });
            }
          } catch (err) {
            processedRows[0].push('-');
            console.warn(
              'Error in custom export logic ("value" function, row ' + (parseInt(i) + 1) + ', col ' + parseInt(j) + ')',
              err
            );
          }

          if (col.style) {
            try {
              styles.push({col: parseInt(j), row: parseInt(i) + 1, ...col.style(row)});
            } catch (err) {
              console.warn(
                'Error in custom export logic ("style" function, row ' +
                  (parseInt(i) + 1) +
                  ', col ' +
                  parseInt(j) +
                  ')',
                err
              );
            }
          }
        }
        rows.push(...processedRows);
      }

      const payload = {};
      payload.rows = rows;
      payload.styledCells = styles;
      payload.freeze_panes = 'B2';
      payload.auto_filter = 'A1:' + numberToLetters(this.opts.exportView.listing.length) + rows.length;
      this.$emit(this.opts.exportView.event, payload);
      return payload;
    },
    // export a csv ready payload
    csvExport: function () {
      const rows = [];
      const header = [];
      for (const l in this.visibleCols) {
        const hcol = this.visibleCols[l];
        header.push(hcol.title || hcol.data);
      }
      rows.push(header);
      for (const i in this.visibleRows) {
        const row = this.visibleRows[i];
        const processedRow = [];
        for (const j in this.visibleCols) {
          const col = this.visibleCols[j];
          const rendererFunc = typeof col.renderer === 'string' ? this.renderers[col.renderer] : col.renderer;
          const cellElem = rendererFunc(document.createElement('div'), row[col.data], j, i, row, col, this);
          const value = cellElem.textContent === 'undefined' ? '' : cellElem.textContent;
          processedRow.push(value);
        }
        rows.push(processedRow);
      }
      const payload = rows.map((r) => r.join(',')).join('\n');
      return payload;
    },
  },
};
