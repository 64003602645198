// This directive allows the dropdown section of an s-input-select component to escape
// from a scrollable container, by temporarily taking the element out of the
// standard page flow by setting its position to 'fixed' and faking it's position.

const aboutCn = new AbortController();

export default {
  install(Vue) {
    Vue.directive('select-overflow', {
      // Inserted is called once when the component enters the DOM
      mounted: (el, binding, vnode) => {
        // If the overflow is explicitly disabled, return early
        if (binding.value === 'disabled') {
          return;
        }

        // If the overflow behaviour is explicitly enabled, set active to true
        let active = binding.value === 'enabled';

        // If not explicitly enabled, attempt to auto detect if the behaviour is necessary
        if (!active && binding.value === 'auto') {
          let inScrollableContainer = false;
          let parent = el.parentElement;

          while (parent && !inScrollableContainer) {
            inScrollableContainer = ['auto', 'scroll'].includes(getComputedStyle(parent).overflowY);

            parent = parent.parentElement;
          }

          active = inScrollableContainer;
        }

        // If not explicitly enabled or auto detected, return early
        if (!active) {
          return;
        }

        // Runtime state variables
        let selectIsOpen = false;

        let originalWidth;
        let originalPosition;
        let originalZIndex;
        let originalMarginTop;

        let placeholder;

        let debouncedWidth = null;
        let pendingWidth = null;
        let widthDebounceCount = 0;

        let closingTimeout = null;

        // Attach a watcher to the input open state
        binding.instance.$watch('open', (isOpen) => {
          // Change of state to open
          if (isOpen && !selectIsOpen) {
            // Stash the 'original' values, from before the input is opened
            originalWidth = el.style.width;
            originalPosition = el.style.position;
            originalZIndex = el.style.zIndex;
            originalMarginTop = el.style.marginTop;

            // Use the debounced width, if available
            const width = (debouncedWidth || el.offsetWidth) - 1;

            el.parentElement.style.width = `${width + 1}px`; // need to balance offset

            // Apply the style changes
            el.style.position = 'fixed';
            el.style.width = `${width}px`;
            el.style.zIndex = 71;

            // Look for any scroll within parent components
            let scroll = 0;
            let p = el.parentElement;

            while (p) {
              scroll += p.scrollTop;
              const overflowY = getComputedStyle(p).overflowY;

              p = !['auto', 'scroll'].includes(overflowY) ? p.parentElement : null;
            }

            // Use a negative margin to account for the scroll amount (nasty)
            el.style.marginTop = `-${scroll}px`;

            // Create a placeholder element to preserve the components effect on the layout flow
            placeholder = document.createElement('div');
            placeholder.style.minHeight = '33px';
            el.parentNode.insertBefore(placeholder, el.nextSibling);

            // Record new state
            selectIsOpen = true;

            // Add class to indicate the overflow hack is active
            el.parentElement.classList.add('select-overflow-active');
          } else if (!isOpen && selectIsOpen) {
            // Delay resetting the element after closing to avoid scroll popping
            closingTimeout = window.setTimeout(() => {
              // Change of state to closed, reset to original style
              el.parentElement.style.width = 'auto';

              el.style.position = originalPosition;
              el.style.width = originalWidth;
              el.style.zIndex = originalZIndex;
              el.style.marginTop = originalMarginTop;

              // Remove the placeholder
              placeholder.remove();

              // Record new state
              selectIsOpen = false;

              // Remove the class
              el.parentElement.classList.remove('select-overflow-active');
            }, 200);
          }

          // If the component is opened, cancel any pending closing timeout
          if (isOpen) {
            window.clearTimeout(closingTimeout);
          }
        });

        // Listen for and prevent external scroll events whilst the input is open
        window.addEventListener(
          'wheel',
          (event) => {
            if (selectIsOpen && !el.contains(event.target)) {
              event.preventDefault();
            }
          },
          {
            passive: false,
            signal: aboutCn.signal,
          }
        );

        // Monitor and debounce the width of the input to smooth out
        // popping jank due to scroll bar
        vnode.widthWatcher = window.setInterval(() => {
          // Only interested in the width of the input when closed
          if (selectIsOpen) {
            return;
          }

          // If the width does not match the debounced value
          if (el.offsetWidth !== debouncedWidth) {
            // If it is a repetition of the previous value, increment the count
            if (el.offsetWidth === pendingWidth) {
              widthDebounceCount++;
            } else {
              // Reset the count if this is a new width
              widthDebounceCount = 0;
            }

            // Record the width value for the next iteration
            pendingWidth = el.offsetWidth;

            // If the value has been stable for 5 iterations update the debounced width
            if (widthDebounceCount >= 5) {
              debouncedWidth = pendingWidth;
            }
          } else {
            // Reset all variables if the value matches the debounced value
            pendingWidth = debouncedWidth;
            widthDebounceCount = 0;
          }
        }, 100);
      },

      // When the component is removed, cancel the interval watching the width
      unmounted: (_, __, vnode) => {
        window.clearInterval(vnode.widthWatcher);
        aboutCn.abort();
      },
    });
  },
};
