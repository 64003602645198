<template>
  <div class="s-axis-chart flex">
    <!-- LEFT HAND SIDE SLOT -->
    <slot></slot>
    <!-- CHART, TIME RANGE SELECTOR AND RESET LINK -->
    <div ref="container" class="chart-box">
      <div :id="uniqueID" ref="anchor" class="chart-inner">
        <span v-if="showTitle" class="chart-title">{{ title }}</span>
      </div>
      <div :id="uniqueID + '-selector'" ref="anchorSelector" :class="{relative: optOrDefault('resetLink')}">
        <div
          v-if="optOrDefault('resetLink')"
          class="reset-link"
          :style="{
            top: optOrDefault('resetLink').top + 'px',
            left: optOrDefault('resetLink').left + 'px',
            'font-size': optOrDefault('resetLink').fontSize + 'px',
          }"
        >
          <a class="s-link reset" href="javascript:;" :style="{display: 'none'}" v-on:click="resetAllFilters">
            {{ optOrDefault('resetLink').text }}
          </a>
        </div>
      </div>
    </div>
    <div v-if="showLegend" class="legend-container"></div>
  </div>
</template>

<script>
  /* eslint-disable camelcase */

  import * as d3 from 'd3';
  import * as dc from '@suadelabs/dc';
  import {sizeToBytes, uniqId} from '@veasel/core/tools';
  import {humanizeNumber} from '@veasel/core/formatters';
  import crossfilter from 'crossfilter2';
  import {id, chartResize} from '@veasel/core/mixins';

  const DEFAULT_OPTIONS = {
    transitionDuration: 500,
    colours: [
      '#ceebff', // --pastel-blue
      '#ffc5c5', // --pastel-red
      '#e0fdb9', // --pastel-green
      '#fff1bf', // --pastel-orange
      '#dee0fc', // --pastel-purple
      '#b9dbfd', // --light-active
      '#0fc27b80', // --approved with opacity
      '#5288bf80', // --label-1 with opacity
      '#ff730080', // --warning with opacity
      '#f1594280', // --error with opacity
    ],
    title: 'Title',
    legend: null,
    legendRightPadding: 15,
    resetLink: null,
    horizontalGridLines: false,
    verticalGridLines: false,
    formatWithFix: (d) => d3.format('~s')(d).replace('G', 'B'),
  };

  const millis_per_second = 1000;
  const millis_per_minute = millis_per_second * 60;
  const millis_per_hour = millis_per_minute * 60;
  const millis_per_day = millis_per_hour * 24;
  const millis_per_week = millis_per_day * 7;
  const millis_per_month_approx = millis_per_day * 28;
  const millis_per_year = millis_per_day * 365;

  const time_bar_width = {
    second: new Date(millis_per_second),
    minute: new Date(millis_per_minute),
    hour: new Date(millis_per_hour),
    day: new Date(millis_per_day),
    week: new Date(millis_per_week),
    month: new Date(millis_per_month_approx),
    year: new Date(millis_per_year),
  };

  const time_x_units = {
    second: d3.timeSeconds,
    minute: d3.timeMinutes,
    hour: d3.timeHours,
    day: d3.timeDays,
    week: d3.timeWeeks,
    month: d3.timeMonths,
    year: d3.timeYears,
  };

  export default {
    name: 's-axis-chart',

    description: 'A basic 2D chart with X and Y axis, handling bars, lines, or areas (based on d3.js and dc).',

    props: {
      values: {
        description: 'List of values to display in the chart',
        type: Array,
      },
      options: {
        description: 'The options object, to specify advanced features and chart style.',
        type: Object,
        default: () => ({}),
      },
      showLegend: {
        description: 'Boolean to show/hide legend on the chart',
        type: Boolean,
        default: true,
      },
      showTitle: {
        description: 'Boolean to show/hide chart title',
        type: Boolean,
        default: true,
      },
      domains: {
        description:
          'An array of domain objects that consist of the max, min and type of y axis required. Used when multiple y axes are required',
        type: Array,
      },
      useMultiYAxis: {
        description: 'Boolean to generate multiple Y axes on the chart if data needs values split',
        type: Boolean,
        default: true,
      },
    },

    mixins: [chartResize, id],

    data: function () {
      return {
        selectedRange: null,
        crossFilters: [],
        processedData: [],
        tooltipValueFormatter: undefined,
        uniqueID: '',
        base64Image: '',
        updateImageTimeout: undefined,
        lowestYMin: 0,
        yDomains: {},
        ticksCount: 8,
        ticksFormat: (d) => humanizeNumber(d),
      };
    },

    computed: {
      title() {
        return this.optOrDefault('title');
      },
    },

    methods: {
      // Get a value from the options property, or from defaults if not present
      optOrDefault(key) {
        return this.options[key] !== undefined ? this.options[key] : DEFAULT_OPTIONS[key];
      },

      // Initialise the crossfilters containing the data driving the charts
      initCrossFilters() {
        this.crossFilters.forEach((crossFilter) => {
          if (crossFilter) {
            crossFilter.remove();
            crossFilter = null;
          }
        });

        this.processedData.splice(0, this.processedData.length);

        this.values.forEach((chartData, index) => {
          if (chartData.yAxis.domain && typeof chartData.yAxis.domain !== 'string') {
            const min = Math.min(...chartData.yAxis.domain);

            if (index === 0 || min < this.lowestYMin) {
              this.lowestYMin = min;
            }
          }

          this.crossFilters[index] = this.createCrossFilter(chartData);
          this.processedData[index] = this.processData(chartData, index);
        });
      },

      // Generate a cross filter from a series of chart data, depending on the data format
      createCrossFilter(chartData) {
        if (chartData.dataFormat === 'key-value') {
          return crossfilter(
            Object.keys(chartData.values[0]).map((key) => {
              const ret = {};

              ret[chartData.xAxis.seriesKey] = key;
              ret[chartData.yAxis.seriesKey] = chartData.values[0][key];

              return ret;
            })
          );
        } else {
          return crossfilter(chartData.values);
        }
      },

      // Derive dimensions, groups, binning and ranges from the crossfilter containg a chart data series
      processData(chartData, index) {
        const ndx = this.crossFilters[index];

        let dimension;
        let group;
        let binWidth;
        let max;
        let min;

        if (chartData.xAxis.type === 'label') {
          // LABEL (QUANTATIVE)
          const xSeriesKey = chartData.xAxis.seriesKey;
          const ySeriesKey = chartData.yAxis.seriesKey;

          dimension = ndx.dimension((d) => d[xSeriesKey]);

          group =
            chartData.dataFormat === 'raw'
              ? dimension.group().reduceCount() // For raw data, count entries with the same type
              : dimension.group().reduceSum((d) => d[ySeriesKey]); // For data that is already grouped, use 'sum' of single value
        } else if (['linear', 'log'].includes(chartData.xAxis.type)) {
          // LINEAR & LOGARITHMIC
          const xSeriesKey = chartData.xAxis.seriesKey;
          const ySeriesKey = chartData.yAxis.seriesKey;

          dimension = ndx.dimension((d) => d[xSeriesKey]);
          group = dimension.group().reduceSum((d) => d[ySeriesKey]);

          const xAxisValues = group.all().map((v) => v.key);
          max = d3.max(xAxisValues);
          min = d3.min(xAxisValues);
          binWidth = group.all().length <= 20 ? null : this.getBinWidth(max - min);

          // If this is a bar chart with a linear x axis, we may need to bin the values to avoid lots of tiny bars
          if (chartData.type == 'bar' && binWidth != null) {
            dimension = ndx.dimension((d) => this.getNearestBin(d[xSeriesKey], binWidth));
            group = dimension.group().reduceSum((d) => d[ySeriesKey]);
          }

          // If there were less than 20 values we can display each individually, but we still need to know the width of the bar
          if (binWidth == null) {
            binWidth = this.getInterval(xAxisValues);
          }
        } else if (chartData.xAxis.type === 'time') {
          // TIME
          const xSeriesKey = chartData.xAxis.seriesKey;
          const ySeriesKey = chartData.yAxis.seriesKey;

          // Unfortunately crossfilter will lock up if the date is not valid, so needs to be checked
          dimension = ndx.dimension((d) => {
            const date = new Date(d[xSeriesKey]);

            if (date instanceof Date && !isNaN(date)) {
              return date;
            } else {
              console.warn('Invalid date in chart data!', d[xSeriesKey]);
              return null;
            }
          });

          group =
            chartData.dataFormat === 'raw'
              ? dimension.group().reduceCount()
              : dimension.group().reduceSum((d) => d[ySeriesKey]);

          let xAxisValues = group.all().map((v) => v.key);

          max = d3.max(xAxisValues);
          min = d3.min(xAxisValues);

          binWidth = group.all().length <= 20 ? null : this.getTimeBinWidth(max - min);

          // If this is a bar chart with time on the x axis, we may need to 'bin' the values to avoid lots of tiny bars
          if (chartData.type === 'bar' && binWidth !== undefined) {
            dimension = ndx.dimension((d) => this.getNearestTimeBin(d[xSeriesKey], binWidth));
            group = dimension.group().reduceSum((d) => d[ySeriesKey]);

            xAxisValues = group.all().map((v) => v.key);

            max = d3.max(xAxisValues);
            min = d3.min(xAxisValues);
          }

          // If there were less than 20 values we still need to know the width to use for the bar
          if (binWidth == null) {
            binWidth = new Date(parseInt(this.getInterval(xAxisValues, true)));
          }
        }

        return {dimension: dimension, group: group, binWidth: binWidth, max: max, min: min};
      },

      // Generate the required information for the X axis of a chart
      createXAxis(chartData, index) {
        const type = chartData.xAxis.type;
        let {group, binWidth, max, min} = this.processedData[index];

        switch (type) {
          case 'label':
            return {
              x: d3.scaleBand().domain(group.all().map((v) => v.key)),
              xUnits: dc.units.ordinal,
            };
          case 'linear':
            // Bar charts need one extra step on the x axis as the bar extends from n to n + 1
            if (chartData.type === 'bar') {
              max += binWidth;
            }

            return {
              x: d3.scaleLinear().domain([min, max]),
              xUnits: chartData.type === 'bar' ? () => (max - min) / binWidth : dc.units.integers,
            };
          case 'log':
            return {
              x: d3.scaleLog().clamp(true).domain([min, max]),
              xUnits: dc.units.integers,
              ticks: {
                count: 10,
                format: ',.0r',
              },
            };
          case 'time': {
            let xUnits;

            if (typeof binWidth === 'string') {
              xUnits = time_x_units[binWidth];
              binWidth = time_bar_width[binWidth];
            } else {
              xUnits = chartData.type == 'bar' ? () => (max - min) / binWidth : dc.units.integers;
            }

            // Bar charts need one extra step on the x axis as the bar extends from n to n + 1
            if (chartData.type === 'bar') {
              max = new Date(max.getTime() + binWidth.getTime());
            }

            return {
              x: d3.scaleTime().domain([min, max]),
              xUnits: xUnits,
            };
          }
          default:
            return {
              x: d3.scaleLinear().domain([min, max]),
              xUnits: dc.units.integers,
            };
        }
      },

      // Generate the required information for the Y axis of a chart
      createYAxis(chartData, index) {
        const type = chartData.yAxis.type;
        const {group} = this.processedData[index];
        const domain = this.calculateDomain(chartData);

        // Will overlap if more than 10 ticks, using 8 to be safe - increase height of chart if more needed
        this.ticksCount =
          chartData.yAxis.ticksCount === undefined || chartData.yAxis.ticksCount >= 8 ? 8 : chartData.yAxis.ticksCount;

        // Default, this should be overriden below if necessary
        this.ticksFormat = (d) => humanizeNumber(d);

        switch (type) {
          case 'label':
            return {
              y: d3.scaleBand().domain(group.all().map((v) => v.key)),
              yUnits: dc.units.ordinal,
            };
          case 'linear':
            return {
              y: d3.scaleLinear().domain(domain),
              yUnits: dc.units.integers,
            };
          case 'percentage':
            this.ticksFormat = d3.format('.0%');

            return {
              y: d3.scaleLinear().domain([0, 1]),
              yUnits: dc.units.integers,
            };
          case 'scaled-percentage':
            this.ticksFormat = d3.format('.0%');

            return {
              y: d3.scaleLinear().domain([0, domain[1] < 1 ? 1 : domain[1]]),
              yUnits: dc.units.integers,
            };
          case 'byte':
            this.ticksFormat = (d) => sizeToBytes(d);

            return {
              y: d3.scaleLinear().domain(domain),
              yUnits: dc.units.integers,
            };
          case 'log': {
            let yScale = d3
              .scaleLog()
              .clamp(true)
              .domain([domain[0] !== 0 ? domain[0] : 1, domain[1]]);

            // Needs to be Linear if domain ranges from a negative value to a positive value ie. a log cannot cross 0 or be 0
            if (domain[0] < 0) {
              yScale = d3.scaleLinear().domain(domain);
            }

            return {
              y: yScale,
              yUnits: dc.units.integers,
            };
          }
          default:
            return {
              y: d3.scaleLinear().domain(domain),
              yUnits: dc.units.integers,
            };
        }
      },

      calculateDomain(chartData) {
        let domainRange = this.yDomains[chartData.name];

        if (typeof chartData.yAxis?.domain === 'string' && this.yDomains[chartData.yAxis?.domain] !== undefined) {
          domainRange = this.yDomains[chartData.yAxis.domain];
        }

        let domain = [0, domainRange[1]];

        if (this.lowestYMin < 0) {
          const yMax = Math.max(...[Math.abs(domainRange[0]), Math.abs(domainRange[1])]);

          // Need to use yMax value to align 0 across all axes to balance the charts when there are negative values
          domain = [-yMax, yMax];
        }

        return domain;
      },

      // Builds a chart using its type and data, setting type specific parameters
      buildChart(chartData, root, comp = false, index = 0, bars) {
        const yAxis = this.createYAxis(chartData, index);
        const chart = this.createChartFromType(chartData, root, comp, index, bars, yAxis);

        if (['percentage', 'scaled-percentage'].includes(chartData.yAxis.type)) {
          this.tooltipValueFormatter = (val) => Math.round(val * 100) + '%';
        } else if (chartData.yAxis.type === 'byte') {
          this.tooltipValueFormatter = (val) => sizeToBytes(val);
        }

        if (chartData.onClick) {
          chart.onClick = chartData.onClick;
        } else {
          chart.onClick = function () {};
        }

        this.handleBarChartHover(chartData, chart);

        // create holes if value is NaN
        if (chart.defined) {
          chart.defined((d) => !isNaN(d.y));
        }

        return chart;
      },

      createChartFromType(chartData, root, comp, index, bars, yAxis) {
        let chart;

        const {dimension, group} = this.processedData[index];

        if (chartData.type === 'bar') {
          let colours = this.optOrDefault('colours');

          if (bars > 1 && colours.length > index) {
            colours = colours.slice(index, index + 1);
          }

          chart = dc
            .barChart(root)
            .dimension(dimension)
            .group(group, chartData.name || '' + index)
            .centerBar(comp)
            .colorAccessor((d) => d.key)
            .ordinalColors(colours)
            .useRightYAxis(chartData.yAxis.right || false)
            .multiYAxisDomainData(yAxis.y, chartData.yAxis.domain);
        } else if (chartData.type === 'line') {
          chart = dc
            .lineChart(root)
            .dimension(dimension)
            .group(group, chartData.name || '' + index)
            .colors(this.optOrDefault('colours')[index])
            .useRightYAxis(chartData.yAxis.right || false)
            .multiYAxisDomainData(yAxis.y, chartData.yAxis.domain);
        } else if (chartData.type === 'area') {
          chart = dc
            .lineChart(root)
            .renderArea(true)
            .dimension(dimension)
            .group(group, chartData.name || '' + index)
            .colors(this.optOrDefault('colours')[index])
            .useRightYAxis(chartData.yAxis.right || false)
            .multiYAxisDomainData(yAxis.y, chartData.yAxis.domain);
        }

        return chart;
      },

      handleBarChartHover(chartData, chart) {
        if (chartData.type === 'bar' && (chartData.onClick || chartData.onHover)) {
          // Namespace event type with .clickhover to avoid overriding other listeners
          chart.on('pretransition.clickhover', (chart) => {
            chart.selectAll('rect.bar').classed('pointer', true);

            // If there are hover handlers
            if (chartData.onHover && chartData.onHover.enter && chartData.onHover.leave) {
              const h = chartData.onHover;

              // Add hover handler to slices
              chart
                .selectAll('rect.bar')
                .on('mouseover', function (item) {
                  h.enter(item, this);
                }) // Use non-arrow function to pass bar element as this
                .on('mouseout', function (item) {
                  h.leave(item, this);
                });
            }
          });
        }
      },

      // Build a composite chart by composing a set of single charts
      composeChart(anchor, isSelector = false) {
        const compChart =
          this.useMultiYAxis && Object.values(this.yDomains).length > 0 && !isSelector
            ? dc.compositeMultiAxisChart(anchor)
            : dc.compositeChart(anchor);

        const singleCharts = [];
        const barsLength = this.values.filter((chartData) => chartData.type === 'bar').length;

        // creates a bar chart per chartData item. This array is passed to the compose method
        this.values.forEach((chartData, index) => {
          singleCharts.push(this.buildChart(chartData, compChart, true, index, barsLength));
        });

        return compChart.compose(singleCharts)._rangeBandPadding(1);
      },

      // Render the chart for this component,
      renderChart(renderSelector = false) {
        // If this chart needs to display multiple series of data, use a composite chart
        if (this.values.length > 1) {
          this.chart = this.composeChart(this.$refs.anchor);
          this.chart.rightYAxis().ticks(this.ticksCount).tickFormat(this.ticksFormat);
        } else {
          // Otherwise just use a single chart
          this.chart = this.buildChart(this.values[0], this.$refs.anchor);
        }

        // The tick format can only be set after the chart is composed.
        this.chart.yAxis().ticks(this.ticksCount).tickFormat(this.ticksFormat);

        const xAxis = this.createXAxis(this.values[0], 0);
        const chartWidth = this.options.width || this.$refs.container.offsetWidth;

        this.chart
          .width(chartWidth)
          .height(200)
          .transitionDuration(this.optOrDefault('transitionDuration'))
          .x(xAxis.x)
          .xUnits(xAxis.xUnits)
          .yAxisLabel(this.values[0].yAxis.label)
          .brushOn(false)
          .margins({top: 40, left: 60, right: 60, bottom: 30})
          .renderHorizontalGridLines(this.optOrDefault('horizontalGridLines'))
          .renderVerticalGridLines(this.optOrDefault('verticalGridLines'));

        this.performModificationsPerChartRender();
        this.updateXAxis(xAxis);

        // Make sure svgBox exists on the svg element otherwise svg may be cut off in areas
        this.$nextTick(() => {
          d3.select('.s-axis-chart .chart-box svg').attr('viewBox', `0 0 ${chartWidth} ${this.chart.height()}`);
        });

        this.handleLegend();

        if (this.optOrDefault('margins')) {
          this.chart.margins({...this.optOrDefault('margins')});
        }

        this.handleSelectorChart(renderSelector);

        // create holes if value is NaN
        if (this.chart.defined) {
          this.chart.defined((d) => !isNaN(d.y));
        }

        this.chart.render();
        this.updateBase64Image();
      },

      performModificationsPerChartRender() {
        // Some SVG modifications need to be done on each render. Namespace listener with .svg
        this.chart.on('pretransition.svg', (chart) => {
          const nextTick = this.$nextTick;

          // When compositing multiple bar charts, we need to shrink the width of the bars and pad them into groups
          if (this.values.filter((chartData) => chartData.type === 'bar').length > 1) {
            const subs = chart.selectAll('.sub');
            const barPadding = 3 / subs.size();
            const subScale = d3.scaleLinear().domain([0, subs.size()]).range([0, 100]);

            subs.each(function (_d, i) {
              let startAt = subScale(i + 1) - subScale(1);
              let endAt = subScale(i + 1);

              startAt += barPadding + (i === 0 ? barPadding * 5 : 0);
              endAt -= barPadding + (i === subs.size() - 1 ? barPadding * 5 : 0);

              nextTick(() => {
                d3.select(this)
                  .selectAll('rect')
                  .attr('clip-path', `polygon(${startAt}% 0, ${endAt}% 0, ${endAt}% 100%, ${startAt}% 100%)`);
              });
            });
          }

          // We also want to extend the tickmark at 0 all the way across the chart, to form a clear grid line at y=0 if y=0 is visible
          const ticks = chart.selectAll('.axis.y .tick');
          const domain = chart.select('.axis.x .domain');
          const x2 = chart.select('clipPath rect').attr('width');

          // Sometimes nextTick is not long enough for the text to be ready, so use set timeout as a backup
          const extendTick = (textEl, thisEl, retry = true) => {
            if (textEl.text() === '' && retry) {
              window.setTimeout(() => extendTick(textEl, thisEl, false));
            } else if (textEl.text() === '0') {
              const line = thisEl.select('line');

              line.attr('x2', x2);
              line.attr('x1', '-6');

              // We have found the tickmark to extend, so we want to hide the x-axis line at the bottom of the chart (which may or may not be at y=0)
              domain.attr('opacity', 0);
            }
          };

          ticks.each(function () {
            const thisEl = d3.select(this);
            const text = thisEl.select('text');

            nextTick(() => extendTick(text, thisEl));
          });
        });
      },

      updateXAxis(xAxis) {
        if (this.values[0].xAxis.type == 'time') {
          this.chart.title(this.timeTooltip);
        }

        if (xAxis.ticks) {
          this.chart.xAxis().ticks(xAxis.ticks.count, xAxis.ticks.format);
        }

        if (!this.values[0].xAxis.selector) {
          this.chart.xAxisLabel(this.values[0].xAxis.label);
        }
      },

      handleLegend() {
        if (this.$props.showLegend && this.optOrDefault('legend')) {
          let legendXValue = this.optOrDefault('legend').x;

          if (Object.values(this.yDomains).length > 1) {
            const legendWidthWithRightPadding = dc.legend().itemWidth() + this.optOrDefault('legendRightPadding');

            const widthWithLegend = this.chart.width() + legendWidthWithRightPadding;

            const viewBoxParams = `-${legendWidthWithRightPadding + 5} 0 ${widthWithLegend} ${this.chart.height()}`;

            legendXValue = -legendWidthWithRightPadding;

            // Prevent legend from overlapping chart by adjusting viewBox
            this.$nextTick(() => {
              d3.select('.s-axis-chart .chart-box svg').attr('viewBox', viewBoxParams);
            });
          }

          this.chart.legend(
            dc
              .legend()
              .x(legendXValue)
              .y(this.optOrDefault('legend').y)
              .itemHeight(this.optOrDefault('legend').itemHeight)
              .autoItemWidth(true)
              .gap(this.optOrDefault('legend').gap)
          );
        }
      },

      handleSelectorChart(renderSelector) {
        if (this.values[0].xAxis.selector && renderSelector) {
          this.renderSelectorChart();
          this.chart.rangeChart(this.selectorChart);
          this.selectorChart.render();
        }
      },

      // Render the extra range selection chart below the main chart if necessary
      renderSelectorChart() {
        // Time line selector
        if (this.values[0].xAxis.selector) {
          this.selectorChart = this.composeChart(this.$refs.anchorSelector, true); // (this.values[0], this.$refs.anchorSelector);
          const selectorAxis = this.createXAxis(this.values[0], 0);
          const chartWidth = this.$refs.container.offsetWidth;

          this.selectorChart
            .width(chartWidth)
            .height(125)
            .x(selectorAxis.x)
            .xUnits(selectorAxis.xUnits)
            .margins({top: 15, left: this.chart.margins().left, right: this.chart.margins().right, bottom: 50})
            .xAxisLabel(this.values[0].xAxis.label);
        }

        // Can't chain rightYAxis off yAxis, need to remove ticks from selector chart
        this.selectorChart.rightYAxis().ticks(0, '');
        this.selectorChart.yAxis().ticks(0, '');
      },

      // Calculate the ideal bin width given a specific number of values
      getBinWidth(x) {
        let bin = 10 ** Math.floor(Math.log10(x) - 1);

        if (x > 40 * bin) {
          bin *= 5;
        } else if (x > 20 * bin) {
          bin *= 2;
        }

        return bin;
      },

      // Find the nearest bin for a given value, given a bin width
      getNearestBin(x, binWidth) {
        return parseFloat((Math.floor(x / binWidth) * binWidth).toPrecision(10));
      },

      // Calcaulte the more appropriate bin for a time axis, given a time range
      getTimeBinWidth(x) {
        const diff = new Date(x);
        const diffTime = diff.getTime();
        const seconds = Math.ceil(diffTime / 1000);
        const minutes = Math.ceil(seconds / 60);
        const hours = Math.ceil(minutes / 60);
        const days = Math.ceil(hours / 24);
        const weeks = Math.ceil(days / 7);
        const months = Math.ceil(days / 30);

        if (seconds <= 20) {
          return 'second';
        } else if (minutes <= 20) {
          return 'minute';
        } else if (hours <= 20) {
          return 'hour';
        } else if (days <= 20) {
          return 'day';
        } else if (weeks <= 20) {
          return 'week';
        } else if (months <= 20) {
          return 'month';
        } else {
          return 'year';
        }
      },

      // Find the nearest bin for a time axis, given a specific time and bin width
      getNearestTimeBin(d, binWidth) {
        const date = new Date(d);
        let bin;

        if (date instanceof Date && !isNaN(date)) {
          switch (binWidth) {
            case 'second':
              bin = new Date(Math.floor(date.getTime() / millis_per_second) * millis_per_second);
              break;
            case 'minute':
              bin = new Date(Math.floor(date.getTime() / millis_per_minute) * millis_per_minute);
              break;
            case 'hour':
              bin = new Date(Math.floor(date.getTime() / millis_per_hour) * millis_per_hour);
              break;
            case 'day':
              bin = new Date(Math.floor(date.getTime() / millis_per_day) * millis_per_day);
              break;
            case 'week':
              bin = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() - date.getUTCDay());
              break;
            case 'month':
              bin = new Date(date.getUTCFullYear(), date.getUTCMonth());
              break;
            case 'year':
              bin = new Date(date.getUTCFullYear(), 0);
              break;
          }

          return bin;
        } else {
          console.warn('Invalid date in chart data!', d);
          return null;
        }
      },

      // Determine either the most common or smallest interval between a set of values
      getInterval(values, getSmallest = false) {
        const intervals = {};

        // Count up the incidences of each interval
        for (let i = 1; i < values.length; i++) {
          const interval = (values[i] - values[i - 1]).toFixed(2);

          if (intervals[interval]) {
            intervals[interval].count++;
          } else {
            intervals[interval] = {
              interval: interval,
              count: 1,
            };
          }
        }

        if (getSmallest) {
          let smallest = Infinity;

          Object.keys(intervals).forEach((key) => {
            if (parseFloat(intervals[key].interval) < smallest) {
              smallest = parseFloat(intervals[key].interval);
            }
          });

          return smallest;
        } else {
          let ret;
          let max = 0;

          // Select the most frequent
          Object.keys(intervals).forEach((key) => {
            const interval = intervals[key].interval;
            const count = intervals[key].count;

            if (count > max) {
              max = count;
              ret = parseFloat(interval);
            }
          });

          return ret;
        }
      },

      // Reset filters when reset button is pressed
      resetAllFilters() {
        dc.filterAll();
        console.log('reset filters');
        this.renderChart(true);
      },

      // Generate a tooltip for a data point on a time axis
      timeTooltip: function (d) {
        return (
          d.key.toLocaleString(undefined, {
            dateStyle: 'short',
            timeStyle: 'short',
          }) +
          ' : ' +
          (this.tooltipValueFormatter ? this.tooltipValueFormatter(d.value) : d.value)
        );
      },

      redrawDueToResize() {
        console.log('redraw due to resize');
        if (this.values.length) {
          this.renderChart(true);
        }
      },
      updateBase64Image() {
        this.updateImageTimeout = setTimeout(() => {
          // Get the SVG code, and serialize it
          const serializer = new XMLSerializer();
          const svgNode = this.$refs.anchor.querySelector('svg');
          const svgStr = serializer.serializeToString(svgNode);

          // We need to convert it to a base64, and load it into an image. We need to make sure the image has loaded before we insert this into the canvas
          new Promise((resolve) => {
            const image = new Image(svgNode.width.baseVal.value, svgNode.height.baseVal.value);
            image.onload = function () {
              resolve(image);
            };
            image.src = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(svgStr)));
          }).then((img) => {
            // Finally, we create a canvas, load the element in there, then convert the image into a PNG
            const canvas = document.createElement('canvas');
            canvas.width = svgNode.width.baseVal.value;
            canvas.height = svgNode.height.baseVal.value;
            const context = canvas.getContext('2d');

            // Make the background white so then it's a bit easier to see
            context.fillStyle = '#ffffff';
            context.fillRect(0, 0, svgNode.width.baseVal.value, svgNode.height.baseVal.value);

            canvas.getContext('2d').drawImage(img, 0, 0, svgNode.width.baseVal.value, svgNode.height.baseVal.value);
            this.base64Image = canvas.toDataURL();
          });
        }, this.options.transitionDuration + 50);
      },
      getChartImage() {
        return this.base64Image;
      },
      setYDomains() {
        this.yDomains = {};
        this.values.forEach((chartData) => {
          const yAxisData = chartData.yAxis;
          this.yDomains[chartData.name] = yAxisData.domain
            ? yAxisData.domain
            : [yAxisData.min ?? 0, yAxisData.max ?? 1];
        });
      },
      updateMarginsForMultipleAxes() {
        this.chart.margins().left = 200;
        this.chart.margins().right = 200;
      },
      initializeChart() {
        if (this.values.length) {
          if (Object.values(this.yDomains).length > 2) {
            this.updateMarginsForMultipleAxes();
          }
          this.setYDomains();
          this.initCrossFilters();
          this.renderChart(true);
        }
      },
    },
    created() {
      // Dc uses d3-schale-chromatic colour schemas from v3 and up, a list can be found here https://github.com/d3/d3-scale-chromatic
      dc.config.defaultColors(d3.schemeSet3);
      if (typeof this.id === 'undefined') {
        this.uniqueID = uniqId('chart_').replace('.', '');
      } else {
        this.uniqueID = this.id;
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.initializeChart();
      });
    },

    watch: {
      values() {
        this.initializeChart();
      },
      showLegend() {
        this.renderChart(true);
      },
    },

    beforeUnmount() {
      // Clear updateImageTimeout if it is still running
      clearTimeout(this.updateImageTimeout);

      // Clean up charts when component is destroyed
      if (this.chart) {
        dc.deregisterChart(this.chart);
      }
      if (this.selectorChart) {
        dc.deregisterChart(this.selectorChart);
      }
    },
  };
</script>

<style lang="scss" scoped>
  @import '@veasel/core';

  .s-axis-chart {
    width: 100%;
    height: 100%;
    margin: auto;
    @include base;
  }

  .reset-link {
    position: absolute;
    z-index: 10;
  }

  .chart-inner {
    width: 100%;
    height: 100%;
  }

  .chart-box {
    flex-grow: 1;
    height: 100%;
    overflow: hidden; // Triggers BFC and avoid descendant floats to escape this container
  }
</style>

<style lang="scss">
  @import '@veasel/core';
  // Scoped CSS cannot effect chart SVG items, so wrap in the container class instead
  .s-axis-chart {
    .dc-chart path.line {
      fill: none;
      stroke-width: 1.5px;
    }

    .chart-title {
      @include subtitle;
    }

    .dc-chart rect.bar {
      &:hover {
        fill-opacity: 0.8;
      }

      &.pointer {
        cursor: pointer;
      }
    }

    .dc-chart rect.bar.deselected {
      fill-opacity: 0.5;
    }

    .dc-chart .grid-line line {
      fill: none;
      stroke: #ccc;
      opacity: 0.5;
      shape-rendering: crispEdges;
    }

    text {
      user-select: none;
      pointer-events: none;
    }
  }
</style>
