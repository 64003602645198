<template>
  <div v-html="processedText" />
</template>

<script>
  import DOMPurify from 'dompurify';
  import {marked} from 'marked';

  export default {
    name: 's-markdown',
    description: 'A markdown reader',
    props: {
      content: {
        description: 'The markdown text',
        type: String,
      },
    },
    computed: {
      processedText() {
        const clean = DOMPurify.sanitize(this.content);
        return marked.parse(clean);
      },
    },
  };
</script>
