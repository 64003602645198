<template>
  <s-input-wrapper
    v-click-outside="closeOutside"
    v-date-overflow="overflow"
    v-bind="$props"
    :class="{'input-error': hasError}"
  >
    <div
      class="picker-wrapper full-width relative"
      tabindex="0"
      @keydown.up.prevent="onKeyDownUpEvent"
      @keydown.down.prevent="onKeyDownDownEvent"
      @keydown.left.stop="onKeyDownLeftEvent"
      @keydown.right.stop="onKeyDownRightEvent"
    >
      <input
        :id="id"
        ref="date-picker"
        v-model="inputValue"
        :name="name"
        type="text"
        :placeholder="_placeholder"
        :disabled="disabled"
        :autocomplete="autocomplete"
        :readonly="readonly"
        class="s-input-group-field full-width"
        @keyup="onKeyEnterPress"
        @click="togglePicker"
      />
      <s-icon type="calendar" class="p-r-s" size="14" />
      <div
        v-if="isOpen && date !== null"
        ref="date-picker-container"
        class="s-input-picker"
        :class="{time: scope === 'datetime'}"
      >
        <picker-years
          ref="pickerYears"
          :to="limitTo"
          :from="limitFrom"
          :date="date"
          :selectedDate="selectedDate"
          :weekStart="weekStartDay"
          :isOpen="isOpen"
          @changeYear="updateYear"
        />
        <picker-months
          ref="pickerMonths"
          :activeMonth="activeMonth"
          :to="limitTo"
          :from="limitFrom"
          :date="date"
          :selectedDate="selectedDate"
          :isOpen="isOpen"
          @changeMonth="updateMonth"
        />
        <picker-days
          :to="limitTo"
          :from="limitFrom"
          :weekStart="weekStartDay"
          :date="date"
          :selectedDate="selectedDate"
          :locale="locale"
          @changeDay="updateDay"
        />
        <picker-time
          v-if="scope === 'datetime'"
          :date="date"
          :selectedDate="selectedDate"
          :currentHour="hour"
          :currentMinute="minute"
          :currentSecond="second"
          :isOpen="isOpen"
          @changeTime="updateTime"
        />
        <div class="controls-wrapper">
          <s-button
            id="setDateTime"
            ref="setDateTime"
            text="Done"
            color="theme-approved"
            :on-click="updateBtn"
          ></s-button>
        </div>
      </div>
    </div>
  </s-input-wrapper>
</template>

<script>
  import {
    id,
    name,
    size,
    syncValue,
    onFocus,
    onBlur,
    isDisabled,
    placeholder,
    readonly,
    onChange,
    onKeyPressed,
  } from '@veasel/core/mixins';
  import {STime} from '@veasel/core/time';
  import SInputWrapper from '@veasel/inputs/input-wrapper';
  import PickerDays from './pickerDays.vue';
  import PickerMonths from './pickerMonths.vue';
  import PickerYears from './pickerYears.vue';
  import PickerTime from './pickerTime.vue';

  export default {
    name: 's-input-datetime',
    description: 'A user input for date object.',
    components: {
      PickerDays,
      PickerMonths,
      PickerYears,
      PickerTime,
      SInputWrapper,
    },
    emits: ['update:modelValue', 'blur'],
    mixins: [
      id,
      name,
      size(),
      syncValue([String, Object]),
      onChange(),
      onFocus(),
      onBlur(),
      isDisabled,
      placeholder,
      readonly,
      onKeyPressed(),
    ],
    inject: ['DATETIME_LOCALE'],
    props: {
      autocomplete: {
        type: String,
        default: 'off',
      },
      firstDayOfTheWeek: {
        description:
          'Numeric value representing first day of the week in the calendar Allowed values between 1 & 7 (1 = Sunday, 2 = Monday etc)',
        type: Number,
        default: 2,
        validate: (val) => parseInt(val) >= 1 && parseInt(val) <= 7,
      },
      scope: {
        type: String,
        description:
          'String value to check what type of calendar to show based on date or date and time. Allow values: `date` and `datetime`',
        default: 'date',
        validate: (val) => (typeof val === 'string' && val === 'date') || val === 'datetime',
      },
      locale: {
        type: String,
        description: 'String representing current locale. Used to pick a format needed for STime. Ex. `en-GB`, `en-US`',
        default: STime.localeInfo().locale,
        validate: (val) => typeof val === 'string',
      },
      to: {
        type: [String, Object],
        description:
          'String or STime object representing the starting date. It is used to limit the date/datetime range, strating point',
        default: '',
      },
      overflow: {
        description: 'Indicates whether the input modal should escape from scrollable overflow containers',
        type: String,
        values: ['auto', 'enabled', 'disabled'],
        validator: (v) => ['auto', 'enabled', 'disabled'].includes(v),
        default: 'auto',
      },
      from: {
        type: [String, Object],
        description:
          'String or STime object representing a end date. It is used to limit the date/datetime range, ending point.',
        default: '',
      },
      timezone: {
        type: String,
        description: 'String representing the timezone. Ex. "America/New_York"',
        default: STime.localeInfo().timeZone,
        validate: (val) => typeof val === 'string',
      },
    },
    data: function () {
      return {
        isOpen: false,
        date: null,
        month: null,
        day: null,
        year: null,
        hasError: false,
        hour: null,
        minute: null,
        second: null,
        inputValue: '',
      };
    },
    computed: {
      getLocale() {
        return this.DATETIME_LOCALE ? this.DATETIME_LOCALE : this.$props.locale;
      },
      activeMonth() {
        return this.month;
      },
      weekStartDay() {
        if (this.date && this.date.toDate().toString() !== ' Invalid Date') {
          const day = new STime([this.year, this.month, 0], 'naive');
          const days = ['', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
          const daysNum = days.indexOf(day.format({weekday: 'short'}, 'en-UK')) % 7;
          return daysNum;
        }
        return this.$props.firstDayOfTheWeek;
      },
      limitTo() {
        const {timezone, to} = this.$props;
        if (to && to !== '' && to.length > 8) {
          const toDate = new STime(to, timezone);
          return toDate;
        }
        return false;
      },
      limitFrom() {
        const {timezone, from} = this.$props;
        if (from && from !== '' && from.length > 8) {
          const fromDate = new STime(from, timezone);
          return fromDate;
        }
        return false;
      },
      selectedDate() {
        const {scope, timezone} = this.$props;
        const format = this.getFormat(scope, this.getLocale, timezone);
        return new STime(this.$_value, timezone, format);
      },
    },
    methods: {
      onKeyDownUpEvent(e) {
        if (!e.target.nodeName.includes('INPUT')) {
          this.$refs.pickerYears.prevYear();
        }
      },
      onKeyDownDownEvent(e) {
        if (!e.target.nodeName.includes('INPUT')) {
          this.$refs.pickerYears.nextYear();
        }
      },
      onKeyDownLeftEvent(e) {
        if (!e.target.nodeName.includes('INPUT')) {
          this.$refs.pickerMonths.prevMonth();
        }
      },
      onKeyDownRightEvent(e) {
        if (!e.target.nodeName.includes('INPUT')) {
          this.$refs.pickerMonths.nextMonth();
        }
      },
      closeOutside() {
        if (this.inputValue !== '' && this.date && this.$_value !== this.inputValue) {
          const {scope, timezone} = this.$props;
          const format = this.getFormat(scope, this.getLocale, timezone);
          this.updateDataTimePicker(this.inputValue, format);
          this.setValues();
        }
        this.isOpen = false;
      },
      getFormat(scope, locale, timezone) {
        const formatLocale = STime.localeInfo(locale, timezone);
        const format = scope === 'date' ? formatLocale.inputFormat.date : formatLocale.inputFormat.dateTime;
        return format.trim();
      },
      togglePicker() {
        if (this.date && isNaN(this.date.date)) {
          this.isOpen = false;
        } else {
          this.isOpen = !this.isOpen;
        }
      },
      updateTime(time) {
        switch (time.type) {
          case 'hour':
            this.hour = time.value;
            break;
          case 'minute':
            this.minute = time.value;
            break;
          case 'second':
            this.second = time.value;
            break;
        }
        this.updateDataTimePicker();
      },
      updateYear(year, update) {
        this.year = year;
        this.updateDataTimePicker();
        if (update) {
          this.isOpen = false;
        }
      },
      updateMonth(month, update) {
        this.year = parseInt(month.year);
        this.month = parseInt(month.month);
        this.updateDataTimePicker();
        if (update) {
          this.isOpen = false;
        }
      },
      updateDay(day) {
        this.day = day;
        this.updateDataTimePicker();
        this.setValues();
        this.$_onChange();
        this.isOpen = false;
      },
      onKeyEnterPress(e) {
        if (e.keyCode === 13 && e.target.value !== '') {
          const {scope, timezone} = this.$props;
          const format = this.getFormat(scope, this.getLocale, timezone);
          this.updateDataTimePicker(e.target.value, format);
          this.setValues();
        } else if (e.target.value === '') {
          this.$_value = '';
        }
        this.isOpen = false;
      },
      setValuesTimeUnits() {
        const {timezone} = this.$props;
        this.year = this.date.format({year: 'numeric'});
        this.month = this.date.format({month: 'numeric'});
        this.day = this.date.format({day: 'numeric'});
        if (this.$props.scope === 'datetime') {
          this.hour = this.date.format({hour: '2-digit'}, this.getLocale, timezone);
          this.minute = this.date.format({minute: '2-digit'}, this.getLocale, timezone);
          this.second = this.date.format({second: '2-digit'}, this.getLocale, timezone);
        }
      },
      updateDataTimePicker(data, format) {
        const {from, to, scope, timezone} = this.$props;
        const tz = scope === 'date' ? 'naive' : timezone;
        if (data && data !== null && data.trim() !== '') {
          const date = new STime(data, tz, format ? format : undefined);
          const isBefore = from || from !== '' ? date.isBefore(new STime(from)) : false;
          const isAfter = to || to !== '' ? date.isAfter(new STime(to)) : false;
          if (date.toDate().toString() === 'Invalid Date' || isBefore || isAfter) {
            this.hasError = true;
            setTimeout(() => {
              this.hasError = false;
            }, 1500);
          } else {
            this.date = date;
            this.setValuesTimeUnits();
          }
        } else {
          if (this.date === null || data === null) {
            this.date = new STime(new Date(), tz);
            this.setValuesTimeUnits();
          } else {
            const daysInThisMonth = new STime([this.year, this.month, 1], tz).daysInMonth();
            const dtArray = [
              this.year,
              this.month,
              parseInt(this.day) > daysInThisMonth ? daysInThisMonth : this.day,
              this.hour,
              this.minute,
              this.second,
            ];
            this.date = new STime(dtArray, tz);
          }
        }
      },
      updateBtn(e) {
        this.updateDataTimePicker();
        this.setValues();
        this.togglePicker(e);
      },
      setValues() {
        if (!this.hasError && this.date) {
          this.$_value = this.date;
          this.$_onChange();
        }
      },
    },
    watch: {
      $_value: {
        handler(newValue, oldValue) {
          if (newValue !== oldValue) {
            this.$nextTick(() => {
              this.inputValue = newValue;
              const {scope, timezone} = this.$props;
              const format =
                !this.$_value || this.$_value.includes('-')
                  ? undefined
                  : this.getFormat(scope, this.getLocale, timezone);
              this.updateDataTimePicker(this.$_value, format);
            });
          }
        },
      },

      isOpen(newValue) {
        if (!newValue) {
          this.$_onBlur();
        }
      },
    },
    created() {
      const {scope, timezone} = this.$props;
      const minInputLength = scope === 'date' ? 8 : 16;
      const format =
        !this.$_value || this.$_value.includes('-') ? undefined : this.getFormat(scope, this.getLocale, timezone);
      const getValue = (value) => {
        if (value === '' || value === null || typeof value === 'undefined' || value.length < minInputLength)
          return value;
        const tz = scope === 'date' ? 'naive' : timezone;
        let date;
        if (typeof value === 'string') {
          date = new STime(value, tz);
        } else {
          date = value;
        }
        if (date.toDate().toString().toLowerCase() === 'invalid date') {
          return value;
        }
        if (scope === 'date') {
          return date.format('dateNumeric', this.getLocale).toString();
        } else {
          return date.format('dateTimeNumeric', this.getLocale, tz).toString();
        }
      };

      const setValue = (value) => {
        if (value === '' || value === null || typeof value === 'undefined' || value.length < minInputLength)
          return value;
        if (value.toDate().toString().toLowerCase() === 'invalid date') {
          return value;
        }
        if (scope === 'date') {
          return value.format('isodate');
        } else {
          return value.format('iso', this.getLocale);
        }
      };
      this.updateDataTimePicker(this.$_value, format);

      this.$data.$_formatersValue.push(getValue);
      this.$data.$_unformattersValue.push(setValue);
    },
    mounted() {
      this.$refs['date-picker'].addEventListener('keydown', this.$_onKeyPressed);
      this.$refs['date-picker'].addEventListener('focus', this.$_onFocus);
    },
  };
</script>

<style scoped lang="scss">
  @import '@veasel/core';

  $height: 32px;
  $width: 32px;

  .inactive {
    color: var(--secondary-50) !important;
    pointer-events: none;
  }

  .s-input {
    &-picker {
      z-index: 42;
      min-width: 500px;
      background: white;
      box-shadow: 0 0 6px #00000029;
      padding: 5px;
      position: absolute;
      left: 0;
      top: 35px;
      border-radius: 4px;
      display: grid;
      grid-template-areas:
        'years months time'
        'years calendar time'
        'years controls time';
      grid-template-rows: $height $height * 7 $height;
      grid-template-columns: minmax(auto, 72px) 1fr minmax(min-content, auto);
      gap: 5px;
      font-weight: bold;

      .controls-wrapper {
        grid-area: controls;
        place-self: end;
      }

      &.time {
        min-width: 600px;
      }
    }
  }

  .s-icon {
    @include base;

    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    &:hover {
      background: transparent;
    }
  }

  .relative {
    position: relative !important;
  }
</style>
