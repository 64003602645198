<template>
  <div ref="datalist" class="list-main-container">
    <!-- List Features Row, above list content -->
    <s-filtered-search
      v-if="showDatalistHeader"
      v-model="filteringQuery"
      :options="opts.additionalFilter.filters"
      :parsing="opts.additionalFilter.parsing"
      :show-search="opts.search.display"
      :show-filters="opts.additionalFilter.display"
      class="m-b-m"
      @change="filterUpdated"
    />
    <div v-if="showDatalistHeader" class="datalist-header relative">
      <div class="left-area flex align-items-center">
        <!-- Select / Deselect all checkboxes, leftmost -->
        <div v-if="opts.checkboxes.display && opts.checkboxes.selectAll" class="checkbox-all-container">
          <s-input-checkbox
            v-model="checkboxSelectAll"
            class="checkbox-all m-l-s"
            :on-change="updateCheckboxSelectAll"
            :title="checkboxSelectAll ? 'Unselect all' : 'Select all'"
            :label="selectAllLabel"
            size="small"
            data-cy="checkbox-all"
          />
        </div>

        <!--Actions, left -->
        <div
          v-if="
            opts.exportView.listing ||
            (opts.checkboxes.display &&
              opts.checkboxes.actions.length > 0 &&
              opts.checkboxes.actions.some((a) => a.visible))
          "
          class="list-actions m-r-m"
        >
          <!-- Lonely Action Button (if there is only one enabled action) -->
          <s-button
            v-if="hasOnlyOneEnabledAction"
            class="lonely-action-button"
            color="theme-background-secondary"
            :text="actions[0].name"
            :sticker="checkboxSelection.length ? '' + checkboxSelection.length : false"
            :on-click="callTheActionCallback"
            :disable="(!opts.exportView.listing && checkboxSelection.length === 0) || opts.actionButton?.disable"
          />
          <!-- Actions Dropdown -->
          <s-dropdown
            v-else
            ref="actionDropdown"
            align="left"
            button-text="Actions"
            button-icon="bolt"
            :disable="(!opts.exportView.listing && checkboxSelection.length === 0) || opts.actionButton?.disable"
            :tooltipText="opts.actionButton.tooltipText ? opts.actionButton.tooltipText : undefined"
            :tooltipPosition="opts.actionButton.tooltipPosition ? opts.actionButton.tooltipPosition : undefined"
            close-on-scroll
          >
            <s-dropdown-item
              v-for="(action, index) in actions"
              v-show="action.safeVisible(checkboxSelection)"
              :key="index"
              :button-icon="action.icon"
              :disable="action.safeDisable(checkboxSelection)"
              :tooltipText="action.safeDisable(checkboxSelection) ? action.disabledMsg : ''"
              :button-text="action.name"
              :on-click="() => action.callback(checkboxSelectionIds, checkboxSelection)"
            />
          </s-dropdown>
        </div>

        <div class="selection flex">
          <div class="theme-style-caption-alt">
            Showing {{ totalVisibleRows }} {{ getItemsName }}
            <span v-if="opts.pagination.num_entries">of {{ opts.pagination.num_entries }}</span>
          </div>
          <div v-if="opts.checkboxes.display" class="theme-style-caption-alt-secondary m-l-m">
            {{ checkboxSelection.length }} Selected
          </div>
        </div>
      </div>
      <div class="additional-buttons float right relative">
        <slot name="buttons"></slot>
      </div>
    </div>

    <!-- List Rows -->
    <div class="list-rows-container">
      <template v-for="(row, index) in alteredRows" :key="'list-row-' + index + '-' + queryIndex">
        <div
          :id="'list-row-' + index"
          class="list-row p-y-s"
          :class="[
            options.noPadding ? 'no-padding' : '',
            opts.highlight === row[opts.uniqId] ? 'highlight' : '',
            row.disabled ? 'disabled' : '',
            ...getExtraClasses(row.classes),
          ]"
          @click="(event) => rowClick(event, row, index)"
          @mouseenter="updateHoverStatus(row, true)"
          @mouseleave="updateHoverStatus(row, false)"
        >
          <div
            v-if="opts.checkboxes.display"
            class="checkbox-inline-container p-x-s"
            :title="row.actionAvailable ? undefined : 'No available action for this row'"
          >
            <s-input-checkbox
              v-if="row.actionAvailable"
              :id="'checkbox-' + safeGet(row, opts.uniqId)"
              v-model="checkboxSelectionMap[safeGet(row, opts.uniqId)]"
              :disabled="row.disabled"
              size="small"
            />
            <!-- checkbox is not displayed if none action is available for the row -->
            <div v-else :style="{width: '18px'}"></div>
          </div>
          <div class="slot-inline-container" :class="opts.checkboxes.display ? 'fit-checkbox' : ''">
            <slot v-bind:row="row" v-bind:index="index" :isHovering="isHovering[row[opts.uniqId]] || false"></slot>
          </div>
        </div>
      </template>
    </div>

    <!-- Loading panel covers rows -->
    <s-loading-panel :state="opts.loading ? 'loading' : 'completed'" :show-text="false" :background="false" />

    <!-- Pagination, below content -->
    <div v-if="opts.pagination.num_entries" ref="pagination" class="pagination-container x-grid m-t-m">
      <div class="row x-row">
        <div class="col-10" :class="getPaginationColSize">
          <s-pagination
            v-if="opts.pagination"
            :offset="opts.pagination.offset"
            :limit="opts.pagination.limit"
            :num-entries="opts.pagination.num_entries"
            :align="opts.pagination.align"
            @change-page="changePage"
          ></s-pagination>
        </div>
        <div class="flex justify-content-flex-end" :class="getPaginationColSize">
          <div
            v-if="opts.settings.pageSize && opts.settings.pageSize.length > 1"
            class="flex align-items-center justify-content-flex-end"
          >
            <span class="theme-style-caption-alt m-r-s">Show rows on page:</span>
            <s-input-select
              v-model="opts.pagination.limit"
              :options="opts.settings.pageSize"
              class="page-selector"
              :on-change="() => updateRowsOnPage()"
              :searchable="false"
              :sort="false"
              data-cy="select-rows-count"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {safeGet, capitalizeFirst, isDeepEqual, debounce} from '@veasel/core/tools';
  import {
    optionsLogic,
    checkboxLogic,
    exportLogic,
    paginationLogic,
    columnsLogic,
    queryLogic,
    searchLogic,
    sortingLogic,
  } from '@veasel/core/mixins';
  import {colorsMixin} from '@veasel/core/colors';

  import button from '@veasel/base/button';
  import {dropdown, dropdownItem} from '@veasel/base/dropdown';
  import loadingPanel from '@veasel/base/loading-panel';
  import inputSelect from '@veasel/inputs/input-select';
  import inputCheckbox from '@veasel/inputs/input-checkbox';
  import filteredSearch from '@veasel/data/filtered-search';
  import pagination from '@veasel/data/pagination';

  const DEFAULT_OPTION_OVERRIDES = {
    queryInUrl: true, // Reflect datalist state (page, search and filters) in route query
    frontend: false, // Boolean (force pagination and search etc to only be handled back end)
    stickyHeader: false, // Boolean | Number (headers not present in datalist)
    stickyScroller: false, // Boolean | Number (scroller not present in datalist)
    pagination: {
      limit: 10,
    },
    settings: {
      pageSize: false,
    },
  };

  export default {
    name: 's-data-list',

    components: {
      's-button': button,
      's-dropdown': dropdown,
      's-dropdown-item': dropdownItem,
      's-loading-panel': loadingPanel,
      's-input-select': inputSelect,
      's-input-checkbox': inputCheckbox,
      's-filtered-search': filteredSearch,
      's-pagination': pagination,
    },

    mixins: [
      optionsLogic,
      checkboxLogic,
      exportLogic,
      paginationLogic,
      columnsLogic,
      queryLogic,
      searchLogic,
      sortingLogic,
      colorsMixin,
    ],

    props: {
      modelValue: {
        type: Object,
      },
      rows: {
        type: Array,
        default: () => [],
      },
      options: {
        type: Object,
        default: () => ({}),
      },
    },

    emits: ['checkboxes-updated', 'export-view', 'query-updated', 'search-updated', 'row-click', 'update:modelValue'],

    data() {
      return {
        safeGet: safeGet,
        queryIndex: 0, // Appended to the :key property of each row to ensure the row and all sub-components are re-rendered correctly
        isHovering: {},
        localFilteringQuery: {search: '', filters: {}},
      };
    },

    computed: {
      filteringQuery: {
        // Use modelValue if passed, otherwise uses local state
        get() {
          return this.modelValue ? this.modelValue : this.localFilteringQuery;
        },
        set(newValue) {
          if (this.modelValue) {
            this.$emit('update:modelValue', newValue);
          } else {
            this.localFilteringQuery = newValue;
          }
        },
      },
      alteredRows: function () {
        // For consistency, we always want to show checkboxes if the checkbox is meant to be visible
        return this.visibleRows.map((row) => ({...row, actionAvailable: true}));
      },
      showDatalistHeader: function () {
        return (
          (this.opts.checkboxes.display && this.opts.checkboxes.selectAll) ||
          (this.opts.checkboxes.display &&
            this.opts.checkboxes.actions.length > 0 &&
            this.opts.checkboxes.actions.some((a) => a.visible)) ||
          (this.opts.additionalFilter.display && this.opts.additionalFilter.filters.length) ||
          this.opts.search.display ||
          this.opts.exportView.listing ||
          this.$slots.buttons
        );
      },
      getItemsName: function () {
        return capitalizeFirst(this.opts.rowName(this.visibleRows.length !== 1));
      },
      getPaginationColSize() {
        return this.opts.pagination.align !== 'center' ? 'col-2' : 'col-12';
      },
    },

    watch: {
      rows() {
        this.queryIndex += 1; // Increment the query index to ensure the :key property of each row is new
      },
    },

    methods: {
      getExtraClasses(extraClasses) {
        if (Array.isArray(extraClasses)) {
          return extraClasses;
        }
        return [];
      },
      rowClick(event, row, index) {
        if (event.target.tagName.toLowerCase() !== 'a' && !event.target.classList.contains('no-click')) {
          this.$emit('row-click', {event, row, index});
        }
      },
      updateHoverStatus(row, state) {
        this.isHovering[row[this.opts.uniqId]] = state;
      },
      filterUpdated() {
        if (!isDeepEqual(this.lastQueryObject, this.queryObject)) {
          this.$emit(this.opts.additionalFilter.event, this.queryObject);
        }
      },
      setFilters() {
        if (this.opts && this.opts.additionalFilter) {
          this.filteringQuery = {filters: this.opts.additionalFilter.selection, search: this.filteringQuery.search};
        }
      },
    },

    created: function () {
      this.initOptions(DEFAULT_OPTION_OVERRIDES);
    },

    mounted: function () {
      this.throttledSearch = debounce(this.searchInRows, this.opts.search.timeLimit);
    },
  };
</script>

<style scoped lang="scss">
  @import '@veasel/core/sass/conf';
  @import '@veasel/core/sass/base/spacing';

  .list-row {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgba(var(--secondary-50-rgb), 0.5);
    transition: $button-transition;

    &:hover {
      background: rgba(var(--secondary-light-rgb), 0.5);
    }

    &.highlight {
      background: var(--secondary-light);
    }

    &.no-padding {
      padding: 0;
    }

    &.disabled {
      pointer-events: none;
    }
  }

  .page-selector {
    flex-grow: 0;
    flex-basis: 0;
    flex-shrink: 0;
    width: 62px;
  }

  .checkbox-inline-container {
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .slot-inline-container {
    width: 100%;
    max-width: 100%;

    &.fit-checkbox {
      max-width: calc(100% - 26px);
      margin-left: get-spacing('m');
    }
  }

  .datalist-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: get-spacing('m');
    border-bottom: 2px solid var(--secondary-50);

    .checkbox-all-container {
      margin-right: get-spacing('m');
    }

    .list-actions {
      flex: 0 1 auto;

      .lonely-action-button {
        width: 100%;
      }
    }

    .list-additional-filter {
      flex: 0 1 auto;
      min-height: 38px;
      margin-right: 10px;
    }

    .list-export button {
      margin-left: 10px;
    }

    .list-settings {
      width: 130px;
      min-height: 38px;
    }

    ::v-deep(.s-dropdown) {
      user-select: none;
      width: 100%;

      .additional-filters-content {
        text-align: left;
      }

      .settings-content {
        width: 300px;
        text-align: left;

        .table-size {
          select {
            width: 60px;
          }
        }

        .table-ordering {
          .container {
            overflow-y: auto;
            max-height: 220px;
          }

          li {
            position: relative;
            height: 18px;
            padding: 4px 6px;

            label {
              padding-left: 24px;
              cursor: pointer;
            }

            input {
              width: 18px;
              height: 18px;
              left: 0;
              margin-top: -1px;
              position: absolute;
            }
          }
        }
      }
    }

    .additional-buttons ::v-deep(.s-button) {
      margin-left: 10px;
    }
  }

  .list-additional-filter {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  // Hack to fix first item in events list
  ::v-deep(.list-row:first-child .vertical-bar) {
    top: -8px;
  }
</style>
