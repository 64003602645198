import {smoothScroll} from '@veasel/core/mixins';

export default {
  mixins: [smoothScroll],
  data: function () {
    return {
      scrollers: [],
      currentAggScroll: 0,
      isCurrentlyScrolling: false,
    };
  },
  computed: {
    aggInnerWidth: function () {
      return this.opts.aggColWidth * this.visibleAggregates.length;
    },
    aggOuterWidth: function () {
      return Math.min(
        Math.floor(this.containerWidth / 2 / this.opts.aggColWidth) * this.opts.aggColWidth,
        this.aggInnerWidth
      );
    },
    isAggScrolling: function () {
      return this.aggInnerWidth > this.aggOuterWidth;
    },
    groupColWidth: function () {
      return Math.max(
        (this.containerWidth - this.aggOuterWidth) / this.cleanQuery.groups.length,
        this.opts.minGrpColWidth
      );
    },
    groupOuterWidth: function () {
      return this.containerWidth - this.aggOuterWidth;
    },
    groupInnerWidth: function () {
      return this.groupColWidth * this.cleanQuery.groups.length + this.aggOuterWidth;
    },
    isAggScrollLeftAllowed: function () {
      return this.currentAggScroll !== 0;
    },
    isAggScrollRightAllowed: function () {
      return this.currentAggScroll + this.aggOuterWidth !== this.aggInnerWidth;
    },
  },

  methods: {
    // synchronize scroll between different parts
    scrollSync: function (event, source) {
      let newScrollLeft;
      if (typeof event === 'number') {
        newScrollLeft = event;
      } else {
        newScrollLeft = event.target.scrollLeft;
      }

      if (!this.isCurrentlyScrolling) {
        this['isCurrentlyScrolling'] = true;
        this.selectionTotal = false;

        if (source === 'scroller') {
          if (this.$refs.headerScroller) {
            this.$refs.headerScroller.scrollLeft = newScrollLeft;
          }
          this.$refs.tableScroller.scrollLeft = newScrollLeft;
          this.$refs.selectorScroller.scrollLeft = newScrollLeft;
          this.$refs.scrollScroller.scrollLeft = newScrollLeft;
          this.isCurrentlyScrolling = false;
        } else {
          this.smoothScroll(this.$refs.headerScroller, 'scrollLeft', newScrollLeft, 50).then(() => {
            this.isCurrentlyScrolling = false;
          });
          this.smoothScroll(this.$refs.tableScroller, 'scrollLeft', newScrollLeft, 50);
          this.smoothScroll(this.$refs.selectorScroller, 'scrollLeft', newScrollLeft, 50);
          this.smoothScroll(this.$refs.scrollScroller, 'scrollLeft', newScrollLeft, 50);
        }
      }
    },

    // scroll horizontally if selection is out of visible container
    scrollToGroup: async function (target) {
      if (target.getAttribute('col-index') !== null) {
        // We are moving around an agg column
        const outerWidth = this.aggOuterWidth;
        const rightLimit = target.offsetLeft + target.offsetWidth - target.offsetParent.offsetParent.scrollLeft;
        const leftLimit = target.offsetLeft - target.offsetParent.offsetParent.scrollLeft;
        if (rightLimit > outerWidth) {
          await this.scrollAggRight();
        } else if (leftLimit < 0) {
          await this.scrollAggLeft();
        }
      } else {
        const rightLimit = target.offsetLeft + target.offsetWidth - this.$refs.tableScroller?.scrollLeft;
        const leftLimit = target.offsetLeft - this.$refs.tableScroller?.scrollLeft;
        let newScrollLeft = this.$refs.tableScroller.scrollLeft;
        if (rightLimit > this.groupOuterWidth) {
          newScrollLeft += rightLimit - this.groupOuterWidth;
        } else if (leftLimit < 0) {
          newScrollLeft += leftLimit;
        }
        this.scrollSync(newScrollLeft, 'selectorNav');
      }
    },

    // scroll vertically if selection is out of container/window
    verticalScrollToSelection: function (target) {
      const isAgg = target.getAttribute('col-index') !== null;
      const topLimit = target.offsetTop + (isAgg ? target.offsetParent.offsetParent.offsetTop : 0);
      const bottomLimit =
        target.offsetTop + (isAgg ? target.offsetParent.offsetParent.offsetTop : 0) + target.offsetHeight;
      for (let i = 0; i < this.scrollers.length; i++) {
        const firstScroller = this.scrollers[i];
        if (topLimit < this.$refs.headerScroller.offsetParent.offsetTop + 23) {
          firstScroller.scrollTop += topLimit - (this.$refs.headerScroller.offsetParent.offsetTop + 23);
        } else if (bottomLimit > this.$refs.scrollScroller.offsetParent.offsetTop) {
          firstScroller.scrollTop += bottomLimit - this.$refs.scrollScroller.offsetParent.offsetTop;
        }
      }
    },

    // scroll the aggregation panel left
    scrollAggLeft: async function () {
      this.isCurrentlyScrolling = true;
      this.currentAggScroll = this.$refs.headerAggScroller.scrollLeft - this.opts.aggColWidth;
      document.querySelectorAll('.agg-result').forEach((el) => {
        this.smoothScroll(el, 'scrollLeft', this.currentAggScroll, 300);
      });
      await this.smoothScroll(this.$refs.headerAggScroller, 'scrollLeft', this.currentAggScroll, 300);
      this.isCurrentlyScrolling = false;
    },

    // scroll the aggregation panel right
    scrollAggRight: async function () {
      this.isCurrentlyScrolling = true;

      this.currentAggScroll = this.opts.aggColWidth + this.$refs.headerAggScroller.scrollLeft;
      document.querySelectorAll('.agg-result').forEach((el) => {
        this.smoothScroll(el, 'scrollLeft', this.currentAggScroll, 300);
      });
      await this.smoothScroll(this.$refs.headerAggScroller, 'scrollLeft', this.currentAggScroll, 300);
      this.isCurrentlyScrolling = false;
    },
  },
};
