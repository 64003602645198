import {isEmpty, clone, uniqId} from '@veasel/core/tools';

export default {
  methods: {
    saveValue() {
      if (this.checkValidity(true)) {
        this.handlePrecedence();
        if (typeof this.parser === 'function') {
          this.$_value = this.parser(
            this.elements,
            this.elements.map((e) => e.key),
            this.elements.map((e) => e.key).join(' '),
            this.parseAsNested(),
            this.allOptions
          );
        } else if (this.parser === 'raw') {
          this.$_value = this.elements;
        } else if (this.parser === 'flat') {
          this.$_value = this.elements.map((e) => e.key);
        } else if (this.parser === 'text') {
          this.$_value = this.elements
            .map((e) => {
              if (!isEmpty(e.anonymousAttributes)) {
                const stringify = e.key + '(' + JSON.stringify(e.anonymousAttributes) + ')';
                // Remove unneeded brackets and speech marks
                return stringify.replace(/["|[|\]]/gim, '');
              } else {
                return e.key;
              }
            })
            .join(' ');
        } else if (this.parser === 'nested') {
          this.$_value = this.parseAsNested();
        }
      }
      this.$_onChange(this.$_value);
    },

    loadValue(value) {
      if (isEmpty(value)) {
        return true;
      }
      const valueToProcess = clone(value);
      if (typeof this.unparser === 'function') {
        // We offer the nested version to the parser, so we want to be able to run the custom unparser, then load the results from this into the built-in unparsers
        if (this.unparserPostProcessor === false) {
          this.elements = this.unparser(valueToProcess, this.allOptions);
        } else if (this.unparserPostProcessor === 'raw') {
          this.elements = this.loadFromRaw(this.unparser(valueToProcess, this.allOptions));
        } else if (this.unparserPostProcessor === 'flat') {
          this.elements = this.loadFromFlat(this.unparser(valueToProcess, this.allOptions));
        } else if (this.unparserPostProcessor === 'text') {
          this.elements = this.loadFromText(this.unparser(valueToProcess, this.allOptions));
        } else if (this.unparserPostProcessor === 'nested') {
          this.elements = this.loadFromNested(this.unparser(valueToProcess, this.allOptions));
        }
      } else if (this.unparser === 'raw') {
        this.elements = this.loadFromRaw(valueToProcess);
      } else if (this.unparser === 'flat') {
        this.elements = this.loadFromFlat(valueToProcess);
      } else if (this.unparser === 'text') {
        this.elements = this.loadFromText(valueToProcess);
      } else if (this.unparser === 'nested') {
        this.elements = this.loadFromNested(valueToProcess);
      }
      this.currentType = (this.elements[this.elements.length - 1] || {type: 'start'}).type;
    },

    loadFromRaw(value) {
      return value.map((elem) => ({
        ...elem,
        id: uniqId(),
      }));
    },

    loadFromFlat(value) {
      return value.map((key) => ({
        ...this.extractAttributesFromString(key),
        id: uniqId(),
      }));
    },

    loadFromText(value) {
      if (isEmpty(value)) {
        return [];
      }
      return value.split(' ').map((key) => ({
        ...this.extractAttributesFromString(key),
        id: uniqId(),
      }));
    },

    loadFromNested(value) {
      if (typeof value === 'string') {
        return [this.allOptions.find((o) => o.key === value)];
      } else if (value instanceof Array) {
        if (value.length === 0) {
          return value;
        }
        if (typeof value[0] === 'string') {
          return this.buildElementFromString(value[0]);
        } else {
          return [this.extractAttributesFromObject(value[0])];
        }
      } else if (value instanceof Object) {
        let flattened = [];
        const operator = this.allOptions.find((o) => o.key === value[this._keyDict.operator]);
        const operand = value[this._keyDict.operand];
        if (operator.type === 'modifiersPrefix') {
          flattened.push(operator);
        }
        operand.forEach((item, index, array) => {
          if (typeof item === 'string') {
            flattened.push(...this.buildElementFromString(item));
          } else if (item instanceof Array && item.length === 1) {
            flattened.push(this.allOptions.find((o) => o.key === item[0]));
          } else if (typeof item === 'object' && item[this._keyDict.operand]) {
            if (item[this._keyDict.operand].length > 1) {
              flattened.push(this.groupStartObject);
            }
            flattened = flattened.concat(this.loadFromNested(item));
            if (item[this._keyDict.operand].length > 1) {
              flattened.push(this.groupEndObject);
            }
          } else if (typeof item === 'object' && item[this._keyDict.function]) {
            flattened.push(this.extractAttributesFromObject(item));
          }
          if (operator.type === 'operators' && index < array.length - 1) {
            flattened.push(operator);
          }
        });
        if (operator.type === 'modifiersSuffix') {
          flattened.push(operator);
        }
        return flattened.map((item) => ({
          ...item,
          id: uniqId(),
        }));
      }
    },
    buildElementFromString(value) {
      const label = this.allOptions.find((o) => o.key === value);
      if (label === undefined) {
        return [
          {
            label: value,
            id: uniqId(),
            tooltip: 'This option is no longer valid. Please remove',
            type: 'invalid',
          },
        ];
      }
      return [
        {
          ...this.allOptions.find((o) => o.key === value),
          id: uniqId(),
        },
      ];
    },

    extractAttributesFromObject(item) {
      const funcName = item[this._keyDict.function];
      const anonymousAttributes = item[this._keyDict.anonymousAttributes];
      const attributes = item[this._keyDict.attributes];
      const elem = this.allOptions.find((o) => o.key === funcName);
      return {
        ...elem,
        attributes,
        anonymousAttributes,
        id: uniqId(),
      };
    },

    extractAttributesFromString(key) {
      const isFunction = key.includes('(') && key.includes(')');
      let funcName;
      let funcAttrs;
      let isAnonymousAttrs;
      let anonymousAttributes;
      let attributes;
      if (isFunction) {
        funcName = key.match(/[^(]*/)[0];
        funcAttrs = key.match(/\((.*)\)/)[1];
        isAnonymousAttrs = !funcAttrs.includes(':');
        if (isAnonymousAttrs) {
          const attrs = funcAttrs
            .split(',')
            .map((item) => `"${item}"`)
            .join(',');
          anonymousAttributes = JSON.parse(`[[${attrs}]]`);
        } else {
          anonymousAttributes = undefined;
        }
        attributes = !isAnonymousAttrs
          ? funcAttrs.split(',').reduce((acc, elem) => {
              const key = elem.split(':')[0];
              const value = elem.split(':')[1];
              return {...acc, [key]: value.includes('"') ? value.replace(/"/g, '') : parseFloat(value)};
            }, {})
          : undefined;
      }
      const elem = this.allOptions.find((o) => (isFunction ? o.key === funcName : o.key === key));
      return {
        ...elem,
        attributes,
        anonymousAttributes,
      };
    },

    replaceParenthesisByNestedArrays(flat) {
      const nested = [];
      let child = [];
      let groupLevel = 0;
      flat.forEach((element) => {
        if (element.type === 'groupEnd' && groupLevel === 1) {
          nested.push(this.replaceParenthesisByNestedArrays(child));
          groupLevel--;
        } else if (groupLevel > 0) {
          if (element.type === 'groupEnd') {
            groupLevel--;
          } else if (element.type === 'groupStart') {
            groupLevel++;
          }
          child.push(element);
        } else if (element.type === 'groupStart') {
          child = [];
          groupLevel++;
        } else {
          nested.push(element);
        }
      });
      return nested;
    },

    replaceNestedArraysByParenthesis(nested) {
      let flat = [];
      nested.forEach((element) => {
        if (element instanceof Array) {
          flat.push(this.groupStartObject);
          element.forEach((e) => {
            if (e instanceof Array) {
              flat = [...flat, this.groupStartObject, ...this.replaceNestedArraysByParenthesis(e), this.groupEndObject];
            } else {
              flat.push(e);
            }
          });
          flat.push(this.groupEndObject);
        } else {
          flat.push(element);
        }
      });
      return flat;
    },

    parseAsNested() {
      if (this.elements.length === 0) {
        return [];
      }
      // make groups for parenthesis
      let object = this.replaceParenthesisByNestedArrays(this.elements);
      // make groups for modifiers
      object = this.replaceModifiersByNestedObjects(object);
      // transform all groups into objects
      object = this.transformIntoNestedObjects(object);

      if (
        object instanceof Array &&
        object.length === 1 &&
        typeof object[0] === 'object' &&
        !object[0][this._keyDict.function]
      ) {
        object = object[0];
      }

      return object;
    },

    replaceModifiersByNestedObjects(flat) {
      const nested = [];
      let modifierGroupLevel = 0;
      flat.forEach((element, index, array) => {
        if (element.type === 'modifiersPrefix') {
          modifierGroupLevel++;
          const nextElement = array[index + 1];
          nested.push({
            [this._keyDict.operator]: element.key,
            [this._keyDict.operand]:
              nextElement instanceof Array ? this.replaceModifiersByNestedObjects([nextElement]) : [nextElement],
          });
        } else if (element.type === 'modifiersSuffix') {
          const previousElement = nested.splice(nested.length, 1);
          nested.splice({
            [this._keyDict.operator]: element.key,
            [this._keyDict.operand]:
              previousElement instanceof Array
                ? this.replaceModifiersByNestedObjects([previousElement])
                : [previousElement],
          });
        } else if (modifierGroupLevel > 0) {
          modifierGroupLevel--;
        } else {
          if (element instanceof Array) {
            nested.push(this.replaceModifiersByNestedObjects(element));
          } else {
            nested.push(element);
          }
        }
      });
      return nested;
    },

    transformElementIntoNestedObject(element) {
      if (element.attrs) {
        if (element.named) {
          return {
            [this._keyDict.function]: element.key,
            [this._keyDict.attributes]: element.attributes,
          };
        } else {
          return {
            [this._keyDict.function]: element.key,
            [this._keyDict.anonymousAttributes]: element.anonymousAttributes,
          };
        }
      } else {
        return element.key;
      }
    },

    transformIntoNestedObjects(nested) {
      const conditions = [];
      nested.forEach((element) => {
        if (element instanceof Array) {
          const op = element.find((e) => e.type === 'operators');
          const list = element.filter((e) => e.type !== 'operators');
          conditions.push({
            [this._keyDict.operator]: op.key,
            [this._keyDict.operand]: list.map((g) =>
              g instanceof Array
                ? this.transformIntoNestedObjects(g)
                : g[this._keyDict.operator] && g[this._keyDict.operand]
                ? {...g, [this._keyDict.operand]: this.transformIntoNestedObjects(g[this._keyDict.operand])}
                : this.transformElementIntoNestedObject(g)
            ),
          });
        } else if (element[this._keyDict.operator]) {
          const op = element[this._keyDict.operator];
          const list = element[this._keyDict.operand].filter((e) => e.type !== 'operators');
          conditions.push({
            [this._keyDict.operator]: op,
            [this._keyDict.operand]: list.map((g) =>
              g instanceof Array ? this.transformIntoNestedObjects(g) : this.transformElementIntoNestedObject(g)
            ),
          });
        } else if (element.type !== 'operators') {
          conditions.push(this.transformElementIntoNestedObject(element));
        }
      });
      const operator =
        (nested.find((e) => e.type === 'operators') || {}).key ||
        (nested.find((e) => e.type === 'modifiersSuffix') || {}).key ||
        (nested.find((e) => e.type === 'modifiersPrefix') || {}).key;
      return operator
        ? {
            [this._keyDict.operator]: operator,
            [this._keyDict.operand]: conditions,
          }
        : conditions;
    },
  },
};
