<template>
  <div class="s-par-coords">
    <div :id="uniqID" style="width: 500px; height: 150px"></div>
    <s-button class="reset-filters" :on-click="resetFilters" text="Reset Filters" color="gray" size="small" />
  </div>
</template>

<script>
  import * as d3 from 'd3';
  import parCoords from 'parcoord-es';
  import {uniqId} from '@veasel/core/tools';
  import button from '@veasel/base/button';

  const DEFAULT_OPTIONS = {
    marginTop: 25,
    marginRight: 10,
    marginBottom: 30,
    marginLeft: 10,
    composite: 'darker',
    renderMode: 'queue',
    renderRate: 50,
    colors: ['red', 'orange', 'green'],
    alpha: 0.2,
    brush: true,
    brushMode: '1D-axes',
    reorder: true,
    dynamicColor: true,
  };

  export default {
    name: 's-par-coords',

    description:
      'A graph to display data as lines over many dimensions. Ideal to identify relations and tendancies in large dataset (based on d3.js, dc and parcoord-es).',

    components: {
      's-button': button,
    },

    emits: ['parcoords-selection'],
    props: {
      data: {
        description: 'The granular data set to display in the parallel coordinates chart.',
        type: Array,
      },
      dimensions: {
        description: 'The list of dimensions (vertical bars).',
        type: Array,
      },
      options: {
        description: 'The options object, to specify advanced features and table style.',
        type: Object,
        default: () => ({}),
      },
    },

    computed: {
      uniqID: () => uniqId('chart_').replace('.', ''),
      // options
      marginTop: function () {
        return this.getDefault('marginTop');
      },
      marginRight: function () {
        return this.getDefault('marginRight');
      },
      marginBottom: function () {
        return this.getDefault('marginBottom');
      },
      marginLeft: function () {
        return this.getDefault('marginLeft');
      },
      composite: function () {
        return this.getDefault('composite');
      },
      renderMode: function () {
        return this.getDefault('renderMode');
      },
      renderRate: function () {
        return this.getDefault('renderRate');
      },
      colors: function () {
        return this.getDefault('colors');
      },
      alpha: function () {
        return this.getDefault('alpha');
      },
      brush: function () {
        return this.getDefault('brush');
      },
      brushMode: function () {
        return this.getDefault('brushMode');
      },
      reorder: function () {
        return this.getDefault('reorder');
      },
      dynamicColor: function () {
        return this.getDefault('dynamicColor');
      },
      // chart size
      width: function () {
        return this.$el.clientWidth;
      },
      height: function () {
        return this.$el.clientHeight;
      },
      // dimensions
      computedDimensions: function () {
        const computed = {};
        for (const i in this.dimensions) {
          const key = typeof this.dimensions[i] === 'string' ? this.dimensions[i] : this.dimensions[i].key;
          computed[key] = {
            title:
              this.dimensions[i].title ||
              (this.dimensions[i].key ? this.dimensions[i].key.toUpperCase() : this.dimensions[i]),
            orient: this.dimensions[i].orient || (i == this.dimensions.length - 1 ? 'right' : 'left'),
            ticks: this.dimensions[i].tickPadding || 6,
            innerTickSize: this.dimensions[i].innerTickSize || 0,
            type: this.dimensions[i].type || typeof this.data[0][this.dimensions[i].key || this.dimensions[i]],
          };
        }
        return computed;
      },
    },
    watch: {
      data: function () {
        this.initParCoords();
      },
    },
    mounted: function () {
      this.initParCoords();
    },
    methods: {
      // get options from default and passed options
      getDefault: function (key) {
        return this.options[key] !== undefined ? this.options[key] : DEFAULT_OPTIONS[key];
      },
      // init, build, and draw the diagram
      initParCoords: function () {
        // emit the fully selected data
        this.$emit('parcoords-selection', this.data);

        // clean the canvas layout to avoid duplicated axis
        d3.select('#' + this.uniqID).html('');

        if (this.data.length) {
          // init the column of reference for the color on the first dimension
          this.referenceDimension = Object.keys(this.computedDimensions)[0];

          // create color domain for the column
          this.updateColorDomain();

          // build and draw the diagram
          this.parcoords = parCoords()('#' + this.uniqID)
            .data(this.data)
            .width(this.width)
            .height(this.height)
            .margin({top: this.marginTop, right: this.marginRight, bottom: this.marginBottom, left: this.marginLeft})
            .composite(this.composite)
            .shadows()
            .mode(this.renderMode)
            .rate(this.renderRate)
            // .detectDimensionTypes()
            .dimensions(this.computedDimensions)
            .alpha(this.alpha)
            .color(this.colorLines)
            .render()
            .interactive();

          // make the diagram filterable with brushes
          if (this.brush) {
            this.parcoords
              .brushable()
              .brushMode(this.brushMode) // enable brushing
              .on('brush', (d) => {
                this.emitSelection(d);
                if (this.data.length === d.length) {
                  this.parcoords.unhighlight(d);
                } else {
                  this.parcoords.highlight(d);
                }
              });
          }

          // allow reordering axis
          if (this.reorder) {
            this.parcoords.reorderable();
          }

          // allow the update of the column of reference for the color
          if (this.dynamicColor) {
            d3.selectAll('.axis .label').on('click', (d) => {
              this.updateColor(d);
            });
          }

          // add ids to axis and make the titles visible
          for (const i in Object.keys(this.computedDimensions)) {
            d3.selectAll('.dimension:nth-child(' + (parseInt(i) + 1) + ') .label')
              .attr('id', Object.keys(this.computedDimensions)[i])
              .attr('fill', 'currentColor')
              .attr('y', '-6');
          }

          // highlight the column of reference
          this.updateAxisTitle();
        }
      },
      // set the color for lines
      colorLines: function (d) {
        return this.colorGradient(d[this.referenceDimension]);
      },
      // update the column of reference and redraw colors
      updateColor: function (dimensionKey) {
        this.referenceDimension = dimensionKey;
        this.updateColorDomain();
        this.parcoords.color(this.colorLines).render();
        this.updateAxisTitle();
      },
      // update color domain according of the axis of the column of reference
      updateColorDomain: function () {
        if (typeof this.data[0][this.referenceDimension] === 'string') {
          this.domain = [...new Set(this.data.map((d) => d[this.referenceDimension]))].sort();
          this.enumDomain = this.domain.slice(0);
          const min = d3.min(this.domain, (_d, i) => i);
          const max = d3.max(this.domain, (_d, i) => i);
          this.buildDomain(min, max);
          const enumColorGradient = d3
            .scaleLinear()
            .domain(this.domain)
            .range(this.colors)
            .interpolate(d3.interpolateLab);
          this.colorGradient = (d) => enumColorGradient(this.enumDomain.indexOf(d));
        } else {
          const min = d3.min(this.data, (d) => d[this.referenceDimension]);
          const max = d3.max(this.data, (d) => d[this.referenceDimension]);
          this.buildDomain(min, max);
          this.colorGradient = d3.scaleLinear().domain(this.domain).range(this.colors).interpolate(d3.interpolateLab);
        }
      },
      // build a domain according to the number of colors
      buildDomain: function (min, max) {
        this.domain = [min, max];
        const additionalSteps = this.colors.length - 1;
        const step = (max - min) / Math.max(1, additionalSteps);
        for (let i = 1; i <= this.colors.length - 2; i++) {
          this.domain.splice(i, 0, min + i * step);
        }
      },
      // highlight axis titles
      updateAxisTitle: function () {
        d3.select('.color-selected').attr('class', 'label');
        d3.select('.label#' + this.referenceDimension).attr('class', 'label color-selected');
      },
      // emit selection after brush filters applied
      emitSelection: function (selection) {
        this.$emit('parcoords-selection', selection);
      },
      // highlight a given row
      highlightRow: function (row) {
        if (this.data.indexOf(row) !== -1) {
          this.parcoords.highlight([row]);
        }
      },
      // reset all brush filters
      resetFilters: function () {
        this.parcoords.data(this.data).brushable().render().updateAxes();
      },
    },
    beforeUnmount() {
      if (this.parcoords) {
        d3.select('#' + this.uniqID)
          .selectAll('div')
          .data(this.data)
          .exit()
          .remove();
      }
    },
  };
</script>

<style lang="scss">
  @import 'parcoord-es/dist/parcoords.css';

  .s-par-coords {
    position: relative;
    height: 100%;
    width: 100%;

    .reset-filters {
      position: absolute;
      right: 0;
      bottom: 0;
    }

    & > div > canvas {
      position: absolute;
    }

    & > canvas {
      pointer-events: none;
    }

    .axis line,
    .axis path {
      fill: none;
      stroke: #222;
      shape-rendering: crispEdges;
    }

    .axis .tick {
      user-select: none;

      text {
        font-size: 12px;
        font-weight: bold;
      }
    }

    canvas {
      opacity: 1;
      -moz-transition: opacity 0.3s;
      -webkit-transition: opacity 0.3s;
      -o-transition: opacity 0.3s;
    }

    canvas.faded {
      opacity: 0.25;
    }

    .label {
      user-select: none;
      font-size: 14px;
      color: black;

      &.color-selected {
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
</style>
