let lottie;
import * as lottieWeb from 'lottie-web';

// Mock lottie-web when run in jest environment as jest virtual DOM does not support canvas mocking
if (import.meta.env.JEST_WORKER_ID !== undefined) {
  lottie = {
    loadAnimation: function () {
      return {};
    },
  };
} else {
  lottie = lottieWeb;
}

export default lottie;
