// create sync 'value' prop and '$_value' computed
const syncValue = function (propTypes = null) {
  return {
    // value type can be defined in component
    props: {
      modelValue: {
        description: 'The input value',
        type: propTypes,
      },
    },
    emits: ['update:modelValue'],
    data: function () {
      // Functions called to format value (truncate, trim...)
      return {
        $_formatersValue: [],
        $_unformattersValue: [],
      };
    },
    computed: {
      $_value: {
        get() {
          return this.$_formatValue(this.modelValue);
        },
        set(val) {
          this.$emit('update:modelValue', this.$_unformatValue(val));
        },
      },
      $_hasNoValue: function () {
        return (
          this.$_value === undefined ||
          this.$_value === null ||
          this.$_value === '' ||
          (Array.isArray(this.$_value) && this.$_value.length === 0)
        );
      },
    },
    watch: {
      $_value: {
        immediate: true,
        deep: true,
        handler: function (newVal) {
          // if there is isRequired mixin
          if (this.required !== undefined && typeof this.$_requireValidate === 'function') {
            this.$_requireValidate(newVal);
          }
          // if there is pattern mixin
          if (this.pattern !== undefined) {
            this.$_patternValidate(newVal);
          }
        },
      },
    },
    methods: {
      $_formatValue: function (val) {
        let formatterVal = val;

        for (let i = 0; i < this.$data.$_formatersValue.length; i++) {
          const formatter = this.$data.$_formatersValue[i];
          formatterVal = formatter(formatterVal);
        }

        return formatterVal;
      },
      $_unformatValue: function (val) {
        let unformatedVal = val;
        for (let i = 0; i < this.$data.$_unformattersValue.length; i++) {
          const unformater = this.$data.$_unformattersValue[i];
          unformatedVal = unformater(unformatedVal);
        }

        return unformatedVal;
      },
    },
  };
};

export default syncValue;
