<template>
  <div
    class="agg-group"
    :style="{'background-color': generateColor(x, !isExtended && query.groups.length !== x + 1)}"
    :agg-index="x + '_' + y"
    @mousedown="groupSelected"
    @dblclick="groupClicked"
  >
    <div
      class="group-cell"
      :class="{root: x === 0, 'is-last': query.groups.length === x + 1}"
      :style="{width: colWidth + 'px'}"
    >
      <div class="expand-icon" :class="{'is-extended': isExtended}" @click="groupClicked">
        <s-icon v-if="isExtended" type="minus-square" size="14"></s-icon>
        <s-icon v-else type="plus-square" size="14"></s-icon>
      </div>
      <div class="group-title" :style="{width: colWidth - 30 + 'px'}">{{ data.value }}</div>
    </div>
    <div v-if="isExtended && data.groups" class="  ">
      <s-agg-group
        v-for="(group, index) in data.groups"
        :key="index"
        :ref="setAggGroupRefs"
        :data="group"
        :x="x + 1"
        :y="y + '-' + index"
        :query="query"
        :visible-aggregates="visibleAggregates"
        :col-width="colWidth"
        :agg-outer-width="aggOuterWidth"
        :agg-inner-width="aggInnerWidth"
        :options="options"
        :parentGroupCurrency="groupCurrency"
        :current-agg-scroll="currentAggScroll"
        @group-selected="groupSelected"
        @agg-selected="aggSelected"
        @drill-down="drillDown"
        @collapsed="collapsed"
      ></s-agg-group>
    </div>
    <div v-else>
      <div
        v-for="index in remainingCols"
        :key="index"
        class="filler-cell"
        :empty-col-index="x + index"
        :style="{width: colWidth + 'px', 'background-color': options.disabledColor}"
      ></div>
      <s-agg-result
        :result="data"
        :x="x + 1"
        :y="'' + y"
        :query="query"
        :visible-aggregates="visibleAggregates"
        :outer-width="aggOuterWidth"
        :inner-width="aggInnerWidth"
        :options="options"
        :color="$parent.$data.isExtended ? generateAggregationColor(x) : generateAggregationColor(0)"
        :countColor="$parent.$data.isExtended ? generateCountColor(x) : generateCountColor(0)"
        :custom-color="$parent.$data.isExtended ? generateCustomColor(x) : generateCustomColor(0)"
        :parentGroupCurrency="groupCurrency"
        :current-agg-scroll="currentAggScroll"
        @agg-selected="aggSelected"
        @drill-down="drillDown"
      ></s-agg-result>
    </div>
  </div>
</template>

<script>
  // dependencies
  import {closestOrSelf} from '@veasel/core/tools';
  import icon from '@veasel/base/icon';
  import aggResult from './aggResult.vue';

  export default {
    name: 's-agg-group',

    emits: ['group-selected', 'collapsed', 'agg-selected', 'drill-down'],

    components: {
      's-agg-result': aggResult,
      's-icon': icon,
    },

    props: {
      data: {type: Object, default: () => ({})},
      rawData: {type: Object, default: () => ({})},
      query: {type: Object, default: () => ({})},
      options: {type: Object, default: () => ({})},
      colWidth: {type: Number, default: 200},
      aggOuterWidth: {type: Number, default: 160},
      aggInnerWidth: {type: Number, default: 160},
      x: {type: Number, default: 0},
      y: {type: String, default: '0'},
      currentAggScroll: {type: Number, default: 0},
      visibleAggregates: {type: Array, default: () => []},
      parentGroupCurrency: {type: String, default: ''},
    },

    data: function () {
      return {
        isExtended: false,
        remainingColumns: [],
        display: true,
        scrollLeft: 0,
        aggGroupRefs: [],
      };
    },
    beforeUpdate() {
      // Flush out old references
      this.aggGroupRefs = [];
    },
    computed: {
      remainingCols: function () {
        return this.query.groups.length - 1 - this.x;
      },

      groupCurrency() {
        if (this.data.property === 'currency_code') {
          return this.data.value;
        } else if (this.parentGroupCurrency) {
          return this.parentGroupCurrency;
        } else {
          return undefined;
        }
      },
    },

    methods: {
      setAggGroupRefs(el) {
        this.aggGroupRefs.push(el);
      },

      findCurrencyDeepDown(data) {
        if (data.property === 'currency_code') {
          return data.value;
        } else {
          this.findCurrencyDeepDown();
        }
      },

      // expand / collapse a group
      groupClicked: function (event) {
        event.stopPropagation();
        if (this.query.groups.length === this.x + 1) {
          return;
        }
        this.isExtended = !this.isExtended;
        this.collapsed(this.x, this.isExtended);
        const index = closestOrSelf(event.target, '[agg-index]').getAttribute('agg-index');
        this.$emit('group-selected', index);
      },

      // expand all handler
      expandAll: function (colIndex, uuid) {
        if (Array.isArray(this.aggGroupRefs)) {
          this.aggGroupRefs.forEach((aggGroup) => {
            if (aggGroup !== null) {
              aggGroup.expandAll(colIndex, uuid);
            }
          });
        } else if (typeof this.aggGroupRefs !== 'undefined') {
          this.aggGroupRefs.expandAll(colIndex, uuid);
        }
        if (this.options.uuid == uuid && this.x <= colIndex) {
          this.isExtended = true;
        }
      },

      // collapse all handler
      collapseAll: function (colIndex, uuid) {
        if (Array.isArray(this.aggGroupRefs)) {
          this.aggGroupRefs.forEach((aggGroup) => {
            if (aggGroup !== null) {
              aggGroup.collapseAll(colIndex, uuid);
            }
          });
        } else if (typeof this.aggGroupRefs !== 'undefined') {
          this.aggGroupRefs.collapseAll(colIndex, uuid);
        }
        if (this.options.uuid == uuid && this.x == colIndex) {
          this.isExtended = false;
        }
      },

      // propagate that a group has been expanded/collapsed
      collapsed: function (x, flag) {
        this.$emit('collapsed', x, flag);
      },

      // color generation for groups cells
      generateColor: function (colIndex, isGray) {
        const baseColor = isGray ? this.options.baseGrayColor : this.options.baseBlueColor;
        const maxValue = 25;
        const changeFactor = (colIndex / this.query.groups.length) * maxValue;
        return (
          'hsl(var(' +
          baseColor +
          '-hue),var(' +
          baseColor +
          '-saturation),calc(var(' +
          baseColor +
          '-lightness) - ' +
          changeFactor +
          '%))'
        );
      },

      // color generation for aggregation cells
      generateAggregationColor: function (colIndex) {
        const baseColor = this.options.aggregationColumnColor;
        const maxValue = 25;
        const changeFactor = (colIndex / this.query.groups.length) * maxValue;
        const results =
          'hsl(var(' +
          baseColor +
          '-hue),var(' +
          baseColor +
          '-saturation),calc(var(' +
          baseColor +
          '-lightness) - ' +
          changeFactor +
          '%))';
        return results;
      },

      // color generation for count aggregation cells
      generateCountColor: function (colIndex) {
        const baseColor = this.options.countColumnColor;
        const maxValue = 25;
        const changeFactor = (colIndex / this.query.groups.length) * maxValue;
        return (
          'hsl(var(' +
          baseColor +
          '-hue),var(' +
          baseColor +
          '-saturation),calc(var(' +
          baseColor +
          '-lightness) - ' +
          changeFactor +
          '%))'
        );
      },

      // color generation for custom aggregation cells
      generateCustomColor: function (colIndex) {
        const baseColor = this.options.customColumnColor;
        const maxValue = 25;
        const changeFactor = (colIndex / this.query.groups.length) * maxValue;
        return (
          'hsl(var(' +
          baseColor +
          '-hue),var(' +
          baseColor +
          '-saturation),calc(var(' +
          baseColor +
          '-lightness) - ' +
          changeFactor +
          '%))'
        );
      },

      // propagate a group selection
      groupSelected: function (event) {
        let index;

        if (typeof event === 'string') {
          index = event;
        } else {
          event.stopPropagation();
          index = closestOrSelf(event.target, '[agg-index]').getAttribute('agg-index');
        }
        this.$emit('group-selected', index);
      },

      // propagate a aggregation selection
      aggSelected: function (event) {
        let index;

        if (typeof event === 'string') {
          index = event;
        } else {
          event.stopPropagation();
          index = closestOrSelf(event.target, '[agg-index]').getAttribute('agg-index');
        }
        this.$emit('agg-selected', index);
      },

      // propagate a drill down action
      drillDown: function (event) {
        let index;

        if (typeof event === 'string') {
          index = event;
        } else {
          event.stopPropagation();
          index = closestOrSelf(event.target, '[agg-index]').getAttribute('agg-index');
        }
        this.$emit('drill-down', index);
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@veasel/core/sass/conf';

  .agg-group {
    display: table-row;
    width: 100%;
    box-sizing: content-box;
    vertical-align: top;

    &.root {
      display: block;
    }
  }

  .filler-cell {
    display: table-cell;
    vertical-align: top;
    min-height: 20px;
    float: left;
    box-sizing: border-box;
    border-left: 1px solid $black;
    border-bottom: 1px solid $black;
  }

  .group-cell {
    position: relative;
    vertical-align: top;
    display: table-cell;
    height: 100%;
    min-height: 20px;
    box-sizing: border-box;
    border-left: 1px solid $black;
    border-bottom: 1px solid $black;

    &.is-last {
      height: 20px;

      .expand-icon {
        display: none;
      }

      .group-title {
        padding-left: 10px;
      }
    }

    .expand-icon {
      position: absolute;
      margin-top: 1px;
      margin-left: 5px;
      cursor: pointer;
    }

    .group-title {
      width: inherit;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 25px;
    }
  }

  ::v-deep(.agg-result) {
    float: right;
    right: 0;
  }
</style>
