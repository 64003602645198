import {clone, setStorage, getStorage, extend, deepExtend, isDeepEqual, closestOrSelf} from '@veasel/core/tools';

const DEFAULT_COL_OPTIONS = {
  data: undefined, // [required] String (key used to retrieve the cell data)
  title: undefined, // [required] String (column name to display in header)
  formatter: 'text', // String | Function (value formating)
  renderer: false, // Boolean | Function (style rendering of the cell)
  width: false, // Boolean | Number (default width of the column)
  index: 0, // Number (column ordering index)
  visible: true, // Boolean (default visibility)
  sortable: true, // Boolean (allow sorting on this column)
  resizable: true, // Boolean (allow resizing the column)
  searchable: true, // Boolean (allow searching by this column)
  editable: true, // Boolean | Object | Function (allow editing cells in this column, can pass an object or a function returning an object like `{type:'input-type', options:{}}`)
  sticky: false, // Boolean | 'left' | 'right' (force this column to float left or right) --TODO
};

export default {
  data: function () {
    return {
      cols: [],
      draggedColumn: null,
      canReset: true,
    };
  },

  computed: {
    // columns after ordering
    orderedCols: function () {
      return this.cols.map((c) => extend(c, this.settings.columns[c.data])).sortBy('index');
    },
    // columns after visibility
    visibleCols: function () {
      return this.cols
        .map((c) => extend(c, this.settings.columns[c.data]))
        .filter((c) => c.visible)
        .sortBy('index');
    },
    // disable flag for showAllColumns
    hasAllColumnsVisible: function () {
      return this.cols.length > 0 && this.cols.length == this.visibleCols.length;
    },
    // disable flag for hideAllColumns
    hasAllColumnsHidden: function () {
      return this.cols.length > 0 && this.visibleCols.length == 0;
    },
    // columns specified
    hasColumns: function () {
      return !!this.visibleCols.length;
    },
    // rows specified
    hasRows: function () {
      return !!this.visibleRows.length;
    },
    // settings synchronization in local storage
    storedSettings: {
      get: function () {
        // get stored columns from local storage
        return getStorage('datatable-settings-' + this.opts.settings.storeSettings) || {};
      },
      set: function (settings) {
        // set stored columns in local storage
        if (this.opts.settings.storeSettings) {
          setStorage('datatable-settings-' + this.opts.settings.storeSettings, settings);
        }
      },
    },
    // Combine stored settings with options pagination/scroller settings and API column data (this.cols)
    settings: function () {
      const settings = clone(this.storedSettings);
      const paginationSize = getStorage('datatable-pagination-limit-' + this.opts.settings.storeSettings);
      settings.pageSize = this.opts.pagination.limit =
        this.opts.pagination.limit || paginationSize || this.opts.settings.pageSize[0];
      settings.columns = settings.columns || {};
      for (const i in this.cols) {
        settings.columns[this.cols[i].data] = this.cols[i];
      }
      if (this.opts.stickyScroller) {
        this.$nextTick(() => {
          this.hideScroller();
        });
      }
      return settings;
    },
  },

  watch: {
    columns: {
      handler: function (cols, oldCols) {
        if (!isDeepEqual(cols, oldCols)) {
          this.initColumns();
        }
      },
      deep: true,
    },
    // Update stored settings when cols are reordered/hidden
    cols: {
      deep: true,
      handler: function () {
        this.storedSettings = this.settings;
      },
    },
  },

  methods: {
    // initialize columns when displaying the table for the first time
    initColumns: function () {
      this.cols = this.columns
        .map((c, i) => extend(DEFAULT_COL_OPTIONS, {index: i}, this.settings.columns[c.data], c))
        .map(this.initRenderer)
        .map((c) => ({...c, sortable: c.data.indexOf('__') === 0 ? false : c.sortable}))
        .sortBy('index');
    },
    initRenderer: function (col) {
      const builtInFormat = typeof col.formatter === 'string' ? col.formatter : false;
      col.formating = builtInFormat ? this.renderers[builtInFormat].value : col.formatter;
      col.rendering = builtInFormat && !col.renderer ? this.renderers[builtInFormat].style : col.renderer;
      return col;
    },
    // show all columns
    displayAllColumns: function () {
      this.canReset = true;
      this.cols['columns'] = this.cols.map((c) => {
        c.visible = true;
        return c;
      });
    },
    // hide all columns
    hideAllColumns: function () {
      this.canReset = true;
      this.cols['columns'] = this.cols.map((c) => {
        c.visible = false;
        return c;
      });
    },
    // a visibility checkbox has been clicked
    updateColumnVisibility: function (col) {
      this.canReset = true;
      this.cols['columns'] = this.cols.map((c) => {
        if (c.data === col.data) {
          c.visible = col.visible;
        }
        return c;
      });
    },
    // a column is being dragged
    dragColumns: function (e) {
      this.canReset = true;
      if (!this.draggedColumn) {
        this.draggedColumn = e.srcElement;
      }
    },
    // a column has been reorder
    dropColumns: function (e) {
      this.canReset = true;
      const to = parseInt(closestOrSelf(e.target, '[id]').id);
      const from = parseInt(this.draggedColumn.id);
      this.cols.splice(to, 0, this.cols.splice(from, 1)[0]);
      let i = 0;
      this.cols = this.cols.map((c) => deepExtend(c, {index: i++}));
      this.cols['columns'] = clone(this.cols);
      this.draggedColumn = null;
    },
    // reset the saved settings for columns and page size to default
    resetColumns: function () {
      this.canReset = false;
      const innerScroller = document.querySelector('.scroller-inner');
      if (innerScroller) {
        innerScroller.style.width = 'auto';
      }
      this.cols = this.columns
        .sortBy('index')
        .map((c) => extend(DEFAULT_COL_OPTIONS, c))
        .map(this.initRenderer)
        .map((c) => {
          const th = document.querySelector(`th[col-name="${c.data}"]`);
          if (th) {
            th.width = c.width || th.offsetWidth;
          }
          return c;
        });
      this.storedSettings = {};
      this.initColumns();
    },
  },
};
