<template>
  <s-input-wrapper v-bind="$props" :class="{'input-error': isError}" :fakeInputHelperMessage="warnings">
    <div :id="id || $_name" class="s-operations relative s-input-group-field">
      <div class="elements-clear-all" :class="{disabled: disabled}">
        <div class="elements-container">
          <span
            class="add-element action"
            :class="{visible: isAddVisible}"
            :style="{order: addIndex}"
            @click="addElem(addIndex - 1)"
          >
            <s-icon type="plus" />
          </span>

          <template v-for="(element, index) in elements" :key="element.id">
            <template v-if="!(isEditing && isReplacing && !isSelectingAttributes && editingIndex === index)">
              <span
                :id="'element-' + index"
                class="element"
                :class="[element.type, {last: index === elements.length - 1, 'is-editing': isEditing}]"
                :data-index="index"
                :style="{order: index}"
                :title="!isEmpty(element.tooltip) ? element.tooltip : ''"
                @mouseenter="hoverElem"
                @mouseleave="unhoverElem"
              >
                <span v-if="!element.attrs" class="content" @click="editElem(index)">{{ element.label }}</span>

                <template v-else-if="element.attrs">
                  <span class="content" @click="editElem(index)">{{ element.label }}</span>

                  <template v-if="element.named">
                    <span v-if="Object.keys(element.attributes).length">{{ getSyntax(element, 'start') }}</span>
                    <template v-for="(key, i) in Object.keys(element.attributes)" :key="key">
                      <span
                        class="attribute"
                        :class="getAttrClassByKey(element, key)"
                        :style="getAttrStyleByKey(element, key)"
                      >
                        <span
                          v-if="!(editingIndex === index && isEditingAttributes && attrKey === key)"
                          @click="editAttrByKey(index, key)"
                        >
                          {{ getAttrName(element, key) }}{{ getSyntax(element, 'assign') }}
                        </span>
                        <s-input
                          v-else
                          :id="id || $_name"
                          ref="inputValueSelect"
                          v-model="selectedValue"
                          class="inline"
                          :props="valueInputProps"
                          :on-change="() => valueSelected('change')"
                          :on-blur="() => valueSelected('blur')"
                          size="small"
                          @enter="() => valueSelected('enter')"
                        />
                        <span @click="editAttrByKey(index, key)">
                          {{ getAttrValueByKey(element, key, element.attributes[key]) }}
                        </span>
                      </span>
                      <span v-if="i === Object.keys(element.attributes).length - 1">
                        {{ getSyntax(element, 'end') }}
                      </span>
                      <span v-else>{{ getSyntax(element, 'separator') }}</span>
                    </template>
                  </template>

                  <template v-else>
                    <span v-if="hasAnonymousAttributes(element)">{{ getSyntax(element, 'start') }}</span>
                    <template v-for="(value, i) in element.anonymousAttributes" :key="i">
                      <span
                        v-if="!(editingIndex === index && isEditingAttributes && anonymousAttrIndex === i)"
                        class="attribute"
                        :class="getAttrClassByIndex(element, i)"
                        :style="getAttrStyleByIndex(element, i)"
                        @click="editAttrByIndex(index, i)"
                      >
                        {{ getAttrValueByIndex(element, i, value) }}
                      </span>
                      <s-input
                        v-else
                        :id="id || $_name"
                        ref="inputValueSelect"
                        v-model="selectedValue"
                        class="inline"
                        :props="valueInputProps"
                        :on-change="() => valueSelected('change')"
                        :on-blur="() => valueSelected('blur')"
                        :disabled="disabled"
                        size="small"
                        @enter="() => valueSelected('enter')"
                      />
                      <span v-if="i === element.anonymousAttributes.length - 1">{{ getSyntax(element, 'end') }}</span>
                      <span v-else>{{ getSyntax(element, 'separator') }}</span>
                    </template>
                  </template>
                </template>

                <span
                  class="hoverable-part left-part"
                  @mouseenter="
                    isAddVisible = true;
                    addIndex = index;
                  "
                  @mouseleave="isAddVisible = false"
                ></span>
                <span
                  v-if="index < elements.length - 1"
                  class="hoverable-part right-part"
                  @mouseenter="
                    isAddVisible = true;
                    addIndex = index + 1;
                  "
                  @mouseleave="isAddVisible = false"
                ></span>
                <span class="remove-element action" @click.prevent="removeElem(index)">
                  <s-icon type="cross" size="10" color="theme-background-main" />
                </span>
              </span>
            </template>
          </template>

          <div
            v-if="!isSelectingAttributes"
            class="element-input"
            :style="{order: isEditing ? editingIndex : elements.length}"
          >
            <s-input-select
              :id="id || $_name"
              ref="inputSelect"
              v-model="selectedOption"
              class="input-select"
              :options="smartOptions"
              keepOpenOnSelect
              :sort="false"
              :on-change="optionSelected"
              :on-blur="finishSelection"
              :disabled="disabled"
              :style="{order: isEditing ? editingIndex : elements.length}"
            />
          </div>
          <div v-else-if="!attrKey" class="element-input" :style="{order: isEditing ? editingIndex : elements.length}">
            <s-input-select
              :id="id || $_name"
              ref="inputAttributeSelect"
              v-model="selectedAttribute"
              :options="attributesOptions"
              keepOpenOnSelect
              class="input-select"
              :sort="false"
              :on-change="attributeSelected"
              :disabled="disabled"
              :style="{order: isEditing ? editingIndex : elements.length}"
            />
          </div>
          <div
            v-else-if="!isEditingAttributes"
            class="element-input"
            :style="{order: isEditing ? editingIndex : elements.length}"
          >
            <s-input
              :id="id || $_name"
              ref="inputValueSelect"
              v-model="selectedValue"
              :props="valueInputProps"
              :on-change="() => valueSelected('change')"
              :style="{order: isEditing ? editingIndex : elements.length}"
              :disabled="disabled"
              :on-blur="() => valueSelected('blur')"
              @enter="() => valueSelected('enter')"
            />
          </div>
        </div>

        <div v-show="elements.length > 0" class="clear-search-button" role="button">
          <s-icon type="cross" :on-click="clearAll" />
        </div>
      </div>
    </div>
  </s-input-wrapper>
</template>

<script>
  import {isEmpty, uniqId} from '@veasel/core/tools';
  import {
    id,
    name,
    syncValue,
    pattern,
    label,
    size,
    onChange,
    isRequired,
    isDisabled,
    hasErrors,
    helperMessages,
  } from '@veasel/core/mixins';
  import icon from '@veasel/base/icon';
  import input from '@veasel/inputs/input';
  import inputSelect from '@veasel/inputs/input-select';
  import inputWrapper from '@veasel/inputs/input-wrapper';
  import operationEditing from '../utils/operationEditing';
  import operationParsing from '../utils/operationParsing';
  import operationValidation from '../utils/operationValidation';
  import parenthesisHighlighting from '../utils/parenthesisHighlighting';
  import precedenceHandler from '../utils/precedenceHandler';

  const SYNTAX_DICT = {
    operand: 'conditions',
    operator: 'operator',
    attributes: 'keywords',
    anonymousAttributes: 'args',
    function: 'filter',
    functionSyntax: {
      start: ' ( ',
      separator: ', ',
      assign: ': ',
      end: ' ) ',
      arrayStart: '[',
      arrayEnd: ']',
      arraySeparator: ',',
    },
    functionDescription: 'description',
  };

  export default {
    name: 's-operations',

    description: 'A complex search bar handling multiple options with logical operators.',

    components: {
      's-icon': icon,
      's-input': input,
      's-input-select': inputSelect,
      's-input-wrapper': inputWrapper,
    },
    emits: ['label-on-top'],
    mixins: [
      id,
      name,
      size,
      syncValue([Object, String, Number]),
      onChange(),
      isRequired,
      isDisabled,
      operationEditing,
      operationParsing,
      operationValidation,
      parenthesisHighlighting,
      precedenceHandler,
      hasErrors,
      helperMessages,
      pattern,
      label,
    ],
    props: {
      options: {
        description: 'The array containing the list of options.',
        type: Array,
      },
      operators: {
        description: 'The array containing the list of operators.',
        type: Array,
        default: () => [
          {key: 'and', label: 'AND', precedence: 2},
          {key: 'or', label: 'OR', precedence: 1},
        ],
      },
      modifiersPrefix: {
        description: 'The array containing the list of prefixes modifiers.',
        type: Array,
        default: () => [{key: 'not', label: 'NOT', precedence: 3}],
      },
      modifiersSuffix: {
        description: 'The array containing the list of suffixes modifiers.',
        type: Array,
        default: () => [],
      },
      groupStart: {
        description: 'The array containing the list of group starters.',
        type: Array,
        default: () => [{key: 'group_start', label: '(', precedence: 9}],
      },
      groupEnd: {
        description: 'The array containing the list of group endings.',
        type: Array,
        default: () => [
          {
            key: 'group_end',
            label: ')',
            precedence: 9,
            hide: (elements, i) => {
              const groupStarted = elements.slice(0, i).filter((e) => e.type === 'groupStart').length;
              const groupClosed = elements.slice(0, i).filter((e) => e.type === 'groupEnd').length;
              const hasAnOpenParenthesisTooClose = elements.slice(i - 3, i).some((e) => e.type === 'groupStart');
              return groupStarted === 0 || groupStarted <= groupClosed || hasAnOpenParenthesisTooClose;
            },
          },
        ],
      },
      parser: {
        description: 'The parser if you want to retrieve data in a specific way.',
        type: [String, Function],
        default: 'nested',
        validator: (value) => typeof value === 'function' || ['text', 'raw', 'flat', 'nested'].includes(value),
      },
      unparser: {
        description: 'The unparser if you want to inject data in a specific way.',
        type: [String, Function],
        default: 'nested',
        validator: (value) => typeof value === 'function' || ['text', 'raw', 'flat', 'nested'].includes(value),
      },
      unparserPostProcessor: {
        description: 'After the unparser function has run, how should the results be processed by the component.',
        type: [String, Boolean],
        default: false,
        validator: (value) =>
          (typeof value === 'boolean' && value === false) || ['text', 'raw', 'flat', 'nested'].includes(value),
      },
      keyDict: {
        description: 'The dictionary to override the final object keys.',
        type: Object,
        default: () => SYNTAX_DICT,
      },
      tooltipPosition: {
        description: 'The position of the helper tooltip.',
        type: String,
        default: 'top',
        validator: (value) => [undefined, 'top', 'bottom', 'left', 'right'].includes(value),
      },
      labelMod: {
        description: 'A flag to force the label to be outlined, overwrites sLabel default labelMod',
        type: String,
        default: 'top',
      },
    },

    data: function () {
      return {
        // Contains each query element. Each element is made up of a number of steps, and has a corresponding sl-element instance
        elements: [],
        // Unique IDs are needed for each element to update the elements in the for loop  \
        uid: 0,
        labelOnTop: false,

        elementTypes: {
          start: {following: ['options', 'modifiersPrefix', 'groupStart']},
          options: {following: ['operators', 'modifiersSuffix', 'groupEnd'], canBeLast: true, canBeFirst: true},
          operators: {following: ['options', 'modifiersPrefix', 'groupStart']},
          modifiersPrefix: {following: ['options', 'groupStart'], canBeFirst: true},
          modifiersSuffix: {following: ['operators', 'groupEnd'], canBeLast: true},
          groupStart: {following: ['options', 'modifiersPrefix', 'groupStart'], canBeFirst: true},
          groupEnd: {following: ['operators', 'modifiersSuffix', 'groupEnd'], canBeLast: true},
        },

        listOfOptionsTypes: ['options', 'operators', 'modifiersPrefix', 'modifiersSuffix', 'groupStart', 'groupEnd'],
        currentType: 'start',
        selectedOption: undefined,
        warnings: {},
        defaultID: uniqId(),
      };
    },

    computed: {
      _keyDict() {
        return {...SYNTAX_DICT, ...this.keyDict};
      },

      smartOptions() {
        if (this.elementTypes[this.currentType]) {
          return this.elementTypes[this.currentType].following
            .reduce((acc, key) => [...this[key].map((o) => ({...o, type: key})), ...acc], [])
            .filter((o) => !o.hide || !o.hide(this.elements, this.elements.length));
        } else {
          return [];
        }
      },

      attributesOptions() {
        const attrs = this.elements[this.elemIndex].attrs
          ? this.elements[this.elemIndex].attrs.concat([{label: 'Done!', key: 'done'}])
          : [];
        return attrs.filter((attr) => {
          return !this.currentAttributes[attr.key];
        });
      },

      currentAttributes() {
        return this.elements[this.elemIndex].attributes;
      },

      currentAttr() {
        return this.elements[this.elemIndex].attrs.find((attr) => attr.key === this.attrKey) || {};
      },

      valueInputProps() {
        const props =
          this.elements[this.elemIndex].attrs && this.attrKey
            ? this.elements[this.elemIndex].attrs.find((attr) => attr.key === this.attrKey)
            : {};
        return {
          size: 'small',
          ...props,
          label: undefined,
        };
      },

      valuesOptions() {
        return this.elements[this.elemIndex].attrs
          ? this.elements[this.elemIndex].attrs.find((attr) => attr.key === this.attrKey).options || []
          : [];
      },

      functionDescription() {
        const isVisible = !!(
          Object.keys(this.warnings).length &&
          this.elements[this.elemIndex] &&
          this.elements[this.elemIndex].attrs &&
          this.elements[this.elemIndex][this._keyDict.functionDescription] &&
          (this.isSelectingAttributes || this.isEditingAttributes)
        );
        return isVisible
          ? {
              inputSelector: '#' + (this.id || this.defaultID),
              forceVisibilty: isVisible,
              position: this.tooltipPosition,
              isFocused: true,
              rules: {
                desc: {info: true, message: this.elements[this.elemIndex][this._keyDict.functionDescription]},
              },
            }
          : false;
      },

      valueTagging() {
        return this.valuesOptions.length === 0;
      },

      allOptions() {
        return this.listOfOptionsTypes.reduce((acc, key) => [...this[key].map((o) => ({...o, type: key})), ...acc], []);
      },

      groupStartObject() {
        return {...this.groupStart[0], type: 'groupStart'};
      },

      groupEndObject() {
        return {...this.groupEnd[0], type: 'groupEnd'};
      },

      elemIndex() {
        return this.isEditing ? this.editingIndex : this.elements.length - 1;
      },
    },

    methods: {
      getSyntax(element, key) {
        return typeof (element.syntax && element.syntax[key]) !== 'undefined'
          ? element.syntax[key]
          : this._keyDict.functionSyntax[key];
      },

      getAttrName(element, key) {
        return element.attrs.find((elem) => elem.key === key).label;
      },

      parseArrayValue(element, options, value) {
        return (
          this.getSyntax(element, 'arrayStart') +
          value
            .map((v) => (options ? (options.find((opt) => opt.key === v.key) || {label: v}).label : v))
            .join(this.getSyntax(element, 'arraySeparator'))
            .replace(/"/g, '') +
          this.getSyntax(element, 'arrayEnd')
        );
      },

      getAttrValueByKey(element, key, value) {
        const options = element.attrs.find((elem) => elem.key === key).options;
        if (value instanceof Array) {
          return this.parseArrayValue(element, options, value);
        } else {
          return options ? (options.find((opt) => opt.key === value) || {}).label : value;
        }
      },

      getAttrValueByIndex(element, index, value) {
        const options = element.attrs[index]?.options;
        if (value instanceof Array) {
          return this.parseArrayValue(element, options, value);
        } else {
          return options ? (options.find((opt) => opt.key === value) || {}).label : value;
        }
      },

      getAttrClassByKey(element, key) {
        return element.attrs.find((elem) => elem.key === key).class || '';
      },

      getAttrStyleByKey(element, key) {
        return element.attrs.find((elem) => elem.key === key).style || {};
      },

      getAttrClassByIndex(element, index) {
        if (isEmpty(element.attrs[index])) {
          return '';
        }
        return element.attrs[index].class || '';
      },

      getAttrStyleByIndex(element, index) {
        if (isEmpty(element.attrs[index])) {
          return '';
        }
        return element.attrs[index].style || {};
      },

      hasAnonymousAttributes(element) {
        if (isEmpty(element.anonymousAttributes)) {
          return false;
        } else {
          return element.anonymousAttributes.length > 0;
        }
      },
      isEmpty,
    },
    watch: {
      $_value: {
        handler(value, prev) {
          if (JSON.stringify(value) !== JSON.stringify(prev)) {
            this.loadValue(value);
            this.$nextTick(() => {
              this.checkValidity(true);
            });
          }
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@veasel/core/sass/colors';
  @import '@veasel/core/sass/conf';

  .s-operations {
    --label-radius: 3px;
    --option-background: #101840;
    --option-border: #101840;
    --option-text: white;
    --option-hover-background: #{lighten(#101840, 30)};
    --operator-background: #2680eb;
    --operator-border: #2680eb;
    --operator-text: white;
    --operator-hover-background: #{darken(#2680eb, 30)};
    --anonymous-attr-background: rgba(38, 128, 235, 0.7);
    --anonymous-attr-hover-background: #{darken(#2680eb, 30)};
    --modifier-background: #888b9f;
    --modifier-border: #101840;
    --modifier-text: white;
    --modifier-hover-background: #{darken(#888b9f, 30)};
    --group-background: white;
    --group-border: #101840;
    --group-text: #101840;
    --group-hover-background: #{darken(white, 30)};
    --group-highlight: #{darken(white, 15)};

    box-sizing: content-box;
    padding: 0 !important;

    * {
      box-sizing: content-box;
    }

    ::v-deep(.select-container .multiselect:hover) {
      box-shadow: none !important;
    }

    .elements-clear-all {
      ::v-deep(.clear-search-button) {
        display: none;
        position: absolute;
        top: calc(50% - 16px);
        right: 7px;
        height: 24px;
        width: 20px;
        padding-top: 8px;
        padding-left: 11px;

        i:hover {
          cursor: pointer;
          color: $red;
        }

        &.no-search-button {
          right: 5px;
        }
      }

      &:not(.disabled):hover .clear-search-button {
        display: block;
      }
    }

    .elements-container {
      overflow: visible;
      width: calc(100% - 7px);
      display: flex;
      flex-wrap: wrap;
      padding-left: 5px;
      padding-right: 0;
      place-items: center;

      &.full {
        width: unset;
      }

      span.element {
        margin: 4px 2px;
        padding: 1px 20px;
        border: 1px solid black;
        user-select: none;
        position: relative;
        border-radius: var(--label-radius);

        .content {
          cursor: pointer;
          padding: 0 3px;
          margin: 0;
        }

        &.options {
          background-color: var(--option-background);
          border-color: var(--option-border);

          span,
          span.content {
            color: var(--option-text);
          }

          span.attribute:not(.operator) {
            color: inherit;
          }

          .content:hover {
            background-color: var(--option-hover-background);
          }

          .attribute {
            cursor: pointer;
            padding: 0 2px;

            &:hover {
              background-color: var(--option-hover-background);
            }

            &.modifier {
              background-color: var(--modifier-background);

              &:hover {
                background-color: var(--modifier-hover-background);
              }
            }

            &.operator {
              background-color: var(--operator-background);

              &:hover {
                background-color: var(--operator-hover-background);
              }
            }

            &.anonymous-attr {
              background-color: var(--anonymous-attr-background);

              &:hover {
                background-color: var(--anonymous-attr-hover-background);
              }
            }
          }

          ::v-deep(.input-wrapper) {
            display: inline-block;
          }
        }

        &.operators {
          background-color: var(--operator-background);
          border-color: var(--operator-border);

          span,
          span.content {
            color: var(--operator-text);
          }

          .content:hover {
            background-color: var(--operator-hover-background);
          }
        }

        &.modifiersPrefix,
        &.modifiersSuffix {
          background-color: var(--modifier-background);
          border-color: var(--modifier-border);
          border-width: 4px;
          padding: 0 20px;

          .remove-element {
            top: 1px;
          }

          span,
          span.content {
            color: var(--modifier-text);
          }

          .content:hover {
            background-color: var(--modifier-hover-background);
          }
        }

        &.modifiersPrefix {
          border-left-width: 4px;
          margin-right: -4px;
        }

        &.modifiersSuffix {
          border-right-width: 4px;
          margin-left: -4px;
        }

        &.invalid {
          color: var(--error);
          border-color: var(--error);
        }

        &.groupStart,
        &.groupEnd {
          background-color: var(--group-background);
          border-color: var(--group-border);
          font-weight: bold;
          transition: background-color 0.5s ease;

          &.highlight {
            background-color: var(--group-highlight);
          }

          span,
          span.content {
            color: var(--group-text);
          }

          .content:hover {
            background-color: var(--group-hover-background);
          }

          .fake-background {
            border-left: 1px solid var(--group-border);
          }
        }

        .remove-element {
          position: absolute;
          display: none;
          cursor: pointer;
          top: 2px;
          right: 1px;
          height: 14px;
          width: 10px;
          padding: 0 5px 4px 3px;
          border-radius: 10px;

          ::v-deep(.s-icon) {
            color: $red;
            position: absolute;
            top: 3px;
            left: 3px;
          }
        }

        &:hover {
          .remove-element {
            display: block;

            &:hover {
              background-color: $red;

              ::v-deep(i) {
                color: $white;
              }
            }
          }
        }
      }

      .add-element {
        position: relative;
        display: none;
        padding: 2px 4px;
        background-color: $green;
        cursor: pointer;
        border-radius: var(--label-radius);

        ::v-deep(i) {
          color: $white;
        }

        &::before {
          content: ' ';
          position: absolute;
          width: 6px;
          height: 28px;
          top: 0;
          left: -5px;
        }
      }

      .add-element.visible,
      .add-element:hover {
        display: block;
      }

      .hoverable-part {
        position: absolute;
        top: -1px;
        width: 6px;
        height: 28px;

        &.left-part {
          left: -5px;
        }

        &.right-part {
          right: -5px;
        }
      }
    }

    .inline {
      display: inline-block;
      user-select: all;
      pointer-events: all;
      z-index: 91;

      ::v-deep(.s-input-group),
      ::v-deep(.multiselect--active) {
        min-height: 17px;
      }

      ::v-deep(input.multiselect__input) {
        min-height: 19px;
      }
    }

    ::v-deep(.multiselect) {
      min-height: 31px;

      &.is-required {
        box-shadow: none;
        outline: none;
      }

      &.sl-input-group-field {
        border: none;
      }

      input {
        border: none;
        padding-left: 0;
        padding-right: 0;
      }

      .multiselect__input {
        border: none;
      }

      .multiselect__placeholder {
        padding-top: 5px;
        padding-left: 0;
      }

      &.multiselect--active:not(.is-error):not(.is-required) {
        border-right: none;
        border-top: none;
        border-bottom: none;
      }
    }
  }

  .sl-input-group.full {
    background: transparent;
  }

  .element-input {
    flex: 1;
    min-width: 125px;
    padding: 1px 2px !important;
  }

  ::v-deep(.element-input) {
    .s-input {
      & .multiselect.s-input-group-field {
        box-shadow: none !important;
        background: transparent !important;
      }
    }
  }
</style>
