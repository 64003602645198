<template>
  <div class="crossfilter-container" :style="{'min-height': minHeight + 'px'}">
    <div class="s-cross-filter-item">
      <div :id="uniqueID" ref="anchor"></div>
    </div>
    <div v-if="dataEmpty && noDataMessage" class="empty-text" :style="dataEmptyMessageStyle">No Data to Display</div>
  </div>
</template>

<script>
  import * as d3 from 'd3';
  import * as dc from '@suadelabs/dc';

  import {clone, timeIn, uniqId} from '@veasel/core/tools';
  import {id, chartResize} from '@veasel/core/mixins';

  const DEFAULT_OPTIONS = {
    valueKey: 'value',
    labelKey: 'label',
    brush: true,
    xAxisType: 'linear',
    timeGranularity: 'day',
    dateFormat: 'DD-MM-YYYY',
    backgroundColor: '',
    margin: 15,
    xAxisMargin: 20,
    yAxisMargin: 40,
    transition: 500,
    labelSort: false,
    tickFormat: null,
    pretransition: null,
    noDataMessage: true,
    minHeight: null,
    tooltip: null,
    barsCap: null,
    timeMin: null,
    timeMax: null,
  };

  export default {
    name: 's-cross-filter-item',

    props: {
      title: {type: String},
      facts: {type: [Boolean, Object], required: true},
      options: {type: Object, default: () => ({})},
    },

    data() {
      return {
        uniqueID: '',
      };
    },

    mixins: [chartResize, id],

    computed: {
      // options
      valueKey() {
        return this.getDefault('valueKey');
      },
      labelKey() {
        return this.getDefault('labelKey');
      },
      brush() {
        return this.getDefault('brush');
      },
      xAxisType() {
        return this.getDefault('xAxisType');
      },
      timeGranularity() {
        return this.getDefault('timeGranularity');
      },
      dateFormat() {
        return this.getDefault('dateFormat');
      },
      backgroundColor() {
        return this.getDefault('backgroundColor');
      },
      margin() {
        return this.getDefault('margin');
      },
      colBorder() {
        return this.getDefault('colBorder');
      },
      colMargin() {
        return this.getDefault('colMargin');
      },
      xAxisMargin() {
        return this.getDefault('xAxisMargin');
      },
      yAxisMargin() {
        return this.getDefault('yAxisMargin');
      },
      transition() {
        return this.getDefault('transition');
      },
      labelSort() {
        return this.getDefault('labelSort');
      },
      tickFormat() {
        return this.getDefault('tickFormat');
      },
      pretransition() {
        return this.getDefault('pretransition');
      },
      noDataMessage() {
        return this.getDefault('noDataMessage');
      },
      minHeight() {
        return this.getDefault('minHeight');
      },
      tooltip() {
        return this.getDefault('tooltip');
      },
      barsCap() {
        return this.getDefault('barsCap');
      },
      timeMin() {
        return this.getDefault('timeMin');
      },
      timeMax() {
        return this.getDefault('timeMax');
      },

      dataEmpty() {
        if (this.facts) {
          return this.facts.all().length === 0;
        }

        return true;
      },

      dataEmptyMessageStyle() {
        return {
          top: 'calc(50% - ' + this.xAxisMargin / 2 + 'px)',
        };
      },
    },

    methods: {
      getDefault(key) {
        return this.options[key] !== undefined ? this.options[key] : DEFAULT_OPTIONS[key];
      },

      buildChart() {
        this.chart = dc.barChart(this.$refs.anchor);

        this.setDimensionsIfNoData();

        if (this.xAxisType === 'linear') {
          // Get minimum and maximum values
          const all = this.facts.all();
          let min = d3.min(all, (d) => d[this.labelKey]);
          let max = d3.max(all, (d) => d[this.labelKey]);

          // Generate buckets from min/max
          this.bucket = this.getBucket(max - min);

          // Quantize
          min = parseInt(min / this.bucket) * this.bucket - (this.bucket > 1 ? 0 : this.bucket);
          max = parseInt(max / this.bucket) * this.bucket + this.bucket;

          this.bucketsNumber = () => (max - min) / this.bucket;

          this.xAxis = d3.scaleLinear().domain([min, max]);
          this.values = this.facts.dimension((d) => parseInt(d[this.labelKey] / this.bucket) * this.bucket);
          this.groups = this.values.group().reduceCount((d) => d[this.labelKey]);
          this.centerBar = this.bucket > 1 ? false : true;
        } else if (this.xAxisType === 'label') {
          this.values = this.facts.dimension((d) => d[this.labelKey]);
          this.groups = this.values.group();

          this.capLabelBars();

          const domain = clone(this.groups.all());

          this.sortLabelBars(domain);

          this.xAxis = d3.scaleBand().domain(domain.map((d) => d.key));
          this.bucketsNumber = dc.units.ordinal;
          this.centerBar = false;
        } else if (this.xAxisType === 'time') {
          const all = this.facts.all();
          let min = this.timeMin || d3.min(all, (d) => d[this.labelKey]);
          let max = this.timeMax || d3.max(all, (d) => d[this.labelKey]);

          // If either min or max is null, there is no data, abort the render
          if (min && max) {
            this.bucket = this.getTimeGranularity().timeBucket;
            min = new Date(min.getTime() - this.bucket);
            max = new Date(max.getTime() + this.bucket);
            const timeDomain = [min, max];
            this.values = this.facts.dimension((d) => this.getTimeGranularity().d3Bucket(d[this.labelKey]));
            this.groups = this.values.group().reduceCount((d) => d[this.labelKey]);
            this.xAxis = d3.scaleTime().domain(timeDomain);
            this.bucketsNumber = () => (max - min) / this.bucket;
            this.centerBar = true;
          }
        }

        // distance Bar Graph Summed
        this.chart
          .width(this.lastWidth)
          .height(this.lastHeight)
          .margins({top: this.margin, right: this.margin, bottom: this.xAxisMargin, left: this.yAxisMargin})
          .dimension(this.values) // the values across the x axis
          .group(this.groups) // the values on the y axis
          .on('filtered', this.filter)
          .transitionDuration(this.transition)
          .centerBar(this.centerBar)
          .x(this.xAxis)
          .xUnits(this.bucketsNumber)
          .elasticY(true)
          .yAxis()
          .ticks(1);

        if (this.tickFormat) {
          this.chart.xAxis().tickFormat(this.tickFormat);
        }

        if (this.pretransition) {
          this.chart.on('pretransition', this.pretransition);
        }

        if (this.tooltip) {
          this.chart.title(this.tooltip);
        }

        this.chart.render();
      },

      // Resizing won't happen on first load if there is no data, so need to
      // set height and width values to prevent null being used and the layout breaking.
      setDimensionsIfNoData() {
        if (!this.lastHeight) {
          this.lastHeight = this.minHeight ? this.minHeight : this.$el.clientHeight;
        }
        if (!this.lastWidth) {
          // Set to width of the element container rather than setting a
          // fixed minWidth value as graphs can vary dramatically in width
          this.lastWidth = this.$el.clientWidth;
        }
      },

      getBucket(x) {
        let bucket = 10 ** Math.floor(Math.log10(x) - 1);

        if (x > 40 * bucket) {
          bucket *= 5;
        } else if (x > 20 * bucket) {
          bucket *= 2;
        }

        return bucket;
      },

      filter() {
        // We may need to go up several layers of components to find the wrapper component due to other components
        // like asyncWrapper being in-between, which is the case for the componentRender
        if (typeof this.$parent.updateFacts === 'function') {
          this.$parent.updateFacts();
        } else if (typeof this.$parent.$parent.updateFacts === 'function') {
          this.$parent.$parent.updateFacts();
        } else if (typeof this.$parent.$parent.$parent.updateFacts === 'function') {
          this.$parent.$parent.$parent.updateFacts();
        } else {
          console.error('SUADE LIBS: Unable to update facts on s-cross-filter-wrapper - cannot find it');
        }
      },

      getTimeGranularity() {
        let bucket;

        switch (this.timeGranularity) {
          case 'hour':
            bucket = d3.timeHour;
            break;
          case 'day':
            bucket = d3.timeDay;
            break;
          case 'week':
            bucket = d3.timeWeek;
            break;
          case 'month':
            bucket = d3.timeMonth;
            break;
          case 'year':
            bucket = d3.timeYear;
            break;
          default:
            bucket = d3.timeDay;
            break;
        }

        return {d3Bucket: bucket, timeBucket: timeIn(this.timeGranularity)};
      },

      // If a cap is set, limit the number of bars and group remaining into an 'Others' bar
      capLabelBars() {
        if (this.barsCap) {
          const getTops = (sourceGroup, barsCap) => {
            const all = sourceGroup.all();
            let othersGroup = [];

            if (all.length > barsCap) {
              const remaining = all.slice(barsCap);
              const count = remaining.reduce((sum, nextGroup) => sum + nextGroup.value, 0);

              othersGroup = [{key: 'Others', value: count}];
            }

            return {
              all: function () {
                return sourceGroup.top(barsCap).concat(othersGroup);
              },
            };
          };

          this.groups = getTops(this.groups, this.barsCap);
        }
      },

      // Sort the bars of a label bar chart, keeping the 'Others' column last, if present
      sortLabelBars(domain) {
        if (this.labelSort === 'asc') {
          domain = domain.sort((d1, d2) => d1.value - d2.value);
        } else if (this.labelSort === 'desc') {
          domain = domain.sort((d1, d2) => d2.value - d1.value);
        }

        if (this.barsCap) {
          const index = domain.map((d) => d.key).indexOf('Others');

          if (index != -1) {
            domain.splice(domain.length - 1, 0, domain.splice(index, 1)[0]);
          }
        }
      },

      redrawDueToResize() {
        if (this.facts && this.facts.all().length) {
          this.buildChart();
        }
      },
    },
    created() {
      // Dc uses d3-schale-chromatic colour schemas from v3 and up, a list can be found here https://github.com/d3/d3-scale-chromatic
      dc.config.defaultColors(d3.schemeSet3);
      if (typeof this.id === 'undefined') {
        this.uniqueID = uniqId('chart_').replace('.', '');
      } else {
        this.uniqueID = this.id;
      }
    },
    watch: {
      facts(facts) {
        if (facts) {
          this.checkForSizeChange();
          this.buildChart();
        }
      },
    },

    beforeUnmount() {
      if (this.chart) {
        dc.deregisterChart(this.chart);
      }
    },
  };
</script>

<style lang="scss">
  @import '@veasel/core/sass/conf';

  .crossfilter-container {
    width: 100%;
    height: 100%;
  }

  .s-cross-filter-item {
    width: 100%;
    height: 100%;

    .dc-chart {
      float: left;
    }

    .dc-chart rect.bar {
      stroke: none;
      fill: var(--active);
    }

    .dc-chart rect.bar:hover {
      fill-opacity: 0.5;
    }

    .dc-chart rect.stack1 {
      stroke: none;
      fill: var(--error);
    }

    .dc-chart rect.stack2 {
      stroke: none;
      fill: var(--approved);
    }

    .dc-chart rect.deselected {
      stroke: none;
      fill: #ccc;
    }

    .dc-chart .sub .bar {
      stroke: none;
      fill: #ccc;
    }

    .dc-chart .pie-slice {
      fill: var(--background-main);
      font-size: 12px;
      cursor: pointer;
    }

    .dc-chart .pie-slice :hover {
      fill-opacity: 0.8;
    }

    .dc-chart .selected path {
      stroke-width: 3;
      stroke: #ccc;
      fill-opacity: 1;
    }

    .dc-chart .deselected path {
      stroke: none;
      fill-opacity: 0.5;
      fill: #ccc;
    }

    .dc-chart .axis path,
    .axis line {
      fill: none;
      stroke: #000;
      shape-rendering: crispEdges;
    }

    .dc-chart .axis text {
      font: 10px sans-serif;
    }

    .dc-chart .grid-line line {
      fill: none;
      stroke: #ccc;
      opacity: 0.5;
      shape-rendering: crispEdges;
    }

    .dc-chart .brush rect.background {
      z-index: -1;
    }

    .dc-chart .brush rect.extent {
      fill: var(--active);
      fill-opacity: 0.125;
    }

    .dc-chart .brush .resize path {
      fill: #eee;
      stroke: #666;
    }

    .dc-chart path.line {
      fill: none;
      stroke: var(--active);
      stroke-width: 1.5px;
    }

    .dc-chart circle.dot {
      fill: var(--active);
    }

    .dc-chart g.stack1 path.line {
      stroke: var(--approved);
    }

    .dc-chart g.stack1 circle.dot {
      fill: var(--approved);
    }

    .dc-chart g.stack2 path.line {
      stroke: var(--error);
    }

    .dc-chart g.stack2 circle.dot {
      fill: var(--error);
    }

    .dc-chart g.dc-tooltip path {
      fill: none;
      stroke: var(--secondary);
      stroke-opacity: 0.8;
    }

    .dc-chart path.area {
      fill: var(--active);
      fill-opacity: 0.3;
      stroke: none;
    }

    .dc-chart g.stack1 path.area {
      fill: var(--approved);
    }

    .dc-chart g.stack2 path.area {
      fill: var(--error);
    }

    .dc-chart .node {
      font-size: 0.7em;
      cursor: pointer;
    }

    .dc-chart .node :hover {
      fill-opacity: 0.8;
    }

    .dc-chart .selected circle {
      stroke-width: 3;
      stroke: #ccc;
      fill-opacity: 1;
    }

    .dc-chart .deselected circle {
      stroke: none;
      fill-opacity: 0.5;
      fill: #ccc;
    }

    .dc-chart .bubble {
      stroke: none;
      fill-opacity: 0.6;
    }

    .dc-data-count {
      float: right;
      margin-top: 15px;
      margin-right: 15px;
    }

    .dc-data-count .filter-count {
      color: #3182bd;
      font-weight: bold;
    }

    .dc-data-count .total-count {
      color: #3182bd;
      font-weight: bold;
    }

    .dc-chart g.state {
      cursor: pointer;
    }

    .dc-chart g.state :hover {
      fill-opacity: 0.8;
    }

    .dc-chart g.state path {
      stroke: var(--background-main);
    }

    .dc-chart g.deselected path {
      fill: var(--secondary);
    }

    .dc-chart g.deselected text {
      display: none;
    }

    .dc-chart g.county path {
      stroke: var(--background-main);
      fill: none;
    }

    .dc-chart g.debug rect {
      fill: blue;
      fill-opacity: 0.2;
    }

    .dc-chart g.row rect {
      fill-opacity: 0.8;
      cursor: pointer;
    }

    .dc-chart g.row rect:hover {
      fill-opacity: 0.6;
    }

    .dc-chart g.row text {
      fill: black;
      font-size: 12px;
    }

    .dc-chart .tick {
      pointer-events: none;
      user-select: none;
    }
  }

  .empty-text {
    position: absolute;
    left: 54%;
    top: 50%;
    transform: translateX(-50%);
  }
</style>
