<template>
  <span :id="uID" class="s-label" :class="['size-' + size]">
    <span class="title theme-style-base-small-bold-background-main" :class="[type, 'theme-' + type]">
      {{ title }}
    </span>
    <span
      v-if="!isEmpty($slots.default)"
      class="label-content theme-style-base-small-bold"
      :style="{borderColor: 'var(--' + type + ')'}"
    >
      <slot></slot>
    </span>
  </span>
  <s-tooltip v-if="!isEmpty($slots.tooltip)" position="bottom" :target="'#' + uID">
    <slot name="tooltip"></slot>
  </s-tooltip>
</template>

<script>
  import {isEmpty, uniqId} from '@veasel/core/tools';
  import {size} from '@veasel/core/mixins';
  import tooltip from '@veasel/base/tooltip';

  export default {
    name: 's-label',
    description: 'A simple stylized label',
    components: {
      's-tooltip': tooltip,
    },
    mixins: [size(['small', 'tall'], 'small')],
    props: {
      type: {
        description: 'The label type/style.',
        type: String,
        values: ['active', 'secondary', 'warning', 'error', 'approved'],
        validator: (val) => ['active', 'secondary', 'warning', 'error', 'approved'].includes(val),
        default: 'active',
      },
      title: {
        type: String,
      },
    },
    slots: {
      default: 'Content of the label',
      tooltip: 'Contents of the tooltip that appears when hover over',
    },
    data() {
      return {
        uID: '',
      };
    },
    methods: {
      isEmpty,
    },
    created() {
      this.uID = uniqId('uid-');
    },
  };
</script>

<style scoped lang="scss">
  @import '@veasel/core';

  .s-label {
    display: flex;
    margin-bottom: get-spacing('s');
    margin-right: get-spacing('s');

    &.size-tall {
      .title {
        line-height: 32px;
        padding: 9px 7px 9px 8px;
      }
    }
  }

  .title {
    vertical-align: top;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    padding: 5px get-spacing('s') 3px;

    &:only-child {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
  }

  .label-content {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-top: 1px solid;
    border-right: 1px solid;
    border-left: 1px solid;
    border-bottom: 1px solid;
    padding-left: 8px;
    padding-right: 8px;
    cursor: default;
    background: var(--background-main);
    display: flex;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: -1px;
    padding-top: 1px;

    &:empty {
      display: none;
    }
  }
</style>
