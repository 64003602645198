<template>
  <s-input-wrapper v-bind="$props" isBoolean>
    <div class="s-input row-fluid preload" :class="[size]">
      <div
        class="checkbox"
        :class="[$data.$_isMissing ? 'is-required' : '', label ? 'has-label' : '']"
        @click="checkValue"
      >
        <label
          :id="id"
          :name="name"
          class="checkbox-label"
          :class="{disabled: disabled, 'justify-content-center': centerInput}"
        >
          <span class="s-checkbox" :class="[$_value ? 'active' : '']">
            <s-icon v-if="$_value" type="checkbox-tick-small" :color="iconColor" :size="iconSize" />
          </span>
          <span v-if="label && labelMod === 'inside'" class="label">{{ label }}</span>
        </label>
      </div>
    </div>
  </s-input-wrapper>
</template>

<script>
  import SInputWrapper from '@veasel/inputs/input-wrapper';
  import {
    syncValue,
    onChange,
    onClick,
    onBlur,
    onFocus,
    id,
    name,
    isDisabled,
    placeholder,
    label,
    size,
  } from '@veasel/core/mixins';

  export default {
    name: 's-input-checkbox',
    description: 'A user input as a check box for boolean value.',
    components: {SInputWrapper},
    mixins: [
      syncValue(Boolean),
      onChange(),
      onClick(),
      onBlur(),
      onFocus(),
      id,
      name,
      isDisabled,
      placeholder,
      label,
      size(),
    ],
    data() {
      return {
        iconColor: 'theme-background-main',
      };
    },
    props: {
      label: {
        description: 'A label to describe the input.',
        type: String,
        default: '',
      },
      centerInput: {
        description: 'Center the checkbox. Handy for when you have no label',
        type: Boolean,
        default: false,
      },
    },
    computed: {
      iconSize() {
        if (this.size === 'small') {
          return 10;
        }
        return 16;
      },
    },
    methods: {
      checkValue: function (e) {
        e.stopPropagation();
        if (!this.disabled) {
          this.$_value = !this.$_value;
          this.$_onChange();
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@veasel/core';

  .s-input .disabled {
    pointer-events: none;
    background: transparent;

    .s-checkbox {
      &::after {
        border-color: var(--secondary-50);
        border-width: 1px;
      }

      &.active {
        background-color: var(--secondary-50);
      }
    }

    span {
      pointer-events: none;
    }

    ::v-deep([class^='s-icon-']) {
      pointer-events: none;
    }
  }

  .checkbox {
    cursor: pointer;

    .checkbox-label {
      pointer-events: none;
      user-select: none;
      align-items: center;

      span,
      ::v-deep(.s-icon) {
        pointer-events: all;
        cursor: pointer;
      }

      .label {
        @include base-small;

        margin-left: get-spacing('s');
      }
    }
  }

  .s-input.medium {
    .s-checkbox {
      height: 1.5rem;
      min-width: 1.5rem;
    }

    .has-label {
      padding-top: get-spacing('xs');
      padding-bottom: get-spacing('xs');
    }

    label {
      display: flex;

      ::v-deep(.s-icon) {
        line-height: 2rem;
        font-size: 1rem;
      }
    }
  }

  .s-input.small {
    .s-checkbox {
      height: 1rem;
      min-width: 1rem;
    }

    .has-label {
      padding-top: get-spacing('xs');
      padding-bottom: get-spacing('xs');
    }

    label {
      display: flex;
    }
  }

  .s-checkbox {
    background: var(--background-main);
    border-radius: $button-radius;
    box-sizing: border-box;
    border: 0;
    transition: ease border-color $transition-time;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      box-sizing: border-box;
      left: 0;
      border-radius: $button-radius;
      border: 1px solid var(--secondary);
    }

    &:hover::after {
      border-color: var(--active);
      border-width: 2px;
    }

    &.active {
      background: var(--active);

      &::after {
        border-color: var(--active);
      }
    }

    .s-icon {
      stroke: var(--background-main);
      stroke-width: 2px;
      position: absolute;
      line-height: 1em;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
</style>
