import {copyStringToClipboard, closestOrSelf} from '@veasel/core/tools';

export default {
  data: function () {
    return {
      startAggSelectionIndex: false,
      endAggSelectionIndex: false,
      totalValue: 0,
    };
  },

  methods: {
    // event handler for group cell selection
    groupSelected: function (to, isTrigggerdByKeyboard) {
      this.removeSelection();
      this.addSelectionListener();
      this.$nextTick(() => {
        let target = this.$el.querySelector('[agg-index="' + to + '"] .group-cell');
        if (!target) {
          const x = parseInt(to.split('_')[0]) - 1;
          const y = parseInt(to.split('_')[1]);
          target = this.$el.querySelector('[agg-index="' + x + '_' + y + '"] .group-cell');
        }
        if (target) {
          this.startAggSelectionIndex = to;
          this.displaySelector(
            this.$refs.groupSelector,
            target.offsetWidth - 4,
            target.offsetHeight - 4,
            target.offsetTop + 1,
            target.offsetLeft + 2
          );
          this.scrollToGroup(target);
          if (isTrigggerdByKeyboard) {
            this.verticalScrollToSelection(target);
          }
          copyStringToClipboard(target.querySelector('.group-title').innerText);
          this.emitGroup(to);
        }
      });
    },

    // event handler for aggregation cell selection
    aggSelected: async function (to) {
      this.removeSelection();
      this.addSelectionListener();
      const isMouseEvent = event instanceof MouseEvent;
      if (isMouseEvent) {
        this.addAggSelectionListener();
      }
      this.startAggSelectionIndex = to;
      const target = this.$el.querySelector('[agg-index="' + to + '"].agg-cell');
      this.$refs.aggSelector.style.display = 'none'; // Hide the selector when the scroll is happening
      await this.scrollToGroup(target);
      this.displaySelector(
        this.$refs.aggSelector,
        target.offsetWidth - 4,
        target.offsetHeight - 4,
        target.offsetTop + target.offsetParent.offsetParent.offsetTop + 1,
        target.offsetLeft + target.offsetParent.offsetParent.offsetLeft + 2,
        true
      );
      copyStringToClipboard(target.innerText);
      this.verticalScrollToSelection(target);
    },

    // event handler for multiple aggregation cell selection
    aggSelectedMultiple: function (_from, _to) {
      this.endAggSelectionIndex = _to;
      this.addSelectionListener();
      const target = document.querySelector('[col-index][agg-index="' + _to + '"]', this.$el);
      if (target) {
        this.verticalScrollToSelection(target);
      }
      // check which selected cell is the first (top to bottom)
      const index1 = parseFloat(_from.split('_')[1].replace('-', '.').replace(/-/g, ''));
      const index2 = parseFloat(_to.split('_')[1].replace('-', '.').replace(/-/g, ''));
      let fromYIndex;
      let toYIndex;
      if (index1 > index2) {
        fromYIndex = _to;
        toYIndex = _from;
      } else {
        fromYIndex = _from;
        toYIndex = _to;
      }
      // retrieve col index of the current column
      const colIndex = this.$el.querySelector('[agg-index="' + _from + '"].agg-cell').getAttribute('col-index');
      // retrieve index in the list of cell for the current column
      const firstOfSelection = document.querySelector(
        '[col-index="' + colIndex + '"][agg-index$="_' + fromYIndex.split('_')[1] + '"]',
        this.$el
      );
      let lastOfSelection = document.querySelector(
        '[col-index="' + colIndex + '"][agg-index$="_' + toYIndex.split('_')[1] + '"]',
        this.$el
      );
      if (!firstOfSelection) {
        return;
      }
      if (!lastOfSelection) {
        lastOfSelection = document
          .querySelector('[col-index="' + colIndex + '"][agg-index*="_' + toYIndex.split('_')[1] + '-' + '"]', this.$el)
          .last();
      }
      const columnList = document.querySelectorAll('[col-index="' + colIndex + '"]', this.$el);
      const selectionList = [];
      let startedAndNotFinished = false;
      // list the values to sum for total
      columnList.forEach(function (el) {
        if (el === firstOfSelection) {
          startedAndNotFinished = true;
        }
        if (startedAndNotFinished) {
          selectionList.push(Number(el.getAttribute('value')));
        }
        if (el === lastOfSelection) {
          startedAndNotFinished = false;
        }
      });
      // calculate total
      this.totalValue = Number(selectionList.reduce((acc, value) => acc + value, 0).toFixed(2));
      this.displaySelector(
        this.$refs.aggSelector,
        firstOfSelection.offsetWidth - 4,
        firstOfSelection.offsetHeight +
          (lastOfSelection.offsetParent.offsetParent.offsetTop - firstOfSelection.offsetParent.offsetParent.offsetTop) -
          4,
        firstOfSelection.offsetTop + firstOfSelection.offsetParent.offsetParent.offsetTop + 1,
        firstOfSelection.offsetLeft + firstOfSelection.offsetParent.offsetParent.offsetLeft + 2,
        true
      );
      copyStringToClipboard(selectionList.join('\n'));
      // display total
      const total = this.$refs.total;
      const rect = this.$refs.aggSelector.getBoundingClientRect();
      if (total?.style) {
        total.style.display = 'flex';
        total.style.minWidth = lastOfSelection.offsetWidth + 'px';
        total.style.height = lastOfSelection.offsetHeight + 'px';
        total.style.top = rect.bottom + 2 + 'px';
        total.style.right = 'calc(100% - ' + Math.ceil(rect.right + 1) + 'px)';
        document.addEventListener('scroll', this.hideTotal);
        this.scrollers.forEach((scroller) => {
          scroller.addEventListener('scroll', this.hideTotal);
        });
      }
    },

    /**
     * Positions a selector to the correct location
     * @param {Element} target
     * @param {Number} width
     * @param {Number} height
     * @param {Number} top
     * @param {Number} left
     * @param {Boolean} isAggSelector
     */
    displaySelector: function (target, width, height, top, left, isAggSelector) {
      const aggScroll = isAggSelector ? this.currentAggScroll : 0;
      if (target?.style) {
        target.style.display = 'block';
        target.style.width = width + 'px';
        target.style.height = height + 'px';
        target.style.marginTop = top + 'px';
        target.style.marginLeft = left - aggScroll + 'px';
      }
    },

    // hide the total cell
    hideTotal: function () {
      document.removeEventListener('scroll', this.hideTotal);
      this.scrollers.forEach((scroller) => {
        scroller.removeEventListener('scroll', this.hideTotal);
      });

      if (this.$refs.total) {
        this.$refs.total.style.display = 'none';
      }
    },

    // add listener to cancel current selection
    addSelectionListener: function () {
      document.addEventListener('mousedown', this.removeSelection);
      document.addEventListener('keydown', this.keydownHandler);
    },

    // add listener for multiple selection
    addAggSelectionListener: function () {
      document.addEventListener('mousemove', this.throttleMmousemoveHandler);
      document.addEventListener('mouseup', this.mouseupHandler);
    },

    // multiple selection handler
    mousemoveHandler: function (event) {
      let index = closestOrSelf(event.target, '[agg-index]');
      if (index) {
        index = index.getAttribute('agg-index');
        if (this.startAggSelectionIndex === index) {
          this.aggSelected(this.startAggSelectionIndex);
        } else {
          this.aggSelectedMultiple(this.startAggSelectionIndex, index);
        }
      }
    },

    // end of multiple selection handler
    mouseupHandler: function () {
      document.removeEventListener('mousemove', this.throttleMmousemoveHandler);
      document.removeEventListener('mouseup', this.mouseupHandler);
    },

    // cancel current selection handler
    removeSelection: function (isDefinitive) {
      // Unless a arg is provided, we assume that the target click happened within the table
      let isClickTargetWithinTable = true;
      if (isDefinitive) {
        isClickTargetWithinTable = this.$el.contains(isDefinitive.target);
      }

      // If a target click did happen within the aggtable, (like if we are making a new selection)
      // then we want to clear and flush what is selected, as there are other places that will set the next selection
      // If it didn't happen in the table, then we want to leave things as they are, so then the graph can be exported
      if (isClickTargetWithinTable) {
        document.removeEventListener('mousedown', this.removeSelection);
        document.removeEventListener('keydown', this.keydownHandler);
        this.startAggSelectionIndex = false;
        this.endAggSelectionIndex = false;
        if (this.$refs.groupSelector) {
          this.$refs.groupSelector.style.display = 'none';
          this.$refs.aggSelector.style.display = 'none';
          this.$refs.total.style.display = 'none';
        }
      }

      // If we were provided an arg and the click was in the table (like clicking a header) then we want to clear the selection by running this emit
      if (isDefinitive && isClickTargetWithinTable) {
        this.emitGlobalGroup();
      }
    },

    // keyboard navigation handler
    keydownHandler: function (event) {
      let index = this.endAggSelectionIndex || this.startAggSelectionIndex;
      if (!index) {
        return;
      }

      // If an animation is happening, we want to cancel the action.
      if (this.isCurrentlyScrolling) {
        event.preventDefault();
        return;
      }

      const x = index.split('_')[0];
      const y = index.split('_')[1];
      const yArray = y.split('-');
      const colIndex = parseInt(document.querySelector(`[agg-index="${index}"]`)?.getAttribute('col-index'));
      this.mouseupHandler();
      if (!isNaN(colIndex)) {
        switch (event.key) {
          case 'ArrowUp':
            event.preventDefault();
            index = this.getAggCellAbove(colIndex, yArray);
            break;
          case 'ArrowDown':
            event.preventDefault();
            index = this.getAggCellBelow(colIndex, yArray);
            break;
          case 'ArrowLeft':
            event.preventDefault();
            if (colIndex <= 0) {
              index = parseInt(x) - 1 + '_' + yArray.join('-');
              document.removeEventListener('keydown', this.keydownHandler);
              this.groupSelected(index, true);
              return;
            } else {
              index = document
                .querySelector(`[col-index="${colIndex - 1}"][agg-index$="_${yArray.join('-')}"]`)
                ?.getAttribute('agg-index');
              break;
            }
          case 'ArrowRight':
            event.shiftKey = false;
            event.preventDefault();
            index = document
              .querySelector(`[col-index="${colIndex + 1}"][agg-index$="_${yArray.join('-')}"]`)
              ?.getAttribute('agg-index');
            break;
          case 'Enter':
            event.preventDefault();
            this.emitDrillDown(index);
            event.shiftKey = false;
            return;
          default:
            return;
        }
        if (document.querySelector(`[agg-index="${index}"]`) === null) {
          return;
        }
        document.removeEventListener('keydown', this.keydownHandler);
        if (event.shiftKey) {
          this.endAggSelectionIndex = index;
          this.aggSelectedMultiple(this.startAggSelectionIndex, index);
        } else {
          this.endAggSelectionInde = false;
          this.startAggSelectionIndex = index;
          this.aggSelected(index);
        }
      } else {
        const $expandIcon = document.querySelector(`[agg-index="${index}"] .expand-icon`);
        switch (event.key) {
          case 'ArrowUp':
            event.preventDefault();
            index = this.getGroupCellAbove(x, yArray);
            break;
          case 'ArrowDown':
            event.preventDefault();
            index = this.getGroupCellBelow(x, yArray);
            break;
          case 'ArrowLeft':
            event.preventDefault();
            yArray.pop();
            index = Math.max(0, parseInt(x) - 1) + '_' + yArray.join('-');
            break;
          case 'ArrowRight':
            event.preventDefault();
            if (
              x === this.groupHeaders.length - 1 ||
              document.querySelectorAll('[agg-index="' + (parseInt(x) + 1) + '_' + yArray.join('-') + '-0"]', this.$el)
                .length === 0
            ) {
              document.removeEventListener('keydown', this.keydownHandler);
              index = parseInt(x) + 1 + '_' + yArray.join('-');
              this.aggSelected(index);
              return;
            } else {
              yArray.push('0');
              index = parseInt(x) + 1 + '_' + yArray.join('-');
            }
            break;
          case 'Enter':
            event.preventDefault();
            if ($expandIcon) {
              $expandIcon.click();
              if ($expandIcon.hasClass('is-extended')) {
                document.removeEventListener('keydown', this.keydownHandler);
                this.groupSelected(index, true);
              }
            }
            return;
          default:
            return;
        }
        if (document.querySelectorAll('[agg-index="' + index + '"]', this.$el).length === 0) {
          return;
        }
        document.removeEventListener('keydown', this.keydownHandler);
        this.endAggSelectionInde = false;
        this.startAggSelectionIndex = index;
        this.groupSelected(index, true);
      }
    },

    // navigate to top aggregation cell
    getAggCellAbove: function (colIndex, indexArray) {
      indexArray[0] += '.';
      const currentIndex = Number(indexArray.join(''));
      const list = document.querySelectorAll('[col-index="' + colIndex + '"].agg-cell');
      const indexesList = Array.from(list).map(function (el) {
        return Number(el.getAttribute('agg-index').split('_')[1].replace('-', '.').replace(/-/g, ''));
      });
      for (let i = indexesList.length - 1; i >= 0; i--) {
        if (indexesList[i] < currentIndex) {
          return list[i].getAttribute('agg-index');
        }
      }
    },

    // navigate to bottom aggregation cell
    getAggCellBelow: function (colIndex, indexArray) {
      indexArray[0] += '.';
      const currentIndex = Number(indexArray.join(''));
      const list = document.querySelectorAll('[col-index="' + colIndex + '"].agg-cell');
      const indexesList = Array.from(list).map(function (el) {
        return Number(el.getAttribute('agg-index').split('_')[1].replace('-', '.').replace(/-/g, ''));
      });
      for (let i = 0; i < indexesList.length; i++) {
        if (indexesList[i] > currentIndex) {
          return list[i].getAttribute('agg-index');
        }
      }
    },

    // navigate to top group cell
    getGroupCellAbove: function (x, indexArray) {
      const length = indexArray.length;
      for (let i = indexArray.length - 1; i >= 0; i--) {
        if (indexArray[i] > 0) {
          if (i === length - 1) {
            indexArray[i]--;
            return x + '_' + indexArray.join('-');
          } else {
            indexArray[indexArray.length - 1]--;
            for (let i = indexArray.length; i <= x; i++) {
              indexArray[i] =
                this.$el.querySelectorAll('[agg-index^="' + i + '_' + indexArray.join('-') + '-"]').length - 1;
            }
            return x + '_' + indexArray.join('-');
          }
        } else {
          indexArray.pop();
        }
      }
      return false;
    },

    // navigate to bottom group cell
    getGroupCellBelow: function (x, indexArray) {
      for (let i = indexArray.length - 1; i >= 0; i--) {
        indexArray[i]++;
        if (document.querySelectorAll('[agg-index="' + x + '_' + indexArray.join('-') + '"]', this.$el).length > 0) {
          return x + '_' + indexArray.join('-');
        } else {
          indexArray[i] = 0;
        }
      }
      return false;
    },
  },
};
