<template>
  <section v-for="position in positions" :key="position" class="s-notifications__wrapper" :class="position">
    <template v-for="notification in notificationsList">
      <div
        v-if="notification.position === position"
        :id="notification.id"
        :key="notification.title"
        class="s-notifications fade-in"
        :class="[notification.type]"
        @mouseleave="removeNotification(notification)"
      >
        <div class="s-notifications__icon">
          <s-icon :type="notification.icon" color="white" :size="16" />
        </div>
        <div class="s-notifications__content">
          <p v-if="notification.allowHtml" class="s-notifications__title" v-html="notification.title"></p>
          <p v-else class="s-notifications__title">{{ notification.title }}</p>
          <p v-if="notification.allowHtml" class="s-notifications__message" v-html="notification.message"></p>
          <p v-else class="s-notifications__message">{{ notification.message }}</p>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
  import {defineComponent} from 'vue';
  export default defineComponent({
    name: 'SNotifications',
    data() {
      return {
        notificationsList: [],
        positions: [
          'top-left',
          'top-center',
          'top-right',
          'center-center',
          'bottom-left',
          'bottom-right',
          'bottom-center',
        ],
        icons: {
          success: 'checkmark',
          warning: 'warning',
          error: 'cross',
          info: 'info-circle',
        },
      };
    },
    inject: ['defaultOptions'],
    methods: {
      notify(...args) {
        const [type, title, message, options] = args || [];
        const item = {
          id: this.notificationsList.length,
          title,
          type,
          message,
          icon: this.icons[type],
        };
        item.position = options && options.position ? options.position : this.defaultOptions.defaultPosition;
        item.allowHtml = options && options.allowHtml ? options.allowHtml : this.defaultOptions.defaultPosition;
        item.timeout = options && options.timeout ? options.timeout : this.defaultOptions.defaultTimeout;
        this.notificationsList.push(item);
        if (!this.defaultOptions.disableTimeout) {
          const timeout = setTimeout(() => {
            this.removeNotification(item);
            clearTimeout(timeout);
          }, item.timeout);
        }
        return item;
      },
      success(title, message, options) {
        return this.notify('success', title, message, options);
      },
      info(title, message, options) {
        return this.notify('info', title, message, options);
      },
      warning(title, message, options) {
        return this.notify('warning', title, message, options);
      },
      error(title, message, options) {
        return this.notify('error', title, message, options);
      },
      removeNotification(notification) {
        const notificationWrapper = document.getElementById(notification.id);
        if (notificationWrapper) {
          notificationWrapper.addEventListener('animationend', function () {
            notificationWrapper.remove();
          });
          notificationWrapper.classList.toggle('fade-out');
          notificationWrapper.classList.toggle('fade-in');
        }
      },
    },
  });
</script>

<style lang="scss" scoped>
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  .fade {
    &-in {
      animation: 0.2s fadeIn;
      animation-fill-mode: forwards;
    }

    &-out {
      animation: 0.2s fadeOut;
      animation-fill-mode: forwards;
    }
  }

  p {
    padding: 0;
    margin: 0;
  }

  .s-notifications {
    border-radius: 5px;
    box-shadow: 0 0 12px rgba(252, 252, 252, 0.3);
    display: flex;
    align-items: center;
    padding: 5px 15px;
    color: white;
    word-break: break-all;
    margin: 15px;
    width: 300px;
    min-height: 50px;
    height: max-content;
    max-height: 100%;
    z-index: 9999;
    background: transparent;
    position: relative;
    pointer-events: auto;
    opacity: 0;
    transition: all 0.3s ease-in;

    &__wrapper {
      position: fixed;
      z-index: 9999;
      pointer-events: none;
      height: max-content;
      width: max-content;
    }

    &:hover {
      box-shadow: 0 0 12px rgba(51, 51, 51, 0.5);
      transition: opacity 0.3s ease-in;
      opacity: 1;
      background: transparent;
    }

    &__icon {
      margin-right: 15px;
    }

    &__title {
      white-space: pre-wrap;
      word-break: break-word;
      font-weight: 900;
    }

    &__message {
      white-space: pre-wrap;
      word-break: break-word;
      font-size: 16px;
    }
  }

  .success {
    background: #5cb85c !important;
  }

  .info {
    background: #5bc0de !important;
  }

  .warning {
    background: #f0ad4e !important;
  }

  .error {
    background: #db2828 !important;
  }

  .top {
    &-left {
      left: 10px;
      top: 10px;
    }

    &-right {
      right: 10px;
      top: 10px;
    }

    &-center {
      left: 10px;
      right: 10px;
      top: 10px;
      margin: auto;
    }
  }

  .bottom {
    &-left {
      left: 10px;
      bottom: 10px;
    }

    &-right {
      right: 10px;
      bottom: 10px;
    }

    &-center {
      left: 10px;
      right: 10px;
      bottom: 10px;
    }
  }

  .center {
    &-center {
      left: 10px;
      right: 10px;
      bottom: 10px;
      top: 10px;
      margin: auto;
    }
  }
</style>
