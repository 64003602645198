export default {
  methods: {
    /**
     * Animates the changing of a property by slowly changing the element
     *
     * eg: this.smoothScroll(element, 'scrollLeft', value, 100);
     *
     * @param {Element} elementToTarget  The Element you want to change
     * @param {String} elementProperty The property you want to change
     * @param {Number} newValue The new value you want to set.
     * @param {Number} speed The speed of the animation
     * @return {Promise}
     */
    smoothScroll: function (elementToTarget, elementProperty, newValue, speed) {
      return new Promise((resolve) => {
        const animationFrameRate = 30; // Amount of frames to complete
        const oldValue = elementToTarget[elementProperty];
        const pixelsToMovePerFrame = ((oldValue - newValue) / animationFrameRate) * -1;
        const isAscending = newValue > oldValue; // Are we increasing or decreasing the value
        let animationFrame = 1;

        const scrollAnimation = setInterval(() => {
          elementToTarget[elementProperty] = isAscending
            ? Math.ceil(elementToTarget[elementProperty] + pixelsToMovePerFrame)
            : Math.floor(elementToTarget[elementProperty] + pixelsToMovePerFrame);
          animationFrame++;
          // When the animation is complete, we want to stop this setInterval
          if (animationFrame === animationFrameRate) {
            clearInterval(scrollAnimation);
            elementToTarget[elementProperty] = newValue;
            resolve(true);
          } else if (isAscending && elementToTarget[elementProperty] >= newValue) {
            clearInterval(scrollAnimation);
            resolve(true);
            elementToTarget[elementProperty] = newValue;
          } else if (!isAscending && elementToTarget[elementProperty] <= newValue) {
            clearInterval(scrollAnimation);
            elementToTarget[elementProperty] = newValue;
            resolve(true);
          }
        }, speed / animationFrameRate); // How often to change the frame.
      });
    },
  },
};
