<template>
  <span class="s-icon" @mouseover="isOnHover = true" @mouseout="isOnHover = false" @click="$_onClick">
    <div
      class="svg-icon icon-wrapper"
      :class="[
        iconType,
        computedColor,
        {spinner: spinner},
        {'icon-clickable': onClick},
        'icon',
        {'use-fill': isActualColorProvided},
      ]"
      :style="computedSize"
      v-html="getIcon(type)"
    ></div>

    <s-tooltip v-if="!isEmpty(tooltipContent)" position="bottom" :target="'#' + uID">
      {{ tooltipContent }}
    </s-tooltip>
  </span>
</template>

<script>
  import {isEmpty, uniqId} from '@veasel/core/tools';
  import {onClick} from '@veasel/core/mixins';
  import {themeColorsList} from '@veasel/core/themes';
  import iconManifest from '../assets/iconManifest.mjs';
  import {getIconByType} from '../utils/getIconByType';
  import tooltip from '@veasel/base/tooltip';

  const ICONS = [...iconManifest.iconSet];

  const REM_BASELINE = 16;

  const COLORS = [
    'black',
    'white',
    'gray',
    'lightgray',
    'blue',
    'focus-blue',
    'lightblue',
    'red',
    'green',
    'orange',
    ...themeColorsList,
    null,
  ];

  /**
   * @param {string} val
   * @param {string[]} array
   * @param {string} prop
   * @return {boolean}
   */
  function validate(val, array, prop) {
    if (!array.includes(val) && val !== 'unset' && val.charAt(0) !== '#') {
      console.warn(`s-icon: Incorrect ${prop} prop value - ${val} - should be one of ${array.join(', ')}`);
    }

    return true;
  }

  export default {
    name: 's-icon',
    description: 'A simple icon as font.',
    components: {
      's-tooltip': tooltip,
    },
    mixins: [onClick()],
    props: {
      type: {
        description: 'The icon identifier.',
        type: String,
        values: ICONS,
        validator: (val) => validate(val, ICONS, 'type'),
        required: true,
      },
      size: {
        description: 'The required size of the icon',
        type: [Number, String],
        default: REM_BASELINE,
      },
      color: {
        description: 'The icon color.',
        type: String,
        default: 'black',
        values: COLORS,
        validator: (val) => validate(val, COLORS, 'color', this),
      },
      hoverColor: {
        description: 'The icon color on hover',
        type: String,
        default: '',
        values: ['', ...COLORS],
        validator: (val) => validate(val, ['', ...COLORS], 'hoverColor', this),
      },
      spinner: {
        description: 'A flag for the icon to spin.',
        type: Boolean,
        default: false,
      },
      tooltipContent: {
        description: 'Content for a tooltip',
        type: String,
        default: '',
      },
    },
    data() {
      return {
        uID: '',
        isOnHover: false,
      };
    },
    created() {
      this.uID = uniqId('uid-', 0);
    },
    computed: {
      iconType: function () {
        return 'icon-' + this.type;
      },
      computedColor: function () {
        // ignore so color from a wrapper can be inherited
        if (this.color === 'unset' || this.isActualColorProvided) {
          return;
        }
        if (this.isOnHover && this.hoverColor !== '') {
          return this.hoverColor;
        }
        return this.color;
      },
      computedSize: function () {
        const remSize = parseInt(this.size) / REM_BASELINE;
        return `width: ${remSize}rem`;
      },
      isActualColorProvided() {
        return this.color.charAt(0) === '#';
      },
    },
    methods: {
      getIcon(icon) {
        return getIconByType(icon);
      },
      isEmpty,
    },
  };
</script>

<style lang="scss">
  // spinning logic

  @keyframes anim-rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .spinner {
    display: inline-block;
    height: 1em;
    line-height: 1;
    animation: anim-rotate 2s infinite linear;
  }

  .spinner-steps-8 {
    animation: anim-rotate 1s infinite steps(8);
  }

  .spinner-steps-12 {
    animation: anim-rotate 1s infinite steps(12);
  }

  .svg-icon {
    margin: 0 auto;

    svg {
      display: block;
      width: 100%;
      height: 100%;
      background: transparent;
    }

    &.use-fill svg {
      fill: v-bind(color);
    }

    &.icon-clickable {
      cursor: pointer;
    }
  }
</style>
