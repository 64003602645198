import * as Sentry from '@sentry/vue';
import API from '@/services/api.js';

const appFunctionsMixin = {
  methods: {
    // Load initial values used throughout the application
    initialValues() {
      // get current user
      return (
        API.getCurrentUser()
          .then(() => {
            let promises = [
              API.getDataAttributes(),
              API.getReportConfig(),
              API.getCurrentUserTodos(),
              API.getSystemConfig(),
              API.getSessionExpiration(),
            ];
            if (
              this.$store.state.user.permissions &&
              this.$store.state.user.permissions.includes('view_reports_list')
            ) {
              promises.push(API.getReportTypes());
              promises.push(API.getRunningCalculators());
              promises.push(API.getServicesStatus());
            }
            return Promise.all(promises);
          })
          // get data types and schemas
          .then(() =>
            Promise.all([
              ...(this.$store.state.system.feature_flags &&
              this.$store.state.system.feature_flags.includes('denormalised_upload_only')
                ? [API.getFlatDataAttributes()]
                : []),
            ])
          )
          .then(() =>
            API.getSystemVersion().then((version) => {
              if (!window.location.host.includes('localhost')) {
                Sentry.getClient().getOptions().release = version;
              }
            })
          )
          .catch((err) => {
            console.error(err);
          })
      );
    },

    // Log the user out and redirect to the login screen
    logOut() {
      this.$store.dispatch('user/logout');
    },
  },
};

export default appFunctionsMixin;
