/* eslint-disable valid-jsdoc,require-jsdoc */

import patterns from './../../patterns';
import lodash from 'lodash';
import JSONCrushLib from './jsoncrush';
import chartRangeBuilder from './chartRangeBuilder';

const SUADE_STORAGE_PREFIX = 'suade-';

const uniqRequests = {};

const customEventsHandlers = {};

const DEFAULT_CURRENCY = 'GBP';

// xhr handling functions
function xhrStart(uniq) {
  uniqRequests[uniq] = true;
  if (typeof window.$slXHRLoader === 'object') {
    window.$slXHRLoader.startRequest(ongoingRequests());
  }
}

function xhrEnd(uniq) {
  delete uniqRequests[uniq];
  if (typeof window.$slXHRLoader === 'object') {
    window.$slXHRLoader.endRequest(ongoingRequests());
  }
}

/**
 * Generate an unique identifier with eventually a given prefix.
 * @param {String | Number} pr Prefix the unique identifier
 * @param {Number} dp Decimal places of the unique identifier (8 by default)
 * @return {String} New Unique Identifier
 */
export const uniqId = function (pr, dp = 8) {
  pr = pr || '';

  // If we are running jest tests, we don't want this to be random due to snapshot tests
  if (import.meta.env.JEST_WORKER_ID !== undefined && import.meta.env.slIgnoreJestExclusions !== 'true') {
    return pr + 'uniqId';
  }

  function seed(s, w) {
    s = parseInt(s, 10).toString(16);
    return w < s.length ? s.slice(s.length - w) : w > s.length ? new Array(1 + (w - s.length)).join('0') + s : s;
  }

  const result =
    pr +
    seed((Math.random() * 100000).toFixed(16).toString()) +
    seed(parseInt(new Date().getTime() / 1000, 10), 8) +
    seed(Math.floor(Math.random() * 0x75bcd15) + 1, 5);
  return result.replace(/\./g, '').slice(0, pr.length + dp);
};

/**
 * Encode a JS object as a string ready to be passed in a URL.
 * @param {Object} The string to encode
 * @return {String} The encoded string
 */
export const crush = function (obj) {
  return JSONCrushLib.crush(JSON.stringify(obj));
};

/**
 * Decode an encoded string back to JS object.
 * @param {String} The string to encode
 * @return {Object} The decoded object
 */
export const uncrush = function (string) {
  return JSON.parse(JSONCrushLib.uncrush(decodeURIComponent(string)));
};

/**
 * Convert an int in a readable file size.
 * @param {Number} bytes Number of bytes to convert
 * @return {String} Human readable string of bytes (eg 20 MB)
 */
export const sizeToBytes = function (bytes) {
  let output;
  if (bytes >= 1180591620717411303424) {
    output = (bytes / 1180591620717411303424).toFixed(2).toString() + ' ZB';
  } else if (bytes >= 1152921504606846976) {
    output = (bytes / 1152921504606846976).toFixed(2).toString() + ' EB';
  } else if (bytes >= 1125899906842624) {
    output = (bytes / 1125899906842624).toFixed(2).toString() + ' PB';
  } else if (bytes >= 1099511627776) {
    output = (bytes / 1099511627776).toFixed(2).toString() + ' TB';
  } else if (bytes >= 1073741824) {
    output = (bytes / 1073741824).toFixed(2).toString() + ' GB';
  } else if (bytes >= 1048576) {
    output = (bytes / 1048576).toFixed(2).toString() + ' MB';
  } else if (bytes >= 1024) {
    output = (bytes / 1024).toFixed(2).toString() + ' KB';
  } else if (bytes > 1) {
    output = bytes.toString() + ' bytes';
  } else if (bytes === 1) {
    output = bytes.toString() + ' byte';
  } else {
    output = '0 byte';
  }
  return output;
};

/**
 * Performs a deep comparison between two values to determine if they are equivalent.
 * @param {*} item1 Object or array to compare
 * @param {*} item2 Object or array to compare
 * @return {boolean} Results of the compare
 */
export const isDeepEqual = function (item1, item2) {
  return lodash.isEqual(item1, item2);
};

/**
 * Sort an object by key (alphabetically).
 * @param {Object} Object or Array to sort
 * @return {Object} Sorted object
 */
export const sortKeys = function (obj) {
  if (obj instanceof Array) {
    return obj.map(sortKeys);
  } else if (obj instanceof Object) {
    return Object.keys(obj)
      .sort()
      .reduce(function (result, key) {
        result[key] = sortKeys(obj[key]);
        return result;
      }, {});
  } else {
    return obj;
  }
};

/**
 * A sorting function to use to sort an array of version numbers. Shamelessly stolen from https://rixong.medium.com/javascript-sort-and-app-version-numbers-b295c6c06926
 * @param a {String} A version to compare with
 * @param b {String} Another version to compare with
 * @return {number}
 */
export const sortVersions = function (a, b) {
  if (a === b) {
    return 0;
  }
  const splitA = a.split('.');
  const splitB = b.split('.');
  const length = Math.max(splitA.length, splitB.length);
  for (let i = 0; i < length; i++) {
    // FLIP
    if (parseInt(splitA[i]) > parseInt(splitB[i]) || (splitA[i] === splitB[i] && isNaN(splitB[i + 1]))) {
      return 1;
    }
    // DONT FLIP
    if (parseInt(splitA[i]) < parseInt(splitB[i]) || (splitA[i] === splitB[i] && isNaN(splitA[i + 1]))) {
      return -1;
    }
  }
};

/**
 * Get an the differences between 2 objects in a third one.
 * @param {Object} First object to compare
 * @param {Object} Second object to compare
 * @return {Object} Third object containing the changes in plus and minus keys, the list of diffs, and the diffs count
 */
export const getDiff = function (_obj1, _obj2) {
  const obj1 = JSON.parse(JSON.stringify(sortKeys(_obj1)));
  const obj2 = JSON.parse(JSON.stringify(sortKeys(_obj2)));

  const changes = {plus: {}, minus: {}, count: 0, diffs: []};
  Object.keys(obj1).forEach((key) => {
    searchForDiffs(key, obj1, obj2, 'minus');
  });
  Object.keys(obj2).forEach((key) => {
    searchForDiffs(key, obj2, obj1, 'plus');
  });

  function searchForDiffs(path, o1, o2, type) {
    const value1 = getValueAtPath(o1, path, null);
    const value2 = getValueAtPath(o2, path, null);
    if (value1 instanceof Array) {
      value1.forEach((_o, index) => {
        searchForDiffs(path + '.' + index, o1, o2, type);
      });
    } else if (value1 instanceof Object) {
      Object.keys(value1).forEach((key) => {
        searchForDiffs(path + '.' + key, o1, o2, type);
      });
    } else {
      registerDiff(value1, value2, path, type);
    }
  }

  function registerDiff(value1, value2, path, type) {
    if (value1 !== value2) {
      setValueAtPath(changes[type], path, value1);
      changes.count++;
      changes.diffs.push({type: type, path: path, value: value1});
    }
  }

  return changes;
};

/**
 * Humanize an array ([1,2,3] => '1, 2, and 3')
 * @param {Array} array
 * @param {number} limit
 * @param {Boolean|String} line jump
 * @param {Boolean} and operator
 * @return {String}
 */
export const humanizeArray = function (array, limit = 10, lineJump = false, and = true) {
  const separator = lineJump === true ? '\n' : lineJump === false ? '' : lineJump;
  if (array.length === 0) {
    return '';
  } else if (array.length === 1) {
    return array[0];
  } else if (array.length <= limit) {
    return (
      array.slice(0, array.length - 1).join(', ' + separator) +
      ' ' +
      separator +
      (and ? 'and ' : '') +
      array.slice(array.length - 1, array.length)
    );
  } else if (limit === -1) {
    return array.slice(0, -1).join(', ' + separator) + ' ' + separator + (and ? 'and ' : '') + array.slice(-1);
  } else {
    return (
      array.slice(0, limit).join(', ' + separator) +
      ' ' +
      separator +
      'and ' +
      (array.length - limit) +
      ' other' +
      (array.length - limit > 1 ? 's' : '')
    );
  }
};

/**
 * Humanize an ordinal number (1 => '1st')
 * @param {number} number
 * @return {String}
 */
export const humanizeOrdinal = function (number = 0) {
  const ordinal = {
    one: 'st',
    two: 'nd',
    few: 'rd',
    many: 'th',
    zero: 'th',
    other: 'th',
  };
  const parser = new Intl.PluralRules('en-UK', {type: 'ordinal'});
  return number + ordinal[parser.select(number)];
};

/**
 * Convert a big number in a humanized number (12345 => 12,3K)
 * @param {Number|String} number
 * @param {Number} maxDigits
 * @return {String}
 */
export const humanizeNumber = function (num, maxDigits = 3) {
  if (isNaN(num)) {
    return num;
  }

  // Need to make sure calculations are done on a positive number.
  // The negative sign will be added back at the end.
  const sign = Math.sign(num);
  let number = Math.abs(num);

  let dotPosition = 0;
  let symbol = '';
  if (number < 1000) {
    return number.toString();
  } else if (number < 1000000) {
    number = String(number / 1000);
    dotPosition = number.indexOf('.');
    symbol = 'K';
  } else if (number < 1000000000) {
    number = String(number / 1000000);
    dotPosition = number.indexOf('.');
    symbol = 'M';
  } else if (number < 1000000000000) {
    number = String(number / 1000000000);
    dotPosition = number.indexOf('.');
    symbol = 'B';
  } else if (number < 1000000000000000) {
    number = String(number / 1000000000000);
    dotPosition = number.indexOf('.');
    symbol = 'T';
  }

  const formattedNumber =
    dotPosition > 0 && dotPosition < maxDigits ? number.slice(0, maxDigits + 1) : number.slice(0, maxDigits);

  return formattedNumber * sign + symbol;
};

/**
 * Convert a number in a localize number (12345 => 12,345.00)
 * @param {Number} options
 * @return {String}
 */
export const localizeNumber = function (value, locale = navigator.language, options = {}) {
  if (isNaN(value)) {
    return value;
  }
  return Intl.NumberFormat(locale, options).format(value);
};

/**
 * Add a css rule on hover for a given selector.
 * @param {String} selector Selector Query
 * @param {String} styleContent CSS to add
 */
export const addStyleForSelector = function (selector, styleContent) {
  const style = document.styleSheets[0];
  const styleSel = selector;
  if (style.insertRule) {
    // for modern, DOM-compliant browsers
    style.insertRule(styleSel + '{' + styleContent + '}', style.cssRules.length);
  } else {
    // for IE < 9
    style.addRule(styleSel, styleContent, -1);
  }
};

/**
 * Convert a color to a lighter or darker one.
 * @param {String} color Hex-color to change
 * @param {Number} amount Amount to lighten or darken the colour
 * @return {string} New color in hex format
 */
export const lightenDarkenColor = function (color, amount) {
  color = color.slice(1);
  const num = parseInt(color, 16);
  let r = (num >> 16) + amount;
  if (r > 255) {
    r = 255;
  } else if (r < 0) {
    r = 0;
  }
  let g = ((num >> 8) & 0x00ff) + amount;
  if (g > 255) {
    g = 255;
  } else if (g < 0) {
    g = 0;
  }
  let b = (num & 0x0000ff) + amount;
  if (b > 255) {
    b = 255;
  } else if (b < 0) {
    b = 0;
  }
  return '#' + (0x1000000 | (b | (g << 8) | (r << 16))).toString(16).substring(1);
};

/**
 * Convert color from rgb(255,255,255) to #ffffff format.
 * @param {string} color String of a colour in an rgb() format
 * @return {string} String of colour in an hex format eg #ffffff
 */
export const rgbToHex = function (color) {
  color = '' + color;

  if (color.charAt(0) === '#') {
    return color;
  }

  if (!color || color.indexOf('rgb') < 0 || color.indexOf('rgba') > -1) {
    console.warn('[Suade Lib]: Incorrect rgb colour provided to rgbToHex()');
    return '';
  }

  const nums = /(.*?)rgb\((\d+),\s*(\d+),\s*(\d+)\)/i.exec(color);
  const r = parseInt(nums[2], 10).toString(16);
  const g = parseInt(nums[3], 10).toString(16);
  const b = parseInt(nums[4], 10).toString(16);
  return '#' + ((r.length === 1 ? '0' + r : r) + (g.length === 1 ? '0' + g : g) + (b.length === 1 ? '0' + b : b));
};

/**
 * Convert color from #ffffff to rgb(255,255,255) format.
 * @param {string} hex Colour in Hex format
 * @return {string} Colour in rgb format
 */
export const hexToRgb = function (hex) {
  if (hex.charAt(0) === '#') {
    hex = hex.substr(1);
  }

  if (hex.length !== 6) {
    console.warn('[Suade Lib]: Incorrect hex colour provided to hexToRgb()');
    return '';
  }

  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return 'rgb(' + r + ',' + g + ',' + b + ')';
};

/**
 * Convert a percentage to a color for a given gradient array (or red to green by default).
 * @param {Number} percentage Numerical percentage
 * @param {Object} percentColors Optional Gradient Scale. A gradient of Red to Green is default
 * @return {string} Hex Colour
 */
export const getColorForPercentage = function (percentage, percentColors) {
  percentage = percentage / 100;
  percentColors = percentColors || [
    {pct: 0.0, color: {r: 251, g: 72, b: 72}},
    {pct: 0.5, color: {r: 240, g: 173, b: 78}},
    {pct: 1.0, color: {r: 92, g: 184, b: 92}},
  ];
  let i;
  for (i = 1; i < percentColors.length - 1; i++) {
    if (percentage <= percentColors[i].pct) {
      break;
    }
  }
  const lower = percentColors[i - 1];
  const upper = percentColors[i];
  const range = upper.pct - lower.pct;
  const rangePct = (percentage - lower.pct) / range;
  const pctLower = 1 - rangePct;
  const pctUpper = rangePct;
  const r = Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper);
  const g = Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper);
  const b = Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper);
  return '#' + (0x1000000 | (b | (g << 8) | (r << 16))).toString(16).substring(1);
};

/**
 * Check is JSON string is valid or not.
 * @param {String} json JSON String
 * @return {boolean} If the string is valid to be JSON or not
 */
export const isValidJSON = function (json) {
  try {
    JSON.parse(json);
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * Escape html tags from given string.
 * @param {String} html HTML you want to tags to be escaped from
 * @return {String} String of HTML escaped
 */
export const escapeHtml = function (html) {
  const wrappingDiv = document.createElement('div');
  wrappingDiv.textContent = html;
  return wrappingDiv.innerHTML;
};

/**
 * Get the real color of an element.
 * @param {String} el Selector string of the element you want the color from.
 * @param {String} property CSS property that you want.
 * @return {String} Color of the element, or 'none' if there isn't a colour to find.
 */
export const getComputedColor = function (el, property) {
  if (!el || el.length === 0 || !property) {
    return 'none';
  }
  const element = typeof el === 'string' ? document.querySelector(el) : el;
  if (element) {
    let ret = window.getComputedStyle(element).getPropertyValue(property);
    if (!colorVisible(ret)) ret = window.getComputedStyle(element).getPropertyValue(property);
    if (colorVisible(ret)) return ret;
    if (element.tagName.toLowerCase() !== 'body') return getComputedColor(element.parentNode, property);
  }
  return 'none';

  function colorVisible(colorStr) {
    return colorStr && !(colorStr.substr(0, 4) === 'rgba' && colorStr.substr(-2, 2) === '0)');
  }
};

/**
 * JSON stringify and remove circular references.
 * @param {object} object Object to stringify
 * @return {string | boolean} Stringified object, or a boolean false if the object is not valid.
 */
export const stringifySafe = function (object) {
  const cache = [];
  if (!object || object instanceof Event || object?.parentNode) {
    return false;
  }
  return JSON.stringify(object, function (_key, value) {
    if (typeof value === 'object' && value !== null) {
      if (cache.indexOf(value) !== -1) {
        // Circular reference found, discard key
        return;
      }
      // Store value in our collection
      cache.push(value);
    }
    return value;
  });
};

/**
 * Build a monetary formatted string.
 * @param {String | Number} number Number to format
 * @return {string} Monetary formatted number
 */
export const toCurrencyNumber = function (number, placeholder = '') {
  if (isNaN(number) || number === null) {
    return placeholder;
  }
  if (!number.toFixed) {
    number = parseFloat(number);
  }

  number = number.toFixed(2);
  const index = number.toString().indexOf('.');
  if (index !== -1) {
    return (
      number
        .toString()
        .slice(0, index)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + number.toString().slice(index)
    );
  } else {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
};

/**
 * Retrieve value in an object with a dotted path.
 * @param {Object} obj Object to get value from
 * @param {String} path Path to get value from, can be dotted.
 * @param {String} [defaultValue=] Value to return if it cannot find the required values. Default is an empty string.
 * @return {*} Found value, or defaultValue if nothing could be found.
 */
export const getValueAtPath = function (obj, path, defaultValue = '') {
  const value = ('' + path).split('.').reduce(function (acc, part) {
    return acc && acc[part];
  }, obj);
  return value !== null && value !== undefined ? value : defaultValue;
};

/**
 * Set a value in an object with a dotted path.
 * @param {Object} obj Object to set value to
 * @param {String} path Path to set the value, can be dotted
 * @param {*} value Value to set
 */
export const setValueAtPath = function (obj, path, value) {
  const pathArr = ('' + path).split('.');
  for (let i = 0; i < pathArr.length; i++) {
    const p = pathArr[i];
    if (!obj[p]) {
      obj[p] = {};
    }
    if (i === pathArr.length - 1) {
      obj[p] = value;
    }
    obj = obj[p];
  }
};

/**
 * Capitalize first letter of a string
 * @param {String} str String you want to capitalize
 * @return {* | String} The string now capitalized
 */
export const capitalizeFirst = function (str) {
  return str ? str[0].toUpperCase() + str.substring(1) : str;
};

/**
 * Converts a string to PascalCase
 * @param {String} s String you want to convert to PascalCase
 * @return {String} String in pascal case
 */
export const pascalCase = function (s) {
  s = s.replace(/([-_\s][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '').replace(' ', '');
  });
  return s.charAt(0).toUpperCase() + s.substr(1);
};

/**
 * Make a "banking value" string readable by "humans bankers".
 * @param {String} string String to humanize
 * @return {String} Humanized string
 */
export const humanizeString = function (string) {
  // if currency or country code, do nothing
  if (!string) {
    return string;
  }
  if (string === string.toUpperCase() && string.length <= 3) {
    return string;
  }
  // lower case all
  string = string.toLowerCase();
  // upper case first letter
  string = capitalizeFirst(string);
  // remove underscores;
  string = string.replace(/_/g, ' ');
  return string;
};

/**
 * Make a "banking value" string readable with each word capitalized.
 * @param {String} string String to humanize & capitalize
 * @return {String} Humanized & Capitalize string
 */
export const humanizeCapitalizeString = function (string) {
  // if currency or country code, do nothing
  if (!string) {
    return string;
  }
  if (string === string.toUpperCase() && string.length <= 3) {
    return string;
  }
  // lower case all
  string = string.toLowerCase();
  // remove underscores;
  string = string.replace(/_/g, ' ');
  // upper case first letter of each word, unless 'and' or 'by'
  string = string
    .split(' ')
    .filter((w) => w !== '')
    .map((word) => (['and', 'by'].includes(word) ? word : capitalizeFirst(word)))
    .join(' ');
  string = string
    .split('-')
    .filter((w) => w !== '')
    .map((word) => capitalizeFirst(word))
    .join('-');

  return string;
};

/**
 * Clone an object using a different pointer
 * @param {Object} value Object to clone
 * @return {Object} Cloned object
 */
export const clone = function (value) {
  if (typeof value === 'object') {
    return lodash.cloneDeep(value);
  } else {
    return value;
  }
};

/**
 * should extend n Objects recursively
 * @num {Number} The number
 * @return {String} Full number formatted as a String
 */
export const deepExtend = (...args) => {
  if (args.length === 0) return {};
  const result = Array.isArray(args[0]) ? [] : {};
  args.forEach((arg) => {
    for (const key in arg) {
      if (Object.prototype.hasOwnProperty.call(arg, key)) {
        const item = arg[key];
        if (item && typeof item === 'object' && !Array.isArray(item)) {
          result[key] = deepExtend(result[key], item);
        } else {
          result[key] = item;
        }
      }
    }
  });
  return result;
};

/**
 * Extend an object with one or many other objects, into a new object.
 * @param {...Object} object Objects to merge
 * @return {Object} Extended Object
 */
export const extend = function (...toExtend) {
  if (typeof toExtend[0] !== 'object') {
    return {};
  }
  return deepExtend({}, ...toExtend);
};

/**
 * Get value at path ensuring it exists, if not, return false.
 * @param {Object} obj Object to get value
 * @param {String} path Path of the value. Can be dotted.
 * @return {*} Retrieved value. If there is no value found, then returned false
 */
export const safeGet = function (obj, path) {
  if (obj === undefined) {
    return false;
  }
  const array = path.split('.');
  return extractValue(obj, array);

  function extractValue(_obj, _array) {
    const arrayLength = _array.length;
    const subObj = _obj[_array[0]];
    const _path = _array.splice(1);
    if (subObj === undefined) {
      return false;
    } else if (arrayLength > 1) {
      return extractValue(subObj, _path);
    } else {
      return subObj;
    }
  }
};

/**
 * Get value at path from local storage.
 * @param {String} path Path to item in storage
 * @param {String} prefix Prefix of Storage Item
 * @return {*} Returns false if not found, other will return the item. Will also convert JSON to object
 */
export const getStorage = function (path, prefix = SUADE_STORAGE_PREFIX) {
  if (localStorage[prefix + path] === undefined) {
    return false;
  } else {
    const result = localStorage[prefix + path];
    return isValidJSON(result) ? JSON.parse(result) : result;
  }
};

/**
 * Set value at path in local storage.
 * @param {String} path Path to item in storage
 * @param {*} obj Item to store
 * @param {String} prefix Prefix of Storage Item
 */
export const setStorage = function (path, obj, prefix = SUADE_STORAGE_PREFIX) {
  if (obj === undefined) {
    // Don't want to store undefined in local storage, as it will be read back as a string
    obj = null;
  }
  localStorage[prefix + path] = typeof obj === 'object' ? JSON.stringify(obj) : obj;
};

/**
 * Get dict value at path in local storage.
 * @param {String} path Path to item in storage
 * @param {String} dictPath Path of the property to update inside object
 * @param {String} prefix Prefix of Storage Item
 */
export const getStorageDict = function (path, dictPath, prefix = SUADE_STORAGE_PREFIX) {
  const obj = getStorage(path, prefix) || {};
  return getValueAtPath(obj || {}, dictPath);
};

/**
 * Set dict value at path in local storage.
 * @param {String} path Path to item in storage
 * @param {String} dictPath Path of the property to update inside object
 * @param {*} value Value to store
 * @param {String} prefix Prefix of Storage Item
 */
export const setStorageDict = function (path, dictPath, value, prefix = SUADE_STORAGE_PREFIX) {
  const obj = getStorage(path, prefix) || {};
  setValueAtPath(obj || {}, dictPath, value);
  localStorage[prefix + path] = JSON.stringify(obj);
};

/**
 * Remove value at path in local storage.
 * @param {String} path Path to item to remove
 * @param {String} prefix Prefix of Storage Item
 */
export const removeStorage = function (path, prefix = SUADE_STORAGE_PREFIX) {
  localStorage.removeItem(prefix + path);
};

/**
 * Return true if the value at path from local storage exists, false otherwise
 * @param {String} path Path to item to find
 * @param {String} prefix Prefix of Storage Item
 * @return {boolean} If the item is found, then return true, else return false
 */
export const existStorage = function (path, prefix = SUADE_STORAGE_PREFIX) {
  return localStorage[prefix + path] !== undefined;
};

/**
 * Get cookie at path
 * @param {String} name Cookie Name
 * @return {*} Cookie contents
 */
export const getCookie = function (name) {
  const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : null;
};

/**
 * Set cookie at path, value, and expiration (in days)
 * @param {String} name Cookie Name
 * @param {String} value Value to save
 * @param {Number} days Number of days before the cookie expires
 */
export const setCookie = function (name, value, days) {
  days = days || 30;
  const d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
  const isSecure = window.location.protocol === 'https:';
  document.cookie = name + '=' + value + ';path=/;expires=' + d.toUTCString() + (isSecure ? '; Secure' : '');
};

/**
 * Delete cookie at path
 * @param {string} name Cookie Name to delete
 */
export const deleteCookie = function (name) {
  setCookie(name, '', -1);
};

/**
 * Debounce a function with a delay
 * @param {Function} fn Function to fire after a delay
 * @param {Number} delay Milliseconds to delay the function when fired
 * @return {Function} A function ready to be used
 */
export const debounce = function (fn, delay) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => fn(...args), delay);
  };
};

/**
 * Throttle a function with a delay.
 * @param {Function} fn Function to fire after a delay
 * @param {Number} delay Milliseconds to delay the function when fired
 * @return {Function} A function ready to be used
 */
export const throttle = function (fn, delay) {
  let lastCall = 0;
  let timeout;
  return function (...args) {
    const now = new Date().getTime();
    if (lastCall === 0 || now > lastCall + delay) {
      lastCall = now;
      fn(...args);
    } else {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        lastCall = now;
        fn(...args);
      }, lastCall + delay - now);
    }
  };
};

/**
 * Check if defined
 * @param {*} obj Variable to check
 * @return {Boolean} Returns true or false depending if it is defined
 */
export const isDefined = function (obj) {
  return obj !== undefined;
};

/**
 * Execute function while catching eventual errors.
 * @param {Function} fn Function to fire
 * @param {String} msg Console.warn message to log if the function throws an exception
 * @param {*} defaultValue A value to return if the function cannot complete can throw an exception
 * @return {function(...[*]=)}
 */
export const catchify = function (fn, msg, defaultValue) {
  return function () {
    try {
      if (typeof fn === 'function') {
        return fn.apply(this, arguments);
      } else {
        return fn;
      }
    } catch (err) {
      if (msg) {
        console.warn('[Suade Lib] ' + msg || '[Suade Lib] Error caught while catchifying a function:' + fn);
      }
      console.warn('[Suade Lib] ', err);
      return defaultValue !== undefined ? defaultValue : false;
    }
  };
};

/**
 * Parse a number with min and max boundaries.
 * @param {Number} min Smallest amount the number can be
 * @param {Number} toParse Number to check the min/max against
 * @param {Number} max Largest amount the number can be
 * @return {Number} Result from checking the numbers
 */
export const minMax = function (min, toParse, max) {
  return Math.min(Math.max(min, toParse), max);
};

/**
 * retrieve all DOM parents elements that scrolls.
 * @param {Element} elem Element to start finding scrolling elements from.
 * @param {Element[]} list=[] Previously found scrollable elements
 * @return {Element[]|Document} Array of elements that are scrollable, or document if none were found.
 */
export const getAllScrollParents = function (elem, list) {
  if (list === undefined) {
    list = [];
  }
  if (elem === null) {
    return list || document;
  }
  if (elem.scrollHeight > elem.clientHeight || elem.scrollWidth > elem.clientWidth) {
    list.push(elem);
    return getAllScrollParents(elem.parentElement, list);
  } else {
    return getAllScrollParents(elem.parentElement, list);
  }
};

/**
 * Add the given string to the OS clipboard, making it ready to paste.
 * @param {String} str String to copy to clipboard
 * @return {Boolean} If operation was successful or not.
 */
export const copyStringToClipboard = function (str) {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  if (document.execCommand) {
    document.execCommand('copy');
    document.body.removeChild(el);
    return true;
  }
  document.body.removeChild(el);
  return false;
};

/**
 * Smoothly scroll to an selected element using CSS acceleration.
 * @param {String} selector Selector to find and scroll into view
 * @param {String} block Defines vertical alignment. One of start, center, end, or nearest. Defaults to start.
 * @return {Boolean} If element was found and element is now in view
 */
export const smoothScrollTo = function (selector, block = 'start') {
  const el = document.querySelector(selector);
  if (el) {
    setTimeout(function () {
      el.scrollIntoView({
        behavior: 'smooth',
        block: block,
      });
    });
    return true;
  } else {
    return false;
  }
};

/**
 * Transforms an object into a url formatted data string. Suitable for preparing a GET request
 * @param {Object} data Object to transform into a url formatted data
 * @return {String} URL Formatted data String
 */
export const parseDataInUrl = function (data) {
  let dataUrl = '?';

  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      if (Array.isArray(data[key])) {
        data[key].forEach((item) => (dataUrl += `${key}=${item}&`));
      } else if (data[key] !== undefined) {
        dataUrl += `${key}=${data[key]}&`;
      }
    }
  }

  return dataUrl.slice(0, -1);
};

/**
 * Send an HTTP request over the network.
 * @param {'GET'|'POST'} method Request Method
 * @param {String} url URL to request to
 * @param {Object} data Data to send to server
 * @param {Object} options Request options. Options starting without an underscore are set as request header options
 * @returns {Promise}
 */

export const http = function (method, url, data = {}, options = {}) {
  const uniq = uniqId('request');
  let dataToSend;
  xhrStart(uniq);
  return new Promise(function (resolve, reject) {
    const req = new XMLHttpRequest();
    if (method === 'GET' && data) {
      url += parseDataInUrl(data);
    }
    if (options.timeout) {
      req.timeout = options.timeout;
    }
    req.open(method, url);
    // handle headers
    if (method !== 'GET' && !options._isFileUpload) {
      req.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    }
    for (const key in options) {
      // options starting with '_' are not sent to the backend otherwise it is header options
      if (key.indexOf('_') !== 0 && options[key]) {
        req.setRequestHeader(key, options[key]);
      }
    }
    // handle data
    if (options._isFileDownload) {
      req.responseType = 'arraybuffer';
      dataToSend = JSON.stringify(data);
    } else if (options._isFileUpload) {
      dataToSend = new FormData();
      for (const key in data) {
        dataToSend.append(
          key,
          key === 'file' || key === 'photo' || (options._FileUploadKeys || []).includes(key)
            ? new File([data[key].content], data[key].name, {type: data[key].type})
            : JSON.stringify(data[key])
        );
      }
    } else {
      dataToSend = JSON.stringify(data);
    }
    // handle response
    req.onload = function () {
      xhrEnd(uniq);
      if (req.status.toString().indexOf('2') === 0) {
        resolve(req);
      } else {
        reject({status_code: req.status, error: req.response, url});
      }
    };
    // handle error
    req.onerror = function (err) {
      xhrEnd(uniq);
      reject({status_code: req.status, error: err});
    };
    // handle timeout
    req.ontimeout = function () {
      xhrEnd(uniq);
      reject({
        status_code: 408,
        error: JSON.stringify({type: 'timeout', title: 'Timeout', message: 'Request took too long!'}),
      });
    };
    req.send(dataToSend);
  });
};

/**
 * Retrieve the number of ongoing request sent via HTTP function.
 * @return {number}
 */
export const ongoingRequests = function () {
  return Object.keys(uniqRequests).length;
};

/**
 * Get all DOM elements attributes.
 * @param {Element} node Element to get attributes from
 * @return {Object} Object with keys as names,
 */
export const getAttributes = function (node) {
  const attrs = {};
  for (let i = 0; i < node.attributes.length; i++) {
    attrs[node.attributes[i].name] = node.attributes[i].value;
  }
  return attrs;
};

/**
 * Test if a value match a given pattern.
 * @param {*} value Value to check.
 * @param {String|Function} pattern Either a function to run against, a RegExp String, or a key in the patterns.js file
 * @return {boolean} Returns a true/false based on if the value matches the pattern provided
 */
export const valueMatchPattern = function (value, pattern) {
  let isMatching = true;

  let testPattern;

  if (pattern) {
    if (typeof pattern === 'function') {
      testPattern = pattern;
    } else if (patterns[pattern]) {
      testPattern = patterns[pattern];
    } else {
      testPattern = new RegExp(pattern, 'i');
    }
  }

  if (testPattern) {
    if (value !== undefined) {
      isMatching = typeof testPattern == 'function' ? testPattern(value) : (value + '').match(testPattern);
    }
  }
  return isMatching;
};

/**
 * An empty function.
 *
 */
export const noop = function () {};

/**
 * Check if element is a DOM element.
 * @param {Object} object Object to check
 * @return {Boolean} Return true/false depending on if the element is a DOM element
 */
export const isDOM = function (object) {
  return object instanceof Node;
};

/**
 * Return self or first parent node matching a given selector.
 * @param {Element} object Element to start with in finding the given selector
 * @param {String} selector The Selector to find the closest element
 * @return {Element|Boolean} The closest Element to the original element, or false if the object provided is not a DOM
 */
export const closestOrSelf = function (object, selector) {
  if (isDOM(object)) {
    if (object.matches(selector)) {
      return object;
    } else {
      return object.closest(selector);
    }
  } else {
    return false;
  }
};

/**
 * Return an array of all parent nodes matching a given selector.
 * @param {Element|Node} object Element to start searching for a selector
 * @param {String} selector Selector to search for
 * @param {Element[]=} intermediateResults Previous Search
 * @return {Element[]|boolean} An array of Elements matching the selector, or false if none were found
 */
export const allMatchingParents = function (object, selector, intermediateResults) {
  if (isDOM(object)) {
    const results = intermediateResults || [];
    if (object.closest(selector)) {
      results.push(object.closest(selector));
      return allMatchingParents(object.closest(selector).parentNode, selector, results);
    } else {
      return results;
    }
  } else {
    return false;
  }
};

/**
 * Clone the given DOM element
 * @param {HTMLElement} object Element to clone
 * @return {HTMLElement|Object} Cloned Object
 */
export const cloneDOM = function (object) {
  if (isDOM(object)) {
    return object.cloneNode();
    // let cloned = {};
    // cloned.offsetTop = object.offsetTop;
    // cloned.offsetLeft = object.offsetLeft;
    // cloned.offsetHeight = object.offsetHeight;
    // cloned.offsetWidth = object.offsetWidth;
    // for (let i in object.attributes) {
    //   if(object.attributes.hasOwnProperty(i)) {
    //     const attr = object.attributes[i];
    //     if (!isNaN(i) && attr && attr.nodeName) {
    //       let key = attr.nodeName;
    //       cloned[key] = object.getAttribute(key);
    //     }
    //   }
    // }
    // return cloned;
  } else {
    return JSON.parse(JSON.stringify(object));
  }
};

/**
 * Convert an dictionary to a list of objects using a key if given, or 'id'.
 * @param {Object} object Object to convert
 * @param {String} key Key or ID to use
 * @return {[]|Array} Converted dictionary
 */
export const toArray = function (object, key) {
  if (object instanceof Array) {
    return object;
  }
  const a = [];
  for (const i in object) {
    if (Object.prototype.hasOwnProperty.call(object, i)) {
      if (typeof object[i] === 'object' && i !== '$promise') {
        a.push(object[i]);
        a[a.length - 1][key || 'id'] = i;
      } else if (!i.startsWith('$') && typeof object[i] !== 'function') {
        a.push({key: i, value: object[i], index: a.length});
      }
    }
  }
  return a;
};

/**
 * Creates a searchable string of every value of the object.
 * @param {Object} object Object to stringify
 * @return {String} Stringified object
 */
export const stringifyValuesOnly = function (object) {
  let string = '';
  for (const i in object) {
    if (Object.prototype.hasOwnProperty.call(object, i)) {
      if (typeof object[i] === 'object') {
        string += ' ' + stringifyValuesOnly(object[i]);
      } else {
        const stringifyValue = JSON.stringify(object[i]);
        // handle case if object[i] is a function
        if (stringifyValue) {
          string += ' ' + stringifyValue.replace(/"/g, '');
        }
      }
    }
  }
  return string;
};

/**
 * Get milliseconds contained in the given time bucket (hour, day, week, ...).
 * @param bucket {'century'|'decade'|'year'|'semi-year'|'quarter'|'month'|'week'|'day'|'hour'|'minute'|'second'} Time period to return in milliseconds
 * @return {number} Milliseconds of the time bucket
 */
export const timeIn = function (bucket) {
  bucket = bucket.toLowerCase();
  if (bucket.indexOf('cent') === 0) {
    return 100 * 365 * 24 * 3600 * 1000;
  } else if (bucket.indexOf('dec') === 0) {
    return 10 * 365 * 24 * 3600 * 1000;
  } else if (bucket.indexOf('y') === 0) {
    return 365 * 24 * 3600 * 1000;
  } else if (bucket.indexOf('sem') === 0) {
    return 184 * 24 * 3600 * 1000;
  } else if (bucket.indexOf('qua') === 0) {
    return 92 * 24 * 3600 * 1000;
  } else if (bucket.indexOf('mo') === 0) {
    return 31 * 24 * 3600 * 1000;
  } else if (bucket.indexOf('w') === 0) {
    return 7 * 24 * 3600 * 1000;
  } else if (bucket.indexOf('d') === 0) {
    return 24 * 3600 * 1000;
  } else if (bucket.indexOf('h') === 0) {
    return 3600 * 1000;
  } else if (bucket.indexOf('m') === 0) {
    return 60 * 1000;
  } else if (bucket.indexOf('s') === 0) {
    return 1000;
  }
};

/**
 * Decode buffer array into string.
 * @param {ArrayBuffer} data ArrayBuffer object to convert
 * @return {string} Array buffer into a string
 */
export const decodeBufferArray = function (data) {
  let response;
  if ('TextDecoder' in window) {
    // Decode as UTF-8
    const dataView = new DataView(data);
    const decoder = new TextDecoder('utf8');
    response = decoder.decode(dataView);
  } else {
    // Fallback decode as ASCII
    response = String.fromCharCode.apply(null, new Uint8Array(data));
  }
  return response;
};

/**
 * Remove HTML tags from a string and only return the text.
 * @param {String} html HTML String that needs to be stripped
 * @return {String} Text content without HTML
 */
export const stripHtml = function (html) {
  const temporalDivElement = document.createElement('div');
  temporalDivElement.innerHTML = html;
  return temporalDivElement.textContent || temporalDivElement.innerText || '';
};

/**
 * Return the intersection of the values in each nested array for a given array of arrays.
 * @param {[][]} arrayOfArray Array of the 2 arrays to find intersections
 * @return {any[]} Array of intersections
 */
export const arrayIntersection = function (arrayOfArray) {
  if (arrayOfArray[0] === undefined || arrayOfArray[0] === null) {
    return [];
  }
  return arrayOfArray[0].filter((refValue) => {
    return arrayOfArray.slice(1).every((array) => array.includes(refValue));
  });
};

/**
 * Return the non intersection (union minus intersection) of the values in each nested array for a given array of arrays.
 * @param {[][]} arrayOfArray Array of the 2 arrays to find intersections
 * @return {any[]} Array of non intersections
 */
export const arraySymmetricDifference = function (arrayOfArray) {
  const union = arrayUnion(arrayOfArray);
  const intersection = arrayIntersection(arrayOfArray);
  return union.filter((item) => !intersection.includes(item));
};

/**
 * Return the union of the values in each nested array for a given array of arrays.
 * @param {[][]} arrayOfArray Arrays to union
 * @return {any[]} Unionised array
 */
export const arrayUnion = function (arrayOfArray) {
  return [
    ...new Set(
      arrayOfArray.reduce((acc, array) => {
        return acc.concat(array);
      })
    ),
  ];
};

/**
 * Apply a filtering function on an object's entries and return a filtered object
 * @param {{}} object to filter
 * @param {Function} filtering function
 * @return {{}} filtered array
 */
export const filterObject = function (obj, predicate) {
  return Object.fromEntries(Object.entries(obj).filter(predicate));
};

/**
 * Convert a number to letters (excel column like)
 * @param {Number} num Number to convert
 * @return {String} Converted number to letters
 */
export const numberToLetters = function (num) {
  let ret = '';
  for (let a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
    ret = String.fromCharCode((num % b) / a + 65) + ret;
  }
  return ret;
};

/**
 * Convert letters (excel column like) to a number
 * @param {String} letters Letters to convert
 * @return {Number} Number
 */
export const lettersToNumber = function (letters) {
  return letters.split('').reduce((r, a) => r * 26 + parseInt(a, 36) - 9, 0);
};

/**
 * Convert a JS object to code as a string nicely formatted
 * @param {Object} obj Object to nicely format
 * @return {String} Object in a nicely readable string
 */
export const valuesToCode = function (obj) {
  return JSON.stringify(
    obj,
    (_key, value) => {
      if (typeof value === 'function') {
        return '[ function() {} ]';
      } else {
        return value;
      }
    },
    2
  );
};

/**
 * Extract currency multiplier ("GBP => 100")
 * @param {String} currency Currency to get, eg GBP, EUR
 * @return {Number} Decimal Multiplier for the provided currency
 */
export const getDecimalMultiplierForCurrency = function (currency) {
  const opts = {
    style: 'currency',
    currency: currency || DEFAULT_CURRENCY,
  };
  const decimals = (Intl.NumberFormat('en-UK', opts).format(0.1111111111111111).split('.')[1] || []).length;
  return Math.pow(10, decimals);
};

/**
 * Check the validity of a currency
 * @param {String} currency Currency to validate, eg GBP, EUR
 * @return {Boolean} flag for the currency's validity
 */
export const validateCurrency = function (currency) {
  try {
    Intl.NumberFormat('en-UK', {currency: currency}).format(1);
    return true;
  } catch {
    return false;
  }
};

/**
 * Format a number into a localized currency string (123456 => "£123,456.00")
 * @param {Number} value Number to Format
 * @param {String} currency Currency Code to format with
 * @param {String} locale Locale to format the string. Default is current browser
 * @param {Object} options Optional options array to pass additional options to intl.NumberFormat function
 * @return {string} Formatted Number
 */
export const formatToLocaleNumber = function (value, currency, locale = navigator.language, options = {}) {
  if (isNaN(value)) {
    return ' ';
  }
  const opts = {
    style: 'currency',
    currency: currency || DEFAULT_CURRENCY,
    currencyDisplay: 'code',
    useGrouping: true,
    ...options,
  };
  let result = Intl.NumberFormat(locale, opts)
    .format(value)
    .replace(/-(\D+\s?)/g, (_a, g1) => g1 + '-')
    .replace(/\s/g, ' ');
  if (options.hideCurrencyCode || !currency) {
    result = result.replace(/[A-Z]* /g, '');
  }
  return result;
};

/**
 * Reverse currency number localization to get the original number as a float
 * @param {String} stringNumber Formatted Number to convert to an integer
 * @param {String} locale Optional locale. If none is specified, then browser language is used instead.
 * @return {Number} Integer from provided number
 */
export const parseLocaleNumber = function (stringNumber, locale = navigator.language) {
  const tsNum = 1111;
  const dsNum = 1.1;
  const thousandSeparator = tsNum.toLocaleString(locale).replace(/1/g, '');
  const decimalSeparator = dsNum.toLocaleString(locale).replace(/1/g, '');

  return parseFloat(
    stringNumber
      .replace(/([^0-9,.-])/g, '')
      .replace(new RegExp(thousandSeparator ? '\\' + thousandSeparator : '', 'g'), '')
      .replace(new RegExp(decimalSeparator ? '\\' + decimalSeparator : ''), '.')
  );
};

/**
 * Format a integer number of minor currency (cents) into a localized currency string (123456 => "£1,234.56")
 * @param {Number} value Number to format
 * @param {String=GBP} currency Currency to format to. Default is
 * @param {String} locale Optional locale. If none is specified, then browser language is used instead.
 * @param {Object} options Optional options array to pass additional options to intl.NumberFormat function
 * @returns {String} Formatted number
 */
export const formatToLocaleNumberFromInteger = function (
  value,
  currency,
  locale = navigator.language,
  options = {},
  exponentDict = {}
) {
  if (isNaN(value)) {
    return ' ';
  }
  const opts = {
    style: 'currency',
    currency: currency || DEFAULT_CURRENCY,
    currencyDisplay: 'code',
    useGrouping: true,
    ...options,
  };
  if (exponentDict[currency] && exponentDict[currency].iso === null) {
    if (getDecimalMultiplierForCurrency(currency) !== Math.pow(10, exponentDict[currency].exponent)) {
      value = value * (getDecimalMultiplierForCurrency(currency) / Math.pow(10, exponentDict[currency].exponent));
    }
  }
  let result = Intl.NumberFormat(locale, opts)
    .format(value / getDecimalMultiplierForCurrency(currency))
    .replace(/-(\D+\s?)/g, (_a, g1) => g1 + '-')
    .replace(/\s/g, ' ');
  if (options.hideCurrencyCode || !currency) {
    result = result.replace(/[A-Z]* /g, '');
  }
  return result;
};

/**
 * Reverse currency number localization to get the original number as an integer of minor currency (cents)
 * @param {String} stringNumber Formatted number to convert to an integer
 * @param {String} currency Currency to format from
 * @param {String} locale Optional locale. If none is specified, then browser language is used instead.
 * @return {Number} Integer from provided number
 */
export const parseLocaleNumberToInteger = function (
  stringNumber,
  currency = DEFAULT_CURRENCY,
  locale = navigator.language
) {
  const thousandSeparator = (1111).toLocaleString(locale).replace(/1/g, '');
  const decimalSeparator = (1.1).toLocaleString(locale).replace(/1/g, '');

  return Math.round(
    ('' + stringNumber)
      .replace(/([^0-9,.-])/g, '')
      .replace(new RegExp(thousandSeparator ? '\\' + thousandSeparator : '', 'g'), '')
      .replace(new RegExp(decimalSeparator ? '\\' + decimalSeparator : ''), '.') *
      getDecimalMultiplierForCurrency(currency)
  );
};

/**
 * Create an inline web worker processing the given function, using accessible dependencies
 * @param {Function} fn Function
 * @param {String[]} dep Array of file names to import as importScripts
 * @return {Worker} Web Worker
 */
export const createWorker = function (fn, dep = []) {
  let dependencies = '';
  for (const i in dep) {
    dependencies += 'importScripts(' + dep[i] + ');\n';
  }
  const blob = new Blob([dependencies + '\nconst imports = this;\n\nself.onmessage = ', fn.toString()], {
    type: 'text/javascript',
  });
  const url = URL.createObjectURL(blob);
  return new Worker(url);
};

/**
 * Checks if the variable is empty in any way. Being empty includes being undefined, null, or no length to the variable
 * @param {*} varToCheck variable to check
 * @return {boolean} If the variable is empty or not
 */
export const isEmpty = (varToCheck) => {
  if (Array.isArray(varToCheck)) {
    return varToCheck.length === 0;
  }

  if (isDeepEqual(varToCheck, {})) {
    return true;
  }

  return typeof varToCheck === 'undefined' || varToCheck === '' || varToCheck === null || varToCheck === undefined;
};

/**
 * Flattens a object and disregards keys
 * @param {Object} obj Object to flatten
 * @return {[]} Flatten object in an array
 */
export const flattenObject = (obj) => {
  const results = [];
  Object.keys(obj).forEach((key) => {
    if (Array.isArray(obj[key])) {
      results.push(...flattenArray(obj[key]));
    } else if (typeof obj[key] === 'object') {
      results.push(...flattenObject(obj[key]));
    } else {
      results.push(obj[key]);
    }
  });
  return results;
};

/**
 * Flattens an array (and objects in an array) and disregards keys
 * @param {[]} arr Array to flatten
 * @return {[]} Flatten array in an array
 */
export const flattenArray = (arr) => {
  const results = [];
  arr.forEach((item) => {
    if (Array.isArray(item)) {
      results.push(...flattenArray(item));
    } else if (typeof item === 'object') {
      results.push(...flattenObject(item));
    } else {
      results.push(item);
    }
  });
  return results;
};

/**
 * Emit a custom event
 * @param {String} The event name
 * @param {*} The event data
 * @param {Function} The callback after action
 */
export const emitEvent = (event, data, callback = () => {}) => {
  const eventName = 'custom_event_' + event;
  const callbackName = eventName + '_callback';
  document.removeEventListener(callbackName, customEventsHandlers[callbackName]);
  customEventsHandlers[callbackName] = function () {
    callback();
  };
  document.addEventListener(callbackName, customEventsHandlers[callbackName], {once: true});
  document.dispatchEvent(new CustomEvent(eventName, {detail: data}));
};

/**
 * Listen a custom event
 * @param {String} The event name
 * @param {Function} Action
 */
export const onEvent = (event, action = () => {}) => {
  const eventName = 'custom_event_' + event;
  const callbackName = eventName + '_callback';
  document.removeEventListener(eventName, customEventsHandlers[eventName]);
  customEventsHandlers[eventName] = function (data) {
    if (action.length > 1) {
      action(data.detail, () => {
        document.dispatchEvent(new CustomEvent(callbackName));
      });
    } else {
      action(data.detail);
      document.dispatchEvent(new CustomEvent(callbackName));
    }
  };
  document.addEventListener(eventName, customEventsHandlers[eventName], {once: true});
};

/**
 * Converts ISO date to a short style date
 * @param {String} isoDate ISO Date
 * @param {String | null} locale Locale to use. If none is provided, then will look for one set at local storage.
 * @return {String} Formatted date in DD-MM-YYYY style
 */
export const isoToHumanDateShort = (isoDate, locale = null) => {
  if (isEmpty(isoDate)) {
    return '';
  }
  const date = Date.parse(isoDate);
  locale = isEmpty(locale) ? getStorage('locale') : locale;
  locale = locale === false ? 'en-GB' : locale;

  const dateArray = new Intl.DateTimeFormat(locale, {dateStyle: 'short', timeZone: 'UTC'}).formatToParts(date);
  return dateArray[0].value + '-' + dateArray[2].value + '-' + dateArray[4].value;
};

/**
 * Converts ISO date to a medium style date
 * @param {String} isoDate ISO Date
 * @param {String | null} locale Locale to use. If none is provided, then will look for one set at local storage.
 * @return {String} Formatted date in Mar 21, 2021 style
 */
export const isoToHumanDateMedium = (isoDate, locale = null) => {
  if (isEmpty(isoDate)) {
    return '';
  }

  const date = Date.parse(isoDate);
  locale = isEmpty(locale) ? getStorage('locale') : locale;
  locale = locale === false ? 'en-GB' : locale;
  const dateArray = new Intl.DateTimeFormat(locale, {dateStyle: 'medium', timeZone: 'UTC'}).formatToParts(date);
  return dateArray[2].value + ' ' + dateArray[0].value + ', ' + dateArray[4].value;
};

/**
 * Converts ISO date to a long style date
 * @param {String} isoDate ISO Date
 * @param {String | null} locale Locale to use. If none is provided, then will look for one set at local storage.
 * @return {String} Formatted date in March 21, 2021 style
 */
export const isoToHumanDateLong = (isoDate, locale = null) => {
  if (isEmpty(isoDate)) {
    return '';
  }
  const date = Date.parse(isoDate);
  locale = isEmpty(locale) ? getStorage('locale') : locale;
  locale = locale === false ? 'en-GB' : locale;
  const dateArray = new Intl.DateTimeFormat(locale, {dateStyle: 'long'}).formatToParts(date);
  return dateArray[2].value + ' ' + dateArray[0].value + ', ' + dateArray[4].value;
};

/**
 * Download a file
 * @filename {String} isoDate ISO Date
 * @param {String | null} locale Locale to use. If none is provided, then will look for one set at local storage.
 * @return {String} Formatted date in March 21, 2021 style
 */
export const downloadFile = (filename, content) => {
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

/**
 * Format a Number into a string ensuring there is no scientific notation
 * @param {Number} num The number
 * @return {String} Full number formatted as a String
 */
export const fullNumber = (num) => {
  const realNum = +num;
  if (!isNaN(realNum)) {
    const match = /(\d)(?:\.(\d+))?e(\+|-)(\d+)/.exec(realNum + '');
    if (match) {
      const end = match[2] || '';
      const startAndEnd = match[1] + end;
      const digits = +match[4];
      num =
        match[3] === '+'
          ? startAndEnd + Array(digits + 1 - end.length).join('0')
          : '0.' + Array(digits).join('0') + startAndEnd;
    }
    return num + '';
  }
};

/**
 * Clamp a value between two other values.
 * Inspired from https://github.com/hughsk/clamp
 * @param value {int} Number to evaluate
 * @param min {int} Min number in range
 * @param max {int} Max Number in range
 * @returns {int}  Returns value, if it is between a and b. Otherwise, returns the number it's gone past.
 */
export const clamp = (value, min, max) => {
  return min < max ? (value < min ? min : value > max ? max : value) : value < max ? max : value > min ? min : value;
};

/**
 * Build domain data for a given set of domains
 * @param domains {Object} An Object containing the name {String} and the data {Object} of the domain
 * @returns {Array}  Returns an array of objects containing meta data about the domain and the domain itself or a reference to the domain that should be used.
 */
export const consolidateDomains = (domains) => chartRangeBuilder(domains);
