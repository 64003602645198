<template>
  <section class="time-picker">
    <div class="time-picker-content">
      <div class="time-picker-header">H</div>
      <div class="time-picker-controls">
        <s-icon type="arrow-drop-up" size="14" @click="prevValue('h')" />
      </div>
      <ul ref="hoursList" class="time-list">
        <li
          v-for="h in hours"
          :key="h"
          class="time-element"
          :class="[{active: parseInt(h) === parseInt($props.currentHour)}]"
          @click="setValues('hour', h)"
        >
          {{ h }}
        </li>
      </ul>
      <div class="time-picker-controls">
        <s-icon type="arrow-drop-down" size="14" @click="nextValue('h')" />
      </div>
    </div>

    <div class="time-picker-content">
      <div class="time-picker-header">M</div>
      <div class="time-picker-controls">
        <s-icon type="arrow-drop-up" size="14" @click="prevValue('m')" />
      </div>
      <ul ref="minList" class="time-list">
        <li
          v-for="m in minutes"
          :key="m"
          class="time-element"
          :class="[{active: parseInt(m) === parseInt($props.currentMinute)}]"
          @click="setValues('minute', m)"
        >
          {{ m }}
        </li>
      </ul>
      <div class="time-picker-controls">
        <s-icon type="arrow-drop-down" size="14" @click="nextValue('m')" />
      </div>
    </div>

    <div class="time-picker-content">
      <div class="time-picker-header">S</div>
      <div class="time-picker-controls">
        <s-icon type="arrow-drop-up" size="14" @click="prevValue('s')" />
      </div>
      <ul ref="secList" class="time-list">
        <li
          v-for="s in seconds"
          :key="s"
          class="time-element"
          :class="[{active: parseInt(s) === parseInt($props.currentSecond)}]"
          @click="setValues('second', s)"
        >
          {{ s }}
        </li>
      </ul>
      <div class="time-picker-controls">
        <s-icon type="arrow-drop-down" size="14" @click="nextValue('s')" />
      </div>
    </div>
  </section>
</template>

<script>
  import functions from '../utils/functions';
  import mixins from '../utils/mixins';

  const MAX_LIST_ELEMENTS = 6;
  export default {
    name: 'calendarTime',
    emits: ['changeTime'],
    mixins: [mixins],
    props: {
      currentHour: {
        type: [Number, String],
        description: 'Value of current hour of main date STime data object',
      },
      currentSecond: {
        type: [Number, String],
        description: 'Value of current second of main date STime data object',
      },
      currentMinute: {
        type: [Number, String],
        description: 'Value of current minute of main date STime data object',
      },
    },
    data() {
      return {
        hours: [],
        minutes: [],
        seconds: [],
      };
    },
    methods: {
      changeTime(val) {
        this.$emit('changeTime', val);
      },
      setValues(type, v) {
        const timeToChange = {type: type, value: v};
        this.changeTime(timeToChange);
      },
      setPrevValue(minV, maxV, array) {
        const f = array[0];
        let val = f - 1;
        if (val < minV) {
          val = maxV;
        } else if (val > maxV) {
          val = 0;
        }
        array.pop();
        array.unshift(val);
      },
      setNextValue(maxV, array) {
        const f = array[array.length - 1];
        let val = f + 1;
        if (val > maxV) {
          val = 0;
        }
        array.shift();
        array.push(val);
      },
      prevValue(type) {
        switch (type) {
          case 'h': {
            this.setPrevValue(0, 23, this.hours);
            break;
          }
          case 'm': {
            this.setPrevValue(0, 59, this.minutes);
            break;
          }
          case 's': {
            this.setPrevValue(0, 59, this.seconds);
            break;
          }
        }
      },
      nextValue(type) {
        switch (type) {
          case 'h': {
            this.setNextValue(23, this.hours);
            break;
          }
          case 'm': {
            this.setNextValue(59, this.minutes);
            break;
          }
          case 's': {
            this.setNextValue(59, this.seconds);
            break;
          }
        }
      },
    },
    created() {
      let hoursInit;
      let minutesInit;
      let secondsInit;
      for (let i = 0; i < MAX_LIST_ELEMENTS; i++) {
        hoursInit = parseInt(this.$props.currentHour) + i;
        minutesInit = parseInt(this.$props.currentMinute) + i;
        secondsInit = parseInt(this.$props.currentSecond) + i;
        if (hoursInit > 23) {
          hoursInit = parseInt(this.$props.currentHour) + i - 24;
        }
        if (minutesInit > 59) {
          minutesInit = parseInt(this.$props.currentMinute) + i - 60;
        }
        if (secondsInit > 59) {
          secondsInit = parseInt(this.$props.currentSecond) + i - 60;
        }
        this.hours.push(hoursInit);
        this.minutes.push(minutesInit);
        this.seconds.push(secondsInit);
      }
    },
    mounted() {
      this.$nextTick(() => {
        const secList = this.$refs.secList;
        const minList = this.$refs.minList;
        const hoursList = this.$refs.hoursList;
        if (secList && minList && hoursList) {
          secList.scrollTop = secList.scrollHeight / MAX_LIST_ELEMENTS;
          minList.scrollTop = minList.scrollHeight / MAX_LIST_ELEMENTS;
          hoursList.scrollTop = hoursList.scrollHeight / MAX_LIST_ELEMENTS;
          const opts = {
            downfn: this.prevValue,
            upfn: this.nextValue,
          };
          secList.addEventListener('wheel', (e) => functions.scrollWheelEventListener(e, {...opts, value: 's'}));
          minList.addEventListener('wheel', (e) => functions.scrollWheelEventListener(e, {...opts, value: 'm'}));
          hoursList.addEventListener('wheel', (e) => functions.scrollWheelEventListener(e, {...opts, value: 'h'}));
        }
      });
    },
    beforeUnmount() {
      this.$nextTick(() => {
        const secList = this.$refs.secList;
        const minList = this.$refs.minList;
        const hoursList = this.$refs.hoursList;
        if (secList && minList && hoursList) {
          secList.scrollTop = secList.scrollHeight / MAX_LIST_ELEMENTS;
          minList.scrollTop = minList.scrollHeight / MAX_LIST_ELEMENTS;
          hoursList.scrollTop = hoursList.scrollHeight / MAX_LIST_ELEMENTS;
          const opts = {
            downfn: this.prevValue,
            upfn: this.nextValue,
          };
          secList.removeEventListener('wheel', (e) => functions.scrollWheelEventListener(e, {...opts, value: 's'}));
          minList.removeEventListener('wheel', (e) => functions.scrollWheelEventListener(e, {...opts, value: 'm'}));
          hoursList.removeEventListener('wheel', (e) => functions.scrollWheelEventListener(e, {...opts, value: 'h'}));
        }
      });
    },
  };
</script>

<style lang="scss" scoped>
  @import '@veasel/core/sass/export';
  $height: 32px;
  $width: 32px;

  .inactive {
    color: var(--secondary-50) !important;
    pointer-events: none;
  }

  .s-icon {
    @include title;

    &:hover {
      background: var(--secondary-light);
    }
  }

  .time-picker {
    text-align: center;
    display: grid;
    grid-area: time;
    grid-template-rows: $height $height 1fr $height;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;

    &-header {
      color: var(--secondary);
      height: $height;
      place-items: center;
      display: grid;
    }

    &-content {
      display: grid;
      grid-template-rows: $height $height 1fr $height;
      gap: 2.5px;
    }

    &-controls {
      display: grid;
      border-radius: 4px;
      background: var(--background-secondary);
      width: $width;

      &:hover {
        background: var(--secondary-light);
      }
    }

    .time-list {
      background: var(--background-secondary);
      display: grid;
    }

    .time-element {
      width: $width;
      height: $height;
      place-items: center;
      align-items: center;
      display: grid;
      cursor: pointer;

      &:hover {
        background: var(--secondary-light);
      }
    }

    .active {
      background: var(--active) !important;
      color: white !important;
    }
  }
</style>
