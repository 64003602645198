<template>
  <div class="graph-wrap graph-bar" data-chart>
    <graph-tooltip :showTooltip="showTooltip" :details="tooltipData" />
  </div>
</template>

<script>
  import {pointer} from 'd3';
  import SVGGraph from '../utils/svgGraphMixin.js';
  import graphTooltip from './graphTooltip.vue';
  import {colorsMixin} from '@veasel/core/colors';

  const TYPE_DISCRETE = 'discrete';
  const TYPE_TRISTATE = 'tristate';

  export default {
    name: 's-graph-bar',
    components: {
      graphTooltip,
    },
    mixins: [colorsMixin, SVGGraph],
    props: {
      type: {
        description: 'The type of bar graph to draw.',
        type: String,
      },
      values: {
        description: 'The number values to be used when constructing the graph.',
        type: Array,
        required: true,
      },
      options: {
        description: 'The options object, to specify style.',
        type: Object,
        default: () => ({}),
      },
      trackId: {
        description: 'The unique identifier for the wrapper that the svg must be applied to',
        type: String,
        required: true,
      },
    },
    data() {
      return {
        xPositions: [],
      };
    },
    watch: {
      // The `updated` lifecycle hook will trigger every time the graph is moused over
      // this watcher provides a way to only trigger an update to d3js when the values change
      values: function () {
        this.draw();
      },
    },
    methods: {
      drawBarGraph: function () {
        const defaults = {
          width: 80,
          height: 30,
          barSpacing: 2,
          maxBarWidth: 8,
          marginTop: 0,
          barColorPositive: this.s_colors(this.suadeColors.blue),
          barColorNegative: this.s_colors(this.suadeColors.red),
          barColorZero: this.s_colors(this.suadeColors.gray),
          barColorWin: this.s_colors(this.suadeColors.green),
        };

        if (this.type === TYPE_DISCRETE) {
          this.showDot = false;
          defaults.marginTop = 8;
          defaults.maxBarWidth = 6;
          defaults.barSpacing = 0;
        }

        this.graphOptions = this.getGraphOptions(defaults);

        this.draw();
      },
      draw: function () {
        this.graphOptions.width = this.values.length * (this.graphOptions.maxBarWidth + this.graphOptions.barSpacing);

        this.setupGraph();

        const minValue = Math.min(...this.values);
        const maxValue = Math.max(...this.values);

        this.xLinear = this.getLinearScaleX(0, this.values.length);
        this.yLinear = this.getLinearScaleY(minValue, maxValue);

        this.drawBars();

        this.handleBarMouseEvents();
      },
      drawBars: function () {
        this.svgGraph
          .selectAll('.bar')
          .data(this.values)
          .enter()
          .append('rect')
          .attr('class', 'bar')
          .attr('x', (_data, index) => {
            this.xPositions.push(this.xLinear(index));
            return this.xLinear(index);
          })
          .attr('y', (data) => this.getRectYPos(data))
          .attr('width', this.getRectWidth())
          .attr('height', (data) => this.getRectHeight(data))
          .attr('fill', (data) => this.handleColors(data));
      },
      getRectYPos: function (data) {
        if (this.type === TYPE_DISCRETE) {
          if (data < 0) {
            return this.yLinear(data) - this.graphOptions.marginTop;
          }
          return this.yLinear(data);
        }

        return data > 0 ? this.yLinear(data) : this.yLinear(0);
      },
      getRectWidth: function () {
        if (this.type === TYPE_DISCRETE) {
          return 1;
        }

        return this.graphOptions.maxBarWidth;
      },
      getRectHeight: function (data) {
        // Need to draw a line rather than leave an empty gap
        if (!data || data === 0) {
          return 1;
        }

        if (this.type === TYPE_DISCRETE) {
          return this.graphOptions.marginTop;
        }

        const zeroHeightPoint = this.yLinear(0);
        const dataHeightPoint = this.yLinear(data);

        // Will cancel out if minY === maxY in linear data on y Axis
        const height = dataHeightPoint - zeroHeightPoint;

        if (height === 0) {
          return dataHeightPoint;
        }

        return Math.abs(height);
      },
      handleColors: function (data) {
        if (!data || data === 0) {
          return this.graphOptions.barColorZero;
        }
        if (data > 0) {
          if (this.type === TYPE_TRISTATE) {
            return this.graphOptions.barColorWin;
          }
          return this.graphOptions.barColorPositive;
        }
        return this.graphOptions.barColorNegative;
      },
      updateDateToolTipText: function (data) {
        if (this.type === TYPE_TRISTATE) {
          if (data > 0) {
            return 'Win';
          }
          if (data === 0) {
            return 'Draw';
          }
          return 'Loss';
        }
        return !data ? 0 : data;
      },
      determineXValueFromEvent: function (posX) {
        // Finds x-position i.e. the starting point of the Bar only
        const xPositionValue = this.xPositions.find(
          (position, index) => position <= posX && posX < this.xPositions[index + 1]
        );

        if (xPositionValue === undefined) {
          if (posX - this.xPositions[0] < this.xPositions.at(-1) - posX) {
            return this.xPositions[0];
          }
          return this.xPositions.at(-1);
        }

        return xPositionValue;
      },
      handleBarMouseEvents: function () {
        this.svgGraph.on('mousemove', (e) => {
          const [posX, posY] = pointer(e);

          this.tooltipData.posX = posX + 15;
          this.tooltipData.posY = posY - 25;

          // Need to get index of a stored x position in mem to send to the tooltip.
          // This cannot be done by attaching the event to the bar itself because the height
          // might not reach a point that can be targeted.
          const xValue = this.determineXValueFromEvent(posX);
          const index = this.xPositions.indexOf(xValue);
          const data = this.values[index];

          this.tooltipData.dotColor = this.handleColors(data);
          this.tooltipData.text = this.updateDateToolTipText(data);
        });

        this.svgGraph.on('mouseleave', () => {
          this.showTooltip = false;
        });
      },
    },
    mounted() {
      this.drawBarGraph();
    },
  };
</script>
