/**
 * Allow a component to call a specific function or emit an event when a key is pressed
 * @param {boolean} required
 * @return {object}
 */
const onKeyPressed = function (required = false) {
  return {
    props: {
      onKeyPressed: {
        description: 'Function called or event emitted on input key pressed',
        type: [Function, String],
        required: required,
      },
    },
    emits: ['enter'],
    methods: {
      $_onKeyPressed: function (event) {
        if (typeof this.onKeyPressed == 'function') {
          this.onKeyPressed(event);
        } else if (typeof this.onKeyPressed == 'string') {
          this.$el.dispatchEvent(new Event(this.onKeyPressed));
        } else if (required) {
          console.error(this.$options.name + ' requires onKeyPressed prop');
        }
        if (event.key === 'Enter') {
          this.$emit('enter');
        }
      },
    },
  };
};

export default onKeyPressed;
