/* eslint-disable require-jsdoc */
import * as Utils from './utils';
import * as Consts from './consts';

const MILLISECONDS_A_YEAR = Consts.MILLISECONDS_A_DAY * 365;
const MILLISECONDS_A_MONTH = Consts.MILLISECONDS_A_DAY * 30;

const unitToMS = {
  years: MILLISECONDS_A_YEAR,
  months: MILLISECONDS_A_MONTH,
  days: Consts.MILLISECONDS_A_DAY,
  hours: Consts.MILLISECONDS_A_HOUR,
  minutes: Consts.MILLISECONDS_A_MINUTE,
  seconds: Consts.MILLISECONDS_A_SECOND,
  milliseconds: 1,
  weeks: Consts.MILLISECONDS_A_WEEK,
};

const parseDuration = (duration) => {
  if (typeof duration === 'number') {
    return duration;
  } else if (duration === undefined) {
    return 0;
  } else {
    throw new TypeError();
  }
};

/**
 * SuadeTime Duration class for initialising and displaying durations
 */
class Duration {
  /**
   * @param {Number} duration
   * @param {String} unit
   */
  constructor(duration, unit = Consts.MS) {
    this.$u = {};
    unit = Utils.prettyUnit(unit);
    duration = this.convertToMilliseconds(parseDuration(duration), `${unit}s`);
    this.ms = Math.abs(duration);
    this.init();
  }

  /**
   *  Initialise the units of time
   */
  init() {
    let {ms} = this;
    this.$u.years = Math.floor(ms / MILLISECONDS_A_YEAR);
    ms %= MILLISECONDS_A_YEAR;
    this.$u.months = Math.floor(ms / MILLISECONDS_A_MONTH);
    ms %= MILLISECONDS_A_MONTH;
    this.$u.days = Math.floor(ms / Consts.MILLISECONDS_A_DAY);
    ms %= Consts.MILLISECONDS_A_DAY;
    this.$u.hours = Math.floor(ms / Consts.MILLISECONDS_A_HOUR);
    ms %= Consts.MILLISECONDS_A_HOUR;
    this.$u.minutes = Math.floor(ms / Consts.MILLISECONDS_A_MINUTE);
    ms %= Consts.MILLISECONDS_A_MINUTE;
    this.$u.seconds = Math.floor(ms / Consts.MILLISECONDS_A_SECOND);
    ms %= Consts.MILLISECONDS_A_SECOND;
    this.$u.milliseconds = ms;
  }

  convertToMilliseconds(duration, unit) {
    if (unit !== Consts.MS) {
      return duration * unitToMS[unit];
    } else return duration;
  }

  /**
   * Get the amount of a unit in a duration (i.e. number in the range of 0 to 999 for milliseconds, 0 to 59 for seconds etc.)
   * @param {String} unit
   * @return {Number}
   */
  get(unit) {
    unit = Utils.prettyUnit(unit) + 's';
    return this.$u[unit];
  }

  as(unit) {
    return this.ms / unitToMS[unit];
  }

  years() {
    return this.get('years');
  }

  months() {
    return this.get('months');
  }

  days() {
    return this.get('days');
  }

  hours() {
    return this.get('hours');
  }

  minutes() {
    return this.get('minutes');
  }

  seconds() {
    return this.get('seconds');
  }

  milliseconds() {
    return this.get('milliseconds');
  }

  asYears() {
    return this.as('years');
  }

  asMonths() {
    return this.as('months');
  }

  asDays() {
    return this.as('days');
  }

  asHours() {
    return this.as('hours');
  }

  asMinutes() {
    return this.as('minutes');
  }

  asSeconds() {
    return this.as('seconds');
  }

  asMilliseconds() {
    return this.as('milliseconds');
  }

  /**
   *
   * @param {Object} options
   * @return {String}
   */
  format(options = {}) {
    const {locale, unitStyle = 'narrow', precision = 2} = options;
    const durationComponents = [
      {value: this.$u.years, unit: Consts.Y},
      {value: this.$u.months, unit: Consts.M},
      {value: this.$u.days, unit: Consts.D},
      {value: this.$u.hours, unit: Consts.H},
      {value: this.$u.minutes, unit: Consts.MIN},
      {value: this.$u.seconds, unit: Consts.S},
      {value: this.$u.milliseconds, unit: Consts.MS},
    ];

    const filteredDurations = durationComponents.filter(({value}) => value);
    let durationToDisplay = '';
    for (let i = 0; i < filteredDurations.length; i++) {
      if (i >= precision) {
        break;
      }
      if (i !== 0) {
        durationToDisplay += ' ';
      }
      durationToDisplay += new Intl.NumberFormat(locale, {
        style: 'unit',
        unit: filteredDurations[i].unit,
        unitDisplay: unitStyle,
      }).format(filteredDurations[i].value);
    }

    return durationToDisplay;
  }
}

export default Duration;
