export default {
  props: {
    min: {
      type: [Number, String, Object, Date],
    },
    max: {
      type: [Number, String, Object, Date],
    },
    maxLength: {
      type: Number,
    },
    multiple: Boolean,
  },

  computed: {
    $_hasMinError() {
      if (this.multiple) {
        return this.min != undefined && Array.isArray(this.modelValue) && this.modelValue.length < this.min;
      }
    },

    $_hasMaxError() {
      if (this.multiple) {
        return this.max != undefined && Array.isArray(this.modelValue) && this.modelValue.length > this.max;
      }
    },

    $_hasMaxSizeError() {
      if (typeof this.modelValue === 'string' && this.maxLength) {
        return this.modelValue.length > this.maxLength;
      }
    },

    $_hasErrors: function () {
      return this._isEmpty
        ? false
        : !this._validPattern || !!this.$_hasMaxSizeError || !!this.$_hasMinError || !!this.$_hasMaxError;
    },
  },
};
