<template>
  <li class="node-tree">
    <div class="node-line" :class="{disabled: disabled || parsedNode.disabled}" @click="select">
      <span v-if="!root" class="branch branch-h" :class="{group: isGroup && expanded}"></span>
      <span class="branch branch-v-top" :class="{group: isGroup && expanded}"></span>
      <span class="branch branch-v-bottom" :class="{group: isGroup && expanded}"></span>
      <span v-if="isGroup" class="expander" @click="toggle">
        <s-icon :type="expanded ? 'minus-square' : 'plus-square'" />
      </span>
      <span class="tree-label">{{ parsedNode.label }}</span>
      <span v-if="isGroup && parsedNode.selectAll && !disabled" class="group-selector">
        <a class="s-link theme-style-caption-link" @click="($event) => selectAll(selectAllState, $event)">
          {{ selectAllState ? 'Unselect All' : 'Select All' }}
        </a>
      </span>
      <span class="selector" :class="{disabled: disabled || parsedNode.disabled}">
        <s-icon
          v-show="parsedNode.checkable"
          :type="
            state[parsedNode.category] && state[parsedNode.category][parsedNode.key] ? 'checkbox-selected' : 'checkbox'
          "
        />
      </span>
    </div>
    <ul v-if="isGroup" v-show="expanded" class="tree-branch">
      <tree-node
        v-for="child in parsedNode.children"
        :ref="child.children && child.children.length ? 'node-' + child.key || child.label || child : 'none'"
        :key="child.key || child.label || child"
        :state="state"
        :node="child"
        :defaultCategory="defaultCategory"
        :logicMod="logicMod"
        :disabled="disabled"
        @node-updated="nodeUpdated"
        @update-parent="updateParent"
      ></tree-node>
    </ul>
  </li>
</template>

<script>
  import icon from '@veasel/base/icon';

  export default {
    name: 'tree-node',
    components: {
      's-icon': icon,
    },
    emits: ['node-updated', 'update-parent'],
    props: {
      node: {
        type: [Object, String],
        default: () => ({}),
      },
      state: {
        type: Object,
        default: () => ({}),
      },
      root: {
        type: Boolean,
        default: false,
      },
      defaultCategory: {
        type: String,
        default: 'others',
      },
      logicMod: {
        type: String,
        validator: (value) => ['none', 'child-needs-parent', 'parent-needs-children'].includes(value),
        default: 'none',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data: function () {
      return {
        expanded: true,
        selectAllState: false,
      };
    },
    computed: {
      parsedNode() {
        return this.parseNode(this.node);
      },

      isGroup() {
        return this.parsedNode.children && this.parsedNode.children.length > 0;
      },
    },
    methods: {
      parseNode(node) {
        return {
          key: node.key || node,
          label: node.label || node.key || node,
          category: node.category || this.defaultCategory,
          children: node.children || [],
          selectAll: node.selectAll === false ? false : true,
          checkable: node.checkable === false ? false : true,
          disabled: !!node.disabled || this.disabled,
        };
      },
      toggle(e) {
        e.stopPropagation();
        this.expanded = !this.expanded;
      },
      updateParent(flag) {
        this.$emit('node-updated', {key: this.parsedNode.key, category: this.parsedNode.category, flag: flag});
        this.$emit('update-parent', flag);
      },
      nodeUpdated(e) {
        this.$emit('node-updated', e);
      },
      triggerLogic(flag) {
        if (this.logicMod === 'child-needs-parent') {
          // when a child is checked, all parents are checked too
          if (flag) {
            this.$emit('update-parent', flag);
          }
        } else if (this.logicMod === 'parent-needs-children') {
          // when a parent is checked, all children are checked too
          if (flag) {
            this.selectChildren(flag);
            this.selectGrandChildren(flag);
          }
          // when a child is unchecked, all parents are unchecked too
          if (!flag) {
            this.$emit('update-parent', flag);
          }
        }
      },
      select() {
        const flag = !(
          this.state[this.parsedNode.category] && this.state[this.parsedNode.category][this.parsedNode.key]
        );
        if (this.parsedNode.checkable && !this.parsedNode.disabled) {
          this.$emit('node-updated', {
            key: this.parsedNode.key,
            category: this.parsedNode.category,
            flag: flag,
          });
        }
        this.triggerLogic(flag);
      },
      selectAll(flag, e) {
        if (e) {
          e.stopPropagation();
        }
        // select self
        if (this.parsedNode.checkable && !this.parsedNode.disabled) {
          this.$emit('node-updated', {key: this.parsedNode.key, category: this.parsedNode.category, flag: !flag});
        }
        this.triggerLogic(!flag);
        this.selectChildren(!flag);
        this.selectGrandChildren(!flag);
        this.selectAllState = !flag;
      },
      selectChildren(flag) {
        // select direct children
        for (const i in this.parsedNode.children) {
          const child = this.parseNode(this.parsedNode.children[i]);
          if (!child.children.length && child.checkable && !child.disabled) {
            this.$emit('node-updated', {key: child.key, category: child.category, flag: flag});
          }
        }
      },
      selectGrandChildren(flag) {
        // select potential grand children
        for (const i in Object.keys(this.$refs)) {
          const refId = Object.keys(this.$refs)[i];
          if (refId.indexOf('node-') === 0) {
            this.$refs[refId].selectAll(!flag);
          }
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@veasel/core/sass/conf';

  .tree-branch {
    margin-left: 1rem;
  }

  .node-tree {
    position: relative;

    .node-line {
      padding: 0.125rem 0.5rem;
      user-select: none;
      display: flex;
      align-items: center;

      &:hover {
        background-color: var(--secondary-light);
      }

      &.disabled:hover {
        background-color: var(--background-secondary);
      }

      .expander {
        cursor: pointer;
        position: absolute;
        z-index: 1;
      }

      .branch {
        position: absolute;
        pointer-events: none;
      }

      .branch-h {
        width: 0.75rem;
        left: 0;
        top: 0;
        height: 0.625rem;
        border-bottom: 1px dotted var(--secondary);
      }

      .tree-label {
        padding-left: 1.5rem;
        flex-grow: 1;
        align-self: flex-start;
        text-align: left;
      }

      .selector {
        text-align: right;
        width: 1rem;
        min-height: 0.5rem;
        display: flex;
        align-content: center;

        ::v-deep(.s-icon i.icon) {
          color: var(--active);
        }

        &.disabled {
          pointer-events: none;

          ::v-deep(.s-icon i.icon) {
            color: var(--secondary);
          }
        }
      }

      .group-selector {
        text-align: right;
        margin-right: 0.5rem;
        display: flex;
        align-content: center;
        padding-top: 0.1rem;

        a {
          cursor: pointer;
        }
      }
    }

    .node-tree .node-line .branch-v-top {
      width: 1rem;
      left: 0;
      top: -0.725rem;
      height: 1.3rem;
      border-left: 1px dotted var(--secondary);
    }

    .node-tree:not(:last-child) > .node-line > .branch-v-bottom.group {
      width: 1rem;
      left: 0;
      top: 0.55rem;
      height: calc(100% - 1.325rem);
      border-left: 1px dotted var(--secondary);
    }
  }
</style>
