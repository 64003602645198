<template>
  <nav
    class="s-navbar flex flex-direction-column"
    :class="{collapsed: collapsed, 'non-primary-background': hasNonPrimaryBackground}"
    :style="{width: computedWidth + 'px', top: marginTop + 'px'}"
  >
    <div class="nav-list">
      <menu class="nav-menu">
        <li v-for="item in menu" :id="item.id" :key="item.name" class="nav-menu-item">
          <template v-if="permission(item)">
            <!-- Router link, standard href link or event emitter -->
            <router-link
              v-if="item.to"
              :key="item.id"
              :data-cy="item.name.split(' ').join('')"
              :to="linkPermission(item).to"
              class="root-item flex align-items-center"
              :class="{active: isActive(item), open: isOpen(item), 'collapsed-children': isSubmenuOpen(item)}"
              @mouseover="hover(item)"
            >
              <s-icon :type="item.icon" class="nav-item-icon" size="18" />
              <transition
                name="nav-item-text-transition"
                @before-leave="navItemTextBeforeLeave"
                @after-leave="navItemTextAfterLeave"
                @before-enter="navItemTextBeforeEnter"
                @after-enter="navItemTextAfterEnter"
              >
                <span v-show="!collapsed" class="nav-item-text">{{ item.name }}</span>
              </transition>
              <s-icon
                v-if="item.submenu"
                type="arrow-drop-down"
                class="submenu-arrow"
                color="theme-secondary"
                :size="8"
                @click.prevent="toggleCollapseChildren(item)"
              />
            </router-link>
            <a
              v-else-if="item.href"
              :data-cy="item?.name.split(' ').join('')"
              :href="item.href"
              class="root-item"
              :class="{active: isActive(item), open: isOpen(item)}"
              @mouseover="hover(item)"
            >
              <s-icon :type="item.icon" class="nav-item-icon" size="18" />
              <span class="nav-item-text">{{ item.name }}</span>
              <s-icon
                v-if="item.submenu"
                type="arrow-drop-down"
                class="submenu-arrow"
                color="theme-secondary"
                :size="8"
                @click.prevent="toggleCollapseChildren(item)"
              />
            </a>
            <a
              v-else-if="item.emit"
              :data-cy="item.name.split(' ').join('')"
              class="root-item pointer"
              @click="$emit(item.emit)"
              @mouseover="hover(item)"
            >
              <s-icon :type="item.icon" class="nav-item-icon" size="18" />
              <span class="nav-item-text">{{ item.name }}</span>
              <s-icon
                v-if="item.submenu"
                type="arrow-drop-down"
                class="submenu-arrow"
                color="theme-secondary"
                :size="8"
                @click.prevent="toggleCollapseChildren(item)"
              />
            </a>

            <!-- Nested sub-menu: visible if the navbar is not collapsed and the root item is currently active -->
            <s-transition-slide-y>
              <menu v-if="isSubmenuOpen(item)" class="nav-submenu">
                <li
                  v-for="subitem in item.submenu"
                  :key="subitem?.name"
                  class="nav-submenu-item"
                  :data-cy="subitem?.name.split(' ').join('')"
                >
                  <template v-if="permission(subitem)">
                    <router-link v-if="subitem.to" :to="subitem.to" :class="{active: isActive(subitem)}">
                      <span class="ellipsis">{{ subitem.name }}</span>
                    </router-link>
                    <a v-else-if="subitem.href" :href="subitem.href" :class="{active: isActive(subitem)}">
                      <span class="ellipsis">{{ subitem.name }}</span>
                    </a>
                  </template>
                </li>
              </menu>
            </s-transition-slide-y>
            <!-- Hover-expanding sub-menu: visible when the user hovers a root item if the menu is collapsed or the item is not the active one-->
            <menu v-if="collapsed" class="nav-submenu-drop" :group="item.id">
              <li v-if="collapsed" class="nav-submenu-drop-title">
                <router-link v-if="item.to" :to="item.to" :class="{active: isActive(item)}">
                  <span>{{ item.name }}</span>
                </router-link>
                <a v-else-if="item.href" :href="item.href" :class="{active: isActive(item)}">
                  <span>{{ item.name }}</span>
                </a>
                <a v-else-if="item.emit" class="pointer" @click="$emit(item.emit)">
                  <span>{{ item.name }}</span>
                </a>
              </li>
              <div v-if="item.submenu" class="nav-submenu-contents">
                <li v-for="subitemdrop in item.submenu" :key="subitemdrop.name">
                  <template v-if="permission(subitemdrop)">
                    <router-link v-if="subitemdrop.to" :to="subitemdrop.to" :class="{active: isActive(subitemdrop)}">
                      <span class="ellipsis">{{ subitemdrop.name }}</span>
                    </router-link>
                    <a v-else-if="subitemdrop.href" :href="subitemdrop.href" :class="{active: isActive(subitemdrop)}">
                      <span class="ellipsis">{{ subitemdrop.name }}</span>
                    </a>
                  </template>
                </li>
              </div>
            </menu>
          </template>
        </li>
      </menu>
    </div>
    <div :class="['collapse-area', collapsed ? 'collapsed' : '']" @click="collapse">
      <s-icon type="arrow-drop-down" size="12" />
    </div>
    <slot name="bottom"></slot>
  </nav>
</template>

<script>
  import {getStorage, setStorage, isEmpty} from '@veasel/core/tools';
  import {icon, transitionSlideY} from '@veasel/base';

  export default {
    name: 's-navbar',
    description: 'A navbar component that can be toggled, and be fed with a nested menu of link.',
    components: {
      's-icon': icon,
      's-transition-slide-y': transitionSlideY,
    },
    props: {
      collapsed: {
        description: 'If the navbar is collapsed',
        type: Boolean,
        default: false,
      },
      menu: {
        description: 'The menu object containing all labels, links, and icons.',
        type: Array,
        default: () => [],
      },
      width: {
        description: 'The navbar width (when expanded).',
        type: Number,
        default: 220,
      },
      marginTop: {
        description: 'The margin from the top of the screen,',
        type: Number,
        default: 0,
      },
      permission: {
        description: 'A function to handle link permissions',
        type: Function,
        default: () => true,
      },
      linkPermission: {
        type: Function,
        default: (item) => item,
      },
      usePrimaryBackground: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        showSubmenus: this.resetShowSubmenus(),
        lastToggledId: undefined,
      };
    },

    emits: ['update-collapse'],

    computed: {
      computedWidth() {
        return this.collapsed ? 66 : this.width;
      },
      hasNonPrimaryBackground() {
        return !this.usePrimaryBackground;
      },
      navItemTextWidth() {
        return this.width - 88 + 'px';
      },
    },
    watch: {
      $route() {
        this.showSubmenus = this.resetShowSubmenus();
      },
    },

    created() {
      this.$emit('update-collapse', getStorage('navbar-collapse-state'));
    },

    methods: {
      collapse() {
        this.$nextTick(() => {
          setStorage('navbar-collapse-state', !this.collapsed);
          this.$emit('update-collapse', !this.collapsed);
        });
      },

      /**
       * On hover of parent menu item (e.g. Home, Data, Report) reposition submenu if it overflows the window
       * @param {Object} item parent menu item
       */
      hover(item) {
        // Find submenu group (won't exist if parent item is selected)
        const menu = document.querySelector('.nav-submenu-drop[group="' + item.id + '"]');
        if (menu) {
          const menuHeight = menu.getClientRects()[0].height;
          const targetMenuItemTop = document.querySelector('.nav-menu-item#' + item.id)?.getClientRects()[0].y;
          if (targetMenuItemTop && targetMenuItemTop + menuHeight > window.innerHeight) {
            menu.style.left = this.computedWidth - 8 + 'px';
            menu.style.top = window.innerHeight - menuHeight + 'px';
          } else {
            menu.style.left = this.computedWidth - 8 + 'px';
            menu.style.top = targetMenuItemTop + 'px';
          }
        }
      },
      isActive(item) {
        if (item.to) {
          if (item.to.path) {
            return this.$route.path.startsWith(item.match) || this.$route.path === item.to.path;
          } else {
            return this.$route.path === item.to;
          }
        }
        return false;
      },
      isSubpageActive(item) {
        if (item.to && item.submenu) {
          return this.$route.path.includes(item.match + '/');
        }
        return false;
      },

      isSubmenuActive(items) {
        return (
          items.filter((item) => {
            return this.isActive(item);
          }).length > 0
        );
      },

      isOpen(item) {
        return item.match && this.$route.path.startsWith(item.match) && !isEmpty(item.submenu);
      },

      isSubmenuOpen(item) {
        if (item.submenu && !this.collapsed) {
          return this.showSubmenus[item.id];
        }
        return false;
      },

      toggleCollapseChildren(item) {
        if (this.lastToggledId !== item.id) {
          this.showSubmenus[this.lastToggledId] = false;
        }

        this.showSubmenus[item.id] = !this.showSubmenus[item.id];
        this.lastToggledId = item.id;
      },

      resetShowSubmenus() {
        return [...this.menu].reduce((map, item) => {
          map[item.id] = this.isOpen(item);
          return map;
        }, {});
      },

      navItemTextBeforeLeave(element) {
        element.style.height = getComputedStyle(element).height;
      },

      navItemTextAfterLeave(element) {
        element.style.height = null;
      },

      navItemTextBeforeEnter(element) {
        element.style.position = 'absolute';
        element.style.display = 'inline-block';
        element.style.visibility = 'hidden';
        element.style.height = 'auto';
        element.style.width = this.navItemTextWidth;
        getComputedStyle(element).width;

        const height = getComputedStyle(element).height;

        element.style.width = null;
        element.style.position = null;
        element.style.display = 'none';
        element.style.visibility = null;
        element.style.height = height;

        // Force repaint to make sure the
        // animation is triggered correctly.
        getComputedStyle(element).height;

        // Trigger the animation.
        // We use `requestAnimationFrame` because we need
        // to make sure the browser has finished
        // painting after setting the `height`
        // to `0` in the line above.
        requestAnimationFrame(() => {
          element.style.height = height;
        });
      },

      navItemTextAfterEnter(element) {
        element.style.height = null;
        element.style.width = null;
      },
    },
  };
</script>

<style scoped lang="scss">
  @use 'sass:color';
  @import '@veasel/core';

  $navbar-scroll-bar: color.adjust($gray, $lightness: 10%);
  $navbar-scroll-bar-hover: color.adjust($gray, $lightness: 10%);
  $navbar-scroll-bar-background: color.adjust($lightgray, $lightness: -10%);

  // For easy adjustment
  $navbar-scrollbar-width: 4px;

  @mixin submenu-line {
    content: '';
    width: get-spacing('s');
    height: 1px;
    background: var(--light-hover);
    display: block;
    position: absolute;
    top: 50%;
    left: -8px;
  }

  .s-navbar {
    background-color: var(--background-secondary);
    box-sizing: border-box;
    position: fixed;
    z-index: 48;
    height: calc(100vh - 40px);
    overflow-y: auto;
    user-select: none;
    text-align: left;
    padding-left: get-spacing('l');
    padding-right: get-spacing('m');
    transition: ease all $transition-time;

    &::-webkit-scrollbar {
      width: $navbar-scrollbar-width;
      background-color: $navbar-scroll-bar-background; /* or add it to the track */
      z-index: 51;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $navbar-scroll-bar;
      z-index: 51;

      &:hover {
        background-color: $navbar-scroll-bar-hover;
      }
    }

    .nav-item-icon {
      display: inherit;
    }

    &.collapsed {
      padding-right: get-spacing('s');
      padding-left: get-spacing('s');

      .nav-list {
        li.nav-menu-item {
          & > a {
            .submenu-arrow {
              width: 0;
              padding: 0;
              pointer-events: all;
            }
          }
        }
      }
    }

    .nav-list {
      padding-top: get-spacing('l');
      padding-bottom: get-spacing('xxxxl');

      li.nav-menu-item {
        position: relative;

        menu.nav-submenu {
          margin-top: get-spacing('s');

          &::before {
            content: '';
            display: block;
            width: 1px;
            height: calc(100% - 46px - 34px);
            left: 16px;
            background: var(--light-hover);
            position: absolute;
          }

          a {
            width: 176px;
            margin-left: 24px;
            padding: 16px;

            &::before {
              @include submenu-line;
            }

            span {
              margin-left: 0;
            }
          }
        }

        a {
          padding: 16px 16px;
          width: 100%;
          border-radius: $button-radius;
          box-sizing: border-box;
          display: flex;
          position: relative;

          @include caption-secondary;

          // Reduce the line height by 1px. This helps the height of each menu item, and saves the icons jumping when expanding and collapsing.
          //line-height: calc(var(--caption-secondary-line-height) - 1px);

          &.open {
            background: var(--secondary-light);

            span.nav-item-text {
              @include caption-alt;
              // Reduce the line height by 1px. This helps the height of each menu item, and saves the icons jumping when expanding and collapsing.
              //line-height: calc(var(--caption-alt-line-height) - 1px);
            }
          }

          &.collapsed-children {
            .submenu-arrow {
              transform: rotate(180deg);
              margin-top: 3px;
              pointer-events: all;
            }
          }

          &.active:not(.open) {
            position: relative;
            background-color: var(--active);

            &:hover {
              background-color: var(--active);
            }

            ::v-deep(.svg-icon) {
              fill: var(--background-main);
            }

            span:not(.s-icon) {
              @include caption-alt-background-main;
              // Reduce the line height by 1px. This helps the height of each menu item, and saves the icons jumping when expanding and collapsing.
              //line-height: calc(var(--caption-alt-background-main-line-height) - 1px);
            }
          }

          &.root-item.active:not(.open) {
            background-color: var(--active);
          }

          ::v-deep(.svg-icon) {
            fill: var(--secondary);
            pointer-events: none;
            width: 18px;
            display: inline-block;
          }

          span.nav-item-text {
            display: inline-block;
            width: calc(100% - 16px);
            box-sizing: border-box;
            overflow: hidden;
            padding-left: get-spacing('m');
            @include caption-secondary;

            // Reduce the line height by 1px. This helps the height of each menu item, and saves the icons jumping when expanding and collapsing.
            //line-height: calc(var(--caption-secondary-line-height) - 1px);
          }

          &:hover {
            background-color: var(--light-hover);
          }

          .submenu-arrow {
            position: absolute;
            right: get-spacing('s');
            padding: get-spacing('s');
            transform: rotate(0deg);
            margin-top: 0;
            transition: ease all $transition-time;
            overflow: hidden;
            width: 8px;
            pointer-events: all;

            ::v-deep(.svg-icon) {
              width: 8px;
            }

            &:hover ::v-deep(.svg-icon) path {
              fill: var(--hover);
            }
          }

          .pointer {
            cursor: pointer;
          }
        }

        .nav-submenu-drop {
          overflow: hidden;
          display: none;
          position: fixed;
          z-index: 80;
          border-radius: $border-radius;
          background-color: var(--background-main);
          box-shadow: 0 3px 6px #00000029;

          .nav-submenu-contents {
            padding-right: get-spacing('s');
            padding-left: get-spacing('m');
            padding-bottom: get-spacing('s');
            position: relative;

            &::before {
              content: '';
              display: block;
              width: 1px;
              height: calc(100% - 39px - 16px);
              left: 8px;
              top: 24px;
              background: var(--light-hover);
              position: absolute;
            }
          }

          li.nav-submenu-drop-title {
            background: var(--light-hover);
            padding-left: get-spacing('m');

            a {
              &.active {
                background: transparent;
              }

              &::before {
                content: initial;
              }

              span:not(.s-icon) {
                @include caption-alt;
              }
            }
          }

          li {
            a {
              min-width: 176px;
              padding: 16px;

              &::before {
                @include submenu-line;
              }

              &.active {
                position: relative;
              }

              span:not(.s-icon) {
                display: block;
              }
            }
          }
        }

        &:hover .nav-submenu-drop {
          display: block;
        }
      }
    }

    .collapse-area {
      font-size: 12px;
      width: 32px;
      height: 32px;
      border-radius: 32px;
      background: var(--background-main);
      cursor: pointer;
      box-shadow: 0 3px 6px #00000029;
      position: fixed;
      left: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 17px;

      ::v-deep(.s-icon .icon) {
        transition: ease all $transition-time;
      }

      ::v-deep(.s-icon) {
        position: relative;
        transform: rotate(90deg);
        transition: ease all $transition-time;
        left: -2px;
      }

      &.collapsed ::v-deep(.s-icon) {
        transform: rotate(270deg);
        left: 2px;
      }

      &:hover ::v-deep(.s-icon .icon) {
        color: var(--active);
      }
    }

    .nav-item-text-transition-enter-from {
      width: 0 !important;
    }

    .nav-item-text-transition-enter-to {
      /* stylelint-disable-next-line */
      width: v-bind(navItemTextWidth) !important;
    }

    .nav-item-text-transition-leave-from {
      /* stylelint-disable-next-line */
      width: v-bind(navItemTextWidth) !important;
    }

    .nav-item-text-transition-leave-to {
      width: 0 !important;
    }

    .nav-item-text-transition-leave-active,
    .nav-item-text-transition-enter-active {
      transition: ease all $transition-time;
    }
  }
</style>
