export default {
  name: 'logic',
  namespaced: true,
  state: {
    requirements: false,
    metrics: false,
    tags: {},
  },
  mutations: {
    setRequirements: function (state, list) {
      state.requirements = list;
    },
    setMetrics: function (state, list) {
      state.metrics = list;
    },
    setTag: function (state, tag) {
      state.tags[tag.tag] = tag;
    },
  },
  actions: {
    setRequirements: function (context, list) {
      if (list) {
        context.commit('setRequirements', list);
      }
    },
    setMetrics: function (context, list) {
      if (list) {
        context.commit('setMetrics', list);
      }
    },
    setTag: function (context, tag) {
      if (tag) {
        context.commit('setTag', tag);
      }
    },
  },
};
